import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';
import {IShaderFireAframe} from "../../chemistry/1-metal-ions/shader-fire";

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface ICellsSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolRootHairCellEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolXylemCellEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolPhloemCellEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    mixer: THREE.AnimationMixer;
    cellHighlight: THREE.AnimationAction;
    xylemMixer: THREE.AnimationMixer;
    xylemFirstHighlight: THREE.AnimationAction;
    xylemSecondHighlight: THREE.AnimationAction;
    xylemThirdHighlight: THREE.AnimationAction;
    phloemMixer: THREE.AnimationMixer;
    phloemFirstHighlight: THREE.AnimationAction;
    phloemSecondHighlight: THREE.AnimationAction;
    phloemThirdHighlight: THREE.AnimationAction;
    phloemFourthHighlight: THREE.AnimationAction;
    phloemFifthHighlight: THREE.AnimationAction;
    phloemSixthHighlight: THREE.AnimationAction;
    phloemSevenHighlight: THREE.AnimationAction;
    phloemEightHighlight: THREE.AnimationAction;
    phloemNinthHighlight: THREE.AnimationAction;
    phloemTenthHighlight: THREE.AnimationAction;
    rootHairMixer: THREE.AnimationMixer;
    rootHairFirstHighlight: THREE.AnimationAction;
    rootHairSecondHighlight: THREE.AnimationAction;
    rootHairThirdHighlight: THREE.AnimationAction;
    rootHairFourthHighlight: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    buttonsInitialised: boolean;
    rootHairButtonHandler: () => void;
    vacuoleButtonHandler: () => void;
    aboveRootHairButtonHandler: () => void;
    nucleusButtonHandler: () => void;
    thinWallsButtonHandler: () => void;
    ligninButtonHandler: () => void;
    topButtonHandler: () => void;
    xylemButtonHandler: () => void;
    sievePlateButtonHandler: () => void;
    companionCellsButtonHandler: () => void;
    phloemCellButtonHandler: () => void;
    el: AFrame.Entity;
    currentAssetId: number;
}

const CellScene = {
    name: 'cell-scene',
    val: {
        init(this: ICellsSceneAframe) {
            const controlElement = document.getElementById('mainHolder') as AFrame.Entity;
            controlElement.addEventListener('model-loaded', () => {
                //initialiseAnimations();
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
            })

            const phloemCell = document.getElementById('phloemCell') as AFrame.Entity;
            phloemCell.addEventListener('model-loaded', () => {
                const animatedEl = phloemCell.object3D.getObjectByName('Phloem_Cell_AR') as any;
                this.phloemMixer = new THREE.AnimationMixer(animatedEl)
                const [Phloem_ParticleAnim_1,
                    Phloem_ParticleAnim_2,
                    Phloem_ParticleAnim_3,
                    Phloem_ParticleAnim_4,
                    Phloem_ParticleAnim_5,
                    Phloem_ParticleAnim_6,
                    Phloem_ParticleAnim_7,
                    Phloem_ParticleAnim_8,
                    Phloem_ParticleAnim_9,
                    Phloem_ParticleAnim_10] = animatedEl.animations
                this.phloemFirstHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_1)
                this.phloemSecondHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_2)
                this.phloemThirdHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_3)
                this.phloemFourthHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_4)
                this.phloemFifthHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_5)
                this.phloemSixthHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_6)
                this.phloemSevenHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_7)
                this.phloemEightHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_8)
                this.phloemNinthHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_9)
                this.phloemTenthHighlight = this.phloemMixer.clipAction(Phloem_ParticleAnim_10)
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolPhloemCellEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolPhloemCellEntity.hasLoaded) {
                    initialisePhloemButtons();
                } else {
                    this.poolPhloemCellEntity.addEventListener('loaded', () => {
                        initialisePhloemButtons();
                    });
                }
            });

            const rootHairCell = document.getElementById('rootHairCell') as AFrame.Entity;
            // rootHairCell.setAttribute('scale', '0 0 0');
            rootHairCell.addEventListener('model-loaded', () => {
                const animatedEl = rootHairCell.object3D.getObjectByName('Root_Hair_Cell_AR') as any;
                this.rootHairMixer = new THREE.AnimationMixer(animatedEl)
                const [Soil_Spinning_Anim, RootHair_Particles1, RootHair_Particles2, RootHair_Particles3] = animatedEl.animations
                this.rootHairFirstHighlight = this.rootHairMixer.clipAction(Soil_Spinning_Anim)
                this.rootHairSecondHighlight = this.rootHairMixer.clipAction(RootHair_Particles1)
                this.rootHairThirdHighlight = this.rootHairMixer.clipAction(RootHair_Particles2)
                this.rootHairFourthHighlight = this.rootHairMixer.clipAction(RootHair_Particles3)
                this.rootHairFirstHighlight.setDuration(10)

                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolRootHairCellEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolRootHairCellEntity.hasLoaded) {
                    initialiseRootHairButtons();
                } else {
                    this.poolRootHairCellEntity.addEventListener('loaded', () => {
                        initialiseRootHairButtons();
                    });
                }
            });

            const xylemCell = document.getElementById('xylemCell') as AFrame.Entity;
            xylemCell.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const animatedEl = xylemCell.object3D.getObjectByName('Xylem_Cell_AR') as any;
                this.xylemMixer = new THREE.AnimationMixer(animatedEl)
                const [Xylem_ParticleAnim_1, Xylem_ParticleAnim_2, Xylem_ParticleAnim_3] = animatedEl.animations
                this.xylemFirstHighlight = this.xylemMixer.clipAction(Xylem_ParticleAnim_1)
                this.xylemSecondHighlight = this.xylemMixer.clipAction(Xylem_ParticleAnim_2)
                this.xylemThirdHighlight = this.xylemMixer.clipAction(Xylem_ParticleAnim_3)

                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolXylemCellEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolXylemCellEntity.hasLoaded) {
                    initialiseXylemButtons();
                } else {
                    this.poolXylemCellEntity.addEventListener('loaded', () => {
                        initialiseXylemButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                const cameraEl = this.el.sceneEl?.querySelector('a-camera');
                const cellElement = document.getElementById('mainHolder') as AFrame.Entity;

                // Set the position of the element to the camera's position.

                if (cameraEl) {
                    // console.log('Camera found: ', cameraEl.getAttribute('rotation'));
                    // console.log('Rotation before: ', controlElement.getAttribute('rotation'));

                    // Get the camera's Y rotation.
                    const cameraRotation = cameraEl.getAttribute('rotation') as unknown as THREE.Euler;
                    const cameraRotationY = cameraRotation ? cameraRotation.y : 0;

                    // Get the current rotation of the element.
                    const cellRotation = cellElement.getAttribute('rotation');

                    // Set the element's Y rotation to match the camera's Y rotation.
                    cellElement.setAttribute('rotation', {
                        x: cellRotation.x,
                        y: cameraRotationY,
                        z: cellRotation.z
                    });

                    const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                    // Create an offset vector.
                    const cellOffset = new THREE.Vector3(0, -8, -8);

                    cellOffset.applyQuaternion(cellElement.object3D.quaternion);
                    // Add the offset to the camera's position.
                    const cellNewPosition = camPos.clone().add(cellOffset);

                    // Set the position of the element with the offset.
                    cellElement.setAttribute('position', cellNewPosition);
                }
                if (this.currentDeactivatedButton) {
                    (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                    this.annotationComponent.deactivate();
                }
            });
            // this.currentAssetId = 0;
            this.el.sceneEl?.addEventListener('asset-change', (event) => {

                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const newAssetId = customEvent.detail.assetId;
                const phoemHolder = document.getElementById('phloemCell') as AFrame.Entity;
                const rootHairHolder = document.getElementById('rootHairCell') as AFrame.Entity;
                const xylemHolder = document.getElementById('xylemCell') as AFrame.Entity;

                if (newAssetId === 0) {
                    phoemHolder.object3D.visible = false
                    rootHairHolder.object3D.visible = false
                    xylemHolder.object3D.visible = false
                    this.phloemFirstHighlight.stop()
                    this.phloemSecondHighlight.stop()
                    this.phloemThirdHighlight.stop()
                    this.phloemFourthHighlight.stop()
                    this.phloemFifthHighlight.stop()
                    this.phloemSixthHighlight.stop()
                    this.phloemSevenHighlight.stop()
                    this.phloemEightHighlight.stop()
                    this.phloemNinthHighlight.stop()
                    this.phloemTenthHighlight.stop()
                    this.xylemFirstHighlight.stop()
                    this.xylemSecondHighlight.stop()
                    this.xylemThirdHighlight.stop()
                    this.rootHairFirstHighlight.stop()
                    this.rootHairSecondHighlight.stop()
                    this.rootHairThirdHighlight.stop()
                    this.rootHairFourthHighlight.stop()
                }
                if (newAssetId === 1) {
                    phoemHolder.object3D.visible = false
                    rootHairHolder.object3D.visible = true
                    rootHairHolder.setAttribute('position', '0 2 0')
                    xylemHolder.object3D.visible = false
                    this.phloemFirstHighlight.stop()
                    this.phloemSecondHighlight.stop()
                    this.phloemThirdHighlight.stop()
                    this.phloemFourthHighlight.stop()
                    this.phloemFifthHighlight.stop()
                    this.phloemSixthHighlight.stop()
                    this.phloemSevenHighlight.stop()
                    this.phloemEightHighlight.stop()
                    this.phloemNinthHighlight.stop()
                    this.phloemTenthHighlight.stop()
                    this.xylemFirstHighlight.stop()
                    this.xylemSecondHighlight.stop()
                    this.xylemThirdHighlight.stop()
                    this.rootHairFirstHighlight.play()
                    this.rootHairSecondHighlight.play()
                    this.rootHairThirdHighlight.play()
                    this.rootHairFourthHighlight.play()
                }
                if (newAssetId === 2) {
                    phoemHolder.object3D.visible = false
                    rootHairHolder.object3D.visible = false
                    xylemHolder.object3D.visible = true
                    xylemCell.setAttribute('position', '0 2 0')
                    this.phloemFirstHighlight.stop()
                    this.phloemSecondHighlight.stop()
                    this.phloemThirdHighlight.stop()
                    this.phloemFourthHighlight.stop()
                    this.phloemFifthHighlight.stop()
                    this.phloemSixthHighlight.stop()
                    this.phloemSevenHighlight.stop()
                    this.phloemEightHighlight.stop()
                    this.phloemNinthHighlight.stop()
                    this.phloemTenthHighlight.stop()
                    this.rootHairFirstHighlight.stop()
                    this.rootHairSecondHighlight.stop()
                    this.rootHairThirdHighlight.stop()
                    this.rootHairFourthHighlight.stop()
                    this.xylemFirstHighlight.play()
                    this.xylemSecondHighlight.play()
                    this.xylemThirdHighlight.play()
                }
                if (newAssetId === 3) {
                    phoemHolder.object3D.visible = true
                    rootHairHolder.object3D.visible = false
                    xylemHolder.object3D.visible = false
                    phloemCell.setAttribute('position', '0 2 0')
                    this.xylemFirstHighlight.stop()
                    this.xylemSecondHighlight.stop()
                    this.xylemThirdHighlight.stop()
                    this.rootHairFirstHighlight.stop()
                    this.rootHairSecondHighlight.stop()
                    this.rootHairThirdHighlight.stop()
                    this.rootHairFourthHighlight.stop()
                    this.phloemFirstHighlight.play()
                    this.phloemSecondHighlight.play()
                    this.phloemThirdHighlight.play()
                    this.phloemFourthHighlight.play()
                    this.phloemFifthHighlight.play()
                    this.phloemSixthHighlight.play()
                    this.phloemSevenHighlight.play()
                    this.phloemEightHighlight.play()
                    this.phloemNinthHighlight.play()
                    this.phloemTenthHighlight.play()
                }
            });

            this.rootHairButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Root hair';
                    const body = 'Root hair cells have a large surface area to increase the rate of absorption. This allows for more absorption of minerals and water.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.vacuoleButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Vacuole';
                    const body = 'Root hair cells have large permanent vacuoles full of cell sap that create a concentration gradient that increases the rate of water absorption by osmosis';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.nucleusButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Nucleus';
                    const body = ''
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.thinWallsButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Thin walls';
                    const body = 'Root hair cells have thinner walls which makes diffusion easier due to shorter distance for water to diffuse through'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.aboveRootHairButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Root hair cell';
                    const body = 'The function of a root hair cell is to take up water and mineral ions from the soil to the plant.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.ligninButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Lignin';
                    const body = 'Lignin walls, rings, and spirals support the xylem cells by giving them the support and flexibility needed to handle the pressure caused by the up-flow of water. <br />. Lignin spirals and walls also support the stem of the plant'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.topButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Xylem Continuous Tube';
                    const body = 'There is no top or bottom to xylem vessels. They are continuous tubes with water running through the plant';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.xylemButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Xylem cell';
                    const body = 'The function of a xylem cell is to transport water and mineral ions upwards from the roots to other parts of the plant.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.sievePlateButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Sieve Plate';
                    const body = 'Sieve plates contain holes that allow sugar dissolved in water to move freely upwards and downwards'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.companionCellsButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Companion cells';
                    const body = 'Companion cells contain many mitochondria which provide the energy needed to transport sugar and amino acids up and down the tubes. '
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.phloemCellButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Phloem cell';
                    const body = 'The function of a phloem cell is to transport dissolved sugars made by photosynthesis and amino acids around the plant.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }
            const initialiseRootHairButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolRootHairCellEntity.components['pool'] as PoolComponent;
                const rootHairTriggerBtn = poolButtons.requestEntity()
                rootHairTriggerBtn?.setAttribute('position', '0.2 10 0')
                rootHairTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        rootHairTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        rootHairTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 2) {
                        rootHairTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 3) {
                        rootHairTriggerBtn?.setAttribute('scale', '0 0 0')
                    }

                });
                rootHairTriggerBtn?.play()
                rootHairTriggerBtn?.addEventListener('click', () => {
                    this.rootHairButtonHandler()
                    if (rootHairTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(rootHairTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (rootHairTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = rootHairTriggerBtn
                    }
                });
                const vacuoleTriggerBtn = poolButtons.requestEntity()
                vacuoleTriggerBtn?.setAttribute('position', '0 7 0')
                vacuoleTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        vacuoleTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        vacuoleTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 2) {
                        vacuoleTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 3) {
                        vacuoleTriggerBtn?.setAttribute('scale', '0 0 0')
                    }

                });
                vacuoleTriggerBtn?.play()
                vacuoleTriggerBtn?.addEventListener('click', () => {
                    this.vacuoleButtonHandler()
                    if (vacuoleTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(vacuoleTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (vacuoleTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = vacuoleTriggerBtn
                    }
                });
                const nucleusTriggerBtn = poolButtons.requestEntity()
                nucleusTriggerBtn?.setAttribute('position', '0 6.3 0')
                nucleusTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        nucleusTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        nucleusTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 2) {
                        nucleusTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 3) {
                        nucleusTriggerBtn?.setAttribute('scale', '0 0 0')
                    }

                });
                nucleusTriggerBtn?.play()
                nucleusTriggerBtn?.addEventListener('click', () => {
                    this.nucleusButtonHandler()
                    if (nucleusTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(nucleusTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (nucleusTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = nucleusTriggerBtn
                    }
                });
                const thinWallsTriggerButton = poolButtons.requestEntity();
                thinWallsTriggerButton?.setAttribute('position', '1.1 6.8 0');
                thinWallsTriggerButton?.setAttribute('scale', '0 0 0');
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        thinWallsTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        thinWallsTriggerButton?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 2) {
                        thinWallsTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 3) {
                        thinWallsTriggerButton?.setAttribute('scale', '0 0 0')
                    }

                });
                thinWallsTriggerButton?.play()
                thinWallsTriggerButton?.addEventListener('click', () => {
                    this.thinWallsButtonHandler()
                    if (thinWallsTriggerButton) {
                        this.annotationComponent.setObjectToFollow(thinWallsTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (thinWallsTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = thinWallsTriggerButton

                    }
                });
                const aboveRootHairTriggerBtn = poolButtons.requestEntity()
                aboveRootHairTriggerBtn?.setAttribute('position', '1.4 8.5 0')
                aboveRootHairTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        aboveRootHairTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        aboveRootHairTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 2) {
                        aboveRootHairTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 3) {
                        aboveRootHairTriggerBtn?.setAttribute('scale', '0 0 0')
                    }

                });
                aboveRootHairTriggerBtn?.play()
                aboveRootHairTriggerBtn?.addEventListener('click', () => {
                    this.aboveRootHairButtonHandler()
                    if (aboveRootHairTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(aboveRootHairTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (aboveRootHairTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = aboveRootHairTriggerBtn
                    }
                });

            };
            const initialiseXylemButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolXylemCellEntity.components['pool'] as PoolComponent;
                const ligninTriggerBtn = poolButtons.requestEntity()
                ligninTriggerBtn?.setAttribute('position', '0 7 0.3')
                ligninTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        ligninTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        ligninTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 2) {
                        ligninTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 3) {
                        ligninTriggerBtn?.setAttribute('scale', '0 0 0')
                    }

                });
                ligninTriggerBtn?.play()
                ligninTriggerBtn?.addEventListener('click', () => {
                    this.ligninButtonHandler()
                    if (ligninTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(ligninTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (ligninTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = ligninTriggerBtn
                    }
                });
                const topTriggerBtn = poolButtons.requestEntity()
                topTriggerBtn?.setAttribute('position', '0 9.5 0.3')
                topTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        topTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        topTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 2) {
                        topTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 3) {
                        topTriggerBtn?.setAttribute('scale', '0 0 0')
                    }

                });
                topTriggerBtn?.play()
                topTriggerBtn?.addEventListener('click', () => {
                    this.topButtonHandler()
                    if (topTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(topTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (topTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = topTriggerBtn
                    }
                });
                const xylemTriggerBtn = poolButtons.requestEntity()
                xylemTriggerBtn?.setAttribute('position', '1.4 8 0.3')
                xylemTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        xylemTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        xylemTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 2) {
                        xylemTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 3) {
                        xylemTriggerBtn?.setAttribute('scale', '0 0 0')
                    }

                });
                xylemTriggerBtn?.play()
                xylemTriggerBtn?.addEventListener('click', () => {
                    this.xylemButtonHandler()
                    if (xylemTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(xylemTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (xylemTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = xylemTriggerBtn
                    }
                });
            };
            const initialisePhloemButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolPhloemCellEntity.components['pool'] as PoolComponent;
                const sievePlateTriggerBtn = poolButtons.requestEntity()
                sievePlateTriggerBtn?.setAttribute('position', '0 7 0.4')
                sievePlateTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        sievePlateTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        sievePlateTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 2) {
                        sievePlateTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 3) {
                        sievePlateTriggerBtn?.setAttribute('scale', '2 2 2')
                    }

                });
                sievePlateTriggerBtn?.play()
                sievePlateTriggerBtn?.addEventListener('click', () => {
                    this.sievePlateButtonHandler()
                    if (sievePlateTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(sievePlateTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (sievePlateTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = sievePlateTriggerBtn
                    }
                });
                const companionCellsTriggerBtn = poolButtons.requestEntity()
                companionCellsTriggerBtn?.setAttribute('position', '0.25 9.2 0.4')
                companionCellsTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        companionCellsTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        companionCellsTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 2) {
                        companionCellsTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 3) {
                        companionCellsTriggerBtn?.setAttribute('scale', '2 2 2')
                    }

                });
                companionCellsTriggerBtn?.play()
                companionCellsTriggerBtn?.addEventListener('click', () => {
                    this.companionCellsButtonHandler()
                    if (companionCellsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(companionCellsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (companionCellsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = companionCellsTriggerBtn
                    }
                });
                const phloemCellTriggerBtn = poolButtons.requestEntity()
                phloemCellTriggerBtn?.setAttribute('position', '1.4 8 0')
                phloemCellTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {

                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        phloemCellTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        phloemCellTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 2) {
                        phloemCellTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 3) {
                        phloemCellTriggerBtn?.setAttribute('scale', '2 2 2')
                    }

                });
                phloemCellTriggerBtn?.play()
                phloemCellTriggerBtn?.addEventListener('click', () => {
                    this.phloemCellButtonHandler()
                    if (phloemCellTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(phloemCellTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (phloemCellTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = phloemCellTriggerBtn
                    }
                });
            };
        },
        tick(this: ICellsSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
            if (this.rootHairMixer) {
                this.rootHairMixer.update(deltaTime * 0.001);
            }
            if (this.xylemMixer) {
                this.xylemMixer.update(deltaTime * 0.001);
            }
            if (this.phloemMixer) {
                this.phloemMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {CellScene as CellSceneComponent}
