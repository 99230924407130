import * as AFrame from 'aframe';
import { IShaderFireAframe } from './shader-fire';

interface IHouseControl {
    el: AFrame.Entity;
}

const LessonStart = {
    name: 'lesson-start',
    val: {
        init(this: IHouseControl) {
            // default aframe loader not working
            //loading from here
            // const burner = document.getElementById('burner') as AFrame.Entity;
            // const burnerModelUrl = require('../../../assets/models/BunsenBurner.glb');
            // // burner.setAttribute('gltf-model', 'https://bridgear.blob.core.windows.net/public/ModelHouse_AR_with_Animations.glb');
            // burner.setAttribute('gltf-model', burnerModelUrl);
            // // Add 'model-loaded' event listener to the component
            // burner.addEventListener('model-loaded', () => {
            //     console.log('Burner loaded');
            // });
// 
            // //loading the bottle model
            // const bottle = document.getElementById('bottle') as AFrame.Entity;
            // const bottleModelUrl = require('../../../assets/models/SprayBottle.glb');
            // // burner.setAttribute('gltf-model', 'https://bridgear.blob.core.windows.net/public/ModelHouse_AR_with_Animations.glb');
            // bottle.setAttribute('gltf-model', bottleModelUrl);
            // // Add 'model-loaded' event listener to the component
            // bottle.addEventListener('model-loaded', () => {
            //     console.log('Bottle loaded');
            // });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('Chem 1 Lesson-start.tsx: lesson started')
                // remove tap place
                const ring = document.getElementById('ring') as AFrame.Entity;
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }

            
                // set the burner
                const burner = document.getElementById('burner') as AFrame.Entity;
                // check if burner has fire component assigned
                if (burner.hasAttribute('shader-fire')) {
                    const burner = document.getElementById('burner') as AFrame.Entity;
                    const fire = burner.components['shader-fire'] as unknown as IShaderFireAframe;
                    fire.setVisibility(true);
                } else {
                    burner.setAttribute('shader-fire', '');
                }
                
                
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: burner; scale: 35 35 35; relativeRotation: 0 60 0;');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // set the burner
                    const burner = document.getElementById('burner') as AFrame.Entity;
                    const fire = burner.components['shader-fire'] as unknown as IShaderFireAframe;
                    fire.setVisibility(false);
                    
                }
            });

          

            
            
            
                
                
            
        },
    },
};
export { LessonStart as LessonStartComponent }