import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    ammText: AFrame.Entity;
    voltText: AFrame.Entity;
    currentDeactivatedButton: AFrame.Entity;
    poolEntity: AFrame.Entity;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    findingTheEMFHandler: () => void;
    EMFHandler: () => void;
    terminalHandler: () => void;
    resistanceHandler: () => void;
    resistorHandler: () => void;
    el: AFrame.Entity;
    circuit: AFrame.Entity;
    slider: THREE.Mesh;
    prevCircuit: THREE.Mesh;
    sliderValue: string;
    distanceEvent: CustomEvent;
    screensTimeout: NodeJS.Timeout;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            const circuit = document.getElementById('circuit') as AFrame.Entity;
            circuit.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                setVoltmeterDigits()
                setAmmeterDigits()
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                    const notebookCircles = circuit.object3D.getObjectByName(`NotebookCircles`) as THREE.Object3D
                    this.slider = this.el.object3D.getObjectByName(`Slider`) as THREE.Mesh

                    notebookCircles.traverse((circle) => {
                        if (circle.name !== 'NotebookCircle_(1)' && circle.name !== 'NotebookCircles') {
                            circle.visible = false
                        }
                    })

                }
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: circuit; scale: 15 15 15; offset: 0 0 -4');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    this.el.sceneEl?.appendChild(ring);
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });

            this.el.sceneEl?.addEventListener('slider-move', (e) => {
                const ce = e as CustomEvent;
                this.sliderValue = ce.detail.value;
                console.log(this.slider)
                if (this.slider) {
                    clearTimeout(this.screensTimeout)
                    switch (parseInt(this.sliderValue)) {
                        case 0:
                            this.slider.position.x = -0.029
                            updateCircuitDynamicValues('0.5', '5.9', '1')
                            break;
                        case 1:
                            this.slider.position.x = -0.017
                            updateCircuitDynamicValues('1.0', '5.8', '2')
                            break;
                        case 2:
                            this.slider.position.x = -0.006
                            updateCircuitDynamicValues('1.5', '5.7', '3')
                            break;
                        case 3:
                            this.slider.position.x = 0.006
                            updateCircuitDynamicValues('2.0', '5.6', '4')
                            break;
                        case 4:
                            this.slider.position.x = 0.017
                            updateCircuitDynamicValues('2.5', '5.5', '5')
                            break;
                        case 5:
                            this.slider.position.x = 0.0299
                            updateCircuitDynamicValues('3.0', '5.4', '6')
                            break;
                    }
                }

            });
            this.findingTheEMFHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Finding the E.m.f';
                    const body = '<span style="font-size: 14px">To measure the e.m.f of a battery, plot a graph of the terminal potential difference against the current through the battery . We can vary this in the circuit using a variable resistor. The line of best fit’s y intercept shows the e.m.f. The magnitude of the line of best fit’s gradient is the internal resistance.</span>';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.EMFHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'E.m.f';
                    const body = 'The electromotive force (e.m.f) is the maximum voltage of a battery, the potential energy given to each coulomb of charge. Due to internal resistance, the voltage over the cell (the terminal potential difference) is always slightly less than the e.m.f of the cell.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.terminalHandler = () => {
                if (this.onObjectSelected) {
                    const title = '<span style="font-size: 20px">Terminal Potential Difference</span>';
                    const body = 'The terminal potential difference is what is left of the battery’s e.m.f after it has overcome internal resistance; it is the useful voltage of the battery. We measure this with a voltmeter connected in parallel to the battery.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.resistanceHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Resistance';
                    const body = 'Inside all conductors, flowing electrons collide with ions and transfer their potential energy to the environment as heat; this even happens inside a cell. The resistance inside a cell is known as internal resistance.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.resistorHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Variable Resistor';
                    const body = 'A variable resistor allows us to make quick changes to the current and in the circuit by changing the resistance. This changes the lost volts inside the cell. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const findingTheEMFTriggerBtn = poolButtons.requestEntity()
                findingTheEMFTriggerBtn?.setAttribute('position', '0.4 0.05 0.22')
                findingTheEMFTriggerBtn?.setAttribute('scale', '2 2 2')
                findingTheEMFTriggerBtn?.play()
                findingTheEMFTriggerBtn?.addEventListener('click', () => {
                    this.findingTheEMFHandler()
                    if (findingTheEMFTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(findingTheEMFTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (findingTheEMFTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = findingTheEMFTriggerBtn
                    }
                });
                const EMFTriggerButton = poolButtons.requestEntity();
                EMFTriggerButton?.setAttribute('position', '0 0.03 -0.1');
                EMFTriggerButton?.setAttribute('scale', '2 2 2')
                EMFTriggerButton?.play()
                EMFTriggerButton?.addEventListener('click', () => {
                    this.EMFHandler()
                    if (EMFTriggerButton) {
                        this.annotationComponent.setObjectToFollow(EMFTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (EMFTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = EMFTriggerButton

                    }
                });
                const terminalTriggerButton = poolButtons.requestEntity();
                terminalTriggerButton?.setAttribute('position', '-0.1 0.05 0.2');
                terminalTriggerButton?.setAttribute('scale', '2 2 2')
                terminalTriggerButton?.play()
                terminalTriggerButton?.addEventListener('click', () => {
                    this.terminalHandler()
                    if (terminalTriggerButton) {
                        this.annotationComponent.setObjectToFollow(terminalTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (terminalTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = terminalTriggerButton

                    }
                });
                const resistanceTriggerButton = poolButtons.requestEntity();
                resistanceTriggerButton?.setAttribute('position', '-0.065 0.03 0.1');
                resistanceTriggerButton?.setAttribute('scale', '2 2 2')
                resistanceTriggerButton?.play()
                resistanceTriggerButton?.addEventListener('click', () => {
                    this.resistanceHandler()
                    if (resistanceTriggerButton) {
                        this.annotationComponent.setObjectToFollow(resistanceTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (resistanceTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = resistanceTriggerButton

                    }
                });
                const resistorTriggerButton = poolButtons.requestEntity();
                resistorTriggerButton?.setAttribute('position', '0.05 0.05 0.01');
                resistorTriggerButton?.setAttribute('scale', '2 2 2')
                resistorTriggerButton?.play()
                resistorTriggerButton?.addEventListener('click', () => {
                    this.resistorHandler()
                    if (resistorTriggerButton) {
                        this.annotationComponent.setObjectToFollow(resistorTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (resistorTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = resistorTriggerButton

                    }
                });
            };
            const setVoltmeterDigits = () => {
                if (!this.voltText) {
                    const voltText = document.createElement('a-entity')
                    voltText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    voltText.setAttribute('position', '0.626 0.025 0.174');
                    voltText.setAttribute('scale', '0.1 0.1 0.1');
                    voltText.setAttribute('rotation', '-90 0 0');
                    voltText.setAttribute('visible', true);
                    this.el.appendChild(voltText);
                    this.voltText = voltText;
                }
            }
            const setAmmeterDigits = () => {
                if (!this.ammText) {
                    const ammText = document.createElement('a-entity')
                    ammText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    ammText.setAttribute('position', '0.315 0.025 0.174');
                    ammText.setAttribute('scale', '0.1 0.1 0.1');
                    ammText.setAttribute('rotation', '-90 0 0');
                    ammText.setAttribute('visible', true);
                    this.el.appendChild(ammText);
                    this.ammText = ammText;
                }
            }
            const setNotebookCircleVisible = (index: string) => {
                const circuit = document.getElementById('circuit') as AFrame.Entity;
                const notebookCircles = circuit.object3D.getObjectByName(`NotebookCircle_(${index})`) as THREE.Object3D
                if (this.prevCircuit) {
                    this.prevCircuit.visible = false
                }
                if (notebookCircles) {
                    notebookCircles.visible = true
                    this.prevCircuit = notebookCircles as THREE.Mesh;
                }
            }
            const updateCircuitDynamicValues = (voltValue: string, ammValue: string, index: string) => {
                setNotebookCircleVisible('0')
                this.ammText.setAttribute('text', "value:; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                this.ammText.setAttribute('position', '0.315 0.025 0.174');
                this.voltText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                this.voltText.setAttribute('position', '0.626 0.025 0.174');
                this.screensTimeout = setTimeout(() => {
                    setNotebookCircleVisible(index)
                    this.ammText.setAttribute('text', `value: ${ammValue}; color: #8ad1a9; align: left; width: 10; wrap-count: 50;`);
                    this.ammText.setAttribute('position', '0.315 0.025 0.174');
                    this.voltText.setAttribute('text', `value: ${voltValue}; color: #8ad1a9; align: left; width: 10; wrap-count: 50;`);
                    this.voltText.setAttribute('position', '0.626 0.025 0.174');
                }, 500)
            }
        },
        tick(this: ICircuitControl, time: number, deltaTime: number) {
            const ring = document.getElementById('ring')
            if (!ring) {
                const circuit = document.getElementById('circuit') as AFrame.Entity;
                const cameraEl = this.el.sceneEl?.querySelector('a-camera');
                if (cameraEl && circuit) {
                    const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                    let cameraPosition = camPos.clone()
                    let spherePos = circuit.object3D.position.clone()
                    let distance = cameraPosition.distanceTo(spherePos)
                    if (!this.distanceEvent) {
                        this.distanceEvent = new CustomEvent('distance-change', {detail: {value: false}});
                    }
                    if ((distance < 6 && cameraPosition.x < 3) || (distance < 8 && cameraPosition.x > 3) || (distance < 10 && cameraPosition.x > 5)) {
                        console.log(distance)
                        console.log(cameraPosition.x)
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = true
                        scene.emit('distance-change', this.distanceEvent.detail.value);
                        circuit.object3D.visible = false
                    } else {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = false
                        scene.emit('distance-change', this.distanceEvent.detail.value);
                        if (!circuit.object3D.visible) {
                            circuit.object3D.visible = true
                        }
                    }
                }
            }
        },
    },
};
export {CircuitControlComponent}
