import { AFrame } from "aframe";
import * as THREE from "three";

interface RainAframe {
    myMaterial: THREE.MeshBasicMaterial;
    rainShader: THREE.ShaderMaterial;
    el: AFrame['AEntity'];
}

const RainComponent = {
    name: "rain",
    val: {
        init(this: RainAframe) {
            this.el.addEventListener('model-loaded', () => {
                const textureAnimation = () => {
                    const modelRain = document.getElementById('modelRain') as AFrame['AEntity'];
                    const backgroud = modelRain.object3D.getObjectByName('RainPlane') as THREE.Mesh;

                    const mat = backgroud.material as THREE.MeshBasicMaterial;
                    mat.depthWrite = true;
                    mat.alphaTest = 0.5;
                    const offset = Math.random();
                    mat.userData.uniforms = { u_time: { value: 0 }, u_offset: { value: offset } };

                    mat.onBeforeCompile = (shader) => {
                        shader.uniforms.u_time = mat.userData.uniforms.u_time;
                        shader.uniforms.u_offset = mat.userData.uniforms.u_offset;
                        shader.fragmentShader = 'uniform float u_time;\nuniform float u_offset;\n' + shader.fragmentShader;
                        shader.fragmentShader = shader.fragmentShader.replace(
                            '#include <map_fragment>',
                            `
                                vec2 animatedUv = vec2(vUv.x, vUv.y - u_time * 0.0012);
                                vec4 mapColor = texture2D( map, animatedUv );
                                diffuseColor *= mapColor;
                                `
                        );
                    };

                    backgroud.material = mat;
                    backgroud.material.needsUpdate = true;
                    this.myMaterial = mat
                    console.log('background new', backgroud)
                    console.log('material new', backgroud.material)         
                }
                textureAnimation();
            });
        },
        tick(this: RainAframe, time: number, timeDelta: number) {
            if (this.myMaterial) {
                this.myMaterial.userData.uniforms.u_time.value = time;
            }
        },
    },
};
export { RainComponent as ShaderRainComponent }