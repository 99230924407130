import React, {useRef, useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {AnnotationState} from '../../../lib/aframe/components/annotation';
import {Annotation} from '../../../lib/aframe/components/annotation';
import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import {WorldButtonComponent} from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {AtomScene} from './atom-scene';
import h2 from '../../../assets/img/UI_H2.png'
import na from '../../../assets/img/UI_Na.png'

import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
    PLAY_HINT,
} from 'shared/constants/hint-items';
import {TapPlaceAir} from 'lib/aframe/components/tap-place-air';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText = 'Investigate how halogens react with metals and non-metals, and what products are made. Use the side menu to navigate through the different reactants and tap the annotation (◉) to see how they react.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, PLAY_HINT];

const ChemistryReactionsOfHalogensAframe = () => {
    const [selectedObject, setSelectedObject] = useState<{
        title: string;
        body: string;
    } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(
        AnnotationState.None
    );
    const [lessonStarted, setLessonStarted] = useState(false);
    const assetIcons = [h2, na]
    const [assetId, setAssetId] = useState(0);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

    const changeAsset = (assetId: number) => {
        setAnimToggle(true)
        setAssetId(assetId);
    };
    const assetChangeEventRef = useRef(
        new CustomEvent('asset-change', {detail: {assetId: 0}})
    );
    React.useEffect(() => {
        // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            console.log('Scene loaded');
            // Update the assetChangeEvent detail before dispatching
            assetChangeEventRef.current.detail.assetId = assetId;
            scene.dispatchEvent(assetChangeEventRef.current);
        }
    }, [assetId]);
    const [animToggle, setAnimToggle] = useState(true);

    const onLessonStarted = () => {
        setLessonStarted(true);
    };
    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };
    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };

    const onToggleAnimation = () => {
        setAnimToggle(!animToggle);
    };
    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };

    const annotationStateEventRef = React.useRef(new CustomEvent('annotation-state-change', {detail: {annotationState: annotationState}}));
    React.useEffect(() => {
        if (annotationState !== undefined) {
            const scene = document.querySelector('a-scene');
            if (scene) {
                annotationStateEventRef.current.detail.annotationState = annotationState;
                scene.dispatchEvent(annotationStateEventRef.current);
            }
        }
        if(annotationState === 1 && !animToggle)setAnimToggle(true);
    }, [annotationState]);

    const animToggleEventRef = useRef(
        new CustomEvent('anim-toggle', {detail: {toggle: false}})
    );
    React.useEffect(() => {
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            animToggleEventRef.current.detail.toggle = animToggle;
            scene.dispatchEvent(animToggleEventRef.current);
        }
    }, [animToggle]);
    const onLessonRecenter = () => {
        setLessonStarted(false);
        setAnimToggle(true)
    };
    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                lessonStarted={lessonStarted}
                assetIcons={assetIcons}
                onAssetButtonClick={changeAsset}
                onAnnotationStateChanged={onAnnotationStateChanged}
                onToggleAnimation={onToggleAnimation}
                toggleAnimationStatus={animToggle}
                hintItems={hintItems}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./chemistry-reactions-of-halogens-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    TapPlaceAir,
                    WorldButtonComponent,
                    FaceCameraComponent,
                    AtomScene,
                    Annotation,
                ]}
                onObjectSelected={onObjectSelected}
                onLessonStart={onLessonStarted}
                onLessonRecenter={onLessonRecenter}
                annotationState={annotationState}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>
    );
};
export {ChemistryReactionsOfHalogensAframe};
