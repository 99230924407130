import { Box, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useData, useHandlers } from './hooks';
import {
  TrainingTypeEnum,
  TrainingStatusEnum,
  TrainingLevelEnum,
} from '../../utils/enums';
import { useParams } from 'react-router-dom';
import { CourseStatisticItem } from './CourseStatisticItem';
import { SharedSearch } from '../../../../shared/components/SharedSearch';
import { DisplayEnum } from '../../../../shared/enums';
import { useTranslation } from 'react-i18next';
import { useTrainingsContext } from 'features/Сourses/context';

export function CourseStatistic() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const {
    newTrainings,
    all,
    inProgress,
    completed,
    filterStatus,
    filterType,
    filterLevel,
    physics,
    chemistry,
    biology,
    elementarySchool,
    highSchool,
    middleSchool,
  } = useData();
  const { id } = useParams<{ id: string }>();
  const {
    handleSearchChange,
    handleStatusChange,
    handleTypeChange,
    handleLevelChange,
  } = useHandlers(id || '');
  const { t, i18n } = useTranslation();
  const [{ filters }] = useTrainingsContext();
  const i18nPath = 'COURSES_WRAPPER_COMPONENT.COURSES_STATIC_COMPONENT.';

  return (
    <Stack
      width='100%'
      maxWidth={mobile ? '100%' : 308}
      bgcolor='#F1F1F1'
      borderRadius='10px'
      padding='20px'
    >
      <SharedSearch
        onChange={handleSearchChange}
        name='courses'
        placeholder={t(i18nPath + 'SEARCH') || ''}
        id='courses'
        inputValue={filters?.Search}
      />
      <Stack mt={4}>
        {/* <Stack>
          <Typography fontFamily='GilroySemiBold' color='#262626' mb={1.75}>
            STATUS
          </Typography>
          <CourseStatisticItem
            title='All'
            value={all}
            isActive={!filterStatus || filterStatus === TrainingStatusEnum.All}
            handleStatusChange={() =>
              handleStatusChange(TrainingStatusEnum.All)
            }
          />
          <CourseStatisticItem
            title='New'
            value={newTrainings}
            isActive={filterStatus === TrainingStatusEnum.New}
            handleStatusChange={() =>
              handleStatusChange(TrainingStatusEnum.New)
            }
          />
          <CourseStatisticItem
            title='In Progress'
            value={inProgress}
            isActive={filterStatus === TrainingStatusEnum.InProgress}
            handleStatusChange={() =>
              handleStatusChange(TrainingStatusEnum.InProgress)
            }
          />
          <CourseStatisticItem
            title='Completed'
            value={completed}
            isActive={filterStatus === TrainingStatusEnum.Completed}
            handleStatusChange={() =>
              handleStatusChange(TrainingStatusEnum.Completed)
            }
          />
          <Box border='1px solid #D2D2D2' mt={1.7} mb={2}></Box>
        </Stack> */}
        <Stack>
          <Typography fontFamily='GilroySemiBold' color='#262626' mb={1.75}>
            {t(i18nPath + 'TYPES')}
          </Typography>
          <CourseStatisticItem
            title={t(i18nPath + TrainingTypeEnum.All)}
            value={all}
            isActive={!filterType || filterType === TrainingTypeEnum.All}
            handleStatusChange={() => handleTypeChange()}
          />
          <CourseStatisticItem
            title={t(i18nPath + TrainingTypeEnum.Physics)}
            value={physics}
            isActive={filterType === TrainingTypeEnum.Physics}
            handleStatusChange={() =>
              handleTypeChange(TrainingTypeEnum.Physics)
            }
          />
          <CourseStatisticItem
            title={t(i18nPath + TrainingTypeEnum.Biology)}
            value={biology}
            isActive={filterType === TrainingTypeEnum.Biology}
            handleStatusChange={() =>
              handleTypeChange(TrainingTypeEnum.Biology)
            }
          />
          <CourseStatisticItem
            title={t(i18nPath + TrainingTypeEnum.Chemistry)}
            value={chemistry}
            isActive={filterType === TrainingTypeEnum.Chemistry}
            handleStatusChange={() =>
              handleTypeChange(TrainingTypeEnum.Chemistry)
            }
          />
          <Box border='1px solid #D2D2D2' mt={1.7} mb={2}></Box>
        </Stack>
        <Stack>
          <Typography fontFamily='GilroySemiBold' color='#262626' mb={1.75}>
            {t(i18nPath + 'LEVELS')}
          </Typography>
          <CourseStatisticItem
            title={t(i18nPath + TrainingLevelEnum.All)}
            value={all}
            isActive={!filterLevel || filterLevel === TrainingLevelEnum.All}
            handleStatusChange={() => handleLevelChange()}
          />
          <CourseStatisticItem
            title={t(i18nPath + TrainingLevelEnum.HighSchool)}
            value={highSchool}
            isActive={filterLevel === TrainingLevelEnum.HighSchool}
            handleStatusChange={() =>
              handleLevelChange(TrainingLevelEnum.HighSchool)
            }
          />
          <CourseStatisticItem
            title={t(i18nPath + TrainingLevelEnum.MiddleSchool)}
            value={middleSchool}
            isActive={filterLevel === TrainingLevelEnum.MiddleSchool}
            handleStatusChange={() =>
              handleLevelChange(TrainingLevelEnum.MiddleSchool)
            }
          />
          <CourseStatisticItem
            title={t(i18nPath + TrainingLevelEnum.ElementarySchool)}
            value={elementarySchool}
            isActive={filterLevel === TrainingLevelEnum.ElementarySchool}
            handleStatusChange={() =>
              handleLevelChange(TrainingLevelEnum.ElementarySchool)
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
