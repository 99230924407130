import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';


interface IDNAControl {
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    nucleotides: AFrame.Entity;
    nucleotideTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    phosphateTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    sugarTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    baseTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    bondsTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const DNAControlComponent = {
    name: 'lesson-start',
    val: {
        init(this: IDNAControl) {

            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                this.nucleotides = document.getElementById('nucleotides') as AFrame.Entity;
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: holder; scale: 14 14 14; offset: 0 0 -3');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            const setHightlightEl = (el: string) => {
                const nucleotideHighlights = this.nucleotides.object3D.getObjectByName('Highlights') as THREE.Object3D
                nucleotideHighlights.traverse(child => {
                    if (child.name !== 'Highlights' && el !== 'Highlights') {
                        child.visible = (child.name === el);
                    } else {
                        child.visible = true
                    }
                })
            }

            const dnaButtonHandler = () => {
                const title = '<span style="font-size: 18px">Deoxyribonucleic acid (DNA)</span>'
                const body = '<span style="font-size: 14px">DNA is a chemical molecule in the cells’ nuclei that makes up genes. The structure of DNA is a double helix, it looks like a twisted ladder. It carries genetic information that determines an organism’s characteristics: physical traits, behaviors, susceptibility to certain diseases, etc.</span>'
                this.nucleotideTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                this.phosphateTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                this.sugarTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                this.baseTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                this.bondsTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                this.nucleotides.object3D.visible = true
                if (this.onObjectSelected) {
                    console.log('Calling onObjectSelected callback');
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const nucleotideButtonHandler = () => {
                const title = 'Nucleotide'
                const body = 'A nucleotide is the repeated building block of DNA. It contains a phosphate, a sugar, and a base. This base is different from a chemical base meaning alkaline.'
                setHightlightEl('NucleotidesHighlight')
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const phosphateButtonHandler = () => {
                const title = 'Phosphate'
                const body = 'This is a phosphate group; it is part of the DNA backbone. The phosphate is the same in every DNA nucleotide.'
                setHightlightEl('PhosphateHighlight')
                if (this.onObjectSelected) {
                    console.log('Calling onObjectSelected callback');
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const sugarButtonHandler = () => {
                const title = 'Deoxyribose Sugar'
                const body = 'This is a deoxyribose sugar; it is part of the DNA backbone. The sugar is the same in every DNA nucleotide. '
                setHightlightEl('SugarHighlight')
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const baseButtonHandler = () => {
                const title = 'Nitrogenous Base'
                const body = 'This is a nitrogenous base; it forms the “rungs” of the DNA. There can be one of four different bases in a DNA nucleotide. The bases are adenine (A), guanine (G), cytosine (C), and thymine (T).'
                setHightlightEl('BaseHighlight')
                if (this.onObjectSelected) {
                    console.log('Calling onObjectSelected callback');
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const bondsButtonHandler = () => {
                const title = 'Hydrogen bonds'
                const body = 'Bases are linked through hydrogen bonds. In DNA, adenine (A) is always paired with thymine (T). Guanine is always paired with Cytosine. This is called complementary base pairing. '
                setHightlightEl('BondsHighlight')
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const dnaTriggetBtn = poolButtons.requestEntity();
                dnaTriggetBtn?.setAttribute('position', '-0.05 0.075 0.05');
                dnaTriggetBtn?.setAttribute('scale', '0.2 0.2 0.2');
                dnaTriggetBtn?.play();
                dnaTriggetBtn?.addEventListener('click', () => {
                    dnaButtonHandler();
                    if (dnaTriggetBtn) {
                        this.annotationComponent.setObjectToFollow(dnaTriggetBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (dnaTriggetBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = dnaTriggetBtn
                    }
                });

                this.nucleotideTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.nucleotideTriggerBtn?.setAttribute('position', '0.05 0.18 0.01')
                this.nucleotideTriggerBtn?.setAttribute('scale', '0 0 0');
                this.nucleotideTriggerBtn?.play()
                this.nucleotideTriggerBtn?.addEventListener('click', () => {
                    nucleotideButtonHandler();
                    if (this.nucleotideTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.nucleotideTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.nucleotideTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.nucleotideTriggerBtn
                    }
                });

                this.phosphateTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.phosphateTriggerBtn?.setAttribute('position', '0.02 0 0.01');
                this.phosphateTriggerBtn?.setAttribute('scale', '0 0 0');
                this.phosphateTriggerBtn?.play();
                this.phosphateTriggerBtn?.addEventListener('click', () => {
                    phosphateButtonHandler();
                    if (this.phosphateTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.phosphateTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.phosphateTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.phosphateTriggerBtn
                    }
                });

                this.sugarTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.sugarTriggerBtn?.setAttribute('position', '0.25 0.03 0.01')
                this.sugarTriggerBtn?.setAttribute('scale', '0 0 0');
                this.sugarTriggerBtn?.play()
                this.sugarTriggerBtn?.addEventListener('click', () => {
                    sugarButtonHandler();
                    if (this.sugarTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.sugarTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.sugarTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.sugarTriggerBtn
                    }
                });

                this.baseTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.baseTriggerBtn?.setAttribute('position', '0.2 -0.06 0.01');
                this.baseTriggerBtn?.setAttribute('scale', '0 0 0');
                this.baseTriggerBtn?.play();
                this.baseTriggerBtn?.addEventListener('click', () => {
                    baseButtonHandler();
                    if (this.baseTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.baseTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.baseTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.baseTriggerBtn
                    }
                });

                this.bondsTriggerBtn = poolButtons.requestEntity() as AFrame.Entity
                this.bondsTriggerBtn?.setAttribute('position', '0.15 0.075 0.01')
                this.bondsTriggerBtn?.setAttribute('scale', '0 0 0');
                this.bondsTriggerBtn?.play()
                this.bondsTriggerBtn?.addEventListener('click', () => {
                    bondsButtonHandler();
                    if (this.bondsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.bondsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.bondsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.bondsTriggerBtn
                    }
                });
            }
        },
    },
};
export {DNAControlComponent as WireControl}
