import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Consent } from './part/Consent';
import { Information } from './part/Information';
import { HowWeUse } from './part/HowWeUse';
import { Footer } from '../../../shared/components/Footer';
import { LogFile } from './part/LogFile';
import { WebBeacons } from './part/WebBeacons';
import { AdvertisingPartners } from './part/AdvertisingPartners';
import { ThirdParty } from './part/ThirdParty';
import { CCPA } from './part/CCPA';
import { GDPR } from './part/GDPR';
import { ChildrenInformation } from './part/ChildrenInformation';
import { useTranslation } from 'react-i18next';
import Header from '../../../shared/components/Header';

export function PrivacyPolicyPage() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const i18nPath = 'PRIVACY_POLICY_PAGE.';

  return (
    <Box bgcolor={theme.palette.background.default}>
      <Header />
      <Box maxWidth={1020} marginX='auto'>
        <Stack padding={4.2} spacing={2} justifyContent='center'>
          <Typography
            fontFamily='GilroyBold'
            variant='h3'
            color={theme.palette.text.primary}
          >
            {t(i18nPath + 'TITLE')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_1')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_2')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_3')}
          </Typography>
          <Consent />
          <Information />
          <HowWeUse />
          <LogFile />
          <WebBeacons />
          <AdvertisingPartners />
          <ThirdParty />
          <CCPA />
          <GDPR />
          <ChildrenInformation />
        </Stack>
      </Box>
      <Footer onMainPage={false} />
    </Box>
  );
}
