import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function ThirdParty() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const i18nPath = 'PRIVACY_POLICY_PAGE.THIRD_PARTY.';

  return (
    <>
      <Typography variant='h5' color={theme.palette.text.primary}>
        {t(i18nPath + 'TITLE')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        {t(i18nPath + 'TEXT_1')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        {t(i18nPath + 'TEXT_2')}
      </Typography>
    </>
  );
}
