// AnnotationSystem.tsx
import { System, Entity } from "aframe";

interface IAnnotationSystemState {
  onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

export interface IAnnotationSystemAframe extends System {
  state: IAnnotationSystemState;
  registerOnObjectSelected(
    fn: (selectedObject: { title: string; body: string }) => void
  ): void;
  getObjectSelectedFunction(): ((selectedObject: { title: string; body: string }) => void) | null;
}

// Add this line outside the AnnotationSystem object
const annotationSystemState = {
    onObjectSelected: null,
  };

const AnnotationSystem: { name: string; val: IAnnotationSystemAframe } = {
  name: "annotation-system",
  val: {
    data: {},
    schema: {},
    state: annotationSystemState,
    el: document.createElement('a-entity') as Entity,

    init(): void {
      console.log("Annotation system initialized");
    },

    pause() {},

    play() {},

    registerOnObjectSelected(fn) {
      console.log("Registering onObjectSelected callback: ", fn);
      this.state.onObjectSelected = fn;
      console.log(this.state.onObjectSelected)
    },

    getObjectSelectedFunction() {
      console.log('Current onObjectSelected state:', this.state.onObjectSelected);
      return this.state.onObjectSelected;
    },
  },
};

export { AnnotationSystem };
