import type { State } from './typings';

export const initialState: State = {
  trainingsInfo: {
    all: 0,
    new: 0,
    inProgress: 0,
    completed: 0,
    physics: 0,
    biology: 0,
    chemistry: 0,
    elementarySchool: 0,
    middleSchool: 0,
    highSchool: 0,
    trainings: [],
  },
  filters: { Search: '' },
};
