import React, { useRef, useState } from 'react';
import { AFrameComponent, DISABLE_IMAGE_TARGETS } from 'lib/aframe-component-2';
import { AnnotationState } from '../../../lib/aframe/components/annotation';
import { Annotation } from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import { TapPlaceAir } from 'lib/aframe/components/tap-place-air';
import { SpaceScene } from './space-scene';
import { MethodSystem } from 'lib/aframe/systems/method-system';

import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  PLAY_HINT,
} from 'shared/constants/hint-items';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
  'Explore the annotations to learn about the James Webb Space Telescope and one of its first images.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, PLAY_HINT];

const PhysicsSpecialsFirstAframe = () => {
  const [toggle, setToggle] = useState(false);
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState(false);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const onLessonStarted = () => {
    setLessonStarted(true);
  };
  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };

  const onToggleAnimation = () => {
    setToggle(!toggle);
    // setTimeout(() => {
    //   setToggle(false);
    // }, 11500);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };

  const onDeployTelescopeFinish = () => {
    setToggle(false);
  };

  const onDeployTelescopeStart = () => {
    setToggle(true);
  };

  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };

  const animToggleEventRef = useRef(
    new CustomEvent('anim-toggle', { detail: { toggle: false } })
  );
  React.useEffect(() => {
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      animToggleEventRef.current.detail.toggle = toggle;
      scene.dispatchEvent(animToggleEventRef.current);
    }
  }, [toggle]);

  const annotationStateEventRef = React.useRef(new CustomEvent('annotation-state-change', { detail: { annotationState: annotationState } }));

  React.useEffect(() => {
    if (annotationState !== undefined) {
      const scene = document.querySelector('a-scene');
      if (scene) {
        annotationStateEventRef.current.detail.annotationState = annotationState;
        scene.dispatchEvent(annotationStateEventRef.current);
      }
    }
  }, [annotationState]);


  // these are callbacks passed to aframe using methodSystem
  // these are accesed in aframe using associated string keys  
  const systemFunctions = {
    'onLessonStart': onLessonStarted,
    'onObjectSelected': onObjectSelected,
    'onFinishDeploy' : onDeployTelescopeFinish,
    'onStartDeploy': onDeployTelescopeStart,
  };

  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        lessonStarted={lessonStarted}
        onToggleAnimation={onToggleAnimation}
        toggleAnimationStatus={toggle}
        onAnnotationStateChanged={onAnnotationStateChanged}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameComponent
        sceneHtml={require('./physics-specials-first-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          TapPlaceAir,
          WorldButtonComponent,
          FaceCameraComponent,
          SpaceScene,
          Annotation,
        ]}
        systems={[MethodSystem]}
        systemFunctions={systemFunctions}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { PhysicsSpecialsFirstAframe };
