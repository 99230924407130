import React from 'react';
import { Box } from '@mui/material';
import { IconImage } from 'shared/components/IconImage';

import wallLogo from '../../../../assets/wallLogo.svg';

const WallLogo: React.FC<any> = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '10%',
      }}
    >
      <IconImage src={wallLogo} alt='8th Wall Logo' />
    </Box>
  );
};

export default WallLogo;
