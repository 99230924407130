import { Box, styled } from '@mui/material';
import { DisplayEnum } from 'shared/enums';

import Background from '../../../assets/headerBG.png';

export const HeaderBG = styled(Box, { name: 'HeaderBG' })({
  width: '100%',
  maxWidth: '1440px',
  margin: '0 auto',
  backgroundImage: `url('${Background}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100% -10px',
  backgroundSize: '300px 318px',
  position: 'relative',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
    backgroundImage: 'none',
    maxWidth: '100%',
  },
});
