import React, { useRef, useState } from 'react';
import { AFrameComponent, DISABLE_IMAGE_TARGETS } from 'lib/aframe-component-2';
import { AnnotationState } from '../../../lib/aframe/components/annotation';
import { Annotation } from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import { AtomScene } from './atom-scene';
import { MethodSystem } from 'lib/aframe/systems/method-system';

import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  PLAY_HINT,
} from 'shared/constants/hint-items';
import { TapPlaceAir } from 'lib/aframe/components/tap-place-air';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText = 'Interact with the 3D atom to learn more about its particles.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, PLAY_HINT];

const PhysicsModernAtomAframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState(false);

  const [animToggle, setAnimToggle] = useState(true);

  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const onLessonStarted = () => {
    setLessonStarted(true);
  };
  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };
  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };


  const onToggleAnimation = () => {
    setAnimToggle(!animToggle);
    // setTimeout(() => {
    //   setToggle(false);
    // }, 11500);
  };


  const annotationStateEventRef = React.useRef(new CustomEvent('annotation-state-change', { detail: { annotationState: annotationState } }));
  React.useEffect(() => {
    if (annotationState !== undefined) {
      const scene = document.querySelector('a-scene');
      if (scene) {
        annotationStateEventRef.current.detail.annotationState = annotationState;
        scene.dispatchEvent(annotationStateEventRef.current);
      }
    }
  }, [annotationState]);

  const animToggleEventRef = useRef(
    new CustomEvent('anim-toggle', { detail: { toggle: false } })
  );
  React.useEffect(() => {
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      animToggleEventRef.current.detail.toggle = animToggle;
      scene.dispatchEvent(animToggleEventRef.current);
    }
  }, [animToggle]);


  // these are callbacks passed to aframe using methodSystem
  // these are accesed in aframe using associated string keys  
  const systemFunctions = {
    'onLessonStart': onLessonStarted,
    'onObjectSelected': onObjectSelected,
  };

  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        lessonStarted={lessonStarted}
        onAnnotationStateChanged={onAnnotationStateChanged}
        onToggleAnimation={onToggleAnimation}
        toggleAnimationStatus={animToggle}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameComponent
        sceneHtml={require('./physics17-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          TapPlaceAir,
          WorldButtonComponent,
          FaceCameraComponent,
          AtomScene,
          Annotation,
        ]}
        systems={[MethodSystem]}
        systemFunctions={systemFunctions}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { PhysicsModernAtomAframe };
