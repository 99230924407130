export const vertexShader = `
varying vec2 vUv;

void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

export const fragmentShader = `
varying vec2 vUv;
uniform sampler2D u_texture;
uniform float u_time;

void main() {
  vec2 uv = vUv;
  uv.y-=u_time;
  uv.x*=4.0;

  gl_FragColor = texture2D(u_texture, uv);
}
`