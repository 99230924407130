import * as AFrame from "aframe";
import * as THREE from "three";

interface IMaskedAnimationAframe {
    el: AFrame.Entity;
    mixer: THREE.AnimationMixer;
}

const MaskedAnimationAframe = {
    name: "masked-animation",
    val: {
        init(this: IMaskedAnimationAframe) {
            this.el.addEventListener("model-loaded", (e: Event) => {
                playAnimations();
                
                e.stopPropagation();
            });



            const playAnimations = () => {
                // animate model
                
                const animatedEl = this.el.object3D.getObjectByName('Scene');
                if (animatedEl) {
                    this.mixer = new THREE.AnimationMixer(animatedEl);
                    for (let i = 0; i < animatedEl.animations.length; i++) {
                        const clip = animatedEl.animations[i];
                        const action = this.mixer.clipAction(clip);
                        if (i <= 5) {
                            action.timeScale = 0.1;
                        }
                        action.play();
                    }
                }
            };
        },
        tick(this: IMaskedAnimationAframe, time: number, timeDelta: number) {
            if (this.mixer) {
                this.mixer.update(timeDelta / 1000);
            };

        },
    },
};
export { MaskedAnimationAframe as MaskedAnimation};