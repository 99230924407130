import * as AFrame from 'aframe';
import * as THREE from 'three';

interface IAtomsSceneAframe {
    mixer: THREE.AnimationMixer;
    atomAnimation: THREE.AnimationAction;
    state1Animation: THREE.AnimationAction;
    state2Animation: THREE.AnimationAction;
    state3Animation: THREE.AnimationAction;
    state4Animation: THREE.AnimationAction;
    state5Animation: THREE.AnimationAction;
    state6Animation: THREE.AnimationAction;
    prevAnimation: THREE.AnimationAction;
    el: AFrame.Entity;
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: IAtomsSceneAframe) {
            const atomHolder = document.getElementById('atom') as AFrame.Entity
            atomHolder.addEventListener('model-loaded', () => {
                const atom = atomHolder.object3D.getObjectByName('LithiumAtom') as THREE.Object3D
                atom.rotation.set(-90, 0, 0)
                const animatedEl = atomHolder.object3D.getObjectByName('Physics19_NuclideNotation') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [LithiumRotate, State1,State3,State4,State5,State6,State2] = animatedEl.animations
                this.atomAnimation = this.mixer.clipAction(LithiumRotate)
                this.state1Animation = this.mixer.clipAction(State1)
                this.state2Animation = this.mixer.clipAction(State2)
                this.state3Animation = this.mixer.clipAction(State3)
                this.state4Animation = this.mixer.clipAction(State4)
                this.state5Animation = this.mixer.clipAction(State5)
                this.state6Animation = this.mixer.clipAction(State6)
                this.atomAnimation.play()
                this.state1Animation.play()
            });
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
            });
            this.el.sceneEl?.addEventListener('next-slide', (e) => {
                const ce = e as CustomEvent;
                let count = ce.detail.title + 1
                if (count === 6) count = 0
                if (this.prevAnimation) {
                    this.prevAnimation.stop()
                }
                switch (count){
                    case 0:
                        this.state6Animation.stop()
                        this.state1Animation.play()
                        break;
                    case 1:
                        this.state1Animation.stop()
                        this.state2Animation.play()
                        break;
                    case 2:
                        this.state2Animation.stop()
                        this.state3Animation.play()
                        break;
                    case 3:
                        this.state3Animation.stop()
                        this.state4Animation.play()
                        break;
                    case 4:
                        this.state4Animation.stop()
                        this.state5Animation.play()
                        break;
                    case 5:
                        this.state5Animation.stop()
                        this.state6Animation.play()
                        break;
                }
            });
            this.el.sceneEl?.addEventListener('prev-slide', (e) => {
                const ce = e as CustomEvent;
                let count = ce.detail.title - 1
                if (count === -1) count = 5
                if (this.prevAnimation) {
                    this.prevAnimation.stop()
                }
                switch (count){
                    case 0:
                        this.state2Animation.stop()
                        this.state1Animation.play()
                        break;
                    case 1:
                        this.state3Animation.stop()
                        this.state2Animation.play()
                        break;
                    case 2:
                        this.state4Animation.stop()
                        this.state3Animation.play()
                        break;
                    case 3:
                        this.state5Animation.stop()
                        this.state4Animation.play()
                        break;
                    case 4:
                        this.state6Animation.stop()
                        this.state5Animation.play()
                        break;
                    case 5:
                        this.state1Animation.stop()
                        this.state6Animation.play()
                        break;
                }
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: atom; scale: 10 10 10; offset: -2 -4 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                }
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                this.atomAnimation.paused = !!animToggle;
            });

        },
        tick(this: IAtomsSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {AtomsScene as AtomsSceneComponent}
