// src/components/IconPanel.tsx
import React, { useState } from 'react';
import '../styles/iconPanel.css';
import AssetIconListComponent from './asset-icon-list';
import AssetToggleListComponent from './asset-toggle-list';

interface IconPanelProps {
  icons: string[];
  onRecenterIconClick: () => void;
  onQuizIconClick: () => void;
  assetSelectionActive: boolean;
  onAssetSelectionToggle: () => void;
  onAssetButtonClick?: (assetId: number) => void; // if this method is defined, renderes an asset selection list in the icon panel
  onAssetToggleClick?: (assetId: number) => void; // if this prop is provided, renderes an asset toggle selection list in the icon panel
  onToggleAnimation?: (toggle: boolean) => void
  onSceneButtonClick?: () => void;
  lessonStarted?: boolean;
  
}

const IconPanel: React.FC<IconPanelProps> = ({ icons, onRecenterIconClick, onToggleAnimation, onQuizIconClick, assetSelectionActive, onAssetSelectionToggle, onSceneButtonClick, onAssetButtonClick, onAssetToggleClick, lessonStarted }) => {
  const [assetSelectionOn, setAssetSelectionOn] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(0);
  const [toggle, setToggle] = useState(false);
  const handleAssetSelectionToggle = () => {
    // setAssetSelectionOn(!assetSelectionOn);
    onAssetSelectionToggle();
  };
  const handleSceneButtonClick = () => {
    // setAssetSelectionOn(!assetSelectionOn);
    if (onSceneButtonClick) {onSceneButtonClick()};
  };
  const handleToggleAnimation = () => {
      setToggle(!toggle)
    if (onToggleAnimation) {
      onToggleAnimation(toggle)
    }
  }
  const handleAssetButtonClick = (assetId: number) => {
    if (onAssetButtonClick && lessonStarted) {
      setToggle(true);
      onAssetButtonClick(assetId);
    }
    setSelectedAsset(assetId);
  };
  const renderIcon = (iconSrc: string, index: number) => {
    let handleClick;
    if (index === 0) {
      handleClick = onRecenterIconClick;
    } else if (index === 1) {
      handleClick = handleToggleAnimation;
    } else if (index === 2) {
      handleClick = onQuizIconClick;
    } else if (index === 3) {
      handleClick = handleAssetSelectionToggle;
    } else if (index === 2) {
      handleClick = handleSceneButtonClick;
    }

    return (
      <div key={index} className="menuItem">
        <img
          src={iconSrc}
          alt={`Icon ${index + 1}`}
          onClick={handleClick}
          style={{ opacity: 1 }}
        />
      </div>
    );
  };

  return (
    <div className='iconMenu'>
    {icons.map((iconSrc, index) => {
      if (index === 0) {
        return renderIcon(iconSrc, index);
      }
      if (index === 1 && onToggleAnimation && lessonStarted) {
        return renderIcon(iconSrc, index);
      }
      if (index === 2) {
        return renderIcon(iconSrc, index);
      }
      if (index === 3 && (onAssetButtonClick || onAssetToggleClick || onSceneButtonClick) && lessonStarted) {
        return renderIcon(iconSrc, index);
      }


    })}
    {lessonStarted && onAssetButtonClick && (
      <AssetIconListComponent
        icons={icons.slice(4)}
        onAssetButtonClick={handleAssetButtonClick}
        assetSelectionOn={assetSelectionActive}
        selectedAsset={selectedAsset}
      />
    )}
    {lessonStarted && onAssetToggleClick && (
      <AssetToggleListComponent
        icons={icons.slice(4)}
        onAssetToggleClick={onAssetToggleClick}
        assetSelectionOn={assetSelectionActive}
        selectedAsset={selectedAsset}
      />
    )}
    {lessonStarted && onSceneButtonClick && (
      <AssetToggleListComponent
        icons={icons.slice(4)}
        onAssetToggleClick={onSceneButtonClick}
        assetSelectionOn={assetSelectionActive}
        selectedAsset={selectedAsset}
      />
    )}
  </div>
  );
};

export default IconPanel;
