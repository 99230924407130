export const vertexShader = `
uniform float u_time;
varying vec3 vPos;

vec2 random(vec2 st) {
    st = vec2(dot(st, vec2(127.1, 311.7)), dot(st, vec2(269.5, 183.3)));
    return -1.0 + 2.0 * fract(sin(st) * 43758.5453123);
}

float noise(vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    vec2 u = f * f * (3.0 - 2.0 * f);

    return mix(mix(dot(random(i + vec2(0.0, 0.0)), f - vec2(0.0, 0.0)),
                   dot(random(i + vec2(1.0, 0.0)), f - vec2(1.0, 0.0)), u.x),
               mix(dot(random(i + vec2(0.0, 1.0)), f - vec2(0.0, 1.0)),
                   dot(random(i + vec2(1.0, 1.0)), f - vec2(1.0, 1.0)), u.x), u.y);
}

void main() {
  vPos = position;
  vec3 newPosition = position;
  float waveHeight = 0.1 * 0.2;
  float distortion = noise(position.xz * 5.0 + u_time) * waveHeight;
  newPosition.y += distortion;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);

}
`

export const fragmentShader = `
varying vec3 vPos;

void main() {
    vec3 waterColor = vec3(0.003, 0.322, 0.388);
  
    gl_FragColor = vec4(waterColor, 0.25);
  }
`