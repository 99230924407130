import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CourseCard } from './CourseCard';
import { useTrainingsContext } from '../../context';
import { DisplayEnum } from '../../../../shared/enums';
import { Circles } from 'react-loader-spinner';
import { IconImage } from '../../../../shared/components/IconImage';
import { useTranslation } from 'react-i18next';

import Empty from '../../../../assets/nothing.svg'

type Props = {
  loading: boolean;
};

export function CourseList(props: Props) {
  const { loading } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const [
    {
      trainingsInfo: { trainings },
    },
  ] = useTrainingsContext();
  const { t, i18n } = useTranslation();
  const i18nPath = 'COURSES_WRAPPER_COMPONENT.COURSES_LIST_COMPONENT.';

  return (
    <Stack mt={mobile ? 2 : -2} width={mobile ? '100%' : 'calc(100% - 333px)'} maxWidth={842}>
      {loading && (
        <Stack
          width='100%'
          py={tablet ? '0' : '150px'}
          px={tablet ? '0' : '200px'}
          mx='auto'
          alignItems='center'
          boxSizing='border-box'
        >
          <Circles
            height={120}
            width={120}
            color={theme.palette.grey.A200}
            ariaLabel='loading'
          />
        </Stack>
      )}
      {!loading &&
        (trainings || []).map((training) => (
          <CourseCard
            id={training.id}
            title={training.title}
            description={training.description}
					  imageUrl={training.imageUrl}
					  url={training.url}
					  type={training.type}
					  qr={training.qr}
            level={training.level}
            status={training.status}
						key={training.id}
          />
        ))}
      {!loading && (!trainings || !trainings.length) && (
        <Stack
          width='100%'
          py={tablet ? '0' : '150px'}
          px={tablet ? '0' : '200px'}
					mt={2}
          mx='auto'
          alignItems='center'
          boxSizing='border-box'
        >
          <IconImage src={Empty} alt='no item' width='78px' height='78px' />
          <Typography
            fontSize={28}
            mt={3.6}
            fontFamily='GilroyBold'
            textAlign='center'
            color={theme.palette.grey.A400}
          >
            {t(i18nPath + 'EMPTY_LIST')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
