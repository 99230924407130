import * as AFRAME from 'aframe';

interface IWorldButtonAframe {
    el: AFRAME.Entity;
}


const GradientGlowComponent = {
    name: 'gradient-glow',
    val: {
        init(this: IWorldButtonAframe) {
            this.el.addEventListener('model-loaded', (e) => {
                const plane = this.el.object3D.getObjectByName('GradientPlane');
                plane?.position.set(0, 0, 0);
                //prevent calling on parent elements
                e.stopPropagation();

                const sphre = document.createElement('a-sphere');
                sphre.setAttribute('position', '0 0 0');
                sphre.setAttribute('radius', '0.005');
                sphre.setAttribute('material', 'color: #fff; shader: flat; transparent: true; opacity: 0.5');
                this.el.appendChild(sphre);
            });
        },
    },
};
export { GradientGlowComponent as GradientGlow }