import * as AFrame from 'aframe';
import { Object3D } from 'three';
import * as TWEEN from '@tweenjs/tween.js';

export interface IStateMachine {
    setState(newState: number): void;
    getState(): number;
    nextState(): void;
    state: number;
    el: AFrame.Entity;

};

const StateMachineComponent = {
    name: 'state-machine',
    val: {
        init(this: IStateMachine) {
        },
        setState(this: IStateMachine, newState: number) {
            this.state = newState;
            const solutionA = this.el.object3D.getObjectByName('TestTube_SolutionA') as Object3D;
            const solutionB = this.el.object3D.getObjectByName('TestTube_SolutionB') as Object3D;
            const solutionC = this.el.object3D.getObjectByName('TestTube_SolutionC') as Object3D;

            const liquidB = this.el.object3D.getObjectByName('TubeFluidHCl001') as Object3D;
            const liquidC = this.el.object3D.getObjectByName('TubeFluidHCl002') as Object3D;

            const lwTube2 = this.el.object3D.getObjectByName('LimewaterTube001') as Object3D;
            const lwTube3 = this.el.object3D.getObjectByName('LimewaterTube002') as Object3D;

            // this.el.object3D.traverse((node) => {
            //     console.log(node.name, node);
            // });
            switch (this.state) {
                case 0:
                    solutionB.scale.set(0.01, 0.01, 0.01);
                    solutionC.scale.set(0.01, 0.01, 0.01);
                    
                    lwTube2.scale.set(0.0001, 0.0001, 0.0001);
                    lwTube3.scale.set(0.0001, 0.0001, 0.0001);
                    lwTube2.visible = false;
                    lwTube3.visible = false;

                    liquidB.visible = false;
                    liquidC.visible = false;
                    break;
                case 1:
                    //solutionA.visible = false;
                    new TWEEN.Tween(solutionB.scale)
                        .to({ x: 1, y: 1, z: 1 }, 300)
                        .onComplete(() => {
                            liquidB.visible = true;
                        })
                        .start();
                    lwTube2.visible = true;
                    new TWEEN.Tween(lwTube2.scale)
                        .to({ x: 0.01, y: 0.01, z: 0.01 }, 300)
                        .start();
                   
                    //solutionC.visible = false;
                    break;
                case 2:
                    new TWEEN.Tween(solutionC.scale)
                        .to({ x: 1, y: 1, z: 1 }, 300)
                        .onComplete(() => {
                            liquidC.visible = true;
                        })
                        .start();
                    lwTube3.visible = true;
                    new TWEEN.Tween(lwTube3.scale)
                        .to({ x: 0.01, y: 0.01, z: 0.01 }, 300)
                        .start();
                    break;
                case 6:
                    // solutionA.visible = true;
                    // solutionB.visible = true;
                    // solutionC.visible = true;
                    break;
                default:

                    break;
            };
        },
        nextState(this: IStateMachine) {
            this.setState(this.state + 1);
        },
        getState(this: IStateMachine) {
            return this.state;
        }, 
        tick() {
            TWEEN.update();
        },
        
    },
};
export {StateMachineComponent as StateMachine};