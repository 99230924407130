import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Footer } from '../../../shared/components/Footer';
import { DataManager } from '../DataManager';
import { StyledStack } from './components/StyledCard';
import { IconImage } from '../../../shared/components/IconImage';
import { ContentComponent } from './components/ContentComponent';
import { DisplayEnum } from '../../../shared/enums';
import { JoinUsPopup } from '../../../shared/components/JoinUsPopup';
import HeaderComponent from '../../../shared/components/Header';

import mobileImg from '../../../assets/mobile.png';
import fire from '../../../assets/fire.png';

export function CourseContentPage() {
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <DataManager setLoading={setLoading}>
      <StyledStack>
        <HeaderComponent transparentBg={true} />
        <Box width='fit-content' mx='auto' position='relative'>
          <IconImage
            src={mobileImg}
            alt='mobileImg'
            width={mobile ? '187px' : xlDisplay ? '372px' : '240px'}
            height={mobile ? '350px' : xlDisplay ? '693px' : '450px'}
          />
          <Box
            position='absolute'
            bottom={tablet ? '20%' : '200px'}
            left={mobile ? '8%' : xlDisplay ? '60px' : '9%'}
            zIndex={2}
          >
            <IconImage
              src={fire}
              alt='fire'
              width={mobile ? '154px' : xlDisplay ? '254px' : '200px'}
            />
          </Box>
        </Box>
        <ContentComponent loading={loading} />
        <Footer showAdditionalInfo={true} onMainPage={false} />
        {/* <JoinUsPopup /> */}
      </StyledStack>
    </DataManager>
  );
}
