import { Box, Stack, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback } from 'react';
import type { ChangeEvent } from 'react';
import { useFormikContext } from 'formik';

export const StyledSearchStack = styled(Stack, { name: 'StyledSearchStack' })({
  border: '1px solid #D2D2D2',
  boxShadow: '0px 12px 99px #00000029',
  borderRadius: '5px',
  height: '100%',
});

export const StyledSearchInput = styled('input', { name: 'StyledSearchInput' })(
  {
    padding: '13px 15px',
    paddingLeft: '40px',
    borderRadius: '4px',
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
    background: '#FFFFFF12',
    color: '#000',
    fontSize: '18px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .5)',
    },
  }
);

type Props = {
  bgColor?: string;
  onChange?: (value: string, name: string) => void;
  placeholder?: string;
  name: string;
  id: string;
  inputValue?: string;
};

export function SharedSearch(props: Props) {
  const {
    bgColor = '#FFFFFF12',
    onChange,
    placeholder = 'Search simulations …',
    name,
    id,
    inputValue,
  } = props;
  const formik = useFormikContext();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value, name);
      } else {
        formik?.setFieldValue(name || id, event.target.value);
      }
    },
    [onChange, name, formik, id]
  );

  return (
    <StyledSearchStack position='relative' minWidth={268}>
			<Box position='absolute' top={11} left={11} color='#929292'>
        <SearchIcon />
      </Box>
      <StyledSearchInput
        id={name}
        name={name}
        type='text'
        value={inputValue || ''}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </StyledSearchStack>
  );
}
