import { Routes, Route } from 'react-router-dom';
import { CookiePage } from './CookiePage';
import { PrivacyPolicyPage } from './PrivacyPolicyPage';
import { TermsAndConditionsPage } from './TermsAndConditionsPage';

export function CommitmentRoutes() {
  return (
    <Routes>
      <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
      <Route
        path='/terms-and-conditions'
        element={<TermsAndConditionsPage />}
      />
      <Route path='/cookie' element={<CookiePage />} />
    </Routes>
  );
}
