import { AFrame } from 'aframe';

declare global {
    interface Window {
      XR8: any;
    }
}

interface ITrackingStateAframe {
    el: AFrame['AEntity'];
  }

interface TrackingState {
    type: string;
    tracking: string;
}

interface MeshFoundEventDetail {
  id: string;
  position: { x: number; y: number; z: number };
  rotation: { w: number; x: number; y: number; z: number };
  geometry: {
    index: Uint32Array;
    attributes: Array<{
      name: 'position' | 'color';
      array: Float32Array;
      itemSize: number;
    }>;
  };
}

interface MeshUpdatedEventDetail {
  id: string;
  position: { x: number; y: number; z: number };
  rotation: { w: number; x: number; y: number; z: number };
}

interface MeshLostEventDetail {
  id: string;
}


const TrackingStateComponent = {
    name: 'tracking-state',
    val: {
        init(this : ITrackingStateAframe) {
          console.log('TrackingStateComponent initialized');

          const restartXRSession = () => {
            console.log('Trying to restart XR session')
            // const scene = document.querySelector('a-scene') as any;
            // if (scene && scene.xrSession) {
            //   scene.xrSession.end().then(() => {
            //     scene.enterAR();
            //     console.log('Entering AR')
            //   });
            // }
            window.XR8.XrController.recenter();
          };


          const trackingStatusChangeEvent = new CustomEvent('tracking-status-change', {
            detail: { status: '', reason: '' },
          });

          const trackingStateLoggerModule = {
            name: 'tracking-state-logger',
            listeners: [
              {
                event: 'reality.trackingstatus',
                process: ({ detail: { status, reason } }: { detail: { status: string; reason: string } }) => {
                  
                  console.log(`Tracking status: ${status}, reason: ${reason}`);
                  // const event = new CustomEvent('tracking-status-change', { detail: { status, reason } })
                  trackingStatusChangeEvent.detail.status = status;
                  trackingStatusChangeEvent.detail.reason = reason;
                  window.dispatchEvent(trackingStatusChangeEvent);
                },
              },
              {
                event: 'reality.meshfound',
                process: ({ detail: { id, position, rotation, geometry } }: { detail: MeshFoundEventDetail }) => {
                  console.log('Mesh found:', { id, position, rotation, geometry });
                },
              },
              {
                event: 'reality.meshupdated',
                process: ({ detail: { id, position, rotation } }: { detail: MeshUpdatedEventDetail }) => {
                  console.log('Mesh updated:', { id, position, rotation });
                },
              },
              {
                event: 'reality.meshlost',
                process: ({ detail: { id } }: { detail: MeshLostEventDetail }) => {
                  console.log('Mesh lost:', { id });
                },
              },
            ],
          };

          const addPipelineModule = () => {
              console.log('Adding pipeline module');
              window.XR8.addCameraPipelineModule(trackingStateLoggerModule);
              console.log('Pipeline module added');
          };

          if (window.XR8) {
              console.log('Xr8 exists');
              addPipelineModule();
          } else {
              window.addEventListener('xrloaded', () => {
                  console.log('Xr8 loaded');
                  addPipelineModule();
              });
          }

          // Register the 'tracking-restart' event listener on the window
          window.addEventListener('tracking-restart', () => {
            console.log('Restarting XR session');
            //restartXRSession();
            window.location.reload();
          });
        },
        
    },
};
export { TrackingStateComponent }