import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';
import {IShaderFireAframe} from "../1-metal-ions/shader-fire";
import {IShaderSmokeAframe} from "../exothermic-and-endothermic-reactions/shader-smoke";

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface IEvaporationAndCrystallisationSceneAframe {
    buttonHolder: AFrame.Entity[];
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    sliderValue: string;
    mixer: THREE.AnimationMixer;
    evaporateAnimation: THREE.AnimationAction;
    turnOnBunsenAnimation: THREE.AnimationAction;
    newTexture: THREE.Texture;
    paperMesh: THREE.Mesh;
    material: THREE.MeshStandardMaterial;
    bunsenBurnerButtonHandler: () => void;
    evaporationDishButtonHandler: () => void;
    mixtureButtonHandler: () => void;
    duration: number;
    startTime: number;
    texture2: string;
    onObjectSelected: ((selectedObject: { title: string; body: string; imageExtra?: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    model: AFrame.Entity;
    updateTexture: boolean;
    canPlay: boolean;
    fire: any;
    blocker: boolean;
}

const LessonStart = {
    name: 'lesson-scene',
    val: {
        init(this: IEvaporationAndCrystallisationSceneAframe) {
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                // ony initialise buttons once pool has loaded
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // moved button initialisation to lesson-start event
                // if initialising here, the buttons are not parenting to objects correctly
                // only applies to this lesson
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
                // ElectricBunsen
                this.model = document.getElementById('model') as AFrame.Entity;
                const animatedEl = this.model.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [Evaporate, TurnOnBunsen] = animatedEl.animations
                this.evaporateAnimation = this.mixer.clipAction(Evaporate)
                this.turnOnBunsenAnimation = this.mixer.clipAction(TurnOnBunsen)
                this.evaporateAnimation.setLoop(THREE.LoopPingPong, 1)
                this.turnOnBunsenAnimation.setLoop(THREE.LoopPingPong, 1)
            });
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                    const glass = this.model.object3D.getObjectByName('Bowl') as THREE.Mesh;
                    const crystals = this.model.object3D.getObjectByName('Crystals') as THREE.Mesh;
                    crystals.scale.set(0, 0, 0)
                    const glassMat = glass.material as THREE.MeshPhysicalMaterial;
                    glassMat.transparent = true;
                    glassMat.color = new THREE.Color(0x4f4f4f);
                    glassMat.side = 2;
                    glassMat.metalness = 0.2;
                    glassMat.reflectivity = 0.3;
                    glassMat.thickness = 2;
                    glassMat.transmission = 0.8;
                    glassMat.needsUpdate = true;
                }
            })
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                if (this.model.hasAttribute('shader-fire')) {
                    const fire = this.model.components['shader-fire'] as unknown as IShaderFireAframe;
                    fire.setVisibility(false);
                }
                if (this.model.hasAttribute('shader-smoke')) {
                    const smoke = this.model.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                    smoke.setVisibility(false);
                }
                this.evaporateAnimation.stop()
                this.turnOnBunsenAnimation.stop()
                console.log('Event recenter received')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: model; scale: 20 20 20; offset: 0 0 -0.5');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                }
            });

            const crystalGrows = () => {
                const crystals = this.model.object3D.getObjectByName('Crystals') as THREE.Mesh;
                let count = 0
                const interval = setInterval(() => {
                    if (count <= 2) {
                        crystals.scale.set(count, count, count)
                        count += 0.02
                    } else {
                        clearInterval(interval)
                    }
                }, 50)
            }
            this.bunsenBurnerButtonHandler = () => {
                if (!this.blocker){
                    this.blocker = true
                    crystalGrows()
                    this.evaporateAnimation.stop()
                    this.evaporateAnimation.clampWhenFinished = true
                    this.evaporateAnimation.play()
                    this.turnOnBunsenAnimation.timeScale = 1
                    this.turnOnBunsenAnimation.clampWhenFinished = true
                    this.turnOnBunsenAnimation.play()
                    // check if burner has fire component assigned
                    if (this.model.hasAttribute('shader-fire')) {
                        const fire = this.model.components['shader-fire'] as unknown as IShaderFireAframe;
                        fire.setVisibility(true);
                    } else {
                        this.model.setAttribute('shader-fire', '');
                    }
                    if (this.model.hasAttribute('shader-smoke')) {
                        const smoke = this.model.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                        smoke.setVisibility(true);
                    }  else {
                        this.model.setAttribute('shader-smoke', '');
                    }
                    setTimeout(() => {
                        this.turnOnBunsenAnimation.stop()
                        this.turnOnBunsenAnimation.timeScale = -1
                        this.turnOnBunsenAnimation.play()
                        this.evaporateAnimation.paused = true
                        if (this.model.hasAttribute('shader-fire')) {
                            const fire = this.model.components['shader-fire'] as unknown as IShaderFireAframe;
                            fire.setVisibility(false);
                        }
                        if (this.model.hasAttribute('shader-smoke')) {
                            const smoke = this.model.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                            smoke.setVisibility(false);
                        }
                        this.blocker = false
                    }, this.evaporateAnimation.getClip().duration * 1000 - 5500)
                }

                if (this.onObjectSelected) {
                    const title = 'Bunsen Burner';
                    const body = 'The bunsen burner provides heat which warms the solution and causes the water to evaporate. If performing crystallisation, it’s important to remove the solution from the heat when crystals start to form, before all the water has evaporated.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.evaporationDishButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Evaporation Dish';
                    const body = 'An evaporation dish is a shallow, heat-resistant container used for evaporating liquids or solutions. Its wide surface area enhances heat exposure, improving the evaporation process.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.mixtureButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Mixture';
                    const body = 'Evaporation and crystallisation are used to separate soluble solids (salts) from a liquid. While the methods share similarities, when the salt is prone to decomposition upon exposure to heat, as in this particular mixture, crystallisation must be used.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // button holder
                this.buttonHolder = [];
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const bunsenBurnerTriggerBtn = poolButtons.requestEntity()
                bunsenBurnerTriggerBtn?.setAttribute('position', '0.01 0.1 0.07')
                bunsenBurnerTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1');
                bunsenBurnerTriggerBtn?.play()
                bunsenBurnerTriggerBtn?.addEventListener('click', () => {
                    this.bunsenBurnerButtonHandler()
                    if (bunsenBurnerTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(bunsenBurnerTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (bunsenBurnerTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = bunsenBurnerTriggerBtn
                    }
                });
                if (bunsenBurnerTriggerBtn)
                    this.buttonHolder.push(bunsenBurnerTriggerBtn);

                const evaporationDishTriggerBtn = poolButtons.requestEntity()
                evaporationDishTriggerBtn?.setAttribute('position', '0.06 0.28 0.11')
                evaporationDishTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                evaporationDishTriggerBtn?.play()
                evaporationDishTriggerBtn?.addEventListener('click', () => {
                    this.evaporationDishButtonHandler()
                    if (evaporationDishTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(evaporationDishTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (evaporationDishTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = evaporationDishTriggerBtn
                    }
                });
                if (evaporationDishTriggerBtn)
                    this.buttonHolder.push(evaporationDishTriggerBtn);

                const mixtureTriggerBtn = poolButtons.requestEntity()
                mixtureTriggerBtn?.setAttribute('position', '-0.05 0.24 0.1')
                mixtureTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                mixtureTriggerBtn?.play()
                mixtureTriggerBtn?.addEventListener('click', () => {
                    this.mixtureButtonHandler()
                    if (mixtureTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(mixtureTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (mixtureTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = mixtureTriggerBtn
                    }
                });
                if (mixtureTriggerBtn)
                    this.buttonHolder.push(mixtureTriggerBtn);

            };
        },
        tick(this: IEvaporationAndCrystallisationSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {LessonStart as CrystallisationSceneComponent}
