import {
  Box,
  Dialog,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { MenuModal } from './MenuModal';
import { useSharedContext } from '../context';
import { useCloseBurgerMenu } from '../hooks/useCloseBurgerMenu';
import { DividerGray } from './DividerGray';
import { IconImage } from './IconImage';
import { HashLink } from './HashLink';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

import Logo from '../../assets/Logo.png';
import LinkedinLogo from '../../assets/linkedin.svg';
import InstagramLogo from '../../assets/instagram.svg';
import TicTokLogo from '../../assets/tiktok.svg';
import TwitterLogo from '../../assets/twitter.svg';
import YouTubeLogo from '../../assets/youtube.svg';

type Props = {
  hideNavigationMenu?: boolean;
};

export function BurgerMenu(props: Props) {
  const { hideNavigationMenu } = props;
  const [{ openBurgerMenu }] = useSharedContext();
  const theme = useTheme();
  const handleCloseBurgerMenu = useCloseBurgerMenu();
  const { t, i18n } = useTranslation();

  return (
    <Dialog fullScreen open={openBurgerMenu} PaperComponent={MenuModal}>
      <Stack pt={1.8} pb={1.8} direction='row' alignItems='center'>
        <IconButton
          edge='start'
          color='inherit'
          onClick={handleCloseBurgerMenu}
          aria-label='close'
        >
          <CloseIcon />
        </IconButton>
        <Box mt={0.5}>
          <IconImage src={Logo} width='67px' />
        </Box>
      </Stack>
      <DividerGray />
      <Stack
        direction='column'
        fontFamily='GilroyBold'
        fontSize={24}
        lineHeight={1.2}
        spacing={2}
        mt={2}
        mb={2}
        color={theme.palette.text.primary}
      >
        <Box>
          <HashLink onClick={handleCloseBurgerMenu} to='/simulations'>
            {t('COMMON.LINK.SIMULATIONS')}
          </HashLink>
        </Box>
        <Box>
          <HashLink onClick={handleCloseBurgerMenu} to='/#howItWorks'>
            {t('COMMON.LINK.HOW_IT_WORKS')}
          </HashLink>
        </Box>
        <Box>
          <HashLink onClick={handleCloseBurgerMenu} to='/#contacts'>
            {t('COMMON.LINK.CONTACT_US')}
          </HashLink>
        </Box>
      </Stack>

      <DividerGray />
      <Stack direction='row' spacing={2} ml='auto' mr='auto' mt={2}>
        <Link href='https://twitter.com/bridgelearning1' target='_blank'>
          <IconImage src={TwitterLogo} alt='Twitter Logo' height='26px' />
        </Link>
        <Link
          href='https://www.youtube.com/channel/UC-zHgMRyo6EOBchJpeIzFZw'
          target='_blank'
        >
          <IconImage src={YouTubeLogo} alt='YouTubeLogo' height='26px' />
        </Link>
        <Link
          href='https://www.instagram.com/bridgelearningtechnologies/'
          target='_blank'
        >
          <IconImage src={InstagramLogo} alt='Instagram Logo' height='26px' />
        </Link>
        <Link
          href='https://www.linkedin.com/company/bridge-learning-technologies-ltd/'
          target='_blank'
        >
          <IconImage src={LinkedinLogo} alt='Linkedin Logo' height='26px' />
        </Link>
        <Link href='https://www.tiktok.com/@bridgelt' target='_blank'>
          <IconImage src={TicTokLogo} alt='TicTok Logo' height='26px' />
        </Link>
      </Stack>

      <Stack mt={2} ml='auto' mr='auto'>
        {/*<IconImage src={BridgeLogo} alt='Bridge Logo' />*/}
        <Typography
          mt={2}
          variant='body1'
          color={theme.palette.text.secondary}
          fontFamily='GilroyMedium'
        >
          {t('COMMON.LINK.BRIDGE')}
        </Typography>
      </Stack>

      <Stack direction='row' spacing={1} ml='auto' mr='auto' mt={2} mb={2}>
        <Link
          color={theme.palette.text.secondary}
          target='_blank'
          href='/privacy-policy'
        >
          {t('COMMON.LINK.PRIVACY_POLICY')}
        </Link>
        <Link
          color={theme.palette.text.secondary}
          target='_blank'
          href='/terms-and-conditions'
        >
          {t('COMMON.LINK.TERMS_AND_CONDITIONS')}
        </Link>
        <Link
          color={theme.palette.text.secondary}
          target='_blank'
          href='/cookie'
        >
          {t('COMMON.LINK.COOKIES')}
        </Link>
      </Stack>
    </Dialog>
  );
}
