import React, {useRef, useState} from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { TapPlaceComponent } from '../../../lib/aframe/components/tap-place-object';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { CircuitControlComponent } from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
} from 'shared/constants/hint-items';
import sun from "../../../assets/img/sun.png";
import frost from "../../../assets/img/frost.png";
import {GradientGlow} from './gradient-glow';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
  'Use the slider to vary the conditions of the circuit and observe the results. Select a different circuit in the drop down menu.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT];

const PhysicsSensorsAframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState<boolean>(false);
  const [sliderLoaded, setSliderLoaded ] = useState<{
    title: string;
    range: number[];
    step: number;
    firstValue: number;
    stringVariant?: boolean,
  } | null>({
    title: 'Light Intensity',
    range: [0, 100],
    step: 5,
    firstValue: 0,
    stringVariant: true,
  });

  const assetIcons = [sun, frost];
  const [assetId, setAssetId] = useState(0);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const changeAsset = (assetId: number) => {
    setAssetId(assetId);
    if (assetId === 0) {
      setSliderLoaded({
        title: 'Light Intensity',
        range: [0, 100],
        step: 5,
        firstValue: 0,
        stringVariant: true,
      });
    } else {
      setSliderLoaded({
        title: 'Temperature',
        range: [0, 100],
        step: 5,
        firstValue: 0,
        stringVariant: true,
      });
    }
  };
  const assetChangeEventRef = useRef(
      new CustomEvent('asset-change', { detail: { assetId: 0 } })
  );
  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };
  React.useEffect(() => {
    // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      console.log('Scene loaded');
      // Update the assetChangeEvent detail before dispatching
      assetChangeEventRef.current.detail.assetId = assetId;
      scene.dispatchEvent(assetChangeEventRef.current);
    }
  }, [assetId]);
  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };

  const onLessonStarted = () => {
    setLessonStarted(true);
  };

  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };

  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        sliderLoad={sliderLoaded}
        lessonStarted={lessonStarted}
        assetIcons={assetIcons}
        onAssetButtonClick={changeAsset}
        onAnnotationStateChanged={onAnnotationStateChanged}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./physics-sensors-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          CircuitControlComponent,
          TapPlaceComponent,
          GradientGlow,
          WorldButtonComponent,
          FaceCameraComponent,
          Annotation,
        ]}
        onObjectSelected={onObjectSelected}
        onLessonStart={onLessonStarted}
        annotationState={annotationState}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { PhysicsSensorsAframe };
