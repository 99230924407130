import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface ICellSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	actionRespiration: THREE.AnimationAction;
	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;
	el: AFrame.Entity;
	currentAssetId: number;
	poolButtons: PoolComponent;

	controlElement: AFrame.Entity;
	bacteriaCell: AFrame.Entity;

	chromosomaTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	plasmidTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	cytoplasmTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	cellWallTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	ribosomeTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	cellMembraneTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	bacterialCellTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;

	chromosomaButtonHandler: () => void;
	plasmidButtonHandler: () => void;
	cytoplasmButtonHandler: () => void;
	cellWallButtonHandler: () => void;
	ribosomeButtonHandler: () => void;
	cellMembraneButtonHandler: () => void;
	bacterialCellButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const BacteriaCellScene = {
	name: 'bacteria-cell-scene',
	val: {
		init(this: ICellSceneAframe) {
			this.controlElement = document.getElementById('sceneHolder') as AFrame.Entity;
			this.controlElement.addEventListener('model-loaded', (e) => {
				const customEvent = e as CustomEvent;
				const modelId = (customEvent.target as HTMLElement).id
				console.log('Loaded model', modelId);
				// only proceeding with the initialisation if the model is the one we want and nothing was yet initialised
				if (modelId !== 'bacteriaCell') {
					return;
				}
				// console.log(
				// 	'Cell scene model loaded (MuscleCellContracting). Initialising behaviours.',
				// )
				//initialiseAnimations();
				const scene = this.el.sceneEl as AFrame.Scene & {
					systems: { "annotation-system": IAnnotationSystemAframe };
				};
				const annotationSystem = scene.systems["annotation-system"];
				this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
				this.el.setAttribute('annotation', '');
				this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}
				// moved here
				// initialiseAnimations();
			})

			this.bacteriaCell = document.getElementById('bacteriaCell') as AFrame.Entity;

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			// when model is loaded adding recenter functionality
			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// position this el in fron of camera with the following offset "3 4.5 -5"
				// Get the camera and the desired offset.
				// Get the A-Frame camera component.
				const cameraEl = this.el.sceneEl?.querySelector('a-camera');

				// Set the position of the element to the camera's position.

				if (cameraEl) {
					console.log('Camera found: ', cameraEl.getAttribute('rotation'));

					console.log('Rotation before: ', this.el.getAttribute('rotation'));


					// Get the camera's Y rotation.
					const cameraRotation = cameraEl.getAttribute('rotation') as unknown as THREE.Euler;
					const cameraRotationY = cameraRotation ? cameraRotation.y : 0;

					// Get the current rotation of the element.
					const elementRotation = this.el.getAttribute('rotation');

					// Set the element's Y rotation to match the camera's Y rotation.
					this.el.setAttribute('rotation', {
						x: elementRotation.x,
						y: cameraRotationY,
						z: elementRotation.z
					});
					console.log('Rotation after: ', this.el.getAttribute('rotation'))

					const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
					// Create an offset vector.
					const offset = new THREE.Vector3(0, -8, -8);
					offset.applyQuaternion(this.el.object3D.quaternion);

					// Add the offset to the camera's position.
					const newPosition = camPos.clone().add(offset);

					// Set the position of the element with the offset.
					this.el.setAttribute('position', newPosition);
				}
			});

			this.chromosomaButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				console.log('button is clicked')
				if (this.onObjectSelected) {
					const title = 'Chromosomal DNA';
					const body = 'Chromosomal DNA is a large loop that floats in the cytoplasm in prokaryotes. This is the prokaryotic main DNA.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.plasmidButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Plasmid';
					const body = 'Plasmids are small circular DNA that floats in the cytoplasm. They are not the cell’s main DNA, but contain useful genes such as antibiotic resistance genes.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.cytoplasmButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Cytoplasm';
					const body = 'Prokaryotes contain cytoplasm where the genetic material floats.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.cellWallButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Cell Wall';
					const body = 'Prokaryotic cell walls are composed of peptidoglycan.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.ribosomeButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Ribosome';
					const body = 'Ribosomes are the site for protein synthesis.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.cellMembraneButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Cell Membrane';
					const body = 'Prokaryotes have cell membranes that control the movement of substances in and out of the cell.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.bacterialCellButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Bacterial Cell';
					const body = 'All bacteria are prokaryotes. Most prokaryotes are unicellular organisms.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}
			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.chromosomaTriggerBtn = poolButtons.requestEntity()
				this.chromosomaTriggerBtn?.object3D.position.set(0, 0.1, -0.8)
				this.chromosomaTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.chromosomaTriggerBtn?.play()
				this.chromosomaTriggerBtn?.addEventListener('click', () => {
					this.chromosomaButtonHandler();
					if (this.chromosomaTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.chromosomaTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.chromosomaTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.chromosomaTriggerBtn
					}
				});

				this.plasmidTriggerBtn = poolButtons.requestEntity()
				this.plasmidTriggerBtn?.object3D.position.set(0.42, 0.1, -0.72)
				this.plasmidTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.plasmidTriggerBtn?.play()
				this.plasmidTriggerBtn?.addEventListener('click', () => {
					this.plasmidButtonHandler();
					if (this.plasmidTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.plasmidTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.plasmidTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.plasmidTriggerBtn
					}
				});

				this.cellMembraneTriggerBtn = poolButtons.requestEntity()
				this.cellMembraneTriggerBtn?.object3D.position.set(-0.27, 0.2, -0.9)
				this.cellMembraneTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.cellMembraneTriggerBtn?.play()
				this.cellMembraneTriggerBtn?.addEventListener('click', () => {
					this.cellMembraneButtonHandler();
					if (this.cellMembraneTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.cellMembraneTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.cellMembraneTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.cellMembraneTriggerBtn
					}
				});

				this.cytoplasmTriggerBtn = poolButtons.requestEntity()
				this.cytoplasmTriggerBtn?.object3D.position.set(-0.24, -0.03, -0.85)
				this.cytoplasmTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.cytoplasmTriggerBtn?.play()
				this.cytoplasmTriggerBtn?.addEventListener('click', () => {
					this.cytoplasmButtonHandler();
					if (this.cytoplasmTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.cytoplasmTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.cytoplasmTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.cytoplasmTriggerBtn
					}
				});

				this.cellWallTriggerBtn = poolButtons.requestEntity()
				this.cellWallTriggerBtn?.object3D.position.set(-0.42, 0, -0.9)
				this.cellWallTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.cellWallTriggerBtn?.play()
				this.cellWallTriggerBtn?.addEventListener('click', () => {
					this.cellWallButtonHandler();
					if (this.cellWallTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.cellWallTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.cellWallTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.cellWallTriggerBtn
					}
				});

				this.ribosomeTriggerBtn = poolButtons.requestEntity()
				this.ribosomeTriggerBtn?.object3D.position.set(0.2, 0.2, -0.85)
				this.ribosomeTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.ribosomeTriggerBtn?.play()
				this.ribosomeTriggerBtn?.addEventListener('click', () => {
					this.ribosomeButtonHandler();
					if (this.ribosomeTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.ribosomeTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.ribosomeTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.ribosomeTriggerBtn
					}
				});

				this.bacterialCellTriggerBtn = poolButtons.requestEntity()
				this.bacterialCellTriggerBtn?.object3D.position.set(0.52, 0.018, -0.65)
				this.bacterialCellTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.bacterialCellTriggerBtn?.play()
				this.bacterialCellTriggerBtn?.addEventListener('click', () => {
					this.bacterialCellButtonHandler();
					if (this.bacterialCellTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.bacterialCellTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.bacterialCellTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.bacterialCellTriggerBtn
					}
				});
			}

		},
		tick(this: ICellSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { BacteriaCellScene as BacteriaCellSceneComponent }