import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    poolEntity: AFrame.Entity;
    alphaDecayHandler: () => void;
    betaDecayHandler: () => void;
    gammaDecayHandler: () => void;
    neutronDecayHandler: () => void;
    alphaDecayMixer: THREE.AnimationMixer;
    betaDecayMixer: THREE.AnimationMixer;
    gammaDecayMixer: THREE.AnimationMixer;
    neutronDecayMixer: THREE.AnimationMixer;
    alphaDecayAnimation: THREE.AnimationAction;
    betaDecayAnimation: THREE.AnimationAction;
    gammaDecayAnimation: THREE.AnimationAction;
    neutronDecayAnimation: THREE.AnimationAction;
    prevAnimation: THREE.AnimationAction;
    currentAnimation: THREE.AnimationAction;
    annotationComponent: IAnnotationAframe;
    currentDeactivatedButton: AFrame.Entity;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    alphaDecayModel: AFrame.Entity;
    betaDecayModel: AFrame.Entity;
    gammaDecayModel: AFrame.Entity;
    neutronDecayModel: AFrame.Entity;
    prevModel: AFrame.Entity;
    currentAssetId: number;
    currentHandler: () => void;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'lesson-start',
    val: {
        init(this: ICircuitControl) {
            const holder = document.getElementById('holder') as AFrame.Entity;
            holder.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.alphaDecayModel = document.getElementById('alpha') as AFrame.Entity
                this.betaDecayModel = document.getElementById('beta') as AFrame.Entity
                this.gammaDecayModel = document.getElementById('gamma') as AFrame.Entity
                this.neutronDecayModel = document.getElementById('neutron') as AFrame.Entity
                if (!this.prevModel) this.prevModel = this.alphaDecayModel
                if (!this.currentAssetId) this.currentAssetId = 0
                initialiseAnimations()
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: holder; scale: 15 15 15; offset: 0 -4 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });

            this.el.sceneEl?.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                this.currentAssetId = ce.detail.assetId;
                if (this.prevAnimation) this.prevAnimation.stop()
                switch (this.currentAssetId) {
                    case 0:
                        if (this.prevModel) this.prevModel.object3D.visible = false
                        this.alphaDecayModel.object3D.visible = true
                        this.prevModel = this.alphaDecayModel
                        break;
                    case 1:
                        if (this.prevModel) this.prevModel.object3D.visible = false
                        this.betaDecayModel.object3D.visible = true
                        this.prevModel = this.betaDecayModel
                        break;
                    case 2:
                        if (this.prevModel) this.prevModel.object3D.visible = false
                        this.gammaDecayModel.object3D.visible = true
                        this.prevModel = this.gammaDecayModel
                        break;
                    case 3:
                        if (this.prevModel) this.prevModel.object3D.visible = false
                        this.neutronDecayModel.object3D.visible = true
                        this.prevModel = this.neutronDecayModel
                        break;
                    default:
                        console.warn('lesson-start.tsx:Unknown asset id: ', this.currentAssetId);
                }
            });

            const initialiseAnimations = () => {
                const animatedAlphaDecayEl = this.alphaDecayModel.object3D.getObjectByName('Scene') as any;
                this.alphaDecayMixer = new THREE.AnimationMixer(animatedAlphaDecayEl);
                const [AlphaDecay] = animatedAlphaDecayEl.animations;
                this.alphaDecayAnimation = this.alphaDecayMixer.clipAction(AlphaDecay);

                const animatedBettaDecayEl = this.betaDecayModel.object3D.getObjectByName('Scene') as any;
                this.betaDecayMixer = new THREE.AnimationMixer(animatedBettaDecayEl);
                const [BettaDecay] = animatedBettaDecayEl.animations;
                this.betaDecayAnimation = this.betaDecayMixer.clipAction(BettaDecay);

                const animatedGammaDecayEl = this.gammaDecayModel.object3D.getObjectByName('Scene') as any;
                this.gammaDecayMixer = new THREE.AnimationMixer(animatedGammaDecayEl);
                const [GammaDecay] = animatedGammaDecayEl.animations;
                this.gammaDecayAnimation = this.gammaDecayMixer.clipAction(GammaDecay);

                const NeutronDecayEl = this.neutronDecayModel.object3D.getObjectByName('Scene') as any;
                this.neutronDecayMixer = new THREE.AnimationMixer(NeutronDecayEl);
                const [NeutronDecay] = NeutronDecayEl.animations;
                this.neutronDecayAnimation = this.neutronDecayMixer.clipAction(NeutronDecay);

            };

            this.alphaDecayHandler = () => {
                this.alphaDecayAnimation.play()
                this.prevAnimation = this.alphaDecayAnimation
                if (this.onObjectSelected) {
                    const title = 'Alpha Decay';
                    const body = 'When a nucleus is too large it can emit an alpha particle ‘α’ to become more stable. The alpha particle consists of two protons and two neutrons (the same as a helium nucleus). The remaining atom becomes a different element.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.betaDecayHandler = () => {
                this.betaDecayAnimation.play()
                this.prevAnimation = this.betaDecayAnimation
                if (this.onObjectSelected) {
                    const title = 'Beta Decay';
                    const body = 'When a nucleus has too many neutrons, a neutron will transform into a proton and emit a high energy electron known as a beta particle ‘β<sup>-</sup>’. This means the atom’s mass number stays the same but the atomic number increases by one.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.gammaDecayHandler = () => {
                this.gammaDecayAnimation.play()
                this.prevAnimation = this.gammaDecayAnimation
                if (this.onObjectSelected) {
                    const title = 'Gamma Decay';
                    const body = 'When a nucleus has too much energy, it will emit a high frequency electromagnetic wave known as a gamma ray ‘γ’. The remaining atom contains all the same particles as before so it is the same element.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.neutronDecayHandler = () => {
                this.neutronDecayAnimation.play()
                this.prevAnimation = this.neutronDecayAnimation
                if (this.onObjectSelected) {
                    const title = 'Neutron Decay';
                    const body = 'When a nucleus has far too many neutrons, it will simply emit one of them ‘n’. The resultant atom has the same number of protons so it is an isotope of the original atom. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const alphaDecayTriggerBtn = poolButtons.requestEntity()
                alphaDecayTriggerBtn?.setAttribute('position', '-0.045 0.035 0.14')
                alphaDecayTriggerBtn?.play()
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        alphaDecayTriggerBtn?.setAttribute('scale', '2 2 2')
                    } else {
                        alphaDecayTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                alphaDecayTriggerBtn?.addEventListener('click', () => {
                    this.alphaDecayHandler()
                    if (alphaDecayTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(alphaDecayTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (alphaDecayTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = alphaDecayTriggerBtn
                    }
                });
                const betaDecayTriggerButton = poolButtons.requestEntity();
                betaDecayTriggerButton?.setAttribute('position', '0.025 0.075 0.1');
                betaDecayTriggerButton?.setAttribute('scale', '0 0 0')
                betaDecayTriggerButton?.play()
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 1) {
                        betaDecayTriggerButton?.setAttribute('scale', '2 2 2')
                    } else {
                        betaDecayTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                betaDecayTriggerButton?.addEventListener('click', () => {
                    this.betaDecayHandler()
                    if (betaDecayTriggerButton) {
                        this.annotationComponent.setObjectToFollow(betaDecayTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (betaDecayTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = betaDecayTriggerButton

                    }
                });
                const gammaDecayTriggerButton = poolButtons.requestEntity();
                gammaDecayTriggerButton?.setAttribute('position', '0.005 0.075 0.1');
                gammaDecayTriggerButton?.setAttribute('scale', '0 0 0')
                gammaDecayTriggerButton?.play()
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 2) {
                        gammaDecayTriggerButton?.setAttribute('scale', '2 2 2')
                    } else {
                        gammaDecayTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                gammaDecayTriggerButton?.addEventListener('click', () => {
                    this.gammaDecayHandler()
                    if (gammaDecayTriggerButton) {
                        this.annotationComponent.setObjectToFollow(gammaDecayTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (gammaDecayTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = gammaDecayTriggerButton

                    }
                });
                const neutronDecayTriggerButton = poolButtons.requestEntity();
                neutronDecayTriggerButton?.setAttribute('position', '0.025 0.035 0.1');
                neutronDecayTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 3) {
                        neutronDecayTriggerButton?.setAttribute('scale', '2 2 2')
                    } else {
                        neutronDecayTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                neutronDecayTriggerButton?.play()
                neutronDecayTriggerButton?.addEventListener('click', () => {
                    this.neutronDecayHandler()
                    if (neutronDecayTriggerButton) {
                        this.annotationComponent.setObjectToFollow(neutronDecayTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (neutronDecayTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = neutronDecayTriggerButton

                    }
                });
            };
        },
        tick(this: ICircuitControl, time: number, deltaTime: number) {
            if (this.alphaDecayMixer) {
                this.alphaDecayMixer.update(deltaTime * 0.001);
            }
            if (this.betaDecayMixer) {
                this.betaDecayMixer.update(deltaTime * 0.001);
            }
            if (this.gammaDecayMixer) {
                this.gammaDecayMixer.update(deltaTime * 0.001);
            }
            if (this.neutronDecayMixer) {
                this.neutronDecayMixer.update(deltaTime * 0.001);
            }
        },

    },
};
export {CircuitControlComponent}
