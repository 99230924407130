import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';


interface IHalogensSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    fluorineHandler: () => void;
    chlorineHandler: () => void;
    bromineHandler: () => void;
    iodineHandler: () => void;
    astatineHandler: () => void;
	currentHandler: () => void;
    fluorineAtom: AFrame.Entity;
    chlorineAtom: AFrame.Entity;
    bromineAtom: AFrame.Entity;
    iodineAtom: AFrame.Entity;
    astatineAtom: AFrame.Entity;
    fluorineMixer: THREE.AnimationMixer;
    fluorineCoreAnimation: THREE.AnimationAction;
    fluorineElectronsAnimation: THREE.AnimationAction;
    chlorineMixer: THREE.AnimationMixer;
    chlorineCoreAnimation: THREE.AnimationAction;
    chlorineElectronsAnimation: THREE.AnimationAction;
    bromineMixer: THREE.AnimationMixer;
    bromineCoreAnimation: THREE.AnimationAction;
    bromineElectronsAnimation: THREE.AnimationAction;
    iodineMixer: THREE.AnimationMixer;
    iodineCoreAnimation: THREE.AnimationAction;
    iodineElectronsAnimation: THREE.AnimationAction;
    astatineMixer: THREE.AnimationMixer;
    astatineCoreAnimation: THREE.AnimationAction;
    astatineElectronsAnimation: THREE.AnimationAction;
    currentCoreAnimation: THREE.AnimationAction;
    currentElectronAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; image: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    prevEl: AFrame.Entity;
    currentAssetId: number;
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: IHalogensSceneAframe) {
            const atoms = document.getElementById('atomsHolder') as AFrame.Entity;
            atoms.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                this.el.sceneEl?.addEventListener('lesson-start', () => {
                    // remove tap place
                    const ring = document.getElementById('ring')
                    if (ring) {
                        ring.removeAttribute('tap-place-air')
                        this.el.sceneEl?.removeChild(ring)
                    }
                    this.fluorineAtom = document.getElementById('fluorineAtom') as AFrame.Entity;
                    this.chlorineAtom = document.getElementById('chlorineAtom') as AFrame.Entity;
                    this.bromineAtom = document.getElementById('bromineAtom') as AFrame.Entity;
                    this.iodineAtom = document.getElementById('iodineAtom') as AFrame.Entity;
                    this.astatineAtom = document.getElementById('astatineAtom') as AFrame.Entity;
                    initialiseAnimations()
                    if (!this.currentHandler) this.currentHandler = this.fluorineHandler
                    if (!this.prevEl) this.prevEl = this.fluorineAtom
                    this.prevEl.object3D.visible = true
                    this.currentHandler()
                    this.currentCoreAnimation.play()
                    this.currentElectronAnimation.play()
                })
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: atomsHolder; scale: 10 10 10; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                }
            });
            this.currentAssetId = 0;
            this.el.sceneEl?.addEventListener('asset-change', (event) => {

                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const newAssetId = customEvent.detail.assetId;
                switch (newAssetId) {
                    case 0:
                        this.fluorineHandler()
                        this.currentHandler = this.fluorineHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.fluorineAtom.object3D.visible = true
                        this.fluorineElectronsAnimation.play()
                        this.fluorineCoreAnimation.play()
                        this.prevEl = this.fluorineAtom
                        break;
                    case 1:
                        this.chlorineHandler()
                        this.currentHandler = this.chlorineHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.chlorineAtom.object3D.visible = true
                        this.chlorineElectronsAnimation.play()
                        this.chlorineCoreAnimation.play()
                        this.prevEl = this.chlorineAtom
                        break;
                    case 2:
                        this.bromineHandler()
                        this.currentHandler = this.bromineHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.bromineAtom.object3D.visible = true
                        this.bromineElectronsAnimation.play()
                        this.bromineCoreAnimation.play()
                        this.prevEl = this.bromineAtom
                        break;
                    case 3:
                        this.iodineHandler()
                        this.currentHandler = this.iodineHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.iodineAtom.object3D.visible = true
                        this.iodineElectronsAnimation.play()
                        this.iodineCoreAnimation.play()
                        this.prevEl = this.iodineAtom
                        break;
                    case 4:
                        this.astatineHandler()
                        this.currentHandler = this.astatineHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.astatineAtom.object3D.visible = true
                        this.astatineElectronsAnimation.play()
                        this.astatineCoreAnimation.play()
                        this.prevEl = this.astatineAtom
                        break;
                }

            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    if (this.currentCoreAnimation && this.currentElectronAnimation) {
                        this.currentCoreAnimation.paused = false;
                        this.currentElectronAnimation.paused = false;
                    }
                } else {
                    if (this.currentCoreAnimation && this.currentElectronAnimation) {
                        this.currentCoreAnimation.paused = true;
                        this.currentElectronAnimation.paused = true;
                    }
                }
            })
            this.fluorineHandler = () => {
                this.currentCoreAnimation = this.fluorineCoreAnimation
                this.currentElectronAnimation = this.fluorineElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Halogens/FluorineCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.chlorineHandler = () => {
                this.currentCoreAnimation = this.chlorineCoreAnimation
                this.currentElectronAnimation = this.chlorineElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = '';
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Halogens/ChlorineCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.bromineHandler = () => {
                this.currentCoreAnimation = this.bromineCoreAnimation
                this.currentElectronAnimation = this.bromineElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Halogens/BromineCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.iodineHandler = () => {
                this.currentCoreAnimation = this.iodineCoreAnimation
                this.currentElectronAnimation = this.iodineElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Halogens/IodineCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.astatineHandler = () => {
                this.currentCoreAnimation = this.astatineCoreAnimation
                this.currentElectronAnimation = this.astatineElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Halogens/AstatineCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseAnimations = () => {
                const animatedFluorineEl = this.fluorineAtom.object3D.getObjectByName('Scene') as any;
                this.fluorineMixer = new THREE.AnimationMixer(animatedFluorineEl)
                const [FluorineElectronsAnim, FluorineCoreAnim] = animatedFluorineEl.animations
                this.fluorineElectronsAnimation = this.fluorineMixer.clipAction(FluorineElectronsAnim)
                this.fluorineCoreAnimation = this.fluorineMixer.clipAction(FluorineCoreAnim)

                const animatedChlorineEl = this.chlorineAtom.object3D.getObjectByName('Scene') as any;
                this.chlorineMixer = new THREE.AnimationMixer(animatedChlorineEl)
                const [ChlorineElectronsAnim, ChlorineCoreAnim] = animatedChlorineEl.animations
                this.chlorineElectronsAnimation = this.chlorineMixer.clipAction(ChlorineElectronsAnim)
                this.chlorineCoreAnimation = this.chlorineMixer.clipAction(ChlorineCoreAnim)

                const animatedBromineEl = this.bromineAtom.object3D.getObjectByName('Scene') as any;
                this.bromineMixer = new THREE.AnimationMixer(animatedBromineEl)
                const [BromineElectronsAnim, BromineCoreAnim] = animatedBromineEl.animations
                this.bromineElectronsAnimation = this.bromineMixer.clipAction(BromineElectronsAnim)
                this.bromineCoreAnimation = this.bromineMixer.clipAction(BromineCoreAnim)

                const animatedIodineEl = this.iodineAtom.object3D.getObjectByName('Scene') as any;
                this.iodineMixer = new THREE.AnimationMixer(animatedIodineEl)
                const [IodineElectronsAnim, IodineCoreAnim] = animatedIodineEl.animations
                this.iodineElectronsAnimation = this.iodineMixer.clipAction(IodineElectronsAnim)
                this.iodineCoreAnimation = this.iodineMixer.clipAction(IodineCoreAnim)

                const animatedAstatineEl = this.astatineAtom.object3D.getObjectByName('Scene') as any;
                this.astatineMixer = new THREE.AnimationMixer(animatedAstatineEl)
                const [AstatineElectronsAnim, AstatineCoreAnim] = animatedAstatineEl.animations
                this.astatineElectronsAnimation = this.astatineMixer.clipAction(AstatineElectronsAnim)
                this.astatineCoreAnimation = this.astatineMixer.clipAction(AstatineCoreAnim)

            }
        },
        tick(this: IHalogensSceneAframe, time: number, deltaTime: number) {
            if (this.fluorineMixer) {
                this.fluorineMixer.update(deltaTime * 0.001);
            }
            if (this.chlorineMixer) {
                this.chlorineMixer.update(deltaTime * 0.001);
            }
            if (this.bromineMixer) {
                this.bromineMixer.update(deltaTime * 0.001);
            }
            if (this.iodineMixer) {
                this.iodineMixer.update(deltaTime * 0.001);
            }
            if (this.astatineMixer) {
                this.astatineMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {AtomsScene as AtomsSceneComponent}
