import { Fragment } from "react";
import '../styles/return-to-start-button.css';


const ReturnToStartButton = () =>  {
    const scene = document.querySelector('a-scene');
    const onClick = () => {
        scene?.emit('return-to-start');
    }
    return (
        <Fragment>
            <div className="return-to-start-button" id="return-to-start-button" onClick={onClick}>
                <img src="https://bridgear.blob.core.windows.net/public/Physics/Rockets/skip-back.svg" alt="return-to-start-button" />
            </div>
        </Fragment>
    )
}

export default ReturnToStartButton;