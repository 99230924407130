import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IShaderFireAframe } from './shader-fire';
import * as TWEEN from '@tweenjs/tween.js';
import { IShaderFireSmallAframe } from './shader-fire-small';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface ITestsForCommonGasesSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	actionRespiration: THREE.AnimationAction;

	el: AFrame.Entity;
	currentAssetId: number;
	poolButtons: PoolComponent;
	currentClips: THREE.AnimationAction[];

	mixer_1: THREE.AnimationMixer;
	mixer_2: THREE.AnimationMixer;

	modelCO2: AFrame.Entity;
	modelH2_O2_CL2: AFrame.Entity;

	labelH2: THREE.Mesh;
	labelO2: THREE.Mesh;
	labelCL2: THREE.Mesh;

	H2: THREE.Mesh;
	O2: THREE.Mesh;
	CL2: THREE.Mesh;

	testTube: THREE.Mesh;
	splintClean: THREE.Mesh;
	splintAfterFire: THREE.Mesh;

	actionH2Anim: THREE.AnimationAction;
	actionO2Anim: THREE.AnimationAction;
	actionCL2Anim: THREE.AnimationAction;
	actionTurnOnBunsen: THREE.AnimationAction;

	actionCO2Anim: THREE.AnimationAction;

	annotationATriggerBtn: AFrame.Entity | null;
	annotationBTriggerBtn: AFrame.Entity | null;
	annotationCTriggerBtn: AFrame.Entity | null;
	annotationDTriggerBtn: AFrame.Entity | null;
	annotationETriggerBtn: AFrame.Entity | null;

	annotationAButtonHandler: () => void;
	annotationBButtonHandler: () => void;
	annotationCButtonHandler: () => void;
	annotationDButtonHandler: () => void;
	annotationEButtonHandler: () => void;

	fire: IShaderFireAframe;
	fireSmall: IShaderFireSmallAframe;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const TestsForCommonGasesScene = {
	name: 'experiment',
	val: {
		init(this: ITestsForCommonGasesSceneAframe) {
			var isInitialised = false;
			let count = 0;
			this.el.addEventListener('model-loaded', () => {
				count++;
				if (count < 2) return
				if (!isInitialised) {
					this.modelCO2 = document.getElementById('modelCO2') as AFrame.Entity;
					this.modelH2_O2_CL2 = document.getElementById('modelH2_O2_CL2') as AFrame.Entity;

					// this.modelH2_O2_CL2?.object3D.traverse((child) => {
					// 	console.log(child);
					// });

					this.labelH2 = this.modelH2_O2_CL2.object3D.getObjectByName('H2_Label') as THREE.Mesh;
					this.labelO2 = this.modelH2_O2_CL2.object3D.getObjectByName('O2_Label') as THREE.Mesh;
					this.labelCL2 = this.modelH2_O2_CL2.object3D.getObjectByName('CL2_Label') as THREE.Mesh;

					this.H2 = this.modelH2_O2_CL2.object3D.getObjectByName('H2') as THREE.Mesh;
					this.O2 = this.modelH2_O2_CL2.object3D.getObjectByName('O2') as THREE.Mesh;
					this.CL2 = this.modelH2_O2_CL2.object3D.getObjectByName('CL2') as THREE.Mesh;

					this.splintClean = this.modelH2_O2_CL2.object3D.getObjectByName('Splint_Unlit') as THREE.Mesh;
					this.splintAfterFire = this.modelH2_O2_CL2.object3D.getObjectByName('Splint_Lit') as THREE.Mesh;

					const testTube = this.modelH2_O2_CL2.object3D.getObjectByName('TestTube') as THREE.Mesh;

					const matGlassTube = (testTube as THREE.Mesh).material as THREE.MeshPhysicalMaterial;
					matGlassTube.transparent = true;
					matGlassTube.color = new THREE.Color(0x4f4f4f);
					matGlassTube.side = 2;
					matGlassTube.metalness = 0.2;
					matGlassTube.reflectivity = 0;
					matGlassTube.thickness = 1;
					matGlassTube.transmission = 0.8;
					matGlassTube.needsUpdate = true;

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe }; testTube: THREE.Mesh;
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
					this.currentClips = [];
					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;
					sceneH2();
				}
				else return
			});


			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			// when model is loaded adding recenter functionality

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: sceneHolder;');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}
				this.fire.setVisibility(false);
				this.fireSmall.setVisibility(false);
			});

			const sound = document.getElementById('sound') as HTMLAudioElement;
			let isSoundUnlock = false;

			const unlockAudio = () => {
				sound.play();
				sound.addEventListener('playing', () => {
					sound.pause();
					sound.currentTime = 0;
					isSoundUnlock = true;
				}, { once: true })
			}

			let soundPlayed = false;
			const playSoundOnTime = () => {
				if (!soundPlayed) {
					if (sound) {
						if (!isSoundUnlock) {
							unlockAudio();
						}
						setTimeout(() => {
							sound.play();
						}, 2000);
					}
					soundPlayed = true;
					setTimeout(() => {
						soundPlayed = false;
					}, 2000);
				}
			}

			const stopSound = () => {
				if (sound && !sound.paused) {
					sound.pause();
					sound.currentTime = 0;
				}
			}

			const sceneH2 = () => {
				if (this.labelH2) {
					this.labelH2.visible = true;
				}
				if (this.labelO2) {
					this.labelO2.visible = false;
				}
				if (this.labelCL2) {
					this.labelCL2.visible = false;
				}
				if (this.H2) {
					this.H2.visible = true;
				}
				if (this.O2) {
					this.O2.visible = false;
				}
				if (this.CL2) {
					this.CL2.visible = false;
				}
				if (this.modelH2_O2_CL2.hasAttribute('shader-fire')) {
					this.fire = this.modelH2_O2_CL2.components['shader-fire'] as unknown as IShaderFireAframe;
					this.fire.setVisibility(false);
				}
				if (this.modelH2_O2_CL2.hasAttribute('shader-fire-small')) {
					this.fireSmall = this.modelH2_O2_CL2.components['shader-fire-small'] as unknown as IShaderFireSmallAframe;
					this.fireSmall.setVisibility(false);
				}
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}

				if (this.modelH2_O2_CL2) {
					this.modelH2_O2_CL2.object3D.visible = true;
				}
				if (this.modelCO2) {
					this.modelCO2.object3D.visible = false;
				}

				this.splintClean.visible = true;
				this.splintAfterFire.visible = false;

				if (this.annotationATriggerBtn) {
					this.annotationATriggerBtn.object3D.visible = true;
					this.annotationATriggerBtn?.object3D.position.set(0, 3, 0.1);
				}
				if (this.annotationBTriggerBtn) {
					this.annotationBTriggerBtn.object3D.visible = false;
					this.annotationBTriggerBtn.object3D.position.x = -50;
				}
				if (this.annotationCTriggerBtn) {
					this.annotationCTriggerBtn.object3D.visible = false;
					this.annotationCTriggerBtn.object3D.position.x = -50;
				}
				if (this.annotationDTriggerBtn) {
					this.annotationDTriggerBtn.object3D.visible = false;
					this.annotationDTriggerBtn.object3D.position.x = -50;
				}
			}

			const sceneO2 = () => {
				this.fire.setVisibility(false);
				stopSound();
				if (this.labelH2) {
					this.labelH2.visible = false;
				}
				if (this.labelO2) {
					this.labelO2.visible = true;
				}
				if (this.labelCL2) {
					this.labelCL2.visible = false;
				}

				if (this.H2) {
					this.H2.visible = false;
				}
				if (this.O2) {
					this.O2.visible = true;
				}
				if (this.CL2) {
					this.CL2.visible = false;
				}
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}

				if (this.modelH2_O2_CL2) {
					this.modelH2_O2_CL2.object3D.visible = true;
				}
				if (this.modelCO2) {
					this.modelCO2.object3D.visible = false;
				}

				this.splintClean.visible = false;
				this.splintAfterFire.visible = true;

				if (this.annotationATriggerBtn) {
					this.annotationATriggerBtn.object3D.visible = false;
					this.annotationATriggerBtn.object3D.position.x = -50;
				}
				if (this.annotationBTriggerBtn) {
					this.annotationBTriggerBtn.object3D.visible = true;
					this.annotationBTriggerBtn?.object3D.position.set(0, 3, 0.1);
				}
				if (this.annotationCTriggerBtn) {
					this.annotationCTriggerBtn.object3D.visible = false;
					this.annotationCTriggerBtn.object3D.position.x = -50;
				}
				if (this.annotationDTriggerBtn) {
					this.annotationDTriggerBtn.object3D.visible = false;
					this.annotationDTriggerBtn.object3D.position.x = -50;
				}
			}

			const sceneCl2 = () => {
				this.fire.setVisibility(false);
				stopSound();
				if (this.labelH2) {
					this.labelH2.visible = false;
				}
				if (this.labelO2) {
					this.labelO2.visible = false;
				}
				if (this.labelCL2) {
					this.labelCL2.visible = true;
				}

				if (this.H2) {
					this.H2.visible = false;
				}
				if (this.O2) {
					this.O2.visible = false;
				}
				if (this.CL2) {
					this.CL2.visible = true;
				}

				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}

				if (this.modelH2_O2_CL2) {
					this.modelH2_O2_CL2.object3D.visible = true;
				}
				if (this.modelCO2) {
					this.modelCO2.object3D.visible = false;
				}

				this.splintClean.visible = true;
				this.splintAfterFire.visible = false;

				if (this.annotationATriggerBtn) {
					this.annotationATriggerBtn.object3D.visible = false;
					this.annotationATriggerBtn.object3D.position.x = -50;
				}
				if (this.annotationBTriggerBtn) {
					this.annotationBTriggerBtn.object3D.visible = false;
					this.annotationBTriggerBtn.object3D.position.x = -50;
				}
				if (this.annotationCTriggerBtn) {
					this.annotationCTriggerBtn.object3D.visible = true;
					this.annotationCTriggerBtn?.object3D.position.set(0, 3, 0.1);
				}
				if (this.annotationDTriggerBtn) {
					this.annotationDTriggerBtn.object3D.visible = false;
					this.annotationDTriggerBtn.object3D.position.x = -50;
				}
			}

			const sceneCO2 = () => {
				this.fire.setVisibility(false);
				stopSound();
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}

				if (this.modelH2_O2_CL2) {
					this.modelH2_O2_CL2.object3D.visible = false;
				}
				if (this.modelCO2) {
					this.modelCO2.object3D.visible = true;
				}

				this.splintClean.visible = true;
				this.splintAfterFire.visible = false;

				if (this.annotationATriggerBtn) {
					this.annotationATriggerBtn.object3D.visible = false;
					this.annotationATriggerBtn.object3D.position.x = -50;
				}
				if (this.annotationBTriggerBtn) {
					this.annotationBTriggerBtn.object3D.visible = false;
					this.annotationBTriggerBtn.object3D.position.x = -50;
				}
				if (this.annotationCTriggerBtn) {
					this.annotationCTriggerBtn.object3D.visible = false;
					this.annotationCTriggerBtn.object3D.position.x = -50;
				}
				if (this.annotationDTriggerBtn) {
					this.annotationDTriggerBtn.object3D.visible = true;
					this.annotationDTriggerBtn.object3D.position.set(-1, 3, 0.2);
				}
			}

			this.currentAssetId = 0;
			this.el.sceneEl?.addEventListener('asset-change', (event) => {
				const customEvent = event as CustomEvent; // Cast event to CustomEvent
				const newAssetId = customEvent.detail.assetId;
				this.currentAssetId = newAssetId;
				if (newAssetId === 0) {
					sceneH2();
				}
				if (newAssetId === 1) {
					sceneO2();
				}
				if (newAssetId === 2) {
					sceneCl2();
				}
				if (newAssetId === 3) {
					sceneCO2();
				}
			});

			const initialiseAnimations = () => {
				const animatedProcessH2_O2_CL2 = this.modelH2_O2_CL2?.object3D.getObjectByName('Scene') as any;
				const animatedProcessCO2 = this.modelCO2?.object3D.getObjectByName('Scene') as any;

				this.mixer_1 = new THREE.AnimationMixer(animatedProcessH2_O2_CL2);
				this.mixer_2 = new THREE.AnimationMixer(animatedProcessCO2);

				const showH2Anim = animatedProcessH2_O2_CL2?.animations[2];
				const showO2Anim = animatedProcessH2_O2_CL2?.animations[3];
				const showCL2Anim = animatedProcessH2_O2_CL2?.animations[0];
				const showTurnOnBunsen = animatedProcessH2_O2_CL2?.animations[1];

				const showCO2Anim = animatedProcessCO2?.animations[0];

				this.actionH2Anim = this.mixer_1.clipAction(showH2Anim);
				this.actionO2Anim = this.mixer_1.clipAction(showO2Anim);
				this.actionCL2Anim = this.mixer_1.clipAction(showCL2Anim);
				this.actionTurnOnBunsen = this.mixer_1.clipAction(showTurnOnBunsen);

				this.actionCO2Anim = this.mixer_2.clipAction(showCO2Anim);
			};
			let buttonAEnabled = true;
			this.annotationAButtonHandler = () => {
				if (buttonAEnabled) {
					if (this.currentClips) {
						this.currentClips.forEach(currentClip => {
							currentClip.stop();
						});
					}

					buttonAEnabled = false;

					setTimeout(() => {
						this.fireSmall.setVisibility(true);
						this.splintClean.visible = false;
						this.splintAfterFire.visible = true;
					}, 1200);

					setTimeout(() => {
						this.fire.setVisibility(false);
						this.fireSmall.setVisibility(false);
						buttonAEnabled = true;
					}, 5000);


					playSoundOnTime();
					this.fire.setVisibility(true);

					this.currentClips[0] = this.actionTurnOnBunsen;
					this.actionTurnOnBunsen.reset();
					this.actionTurnOnBunsen.repetitions = 1;
					this.actionTurnOnBunsen.clampWhenFinished = true;
					this.actionTurnOnBunsen.play();

					this.currentClips[1] = this.actionH2Anim;
					this.actionH2Anim.reset();
					this.actionH2Anim.repetitions = 1;
					this.actionH2Anim.clampWhenFinished = false;
					this.actionH2Anim.play();

					if (this.onObjectSelected) {
						const title = 'Test for Hydrogen Gas';
						const body = 'Hydrogen gas is a product of many chemical reactions. You can test to see if hydrogen gas has been produced by holding a lit match over the test tube. If there is hydrogen gas, it will make a squeaky pop sound!';
						this.onObjectSelected({ title, body })
					} else {
						console.log('No object selected method');
					}
				}
			}

			let buttonBEnabled = true;
			this.annotationBButtonHandler = () => {
				if (buttonBEnabled) {
					if (this.currentClips) {
						this.currentClips.forEach(currentClip => {
							currentClip.stop();
						});
					}
					buttonBEnabled = false;

					this.splintClean.visible = false;
					this.splintAfterFire.visible = true;
	
					setTimeout(() => {
						this.fireSmall.setVisibility(true);
					}, 1600);
	
					setTimeout(() => {
						this.fireSmall.setVisibility(false);
						this.splintClean.visible = false;
						this.splintAfterFire.visible = true;
						buttonBEnabled = true;
					}, 5000);
	
					this.currentClips[1] = this.actionO2Anim;
					this.actionO2Anim.reset();
					this.actionO2Anim.repetitions = 1;
					this.actionO2Anim.clampWhenFinished = false;
					this.actionO2Anim.play();
	
					if (this.onObjectSelected) {
						const title = 'Test for Oxygen Gas';
						const body = 'You can test to see if an oxygen-producing reaction has taken place by holding a glowing splint in the test tube. If the splint relights this indicates the presence of oxygen.';
						this.onObjectSelected({ title, body })
					} else {
						console.log('No object selected method');
					}
				}
			}

			this.annotationCButtonHandler = () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}

				this.currentClips[1] = this.actionCL2Anim;
				this.actionCL2Anim.reset();
				this.actionCL2Anim.repetitions = 1;
				this.actionCL2Anim.clampWhenFinished = false;
				this.actionCL2Anim.play();

				if (this.onObjectSelected) {
					const title = 'Test for Chlorine Gas';
					const body = 'Chlorine gas is a product of many chemical reactions. You can test to see if chlorine gas has been produced using damp litmus paper. If exposed to chlorine, the litmus paper will briefly turn red and then bleach white.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.annotationDButtonHandler = () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}

				this.currentClips[1] = this.actionCO2Anim;
				this.actionCO2Anim.reset();
				this.actionCO2Anim.repetitions = 1;
				this.actionCO2Anim.clampWhenFinished = false;
				this.actionCO2Anim.play();

				if (this.onObjectSelected) {
					const title = 'Test for Carbon Dioxide';
					const body = 'You can test to see if a carbon dioxide-producing reaction has taken place by bubbling the gas through limewater. If the gas contains carbon dioxide, the limewater will turn milky or cloudy white.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.annotationATriggerBtn = poolButtons.requestEntity();
				this.annotationATriggerBtn?.object3D.position.set(0, 3, 0.1);
				this.annotationATriggerBtn?.object3D.scale.set(1.1, 1.1, 1.1);
				this.annotationATriggerBtn?.play();
				this.annotationATriggerBtn?.addEventListener('click', () => {
					this.annotationAButtonHandler();
					if (this.annotationATriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationATriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationATriggerBtn;
					}
				});

				this.annotationBTriggerBtn = poolButtons.requestEntity();
				this.annotationBTriggerBtn?.object3D.position.set(0, 3, 0.1);
				this.annotationBTriggerBtn?.object3D.scale.set(1.1, 1.1, 1.1);
				this.annotationBTriggerBtn?.play();
				this.annotationBTriggerBtn?.addEventListener('click', () => {
					this.annotationBButtonHandler();
					if (this.annotationBTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationBTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationBTriggerBtn;
					}
				});

				this.annotationCTriggerBtn = poolButtons.requestEntity();
				this.annotationCTriggerBtn?.object3D.position.set(0, 3, 0.1);
				this.annotationCTriggerBtn?.object3D.scale.set(1.1, 1.1, 1.1);
				this.annotationCTriggerBtn?.play();
				this.annotationCTriggerBtn?.addEventListener('click', () => {
					this.annotationCButtonHandler();
					if (this.annotationCTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationCTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationCTriggerBtn;
					}
				});

				this.annotationDTriggerBtn = poolButtons.requestEntity();
				this.annotationDTriggerBtn?.object3D.position.set(-1, 3, 0.2);
				this.annotationDTriggerBtn?.object3D.scale.set(1.1, 1.1, 1.1);
				this.annotationDTriggerBtn?.play();
				this.annotationDTriggerBtn?.addEventListener('click', () => {
					this.annotationDButtonHandler();
					if (this.annotationDTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationDTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationDTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationDTriggerBtn;
					}
				});
			}
		},
		tick(this: ITestsForCommonGasesSceneAframe, time: number, deltaTime: number) {
			if (this.mixer_1) {
				this.mixer_1.update(deltaTime * 0.001);
			}
			if (this.mixer_2) {
				this.mixer_2.update(deltaTime * 0.001);
			}
		},
	},
};
export { TestsForCommonGasesScene as TestsForCommonGasesSceneComponent }