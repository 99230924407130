import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IMethodSystemAframe } from "lib/aframe/systems/method-system";
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface IAtomScene {
    animationAction: THREE.AnimationAction;
    isButtonsInitialised: boolean;
    mixer: THREE.AnimationMixer;
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: (...args: any[]) => void;
    onLessonStart: (...args: any[]) => void;
    el: AFrame.Entity;
};

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

const AtomSceneComponent =  {
    name: "atom-scene",
    val: {
        init(this: IAtomScene) {
            
            this.isButtonsInitialised = false;
            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {

                
                
                const atom = document.getElementById('atom') as AFrame.Entity;
                atom.classList.remove('cantap');
                // atom.object3D.traverse((obj) => {
                //     console.log(obj);
                // })

                // setup callbacks
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "method-system": IMethodSystemAframe };
                };
                const methodSystem = scene.systems["method-system"];
                const callback = methodSystem.getCallback('onLessonStart');
                if (callback) {
                    this.onLessonStart = callback;
                }

                const callback2 = methodSystem.getCallback('onObjectSelected');
                if (callback2) {
                    this.onObjectSelected = callback2;
                }

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
                // moved button initialisation to lesson-start event
                // if initialising here, the buttons are not parenting to objects correctly
                // only applies to this lesson
				
                // start animations
                initialiseAnimations();

                // add event listeners
                this.el.sceneEl?.addEventListener('anim-toggle', (e) => {
                    const event = e as CustomEvent<{ toggle: boolean }>;
                    if (event.detail.toggle) {
                        if (this.animationAction) this.animationAction.paused = false;
                    } else {
                        if (this.animationAction) this.animationAction.paused = true;
                    }
                });
            });

        

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.onLessonStart();

                // initialise buttons
                if (!this.isButtonsInitialised) {
                    
                    this.isButtonsInitialised = true;
                    if (this.poolEntity.hasLoaded) {
                        initialiseButtons();
                    } else {
                        this.poolEntity.addEventListener('loaded', () => {
                            initialiseButtons();
                        });
                    }
                }
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: atom; scale: 20 20 20; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            

         

            const initialiseAnimations = () => {
                const atom = document.getElementById('atom') as AFrame.Entity;
                const animEl = atom.object3D.getObjectByName('LithiumAtom');
                if (animEl) {
                    this.mixer = new THREE.AnimationMixer(animEl);
                    const [clip] = animEl.animations;
                    this.animationAction = this.mixer.clipAction(clip);
                    this.animationAction.timeScale = 0.5;
                    this.animationAction.play();
                }
                
                
            }

           

            const protonsButtonHandler = () => {
                const title = 'Protons'
                const body = 'Protons are found in the nucleus with a relative charge of +1 and a relative mass of 1. The number of protons an atom has defines what element it is. For example, hydrogen always has one proton, lithium always has three protons and iron has 26 protons.'

                if (this.onObjectSelected) {
                    console.log('Calling onObjectSelected callback');
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const neutronsButtonHandler = () => {
                const title = 'Neutrons'
                const body = 'Neutrons have a charge of zero and a relative mass of 1 (the same as the proton).</br>Neutrons are found in the nucleus of the atom, which is around ten thousand times smaller than the atom as a whole.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const electronButtonHandler = () => {
                const title = 'Electrons'
                const body = 'Electrons have a relative charge of -1 (the opposite of a proton) and a relative mass of 0.0005 (two thousand times lighter than the proton).'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const energyButtonHandler = () => {
                const title = 'Energy Levels'
                const body = 'Electrons can exist at different distances from the nucleus, each with a different energy. By absorbing electromagnetic radiation, electrons can move to higher energy levels (further from the nucleus), and can drop back down by emitting the radiation again.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }


           
            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                // for button parenting
                const atom = document.getElementById('atom') as AFrame.Entity;
                const nucleus = atom.object3D.getObjectByName('Nucleus');
                const outerRing = atom.object3D.getObjectByName('OuterRing');

                const neutronsTriggetBtn = poolButtons.requestEntity();
                neutronsTriggetBtn?.setAttribute('position', '-0.03 0.04 0');
                neutronsTriggetBtn?.play();
                neutronsTriggetBtn?.addEventListener('click', () => {
                    neutronsButtonHandler();
                    if (neutronsTriggetBtn) {
                        this.annotationComponent.setObjectToFollow(neutronsTriggetBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(neutronsTriggetBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = neutronsTriggetBtn
                    }
                });
                if (neutronsTriggetBtn && nucleus) nucleus.add(neutronsTriggetBtn.object3D);
                

                const protonsTriggerBtn = poolButtons.requestEntity()
                protonsTriggerBtn?.setAttribute('position', '0.03 -0.01 0.045')
                protonsTriggerBtn?.play()
                protonsTriggerBtn?.addEventListener('click', () => {
                    protonsButtonHandler();
                    if (protonsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(protonsTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(protonsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = protonsTriggerBtn
                    }
                });
                if (protonsTriggerBtn && nucleus) nucleus.add(protonsTriggerBtn.object3D);

                const electronTriggerBtn = poolButtons.requestEntity()
                electronTriggerBtn?.setAttribute('position', '0.01 0.01 -0.175')
                electronTriggerBtn?.play()
                electronTriggerBtn?.addEventListener('click', () => {
                    electronButtonHandler();
                    if (electronTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(electronTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(electronTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = electronTriggerBtn
                    }
                });
                if (electronTriggerBtn && outerRing) outerRing.add(electronTriggerBtn.object3D);

                const energyTriggerBtn = poolButtons.requestEntity()
                energyTriggerBtn?.setAttribute('position', '0.01 0.01 0.175')
                energyTriggerBtn?.play()
                energyTriggerBtn?.addEventListener('click', () => {
                    energyButtonHandler();
                    if (energyTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(energyTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(energyTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = energyTriggerBtn
                    }
                });
                if (energyTriggerBtn && outerRing) {
                    outerRing.add(energyTriggerBtn.object3D);
                }

            }
        },
        tick(this: IAtomScene, time: number, timeDelta: number) {
            if (this.mixer) {
                this.mixer.update(timeDelta / 1000);    
            }
        },
    }
}  
export {AtomSceneComponent as AtomScene}