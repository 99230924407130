import { Box, Stack, Typography, Link, useTheme } from '@mui/material';
import { Footer } from '../../../shared/components/Footer';
import { useTranslation } from 'react-i18next';
import Header from '../../../shared/components/Header';

export function CookiePage() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const i18nPath = 'COOKIES_PAGE.';

  return (
    <Box bgcolor={theme.palette.background.default}>
      <Header />
      <Box maxWidth={1020} marginX='auto'>
        <Stack padding={4.2} spacing={2} justifyContent='center'>
          <Typography
            fontFamily='GilroyBold'
            variant='h3'
            color={theme.palette.text.primary}
          >
            {t(i18nPath + 'TITLE')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_1')}
          </Typography>
          <Typography variant='h5' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_2')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_3')}
          </Typography>
          <Typography variant='h5' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_4')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_5')}
          </Typography>
          <Typography variant='h5' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_6')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_7')}
          </Typography>
          <Typography variant='h5' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_8')}
          </Typography>
          <Typography component='li' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_9')}
            <Typography
              variant='body1'
              color={theme.palette.text.secondary}
              paddingLeft='22px'
            >
              {t(i18nPath + 'TEXT_10')}
            </Typography>
          </Typography>
          <Typography component='li' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_11')}
            <Typography
              variant='body1'
              color={theme.palette.text.secondary}
              paddingLeft='22px'
            >
              {t(i18nPath + 'TEXT_12')}
            </Typography>
          </Typography>
          <Typography component='li' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_13')}
            <Typography
              variant='body1'
              color={theme.palette.text.secondary}
              paddingLeft='22px'
            >
              {t(i18nPath + 'TEXT_14')}
            </Typography>
          </Typography>
          <Typography variant='h5' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_15')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_16')}
          </Typography>
          <Typography component='li' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_17')}
            <br />
            <br />
            {t(i18nPath + 'TEXT_18')}
          </Typography>
          <Typography variant='h5' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_19')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_20')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_21')}{' '}
            <Link
              href='https://www.cookiepolicygenerator.com/sample-cookies-policy/'
              target='_blank'
            >
              {t(i18nPath + 'TEXT_22')}
            </Link>
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_23')}
          </Typography>
          <Typography component='li' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_24')}
          </Typography>
          <Typography component='li' color={theme.palette.text.primary}>
            {t(i18nPath + 'TEXT_25')}
          </Typography>
        </Stack>
      </Box>
      <Footer onMainPage={false} />
    </Box>
  );
}
