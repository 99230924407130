import { styled, Box } from '@mui/material';

export const StyledOverviewMenuItem = styled(Box, {
  name: 'StyledOverviewMenuItem',
})({
  background: 'rgba(255, 253, 1, 1)',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '13px 20px',
  cursor: 'pointer',
});
