import React, {useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import {WorldButtonComponent} from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import {CloningControl} from './scene-component';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
    Annotation,
    AnnotationState,
} from '../../../lib/aframe/components/annotation';
import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
} from 'shared/constants/hint-items';
import {LoadersPermissonsEnum} from 'shared/enums';
import {TapPlaceComponent} from "../../../lib/aframe/components/tap-place-object";

const introText =
    'Tap on the annotations to learn all about the cutting cloning procedure and grow your own clone!';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT];

const BiologyCloningCuttingAframe = () => {
    const [selectedObject, setSelectedObject] = useState<{
        title: string;
        body: string;
    } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(
        AnnotationState.None
    );
    const [lessonStarted, setLessonStarted] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };

    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };

    const onLessonStarted = () => {
        setLessonStarted(true);
    };

    const onLessonRecenter = () => {
        setLessonStarted(false);
    };

    const permissionsConfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    }

    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                lessonStarted={lessonStarted}
                onAnnotationStateChanged={onAnnotationStateChanged}
                hintItems={hintItems}
                permissions={permissions}
                returnToStartButtonLoad={true}
            />
            <AFrameScene
                sceneHtml={require('./bio-cloning-cutting-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    CloningControl,
                    TapPlaceComponent,
                    WorldButtonComponent,
                    FaceCameraComponent,
                    Annotation,
                ]}
                onObjectSelected={onObjectSelected}
                onLessonStart={onLessonStarted}
                onLessonRecenter={onLessonRecenter}
                annotationState={annotationState}
                permissionsСonfirmation={permissionsConfirmation}
            />
        </React.Fragment>
    );
};
export {BiologyCloningCuttingAframe};
