import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import './styles/instruction.css';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IProcessControllAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;
	actionStarchModel: THREE.AnimationAction;
	actionWaterFill: THREE.AnimationAction;
	actionFoodMix: THREE.AnimationAction;
	actionBeakerToTestTube: THREE.AnimationAction;
	actionIodineDropper: THREE.AnimationAction;
	actionDropper: THREE.AnimationAction;
	currentClip: THREE.AnimationAction;
	mixerStep_1: THREE.AnimationMixer;
	mixerStep_2: THREE.AnimationMixer;
	mixerStep_3: THREE.AnimationMixer;
	mixerStep_4: THREE.AnimationMixer;
	mixerStep_5: THREE.AnimationMixer;
	el: AFrame.Entity;
	currentAssetId: number;
	instruction: HTMLElement | null;

	starch: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	biology4_1: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	biology4_2: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	biology4_3: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	biology4_4: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	biology4_5: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;

	cellModel_1: THREE.Object3D<THREE.Object3DEventMap> | undefined;
	cellModel_0: THREE.Object3D<THREE.Object3DEventMap> | undefined;

	poolButtons: PoolComponent;
	foodTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	pestleTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	waterTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	funnelTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	bottleTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;

	foodButtonHandler: () => void;
	waterButtonHandler: () => void;
	funnelButtonHandler: () => void;
	pestleButtonHandler: () => void;
	bottleButtonHandler: () => void;
	shakeButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const FoodTestsStarchScene = {
	name: 'food-test-scene',
	val: {
		init(this: IProcessControllAframe) {
			var isInitialised = false;
			var counter = 0;
			this.el.addEventListener('model-loaded', () => {
				counter++;
				if (counter < 5) return
				if (!isInitialised) {
					this.instruction = document.getElementById('instruction-starch');
					if (this.instruction) {
						this.instruction.classList.add('invisible')
					}

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;
							initialiseButtons();
						});
					}

					this.starch = document.getElementById('foodTestsStarchScenes') as AFrame.Entity;

					this.biology4_1 = document.getElementById('Biology-4_1') as AFrame.Entity;
					this.biology4_2 = document.getElementById('Biology-4_2') as AFrame.Entity;
					this.biology4_3 = document.getElementById('Biology-4_3') as AFrame.Entity;
					this.biology4_4 = document.getElementById('Biology-4_4') as AFrame.Entity;
					this.biology4_5 = document.getElementById('Biology-4_5') as AFrame.Entity;

					// Animation block
					const animatedBiolody4_1 = this.biology4_1.object3D.getObjectByName('Biology4_1StarchFoods_V3') as any;
					this.mixerStep_1 = new THREE.AnimationMixer(animatedBiolody4_1);
					const StarchModelAnimation = animatedBiolody4_1.animations[0];
					this.actionStarchModel = this.mixerStep_1.clipAction(StarchModelAnimation);

					const animatedBiolody4_2 = this.biology4_2.object3D.getObjectByName('Scene') as any;
					this.mixerStep_2 = new THREE.AnimationMixer(animatedBiolody4_2)
					const FoodMixAnimation = animatedBiolody4_2.animations[0]
					this.actionFoodMix = this.mixerStep_2.clipAction(FoodMixAnimation)

					const animatedBiolody4_3 = this.biology4_3.object3D.getObjectByName('Scene') as any;
					this.mixerStep_3 = new THREE.AnimationMixer(animatedBiolody4_3);
					const WaterFillAnimation = animatedBiolody4_3.animations[0];
					this.actionWaterFill = this.mixerStep_3.clipAction(WaterFillAnimation);

					const animatedBiolody4_4 = this.biology4_4.object3D.getObjectByName('Scene') as any;
					this.mixerStep_4 = new THREE.AnimationMixer(animatedBiolody4_4);
					const BeakerToTestTubeAnimation = animatedBiolody4_4.animations[0];
					this.actionBeakerToTestTube = this.mixerStep_4.clipAction(BeakerToTestTubeAnimation);

					const animatedBiolody4_5 = this.biology4_5.object3D.getObjectByName('Scene') as any;
					this.mixerStep_5 = new THREE.AnimationMixer(animatedBiolody4_5);
					const IodineDropperAnimation = animatedBiolody4_5.animations[0];
					const DropperAnimation = animatedBiolody4_5.animations[1];
					this.actionIodineDropper = this.mixerStep_5.clipAction(IodineDropperAnimation);
					this.actionDropper = this.mixerStep_5.clipAction(DropperAnimation);

					const foodGroup = this.biology4_1.object3D.getObjectByName('Biology4_1StarchFoods_V3_1');
					this.cellModel_1 = foodGroup?.getObjectByName('Model_(1)');
					this.cellModel_0 = foodGroup?.getObjectByName('Model');
					// Animation block

					if (this.cellModel_0) { this.cellModel_0.visible = false };
					if (this.cellModel_1) { this.cellModel_1.visible = false };

					this.biology4_3.object3D.position.x = -0.9;
					this.waterTriggerBtn?.object3D.position.set(0.1, 0.1, 0.01);
					this.biology4_4.object3D.position.x = -1.1;
					this.funnelTriggerBtn?.object3D.position.set(0.1, 0.15, 0.03);

					this.biology4_1.object3D.visible = true;
					this.biology4_2.object3D.visible = false;
					this.biology4_3.object3D.visible = false;
					this.biology4_4.object3D.visible = false;
					this.biology4_5.object3D.visible = false;
					if (this.foodTriggerBtn) {
						this.foodTriggerBtn.object3D.visible = true;
					}
					if (this.pestleTriggerBtn) {
						this.pestleTriggerBtn.object3D.visible = false;
					}
					if (this.waterTriggerBtn) {
						this.waterTriggerBtn.object3D.visible = false;
					}
					if (this.funnelTriggerBtn) {
						this.funnelTriggerBtn.object3D.visible = false;
					}
					if (this.bottleTriggerBtn) {
						this.bottleTriggerBtn.object3D.visible = false;
					}

					this.currentAssetId = 0;
					this.el.sceneEl?.addEventListener('asset-change', (event) => {
						const customEvent = event as CustomEvent; // Cast event to CustomEventhis.t
						const newAssetId = customEvent.detail.assetId;
						if (newAssetId === 0) {
							this.starch.object3D.position.set(0, 0, -4);
							this.biology4_1.object3D.visible = true;
							this.biology4_2.object3D.visible = false;
							this.biology4_3.object3D.visible = false;
							this.biology4_4.object3D.visible = false;
							this.biology4_5.object3D.visible = false;
							if (this.foodTriggerBtn) {
								this.foodTriggerBtn.object3D.visible = true;
							}
							if (this.pestleTriggerBtn) {
								this.pestleTriggerBtn.object3D.visible = false;
							}
							if (this.waterTriggerBtn) {
								this.waterTriggerBtn.object3D.visible = false;
							}
							if (this.funnelTriggerBtn) {
								this.funnelTriggerBtn.object3D.visible = false;
							}
							if (this.bottleTriggerBtn) {
								this.bottleTriggerBtn.object3D.visible = false;
							}
							if (this.instruction) {
								this.instruction.classList.remove('visible')
								this.instruction.classList.add('invisible')
							}
						}
						if (newAssetId === 1) {
							this.biology4_1.object3D.visible = false

							this.instruction?.classList.remove('invisible')
							this.instruction?.classList.add('visible')

							const step1 = document.getElementById("step-1");
							if (step1) {
								step1.addEventListener("click", () => {
									this.biology4_2.object3D.visible = true;
									this.starch.object3D.position.x = 0;
									this.biology4_3.object3D.visible = false;
									this.biology4_4.object3D.visible = false;
									this.biology4_5.object3D.visible = false;
									if (this.foodTriggerBtn) {
										this.foodTriggerBtn.object3D.visible = false;
									}
									if (this.pestleTriggerBtn) {
										this.pestleTriggerBtn.object3D.visible = true;
									}
									if (this.waterTriggerBtn) {
										this.waterTriggerBtn.object3D.visible = false;
									}
									if (this.funnelTriggerBtn) {
										this.funnelTriggerBtn.object3D.visible = false;
									}
									if (this.bottleTriggerBtn) {
										this.bottleTriggerBtn.object3D.visible = false;
									}
									annotationClose()
								});
							}

							const step2 = document.getElementById("step-2");
							if (step2) {
								step2.addEventListener("click", () => {
									this.biology4_2.object3D.visible = false;
									this.biology4_3.object3D.visible = true;
									this.starch.object3D.position.x = 0;
									this.biology4_4.object3D.visible = false;
									this.biology4_5.object3D.visible = false;
									if (this.foodTriggerBtn) {
										this.foodTriggerBtn.object3D.visible = false;
									}
									if (this.pestleTriggerBtn) {
										this.pestleTriggerBtn.object3D.visible = false;
									}
									if (this.waterTriggerBtn) {
										this.waterTriggerBtn.object3D.visible = true;
									}
									if (this.funnelTriggerBtn) {
										this.funnelTriggerBtn.object3D.visible = false;
									}
									if (this.bottleTriggerBtn) {
										this.bottleTriggerBtn.object3D.visible = false;
									}
									annotationClose()
								});
							}

							const step3 = document.getElementById("step-3");
							if (step3) {
								step3.addEventListener("click", () => {
									this.biology4_2.object3D.visible = false;
									this.biology4_3.object3D.visible = false;
									this.biology4_4.object3D.visible = true;
									this.starch.object3D.position.x = 0;
									this.biology4_5.object3D.visible = false;
									if (this.foodTriggerBtn) {
										this.foodTriggerBtn.object3D.visible = false;
									}
									if (this.pestleTriggerBtn) {
										this.pestleTriggerBtn.object3D.visible = false;
									}
									if (this.waterTriggerBtn) {
										this.waterTriggerBtn.object3D.visible = false;
									}
									if (this.funnelTriggerBtn) {
										this.funnelTriggerBtn.object3D.visible = true;
									}
									if (this.bottleTriggerBtn) {
										this.bottleTriggerBtn.object3D.visible = false;
									}
									annotationClose()
								});
							}

							const step4 = document.getElementById("step-4");
							if (step4) {
								step4.addEventListener("click", () => {
									this.biology4_2.object3D.visible = false;
									this.biology4_3.object3D.visible = false;
									this.biology4_4.object3D.visible = false;
									this.biology4_5.object3D.visible = true;
									this.starch.object3D.position.x = 1;
									if (this.foodTriggerBtn) {
										this.foodTriggerBtn.object3D.visible = false;
									}
									if (this.pestleTriggerBtn) {
										this.pestleTriggerBtn.object3D.visible = false;
									}
									if (this.waterTriggerBtn) {
										this.waterTriggerBtn.object3D.visible = false;
									}
									if (this.funnelTriggerBtn) {
										this.funnelTriggerBtn.object3D.visible = false;
									}
									if (this.bottleTriggerBtn) {
										this.bottleTriggerBtn.object3D.visible = true;
									}
									annotationClose()
								});
							}
						}
					});
					isInitialised = true
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started');
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place');
					this.el.sceneEl?.removeChild(ring);
				}
			})



			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: foodTestsStarchScenes; scale: 25 25 25;');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					if (this.currentAssetId === 1) {

					}
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					
					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
					
					const step2 = document.getElementById("step-2");
					if (step2) {
						step2.addEventListener("click", () => {
							this.biology4_3.object3D.position.x = -0.9;
							this.waterTriggerBtn?.object3D.position.set(0.1, 0.1, 0.01);
							annotationClose()
						});
					}

					const step3 = document.getElementById("step-3");
					if (step3) {
						step3.addEventListener("click", () => {
							this.biology4_4.object3D.position.x = -1.1;
							this.funnelTriggerBtn?.object3D.position.set(0.1, 0.15, 0.03);
							annotationClose()
						});
					}
				}

			});

			const annotationClose = () => {
				const scene = document.querySelector('a-scene');
				if (scene) {
					scene.emit('annotation-close');
					// close quiz
				}
			}

			this.el.sceneEl?.addEventListener('quiz-opened', () => {
				this.instruction?.classList.add('instruction-slide-up');
				this.instruction?.classList.remove('instruction-slide-down');
			})
			this.el.sceneEl?.addEventListener('quiz-closed', () => {
				this.instruction?.classList.add('instruction-slide-down');
				this.instruction?.classList.remove('instruction-slide-up');
			})

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			this.foodButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionStarchModel;
				this.actionStarchModel.reset();
				this.actionStarchModel.repetitions = 1;
				this.actionStarchModel.clampWhenFinished = true;
				this.actionStarchModel.play();
				if (this.cellModel_0) { this.cellModel_0.visible = true };
				if (this.cellModel_1) { this.cellModel_1.visible = true };
				if (this.onObjectSelected) {
					const title = 'Starch';
					const body = 'Starch is a complex carbohydrate. It is made up of many glucose molecules linked in a chain-like structure. Starchy foods are a source of energy and serve as a primary source of various nutrients in a balanced diet.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.pestleButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionFoodMix;
				this.actionFoodMix.reset();
				this.actionFoodMix.repetitions = 1;
				this.actionFoodMix.clampWhenFinished = true;
				this.actionFoodMix.play();
				if (this.onObjectSelected) {
					const title = 'Step 1';
					const body = 'Grind the food until it is paste-like. A pestle and mortar is used to grind the food.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.waterButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionWaterFill;
				this.actionWaterFill.reset();
				this.actionWaterFill.repetitions = 1;
				this.actionWaterFill.clampWhenFinished = true;
				this.actionWaterFill.play();

				if (this.onObjectSelected) {
					const title = 'Step 2';
					const body = 'After transferring the food into a beaker, add water to the beaker.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.funnelButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionBeakerToTestTube;
				this.actionBeakerToTestTube.reset();
				this.actionBeakerToTestTube.repetitions = 1;
				this.actionBeakerToTestTube.clampWhenFinished = true;
				this.actionBeakerToTestTube.play();
				if (this.onObjectSelected) {
					const title = 'Step 3';
					const body = 'Filter the food sample to ensure no solid particles interfere with the results. Use a funnel and filter paper to obtain 2 cm<span class="superscript">3</span> of filtrate.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.bottleButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionIodineDropper;
				this.actionIodineDropper.reset();
				this.actionIodineDropper.repetitions = 1;
				this.actionIodineDropper.clampWhenFinished = true;
				this.actionIodineDropper.play();

				if (this.onObjectSelected) {
					const title = 'Step 4';
					const body = 'The reagent used to test for starch is <b>iodine</b>. Add 2 cm<span class="superscript">3</span> to the food sample and shake the test tube gently and observe the results. A positive starch test gives a blue-black colour. If there is no starch present the sample will be an orange-brown colour.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			const initialiseButtons = () => {
				this.foodTriggerBtn = this.poolButtons.requestEntity();
				this.foodTriggerBtn?.object3D.position.set(-0.05, 0.05, 0.12);
				this.foodTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
				this.foodTriggerBtn?.play();
				this.foodTriggerBtn?.addEventListener('click', () => {
					this.foodButtonHandler();
					if (this.foodTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.foodTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.foodTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.foodTriggerBtn;
					}
				});
				this.el.sceneEl?.addEventListener('asset-change', (event) => {
					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						this.foodTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
					}
					if (newAssetId === 1) {
						this.foodTriggerBtn?.object3D.scale.set(0, 0, 0);
					}
				});
				this.pestleTriggerBtn = this.poolButtons.requestEntity();
				this.pestleTriggerBtn?.object3D.position.set(0, 0.1, 0.072);
				this.pestleTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
				this.el.sceneEl?.addEventListener('asset-change', (event) => {

					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						this.pestleTriggerBtn?.object3D.scale.set(0, 0, 0);
					}
					if (newAssetId === 1) {
						this.pestleTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
					}

				});
				this.pestleTriggerBtn?.play();
				this.pestleTriggerBtn?.addEventListener('click', () => {
					this.pestleButtonHandler()
					if (this.pestleTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.pestleTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.pestleTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.pestleTriggerBtn;
					}
				});
				this.waterTriggerBtn = this.poolButtons.requestEntity();
				this.waterTriggerBtn?.object3D.position.set(1.0008, 0.12, 0.01);
				this.waterTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
				this.el.sceneEl?.addEventListener('asset-change', (event) => {

					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						this.waterTriggerBtn?.object3D.scale.set(0, 0, 0);
					}
					if (newAssetId === 1) {
						this.waterTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
					}

				});
				this.waterTriggerBtn?.play();
				this.waterTriggerBtn?.addEventListener('click', () => {
					this.waterButtonHandler()
					if (this.waterTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.waterTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.waterTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.waterTriggerBtn;
					}
				});
				this.funnelTriggerBtn = this.poolButtons.requestEntity();
				this.funnelTriggerBtn?.object3D.position.set(1.1999, 0.15, 0.03);
				this.funnelTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
				this.el.sceneEl?.addEventListener('asset-change', (event) => {

					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						this.funnelTriggerBtn?.object3D.scale.set(0, 0, 0);
					}
					if (newAssetId === 1) {
						this.funnelTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
					}

				});
				this.funnelTriggerBtn?.play();
				this.funnelTriggerBtn?.addEventListener('click', () => {
					this.funnelButtonHandler()
					if (this.funnelTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.funnelTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.funnelTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.funnelTriggerBtn;
					}
				});
				this.bottleTriggerBtn = this.poolButtons.requestEntity();
				this.bottleTriggerBtn?.object3D.position.set(-0.001, 0.0002, 0.02);
				this.bottleTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
				this.el.sceneEl?.addEventListener('asset-change', (event) => {

					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						this.bottleTriggerBtn?.object3D.scale.set(0, 0, 0);
					}
					if (newAssetId === 1) {
						this.bottleTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1);
					}
				});
				this.bottleTriggerBtn?.play();
				this.bottleTriggerBtn?.addEventListener('click', () => {
					this.bottleButtonHandler()
					if (this.bottleTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.bottleTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.bottleTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.bottleTriggerBtn;
					}
				});
			}
		},
		tick(this: IProcessControllAframe, time: number, deltaTime: number) {
			if (this.mixerStep_1) {
				this.mixerStep_1.update(deltaTime * 0.001);
			}
			if (this.mixerStep_2) {
				this.mixerStep_2.update(deltaTime * 0.001);
			}
			if (this.mixerStep_3) {
				this.mixerStep_3.update(deltaTime * 0.001);
			}
			if (this.mixerStep_4) {
				this.mixerStep_4.update(deltaTime * 0.001);
			}
			if (this.mixerStep_5) {
				this.mixerStep_5.update(deltaTime * 0.001);
			}
		},
	},
};
export { FoodTestsStarchScene as FoodTestsStarchSceneComponent }