import type { Actions, State } from './typings';
import { changeFiltersProducer, fetchTrainingsProducer } from './Actions';

export function reducer(state: State, action: Actions) {
  switch (action.type) {
    case 'trainings/fetch':
      return fetchTrainingsProducer(state, action.payload);
    case 'trainings/changeFilters':
      return changeFiltersProducer(state, action.payload);
    default:
      return { ...state };
  }
}
