import { useMemo } from 'react';
import * as Yup from 'yup';

export function useContactUsValidationSchema() {
  return useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required('COMMON.FORM_ERROR.REQUIRED')
          .min(3, 'COMMON.FORM_ERROR.MIN_LENGTH')
          .max(255, 'COMMON.FORM_ERROR.MAX_LENGTH')
          .email('COMMON.FORM_ERROR.EMAIL'),
        name: Yup.string()
          .required('COMMON.FORM_ERROR.REQUIRED')
          .max(255, 'COMMON.FORM_ERROR.MAX_LENGTH'),
        organization: Yup.string()
          .required('COMMON.FORM_ERROR.REQUIRED')
          .max(255, 'COMMON.FORM_ERROR.MAX_LENGTH'),
      }),
    []
  );
}
