import {
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DividerGray } from './DividerGray';
import { IconImage } from './IconImage';
import { DisplayEnum } from '../enums';
import { useTranslation } from 'react-i18next';

import LinkedinLogo from '../../assets/linkedin.svg';
import InstagramLogo from '../../assets/instagram.svg';
import TicTokLogo from '../../assets/tiktok.svg';
import TwitterLogo from '../../assets/twitter.svg';
import YouTubeLogo from '../../assets/youtube.svg';

type Props = {
  showAdditionalInfo?: boolean;
  onMainPage: boolean;
};

export function Footer(props: Props) {
  const { showAdditionalInfo, onMainPage } = props;

  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const { t } = useTranslation();

  return (
    <Stack
      width={xlDisplay ? '1180px' : '100%'}
      mt={xlDisplay ? 0 : 3}
      mx='auto'
      px={xlDisplay ? 0 : mobile ? 2 : 3}
      pb={3}
      boxSizing='border-box'
    >
      <DividerGray />

      <Stack
        direction={mobile ? 'column' : 'row'}
        justifyContent={xlDisplay ? 'space-between' : 'center'}
        flexWrap={xlDisplay ? 'nowrap' : 'wrap'}
        mt={3}
      >
        <Stack
          direction='row'
          spacing={2.8}
          justifyContent='space-between'
          mb={xlDisplay ? 0 : 3}
        >
          <Link href='https://twitter.com/bridgelearning1' target='_blank'>
            <IconImage src={TwitterLogo} alt='Twitter Logo' height='26px' />
          </Link>
          <Link
            href='https://www.youtube.com/channel/UC-zHgMRyo6EOBchJpeIzFZw'
            target='_blank'
          >
            <IconImage src={YouTubeLogo} alt='YouTubeLogo' height='26px' />
          </Link>
          <Link
            href='https://www.instagram.com/bridgelearningtechnologies/'
            target='_blank'
          >
            <IconImage src={InstagramLogo} alt='Instagram Logo' height='26px' />
          </Link>
          <Link
            href='https://www.linkedin.com/company/bridge-learning-technologies-ltd/'
            target='_blank'
          >
            <IconImage src={LinkedinLogo} alt='Linkedin Logo' height='26px' />
          </Link>
          <Link href='https://www.tiktok.com/@bridgelt' target='_blank'>
            <IconImage src={TicTokLogo} alt='TicTok Logo' height='26px' />
          </Link>
        </Stack>

        <Stack mb={xlDisplay ? 0 : 3} ml={tablet ? 2 : 0}>
          <Link
            color={theme.palette.text.secondary}
            href='https://bridgelt.com/'
            target='_blank'
          >
            <Typography
              color={theme.palette.text.secondary}
              fontFamily='GilroyMedium'
              textAlign={mobile ? 'center' : 'start'}
            >
              {t('COMMON.LINK.BRIDGE')}
            </Typography>
          </Link>
        </Stack>

        <Stack
          direction='row'
          justifyContent={mobile ? 'space-between' : 'center'}
          spacing={1}
        >
          <Link
            color={theme.palette.text.secondary}
            target='_blank'
            href='/privacy-policy'
          >
            {t('COMMON.LINK.PRIVACY_POLICY')}
          </Link>
          <Link
            color={theme.palette.text.secondary}
            target='_blank'
            href='/terms-and-conditions'
          >
            {t('COMMON.LINK.TERMS_AND_CONDITIONS')}
          </Link>
          <Link
            color={theme.palette.text.secondary}
            target='_blank'
            href='/cookie'
          >
            {t('COMMON.LINK.COOKIES')}
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
}
