// AnnotationContainer.tsx
import React, { Fragment } from 'react';
import closeIcon from '../../assets/img/Close.png';
import '../styles/annotations.css';
import '../styles/transitions.css';

interface Props {
  title: string;
  body: string;
  image?: string;
  imageExtra?: string;
  onCloseClick: () => void;
  visible: boolean;
}

const AnnotationContainer: React.FC<Props> = ({ title, body, image, imageExtra, onCloseClick, visible }) => {
  const visibilityClass = visible ? (imageExtra ? 'heightContainer ': '') : 'shrink-complete';
  return (
    <Fragment>
      <div id="annotationContainer" className={`${visibilityClass}`}>
        <div className="annotationHeader">
          <span className="containerTitle">ANNOTATION</span>
          <img className="closeAnnotationImage" src={closeIcon} alt="Quiz" onClick={onCloseClick} />
        </div>
        <div id="annotationBody">
          {imageExtra && (
              <img id="imageExtra" src={imageExtra} alt="extra"/>
          )}
          <p dangerouslySetInnerHTML={{ __html: body }} />
        </div>
        <div id="annotationTitle">
          <p dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>
      {image && (
        <div id='annotationImageContainer' className={`${visibilityClass}`}>
          <img src={image} alt="annotation" />
        </div>
      )}
    </Fragment>
  );
};

export default AnnotationContainer;
