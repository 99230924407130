import { Stack, styled } from '@mui/material'

export const TriangleToBottom = styled(Stack, { name: 'TriangleToBottom' })({
  width: 0,
  height: 0,
  margin: '16px',
  borderTop: '16px solid #FFFD01',
  borderRight: '16px solid transparent',
  borderBottom: '0 solid transparent',
  borderLeft: '16px solid transparent',
  position: 'absolute',
  right: '0',
  bottom: '-32px'
})