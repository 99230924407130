import * as THREE from 'three';
import * as AFrame from 'aframe';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { IShaderSprayAframe } from './shader-spray';
import { IShaderFireAframe } from './shader-fire';

interface IBottleControl {
  currentAssetId: number;
  initialLabelFix: () => void;
  spray: () => void;
  mixer: THREE.AnimationMixer;
  action: THREE.AnimationAction;
  mesh: THREE.Object3D;
  bottlePositionHidden: THREE.Vector3;
  bottlePositionNormal: THREE.Vector3;
  loadTexture: (id: number) => void;
  onTextureLoaded: (texture: THREE.Texture) => void;
  swapBottle: (id: number) => void;
  el: AFrame.Entity;
  camera: any;
}

const BottleControlComponent = {
  name: 'bottle-control',
  val: {
    init(this: IBottleControl) {
        this.bottlePositionHidden = new THREE.Vector3(1, -2.5, 5)
        this.bottlePositionNormal = new THREE.Vector3(1.5, -0.5, -6)

        // adjust bottle normal posiion based on the device screen size
        if (window) {
          const devicePixelRatio = window.devicePixelRatio;
          const screenWidth = window.innerWidth;
          const screenHeight = window.innerHeight;

          console.log('Device pixel ratio: ', devicePixelRatio);
          console.log('Screen width: ', screenWidth);
          console.log('Screen height: ', screenHeight);

          const ratio = screenHeight / screenWidth;
          if (ratio > 2.2) {
            this.bottlePositionNormal = new THREE.Vector3(1.5, -0.5, -7.5)
          }
        }
        
        this.el.addEventListener('model-loaded', () => {
          this.el.setAttribute('scale', '15 15 15')
          // target position => sprayBottle.setAttribute('position', '1 -3.5 -5')
          this.el.setAttribute('position', '1 -2.5 5')
          this.el.setAttribute('rotation', '0 -130 0')
          this.el.setAttribute('visible', 'true')
          this.el.setAttribute('shader-spray', '')
          this.initialLabelFix()
          this.camera = this.el.sceneEl?.camera;
          this.camera.el.object3D.add(this.el.object3D)
    
          this.el.object3D.traverse((mesh) => {
            // console.log(mesh)
            if (mesh.name && mesh.name === 'SprayBottleLabel') {
              mesh.rotateOnAxis(new THREE.Vector3(0, 0, 1), -90 * (Math.PI / 180))
              // mesh.translateX(0.1)
              mesh.translateX(-0.01)
              mesh.translateZ(-0.02)
            }
          })
    
          const mesh = this.el.object3D.getObjectByName('Scene')
          if (mesh) {
            this.mixer = new THREE.AnimationMixer(mesh)
            const clip = mesh.animations[0]
            // clip.loop = THREE.LoopOnce
            this.action = this.mixer.clipAction(clip)
            this.action.timeScale = 5
          }

        })

        // Listen for the 'assetChange' event
        this.currentAssetId = 0;
        this.el.sceneEl?.addEventListener('asset-change', (event) => {
            
            const customEvent = event as CustomEvent; // Cast event to CustomEvent
            const newAssetId = customEvent.detail.assetId;
            if (this.currentAssetId !== newAssetId) {
              this.swapBottle(newAssetId)
              // change flame color effects
              const burner = document.getElementById('burner') as AFrame.Entity;
              const shaderFireComponent = burner.components['shader-fire'] as unknown as IShaderFireAframe;
              shaderFireComponent.assetChanged(newAssetId)
              
              this.currentAssetId = newAssetId;
            }
            
        });
      
        const liUrl = 'https://bridgear.blob.core.windows.net/public/Chemistry/LiCl.png'
        const cuUrl = 'https://bridgear.blob.core.windows.net/public/Chemistry/CuCl2.png'
        const caURL = 'https://bridgear.blob.core.windows.net/public/Chemistry/CaCl.png'
        const naUrl = 'https://bridgear.blob.core.windows.net/public/Chemistry/NaCl.png'
        const kUrl = 'https://bridgear.blob.core.windows.net/public/Chemistry/KCl.png'
        // NOTE: labels are ordered and match asset id in UI asset id
        const labels = [liUrl, cuUrl, caURL, naUrl, kUrl]
        const loader = new THREE.TextureLoader()
        this.loadTexture = (id) => {
          const texture = loader.load(labels[id], () => {
            this.onTextureLoaded(texture)
          })
          // texture.flipY = false
          // return texture
        }
      
        this.onTextureLoaded = (texture) => {
          texture.flipY = false
          const mesh = this.el.object3D.getObjectByName('SprayBottleLabel') as THREE.Mesh;
          const material = mesh.material as THREE.MeshBasicMaterial;
          material.map = texture
          // move bottle to new position
          this.el.setAttribute('animation__position', {
            property: 'position',
            to: this.bottlePositionNormal,
            easing: 'linear',
            dur: 500,
          })
        }
      
        this.swapBottle = (id) => {
          console.log('Swaping bottle for id: ', id);
          // move to hidden position
          this.el.setAttribute('animation__position', {
            property: 'position',
            to: this.bottlePositionHidden,
            easing: 'linear',
            dur: 500,
          })
          // apply new material
          setTimeout(() => {
            this.loadTexture(id)
            const fluid = this.el.object3D.getObjectByName('spraybottle_fluid')
            if (fluid && fluid instanceof THREE.Mesh) {
                if (id === 1) {
                    fluid.material.color.set(0x33cccc)
                } else {
                    fluid.material.color.set(0xffffff)
                }
            }
            
          }, 500)
        }
      
        this.el.addEventListener('click', (e) => {
          const sprayComponent = this.el.components['shader-spray'] as unknown as IShaderSprayAframe;
          if (sprayComponent.canSpray) {
            // play trigger animation
            console.log('Spray allowed. Playing animation.')
            this.action.reset()
            this.action.repetitions = 1
            this.action.play()
            sprayComponent.spray()
          }
          e.stopPropagation()
        })

        this.initialLabelFix = () => {
          // this is just a quick bug fix for starting bottle label
          // labels on server are correct
          // original label on a bottle is wrong Sayings  "LiCL" instead of "LiCl"
          const texture = loader.load(labels[0], (texture) => {
            texture.flipY = false
            const mesh = this.el.object3D.getObjectByName('SprayBottleLabel') as THREE.Mesh;
            const material = mesh.material as THREE.MeshBasicMaterial;
            material.map = texture
          })

        }

        this.el.sceneEl?.addEventListener('lesson-start', (e) => {
          // set the bottle
          const sprayBottle = document.getElementById('bottle') as AFrame.Entity;
          sprayBottle.setAttribute('animation__position', {
              property: 'position',
              to: this.bottlePositionNormal,
              easing: 'linear',
              dur: 1000,
            })
        });

        this.el.sceneEl?.addEventListener('lesson-recenter', (e) => {
          this.el.setAttribute('animation__position', {
            property: 'position',
            to: this.bottlePositionHidden,
            easing: 'linear',
            dur: 500,
          })
        });
      // 
        // this.el.sceneEl?.addEventListener('element-selected', (e) => {
        //   console.log('New element selected: ', e.elementId)
        //   this.swapBottle(e.elementId)
        // })

    },
    tick(this: IBottleControl, time: number, deltaTime: number) {
      if (this.mixer) {
        this.mixer.update(deltaTime / 1000);
      }
    },
  },
};

export { BottleControlComponent };
