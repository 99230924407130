import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';


interface IHalogensSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    heliumHandler: () => void;
    neonHandler: () => void;
    argonHandler: () => void;
    kryptonHandler: () => void;
    xenonHandler: () => void;
    radonHandler: () => void;
    currentHandler: () => void;
    heliumAtom: AFrame.Entity;
    neonAtom: AFrame.Entity;
    argonAtom: AFrame.Entity;
    kryptonAtom: AFrame.Entity;
    xenonAtom: AFrame.Entity;
    radonAtom: AFrame.Entity;
    heliumMixer: THREE.AnimationMixer;
    heliumCoreAnimation: THREE.AnimationAction;
    heliumElectronsAnimation: THREE.AnimationAction;
    neonMixer: THREE.AnimationMixer;
    neonCoreAnimation: THREE.AnimationAction;
    neonElectronsAnimation: THREE.AnimationAction;
    argonMixer: THREE.AnimationMixer;
    argonCoreAnimation: THREE.AnimationAction;
    argonElectronsAnimation: THREE.AnimationAction;
    kryptonMixer: THREE.AnimationMixer;
    kryptonCoreAnimation: THREE.AnimationAction;
    kryptonElectronsAnimation: THREE.AnimationAction;
    xenonMixer: THREE.AnimationMixer;
    xenonCoreAnimation: THREE.AnimationAction;
    xenonElectronsAnimation: THREE.AnimationAction;
    radonMixer: THREE.AnimationMixer;
    radonCoreAnimation: THREE.AnimationAction;
    radonElectronsAnimation: THREE.AnimationAction;
    currentCoreAnimation: THREE.AnimationAction;
    currentElectronAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; image: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    prevEl: AFrame.Entity;
    currentAssetId: number;
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: IHalogensSceneAframe) {
            const atoms = document.getElementById('atomsHolder') as AFrame.Entity;
            atoms.addEventListener('model-loaded', (e) => {
                const customEvent = e as CustomEvent;
                if (customEvent.detail.model.el.id !== 'heliumAtom') return;

                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                this.el.sceneEl?.addEventListener('lesson-start', () => {
                    // remove tap place
                    const ring = document.getElementById('ring')
                    if (ring) {
                        ring.removeAttribute('tap-place-air')
                        this.el.sceneEl?.removeChild(ring)
                    }
                    this.heliumAtom = document.getElementById('heliumAtom') as AFrame.Entity;
                    this.neonAtom = document.getElementById('neonAtom') as AFrame.Entity;
                    this.argonAtom = document.getElementById('argonAtom') as AFrame.Entity;
                    this.kryptonAtom = document.getElementById('kryptonAtom') as AFrame.Entity;
                    this.xenonAtom = document.getElementById('xenonAtom') as AFrame.Entity;
                    this.radonAtom = document.getElementById('radonAtom') as AFrame.Entity;
                    initialiseAnimations()

                    if (!this.currentHandler) this.currentHandler = this.heliumHandler
                    if (!this.prevEl) this.prevEl = this.heliumAtom
                    this.prevEl.object3D.visible = true
                    this.currentHandler()
                    this.currentCoreAnimation.play()
                    this.currentElectronAnimation.play()
                })

            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: atomsHolder; scale: 10 10 10; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                }
            });

            this.currentAssetId = 0;
            this.el.sceneEl?.addEventListener('asset-change', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const newAssetId = customEvent.detail.assetId;
                switch (newAssetId) {
                    case 0:
                        this.heliumHandler()
                        this.currentHandler = this.heliumHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.heliumAtom.object3D.visible = true
                        this.heliumElectronsAnimation.play()
                        this.heliumCoreAnimation.play()
                        this.prevEl = this.heliumAtom
                        break;
                    case 1:
                        this.neonHandler()
                        this.currentHandler = this.neonHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.neonAtom.object3D.visible = true
                        this.neonElectronsAnimation.play()
                        this.neonCoreAnimation.play()
                        this.prevEl = this.neonAtom
                        break;
                    case 2:
                        this.argonHandler()
                        this.currentHandler = this.argonHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.argonAtom.object3D.visible = true
                        this.argonElectronsAnimation.play()
                        this.argonCoreAnimation.play()
                        this.prevEl = this.argonAtom
                        break;
                    case 3:
                        this.kryptonHandler()
                        this.currentHandler = this.kryptonHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.kryptonAtom.object3D.visible = true
                        this.kryptonElectronsAnimation.play()
                        this.kryptonCoreAnimation.play()
                        this.prevEl = this.kryptonAtom
                        break;
                    case 4:
                        this.xenonHandler()
                        this.currentHandler = this.xenonHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.xenonAtom.object3D.visible = true
                        this.xenonElectronsAnimation.play()
                        this.xenonCoreAnimation.play()
                        this.prevEl = this.xenonAtom
                        break;
                    case 5:
                        this.radonHandler()
                        this.currentHandler = this.radonHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.radonAtom.object3D.visible = true
                        this.radonElectronsAnimation.play()
                        this.radonCoreAnimation.play()
                        this.prevEl = this.radonAtom
                        break;
                }
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    if (this.currentCoreAnimation && this.currentElectronAnimation) {
                        this.currentCoreAnimation.paused = false;
                        this.currentElectronAnimation.paused = false;
                    }
                } else {
                    if (this.currentCoreAnimation && this.currentElectronAnimation) {
                        this.currentCoreAnimation.paused = true;
                        this.currentElectronAnimation.paused = true;
                    }
                }
            })

            this.heliumHandler = () => {
                this.currentCoreAnimation = this.heliumCoreAnimation
                this.currentElectronAnimation = this.heliumElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/NobleGases/HeliumCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.neonHandler = () => {
                this.currentCoreAnimation = this.neonCoreAnimation
                this.currentElectronAnimation = this.neonElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = '';
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/NobleGases/NeonCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.argonHandler = () => {
                this.currentCoreAnimation = this.argonCoreAnimation
                this.currentElectronAnimation = this.argonElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/NobleGases/ArgonCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.kryptonHandler = () => {
                this.currentCoreAnimation = this.kryptonCoreAnimation
                this.currentElectronAnimation = this.kryptonElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/NobleGases/KryptonCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.xenonHandler = () => {
                this.currentCoreAnimation = this.xenonCoreAnimation
                this.currentElectronAnimation = this.xenonElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/NobleGases/XenonCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            this.radonHandler = () => {
                this.currentCoreAnimation = this.radonCoreAnimation
                this.currentElectronAnimation = this.radonElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/NobleGases/RadonCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseAnimations = () => {
                const animatedHeliumEl = this.heliumAtom.object3D.getObjectByName('Scene') as any;
                this.heliumMixer = new THREE.AnimationMixer(animatedHeliumEl)
                const [HeliumElectronsAnim, HeliumCoreAnim] = animatedHeliumEl.animations
                this.heliumElectronsAnimation = this.heliumMixer.clipAction(HeliumElectronsAnim)
                this.heliumCoreAnimation = this.heliumMixer.clipAction(HeliumCoreAnim)

                const animatedNeonEl = this.neonAtom.object3D.getObjectByName('Scene') as any;
                this.neonMixer = new THREE.AnimationMixer(animatedNeonEl)
                const [NeonElectronsAnim, NeonCoreAnim] = animatedNeonEl.animations
                this.neonElectronsAnimation = this.neonMixer.clipAction(NeonElectronsAnim)
                this.neonCoreAnimation = this.neonMixer.clipAction(NeonCoreAnim)

                const animatedArgonEl = this.argonAtom.object3D.getObjectByName('Scene') as any;
                this.argonMixer = new THREE.AnimationMixer(animatedArgonEl)
                const [ArgonElectronsAnim, ArgonCoreAnim] = animatedArgonEl.animations
                this.argonElectronsAnimation = this.argonMixer.clipAction(ArgonElectronsAnim)
                this.argonCoreAnimation = this.argonMixer.clipAction(ArgonCoreAnim)

                const animatedKryptonEl = this.kryptonAtom.object3D.getObjectByName('Scene') as any;
                this.kryptonMixer = new THREE.AnimationMixer(animatedKryptonEl)
                const [KryptonElectronsAnim, KryptonCoreAnim] = animatedKryptonEl.animations
                this.kryptonElectronsAnimation = this.kryptonMixer.clipAction(KryptonElectronsAnim)
                this.kryptonCoreAnimation = this.kryptonMixer.clipAction(KryptonCoreAnim)

                const animatedXenonEl = this.xenonAtom.object3D.getObjectByName('Scene') as any;
                this.xenonMixer = new THREE.AnimationMixer(animatedXenonEl)
                const [XenonElectronsAnim, XenonCoreAnim] = animatedXenonEl.animations
                this.xenonElectronsAnimation = this.xenonMixer.clipAction(XenonElectronsAnim)
                this.xenonCoreAnimation = this.xenonMixer.clipAction(XenonCoreAnim)

                const animatedRadonEl = this.radonAtom.object3D.getObjectByName('Scene') as any;
                this.radonMixer = new THREE.AnimationMixer(animatedRadonEl)
                const [RadonElectronsAnim, RadonCoreAnim] = animatedRadonEl.animations
                this.radonElectronsAnimation = this.radonMixer.clipAction(RadonElectronsAnim)
                this.radonCoreAnimation = this.radonMixer.clipAction(RadonCoreAnim)

            }

        },
        tick(this: IHalogensSceneAframe, time: number, deltaTime: number) {
            if (this.heliumMixer) {
                this.heliumMixer.update(deltaTime * 0.001);
            }
            if (this.neonMixer) {
                this.neonMixer.update(deltaTime * 0.001);
            }
            if (this.argonMixer) {
                this.argonMixer.update(deltaTime * 0.001);
            }
            if (this.kryptonMixer) {
                this.kryptonMixer.update(deltaTime * 0.001);
            }
            if (this.xenonMixer) {
                this.xenonMixer.update(deltaTime * 0.001);
            }
            if (this.radonMixer) {
                this.radonMixer.update(deltaTime * 0.001);
            }

        },
    },
};
export {AtomsScene as AtomsSceneComponent}
