import * as AFRAME from 'aframe';
import * as THREE from 'three';

interface IWorldButtonAframe {
    isActivated: boolean;
    childPlane2: AFRAME.Entity<AFRAME.ObjectMap<AFRAME.Component<any, AFRAME.System<any>>>>;
    shadowPlane: any;
    childPlane: AFRAME.Entity;
    el: AFRAME.Entity;
    sphere: AFRAME.Entity;
    data: {
      scalex: string;
      tapRadiusX: number;
    };
}

export interface WorldButtonAframeInstance extends IWorldButtonAframe {
    init(): void;
    activate(): void;
    deactivate(): void;
    setInnerSrcImage(src: string): void;
    setAsGraphButton(): void;
    setDisabled(): void;
    setEnabled(): void;
}

const WorldButton = {
    name: 'world-button',
    val: {
        schema: {
          scalex: { type: 'string', default: '1 1 1' }, // scales button to match your needs
          tapRadiusX: { type: 'number', default: 1 }, // multiplier for tapable/clickable object
        },
        init(this: IWorldButtonAframe) {
            // const geometry = 'primitive: circle; radius: 0.05'
            // this.el.setAttribute('geometry', geometry)
            // this.el.setAttribute('material', {color: 0xffffff, transparent: true})
            const innerCircleUrl = require('../../../assets/img/Handle_Plain.png');
            const outCircleUrl = require('../../../assets/img/Handle_Outline.png');

            // scale multiplier provided by schema (world button gets attache to objects in the scene which has varying scale)
            const [x, y, z] = this.data.scalex.split(' ').map(parseFloat);
            // const scaleMultiplier = new THREE.Vector3(x, y, z);

            // Create a plane with the specified geometry
            const geometry = `primitive: plane; width: ${0.11 * x}; height: ${0.11 * y}`;
            this.el.setAttribute('geometry', geometry);
            this.el.setAttribute('material', { src: innerCircleUrl, shader: 'flat', color: 0xffffff, transparent: true, alphaTest: 0.5});
            this.el.object3D.renderOrder = 100;

            // Create a child plane
            this.childPlane = document.createElement('a-plane');
            this.childPlane.setAttribute('width', 0.15 * x);
            this.childPlane.setAttribute('height', 0.15 * y);
            this.childPlane.setAttribute('material', { src: outCircleUrl, shader: 'flat', color: 0xffffff, transparent: true, alphaTest: 0.5});
            this.el.appendChild(this.childPlane);

            // Add scale animation to the child plane
            this.childPlane.setAttribute('animation', {
              property: 'scale',
              dir: 'alternate',
              dur: 1000, // Animation duration in milliseconds
              easing: 'linear',
              loop: true,
              to: '1.2 1.2 1', // Target scale values
            });

            // // increasing tap surface with a sphere (a-sphere casts shadows)
            // this.sphere = document.createElement('a-sphere')
            // this.sphere.setAttribute('radius', 0.2 * x)
            // this.sphere.setAttribute('material', {shader: 'flat', color: '#FF0000', alphaTest: 0.5, transparent: true, opacity: 0, receiveShadow: false, castShadow: false });
            // this.sphere.setAttribute('segments-height', 12)
            // this.sphere.setAttribute('segments-width', 12)
            // this.sphere.setAttribute('class', 'cantap')
            // this.sphere.setAttribute('shadow', 'cast: false; receive: false')
            // this.el.appendChild(this.sphere)

            // increasing tapping surfcace with an invisible plane
            this.childPlane2 = document.createElement('a-plane');
            this.childPlane2.setAttribute('width', 0.25 * x * this.data.tapRadiusX);
            this.childPlane2.setAttribute('height', 0.25 * y * this.data.tapRadiusX);
            this.childPlane2.setAttribute('position', '0 0 0.01');
            this.childPlane2.setAttribute('material', {shader: 'flat', color: 0xffffff, transparent: true});
            this.childPlane2.setAttribute('class', 'cantap');
            this.childPlane2.setAttribute('visible', false);
            this.el.appendChild(this.childPlane2);

            this.isActivated = true;
        },
        activate(this: IWorldButtonAframe) {
          if(!this.isActivated) {
            this.childPlane.removeAttribute('animation');
            this.childPlane.setAttribute('animation', {
              property: 'scale',
              dir: 'alternate',
              dur: 1000, // Animation duration in milliseconds
              easing: 'linear',
              loop: true,
              from: '1 1 1',
              to: '1.2 1.2 1', // Target scale values
            });
            this.isActivated = true;
          }
            
        },
        deactivate(this: IWorldButtonAframe) {
          if (this.isActivated) {
            this.childPlane.removeAttribute('animation');
            this.childPlane.setAttribute('animation', {
              property: 'scale',
              dur: 400, // Animation duration in milliseconds
              easing: 'linear',
              loop: false,
              to: '0.5 0.5 1', // Target scale values
            });
            this.isActivated = false;
          }
            
        },
        setInnerSrcImage(this: IWorldButtonAframe, srcUrl: string) {
          this.el.setAttribute('material', { src: srcUrl, shader: 'flat', color: 0xffffff, transparent: true, alphaTest: 0.5});
        },
        setAsGraphButton(this: IWorldButtonAframe) {
          const srcUrl = require('../../../assets/img/graph_wb.png');
          this.el.setAttribute('material', { src: srcUrl, shader: 'flat', color: 0xffffff, transparent: true, alphaTest: 0.5});
        },
        setDisabled(this: IWorldButtonAframe) {
          // completely disable button
          this.el.setAttribute('visible', false);
          this.childPlane2.classList.remove('cantap');
        },
        setEnabled(this: IWorldButtonAframe) {
          this.el.setAttribute('visible', true);
          this.childPlane2.classList.add('cantap');
        },
    },
};
export { WorldButton as WorldButtonComponent }