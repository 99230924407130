import {
  Stack,
  Box,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DisplayEnum } from '../../../../shared/enums';
import { BackButton } from '../../../../shared/components/BackButton';
import { useData } from '../hooks';
import { useMemo } from 'react';
import { Circles } from 'react-loader-spinner';
import { IconImage } from '../../../../shared/components/IconImage';
import { typeColorUtil } from '../../utils/typeColorUtil';
import { statusColorUtil } from '../../utils/statusColorUtil';
import { levelColorUtil } from '../../utils/levelColorUtils';
import { parseCurriculumDetails } from '../../utils/parseCurriculumDetails';
import { useTranslation } from 'react-i18next';
import { ClickMeStub } from 'shared/components/ClickMeStub';

type Props = {
  loading: boolean;
};

export function ContentComponent(props: Props) {
  const { loading } = props;
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { training } = useData();

  const typeColor = useMemo(() => {
    if (training?.type) {
      return typeColorUtil(training.type);
    }
    return '';
  }, [training]);
  const statusColor = useMemo(() => {
    if (training?.status) {
      return statusColorUtil(training.status);
    }
    return '';
  }, [training]);
  const splitLevelItems = useMemo(() => {
    if (training?.level) {
      return training.level.split(',').map((i) => i.trim());
    }
  }, [training]);
  const curriculumSections = useMemo(() => {
    if (training?.details) {
      return parseCurriculumDetails(training.details);
    }
    return [];
  }, [training]);
  const levelColor = (item: string) => {
    return levelColorUtil(item);
  };
  const { t, i18n } = useTranslation();
  const i18nPath = 'COURSE_CONTENT_COMPONENT.';

  return (
    <Stack
      width={xlDisplay ? 1180 : '100%'}
      mx='auto'
      boxSizing='border-box'
      justifyContent='center'
      alignItems='center'
    >
      {loading && (
        <Stack
          width='100%'
          py={tablet ? '0' : '150px'}
          px={tablet ? '0' : '200px'}
          mx='auto'
          alignItems='center'
          boxSizing='border-box'
        >
          <Circles
            height={120}
            width={120}
            color={theme.palette.grey.A200}
            ariaLabel='loading'
          />
        </Stack>
      )}
      {!loading && (
        <>
          <Stack my={1} direction='row'>
            <Stack
              mr={1}
              padding='5px 7px'
              borderRadius='4px'
              border={`1px solid rgba(${typeColor}, 0.5)`}
              width='fit-content'
            >
              <Typography
                fontFamily='GilroyMedium'
                color={`rgba(${typeColor}, 1)`}
                fontSize={14}
              >
                {t(i18nPath + training?.type)}
              </Typography>
            </Stack>
            {/* <Stack
              mr={1}
              padding='5px 7px'
              borderRadius='4px'
              border={`1px solid rgba(${statusColor}, 0.5)`}
              width='fit-content'
            >
              <Typography
                fontFamily='GilroyMedium'
                color={`rgba(${statusColor}, 1)`}
                fontSize={14}
              >
                {training?.status}
              </Typography>
            </Stack> */}
            {splitLevelItems?.map((item: string, index: number) => {
              return (
                <Stack
                  mr={splitLevelItems.length - 1 === index ? 0 : 1}
                  padding='5px 7px'
                  borderRadius='4px'
                  border={`1px solid rgba(${levelColor(item)}, 0.5)`}
                  width='fit-content'
                  key={item}
                >
                  <Typography
                    fontFamily='GilroyMedium'
                    color={`rgba(${levelColor(item)}, 1)`}
                    fontSize={14}
                  >
                    {t(i18nPath + item)}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
          <Stack>
            <Stack
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='center'
              mt={mobile ? 1 : 0}
              mb={mobile ? 1 : 2}
            >
              <BackButton path='/simulations' />
              <Typography
                fontSize={mobile ? 24 : 44}
                fontFamily='GilroyBold'
                color='#262626'
                ml={1}
              >
                {training?.title}
              </Typography>
            </Stack>
            <Typography
              width={mobile ? '100%' : 440}
              mx='auto'
              px={mobile ? 1.6 : 0}
              fontSize={mobile ? 16 : 18}
              fontFamily='GilroySemiBold'
              color={theme.palette.text.primary}
              textAlign='center'
            >
              {training?.description}
            </Typography>
          </Stack>

          <Stack
            width={mobile ? '100%' : '580px'}
            my={3}
            borderRadius='10px'
            padding={mobile ? 1.6 : 4}
            bgcolor='#F1F1F1'
          >
            <Typography
              mb={2}
              fontFamily='GilroySemiBoldItalic'
              fontSize={mobile ? 16 : 18}
              color={theme.palette.text.primary}
            >
              {t(i18nPath + 'CURRICULUM_DETAILS')}
            </Typography>

            {curriculumSections &&
              curriculumSections.map((i, index) => (
                <Stack key={index} direction='row'>
                  <Typography
                    mr={0.5}
                    fontFamily='GilroyBold'
                    fontSize={mobile ? 16 : 18}
                    color={theme.palette.text.primary}
                  >
                    {i.order}
                  </Typography>
                  <Typography
                    fontFamily='GilroySemiBold'
                    fontSize={mobile ? 16 : 18}
                    color={theme.palette.text.primary}
                  >
                    {i.text}
                  </Typography>
                </Stack>
              ))}
          </Stack>

          <Stack
            mb={4}
            width='100%'
            borderRadius='10px'
            bgcolor='#F1F1F1'
            padding={mobile ? '20px' : '40px'}
            justifyContent='center'
          >
            <Typography
              mb={2}
              fontSize={mobile ? 16 : 22}
              fontFamily='GilroyBold'
              color='#262626'
              textAlign='center'
            >
              {t(i18nPath + (xlDisplay ? 'SCAN' : 'SCAN_MOBILE'))}
            </Typography>
            <Stack
              direction={mobile ? 'column' : 'row'}
              alignItems='center'
              justifyContent='center'
              spacing={mobile ? 3 : 9}
            >
              {/* SNAP CODE */}
              {tablet && (
                <Link href={training?.url ?? ''} target='_blank'>
                  <Box width='fit-content'>
                    <IconImage
                      src={training?.snap ?? ''}
                      alt='snap'
                      width='187px'
                      borderRadius='20px'
                    />
                  </Box>
                </Link>
              )}
              {xlDisplay && (
                <Box width='fit-content'>
                  <IconImage
                    src={training?.snap ?? ''}
                    alt='snap'
                    width='187px'
                    borderRadius='20px'
                  />
                </Box>
              )}

              {/* Mobile Link Click*/}
              {mobile && <ClickMeStub />}

              {/* QR CODE */}
              {tablet && (
                <Link href={training?.url ?? ''} target='_blank'>
                  <Box
                    width='fit-content'
                    height={mobile ? '187px' : 'auto'}
                    border={mobile ? '5px solid #FFFD01' : 'none'}
                    borderRadius={mobile ? '25px' : '0px'}
                  >
                    <IconImage
                      src={training?.qr ?? ''}
                      alt='qr'
                      width={mobile ? '177px' : '187px'}
                      borderRadius='20px'
                    />
                  </Box>
                </Link>
              )}
              {xlDisplay && (
                <Box width='fit-content'>
                  <IconImage
                    src={training?.qr ?? ''}
                    alt='qr'
                    width='187px'
                    borderRadius='20px'
                  />
                </Box>
              )}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
}
