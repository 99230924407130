import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import './styles/plant-hormones-styles.css';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IPlantHormonesSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolButtons: PoolComponent;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionScene1_DefaultState: THREE.AnimationAction;
	actionScene1_GrowStem: THREE.AnimationAction;
	actionScene1_GrowRoots: THREE.AnimationAction;
	actionScene2_StemBend: THREE.AnimationAction;
	actionScene2_Roots: THREE.AnimationAction;
	actionScene3_Grow: THREE.AnimationAction;
	actionScene3_StemBend: THREE.AnimationAction;
	actionScene3_Roots: THREE.AnimationAction;
	actionScene2_GrownState: THREE.AnimationAction;

	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;

	currentAssetId: number;

	groupElement: AFrame.Entity | null;
	
	scope_1: THREE.Object3D<THREE.Object3DEventMap> | undefined;
	scope_2: THREE.Object3D<THREE.Object3DEventMap> | undefined;
	stem: THREE.Object3D<THREE.Object3DEventMap> | undefined;

	el: AFrame.Entity;

	shootTriggerBtn: AFrame.Entity | null;
	rootsTriggerBtn: AFrame.Entity | null;
	phototropismInShootTriggerBtn: AFrame.Entity | null;
	phototropismInRootsTriggerBtn: AFrame.Entity | null;
	geotropismInShootTriggerBtn: AFrame.Entity | null;
	geotropismInRootsTriggerBtn: AFrame.Entity | null;


	shootButtonHandler: () => void;
	rootsButtonHandler: () => void;
	phototropismInShootButtonHandler: () => void;
	phototropismInRootsButtonHandler: () => void;
	geotropismInShootButtonHandler: () => void;
	geotropismInRootsButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const PlantHormonesScene = {
	name: 'plant-hormones',
	val: {
		init(this: IPlantHormonesSceneAframe) {
			var isInitialised = false;

			this.el.addEventListener('model-loaded', () => {
				this.groupElement = document.getElementById('PlantHormones') as AFrame.Entity;

				this.groupElement?.object3D.traverse((child) => {
					console.log(child);
				});

				this.scope_1 = this.groupElement?.object3D.getObjectByName('StemAuxinsMagnified');
				this.scope_2 = this.groupElement?.object3D.getObjectByName('RootAuxinsMagnified');

				this.stem = this.groupElement?.object3D.getObjectByName('Stem7');
				
				if (!isInitialised) {
					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					firstScene();
					isInitialised = true
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			let isExecutionSceneThreeAllowed = true;
			let isExecutionSceneTwoAllowed = true;

			const interruptButtonsAppear = (isAllowed: boolean) => {
				isAllowed = false;
			};

			const firstScene = () => {
				this.actionScene3_Grow.stop();
				this.actionScene3_Grow.reset();
				this.actionScene3_StemBend.stop();
				this.actionScene3_StemBend.reset();
				this.actionScene3_Roots.stop();
				this.actionScene3_Roots.reset();
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionScene1_DefaultState;
				this.actionScene1_DefaultState.reset();
				this.actionScene1_DefaultState.repetitions = 1;
				this.actionScene1_DefaultState.clampWhenFinished = true;
				this.actionScene1_DefaultState.play();
				this.actionScene1_DefaultState.paused = true;
				
				if (this.scope_1) {
					this.scope_1.visible = false;
				}
				if (this.scope_2) {
					this.scope_2.visible = false;
				}
				if (this.shootTriggerBtn) {
					this.shootTriggerBtn.object3D.visible = true;
				}
				if (this.rootsTriggerBtn) {
					this.rootsTriggerBtn.object3D.visible = true;
				}
				if (this.phototropismInShootTriggerBtn) {
					this.phototropismInShootTriggerBtn.object3D.visible = false;
					this.phototropismInShootTriggerBtn.object3D.scale.set(0, 0, 0);
				}
				if (this.phototropismInRootsTriggerBtn) {
					this.phototropismInRootsTriggerBtn.object3D.visible = false;
					this.phototropismInRootsTriggerBtn.object3D.scale.set(0, 0, 0);
					this.phototropismInRootsTriggerBtn.object3D.position.set(-8, -0.1, 0.3);

				}
				if (this.geotropismInShootTriggerBtn) {
					this.geotropismInShootTriggerBtn.object3D.visible = false;
					this.geotropismInShootTriggerBtn.object3D.scale.set(0, 0, 0);
				}
				if (this.geotropismInRootsTriggerBtn) {
					this.geotropismInRootsTriggerBtn.object3D.visible = false;
					this.geotropismInRootsTriggerBtn.object3D.scale.set(0, 0, 0);
				}
				interruptButtonsAppear(isExecutionSceneThreeAllowed);
				interruptButtonsAppear(isExecutionSceneTwoAllowed);
			}

			const secondScene = () => {
				this.actionScene3_Grow.stop();
				this.actionScene3_Grow.reset();
				this.actionScene3_StemBend.stop();
				this.actionScene3_StemBend.reset();
				this.actionScene3_Roots.stop();
				this.actionScene3_Roots.reset();
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionScene2_GrownState;
				this.actionScene2_GrownState.reset();
				this.actionScene2_GrownState.repetitions = 1;
				this.actionScene2_GrownState.clampWhenFinished = true;
				this.actionScene2_GrownState.play();
				this.actionScene2_GrownState.paused = true;
				
				if (this.stem) {
					this.stem.visible = true;
				}
				if (this.scope_1) {
					this.scope_1.visible = false;
				}
				if (this.scope_2) {
					this.scope_2.visible = false;
				}
				if (this.shootTriggerBtn) {
					this.shootTriggerBtn.object3D.visible = false;
				}
				if (this.rootsTriggerBtn) {
					this.rootsTriggerBtn.object3D.visible = false;
				}
				if (this.geotropismInShootTriggerBtn) {
					this.geotropismInShootTriggerBtn.object3D.visible = false;
					this.geotropismInShootTriggerBtn.object3D.scale.set(0, 0, 0);
				}
				if (this.geotropismInRootsTriggerBtn) {
					this.geotropismInRootsTriggerBtn.object3D.visible = false;
					this.geotropismInRootsTriggerBtn.object3D.scale.set(0, 0, 0);
				}
				if (this.phototropismInRootsTriggerBtn) {
					this.phototropismInRootsTriggerBtn.object3D.position.set(-0.6, -0.1, 0.3);
				}
				if (this.phototropismInRootsTriggerBtn) {
					this.phototropismInRootsTriggerBtn.object3D.scale.set(1.2, 1.2, 1.2);
				}
				if (this.phototropismInShootTriggerBtn) {
					this.phototropismInShootTriggerBtn.object3D.scale.set(1.2, 1.2, 1.2);
				}
				interruptButtonsAppear(isExecutionSceneThreeAllowed);
				buttonsAppears(isExecutionSceneTwoAllowed, this.phototropismInRootsTriggerBtn, this.phototropismInShootTriggerBtn, 0)
			}

			const thirdScene = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionScene3_Grow;
				this.actionScene3_Grow.reset();
				this.actionScene3_Grow.repetitions = 1;
				this.actionScene3_Grow.clampWhenFinished = true;
				this.actionScene3_Grow.play();

				if (this.scope_1) {
					this.scope_1.visible = false;
				}
				if (this.scope_2) {
					this.scope_2.visible = false;
				}
				if (this.shootTriggerBtn) {
					this.shootTriggerBtn.object3D.visible = false;
				}
				if (this.rootsTriggerBtn) {
					this.rootsTriggerBtn.object3D.visible = false;
				}
				if (this.phototropismInShootTriggerBtn) {
					this.phototropismInShootTriggerBtn.object3D.visible = false;
					this.phototropismInShootTriggerBtn.object3D.scale.set(0, 0, 0);
				}

				if (this.phototropismInRootsTriggerBtn) {
					this.phototropismInRootsTriggerBtn.object3D.visible = false;
					this.phototropismInRootsTriggerBtn.object3D.scale.set(0, 0, 0);
					this.phototropismInRootsTriggerBtn?.object3D.position.set(-2, -0.1, 0.3);

				}
				if (this.geotropismInShootTriggerBtn) {
					this.geotropismInShootTriggerBtn.object3D.scale.set(1.2, 1.2, 1.2);
				}
				if (this.geotropismInRootsTriggerBtn) {
					this.geotropismInRootsTriggerBtn.object3D.scale.set(1.2, 1.2, 1.2);
				}
				interruptButtonsAppear(isExecutionSceneTwoAllowed);
				buttonsAppears(isExecutionSceneThreeAllowed, this.geotropismInShootTriggerBtn, this.geotropismInRootsTriggerBtn, 2400)
			}

			const buttonsAppears = (isAllowed: boolean, button_1: AFrame.Entity | null, button_2: AFrame.Entity | null, timer: number) => {
				if (!isAllowed) {
					return;
				}
				setTimeout(() => {
					if (button_1) {
						button_1.object3D.visible = true;
					}
					if (button_2) {
						button_2.object3D.visible = true;
					}
				}, timer);
			}
			this.currentAssetId = 0;
			this.el.sceneEl?.addEventListener('asset-change', (event) => {
				const customEvent = event as CustomEvent; // Cast event to CustomEvent
				const newAssetId = customEvent.detail.assetId;
				this.currentAssetId = newAssetId;
				if (newAssetId === 0) {
					firstScene();
				}
				if (newAssetId === 1) {
					secondScene();
				}
				if (newAssetId === 2) {
					thirdScene();
				}
			});

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: SceneHolder; offset: 0 2 0');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}

			});

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.scope_1) {
					this.scope_1.visible = false;
				}
				if (this.scope_2) {
					this.scope_2.visible = false;
				}
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}

			})

			const initialiseAnimations = () => {
				const animatedProcess = this.groupElement?.object3D.getObjectByName('Biology12_PlantHormones_V6') as any;

				this.mixer = new THREE.AnimationMixer(animatedProcess);

				const showScene1_DefaultState = animatedProcess?.animations[0];
				const showScene1_GrowStem = animatedProcess?.animations[1];
				const showScene1_GrowRoots = animatedProcess?.animations[2];
				const showScene2_StemBend = animatedProcess?.animations[3];
				const showScene2_Roots = animatedProcess?.animations[4];
				const showScene3_Grow = animatedProcess?.animations[5];
				const showScene3_StemBend = animatedProcess?.animations[6];
				const showScene3_Roots = animatedProcess?.animations[7];
				const showScene2_GrownState = animatedProcess?.animations[8];

				this.actionScene1_DefaultState = this.mixer.clipAction(showScene1_DefaultState);
				this.actionScene1_GrowStem = this.mixer.clipAction(showScene1_GrowStem);
				this.actionScene1_GrowRoots = this.mixer.clipAction(showScene1_GrowRoots);
				this.actionScene2_StemBend = this.mixer.clipAction(showScene2_StemBend);
				this.actionScene2_Roots = this.mixer.clipAction(showScene2_Roots);
				this.actionScene3_Grow = this.mixer.clipAction(showScene3_Grow);
				this.actionScene3_StemBend = this.mixer.clipAction(showScene3_StemBend);
				this.actionScene3_Roots = this.mixer.clipAction(showScene3_Roots);
				this.actionScene2_GrownState = this.mixer.clipAction(showScene2_GrownState);
			};

			this.shootButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}	
				this.actionScene1_DefaultState.stop();	
				this.currentClip = this.actionScene1_GrowStem;
				this.actionScene1_GrowStem.reset();
				this.actionScene1_GrowStem.repetitions = 1;
				this.actionScene1_GrowStem.clampWhenFinished = true;
				this.actionScene1_GrowStem.play();

				if (this.onObjectSelected) {
					const title = 'The Shoot';
					const body = 'The shoot demonstrates <b>positive phototropism</b> due to the growth of plant shoots towards the light, as well as <b>negative geotropism</b> due to their growth away from gravity.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.rootsButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.actionScene1_DefaultState.stop();
				this.currentClip = this.actionScene1_GrowRoots;
				this.actionScene1_GrowRoots.reset();
				this.actionScene1_GrowRoots.repetitions = 1;
				this.actionScene1_GrowRoots.clampWhenFinished = true;
				this.actionScene1_GrowRoots.play();
				if (this.onObjectSelected) {
					const title = 'Roots';
					const body = 'The root demonstrates <b>negative phototropism</b> due to the growth of plant roots away from the light, as well as <b>positive geotropism</b> due to their growth towards gravity.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.phototropismInShootButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.actionScene2_GrownState.stop();
				this.currentClip = this.actionScene2_StemBend;
				this.actionScene2_StemBend.reset();
				this.actionScene2_StemBend.repetitions = 1;
				this.actionScene2_StemBend.clampWhenFinished = true;
				this.actionScene2_StemBend.play();
						
				if (this.scope_1) {
					this.scope_1.visible = true;
				}
				if (this.scope_2) {
					this.scope_2.visible = false;
				}

				if (this.onObjectSelected) {
					const title = 'Phototropism in Shoot';
					const body = 'If the light is shining on one side of the shoot then auxins will move to the darker side. Auxins cause cell elongation in shoots, meaning the shaded side will grow faster causing the plant to grow towards the light. This is positive phototropism.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.phototropismInRootsButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.actionScene2_GrownState.stop();
				this.currentClip = this.actionScene2_Roots;
				this.actionScene2_Roots.reset();
				this.actionScene2_Roots.repetitions = 1;
				this.actionScene2_Roots.clampWhenFinished = true;
				this.actionScene2_Roots.play();
				
				if (this.scope_1) {
					this.scope_1.visible = false;
				}
				if (this.scope_2) {
					this.scope_2.visible = true;
				}

				if (this.onObjectSelected) {
					const title = 'Phototropism in Roots';
					const body = 'The roots grow away from the light. This is negative phototropism.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.geotropismInRootsButtonHandler = () => {
				if (this.scope_1) {
					this.scope_1.visible = false;
				}
				if (this.scope_2) {
					this.scope_2.visible = true;
				}
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.actionScene3_Grow.stop();
				this.currentClip = this.actionScene3_Roots;
				this.actionScene3_Roots.reset();
				this.actionScene3_Roots.repetitions = 1;
				this.actionScene3_Roots.clampWhenFinished = true;
				this.actionScene3_Roots.play();
				
				if (this.onObjectSelected) {
					const title = 'Geotropism in Roots';
					const body = 'If a plant is horizontal, due to gravity, auxins will accumulate on the lower side. In the root, auxins inhibit cell elongation. The lower part of the root grows slower resulting in the root growing downwards. This is positive geotropism.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.geotropismInShootButtonHandler = () => {
				if (this.scope_1) {
					this.scope_1.visible = true;
				}
				if (this.scope_2) {
					this.scope_2.visible = false;
				}
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.actionScene3_Grow.stop();
				this.currentClip = this.actionScene3_StemBend;
				this.actionScene3_StemBend.reset();
				this.actionScene3_StemBend.repetitions = 1;
				this.actionScene3_StemBend.clampWhenFinished = true;
				this.actionScene3_StemBend.play();
				
				if (this.onObjectSelected) {
					const title = 'Geotropism in Shoot';
					const body = 'If a plant is horizontal, due to gravity, auxins will accumulate on the lower side. In shoots, auxins promote cell elongation. The lower part of the shoot grows faster moving the shoot upwards against the force of gravity. This is negative geotropism.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.shootTriggerBtn = this.poolButtons.requestEntity();
				this.shootTriggerBtn?.object3D.position.set(0, 0, 0.3);
				this.shootTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.shootTriggerBtn?.play();
				this.shootTriggerBtn?.addEventListener('click', () => {
					this.shootButtonHandler();
					if (this.shootTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.shootTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.shootTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.shootTriggerBtn;
					}
				});

				this.rootsTriggerBtn = this.poolButtons.requestEntity();
				this.rootsTriggerBtn?.object3D.position.set(0, -0.6, 0.3);
				this.rootsTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.rootsTriggerBtn?.play();

				this.rootsTriggerBtn?.addEventListener('click', () => {
					this.rootsButtonHandler();
					if (this.rootsTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.rootsTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.rootsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.rootsTriggerBtn;
					}
				});


				this.phototropismInShootTriggerBtn = this.poolButtons.requestEntity();
				this.phototropismInShootTriggerBtn?.object3D.position.set(0, 2, 0.3);
				this.phototropismInShootTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.phototropismInShootTriggerBtn?.play();
				this.phototropismInShootTriggerBtn?.addEventListener('click', () => {
					this.phototropismInShootButtonHandler();
					if (this.phototropismInShootTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.phototropismInShootTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.phototropismInShootTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.phototropismInShootTriggerBtn;
					}
				});

				this.phototropismInRootsTriggerBtn = this.poolButtons.requestEntity();
				this.phototropismInRootsTriggerBtn?.object3D.position.set(-0.6, -0.1, 0.3);
				this.phototropismInRootsTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.phototropismInRootsTriggerBtn?.play();
				this.phototropismInRootsTriggerBtn?.addEventListener('click', () => {
					this.phototropismInRootsButtonHandler();
					if (this.phototropismInRootsTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.phototropismInRootsTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.phototropismInRootsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.phototropismInRootsTriggerBtn;
					}
				});

				this.geotropismInRootsTriggerBtn = this.poolButtons.requestEntity();
				this.geotropismInRootsTriggerBtn?.object3D.position.set(0.3, 1.85, 0.25);
				this.geotropismInRootsTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.geotropismInRootsTriggerBtn?.play();
				this.geotropismInRootsTriggerBtn?.addEventListener('click', () => {
					this.geotropismInRootsButtonHandler();
					if (this.geotropismInRootsTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.geotropismInRootsTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.geotropismInRootsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.geotropismInRootsTriggerBtn
					}
				});

				this.geotropismInShootTriggerBtn = this.poolButtons.requestEntity();
				this.geotropismInShootTriggerBtn?.object3D.position.set(2.45, 2.5, 0.25);
				this.geotropismInShootTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.geotropismInShootTriggerBtn?.play();

				this.geotropismInShootTriggerBtn?.addEventListener('click', () => {
					this.geotropismInShootButtonHandler();
					if (this.geotropismInShootTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.geotropismInShootTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.geotropismInShootTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.geotropismInShootTriggerBtn;
					}
				});
			}
		},
		tick(this: IPlantHormonesSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}

		},
	},
};
export { PlantHormonesScene as PlantHormonesComponent }