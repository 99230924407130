import {
  Stack,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Link,
} from '@mui/material';
import { useCallback } from 'react';
import { IconImage } from '../../../shared/components/IconImage';
import { CustomContainer } from '../../../shared/components/CustomContainer';
import { DisplayEnum } from '../../../shared/enums';
import { CODES_URL, QR_CODE, SNAP_CODE } from 'constants/CodesPath';
import { RoleEnum } from '../../../shared/enums/role.enum';
import { useSharedContext } from '../../../shared/context';
import { useSetRoleType } from '../../../shared/hooks/useSetRoleType';
import { useTranslation } from 'react-i18next';
import { VideoPlayer } from '../../../shared/components/VideoPlayer';
import HeaderHomePageComponent from './HeaderHomePageComponent';

import Welcome from '../../../assets/Welcome.png';
import Social1 from '../../../assets/Social1.svg';
import Social2 from '../../../assets/Social2.svg';
import Social3 from '../../../assets/Social3.svg';
import Social4 from '../../../assets/Social4.svg';
import Social5 from '../../../assets/Social5.svg';

export function WelcomeComponent() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const [{ roleType }] = useSharedContext();
  const { t, i18n } = useTranslation();
  const setRoleType = useSetRoleType();
  const setRole = useCallback(
    (value: RoleEnum) => {
      localStorage.setItem('roleType', value);
      setRoleType();
    },
    [setRoleType]
  );
  const i18nPath = 'LANDING_PAGE.WELCOME_COMPONENT.';

  return (
    <Stack
      borderRadius='0 0 30px 30px'
      boxShadow='0px 14px 76px #0000001C'
      bgcolor='#fff'
      position='relative'
      zIndex={3}
    >
      <HeaderHomePageComponent />
      <CustomContainer>
        <Stack
          direction={mobile ? 'column' : 'row'}
          alignItems='start'
          justifyContent='space-between'
          mt={tablet ? 3 : 6}
        >
          <Box maxWidth={xlDisplay ? '400px' : '100%'}>
            <Box
              mb={3}
              fontFamily='GilroyBold'
              fontSize={18}
              color='#262626'
              display='flex'
              alignItems='center'
              justifyContent={tablet ? 'center' : 'start'}
            >
              <Box
                padding={roleType === RoleEnum.Teacher ? '8px 20px' : 0}
                color={roleType === RoleEnum.Teacher ? '#fff' : '#262626'}
                borderRadius={roleType === RoleEnum.Teacher ? '60px' : '0px'}
                bgcolor={
                  roleType === RoleEnum.Teacher ? '#9945FF' : 'transparent'
                }
                sx={{ cursor: 'pointer' }}
                onClick={() => setRole(RoleEnum.Teacher)}
              >
                {t('COMMON.BUTTON.TEACHER')}
              </Box>
              <Box
                ml={3}
                padding={roleType === RoleEnum.Student ? '8px 20px' : 0}
                color={roleType === RoleEnum.Student ? '#fff' : '#262626'}
                borderRadius={roleType === RoleEnum.Student ? '60px' : '0px'}
                bgcolor={
                  roleType === RoleEnum.Student ? '#9945FF' : 'transparent'
                }
                sx={{ cursor: 'pointer' }}
                onClick={() => setRole(RoleEnum.Student)}
              >
                {t('COMMON.BUTTON.STUDENT')}
              </Box>
            </Box>
            {tablet && <VideoPlayer />}
            <Typography
              fontSize={tablet ? 32 : 44}
              fontFamily='GilroyBold'
              color='#000'
              lineHeight={tablet ? 1.2 : 1}
              mb={2}
              textAlign={xlDisplay ? 'left' : 'center'}
            >
              {t(i18nPath + 'TITLE_PART_1')}
              {xlDisplay && (
                <>
                  <br />{' '}
                </>
              )}
              {t(i18nPath + 'TITLE_PART_2')}
            </Typography>
            <Typography
              fontSize={16}
              fontFamily='GilroySemiBold'
              color={theme.palette.text.primary}
              mb={2}
              textAlign='justify'
            >
              {roleType === RoleEnum.Student
                ? t(i18nPath + 'DESCRIPTION.STUDENT.PART_1')
                : t(i18nPath + 'DESCRIPTION.TEACHER.PART_1')}
            </Typography>
            <Typography
              fontSize={16}
              fontFamily='GilroySemiBold'
              color={theme.palette.text.primary}
              mb={tablet ? 3 : 6}
              textAlign='justify'
            >
              {roleType === RoleEnum.Student
                ? t(i18nPath + 'DESCRIPTION.STUDENT.PART_2')
                : t(i18nPath + 'DESCRIPTION.TEACHER.PART_2')}
            </Typography>
            <Box
              mb={tablet ? 3 : 6}
              display='flex'
              alignItems='center'
              justifyContent={tablet ? 'center' : 'start'}
            >
              {tablet && (
                <>
                  {roleType === RoleEnum.Student && (
                    <Link
                      href={CODES_URL}
                      target='_blank'
                      mr={5}
                    >
                      <Box overflow='hidden' width='157px' height='157px'>
                        <IconImage
                          src={SNAP_CODE}
                          alt='snap code'
                          width='157px'
                        />
                      </Box>
                    </Link>
                  )}

                  <Link href={CODES_URL} target='_blank'>
                    <Box
                      borderRadius='10px'
                      border='1px solid #000'
                      overflow='hidden'
                      width='157px'
                      height='157px'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <IconImage src={QR_CODE} alt='qr code' width='155px' />
                    </Box>
                  </Link>
                </>
              )}
              {xlDisplay && (
                <>
                  {roleType === RoleEnum.Student && (
                    <Box mr={7} overflow='hidden' width='157px' height='157px'>
                      <IconImage
                        src={SNAP_CODE}
                        alt='snap code'
                        width='157px'
                      />
                    </Box>
                  )}

                  <Box
                    borderRadius='10px'
                    border='1px solid #000'
                    overflow='hidden'
                    width='157px'
                    height='157px'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <IconImage src={QR_CODE} alt='qr code' width='155px' />
                  </Box>
                </>
              )}
            </Box>
            {/* <Typography
              fontSize={14}
              fontFamily='GilroySemiBold'
              color={theme.palette.text.primary}
              mb={1.5}
            >
              Share with friends:
            </Typography>
            <Box display='flex' alignItems='center'>
              <Box mr={1}>
                <IconImage src={Social1} alt='social1' />
              </Box>
              <Box mr={1}>
                <IconImage src={Social2} alt='social2' />
              </Box>
              <Box mr={1}>
                <IconImage src={Social3} alt='social3' />
              </Box>
              <Box mr={1}>
                <IconImage src={Social4} alt='social4' />
              </Box>
              <Box>
                <IconImage src={Social5} alt='social5' />
              </Box>
            </Box>
             */}
          </Box>
          {xlDisplay && <VideoPlayer />}
        </Stack>
      </CustomContainer>
    </Stack>
  );
}

export default WelcomeComponent;
