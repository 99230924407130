import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import './styles/plant-responses.css';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IPlantResponsesSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionDefaultState: THREE.AnimationAction;
	actionFullLight: THREE.AnimationAction;
	actionPartialLight: THREE.AnimationAction;
	actionDarkness: THREE.AnimationAction;
	actionVertical: THREE.AnimationAction;

	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;

	currentAssetId: number;
	groupElement: AFrame.Entity | null;
	lightRays: THREE.Object3D | undefined;
	yellowPlant: THREE.Object3D | undefined;
	plant: THREE.Object3D | undefined;

	el: AFrame.Entity;

	fullLightTriggerBtn: AFrame.Entity | null;
	partialLightTriggerBtn: AFrame.Entity | null;
	completeDarknessTriggerBtn: AFrame.Entity | null;
	verticalSeedsTriggerBtn: AFrame.Entity | null;
	germinatingSeedsTriggerBtn: AFrame.Entity | null;

	fullLightButtonHandler: () => void;
	partialLightButtonHandler: () => void;
	completeDarknessButtonHandler: () => void;
	verticalSeedsButtonHandler: () => void;
	germinatingSeedsButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const PlantResponsesScene = {
	name: 'plant-responses',
	val: {
		init(this: IPlantResponsesSceneAframe) {
			var isInitialised = false;
			this.el.addEventListener('model-loaded', () => {
				this.groupElement = document.getElementById('PlantResponses') as AFrame.Entity;

				this.groupElement?.object3D.traverse((child) => {
					console.log(child);
				});

				this.lightRays = this.groupElement.object3D.getObjectByName('LightRaysV2');
				this.yellowPlant = this.groupElement.object3D.getObjectByName('PetriPlants_Yellow');
				this.plant = this.groupElement.object3D.getObjectByName('PetriPlants');

				const glassBarrier = this.groupElement.object3D.getObjectByName('Barrier');
				const glassCloche = this.groupElement.object3D.getObjectByName('Cloche');

				const matGlassBarrier = (glassBarrier as THREE.Mesh).material as THREE.MeshPhysicalMaterial;
				matGlassBarrier.transparent = true;
				matGlassBarrier.color = new THREE.Color(0x4f4f4f);
				matGlassBarrier.side = 2;
				matGlassBarrier.metalness = 0.2;
				matGlassBarrier.reflectivity = 0;
				matGlassBarrier.thickness = 1;
				matGlassBarrier.transmission = 0.8;
				matGlassBarrier.needsUpdate = true;

				const matGlassCloche = (glassCloche as THREE.Mesh).material as THREE.MeshPhysicalMaterial;
				matGlassCloche.transparent = true;
				matGlassCloche.color = new THREE.Color(0x4f4f4f);
				matGlassCloche.side = 2;
				matGlassCloche.metalness = 0.2;
				matGlassCloche.reflectivity = 0;
				matGlassCloche.thickness = 1;
				matGlassCloche.transmission = 0.8;
				matGlassCloche.needsUpdate = true;

				if (!isInitialised) {
					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					firstScene();
					isInitialised = true
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			const firstScene = () => {
				if (this.lightRays) {
					this.lightRays.visible = true;
				}
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionDefaultState;
				this.actionDefaultState.reset();
				this.actionDefaultState.repetitions = 1;
				this.actionDefaultState.clampWhenFinished = true;
				this.actionDefaultState.play();
				this.actionDefaultState.paused = true;
				this.germinatingSeedsTriggerBtn?.object3D.position.set(0, 0, 1.4);
				if (this.fullLightTriggerBtn) {
					this.fullLightTriggerBtn.object3D.visible = true;
					this.fullLightTriggerBtn?.object3D.position.set(-1.3, 5.8, 1.4);
				}
				if (this.partialLightTriggerBtn) {
					this.partialLightTriggerBtn.object3D.visible = false;
					this.partialLightTriggerBtn?.object3D.position.set(-8, 6, 1);
				}
				if (this.completeDarknessTriggerBtn) {
					this.completeDarknessTriggerBtn.object3D.visible = false;
					this.completeDarknessTriggerBtn?.object3D.position.set(-8, 2, 1);
				}
				if (this.verticalSeedsTriggerBtn) {
					this.verticalSeedsTriggerBtn.object3D.visible = false;
					this.verticalSeedsTriggerBtn?.object3D.position.set(-8, 2, 1);
				}
			}

			const secondScene = () => {
				if (this.lightRays) {
					this.lightRays.visible = true;
				}
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionPartialLight;
				this.actionPartialLight.reset();
				this.actionPartialLight.repetitions = 1;
				this.actionPartialLight.clampWhenFinished = true;
				this.actionPartialLight.play();
				this.actionPartialLight.paused = true;
				this.germinatingSeedsTriggerBtn?.object3D.position.set(0, 0, 1.4);
				if (this.fullLightTriggerBtn) {
					this.fullLightTriggerBtn.object3D.visible = false;
					this.fullLightTriggerBtn?.object3D.position.set(-8, 6, 1);
				}
				if (this.partialLightTriggerBtn) {
					this.partialLightTriggerBtn.object3D.visible = true;
					this.partialLightTriggerBtn?.object3D.position.set(-2.75, 4, 6);
				}
				if (this.completeDarknessTriggerBtn) {
					this.completeDarknessTriggerBtn.object3D.visible = false;
					this.completeDarknessTriggerBtn?.object3D.position.set(-8, 2, 1);
				}
				if (this.verticalSeedsTriggerBtn) {
					this.verticalSeedsTriggerBtn.object3D.visible = false;
					this.verticalSeedsTriggerBtn?.object3D.position.set(-8, 2, 1);
				}
			}

			const thirdScene = () => {
				if (this.lightRays) {
					this.lightRays.visible = false;
				}
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionDarkness;
				this.actionDarkness.reset();
				this.actionDarkness.repetitions = 1;
				this.actionDarkness.clampWhenFinished = true;
				this.actionDarkness.play();
				this.actionDarkness.paused = true;
				this.germinatingSeedsTriggerBtn?.object3D.position.set(0, 0, 1.4);
				if (this.fullLightTriggerBtn) {
					this.fullLightTriggerBtn.object3D.visible = false;
					this.fullLightTriggerBtn?.object3D.position.set(-8, 6, 1);
				}
				if (this.partialLightTriggerBtn) {
					this.partialLightTriggerBtn.object3D.visible = false;
					this.partialLightTriggerBtn?.object3D.position.set(-8, 6, 1);
				}
				if (this.completeDarknessTriggerBtn) {
					this.completeDarknessTriggerBtn.object3D.visible = true;
					this.completeDarknessTriggerBtn?.object3D.position.set(1.3, 6, 1.4);
				}
				if (this.verticalSeedsTriggerBtn) {
					this.verticalSeedsTriggerBtn.object3D.visible = false;
					this.verticalSeedsTriggerBtn?.object3D.position.set(-8, 2, 1);
				}
			}

			const forthScene = () => {
				if (this.lightRays) {
					this.lightRays.visible = false;
				}
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionVertical;
				this.actionVertical.reset();
				this.actionVertical.repetitions = 1;
				this.actionVertical.clampWhenFinished = true;
				this.actionVertical.play();
				this.actionVertical.paused = true;
				
				if (this.fullLightTriggerBtn) {
					this.fullLightTriggerBtn.object3D.visible = false;
					this.fullLightTriggerBtn?.object3D.position.set(-8, 6, 1);
				}
				if (this.partialLightTriggerBtn) {
					this.partialLightTriggerBtn.object3D.visible = false;
					this.partialLightTriggerBtn?.object3D.position.set(-8, 6, 1);
				}
				if (this.completeDarknessTriggerBtn) {
					this.completeDarknessTriggerBtn.object3D.visible = false;
					this.completeDarknessTriggerBtn?.object3D.position.set(-8, 2, 1);
				}
				if (this.verticalSeedsTriggerBtn) {
					this.verticalSeedsTriggerBtn.object3D.visible = true;
					this.verticalSeedsTriggerBtn?.object3D.position.set(1.3, 6, 1.4);
				}
			}

			this.currentAssetId = 0;
			this.el.sceneEl?.addEventListener('asset-change', (event) => {
				const customEvent = event as CustomEvent; // Cast event to CustomEvent
				const newAssetId = customEvent.detail.assetId;
				this.currentAssetId = newAssetId;
				if (newAssetId === 0) {
					firstScene();
				}
				if (newAssetId === 1) {
					secondScene();
				}
				if (newAssetId === 2) {
					thirdScene();
				}
				if (newAssetId === 3) {
					forthScene();
				}
			});

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: SceneHolder; ');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}

			});

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			const initialiseAnimations = () => {
				const animatedProcess = this.groupElement?.object3D.getObjectByName('Scene') as any;

				this.mixer = new THREE.AnimationMixer(animatedProcess);

				const showDefaultState = animatedProcess?.animations[4];
				const showFullLight = animatedProcess?.animations[0];
				const showPartialLight = animatedProcess?.animations[1];
				const showDarkness = animatedProcess?.animations[2];
				const showVertical = animatedProcess?.animations[3];

				this.actionDefaultState = this.mixer.clipAction(showDefaultState);
				this.actionFullLight = this.mixer.clipAction(showFullLight);
				this.actionPartialLight = this.mixer.clipAction(showPartialLight);
				this.actionDarkness = this.mixer.clipAction(showDarkness);
				this.actionVertical = this.mixer.clipAction(showVertical);
			};

			this.fullLightButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionFullLight;
				this.actionFullLight.reset();
				this.actionFullLight.repetitions = 1;
				this.actionFullLight.clampWhenFinished = true;
				this.actionFullLight.play();
				if (this.onObjectSelected) {
					const title = 'Full Light';
					const body = 'These germinated seedlings are exposed to sunlight fully. They have grown 10 centimetres in a straight upwards direction towards the light due to phototropism. Phototropism refers to the growth or movement of a plant in response to light.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.partialLightButtonHandler = () => {
				this.actionPartialLight.paused = false;
				if (this.onObjectSelected) {
					const title = 'Partial Light';
					const body = 'A screen can be used to reduce the light. In partial light, the seedlings grew 9.5 cm in a bent direction towards the light due to phototropism. Auxin hormone moves to the shaded part of the stem causing a sped up rate of growth, bending the stem in the direction of light.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.completeDarknessButtonHandler = () => {
				
				this.actionDarkness.timeScale = 1.25;
				this.actionDarkness.paused = false;
				if (this.onObjectSelected) {
					const title = 'Complete Darkness';
					const body = 'These germinated seedlings are not exposed to sunlight. They have grown 15 centimetres upwards. They are the longest because they are trying to reach the light. Due to lack of light, no photosynthesis occurs so the leaves are small and yellow.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.verticalSeedsButtonHandler = () => {
				this.actionVertical.timeScale = 1.25;
				this.actionVertical.paused = false;
				this.germinatingSeedsTriggerBtn?.object3D.position.set(1.5, 2, 1.4);
				if (this.onObjectSelected) {
					const title = 'Vertical Seeds in Darkness';
					const body = 'In the absence of light, when a plant is placed vertically, the stems still grow upwards in the opposite direction of gravity and the roots will grow downwards due to geotropism. Geotropism refers to the growth or movement of a plant in response to gravity.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}
			this.germinatingSeedsButtonHandler = () => {	
				if (this.onObjectSelected) {
					const title = 'Germinating Seeds';
					const body = 'These seeds have already been germinated for you. To germinate seeds, place cotton wool in each dish and with an equal number of seeds on top. Pour an equal amount of water in each dish and leave in a warm, moist place. Water with equal amounts daily.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.fullLightTriggerBtn = poolButtons.requestEntity();
				this.fullLightTriggerBtn?.object3D.position.set(0, 0, 1.4);
				this.fullLightTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.fullLightTriggerBtn?.play();
				this.fullLightTriggerBtn?.addEventListener('click', () => {
					this.fullLightButtonHandler();
					if (this.fullLightTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.fullLightTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.fullLightTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.fullLightTriggerBtn;
					}
				});

				this.partialLightTriggerBtn = poolButtons.requestEntity();
				this.partialLightTriggerBtn?.object3D.position.set(0, 0, 1.4);
				this.partialLightTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.partialLightTriggerBtn?.play();
				this.partialLightTriggerBtn?.addEventListener('click', () => {
					this.partialLightButtonHandler();
					if (this.partialLightTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.partialLightTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.partialLightTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.partialLightTriggerBtn;
					}
				});

				this.completeDarknessTriggerBtn = poolButtons.requestEntity();
				this.completeDarknessTriggerBtn?.object3D.position.set(0, 0, 1.4);
				this.completeDarknessTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.completeDarknessTriggerBtn?.play();
				this.completeDarknessTriggerBtn?.addEventListener('click', () => {
					this.completeDarknessButtonHandler();
					if (this.completeDarknessTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.completeDarknessTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.completeDarknessTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.completeDarknessTriggerBtn;
					}
				});

				this.verticalSeedsTriggerBtn = poolButtons.requestEntity();
				this.verticalSeedsTriggerBtn?.object3D.position.set(0, 0, 1.4);
				this.verticalSeedsTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.verticalSeedsTriggerBtn?.play();
				this.verticalSeedsTriggerBtn?.addEventListener('click', () => {
					this.verticalSeedsButtonHandler();
					if (this.verticalSeedsTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.verticalSeedsTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.verticalSeedsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.verticalSeedsTriggerBtn
					}
				});

				this.germinatingSeedsTriggerBtn = poolButtons.requestEntity();
				this.germinatingSeedsTriggerBtn?.object3D.position.set(0, 0, 1.4);
				this.germinatingSeedsTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.germinatingSeedsTriggerBtn?.play();
				this.germinatingSeedsTriggerBtn?.addEventListener('click', () => {
					this.germinatingSeedsButtonHandler();
					if (this.germinatingSeedsTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.germinatingSeedsTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.germinatingSeedsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.germinatingSeedsTriggerBtn
					}
				});
			}
		},
		tick(this: IPlantResponsesSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { PlantResponsesScene as PlantResponsesComponent }