import { Stack, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { IconImage } from '../../../shared/components/IconImage';
import { DisplayEnum } from '../../../shared/enums';
import { useTranslation } from 'react-i18next';
import SharingInfo from './SharingInfo';

import description1 from '../../../assets/description1.png';
import description2 from '../../../assets/description2.png';
import description3 from '../../../assets/description3.png';
import description4 from '../../../assets/description4.png';
import description5 from '../../../assets/description5.png';
import description6 from '../../../assets/description6.png';
import styledArrow from '../../../assets/styledArrow.svg';

export function Description() {
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { t, i18n } = useTranslation();
  const i18nPath = 'LANDING_PAGE.HOW_IT_WORKS_COMPONENT.DESCRIPTION_COMPONENT.';

  return (
    <Stack mt={tablet ? 2 : 4}>
      <Stack
        width={tablet ? '100%' : 1010}
        mx='auto'
        direction={mobile ? 'column' : 'row'}
        justifyContent={mobile ? 'center' : 'space-between'}
      >
        <Stack
          width='100%'
          maxWidth={mobile ? '100%' : xlDisplay ? 460 : '48%'}
          textAlign={tablet ? 'center' : 'start'}
          mb={tablet ? 3 : 0}
        >
          <Box>
            <IconImage src={description1} alt='description1' width='101px' />
          </Box>
          <Typography fontSize={22} fontFamily='GilroyBold' color='#262626'>
            {t(i18nPath + 'AR.TITLE')}
          </Typography>
          <Typography
            fontSize={16}
            fontFamily='GilroyMedium'
            color={theme.palette.text.secondary}
            textAlign={tablet ? 'justify' : 'start'}
          >
            {t(i18nPath + 'AR.DESCRIPTION')}
          </Typography>
        </Stack>
        <Stack
          width='100%'
          maxWidth={mobile ? '100%' : xlDisplay ? 460 : '48%'}
          textAlign={tablet ? 'center' : 'start'}
        >
          <Box>
            <IconImage src={description2} alt='description2' width='101px' />
          </Box>
          <Typography fontSize={22} fontFamily='GilroyBold' color='#262626'>
            {t(i18nPath + 'ANNOTATIONS.TITLE')}
          </Typography>
          <Typography
            fontSize={16}
            fontFamily='GilroyMedium'
            color={theme.palette.text.secondary}
            textAlign={tablet ? 'justify' : 'start'}
          >
            {t(i18nPath + 'ANNOTATIONS.DESCRIPTION')}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        width={tablet ? '100%' : 1010}
        mt={3}
        mx='auto'
        direction={mobile ? 'column' : 'row'}
        justifyContent={mobile ? 'center' : 'space-between'}
      >
        <Stack
          width='100%'
          maxWidth={mobile ? '100%' : xlDisplay ? 460 : '48%'}
          textAlign={tablet ? 'center' : 'start'}
          mb={tablet ? 3 : 0}
        >
          <Box>
            <IconImage src={description3} alt='description3' width='101px' />
          </Box>
          <Typography fontSize={22} fontFamily='GilroyBold' color='#262626'>
            {t(i18nPath + 'QUIZZES.TITLE')}
          </Typography>
          <Typography
            fontSize={16}
            fontFamily='GilroyMedium'
            color={theme.palette.text.secondary}
            textAlign={tablet ? 'justify' : 'start'}
          >
            {t(i18nPath + 'QUIZZES.DESCRIPTION')}
          </Typography>
        </Stack>
        <Stack
          width='100%'
          maxWidth={mobile ? '100%' : xlDisplay ? 460 : '48%'}
          textAlign={tablet ? 'center' : 'start'}
        >
          <Box>
            <IconImage src={description4} alt='description4' width='101px' />
          </Box>
          <Typography fontSize={22} fontFamily='GilroyBold' color='#262626'>
            {t(i18nPath + 'FLASHCARDS.TITLE')}
          </Typography>
          <Typography
            fontSize={16}
            fontFamily='GilroyMedium'
            color={theme.palette.text.secondary}
            textAlign={tablet ? 'justify' : 'start'}
          >
            {t(i18nPath + 'FLASHCARDS.DESCRIPTION')}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        width={tablet ? '100%' : 1010}
        mt={3}
        mx='auto'
        direction={mobile ? 'column' : 'row'}
        justifyContent={mobile ? 'center' : 'space-between'}
      >
        <Stack
          width='100%'
          maxWidth={mobile ? '100%' : xlDisplay ? 460 : '48%'}
          textAlign={tablet ? 'center' : 'start'}
          mb={tablet ? 3 : 0}
        >
          <Box>
            <IconImage src={description5} alt='description5' width='101px' />
          </Box>
          <Typography fontSize={22} fontFamily='GilroyBold' color='#262626'>
            {t(i18nPath + 'ANIMATED_ASSETS.TITLE')}
          </Typography>
          <Typography
            fontSize={16}
            fontFamily='GilroyMedium'
            color={theme.palette.text.secondary}
            textAlign={tablet ? 'justify' : 'start'}
          >
            {t(i18nPath + 'ANIMATED_ASSETS.DESCRIPTION')}
          </Typography>
        </Stack>
        <Stack
          mt={2.5}
          width='100%'
          maxWidth={mobile ? '100%' : xlDisplay ? 460 : '48%'}
          textAlign={tablet ? 'center' : 'start'}
          position='relative'
        >
          <Box>
            <IconImage src={description6} alt='description6' width='101px' />
          </Box>
          <Typography fontSize={22} fontFamily='GilroyBold' color='#262626'>
            {t(i18nPath + 'INTEGRATION_AND_SHARING.TITLE')}
          </Typography>
          <Typography
            fontSize={16}
            fontFamily='GilroyMedium'
            color={theme.palette.text.secondary}
            textAlign={tablet ? 'justify' : 'start'}
          >
            {t(i18nPath + 'INTEGRATION_AND_SHARING.DESCRIPTION')}
          </Typography>
          {/* {!tablet && (
            <Box position='absolute' top='97px' left='-60px'>
              <IconImage src={styledArrow} alt='styledArrow' />
            </Box>
          )} */}
        </Stack>
      </Stack>
      {/* <SharingInfo /> */}
    </Stack>
  );
}

export default Description;
