import React, { useRef, useState } from 'react';
import { AFrameScene, DISABLE_IMAGE_TARGETS } from '../../../lib/aframe-component';
import { Annotation, AnnotationState } from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';

import { TapPlaceCustom } from 'lib/aframe/components/tap-place-custom';
import { TapPlaceComponent } from '../../../lib/aframe/components/tap-place-object'

import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';


import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
    MODEL3D_HINT,
} from 'shared/constants/hint-items';

import { PhotosynthesisLimitingFactorsComponent } from './photosynthesis-limiting-factors-scene';
import { LoadersPermissonsEnum } from 'shared/enums';

const temperature = 'https://bridgear.blob.core.windows.net/public/Biology/PhotosynthesisLimitingFactors/UI_Temperature.png';
const CO2 = 'https://bridgear.blob.core.windows.net/public/Biology/PhotosynthesisLimitingFactors/UI_CO2.png';
const light = 'https://bridgear.blob.core.windows.net/public/Biology/PhotosynthesisLimitingFactors/UI_LightIntensity.png';
const chloroplast = 'https://bridgear.blob.core.windows.net/public/Biology/PhotosynthesisLimitingFactors/UI_Chlorophyll.png';

const assetIcons = [temperature, CO2, light, chloroplast];

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const introText = 'Explore the four limiting factors of photosynthesis and their effect on the rate of photosynthesis by using the buttons in the drop down menu.'

const BiologyPhotosynthesisLimitingFactorsAframe = (() => {
    const [selectedObject, setSelectedObject] = useState<{ title: string; body: string; image?: string } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(AnnotationState.None);
    const [lessonStarted, setLessonStarted] = useState<boolean>(false);
    const [sliderLoaded, setSliderLoaded] = useState<{
        title: string;
        icon: string;
        range: number[];
        step: number;
        firstValue: number,
        stringVariant?: boolean,
        shortVariant?: boolean
    } | null>({
        title: 'Temperature °C',
        icon: 'https://bridgear.blob.core.windows.net/public/Physics/Bulb.png',
        range: [0, 50],
        step: 1,
        firstValue: 0,
        shortVariant: true
    });
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };
    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };

    const onLesstonStarted = () => {
        setLessonStarted(true);
    };

    const [assetId, setAssetId] = useState(0);
    const changeAsset = (assetId: number) => {
        setSliderLoaded(null)
        if (assetId === 0) {
            setSliderLoaded({
                title: 'Temperature °C',
                icon: 'https://bridgear.blob.core.windows.net/public/Physics/Bulb.png',
                range: [0, 50],
                step: 1,
                firstValue: 0
            })
        }
        if (assetId === 1) {
            setSliderLoaded({
                title: 'CO<span class="subscript">2</span> %',
                icon: 'https://bridgear.blob.core.windows.net/public/Physics/Bulb.png',
                range: [0, 1],
                step: 0.1,
                firstValue: 0
            })
        }
        if (assetId === 2) {
            setSliderLoaded({
                title: 'Light intensity',
                icon: 'https://bridgear.blob.core.windows.net/public/Physics/Bulb.png',
                range: [10, 30],
                step: 10,
                firstValue: 10,
                stringVariant: true,
            })
        }
        if (assetId === 3) {
            setSliderLoaded({
                title: 'Chlorophyll concentration',
                icon: 'https://bridgear.blob.core.windows.net/public/Physics/Bulb.png',
                range: [200, 400],
                step: 100,
                firstValue: 100,
                stringVariant: true,
            })
        }
        setAssetId(assetId);
    }

    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    }
    /**
     * Asset changing functionality
     * Lesson ui would update the asset id state
     * Then dispatch an event to the aframe scene
     * And listen to it in any of the aframe scene components
     */
    const assetChangeEventRef = useRef(new CustomEvent('asset-change', { detail: { assetId: 0 } }));
    React.useEffect(() => {
        // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            console.log('Scene loaded');
            // Update the assetChangeEvent detail before dispatching
            assetChangeEventRef.current.detail.assetId = assetId;
            scene.dispatchEvent(assetChangeEventRef.current);
        }
    }, [assetId]);


    return (
        <React.Fragment>
            <LessonUI flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                onAnnotationStateChanged={onAnnotationStateChanged}
                lessonStarted={lessonStarted}
                assetIcons={assetIcons}
                onAssetButtonClick={changeAsset}
                hintItems={hintItems}
                sliderLoad={sliderLoaded}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./byology-photosynthesis-limiting-factors-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[WorldButtonComponent, FaceCameraComponent, TapPlaceComponent, TapPlaceCustom, PhotosynthesisLimitingFactorsComponent, Annotation]}
                onObjectSelected={onObjectSelected}
                onLessonStart={onLesstonStarted}
                annotationState={annotationState}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>)
})
export { BiologyPhotosynthesisLimitingFactorsAframe }