// src/components/TrackingStatusPanel.tsx
import React from 'react';
import '../styles/trackingStatusPanel.css';

interface TrackingStatusPanelProps {
  visible?: boolean;
  onRecenterClick: () => void;
}

const TrackingStatusPanel: React.FC<TrackingStatusPanelProps> = ({ visible, onRecenterClick }) => {
  if (!visible) return null;

  return (
    <div className="trackingStatusPanel">
      <p className="trackingStatusPanelTitle">Tracking status alert</p>
      <p className="trackingStatusPanelContent">
        Tracking quality is low. <br /> Please try not to rotate the device and ensure there is enough light in the room.
      </p>
      <div className="trackingStatusPanelButtons">
        <button className="trackingStatusPanelButton" onClick={onRecenterClick}>
          Recenter
        </button>
      </div>
    </div>
  );
};

export default TrackingStatusPanel;
