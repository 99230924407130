import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface ICellsSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    mixer: THREE.AnimationMixer;
    flyAnimation: THREE.AnimationAction
    plasmodiumAnimation: THREE.AnimationAction
    rbcAnimation: THREE.AnimationAction
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    buttonsInitialised: boolean;
    vectorButtonHandler: () => void;
    plasmodiumButtonHandler: () => void;
    rbcButtonHandler: () => void;
    model: AFrame.Entity;
    el: AFrame.Entity;
    plasmodiumTriggerBtn: AFrame.Entity;
    rbcTriggerBtn: AFrame.Entity;
    sound: any;
    firstAnn: boolean;
}

const CellScene = {
    name: 'cell-scene',
    val: {
        init(this: ICellsSceneAframe) {
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded

            })
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                    if (this.poolEntity.hasLoaded) {
                        initialiseButtons();
                    } else {
                        this.poolEntity.addEventListener('loaded', () => {
                            initialiseButtons();
                        });
                    }
                    this.sound = document.getElementById('mosquito') as any;
                    initialiseAnimation()
                }
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: holder; scale: 8 8 8; offset: 0 -4 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    this.firstAnn = false
                    this.sound.pause()
                    this.plasmodiumAnimation.stop()
                    this.rbcAnimation.stop()
                    this.plasmodiumTriggerBtn.setAttribute('scale', '0 0 0')
                    this.rbcTriggerBtn.setAttribute('scale', '0 0 0')
                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    this.flyAnimation.paused = false
                    if (this.firstAnn) {
                        if (this.sound) {
                            this.sound.play()
                        }
                    }
                } else {
                    this.flyAnimation.paused = true
                    if (this.firstAnn) {
                        if (this.sound) {
                            this.sound.pause()
                        }
                    }
                }
            });
            this.vectorButtonHandler = () => {
                this.firstAnn = true
                this.sound.play()
                this.plasmodiumAnimation.stop()
                this.plasmodiumAnimation.play()
                this.rbcAnimation.stop()
                this.plasmodiumTriggerBtn.setAttribute('scale', '0.2 0.2 0.2')
                this.rbcTriggerBtn.setAttribute('scale', '0 0 0')
                if (this.onObjectSelected) {
                    const title = 'Vector (Mosquito)';
                    const body = 'Malaria is transmitted through a vector, an organism that spreads a disease. An infected mosquito bites a person, it injects the protist parasite into their bloodstream. Malaria can be prevented by using insect repellents, protective clothing and mosquito nets. '
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }
            this.plasmodiumButtonHandler = () => {
                this.firstAnn = false
                if (this.sound) this.sound.pause()
                this.rbcAnimation.stop()
                this.rbcAnimation.play()
                this.rbcTriggerBtn.setAttribute('scale', '0.2 0.2 0.2')
                if (this.onObjectSelected) {
                    const title = 'Plasmodium';
                    const body = 'Plasmodium is the protist that causes malaria. When a mosquito bites an infected person, plasmodium is sucked up with blood. It then multiplies within the mosquito. Vaccines are unavailable, but treatments include antiparasitic (antimalarial) drugs. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }
            this.rbcButtonHandler = () => {
                this.firstAnn = false
                if (this.onObjectSelected) {
                    const title = 'Red Blood Cell (RBC)';
                    const body = 'Plasmodium multiplies in RBCs causing them to burst open, leading to anaemia, fever, chills, headache, muscle aches, and In severe cases: organ failure, seizures, coma, and death. The malaria parasite can also infect the liver and other organs in the body. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }
            const initialiseAnimation = () => {
                this.model = document.getElementById('model') as AFrame.Entity
                const animatedTMVEl = this.model.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedTMVEl)
                const [Fly, Plasmodium, RBC] = animatedTMVEl.animations
                this.flyAnimation = this.mixer.clipAction(Fly)
                this.plasmodiumAnimation = this.mixer.clipAction(Plasmodium)
                this.plasmodiumAnimation.repetitions = 1
                this.plasmodiumAnimation.clampWhenFinished = true
                this.rbcAnimation = this.mixer.clipAction(RBC)
                this.rbcAnimation.repetitions = 1
                this.rbcAnimation.clampWhenFinished = true
                this.flyAnimation.play()
            }

            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const vectorTriggerBtn = poolButtons.requestEntity()
                vectorTriggerBtn?.setAttribute('position', '-0.15 0.25 0.07')
                vectorTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                vectorTriggerBtn?.play()
                vectorTriggerBtn?.addEventListener('click', () => {
                    this.vectorButtonHandler()
                    if (vectorTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(vectorTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (vectorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = vectorTriggerBtn
                    }
                });
                this.plasmodiumTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.plasmodiumTriggerBtn?.setAttribute('position', '0.1 0.13 0.17')
                this.plasmodiumTriggerBtn?.setAttribute('scale', '0 0 0')
                this.plasmodiumTriggerBtn?.play()
                this.plasmodiumTriggerBtn?.addEventListener('click', () => {
                    this.plasmodiumButtonHandler()
                    if (this.plasmodiumTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.plasmodiumTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.plasmodiumTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.plasmodiumTriggerBtn
                    }
                });
                this.rbcTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.rbcTriggerBtn?.setAttribute('position', '-0.2 0.09 0.55')
                this.rbcTriggerBtn?.setAttribute('scale', '0 0 0')
                this.rbcTriggerBtn?.play()
                this.rbcTriggerBtn?.addEventListener('click', () => {
                    this.rbcButtonHandler()
                    if (this.rbcTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.rbcTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.rbcTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.rbcTriggerBtn
                    }
                });
            };
        },
        tick(this: ICellsSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {CellScene as CellSceneComponent}
