import { Stack, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IconImage } from '../../../shared/components/IconImage';
import { HeaderHashLink } from '../../../shared/components/HeaderHashLink';
import { CustomContainer } from '../../../shared/components/CustomContainer';
import { DisplayEnum } from '../../../shared/enums';
import { useOpenBurgerMenu } from '../../../shared/hooks/useOpenBurgerMenu';
import { BurgerMenu } from '../../../shared/components/BurgerMenu';
import { LanguageSelects } from '../../../shared/components/LanguageSelect';
import { useTranslation } from 'react-i18next';

import Logo from '../../../assets/Logo.png';
import HelpСircle from '../../../assets/helpСircle.svg';
import Mail from '../../../assets/mail.svg';
import LogIn from '../../../assets/logIn.svg';
import Book from '../../../assets/book.svg';
import Simulations from '../../../assets/simulations.svg';
import BurgerMenuImg from '../../../assets/burgerMenu.svg';

export function HeaderHomePageComponent() {
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { t, i18n } = useTranslation();

  const handleOpenBurgerMenu = useOpenBurgerMenu();
  return (
    <CustomContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <Box mt={0.5}>
            <HeaderHashLink to='/'>
              <IconImage src={Logo} width='67px' />
            </HeaderHashLink>
          </Box>
          {!mobile && (
            <>
              <Box ml={4}>
                <HeaderHashLink to='/simulations'>
                  <Box display='flex' alignItems='center'>
                    <Box mr={0.8} lineHeight='1'>
                      <IconImage
                        src={Simulations}
                        alt='simulations'
                        width='14px'
                      />
                    </Box>
                    {t('COMMON.LINK.SIMULATIONS')}
                  </Box>
                </HeaderHashLink>
              </Box>
              <Box ml={5}>
                <HeaderHashLink to='#howItWorks'>
                  <Box display='flex' alignItems='center'>
                    <Box mr={0.8} lineHeight='1'>
                      <IconImage
                        src={HelpСircle}
                        alt='how it works'
                        width='14px'
                      />
                    </Box>
                    {t('COMMON.LINK.HOW_IT_WORKS')}
                  </Box>
                </HeaderHashLink>
              </Box>

              <Box ml={5}>
                <HeaderHashLink to='#contacts'>
                  <Box display='flex' alignItems='center'>
                    <Box mr={0.8} lineHeight='1'>
                      <IconImage src={Mail} alt='courses' width='14px' />
                    </Box>
                    {t('COMMON.LINK.CONTACT_US')}
                  </Box>
                </HeaderHashLink>
              </Box>
            </>
          )}
        </Box>
        <Box display='flex' alignItems='center'>
          {/* <HeaderHashLink to='/login'>
            <Box display='flex' alignItems='center'>
              <Box mr={0.8} lineHeight='1'>
                <IconImage src={LogIn} alt='sign in' width='14px' />
              </Box>
              Sign In
            </Box>
          </HeaderHashLink> */}
          <Box ml={3}>
            <LanguageSelects />
          </Box>
          <Box
            bgcolor='rgba(255, 253, 1, 1)'
            borderRadius='6px'
            padding='12px 20px'
            ml={xlDisplay || mobile ? 3 : 1.5}
            sx={{ cursor: 'pointer' }}
          >
            <HeaderHashLink to='/#joinUs'>
              <Typography fontFamily='GilroySemiBold' color='#262626'>
                {t('COMMON.BUTTON.JOIN_US')}
              </Typography>
            </HeaderHashLink>
          </Box>
          {mobile && (
            <Box ml={1.5} onClick={handleOpenBurgerMenu}>
              <IconImage src={BurgerMenuImg} alt='menu' width='24px' />
            </Box>
          )}
        </Box>
      </Stack>
      <BurgerMenu hideNavigationMenu={true} />
    </CustomContainer>
  );
}

export default HeaderHomePageComponent;
