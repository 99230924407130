
import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IAnnotationSystemAframe } from "../../../lib/aframe/systems/annotation-system";
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface IHouseControl {
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    mixer: THREE.AnimationMixer;
    mixer2: THREE.AnimationMixer;
    foamCavityTriggerBtn: any;
    actionSpray: THREE.AnimationAction;
    actionRoll: THREE.AnimationAction;
    actionCurtains: THREE.AnimationAction;
    actionGlazing: THREE.AnimationAction;
    el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

const HouseControlComponent = {
    name: 'house-control',
    val: {
        init(this: IHouseControl) {
            // Add 'model-loaded' event listener to the component
            let modelCounter = 0;
            this.el.addEventListener('model-loaded', () => {
                modelCounter += 1;
                if (modelCounter < 2) {
                    return; // need both of models to be loaded
                }
                // disable frustum culling
                this.el.object3D.traverse((node) => {
                    if (node.frustumCulled) {
                        node.frustumCulled = false;
                    }
                });

                initialiseAnimations();

                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }

                // const tapPlaceEntity = document.getElementById('tapPlace') as AFrame.Entity;
                // if (tapPlaceEntity) {
                //     tapPlaceEntity.removeAttribute('tap-place-custom');
                //     this.el.sceneEl?.removeChild(tapPlaceEntity);
                // }
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: holder; scale: 3 3 3; relativeRotation: 0 -90 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
                // const tapPlaceEntity = document.getElementById('tapPlace') as AFrame.Entity;
                // if (tapPlaceEntity) {
                //     return;
                // } else {
                //     const newEntity = document.createElement('a-entity');
                //     newEntity.setAttribute('id', 'tapPlace');
                //     newEntity.setAttribute('tap-place-custom', 'id: house; scale: 3 3 3; relativeRotation: 0 -90 0');
                //     this.el.sceneEl?.appendChild(newEntity);
// 
                //     if(this.currentDeactivatedButton) {
                //         (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                //         // remove the line
                //         this.annotationComponent.deactivate();
                //     }
                // }
            });

            const initialiseAnimations = () => {
                console.log('Initialising Animations')
                const house = document.getElementById('house') as AFrame.Entity;
                const animatedEl = house?.object3D.getObjectByName('Scene') as any;
    
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [clipCurtains, clipGlazing,  clipRoll] = animatedEl.animations
                // this.actionDoor = this.mixer.clipAction(clipDoor) // not used
                this.actionGlazing = this.mixer.clipAction(clipGlazing)
                this.actionCurtains = this.mixer.clipAction(clipCurtains)
                this.actionRoll = this.mixer.clipAction(clipRoll)
                // set the roll to start frame
                this.actionRoll.time = 0;
                this.actionRoll.play();
                this.actionRoll.paused = true;


                // spray animation on a separate object
                const spray = document.getElementById('spray') as AFrame.Entity;
                
                if (spray) {
                    const sprayAnim = spray.object3D.getObjectByName('ARVU_House_FoamSequence') as any;
                    this.mixer2 = new THREE.AnimationMixer(sprayAnim); 

                    const [clipSpray] = sprayAnim.animations;
                    this.actionSpray = this.mixer2.clipAction(clipSpray);
                    this.actionSpray.time = 0;
                    this.actionSpray.play();
                    this.actionSpray.paused = true;
                }
                   

            }

            const windButtonHandler = () => {
                const title = 'Double Glazed Window'
                const body = 'A double glazed window is made from two solid layers of glass separated by a thin air gap. To escape the house, heat must travel through the glass, transfer into the air, and then back into the glass before it reaches the outside. This slows the rate of heat loss.'

                if (this.onObjectSelected) {
                    console.log('Calling onObjectSelected callback');
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }

                this.actionGlazing.reset();
                this.actionGlazing.repetitions = 1;
                this.actionGlazing.timeScale = 0.5;
                this.actionGlazing.play();
            }

            const curtainsButtonHandler = () => {
                const title = 'Thick Curtains'
                const body = 'Thick curtains reduce the flow of warm air reaching the windows. They also act as a thick solid insulator which heat must travel through to escape the home. '

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
                this.actionCurtains.reset()
                this.actionCurtains.clampWhenFinished = true
                this.actionCurtains.repetitions = 1
                this.actionCurtains.play()
            }

            const spaceCavityButtonHandler = () => {
                // enable sprayButton if not enabled
                const title = 'Cavity Wall'
                const body = 'Instead of having one thick wall, a cavity wall is made from two separate walls, separated by an air gap.</br>Heat escaping through a cavity wall must travel through one wall, heat the air in the cavity, and then travel through another wall.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
                this.foamCavityTriggerBtn.setAttribute('visible', true)
            }

            const foamCavityButtonHandler = () => {
                const title = 'Cavity Wall Insulation'
                const body = 'By filling the cavity with a foam-like insulation, air currents that carry heat from one wall to the next can no longer form. This is known as cavity wall insulation.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
                this.actionSpray.reset()
                this.actionSpray.repetitions = 1
                this.actionSpray.play()
            }

            const doorButtonHandler = () => {
                const title = 'Draft Excluder'
                const body = 'Heated air can flow under doors and escape to the outside. A draft excluder blocks this gap under a door and stops the flow of air under the external facing doors.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const wallButtonHandler = () => {
                const title = 'Thick Wall'
                const body = 'Walls are built from bricks which have a low thermal conductivity; heat passes through them slowly. The thicker we build the walls of our home, the longer it takes for heat to transfer from the inside to the outside.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const rollButtonHandler = () => {
                const title = 'Loft Insulation'
                const body = 'Insulating the roof space makes a thicker surface for heat to travel through to escape through the roof. It also reduces air currents forming in the roof space.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }
                this.actionRoll.reset()
                this.actionRoll.repetitions = 1
                this.actionRoll.clampWhenFinished = true
                this.actionRoll.play()
            }


            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const windowTriggerBtn = poolButtons.requestEntity();
                windowTriggerBtn?.setAttribute('position', '-1 1 1.25');
                windowTriggerBtn?.play();
                windowTriggerBtn?.addEventListener('click', () => {
                    windButtonHandler();
                    if (windowTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(windowTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(windowTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = windowTriggerBtn
                    }
                });

                const curtainsTriggerBtn = poolButtons.requestEntity()
                curtainsTriggerBtn?.setAttribute('position', '-1 0.9 -0.5')
                curtainsTriggerBtn?.play()
                curtainsTriggerBtn?.addEventListener('click', () => {
                    curtainsButtonHandler();
                    if (curtainsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(curtainsTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(curtainsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = curtainsTriggerBtn
                    }
                });

                const spaceCavityTriggerBtn = poolButtons.requestEntity()
                spaceCavityTriggerBtn?.setAttribute('position', '0.05 2 2.35')
                spaceCavityTriggerBtn?.play()
                spaceCavityTriggerBtn?.addEventListener('click', () => {
                    spaceCavityButtonHandler();
                    if (spaceCavityTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(spaceCavityTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(spaceCavityTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = spaceCavityTriggerBtn
                    }
                });

                this.foamCavityTriggerBtn = poolButtons.requestEntity()
                this.foamCavityTriggerBtn.setAttribute('position', '0.5 0.5 1.7')
                this.foamCavityTriggerBtn.play()
                this.foamCavityTriggerBtn.addEventListener('click', () => {
                    foamCavityButtonHandler();
                    if (this.foamCavityTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.foamCavityTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.foamCavityTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.foamCavityTriggerBtn
                    }
                });
                this.foamCavityTriggerBtn.setAttribute('visible', false);  // disabled by defaul

                const doorTriggerBtn = poolButtons.requestEntity()
                doorTriggerBtn?.setAttribute('position', '-0.6 0.2 -2')
                doorTriggerBtn?.play()
                doorTriggerBtn?.addEventListener('click', () => {
                    doorButtonHandler();
                    if (doorTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(doorTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(doorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = doorTriggerBtn
                    }
                });

                const wallTriggerBtn = poolButtons.requestEntity()
                wallTriggerBtn?.setAttribute('position', '0.05 1.5 -2.2')
                wallTriggerBtn?.play()
                wallTriggerBtn?.addEventListener('click', () => {
                    wallButtonHandler();
                    if (wallTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(wallTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(wallTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = wallTriggerBtn
                    }
                });

                const rollTriggerBtn = poolButtons.requestEntity()
                rollTriggerBtn?.setAttribute('position', '-0.05 2.1 -1.6')
                rollTriggerBtn?.play()
                rollTriggerBtn?.addEventListener('click', () => {
                    rollButtonHandler();
                    if (rollTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(rollTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(rollTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = rollTriggerBtn
                    }
                });
            }
        },
        tick(this: IHouseControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }

            if (this.mixer2) {
                this.mixer2.update(deltaTime * 0.001);
            }
        },
    },
};
export { HouseControlComponent }