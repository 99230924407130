import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';

interface ITransitionMetalsSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
	chromiumHandler: () => void;
    manganeseHandler: () => void;
    ironHandler: () => void;
    cobaltHandler: () => void;
    nickelHandler: () => void;
    copperHandler: () => void;
    currentHandler: () => void;
    chromiumAtom: AFrame.Entity;
    manganeseAtom: AFrame.Entity;
    ironAtom: AFrame.Entity;
    cobaltAtom: AFrame.Entity;
    nickelAtom: AFrame.Entity;
    copperAtom: AFrame.Entity;
    chromiumMixer: THREE.AnimationMixer;
    chromiumCoreAnimation: THREE.AnimationAction;
    chromiumElectronsAnimation: THREE.AnimationAction;
    manganeseMixer: THREE.AnimationMixer;
    manganeseCoreAnimation: THREE.AnimationAction;
    manganeseElectronsAnimation: THREE.AnimationAction;
    ironMixer: THREE.AnimationMixer;
    ironCoreAnimation: THREE.AnimationAction;
    ironElectronsAnimation: THREE.AnimationAction;
    cobaltMixer: THREE.AnimationMixer;
    cobaltCoreAnimation: THREE.AnimationAction;
    cobaltElectronsAnimation: THREE.AnimationAction;
    nickelMixer: THREE.AnimationMixer;
    nickelCoreAnimation: THREE.AnimationAction;
    nickelElectronsAnimation: THREE.AnimationAction;
    copperMixer: THREE.AnimationMixer;
    copperCoreAnimation: THREE.AnimationAction;
    copperElectronsAnimation: THREE.AnimationAction;
    currentCoreAnimation: THREE.AnimationAction;
    currentElectronAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; image: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    prevEl: AFrame.Entity;
    currentAssetId: number;
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: ITransitionMetalsSceneAframe) {
            const atoms = document.getElementById('atomsHolder') as AFrame.Entity;
            atoms.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                this.el.sceneEl?.addEventListener('lesson-start', () => {
                    // remove tap place
                    const ring = document.getElementById('ring')
                    if (ring) {
                        ring.removeAttribute('tap-place-air')
                        this.el.sceneEl?.removeChild(ring)
                    }
                    this.chromiumAtom = document.getElementById('chromiumAtom') as AFrame.Entity;
                    this.manganeseAtom = document.getElementById('manganeseAtom') as AFrame.Entity;
                    this.ironAtom = document.getElementById('ironAtom') as AFrame.Entity;
                    this.cobaltAtom = document.getElementById('cobaltAtom') as AFrame.Entity;
                    this.nickelAtom = document.getElementById('nickelAtom') as AFrame.Entity;
                    this.copperAtom = document.getElementById('copperAtom') as AFrame.Entity;
                    initialiseAnimations()

                    if (!this.currentHandler) this.currentHandler = this.chromiumHandler
                    if (!this.prevEl) this.prevEl = this.chromiumAtom
                    this.prevEl.object3D.visible = true
                    this.currentHandler()
                    this.currentCoreAnimation.play()
                    this.currentElectronAnimation.play()
                })

            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: atomsHolder; scale: 10 10 10; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                }
            });

            this.currentAssetId = 0;
            this.el.sceneEl?.addEventListener('asset-change', (event) => {

                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const newAssetId = customEvent.detail.assetId;
                switch (newAssetId) {
                    case 0:
                        this.chromiumHandler()
                        this.currentHandler = this.chromiumHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.chromiumAtom.object3D.visible = true
                        this.chromiumElectronsAnimation.play()
                        this.chromiumCoreAnimation.play()
                        this.prevEl = this.chromiumAtom
                        break;
                    case 1:
                        this.manganeseHandler()
                        this.currentHandler = this.manganeseHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.manganeseAtom.object3D.visible = true
                        this.manganeseElectronsAnimation.play()
                        this.manganeseCoreAnimation.play()
                        this.prevEl = this.manganeseAtom
                        break;
                    case 2:
                        this.ironHandler()
                        this.currentHandler = this.ironHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.ironAtom.object3D.visible = true
                        this.ironElectronsAnimation.play()
                        this.ironCoreAnimation.play()
                        this.prevEl = this.ironAtom
                        break;
                    case 3:
                        this.cobaltHandler()
                        this.currentHandler = this.cobaltHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.cobaltAtom.object3D.visible = true
                        this.cobaltElectronsAnimation.play()
                        this.cobaltCoreAnimation.play()
                        this.prevEl = this.cobaltAtom
                        break;
                    case 4:
                        this.nickelHandler()
                        this.currentHandler = this.nickelHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.nickelAtom.object3D.visible = true
                        this.nickelElectronsAnimation.play()
                        this.nickelCoreAnimation.play()
                        this.prevEl = this.nickelAtom
                        break;
                    case 5:
                        this.copperHandler()
                        this.currentHandler = this.copperHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.copperAtom.object3D.visible = true
                        this.copperElectronsAnimation.play()
                        this.copperCoreAnimation.play()
                        this.prevEl = this.copperAtom
                        break;
                }
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    if (this.currentCoreAnimation && this.currentElectronAnimation) {
                        this.currentCoreAnimation.paused = false;
                        this.currentElectronAnimation.paused = false;
                    }
                } else {
                    if (this.currentCoreAnimation && this.currentElectronAnimation) {
                        this.currentCoreAnimation.paused = true;
                        this.currentElectronAnimation.paused = true;
                    }
                }
            })
            this.chromiumHandler = () => {
                this.currentCoreAnimation = this.chromiumCoreAnimation
                this.currentElectronAnimation = this.chromiumElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/TransitionMetals/ChromiumCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.manganeseHandler = () => {
                this.currentCoreAnimation = this.manganeseCoreAnimation
                this.currentElectronAnimation = this.manganeseElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = '';
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/TransitionMetals/ManganeseCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.ironHandler = () => {
                this.currentCoreAnimation = this.ironCoreAnimation
                this.currentElectronAnimation = this.ironElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/TransitionMetals/IronCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.cobaltHandler = () => {
                this.currentCoreAnimation = this.cobaltCoreAnimation
                this.currentElectronAnimation = this.cobaltElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/TransitionMetals/CobaltCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.nickelHandler = () => {
                this.currentCoreAnimation = this.nickelCoreAnimation
                this.currentElectronAnimation = this.nickelElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/TransitionMetals/NickelCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            this.copperHandler = () => {
                this.currentCoreAnimation = this.copperCoreAnimation
                this.currentElectronAnimation = this.copperElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/TransitionMetals/CopperCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseAnimations = () => {
                const animatedChromiumEl = this.chromiumAtom.object3D.getObjectByName('Scene') as any;
                this.chromiumMixer = new THREE.AnimationMixer(animatedChromiumEl)
                const [ChromiumElectronsAnim, ChromiumCoreAnim] = animatedChromiumEl.animations
                this.chromiumElectronsAnimation = this.chromiumMixer.clipAction(ChromiumElectronsAnim)
                this.chromiumCoreAnimation = this.chromiumMixer.clipAction(ChromiumCoreAnim)

                const animatedManganeseEl = this.manganeseAtom.object3D.getObjectByName('Scene') as any;
                this.manganeseMixer = new THREE.AnimationMixer(animatedManganeseEl)
                const [ManganeseElectronsAnim, ManganeseCoreAnim] = animatedManganeseEl.animations
                this.manganeseElectronsAnimation = this.manganeseMixer.clipAction(ManganeseElectronsAnim)
                this.manganeseCoreAnimation = this.manganeseMixer.clipAction(ManganeseCoreAnim)

                const animatedIronEl = this.ironAtom.object3D.getObjectByName('Scene') as any;
                this.ironMixer = new THREE.AnimationMixer(animatedIronEl)
                const [IronElectronsAnim, IronCoreAnim] = animatedIronEl.animations
                this.ironElectronsAnimation = this.ironMixer.clipAction(IronElectronsAnim)
                this.ironCoreAnimation = this.ironMixer.clipAction(IronCoreAnim)

                const animatedCobaltEl = this.cobaltAtom.object3D.getObjectByName('Scene') as any;
                this.cobaltMixer = new THREE.AnimationMixer(animatedCobaltEl)
                const [CobaltElectronsAnim, CobaltCoreAnim] = animatedCobaltEl.animations
                this.cobaltElectronsAnimation = this.cobaltMixer.clipAction(CobaltElectronsAnim)
                this.cobaltCoreAnimation = this.cobaltMixer.clipAction(CobaltCoreAnim)

                const animatedNickelEl = this.nickelAtom.object3D.getObjectByName('Scene') as any;
                this.nickelMixer = new THREE.AnimationMixer(animatedNickelEl)
                const [NickelElectronsAnim, NickelCoreAnim] = animatedNickelEl.animations
                this.nickelElectronsAnimation = this.nickelMixer.clipAction(NickelElectronsAnim)
                this.nickelCoreAnimation = this.nickelMixer.clipAction(NickelCoreAnim)

                const animatedCopperEl = this.copperAtom.object3D.getObjectByName('Scene') as any;
                this.copperMixer = new THREE.AnimationMixer(animatedCopperEl)
                const [CopperElectronsAnim, CopperCoreAnim] = animatedCopperEl.animations
                this.copperElectronsAnimation = this.copperMixer.clipAction(CopperElectronsAnim)
                this.copperCoreAnimation = this.copperMixer.clipAction(CopperCoreAnim)

            }
        },
        tick(this: ITransitionMetalsSceneAframe, time: number, deltaTime: number) {
            if (this.chromiumMixer) {
                this.chromiumMixer.update(deltaTime * 0.001);
            }
            if (this.manganeseMixer) {
                this.manganeseMixer.update(deltaTime * 0.001);
            }
            if (this.ironMixer) {
                this.ironMixer.update(deltaTime * 0.001);
            }
            if (this.cobaltMixer) {
                this.cobaltMixer.update(deltaTime * 0.001);
            }
            if (this.nickelMixer) {
                this.nickelMixer.update(deltaTime * 0.001);
            }
            if (this.copperMixer) {
                this.copperMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {AtomsScene as AtomsSceneComponent}
