import {
  Box,
  Dialog,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { RoleMenuModal } from './RoleMenuModal';
import { IconImage } from './IconImage';
import { useCallback, useState } from 'react';
import { RoleEnum } from 'shared/enums/role.enum';
import { DisplayEnum } from 'shared/enums';
import { useSetRoleType } from 'shared/hooks/useSetRoleType';
import { useTranslation } from 'react-i18next';

import Logo from '../../assets/Logo.png';
import Student from '../../assets/student.png';
import Teacher from '../../assets/teacher.png';

export function RoleMenu() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const roleType = localStorage.getItem('roleType');
  const [notShowPopup, setNotShowPopup] = useState(!!roleType);
  const setRoleType = useSetRoleType();
  const setRole = useCallback(
    (value: RoleEnum) => {
      localStorage.setItem('roleType', value);
      setNotShowPopup(true);
      setRoleType();
    },
    [setNotShowPopup, setRoleType]
  );
  const { t, i18n } = useTranslation();
  const i18nPath = 'ROLE_MENU_MODAL.';

  return (
    <Dialog fullScreen open={!notShowPopup} PaperComponent={RoleMenuModal}>
      <Stack>
        <Stack alignItems='center' justifyContent='center'>
          <Box>
            <IconImage src={Logo} width='67px' />
          </Box>
          <Typography
            mt={mobile ? 3 : 6}
            color='#000'
            fontFamily='GilroyBold'
            fontSize={mobile ? 32 : 54}
            textAlign='center'
            lineHeight={1}
          >
            {t(i18nPath + 'TITLE.PART_1')} <br /> {t(i18nPath + 'TITLE.PART_2')}
          </Typography>
          <Typography
            mt={2}
            color={theme.palette.text.primary}
            fontFamily='GilroySemiBold'
            fontSize={mobile ? 16 : 18}
            textAlign='center'
          >
            {t(i18nPath + 'DESCRIPTION.PART_1')}
            <br />
            {t(i18nPath + 'DESCRIPTION.PART_2')}
          </Typography>
          <Typography
            mt={2}
            color={theme.palette.text.primary}
            fontFamily='GilroySemiBold'
            fontSize={mobile ? 16 : 18}
            textAlign='center'
          >
            {t(i18nPath + 'DESCRIPTION.PART_3')}
            <br />
            {t(i18nPath + 'DESCRIPTION.PART_4')}
            <br />
            {t(i18nPath + 'DESCRIPTION.PART_5')}
          </Typography>
          <Stack
            mt={5}
            width={mobile ? '100%' : 'auto'}
            direction='row'
            alignItems='center'
            justifyContent={mobile ? 'space-between' : 'center'}
          >
            <Stack
              mr={mobile ? 3 : 7}
              onClick={() => {
                setRole(RoleEnum.Student);
              }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  'div:nth-of-type(2)': {
                    backgroundColor: 'rgba(153, 69, 255, 1)',
                    color: '#fff',
                  },
                },
              }}
            >
              <Box>
                <IconImage src={Student} width='111px' />
              </Box>
              <Box
                fontFamily='GilroySemiBold'
                padding='10px 20px'
                borderRadius='60px'
                color={theme.palette.text.primary}
                textAlign='center'
              >
                {t('COMMON.BUTTON.STUDENT')}
              </Box>
            </Stack>

            <Stack
              onClick={() => {
                setRole(RoleEnum.Teacher);
              }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  'div:nth-of-type(2)': {
                    backgroundColor: 'rgba(153, 69, 255, 1)',
                    color: '#fff',
                  },
                },
              }}
            >
              <Box>
                <IconImage src={Teacher} width='111px' />
              </Box>
              <Box
                fontFamily='GilroySemiBold'
                padding='10px 20px'
                borderRadius='60px'
                color={theme.palette.text.primary}
                textAlign='center'
              >
                {t('COMMON.BUTTON.TEACHER')}
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
