import React, {useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
    Annotation,
    AnnotationState,
} from '../../../lib/aframe/components/annotation';
import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import {WorldButtonComponent} from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import {GreenhouseSceneComponent} from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
} from 'shared/constants/hint-items';
import {TapPlaceAir} from 'lib/aframe/components/tap-place-air';
import {ShaderSmokeComponent} from "./shader-smoke";
import {ShaderSmoke2Component} from "./shader-smoke2";
import {ShaderSmokeFarmComponent} from "./shader-smoke-farm";
import {LoadersPermissonsEnum} from "../../../shared/enums";
import {ShaderSmokeCowsComponent} from "./shader-smoke-cows";

const introText =
    'Look around the Earth to learn about the greenhouse effect and how humans contribute to greenhouse gas concentrations in the atmosphere.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT];

const ChemistryGreenhouseGasesAframe = () => {
    const [selectedObject, setSelectedObject] = useState<{
        title: string;
        body: string;
        imageExtra?: string;
    } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(
        AnnotationState.None
    );
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

    const [lessonStarted, setLessonStarted] = useState(false);

    const onLessonStarted = () => {
        setLessonStarted(true);
    };
    const onObjectSelected = (data: {
        title: string;
        body: string;
        imageExtra?: string;
    }) => {
        setSelectedObject(data);
    };

    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };
    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };
    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                lessonStarted={lessonStarted}
                onAnnotationStateChanged={onAnnotationStateChanged}
                hintItems={hintItems}
                permissions={permissions}

            />
            <AFrameScene
                sceneHtml={require('./chemistry-greenhouse-gases-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    TapPlaceAir,
                    ShaderSmokeComponent,
                    ShaderSmoke2Component,
                    ShaderSmokeFarmComponent,
                    ShaderSmokeCowsComponent,
                    WorldButtonComponent,
                    FaceCameraComponent,
                    GreenhouseSceneComponent,
                    Annotation,
                ]}
                onObjectSelected={onObjectSelected}
                annotationState={annotationState}
                onLessonStart={onLessonStarted}
                permissionsСonfirmation={permissionsСonfirmation}

            />
        </React.Fragment>
    );
};
export {ChemistryGreenhouseGasesAframe};
