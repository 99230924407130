import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface ICellSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	actionRespiration: THREE.AnimationAction;
	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;
	el: AFrame.Entity;
	currentAssetId: number;
	poolButtons: PoolComponent;

	controlElement: AFrame.Entity;

	nucleusTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	mitochondriaTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	cytoplasmTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	ribosomeTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	cellMembraneTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	cellWallTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	chloroplastsTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	vacuoleTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	plantTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;

	nucleusButtonHandler: () => void;
	mitochondriaButtonHandler: () => void;
	cytoplasmButtonHandler: () => void;
	ribosomeButtonHandler: () => void;
	cellMembraneButtonHandler: () => void;
	cellWallButtonHandler: () => void;
	chloroplastsButtonHandler: () => void;
	vacuoleButtonHandler: () => void;
	plantButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const PlantCellScene = {
	name: 'plant-cell-scene',
	val: {
		init(this: ICellSceneAframe) {
			this.controlElement = document.getElementById('sceneHolder') as AFrame.Entity;
			this.controlElement.addEventListener('model-loaded', (e) => {
				const customEvent = e as CustomEvent;
				const modelId = (customEvent.target as HTMLElement).id
				console.log('Loaded model', modelId);
				// only proceeding with the initialisation if the model is the one we want and nothing was yet initialised
				if (modelId !== 'plantCell') {
					return;
				}
				
				const scene = this.el.sceneEl as AFrame.Scene & {
					systems: { "annotation-system": IAnnotationSystemAframe };
				};
				const annotationSystem = scene.systems["annotation-system"];
				this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
				this.el.setAttribute('annotation', '');
				this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}
				
			})

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			// when model is loaded adding recenter functionality
			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// position this el in fron of camera with the following offset "3 4.5 -5"
				// Get the camera and the desired offset.
				// Get the A-Frame camera component.
				const cameraEl = this.el.sceneEl?.querySelector('a-camera');

				// Set the position of the element to the camera's position.

				if (cameraEl) {
					console.log('Camera found: ', cameraEl.getAttribute('rotation'));

					console.log('Rotation before: ', this.el.getAttribute('rotation'));


					// Get the camera's Y rotation.
					const cameraRotation = cameraEl.getAttribute('rotation') as unknown as THREE.Euler;
					const cameraRotationY = cameraRotation ? cameraRotation.y : 0;

					// Get the current rotation of the element.
					const elementRotation = this.el.getAttribute('rotation');

					// Set the element's Y rotation to match the camera's Y rotation.
					this.el.setAttribute('rotation', {
						x: elementRotation.x,
						y: cameraRotationY,
						z: elementRotation.z
					});
					console.log('Rotation after: ', this.el.getAttribute('rotation'))

					const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
					// Create an offset vector.
					const offset = new THREE.Vector3(0, -8, -8);
					offset.applyQuaternion(this.el.object3D.quaternion);

					// Add the offset to the camera's position.
					const newPosition = camPos.clone().add(offset);

					// Set the position of the element with the offset.
					this.el.setAttribute('position', newPosition);
				}
			});

			this.nucleusButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				console.log('button is clicked')
				if (this.onObjectSelected) {
					const title = 'Nucleus';
					const body = 'The nucleus contains the genetic material of the cell; it contains the chromosomes which are made up of DNA. The function of the nucleus is to control the cell processes.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.mitochondriaButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Mitochondrion';
					const body = 'Mitochondria are the powerhouse of the cell. They are the site of cellular respiration where glucose and oxygen produce energy (ATP) to power the cell processes such as cell division, protein synthesis, and movement.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.cytoplasmButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Cytoplasm';
					const body = 'Cytoplasm is a jelly-like structure that contains all cellular structures including dissolved nutrients, salts and organelles. It acts as a medium for transportation of substances as well as a site for cellular chemical reactions.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.ribosomeButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Ribosome';
					const body = 'Ribosomes are small, complex molecular machines that are responsible for synthesising proteins.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.cellMembraneButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Cell Membrane';
					const body = 'The cell membrane is made up of a phospholipid bilayer. It holds the important function of allowing certain substances in and out of the cell.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.cellWallButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Cell Wall';
					const body = 'Plant cells have cell walls as an outermost layer; they are made up of cellulose. It provides structure and strength to the cell and supports the plant. The cell wall ensures that water pressure inside the cell due to osmosis does not cause the cell to burst but keeps the cell turgid.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.chloroplastsButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Chloroplasts';
					const body = 'Plant cells contain chloroplasts. They are organelles that contain chlorophyll, the green pigment that absorbs light for photosynthesis. Chloroplasts are where photosynthesis takes place.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.vacuoleButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Vacuole';
					const body = 'Plant cells contain large permanent vacuoles that contain cell sap. Cell sap is made up of water, sugar, salt and other substances. The function of the large vacuole is to store nutrients and keep the turgidity of the cell.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.plantButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Plant Cell';
					const body = 'This is a plant cell. It contains all organelles within an animal cell, in addition to a large vacuole, a cell wall and chloroplasts';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}
			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.nucleusTriggerBtn = poolButtons.requestEntity()
				this.nucleusTriggerBtn?.object3D.position.set(-1, 3.78, -2.1)
				this.nucleusTriggerBtn?.object3D.scale.set(1.8, 1.8, 1.8)
				this.nucleusTriggerBtn?.play()
				this.nucleusTriggerBtn?.addEventListener('click', () => {
					this.nucleusButtonHandler();
					if (this.nucleusTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.nucleusTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.nucleusTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.nucleusTriggerBtn
					}
				});

				this.mitochondriaTriggerBtn = poolButtons.requestEntity()
				this.mitochondriaTriggerBtn?.object3D.position.set(-2.5, 2.88, -1.8)
				this.mitochondriaTriggerBtn?.object3D.scale.set(1.8, 1.8, 1.8)
				this.mitochondriaTriggerBtn?.play()
				this.mitochondriaTriggerBtn?.addEventListener('click', () => {
					this.mitochondriaButtonHandler();
					if (this.mitochondriaTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.mitochondriaTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.mitochondriaTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.mitochondriaTriggerBtn
					}
				});

				this.cellMembraneTriggerBtn = poolButtons.requestEntity()
				this.cellMembraneTriggerBtn?.object3D.position.set(0, 7.33, -3.2)
				this.cellMembraneTriggerBtn?.object3D.scale.set(1.8, 1.8, 1.8)
				this.cellMembraneTriggerBtn?.play()
				this.cellMembraneTriggerBtn?.addEventListener('click', () => {
					this.cellMembraneButtonHandler();
					if (this.cellMembraneTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.cellMembraneTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.cellMembraneTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.cellMembraneTriggerBtn
					}
				});

				this.cellWallTriggerBtn = poolButtons.requestEntity()
				this.cellWallTriggerBtn?.object3D.position.set(-0.75, 2.38, 0.1)
				this.cellWallTriggerBtn?.object3D.scale.set(1.6, 1.6, 1.6)
				this.cellWallTriggerBtn?.play()
				this.cellWallTriggerBtn?.addEventListener('click', () => {
					this.cellWallButtonHandler();
					if (this.cellWallTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.cellWallTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.cellWallTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.cellWallTriggerBtn
					}
				});

				this.cytoplasmTriggerBtn = poolButtons.requestEntity()
				this.cytoplasmTriggerBtn?.object3D.position.set(0.85, 5.1, -3.2)
				this.cytoplasmTriggerBtn?.object3D.scale.set(1.8, 1.8, 1.8)
				this.cytoplasmTriggerBtn?.play()
				this.cytoplasmTriggerBtn?.addEventListener('click', () => {
					this.cytoplasmButtonHandler();
					if (this.cytoplasmTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.cytoplasmTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.cytoplasmTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.cytoplasmTriggerBtn
					}
				});

				this.ribosomeTriggerBtn = poolButtons.requestEntity()
				this.ribosomeTriggerBtn?.object3D.position.set(-1, 5.8, -3.2)
				this.ribosomeTriggerBtn?.object3D.scale.set(1.8, 1.8, 1.8)
				this.ribosomeTriggerBtn?.play()
				this.ribosomeTriggerBtn?.addEventListener('click', () => {
					this.ribosomeButtonHandler();
					if (this.ribosomeTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.ribosomeTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.ribosomeTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.ribosomeTriggerBtn
					}
				});

				this.chloroplastsTriggerBtn = poolButtons.requestEntity()
				this.chloroplastsTriggerBtn?.object3D.position.set(0.9, 3, -1)
				this.chloroplastsTriggerBtn?.object3D.scale.set(1.8, 1.8, 1.8)
				this.chloroplastsTriggerBtn?.play()
				this.chloroplastsTriggerBtn?.addEventListener('click', () => {
					this.chloroplastsButtonHandler();
					if (this.chloroplastsTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.chloroplastsTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.chloroplastsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.chloroplastsTriggerBtn
					}
				});

				this.vacuoleTriggerBtn = poolButtons.requestEntity()
				this.vacuoleTriggerBtn?.object3D.position.set(1.3, 6.6, -2.5)
				this.vacuoleTriggerBtn?.object3D.scale.set(1.8, 1.8, 1.8)
				this.vacuoleTriggerBtn?.play()
				this.vacuoleTriggerBtn?.addEventListener('click', () => {
					this.vacuoleButtonHandler();
					if (this.vacuoleTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.vacuoleTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.vacuoleTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.vacuoleTriggerBtn
					}
				});

				this.plantTriggerBtn = poolButtons.requestEntity()
				this.plantTriggerBtn?.object3D.position.set(1.95, 2.8, 0.1)
				this.plantTriggerBtn?.object3D.scale.set(1.6, 1.6, 1.6)
				this.plantTriggerBtn?.play()
				this.plantTriggerBtn?.addEventListener('click', () => {
					this.plantButtonHandler();
					if (this.plantTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.plantTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.plantTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.plantTriggerBtn
					}
				});
			}

		},
		tick(this: ICellSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { PlantCellScene as PlantCellSceneComponent }