
import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IAnnotationSystemAframe } from "../../../lib/aframe/systems/annotation-system";
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IStateMachine } from './state-machine';

interface IHouseControl {
    isAnimationPlaying: boolean;
    DropActions: THREE.AnimationAction[];
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    mixer: THREE.AnimationMixer;
    stateMachine: IStateMachine;
    el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

const SceneControlComponent = {
    name: 'scene-control',
    val: {
        init(this: IHouseControl) {
            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                // console.log('model loaded');
                
                setupLiquidTextures();
                initialiseAnimations();

                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}

                // get state machine
                this.stateMachine = this.el.components['state-machine'] as unknown as IStateMachine;
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                // console.log('Event recenter received')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: base; scale: 40 40 40; relativeRotation: 0 -90 0;');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });

            /**
             * Setting liquid textures to be transparent
             */
            const setupLiquidTextures = () => {
                // tube fluid
                const tubeFluid = this.el.object3D.getObjectByName('TubeFluid') as THREE.Mesh;
                // const fluidMat = tubeFluid.material as THREE.MeshStandardMaterial;
                // fluidMat.transparent = true;
                // fluidMat.opacity = 0.0;
                // tubeFluid.visible = false; #FFFDD0
                const dissolved = this.el.object3D.getObjectByName('TubeFluidDissolved') as THREE.Mesh;
                dissolved.visible = false;
               
                const dissolved1 = this.el.object3D.getObjectByName('TubeFluidDissolved_1') as THREE.Mesh;
                dissolved1.visible = false;
                const mat1 = dissolved1.material as THREE.MeshStandardMaterial;
                
                const dissolved2 = this.el.object3D.getObjectByName('TubeFluidDissolved_2') as THREE.Mesh;
                dissolved2.visible = false;

                const dissolved3 = this.el.object3D.getObjectByName('TubeFluidDissolved_3') as THREE.Mesh;
                dissolved3.visible = false;
                const mat3 = new THREE.MeshStandardMaterial();
                mat3.copy(mat1);
                dissolved3.material = mat3;

                const dissolved4 = this.el.object3D.getObjectByName('TubeFluidDissolved_4') as THREE.Mesh;
                dissolved4.visible = false;
                const mat4 = new THREE.MeshStandardMaterial();
                mat4.copy(mat1);
                dissolved4.material = mat4;

                const dissolved5 = this.el.object3D.getObjectByName('TubeFluidDissolved_5') as THREE.Mesh;
                dissolved5.visible = false;
                
                // changing nitrate to be yellowish
                // this.el.object3D.traverse((node) => {
                //     console.log(node.name);
                // });
               
            };

            /**
             * Funciton to execute when animation finished
             * Switching state to the next one
             */
            const onAnimationFinished = () => {
                this.stateMachine.nextState();
                this.isAnimationPlaying = false;
            };

            const createClickableAreas = () => {
                
                const number1 = this.el.object3D.getObjectByName('Number_0-1') as THREE.Object3D;
                const number2 = this.el.object3D.getObjectByName('Number_0-2') as THREE.Object3D;
                const number3 = this.el.object3D.getObjectByName('Number_1-1') as THREE.Object3D;
                const number4 = this.el.object3D.getObjectByName('Number_1-2') as THREE.Object3D;
                const number5 = this.el.object3D.getObjectByName('Number_2-2') as THREE.Object3D;
                

                // create cube A-Entity to make bottle clickable
                const cube2 = document.createElement('a-entity');
                cube2.setAttribute('geometry', {
                  primitive: 'box',
                  height: 0.1,
                  width: 0.025,
                  depth: 0.035
                });
                cube2.setAttribute('visible', 'false');
                cube2.setAttribute('position', '0.032 0.01 -0.098');
                cube2.setAttribute('class', 'cantap');
                cube2.addEventListener('click', () => {
                    if (!this.isAnimationPlaying) {
                        // making sure we are not in process of playing animation already
                        const state = this.stateMachine.getState();
                           
                        switch (state) {
                            case 0:
                                // simply drop the liquid to first tube
                                if (this.DropActions) {
                                    this.DropActions[0].reset();
                                    this.DropActions[0].repetitions = 1;
                                    this.DropActions[0].play();
                                    this.isAnimationPlaying = true;
                                    activateWhiteSubstance(this.DropActions[0]);
                                    number1.visible = false;
                                    number3.visible = true;
                                }
                                break;
                        
                            case 1:
                                // drop the liquid to second tube
                                if (this.DropActions) {
                                    this.DropActions[1].reset();
                                    this.DropActions[1].repetitions = 1;
                                    this.DropActions[1].play();
                                    this.isAnimationPlaying = true;
                                    activateWhiteSubstance(this.DropActions[1]);
                                    number2.visible = false;
                                    number4.visible = true;
                                }
                                break;
                        
                            case 2:
                                // drop the liquid to 2nd tube again
                                if (this.DropActions) {
                                    this.DropActions[1].reset();
                                    this.DropActions[1].repetitions = 1;
                                    this.DropActions[1].play();
                                    this.isAnimationPlaying = true;
                                    this.stateMachine.deactivateAlert();
                                    number4.visible = false;
                                    number5.visible = true;
                                }
                                break;
                        
                            case 3:
                                // drop the liquid to 3rd tube
                                if (this.DropActions) {
                                    this.DropActions[2].reset();
                                    this.DropActions[2].repetitions = 1;
                                    this.DropActions[2].play();
                                    this.isAnimationPlaying = true;
                                    activateWhiteSubstance(this.DropActions[2]);
                                    number1.visible = false;
                                    number3.visible = true;
                                }
                                break;
                        
                            case 4:
                                // drop the liquid to the forth tube
                                if (this.DropActions) {
                                    this.DropActions[3].reset();
                                    this.DropActions[3].repetitions = 1;
                                    this.DropActions[3].play();
                                    this.isAnimationPlaying = true;
                                    activateWhiteSubstance(this.DropActions[3]);
                                    number2.visible = false;
                                    number4.visible = true;
                                }
                                break;
                        
                            case 5:
                                // drop the liquid to the 4th tube again
                                this.DropActions[3].reset();
                                this.DropActions[3].repetitions = 1;
                                this.DropActions[3].play();
                                this.isAnimationPlaying = true;
                                this.stateMachine.deactivateAlert();
                                number4.visible = false;
                                number5.visible = true;
                                break;
                            case 6:
                                // drop the liquid to the 5th tube
                                if (this.DropActions) {
                                    this.DropActions[4].reset();
                                    this.DropActions[4].repetitions = 1;
                                    this.DropActions[4].play();
                                    this.isAnimationPlaying = true;
                                    activateWhiteSubstance(this.DropActions[4]);
                                    number2.visible = false;
                                    number4.visible = true;
                                }
                                break;
                            case 7:
                                // drop the liquid to the 5th tube again
                                if (this.DropActions) {
                                    this.DropActions[4].reset();
                                    this.DropActions[4].repetitions = 1;
                                    this.DropActions[4].play();
                                    this.isAnimationPlaying = true;
                                    this.stateMachine.deactivateAlert();
                                    deactivateWhiteSubstance(this.DropActions[4]);
                                    number4.visible = false;
                                    number5.visible = true;
                                }
                                break;
                        
                            case 8:
                                // drop the liquid to the 6th tube again
                                this.DropActions[5].reset();
                                this.DropActions[5].repetitions = 1;
                                this.DropActions[5].play();
                                this.isAnimationPlaying = true;
                                activateWhiteSubstance(this.DropActions[5]);
                                number1.visible = false;
                                number3.visible = true;
                                break;
                        }
                        
                    }
                    
                });

                this.mixer.addEventListener('finished', () => {
                    onAnimationFinished();
                });

                this.el.appendChild(cube2);
            };

            const initialiseAnimations = () => {
                this.isAnimationPlaying = false
                const base = document.getElementById('base') as AFrame.Entity;
                if (base) {
                    const animatedEl = base.object3D.getObjectByName('AR_Chem_Lesson4') as THREE.Object3D;
                    this.mixer = new THREE.AnimationMixer(animatedEl);
                    const [clip1, clip2, clip3, clip4, clip5, clip6] = animatedEl.animations;
                    const action1 = this.mixer.clipAction(clip1);
                    const action2 = this.mixer.clipAction(clip2);
                    const action3 = this.mixer.clipAction(clip3);
                    const action4 = this.mixer.clipAction(clip4);
                    const action5 = this.mixer.clipAction(clip5);
                    const action6 = this.mixer.clipAction(clip6);
                    this.DropActions = [action1, action2, action3, action4, action5, action6];
                    // create buttons to trigger animations
                    createClickableAreas();

                } else {
                    console.warn('Scene-control.tsx: Intialising animations - Entity with id: Scene is not found')
                }
            }

            const DropperButtonHandler = () => {
                const title = 'Sodium Hydroxide'
                const body = 'When added to a solution with metal ions insoluble, coloured hydroxides form. Tap the flash card icon for more information.'
                
                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            // const Drop2ButtonHandler = () => {
            //     const title = 'Nitric Acid'
            //     const body = 'Dilute nitric acid is used first to acidify the solution, removing carbonate ions to avoid false positive results.<i>Tap the bottle labelled “HNO<sub>3</sub>” to add a few drops of barium chloride to the unknown solution.</i>'

            //     if (this.onObjectSelected) {
            //         this.onObjectSelected({ title, body })
            //     } else {
            //         console.log('No object selected method')
            //     }
            // }

            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const drop1TriggerBtn = poolButtons.requestEntity();
                if (drop1TriggerBtn) {
                    drop1TriggerBtn.setAttribute('position', '0.01 0 -0.12');
                    drop1TriggerBtn.play();
                    drop1TriggerBtn.addEventListener('click', () => {
                        DropperButtonHandler();
                        if (drop1TriggerBtn) {
                            this.annotationComponent.setObjectToFollow(drop1TriggerBtn);
                            if(this.currentDeactivatedButton) {
                                (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                            }
                            (drop1TriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                            this.currentDeactivatedButton = drop1TriggerBtn
                        }
                    });
                }
               

                // const drop2TriggerBtn = poolButtons.requestEntity()
                // drop2TriggerBtn?.setAttribute('position', '0.01 0 -0.18')
                // drop2TriggerBtn?.play()
                // drop2TriggerBtn?.addEventListener('click', () => {
                //     Drop2ButtonHandler();
                //     if (drop2TriggerBtn) {
                //         this.annotationComponent.setObjectToFollow(drop2TriggerBtn);
                //         if(this.currentDeactivatedButton) {
				// 			(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
				// 		}
				// 		(drop2TriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
				// 		this.currentDeactivatedButton = drop2TriggerBtn
                //     }
                // });
            }

            /**
             * Activating white substance in the tube
             * NOTE: In this lesson substances are colored
             */
           
            const activateWhiteSubstance = (playedAction: THREE.AnimationAction) => {
                const stateId = this.stateMachine.getState();
                let fluidName;
            
                switch (stateId) {
                  case 0:
                    fluidName = 'TubeFluidDissolved';
                    break;
                  case 1:
                    fluidName = 'TubeFluidDissolved_1';
                    break;
                  case 3:
                    fluidName = 'TubeFluidDissolved_2';
                    break;
                  case 4:
                    fluidName = 'TubeFluidDissolved_3';
                    break;
                  case 6:  
                    fluidName = 'TubeFluidDissolved_4'; 
                    break;
                  case 8:
                    fluidName = 'TubeFluidDissolved_5';
                    break;
                  default:
                    return; // If stateId doesn't match, exit the function
                }
            
                const whiteFluid = this.el.object3D.getObjectByName(fluidName) as THREE.Mesh;
                const fluidMaterial = whiteFluid.material as THREE.MeshBasicMaterial;
            
                if (fluidMaterial) {
                  fluidMaterial.opacity = 0;
                  fluidMaterial.transparent = true;
                  whiteFluid.visible = true;
                
                  const targetOpacity = 1;
                  const animationDuration = 3500; // Duration in milliseconds
                  let startTime = performance.now();
                
                  const trackAnimationState = (timestamp: number) => {
                    // get the animation time
                    const actionTime = playedAction.time;
                    if(actionTime > 2.5) {
                        startTime = performance.now();
                        requestAnimationFrame(animateFluid); // start the fluid animation
                        return;
                    }

                    requestAnimationFrame(trackAnimationState);
                  };

                  const animateFluid = (timestamp: number) => {
                    // start animating white substance
                    const elapsed = timestamp - startTime;
                
                    if (elapsed >= animationDuration) {
                      fluidMaterial.opacity = targetOpacity;
                      // originalFluidMaterial.opacity = 0;
                      return; // Terminate the loop
                    }
                
                    // Calculate the current opacity based on the elapsed time
                    fluidMaterial.opacity = (elapsed / animationDuration) * targetOpacity;
                    // originalFluidMaterial.opacity = 1 - (elapsed / animationDuration) * targetOpacity;
                    // console.log('animate fluid')
                    requestAnimationFrame(animateFluid);
                  };
              
                  requestAnimationFrame(trackAnimationState);
                }
            };

            const deactivateWhiteSubstance = (playedAction: THREE.AnimationAction) => {
                const whiteFluid = this.el.object3D.getObjectByName('TubeFluidDissolved_4') as THREE.Mesh;
                const fluidMaterial = whiteFluid.material as THREE.MeshBasicMaterial;
            
                if (fluidMaterial) {
                  fluidMaterial.opacity = 1;
                  fluidMaterial.transparent = true;
                  whiteFluid.visible = true;
                
                  const targetOpacity = 0;
                  const animationDuration = 3500; // Duration in milliseconds
                  let startTime = performance.now();
                
                  const trackAnimationState = (timestamp: number) => {
                    // get the animation time
                    const actionTime = playedAction.time;
                    if(actionTime > 2.5) {
                        startTime = performance.now();
                        requestAnimationFrame(animateFluid); // start the fluid animation
                        return;
                    }

                    requestAnimationFrame(trackAnimationState);
                  };

                  const animateFluid = (timestamp: number) => {
                    // start animating white substance
                    const elapsed = timestamp - startTime;
                
                    if (elapsed >= animationDuration) {
                      fluidMaterial.opacity = targetOpacity;
                      // originalFluidMaterial.opacity = 0;
                      return; // Terminate the loop
                    }
                
                    // Calculate the current opacity based on the elapsed time
                    fluidMaterial.opacity = 1 - (elapsed / animationDuration);
                    // originalFluidMaterial.opacity = 1 - (elapsed / animationDuration) * targetOpacity;
                    // console.log('animate fluid')
                    requestAnimationFrame(animateFluid);
                  };
              
                  requestAnimationFrame(trackAnimationState);
                }
            };

        },
        tick(this: IHouseControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export { SceneControlComponent }