import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface IAtmosphereControl {
    poolEntity: AFrame.Entity;
    gasInMotionHandler: () => void;
    theMoonsAtmosphereHandler: () => void;
    jupitersAtmosphereHandler: () => void;
    tooHotToHoldHandler: () => void;
    earthsAtmosphereHandler: () => void;
    mixer: THREE.AnimationMixer;
    earthRotateAnimation: THREE.AnimationAction;
    jupiterRotateAnimation: THREE.AnimationAction;
    moonAnimation: THREE.AnimationAction;
    moonParticlesAnimation: THREE.AnimationAction;
    gasParticlesAnimation: THREE.AnimationAction;
    fullAnimationsList: THREE.AnimationAction[];
    annotationComponent: IAnnotationAframe;
    currentDeactivatedButton: AFrame.Entity;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    bool: boolean;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const TheMoonAtmosphereComponent = {
    name: 'lesson-start',
    val: {
        init(this: IAtmosphereControl) {
            const model = document.getElementById('model') as AFrame.Entity;
            model.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
                this.el.sceneEl?.addEventListener('lesson-start', () => {
                    // remove tap place
                    const ring = document.getElementById('ring')
                    if (ring) {
                        ring.removeAttribute('tap-place-air')
                        this.el.sceneEl?.removeChild(ring)
                        initialiseAnimations()

                    }
                });
                this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                    this.el.sceneEl?.emit('recenter');
                    // check if the ring exists
                    // if it does ignore the event
                    const ring = document.getElementById('ring')
                    if (ring) {
                        return;
                    } else {
                        const ring = document.createElement('a-ring');
                        ring.setAttribute('id', 'ring');
                        ring.setAttribute('tap-place-air', 'id: model; scale: 5 5 5; offset: 0 -4 -3');
                        ring.setAttribute('material', 'shader: flat; color: #ffffff');
                        ring.setAttribute('rotation', '-90 0 0');
                        ring.setAttribute('radius-inner', '0.5');
                        ring.setAttribute('radius-outer', '0.8');
                        // Attach the created ring element to the scene or another parent entity.
                        this.el.sceneEl?.appendChild(ring);
                        // fix the annotations if there is an active button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                            // remove the line
                            this.annotationComponent.deactivate();
                        }
                    }
                });
                this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                    this.bool = false
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const animToggle = customEvent.detail.toggle;
                    if (animToggle) {
                        this.fullAnimationsList.forEach((currentAnimation: THREE.AnimationAction) => {
                            currentAnimation.paused = false
                        })
                    } else {
                        this.fullAnimationsList.forEach((currentAnimation: THREE.AnimationAction) => {
                            currentAnimation.paused = true
                        })
                    }
                });
            });
            const initialiseAnimations = () => {
                const animatedEl = model.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [EarthRotate, JupiterRotate, Moon, MoonParticles, GasParticles] = animatedEl.animations
                this.earthRotateAnimation = this.mixer.clipAction(EarthRotate)
                this.jupiterRotateAnimation = this.mixer.clipAction(JupiterRotate)
                this.moonParticlesAnimation = this.mixer.clipAction(MoonParticles)
                this.moonAnimation = this.mixer.clipAction(Moon)
                this.gasParticlesAnimation = this.mixer.clipAction(GasParticles)
                this.earthRotateAnimation.play()
                this.jupiterRotateAnimation.play()
                this.moonAnimation.play()
                this.moonParticlesAnimation.play()
                this.gasParticlesAnimation.play()
                this.fullAnimationsList = [
                    this.earthRotateAnimation,
                    this.jupiterRotateAnimation,
                    this.moonAnimation,
                    this.moonParticlesAnimation,
                    this.gasParticlesAnimation
                ]
            }

            this.gasInMotionHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Gas in Motion';
                    const body = 'Gases are made of particles that are in constant random motion. High temperature gases have more kinetic energy and move around faster. The total kinetic and potential energy of a gas is known as its <b>internal energy</b>.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.theMoonsAtmosphereHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'The Moon’s Atmosphere';
                    const body = 'The force of gravity acting on anything depends on its mass; a heavy gas like xenon will feel a larger gravitational force than a small oxygen molecule. This means that, even though the Moon’s gravity is very weak, it can hold a small atmosphere made of heavy xenon.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.jupitersAtmosphereHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Jupiter’s Atmosphere';
                    const body = 'If a gas is made of very small particles like hydrogen, the gravitational force may not be strong enough to keep the gas in the planet’s atmosphere. This means only the most massive planets like Jupiter can hold hydrogen atmospheres. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.tooHotToHoldHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Too Hot to Hold';
                    const body = 'If a gas has a high temperature, it has a high internal energy and its particles will move around very quickly. A fast moving particle may have enough energy to escape an object’s gravitational influence. It’s therefore easier to keep an atmosphere if the planet is cool.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.earthsAtmosphereHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Earth’s Atmosphere';
                    const body = 'Earth’s gravity allows it to retain an atmosphere that contains the oxygen we breathe and the CO<sub>2</sub> plants use to photosynthesize. Earth’s gravity isn’t strong enough to hold the lightest gases. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const gasInMotionTriggerBtn = poolButtons.requestEntity()
                gasInMotionTriggerBtn?.setAttribute('position', '0.27 0.05 0.3')
                gasInMotionTriggerBtn?.setAttribute('scale', '4 4 4')
                gasInMotionTriggerBtn?.play()
                gasInMotionTriggerBtn?.addEventListener('click', () => {
                    this.gasInMotionHandler()
                    if (gasInMotionTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(gasInMotionTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (gasInMotionTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = gasInMotionTriggerBtn
                    }
                });

                const theMoonsAtmosphereTriggerButton = poolButtons.requestEntity();
                theMoonsAtmosphereTriggerButton?.setAttribute('position', '0.12 0.3 0.2');
                theMoonsAtmosphereTriggerButton?.setAttribute('scale', '4 4 4')
                theMoonsAtmosphereTriggerButton?.play()
                theMoonsAtmosphereTriggerButton?.addEventListener('click', () => {
                    this.theMoonsAtmosphereHandler()
                    if (theMoonsAtmosphereTriggerButton) {
                        this.annotationComponent.setObjectToFollow(theMoonsAtmosphereTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theMoonsAtmosphereTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theMoonsAtmosphereTriggerButton
                    }
                });

                const jupitersAtmosphereTriggerBtn = poolButtons.requestEntity()
                jupitersAtmosphereTriggerBtn?.setAttribute('position', '0.85 1.12 0.35')
                jupitersAtmosphereTriggerBtn?.setAttribute('scale', '4 4 4')
                jupitersAtmosphereTriggerBtn?.play()
                jupitersAtmosphereTriggerBtn?.addEventListener('click', () => {
                    this.jupitersAtmosphereHandler()
                    if (jupitersAtmosphereTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(jupitersAtmosphereTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (jupitersAtmosphereTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = jupitersAtmosphereTriggerBtn
                    }
                });

                const tooHotToHoldTriggerButton = poolButtons.requestEntity();
                tooHotToHoldTriggerButton?.setAttribute('position', '-0.5 0.6 0.15');
                tooHotToHoldTriggerButton?.setAttribute('scale', '4 4 4')
                tooHotToHoldTriggerButton?.play()
                tooHotToHoldTriggerButton?.addEventListener('click', () => {
                    this.tooHotToHoldHandler()
                    if (tooHotToHoldTriggerButton) {
                        this.annotationComponent.setObjectToFollow(tooHotToHoldTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (tooHotToHoldTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = tooHotToHoldTriggerButton
                    }
                });

                const earthsAtmosphereTriggerButton = poolButtons.requestEntity();
                earthsAtmosphereTriggerButton?.setAttribute('position', '-0.85 1.22 0.15');
                earthsAtmosphereTriggerButton?.setAttribute('scale', '4 4 4')
                earthsAtmosphereTriggerButton?.play()
                earthsAtmosphereTriggerButton?.addEventListener('click', () => {
                    this.earthsAtmosphereHandler()
                    if (earthsAtmosphereTriggerButton) {
                        this.annotationComponent.setObjectToFollow(earthsAtmosphereTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (earthsAtmosphereTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = earthsAtmosphereTriggerButton
                    }
                });
            };
        },
        tick(this: IAtmosphereControl, time: number, deltaTime: number) {
            const camera = document.querySelector("#camera");
            const cameraPosition = camera.getAttribute("position");
            this.el.object3D.traverse(child => {
                if (child.name.includes('Glow')) {
                    child.lookAt(cameraPosition)
                }
                if (child.name.includes('Cloud')) {
                    child.lookAt(cameraPosition)
                }
                if (child.name.includes('Orange')) {
                    child.lookAt(cameraPosition)
                }
                if (child.name.includes('MoonParticles')) {
                    child.lookAt(cameraPosition)
                }
            })
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {TheMoonAtmosphereComponent}
