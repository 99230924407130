import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}


interface ICellSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	currentClip: THREE.AnimationAction;
	nucleusButtonHandler: () => void;
	mitochondriaButtonHandler: () => void;
	acrosomeButtonHandler: () => void;
	headButtonHandler: () => void;
	onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
	buttonsInitialised: boolean;
	tailButtonHandler: () => void;
	actionSwim: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;
	el: AFrame.Entity;

}

const CellScene = {
	name: 'cell-scene',
	val: {
		init(this: ICellSceneAframe) {
			
			const cell = document.getElementById('spermCell') as AFrame.Entity;
			cell.addEventListener('model-loaded', () => {

				initialiseAnimations();

				//setup annotation callback
				const scene = this.el.sceneEl as AFrame.Scene & {
					systems: { "annotation-system": IAnnotationSystemAframe };
				};
				const annotationSystem = scene.systems["annotation-system"];
				this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

				this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

				//get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}

				// when model is loaded adding recenter functionality
				this.el.sceneEl?.addEventListener('lesson-recenter', () => {
					 // position this el in fron of camera with the following offset "3 4.5 -5"
					 // Get the camera and the desired offset.
    				// Get the A-Frame camera component.
    				const cameraEl = this.el.sceneEl?.querySelector('a-camera');

    				// Set the position of the element to the camera's position.
    				
					if (cameraEl) {
						console.log('Camera found: ', cameraEl.getAttribute('rotation'));

						console.log('Rotation before: ', this.el.getAttribute('rotation'));
					

    					// Get the camera's Y rotation.
    					const cameraRotation = cameraEl.getAttribute('rotation') as unknown as THREE.Euler;
						const cameraRotationY = cameraRotation ? cameraRotation.y : 0;

    					// Get the current rotation of the element.
    					const elementRotation = this.el.getAttribute('rotation');

    					// Set the element's Y rotation to match the camera's Y rotation.
    					this.el.setAttribute('rotation', {
    					    x: elementRotation.x,
    					    y: cameraRotationY,
    					    z: elementRotation.z
    					});


						console.log('Rotation after: ', this.el.getAttribute('rotation'))

						const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
						// Create an offset vector.
    					const offset = new THREE.Vector3(0, -8, -8);
						offset.applyQuaternion(this.el.object3D.quaternion);

    					// Add the offset to the camera's position.
    					const newPosition = camPos.clone().add(offset);

    					// Set the position of the element with the offset.
    					this.el.setAttribute('position', newPosition);
					}
    				
					// fix the annotations if there is an active button
					if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                
				});
			});

			

			const initialiseAnimations = () => {
				console.log('Initialising Animations')
				const spermCell = document.getElementById('spermCell') as AFrame.Entity;
				// spermCell.object3D.traverse((child) => {
				//   console.log(child);
				// });
				const animatedEl = spermCell.object3D.getObjectByName('SpermCell_V2') as any;
				// console.log('Animated element: ', animatedEl)
				this.mixer = new THREE.AnimationMixer(animatedEl)
				const [clipSwim] = animatedEl.animations
				this.actionSwim = this.mixer.clipAction(clipSwim)
			};

			this.tailButtonHandler = () => {
				console.log('Tail button clicked');
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionSwim;
				this.actionSwim.play();

				if (this.onObjectSelected) {
					const title = 'Tail';
					const body = 'The tail is an adaptation that allows the sperm cell to swim quickly towards the egg.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.headButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Head';
					const body = 'The streamlined head reduces friction to provide more speed.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.acrosomeButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Acrosome';
					const body = 'The acrosome contains enzymes that digest the egg cell membrane.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.mitochondriaButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Mitochondria';
					const body = 'The midpiece contains many mitochondria that provide the energy needed for the sperm’s function.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.nucleusButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Nucleus';
					const body = 'The male DNA - 23 chromosomes - is located within the sperm’s nucleus.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				// Wait for the pool component to be initialized
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
					
				const acrosomeTriggerButton = poolButtons.requestEntity();
				acrosomeTriggerButton?.setAttribute('position', '5.45 4.8 -4.5');
				acrosomeTriggerButton?.setAttribute('scale', '2 2 2');
				acrosomeTriggerButton?.play();
				acrosomeTriggerButton?.addEventListener('click', () => {
					this.acrosomeButtonHandler()
					if (acrosomeTriggerButton) {
						this.annotationComponent.setObjectToFollow(acrosomeTriggerButton);
						if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(acrosomeTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = acrosomeTriggerButton

					}
				});
				const nucleusTriggerBtn = poolButtons.requestEntity()
				nucleusTriggerBtn?.setAttribute('position', '4.25 4.65 -4.5')
				nucleusTriggerBtn?.setAttribute('scale', '2 2 2')
				nucleusTriggerBtn?.play()
				nucleusTriggerBtn?.addEventListener('click', () => {
					this.nucleusButtonHandler()
					if (nucleusTriggerBtn) {
						this.annotationComponent.setObjectToFollow(nucleusTriggerBtn);
						if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(nucleusTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = nucleusTriggerBtn
					}
				});
				const mitochondriaTriggerBtn = poolButtons.requestEntity()
				mitochondriaTriggerBtn?.setAttribute('position', '1.75 4.25 -4.5')
				mitochondriaTriggerBtn?.setAttribute('scale', '2 2 2')
				mitochondriaTriggerBtn?.play()
				mitochondriaTriggerBtn?.addEventListener('click', () => {
					this.mitochondriaButtonHandler()
					if (mitochondriaTriggerBtn) {
						this.annotationComponent.setObjectToFollow(mitochondriaTriggerBtn);
						if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(mitochondriaTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = mitochondriaTriggerBtn
					}
				});
				const tailTriggerBtn = poolButtons.requestEntity()
				tailTriggerBtn?.setAttribute('position', '-3 4.5 -4.5')
				tailTriggerBtn?.setAttribute('scale', '2 2 2')
				tailTriggerBtn?.play()
				tailTriggerBtn?.addEventListener('click', () => {
					this.tailButtonHandler()
					if (tailTriggerBtn) {
						this.annotationComponent.setObjectToFollow(tailTriggerBtn);
						if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(tailTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = tailTriggerBtn
					}
				});
				const headTriggerBtn = poolButtons.requestEntity()
				headTriggerBtn?.setAttribute('position', '4 5.6 -4.5')
				headTriggerBtn?.setAttribute('scale', '2 2 2')
				headTriggerBtn?.play()
				headTriggerBtn?.addEventListener('click', () => {
					this.headButtonHandler()
					if (headTriggerBtn) {
						this.annotationComponent.setObjectToFollow(headTriggerBtn);
						if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(headTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = headTriggerBtn
					}
				});

			};

		},
		tick(this: ICellSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { CellScene as CellSceneComponent }