import { Stack, styled } from '@mui/material'

export const TriangleToTop = styled(Stack, { name: 'TriangleToTop' })({
  width: 0,
  height: 0,
  margin: '16px',
  borderTop: '0 solid transparent',
  borderRight: '16px solid transparent',
  borderBottom: '16px solid #FFFD01',
  borderLeft: '16px solid transparent',
  position: 'absolute',
  left: '0',
  top: '-32px'
})