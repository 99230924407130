import * as AFRAME from 'aframe';
import * as THREE from 'three';
import { WorldButtonAframeInstance } from './world-button';

export enum AnnotationState {
  None,
  Full,
  Shrunk,
}

export interface IAnnotationAframe extends AFRAME.Component {
  elToFollow: AFRAME.Entity<AFRAME.ObjectMap<AFRAME.Component<any, AFRAME.System<any>>>>;
  setLineVisible(arg0: boolean): unknown;
  isAnnotationActive: boolean;
  tempVector2: THREE.Vector3;
  tempVector3: THREE.Vector3;
  tempVector4: THREE.Vector3;
  objToFollow: THREE.Object3D<THREE.Object3DEventMap>;
  tempVector: THREE.Vector3;
  drawLine(): () => void;
  drawLine2(): () => void;
  setObjectToFollow(element: AFRAME.Entity): () => void;
  deactivate(): () => void;
  line: THREE.Line;
  line2: THREE.Line;
  cameraSphere: AFRAME.Entity;
  cameraSphere2: AFRAME.Entity;
  el: AFRAME.Entity;
  withImage?: boolean;
}

const AnnotationComponent = {
  name: 'annotation',
  val: {
    schema: {
      target: { type: 'selector' },
    },
    init(this: IAnnotationAframe) {
      // console.log('Initialising annotations');
      const cameraElement = document.querySelector('#camera') as AFRAME.Entity;
      this.isAnnotationActive = false;

      // camera sphere is an object attached to the camera to render the line between screen and world space object
      this.cameraSphere = document.createElement('a-sphere')
      this.cameraSphere.setAttribute('radius', 0.02);
      this.cameraSphere.setAttribute('material', { shader: 'flat', color: '#00FF00', alphaTest: 0.5, transparent: true, opacity: 1 });
      this.cameraSphere.setAttribute('segments-height', 12);
      this.cameraSphere.setAttribute('segments-width', 12);
      this.cameraSphere.setAttribute('position', '0 -0.445 -2');
      this.cameraSphere.setAttribute('visible', 'false');

      // this.cameraSphere2 = document.createElement('a-sphere')
      // this.cameraSphere2.setAttribute('radius', 0.02);
      // this.cameraSphere2.setAttribute('material', { shader: 'flat', color: '#00FF00', alphaTest: 0.5, transparent: true, opacity: 1 });
      // this.cameraSphere2.setAttribute('segments-height', 12);
      // this.cameraSphere2.setAttribute('segments-width', 12);
      // this.cameraSphere2.setAttribute('position', '-0.04 0.44 -2');
      // this.cameraSphere2.setAttribute('visible', 'false');

      if (cameraElement) {
        cameraElement.appendChild(this.cameraSphere)
        // cameraElement.appendChild(this.cameraSphere2)
      } else {
        console.error('No camera element found')
      }

      // Create a THREE.js line
      const geometry = new THREE.BufferGeometry();
      const vertices = new Float32Array(6); // 3 vertices per point * 2 points
      geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
      // const geometry2 = new THREE.BufferGeometry();
      // const vertices2 = new Float32Array(6); // 3 vertices per point * 2 points
      // geometry2.setAttribute('position', new THREE.BufferAttribute(vertices2, 3));
      const material = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 1, visible: false });
      this.line = new THREE.Line(geometry, material);
      this.line.frustumCulled = false; // disable frustum culling on the line to avoid it disappearing under some angles
      // this.line2 = new THREE.Line(geometry2, material);
      // this.line2.frustumCulled = false; // disable frustum culling on the line to avoid it disappearing under some angles
      this.el.sceneEl?.object3D.add(this.line);
      // this.el.sceneEl?.object3D.add(this.line2);


      // Create a reusable tempVector
      this.tempVector = new THREE.Vector3();
      this.tempVector2 = new THREE.Vector3();
      // this.tempVector3 = new THREE.Vector3();
      // this.tempVector4 = new THREE.Vector3();
      this.elToFollow = this.el;
      this.objToFollow = this.el.object3D;

      this.el.sceneEl?.addEventListener('annotation-state-change', (e) => {
        const customEvent = e as CustomEvent;
        // console.log("Annotation state changed: ", customEvent.detail.state);
        switch (customEvent.detail.annotationState) {
          case 0: // Null state
            this.setLineVisible(false);
            // trying to reactivate button that was deactivated when annotation was turned on
            if (this.elToFollow) {
              const worldBtn = this.elToFollow.components['world-button'] as unknown as WorldButtonAframeInstance;
              if (worldBtn) {
                worldBtn.activate();
              }
            }
            break;
          case 1: // Full state
            this.setLineVisible(true);
            this.cameraSphere.setAttribute('position', '0 -0.445 -2')
            break;
          default:
            console.error("Invalid annotation state:", customEvent.detail.annotationState);
        }
      });


    },
    drawLine(this: IAnnotationAframe) {
      const startPosition = this.objToFollow.getWorldPosition(this.tempVector);
      const endPosition = this.cameraSphere.object3D.getWorldPosition(this.tempVector2);
      // const startPosition = new THREE.Vector3(0, 0, 0);

      const positionAttribute = this.line.geometry.attributes.position as THREE.BufferAttribute;
      const positions = positionAttribute.array as Float32Array;
      positions[0] = startPosition.x;
      positions[1] = startPosition.y;
      positions[2] = startPosition.z;
      positions[3] = endPosition.x;
      positions[4] = endPosition.y;
      positions[5] = endPosition.z;

      this.line.geometry.attributes.position.needsUpdate = true;
    },
    // drawLine2(this: IAnnotationAframe) {
    //   const startPosition = this.objToFollow.getWorldPosition(this.tempVector3);
    //   const endPosition = this.cameraSphere2.object3D.getWorldPosition(this.tempVector4);
    //   // const startPosition = new THREE.Vector3(0, 0, 0);

    //   const positionAttribute = this.line2.geometry.attributes.position as THREE.BufferAttribute;
    //   const positions2 = positionAttribute.array as Float32Array;
    //   positions2[0] = startPosition.x;
    //   positions2[1] = startPosition.y;
    //   positions2[2] = startPosition.z;
    //   positions2[3] = endPosition.x;
    //   positions2[4] = endPosition.y;
    //   positions2[5] = endPosition.z;

    //   this.line2.geometry.attributes.position.needsUpdate = true;
    // },
    setObjectToFollow(this: IAnnotationAframe, element: AFRAME.Entity) {
      this.objToFollow = element.object3D;
      this.elToFollow = element;

      if (!this.isAnnotationActive) {
        this.setLineVisible(true);
        this.isAnnotationActive = true;
      }

    },
    deactivate(this: IAnnotationAframe) {
      this.setLineVisible(false);
      this.isAnnotationActive = false;
    },
    setLineVisible(this: IAnnotationAframe, visible: boolean) {
      // set material visible
      if (Array.isArray(this.line.material)) {
        this.line.material.forEach((material) => {
          material.visible = visible;
        });
      } else {
        this.line.material.visible = visible;
      }
      // if (Array.isArray(this.line2.material)) {
      //   this.line2.material.forEach((material) => {
      //     material.visible = visible;
      //   });
      // } else {
      //   this.line2.material.visible = visible;
      // }
    },
    tick(this: IAnnotationAframe) {
      this.drawLine();
      // this.drawLine2();
    },
    
  },
};
export { AnnotationComponent as Annotation }