import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/homeButton.css';

import homeIcon from '../../assets/img/home-1.png';


const HomeButton: React.FC = () => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate('/simulations');
    }; 

    return (
      <div className="homeButtonContainer" onClick={onClick}>
        <img src={homeIcon} alt="Home button" />
      </div>
    );
};

export default HomeButton;