// src/components/Flashcard.tsx
import React from 'react';
import '../styles/flashcard.css'
import '../styles/transitions.css'
import arrowImg from '../../assets/img/Close.png'

interface FlashcardProps {
    title: string;
    content: string;
    imgSrc?: string;
    contentExtra?: string;
    imgExtraSrc?: string;
    contentExtraSecond?: string;
    active: boolean;
    onBackClick: () => void;
}

const Flashcard: React.FC<FlashcardProps> = ({
                                                 title,
                                                 content,
                                                 imgSrc,
                                                 contentExtra,
                                                 imgExtraSrc,
                                                 contentExtraSecond,
                                                 active,
                                                 onBackClick
                                             }) => {
    const flashcardClass = active ? 'cardContainer' : 'cardContainer slide-right';

    return (
        <div className={flashcardClass}>
            <img className="cardBack" src={arrowImg} onClick={onBackClick}/>
            <div className="cardHead">
                <h1 dangerouslySetInnerHTML={{__html: title}}/>
            </div>
            <div className="cardBody">
                <p dangerouslySetInnerHTML={{__html: content}}/>
                {imgSrc && (
                    <div className="cardImage">
                        <img src={imgSrc} alt="Card"/>
                    </div>
                )}
                {contentExtra && (
                    <div className="cardBodyExtra" dangerouslySetInnerHTML={{__html: contentExtra}}/>
                )}
                {imgExtraSrc && (
                    <div className="cardImage">
                        <img src={imgExtraSrc} alt="Card"/>
                    </div>
                )}
                {contentExtraSecond && (
                    <div className="cardBodyExtra" dangerouslySetInnerHTML={{__html: contentExtraSecond}}/>
                )}
            </div>
        </div>
    );
};

export default Flashcard;
