import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useHandlers } from './hooks';
import { useMemo } from 'react';
import { DisplayEnum } from '../../../../shared/enums';
import { IconImage } from '../../../../shared/components/IconImage';
import { typeColorUtil } from '../../utils/typeColorUtil';
import { statusColorUtil } from '../../utils/statusColorUtil';
import { levelColorUtil } from '../../utils/levelColorUtils';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  url: string;
  type: string;
  qr: string;
  level: string;
  status: string;
};

export function CourseCard(props: Props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { id, title, description, imageUrl, url, type, qr, level, status } =
    props;

  const splitLevelItems = useMemo(() => {
    return level.split(',').map((i) => i.trim());
  }, [level]);
  const typeColor = useMemo(() => {
    return typeColorUtil(type);
  }, [type]);
  const statusColor = useMemo(() => {
    return statusColorUtil(status);
  }, [status]);
  const levelColor = (item: string) => {
    return levelColorUtil(item);
  };
  const { handleTrainingCardClick } = useHandlers(id);
  const { t, i18n } = useTranslation();
  const i18nPath = 'COURSES_WRAPPER_COMPONENT.COURSE_CARD_COMPONENT.';

  return (
    <Stack
      mt={2}
      pb={2}
      borderBottom='1px solid #D2D2D2'
      direction={tablet ? 'column' : 'row'}
      alignItems='center'
      justifyContent='flex-start'
      sx={{ cursor: 'pointer' }}
      onClick={handleTrainingCardClick}
    >
      <Stack>
        <Box borderRadius='10px' overflow='hidden' boxSizing='border-box'>
          <IconImage
            src={imageUrl}
            alt={title}
            width='220px'
            borderRadius='10px'
          />
        </Box>
      </Stack>
      <Stack
        ml={tablet ? 0 : 2}
        width={tablet ? '100%' : 'calc(100% - 220px)'}
        overflow='hidden'
      >
        <Typography
          mb={1}
          fontFamily='GilroyBold'
          color='#262626'
          fontSize={22}
          noWrap
        >
          {title}
        </Typography>
        <Stack direction='row' mb={1}>
          <Stack
            mr={1}
            padding='5px 7px'
            borderRadius='4px'
            border={`1px solid rgba(${typeColor}, 0.5)`}
            width='fit-content'
          >
            <Typography
              fontFamily='GilroyMedium'
              color={`rgba(${typeColor}, 1)`}
              fontSize={14}
            >
              {t(i18nPath + type)}
            </Typography>
          </Stack>
          {/* <Stack
            mr={1}
            padding='5px 7px'
            borderRadius='4px'
            border={`1px solid rgba(${statusColor}, 0.5)`}
            width='fit-content'
          >
            <Typography
              fontFamily='GilroyMedium'
              color={`rgba(${statusColor}, 1)`}
              fontSize={14}
            >
              {status}
            </Typography>
          </Stack> */}
          {splitLevelItems.map((item: string, index: number) => {
            return (
              <Stack
                mr={splitLevelItems.length - 1 === index ? 0 : 1}
                padding='5px 7px'
                borderRadius='4px'
                border={`1px solid rgba(${levelColor(`${item}`)}, 0.5)`}
                width='fit-content'
                key={item}
              >
                <Typography
                  fontFamily='GilroyMedium'
                  color={`rgba(${levelColor(`${item}`)}, 1)`}
                  fontSize={14}
                >
                  {t(i18nPath + `${item}`)}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
        <Typography
          mb={1}
          fontFamily='GilroyMedium'
          color={theme.palette.text.secondary}
          fontSize={16}
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
}
