import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface IAtomScene {
    poolEntity: AFrame.Entity;
    hydrogenFluoride: AFrame.Entity;
    sodiumChloride: AFrame.Entity;
    hydrogenFluorideHandler: () => void;
    sodiumChlorideHandler: () => void;
    hydrogenFluorideMixer: THREE.AnimationMixer;
    sodiumChlorideMixer: THREE.AnimationMixer;
    currentAnimation: THREE.AnimationAction;
    currentRotateAnimation: THREE.AnimationAction;
    hydrogenFluorideAnimation: THREE.AnimationAction;
    hydrogenFluorideRotateAnimation: THREE.AnimationAction;
    sodiumChlorideAnimation: THREE.AnimationAction;
    sodiumChlorideRotateAnimation: THREE.AnimationAction;
    annotationComponent: IAnnotationAframe;
    currentDeactivatedButton: AFrame.Entity;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    currentAssetId: number;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const AtomSceneComponent = {
    name: "atom-scene",
    val: {
        init(this: IAtomScene) {
            const holder = document.getElementById('holder') as AFrame.Entity;
            // Add 'model-loaded' event listener to the component
            holder.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                // ony initialise buttons once pool has loaded
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // moved button initialisation to lesson-start event
                // if initialising here, the buttons are not parenting to objects correctly
                // only applies to this lesson
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.hydrogenFluoride = document.getElementById('hydrogenFluoride') as AFrame.Entity
                this.sodiumChloride = document.getElementById('sodiumChloride') as AFrame.Entity
                this.annotationComponent.line.visible = false
                initialiseAnimations()
                if (!this.currentAssetId) {
                    this.currentAssetId = 0
                }
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

            });

            // add event listeners
            this.el.sceneEl?.addEventListener('anim-toggle', (e) => {
                const event = e as CustomEvent<{ toggle: boolean }>;
                if (event.detail.toggle) {
                    if (this.currentAnimation) this.currentAnimation.paused = false;
                    if (this.currentRotateAnimation) this.currentRotateAnimation.paused = false;
                } else {
                    if (this.currentAnimation) this.currentAnimation.paused = true;
                    if (this.currentRotateAnimation) this.currentRotateAnimation.paused = true;
                }
            });

            this.el.sceneEl?.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                this.currentAssetId = ce.detail.assetId;
                this.currentAnimation.stop()
                switch (this.currentAssetId) {
                    case 0:
                        this.hydrogenFluoride.object3D.visible = true
                        this.sodiumChloride.object3D.visible = false
                        this.currentAnimation = this.hydrogenFluorideAnimation
                        this.currentRotateAnimation = this.hydrogenFluorideRotateAnimation
                        break;
                    case 1:
                        this.hydrogenFluoride.object3D.visible = false
                        this.sodiumChloride.object3D.visible = true
                        this.currentAnimation = this.sodiumChlorideAnimation
                        this.currentRotateAnimation = this.sodiumChlorideRotateAnimation
                        break;
                }
                this.currentRotateAnimation.stop()
                this.currentRotateAnimation.play()
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: holder; scale: 5 5 5; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });


            const initialiseAnimations = () => {
                const animLithiumChlorideEl = this.hydrogenFluoride.object3D.getObjectByName('Scene') as any;
                this.hydrogenFluorideMixer = new THREE.AnimationMixer(animLithiumChlorideEl)
                const [LithiumChloride, LithiumChlorideRotate] = animLithiumChlorideEl.animations
                this.hydrogenFluorideRotateAnimation = this.hydrogenFluorideMixer.clipAction(LithiumChlorideRotate)
                this.hydrogenFluorideAnimation = this.hydrogenFluorideMixer.clipAction(LithiumChloride)
                this.hydrogenFluorideRotateAnimation.play()

                const animSodiumOxideEl = this.sodiumChloride.object3D.getObjectByName('Scene') as any;
                this.sodiumChlorideMixer = new THREE.AnimationMixer(animSodiumOxideEl)
                const [SodiumOxideRotate, SodiumOxide] = animSodiumOxideEl.animations
                this.sodiumChlorideAnimation = this.sodiumChlorideMixer.clipAction(SodiumOxide)
                this.sodiumChlorideRotateAnimation = this.sodiumChlorideMixer.clipAction(SodiumOxideRotate)
                this.sodiumChlorideRotateAnimation.play()

                this.currentAnimation = this.hydrogenFluorideAnimation
                this.currentRotateAnimation = this.hydrogenFluorideRotateAnimation
            }

            this.hydrogenFluorideHandler = () => {
                if (this.currentAnimation && !this.currentAnimation.isRunning()) {
                    this.currentAnimation.stop()
                    this.currentAnimation.play()
                }
                if (this.currentRotateAnimation && !this.currentRotateAnimation.isRunning()) {
                    this.currentRotateAnimation.stop()
                    this.currentRotateAnimation.play()
                }
                if (this.onObjectSelected) {
                    const title = 'Hydrogen Fluoride';
                    const body = `You formed hydrogen fluoride! When halogens react with non-metals they form <b>covalent bonds</b> where both atoms share electrons. The balanced equation for this reaction is: <div style='display: flex; flex-direction: row; font-size: 20px; width: 100%; text-align: center; justify-content: space-around; padding: 10px 20px'> <div><b><div>H<sub>2</sub></div></b></div><div>+</div><div><b><div>F<sub>2</sub></div></b></div><div>→</div><div><b><div>2HF</div></b></div></div>`;
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.sodiumChlorideHandler = () => {
                if (this.currentAnimation && !this.currentAnimation.isRunning()) {
                    this.currentAnimation.stop()
                    this.currentAnimation.play()
                }
                if (this.currentRotateAnimation && !this.currentRotateAnimation.isRunning()) {
                    this.currentRotateAnimation.stop()
                    this.currentRotateAnimation.play()
                }
                if (this.onObjectSelected) {
                    const title = 'Sodium Chloride';
                    const body = 'You formed sodium chloride! When halogens react with metals they form <b>ionic bonds</b> where the metal gives an electron to the halogen. The balanced equation for this reaction is: <div style=\'display: flex; flex-direction: row; font-size: 20px; width: 100%; text-align: center; justify-content: space-around; padding: 10px 20px\'> <div><b><div>2Na</div></b></div><div>+</div><div><b><div>Cl<sub>2</sub></div></b></div><div>→</div><div><b><div>2NaCl</div></b></div></div>';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }


            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const lithiumChlorideTriggerBtn = poolButtons.requestEntity()
                lithiumChlorideTriggerBtn?.setAttribute('position', '-0.045 0.035 0.1')
                lithiumChlorideTriggerBtn?.setAttribute('scale', '2 2 2')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        lithiumChlorideTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (this.currentAssetId === 1) {
                        lithiumChlorideTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                lithiumChlorideTriggerBtn?.play()
                lithiumChlorideTriggerBtn?.addEventListener('click', () => {
                    this.hydrogenFluorideHandler()
                    if (lithiumChlorideTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(lithiumChlorideTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (lithiumChlorideTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = lithiumChlorideTriggerBtn
                    }
                });
                const sodiumOxideTriggerButton = poolButtons.requestEntity();
                sodiumOxideTriggerButton?.setAttribute('position', '-0.045 0.035 0.1');
                sodiumOxideTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        sodiumOxideTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        sodiumOxideTriggerButton?.setAttribute('scale', '2 2 2')
                    }
                });
                sodiumOxideTriggerButton?.play()
                sodiumOxideTriggerButton?.addEventListener('click', () => {
                    this.sodiumChlorideHandler()
                    if (sodiumOxideTriggerButton) {
                        this.annotationComponent.setObjectToFollow(sodiumOxideTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (sodiumOxideTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = sodiumOxideTriggerButton
                    }
                });
            };
        },
        tick(this: IAtomScene, time: number, timeDelta: number) {
            if (this.hydrogenFluorideMixer) {
                this.hydrogenFluorideMixer.update(timeDelta / 1000);
            }
            if (this.sodiumChlorideMixer) {
                this.sodiumChlorideMixer.update(timeDelta / 1000);
            }
        },
    }
}
export {AtomSceneComponent as AtomScene}
