import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {

    mixer: THREE.AnimationMixer;
    turnOnAnimation: THREE.AnimationAction;
    electrolysisAnimation: THREE.AnimationAction;
    liftAnimation: THREE.AnimationAction;
    currentDeactivatedButton: AFrame.Entity;
    poolEntity: AFrame.Entity;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    cathodeHandler: () => void;
    anodeHandler: () => void;
    electricalCurrentHandler: () => void;
    copperSulfateSolutionHandler: () => void;
    el: AFrame.Entity;
    circuit: AFrame.Entity;
    plug: THREE.Mesh;
    switchTimeout: NodeJS.Timeout;
    distanceEvent: CustomEvent;
    switchToggle: boolean;
    electrolysisFinished: boolean;
    beakerTaped: boolean;
    processing: boolean
    bloker: boolean
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            this.circuit = document.getElementById('circuit') as AFrame.Entity;
            console.log(this.circuit.object3D)
            const geometry = new THREE.CircleGeometry(1, 32);
            const material = new THREE.MeshBasicMaterial({color: 0x000000});
            this.plug = new THREE.Mesh(geometry, material);
            this.plug.scale.set(0.006, 0.006, 0.006)
            this.plug.position.set(-0.0235, 0.0314, -0.06)
            this.circuit.object3D.add(this.plug);

            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

                initialiseCircuitAnimations()
                createClickableAreas()
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.switchToggle = false
                this.processing = false
                this.bloker = false
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: holder; scale: 20 20 20; offset: 0 0 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    this.el.sceneEl?.appendChild(ring);
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        this.annotationComponent.deactivate();
                    }
                }
            });

            this.cathodeHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Cathode';
                    const body = 'The negatively charged cathode attracts the positively charged copper ions (Cu<sup>2+</sup>) and hydrogen ions (H<sup>+</sup>). Copper is produced at the cathode as it is less reactive than hydrogen (check the flashcard for more information on the relative reactivity of metals with hydrogen. )';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.anodeHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Anode';
                    const body = 'The positively charged anode attracts the negatively charged sulfate ions (SO<sub>4</sub><sup>2-</sup>) and hydroxide ions (OH<sup>-</sup>). In this experiment, oxygen gas (O<sub>2</sub>) is produced at the anode (check the flashcard for more information on how the products at the anode are determined).';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.electricalCurrentHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Electrical Current';
                    const body = 'Electrolysis uses an electric current to break down compounds dissolved in water. The power supply, or direct current (dc) source, supplies the electrical current necessary to break down these compounds into ions.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.copperSulfateSolutionHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Copper Sulfate Solution';
                    const body = 'The beaker contains a copper sulfate solution (CuSO<sub>4</sub> + H<sub>2</sub>O). Once an electrical current is passed through the solution it will break down the compounds into ions. The ions will travel to the electrode with the opposite charge.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const cathodeTriggerBtn = poolButtons.requestEntity()
                cathodeTriggerBtn?.setAttribute('position', '0.05 0.07 0.05')
                cathodeTriggerBtn?.setAttribute('scale', '1 1 1')
                cathodeTriggerBtn?.play()
                cathodeTriggerBtn?.addEventListener('click', () => {
                    this.cathodeHandler()
                    if (cathodeTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(cathodeTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (cathodeTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = cathodeTriggerBtn
                    }
                });

                const anodeTriggerButton = poolButtons.requestEntity();
                anodeTriggerButton?.setAttribute('position', '-0.05 0.07 0.05');
                anodeTriggerButton?.setAttribute('scale', '1 1 1')
                anodeTriggerButton?.play()
                anodeTriggerButton?.addEventListener('click', () => {
                    this.anodeHandler()
                    if (anodeTriggerButton) {
                        this.annotationComponent.setObjectToFollow(anodeTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (anodeTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = anodeTriggerButton

                    }
                });

                const electricalCurrentTriggerButton = poolButtons.requestEntity();
                electricalCurrentTriggerButton?.setAttribute('position', '0.03 0.075 -0.12');
                electricalCurrentTriggerButton?.setAttribute('scale', '1 1 1')
                electricalCurrentTriggerButton?.play()
                electricalCurrentTriggerButton?.addEventListener('click', () => {
                    this.electricalCurrentHandler()
                    if (electricalCurrentTriggerButton) {
                        this.annotationComponent.setObjectToFollow(electricalCurrentTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (electricalCurrentTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = electricalCurrentTriggerButton

                    }
                });

                const copperSulfateSolutionTriggerButton = poolButtons.requestEntity();
                copperSulfateSolutionTriggerButton?.setAttribute('position', '0 0.035 0.1');
                copperSulfateSolutionTriggerButton?.setAttribute('scale', '1 1 1')
                copperSulfateSolutionTriggerButton?.play()
                copperSulfateSolutionTriggerButton?.addEventListener('click', () => {
                    this.copperSulfateSolutionHandler()
                    if (copperSulfateSolutionTriggerButton) {
                        this.annotationComponent.setObjectToFollow(copperSulfateSolutionTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (copperSulfateSolutionTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = copperSulfateSolutionTriggerButton

                    }
                });
            };
            const createClickableAreas = () => {
                const switcher = document.createElement('a-entity') as AFrame.Entity
                switcher.setAttribute('geometry', {
                    primitive: 'box',
                    height: 0.02,
                    width: 0.02,
                    depth: 0.02
                });
                switcher.setAttribute('position', '0.03 0.04 -0.06');
                switcher.setAttribute('visible', 'false');
                switcher.setAttribute('class', 'cantap');
                switcher.addEventListener('click', () => {
                    if (!this.switchToggle) {
                        this.switchToggle = true
                        this.processing = true
                        this.turnOnAnimation.stop()
                        this.turnOnAnimation.repetitions = 1
                        this.turnOnAnimation.timeScale = 1
                        this.turnOnAnimation.clampWhenFinished = true
                        this.turnOnAnimation.play()
                        this.plug.visible = false
                        setTimeout(() => {
                            this.electrolysisAnimation.paused = false
                            this.electrolysisAnimation.clampWhenFinished = true
                            this.electrolysisAnimation.play()
                        }, this.turnOnAnimation.getClip().duration * 1000)
                    } else {
                        this.switchToggle = false
                        this.turnOnAnimation.stop()
                        this.turnOnAnimation.repetitions = 1
                        this.turnOnAnimation.timeScale = -1
                        this.turnOnAnimation.clampWhenFinished = true
                        this.turnOnAnimation.play()
                        this.plug.visible = true
                        this.electrolysisAnimation.paused = true
                        this.processing = false
                    }
                });
                const beakerTap = document.createElement('a-entity') as AFrame.Entity
                beakerTap.setAttribute('geometry', {
                    primitive: 'box',
                    height: 0.1,
                    width: 0.05,
                    depth: 0.05
                });
                beakerTap.setAttribute('position', '0 0.03 0.05');
                beakerTap.setAttribute('visible', 'false');
                beakerTap.setAttribute('class', 'cantap');
                beakerTap.addEventListener('click', () => {
                    if (!this.bloker) {
                        this.bloker = true
                        if (!this.switchToggle) {
                            if (!this.beakerTaped) {
                                this.liftAnimation.stop()
                                this.liftAnimation.clampWhenFinished = true
                                this.liftAnimation.repetitions = 1
                                this.liftAnimation.timeScale = 1
                                this.liftAnimation.play()
                                this.beakerTaped = true
                                setTimeout(() => {
                                    this.bloker = false
                                }, this.liftAnimation.getClip().duration * 1000)
                            } else {
                                this.liftAnimation.stop()
                                this.liftAnimation.clampWhenFinished = true
                                this.liftAnimation.repetitions = 1
                                this.liftAnimation.timeScale = -1
                                this.liftAnimation.play()
                                this.beakerTaped = false
                                setTimeout(() => {
                                    this.bloker = false
                                }, this.liftAnimation.getClip().duration * 1000)
                            }
                        }
                    }
                })
                this.circuit.appendChild(switcher);
                this.circuit.appendChild(beakerTap);
            }

            const initialiseCircuitAnimations = () => {
                const animatedWireEl = this.circuit.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedWireEl)
                const [TurnOnPower, Electrolysis, LiftElectrodes] = animatedWireEl.animations
                this.turnOnAnimation = this.mixer.clipAction(TurnOnPower)
                this.electrolysisAnimation = this.mixer.clipAction(Electrolysis)
                this.liftAnimation = this.mixer.clipAction(LiftElectrodes)
            }
        },
        tick(this: ICircuitControl, time: number, deltaTime: number) {
            const ring = document.getElementById('ring')
            if (!ring) {
                const circuit = document.getElementById('holder') as AFrame.Entity;
                const cameraEl = this.el.sceneEl?.querySelector('a-camera');
                if (cameraEl && circuit) {
                    const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                    let cameraPosition = camPos.clone()
                    let spherePos = circuit.object3D.position.clone()
                    let distance = cameraPosition.distanceTo(spherePos)
                    if (!this.distanceEvent) {
                        this.distanceEvent = new CustomEvent('distance-change', {detail: {value: false}});
                    }
                    if ((distance < 6 && cameraPosition.x < 2)) {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = true
                        scene.emit('distance-change', this.distanceEvent.detail.value);
                        circuit.object3D.visible = false
                    } else {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = false
                        scene.emit('distance-change', this.distanceEvent.detail.value);
                        if (!circuit.object3D.visible) {
                            circuit.object3D.visible = true
                        }
                    }
                }
            }

            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {CircuitControlComponent}
