import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';


interface ICloningControl {
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    returnToStartButton: any;

    el: AFrame.Entity;
    compost: THREE.Mesh;
    mixer: THREE.AnimationMixer;
    cutOffAnimations: THREE.AnimationAction;
    dipPlantAnimations: THREE.AnimationAction;
    growthAnimations: THREE.AnimationAction;
    tissueSamplesTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    cultureMediumTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    plantletsTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    transplantationTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    baseTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CloningControlComponent = {
    name: 'lesson-start',
    val: {
        init(this: ICloningControl) {

            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                this.returnToStartButton = document.getElementById("return-to-start-button") as any;

                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
                const model = document.getElementById('model') as AFrame.Entity;
                const glass = model.object3D.getObjectByName('Lid') as THREE.Mesh
                const glassMaterial = glass.material as THREE.MeshPhysicalMaterial
                glassMaterial.transparent = true;
                glassMaterial.color = new THREE.Color(0x4f4f4f)
                glassMaterial.side = 2
                glassMaterial.metalness = 0.2;
                glassMaterial.reflectivity = 0.3;
                glassMaterial.thickness = 2;
                glassMaterial.transmission = 0.8;
                glassMaterial.needsUpdate = true;
                this.returnToStartButton.classList.remove("return-to-start-button-visible");
                this.returnToStartButton.classList.add("return-to-start-button-invisible");
                initialiseAnimations()
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: holder; scale: 20 20 20; relativeRotation: 0 0 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.el.sceneEl?.addEventListener('return-to-start', () => {
                this.tissueSamplesTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2');
                this.cultureMediumTriggerBtn?.setAttribute('scale', '0 0 0');
                this.plantletsTriggerBtn?.setAttribute('scale', '0 0 0');
                this.transplantationTriggerBtn?.setAttribute('scale', '0 0 0');
                this.cutOffAnimations.stop()
                this.cutOffAnimations.paused = true
                this.cutOffAnimations.play()
                this.dipPlantAnimations.stop()
                this.growthAnimations.stop()
            })

            const initialiseAnimations = () => {
                const model = document.getElementById('model') as AFrame.Entity;
                const animatedEl = model?.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [DipPlant, Growth, CutOff] = animatedEl.animations
                this.cutOffAnimations = this.mixer.clipAction(CutOff);
                this.cutOffAnimations.setLoop(THREE.LoopOnce, 1)
                this.cutOffAnimations.clampWhenFinished = true
                this.dipPlantAnimations = this.mixer.clipAction(DipPlant);
                this.dipPlantAnimations.setLoop(THREE.LoopOnce, 1)
                this.dipPlantAnimations.clampWhenFinished = true
                this.growthAnimations = this.mixer.clipAction(Growth);
                this.growthAnimations.setLoop(THREE.LoopOnce, 1)
                this.growthAnimations.clampWhenFinished = true
                this.cutOffAnimations.play()
                this.cutOffAnimations.paused = true
            }
            const tissueSamplesButtonHandler = () => {
                this.cutOffAnimations.paused = false
                this.cutOffAnimations.play()
                setTimeout(() => {
                    this.tissueSamplesTriggerBtn?.setAttribute('scale', '0 0 0');
                    this.cultureMediumTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                }, this.cutOffAnimations.getClip().duration * 1000)
                const title = 'Tissue Samples'
                const body = 'Small pieces of tissue, such as leaf, stem, or root fragments, are scraped and collected from the parent organism.'
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const cultureMediumButtonHandler = () => {
                this.cutOffAnimations.stop()
                this.dipPlantAnimations.play()
                setTimeout(() => {
                    this.cultureMediumTriggerBtn?.setAttribute('scale', '0 0 0')
                    this.plantletsTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                }, this.dipPlantAnimations.getClip().duration * 1000)
                const title = 'Culture Medium'
                const body = 'The <b>tissue</b> samples will be grown in-vitro in a sterile <b>culture</b> medium. The medium provides essential nutrients, minerals, and hormones (such as auxins) necessary for cell growth and development.'
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const plantletsButtonHandler = () => {
                this.transplantationTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                const title = 'Plantlets'
                const body = 'The cells in the tissues divide and undergo differentiation and develop into specialised tissues and organs. This process mimics the natural development of an organism.'
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const transplantationButtonHandler = () => {
                this.plantletsTriggerBtn?.setAttribute('scale', '0 0 0')
                this.dipPlantAnimations.stop()
                this.growthAnimations.play()
                setTimeout(() => {
                    this.returnToStartButton.classList.remove("return-to-start-button-invisible");
                    this.returnToStartButton.classList.add("return-to-start-button-visible");
                }, this.growthAnimations.getClip().duration * 1000)
                const title = 'Transplantation'
                const body = 'Once the new plantlets have developed, they can be transferred to a suitable growing medium with soil and compost. '
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                this.tissueSamplesTriggerBtn = poolButtons.requestEntity();
                this.tissueSamplesTriggerBtn?.setAttribute('position', '0 0.03 0.03');
                this.tissueSamplesTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2');
                this.tissueSamplesTriggerBtn?.play();
                this.tissueSamplesTriggerBtn?.addEventListener('click', () => {
                    tissueSamplesButtonHandler();
                    if (this.tissueSamplesTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.tissueSamplesTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.tissueSamplesTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.tissueSamplesTriggerBtn
                    }
                });

                this.cultureMediumTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.cultureMediumTriggerBtn?.setAttribute('position', '0.11 0.03 0.03')
                this.cultureMediumTriggerBtn?.setAttribute('scale', '0 0 0');
                this.cultureMediumTriggerBtn?.play()
                this.cultureMediumTriggerBtn?.addEventListener('click', () => {
                    cultureMediumButtonHandler();
                    if (this.cultureMediumTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.cultureMediumTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.cultureMediumTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.cultureMediumTriggerBtn
                    }
                });

                this.plantletsTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.plantletsTriggerBtn?.setAttribute('position', '0.12 0.03 0');
                this.plantletsTriggerBtn?.setAttribute('scale', '0 0 0');
                this.plantletsTriggerBtn?.play();
                this.plantletsTriggerBtn?.addEventListener('click', () => {
                    plantletsButtonHandler();
                    if (this.plantletsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.plantletsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.plantletsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.plantletsTriggerBtn
                    }
                });

                this.transplantationTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.transplantationTriggerBtn?.setAttribute('position', '-0.1 0.05 -0.03')
                this.transplantationTriggerBtn?.setAttribute('scale', '0 0 0');
                this.transplantationTriggerBtn?.play()
                this.transplantationTriggerBtn?.addEventListener('click', () => {
                    transplantationButtonHandler();
                    if (this.transplantationTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.transplantationTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.transplantationTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.transplantationTriggerBtn
                    }
                });
            }
        },
        tick(this: ICloningControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }

        },
    },
};
export {CloningControlComponent as CloningControl}
