import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    baseMaterial: THREE.MeshStandardMaterial;
    aoMaps: { [key: string]: THREE.Texture };
    lightSphere3: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    lightSphere2: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    lightSphere1: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    lightPara3pos: THREE.Vector3;
    lightPara2pos: THREE.Vector3;
    lightPara1pos: THREE.Vector3;
    lightSeries3pos: THREE.Vector3;
    lightSeries2pos: THREE.Vector3;
    lightSeries1pos: THREE.Vector3;
    pointLight: AFrame.Entity;
    pointLight2: AFrame.Entity;
    pointLight3: AFrame.Entity;
    pointLight4: AFrame.Entity;
    pointLight5: AFrame.Entity;
    pointLight6: AFrame.Entity;
    fillMaterial: THREE.MeshStandardMaterial;
    fillMaterial1: THREE.MeshStandardMaterial;
    fillMaterial2: THREE.MeshStandardMaterial;
    fillMaterial3: THREE.MeshStandardMaterial;
    fillMaterial4: THREE.MeshStandardMaterial;
    fillMaterial5: THREE.MeshStandardMaterial;
    lightMesh1: THREE.Mesh;
    lightMesh2: THREE.Mesh;
    lightMesh3: THREE.Mesh;
    seriesAmmeter: THREE.Object3D;
    seriesWire: THREE.Object3D;
    seriesBatteryCell: THREE.Object3D;
    seriesGlassBulb1: THREE.Object3D;
    seriesGlassBulb2: THREE.Object3D;
    seriesGlassBulb3: THREE.Object3D;
    seriesBulbBase1: THREE.Object3D;
    seriesBulbBase2: THREE.Object3D;
    seriesBulbBase3: THREE.Object3D;
    seriesLampBase1: THREE.Object3D;
    seriesLampBase2: THREE.Object3D;
    seriesLampBase3: THREE.Object3D;
    parallelAmmeter: THREE.Object3D;
    parallelWires: THREE.Object3D;
    parallelWiresBlack: THREE.Object3D;
    parallelWiresRed: THREE.Object3D;
    parallelWiresBlack1: THREE.Object3D;
    parallelWiresRed1: THREE.Object3D;
    parallelBatteryCell: THREE.Object3D;
    parallelGlassBulb1: THREE.Object3D;
    parallelGlassBulb2: THREE.Object3D;
    parallelGlassBulb3: THREE.Object3D;
    parallelBulbBase1: THREE.Object3D;
    parallelBulbBase2: THREE.Object3D;
    parallelBulbBase3: THREE.Object3D;
    parallelLampBase1: THREE.Object3D;
    parallelLampBase2: THREE.Object3D;
    parallelLampBase3: THREE.Object3D;
    coil1: THREE.Mesh;
    coil2: THREE.Mesh;
    coil3: THREE.Mesh;
    coil4: THREE.Mesh;
    coil5: THREE.Mesh;
    coil6: THREE.Mesh;
    parallelCoil2: THREE.Object3D;
    parallelCoil3: THREE.Object3D;
    ammText: AFrame.Entity;
    seriesTriggerBtn: AFrame.Entity;
    parallelTriggerBtn: AFrame.Entity;
    currentDeactivatedButton: AFrame.Entity;
    poolEntity: AFrame.Entity;
    seriesCircuitHandler: () => void;
    parallelCircuitHandler: () => void;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    currentAssetid: number;
    sliderValue: string;
    meshMaterial1: THREE.MeshStandardMaterial;
    meshMaterial2: THREE.MeshStandardMaterial;
    meshMaterial3: THREE.MeshStandardMaterial;
    positionY: number;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                const model = document.getElementById('circuit') as AFrame.Entity;
                model.object3D.visible = true

                const baseMesh = this.el.object3D.getObjectByName('Base') as THREE.Mesh
                this.baseMaterial = baseMesh.material as THREE.MeshStandardMaterial;

                this.seriesAmmeter = this.el.object3D.getObjectByName('Ammeter_AR') as THREE.Object3D;
                this.seriesWire = this.el.object3D.getObjectByName('SeriesWiires') as THREE.Object3D;
                this.seriesBatteryCell = this.el.object3D.getObjectByName('BatteryCell_AR') as THREE.Object3D;
                this.seriesAmmeter = this.el.object3D.getObjectByName('Ammeter_AR') as THREE.Object3D;
                this.seriesGlassBulb1 = this.el.object3D.getObjectByName('GlassBulb') as THREE.Object3D;
                
                
                this.seriesGlassBulb2 = this.el.object3D.getObjectByName('GlassBulb001') as THREE.Object3D;
                
                this.seriesGlassBulb3 = this.el.object3D.getObjectByName('GlassBulb002') as THREE.Object3D;
                
                this.seriesBulbBase1 = this.el.object3D.getObjectByName('BulbBase') as THREE.Object3D;
                this.seriesBulbBase2 = this.el.object3D.getObjectByName('BulbBase001') as THREE.Object3D;
                this.seriesBulbBase3 = this.el.object3D.getObjectByName('BulbBase002') as THREE.Object3D;
                this.seriesLampBase1 = this.el.object3D.getObjectByName('LampBase') as THREE.Object3D;

                this.seriesLampBase2 = this.el.object3D.getObjectByName('LampBase001') as THREE.Object3D;
                this.seriesLampBase3 = this.el.object3D.getObjectByName('LampBase002') as THREE.Object3D;

                this.parallelBatteryCell = this.el.object3D.getObjectByName('BatteryCell_AR001') as THREE.Object3D;
                this.parallelAmmeter = this.el.object3D.getObjectByName('Ammeter_AR001') as THREE.Object3D;
                this.parallelWires = this.el.object3D.getObjectByName('ParallelWires') as THREE.Object3D;
                this.parallelWiresBlack = this.el.object3D.getObjectByName('ParallelWiresBlack') as THREE.Object3D;
                this.parallelWiresRed = this.el.object3D.getObjectByName('ParallelWiresRed') as THREE.Object3D;
                this.parallelWiresBlack1 = this.el.object3D.getObjectByName('ParallelWiresBlack_(1)') as THREE.Object3D;
                this.parallelWiresRed1 = this.el.object3D.getObjectByName('ParallelWiresRed_(1)') as THREE.Object3D;
                this.parallelGlassBulb1 = this.el.object3D.getObjectByName('GlassBulb003') as THREE.Object3D;
                this.parallelGlassBulb2 = this.el.object3D.getObjectByName('GlassBulb004') as THREE.Object3D;
                this.parallelGlassBulb3 = this.el.object3D.getObjectByName('GlassBulb005') as THREE.Object3D;
                this.parallelBulbBase1 = this.el.object3D.getObjectByName('BulbBase003') as THREE.Object3D;
                this.parallelBulbBase2 = this.el.object3D.getObjectByName('BulbBase004') as THREE.Object3D;
                this.parallelBulbBase3 = this.el.object3D.getObjectByName('BulbBase005') as THREE.Object3D;
                this.parallelLampBase1 = this.el.object3D.getObjectByName('LampBase003') as THREE.Object3D;
                this.parallelLampBase2 = this.el.object3D.getObjectByName('LampBase004') as THREE.Object3D;
                this.parallelLampBase3 = this.el.object3D.getObjectByName('LampBase005') as THREE.Object3D;

                //fixing shadow casting
                this.seriesGlassBulb1.castShadow = false;
                this.seriesGlassBulb2.castShadow = false;
                this.seriesGlassBulb3.castShadow = false;
                this.parallelGlassBulb1.castShadow = false;
                this.parallelGlassBulb2.castShadow = false;
                this.parallelGlassBulb3.castShadow = false;

                // setup position vectors for lights
                this.lightPara1pos = new THREE.Vector3(0.045, 0.115, 0.075);
                this.lightPara2pos = new THREE.Vector3(0.13, 0.115, 0.075);
                this.lightPara3pos = new THREE.Vector3(0.22, 0.115, 0.075);
                this.lightSeries1pos = new THREE.Vector3(-0.1, 0.115, 0.15);
                this.lightSeries2pos = new THREE.Vector3(-0.0, 0.115, 0.15);
                this.lightSeries3pos = new THREE.Vector3(0.1, 0.115, 0.15);
                
                
                createShaderMaterials();
                this.currentAssetid = 0
                this.sliderValue = '2'
                disableSeriesCircuit()
                enableSecondParallelCircuit()
                setAmmeterDigits()
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: circuit; scale: 21 21 21; offset: 0 0 -3');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }

            });

            this.el.sceneEl?.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                this.currentAssetid = ce.detail.assetId;
                disableParallelCircuit()
                disableSeriesCircuit()
                switch (this.currentAssetid) {
                    case 0:
                        enableSecondParallelCircuit()
                        updateCircuitDynamicValues()
                        break;
                    case 1:
                        enableSecondSeriesCircuit();
                        updateCircuitDynamicValues()
                        break;
                    default:
                        console.warn('lesson-start.tsx:Unknown asset id: ', this.currentAssetid);
                }
            });

            this.el.sceneEl?.addEventListener('slider-move', (e) => {
                const ce = e as CustomEvent;
                disableParallelCircuit()
                disableSeriesCircuit()
                this.sliderValue = ce.detail.value;
                if (this.currentAssetid === 0) {
                    switch (this.sliderValue) {
                        case `1`:
                            updateCircuitDynamicValues()
                            enableFirstParallelCircuit()
                            break;
                        case `2`:
                            updateCircuitDynamicValues()
                            enableSecondParallelCircuit()
                            break;
                        case `3`:
                            updateCircuitDynamicValues()
                            enableThirdParallelCircuit()
                            break;
                    }
                } else {
                    switch (this.sliderValue) {
                        case `1`:
                            updateCircuitDynamicValues()
                            enableFirstSeriesCircuit()
                            break;
                        case `2`:
                            updateCircuitDynamicValues()
                            enableSecondSeriesCircuit()
                            break;
                        case `3`:
                            updateCircuitDynamicValues()
                            enableThirdSeriesCircuit()
                            break;
                    }
                }
            });
            const createShaderMaterials = () => {
                // remove lightmeshes
                const lightMesh1 = this.el.object3D.getObjectByName('Light001') as THREE.Mesh;
                const lightMesh2 = this.el.object3D.getObjectByName('Light002') as THREE.Mesh;
                const lightMesh3 = this.el.object3D.getObjectByName('Light003') as THREE.Mesh;
                const lightMesh6 = this.el.object3D.getObjectByName('Light004') as THREE.Mesh;
                const lightMesh5 = this.el.object3D.getObjectByName('Light005') as THREE.Mesh;
                const lightMesh4 = this.el.object3D.getObjectByName('Light006') as THREE.Mesh;
                lightMesh1.visible = false;
                lightMesh2.visible = false;
                lightMesh3.visible = false;
                lightMesh4.visible = false;
                lightMesh5.visible = false;
                lightMesh6.visible = false;
                // setup new light meshes
                const lightObj1 = document.getElementById('plane1') as AFrame.Entity;
                this.lightMesh1 = lightObj1.object3D.children[0] as THREE.Mesh;
                const lightObj2 = document.getElementById('plane2') as AFrame.Entity;
                this.lightMesh2 = lightObj2.object3D.children[0] as THREE.Mesh;
                const lightObj3 = document.getElementById('plane3') as AFrame.Entity;
                this.lightMesh3 = lightObj3.object3D.children[0] as THREE.Mesh;

                this.lightSphere1 = document.getElementById('sphere1') as AFrame.Entity;
                this.lightSphere2 = document.getElementById('sphere2') as AFrame.Entity;
                this.lightSphere3 = document.getElementById('sphere3') as AFrame.Entity;   


                this.coil1 = this.el.object3D.getObjectByName('Coil') as THREE.Mesh;
                this.fillMaterial = this.coil1.material as THREE.MeshStandardMaterial;
                this.fillMaterial.emissiveIntensity = 0;
                this.coil2 = this.el.object3D.getObjectByName('Coil001') as THREE.Mesh;
                this.fillMaterial1 = this.coil2.material as THREE.MeshStandardMaterial;
                this.fillMaterial1.emissiveIntensity = 0;
                this.coil3 = this.el.object3D.getObjectByName('Coil002') as THREE.Mesh;
                this.fillMaterial2 = this.coil3.material as THREE.MeshStandardMaterial;
                this.fillMaterial2.emissiveIntensity = 0;
                this.coil4 = this.el.object3D.getObjectByName('Coil003') as THREE.Mesh;
                this.fillMaterial3 = this.coil4.material as THREE.MeshStandardMaterial;
                this.fillMaterial3.emissiveIntensity = 0;
                this.coil5 = this.el.object3D.getObjectByName('Coil004') as THREE.Mesh;
                this.fillMaterial4 = this.coil5.material as THREE.MeshStandardMaterial;
                this.fillMaterial4.emissiveIntensity = 0;
                this.coil6 = this.el.object3D.getObjectByName('Coil005') as THREE.Mesh;
                this.fillMaterial5 = this.coil6.material as THREE.MeshStandardMaterial;
                this.fillMaterial5.emissiveIntensity = 0;

                //fix coil dropping shadow
                this.coil1.castShadow = false;
                this.coil2.castShadow = false;
                this.coil3.castShadow = false;
                this.coil4.castShadow = false;
                this.coil5.castShadow = false;
                this.coil6.castShadow = false;

                const light = document.createElement('a-light');
                light.setAttribute('type', 'point');
                light.setAttribute('color', '#ffffff');
                light.setAttribute('intensity', '1');
                light.setAttribute('distance', '100');
                light.setAttribute('decay', '2');
                light.setAttribute('position', '-0.0795 0.115 0.15');
                this.pointLight = light;
                this.el.appendChild(this.pointLight);

                const light2 = document.createElement('a-light');
                light2.setAttribute('type', 'point');
                light2.setAttribute('color', '#ffffff');
                light2.setAttribute('intensity', '1');
                light2.setAttribute('distance', '100');
                light2.setAttribute('decay', '2');
                light2.setAttribute('position', '0.0795 0.115 0.15');
                this.pointLight2 = light2;
                this.el.appendChild(this.pointLight2);


                const light3 = document.createElement('a-light');
                light3.setAttribute('type', 'point');
                light3.setAttribute('color', '#ffffff');
                light3.setAttribute('intensity', '1');
                light3.setAttribute('distance', '100');
                light3.setAttribute('decay', '2');
                light3.setAttribute('position', '0.0795 0.115 0.15');
                this.pointLight3 = light3;
                this.el.appendChild(this.pointLight3);

            };

            const getAOTexture = (id: string) => {
                if (!this.aoMaps) {
                    this.aoMaps = {};
                }

                if (this.aoMaps[id]) {
                    return this.aoMaps[id];
                } else {
                    const map = document.getElementById(id) as HTMLImageElement;
                    const t = new THREE.Texture();
                    t.image = map;
                    t.flipY = false;
                    t.needsUpdate = true;
                    this.aoMaps[id] = t;
                    return t;
                }
            }

            const setLightBulbIntensity = (intensity: number) => {
                this.meshMaterial1 = this.lightMesh1.material as THREE.MeshStandardMaterial;
                this.meshMaterial2 = this.lightMesh2.material as THREE.MeshStandardMaterial;
                this.meshMaterial3 = this.lightMesh3.material as THREE.MeshStandardMaterial;

                switch (intensity) {
                    case 0:
                        if (this.fillMaterial) {
                            this.fillMaterial.emissiveIntensity = 0.25;
                            this.fillMaterial.needsUpdate = true;
                        }
                        if (this.fillMaterial2) {
                            this.fillMaterial2.emissiveIntensity = 0.25;
                            this.fillMaterial2.needsUpdate = true;
                        }
                        if (this.fillMaterial2) {
                            this.fillMaterial2.emissiveIntensity = 0.25;
                            this.fillMaterial2.needsUpdate = true;
                        }

                        this.lightMesh1.scale.set(1, 1, 1)
                        this.lightMesh2.scale.set(1, 1, 1)
                        this.lightMesh3.scale.set(1, 1, 1)

                        this.meshMaterial1.color.set(0xffa500)
                        this.meshMaterial2.color.set(0xffa500)
                        this.meshMaterial3.color.set(0xffa500)
                        this.meshMaterial1.opacity = 0.6
                        this.meshMaterial2.opacity = 0.6
                        this.meshMaterial3.opacity = 0.6

                        this.pointLight.setAttribute('intensity', '0.4');
                        this.pointLight2.setAttribute('intensity', '0.4');
                        this.pointLight3.setAttribute('intensity', '0.4');

                        break;
                    case 1:
                        if (this.fillMaterial) {
                            this.fillMaterial.emissiveIntensity = 0.5;
                            this.fillMaterial.needsUpdate = true;
                        }
                        if (this.fillMaterial1) {
                            this.fillMaterial1.emissiveIntensity = 0.5;
                            this.fillMaterial1.needsUpdate = true;
                        }
                        if (this.fillMaterial2) {
                            this.fillMaterial2.emissiveIntensity = 0.5;
                            this.fillMaterial2.needsUpdate = true;
                        }
                        if (this.fillMaterial3) {
                            this.fillMaterial3.emissiveIntensity = 0.5;
                            this.fillMaterial3.needsUpdate = true;
                        }
                        if (this.fillMaterial4) {
                            this.fillMaterial4.emissiveIntensity = 0.5;
                            this.fillMaterial4.needsUpdate = true;
                        }
                        if (this.fillMaterial5) {
                            this.fillMaterial5.emissiveIntensity = 0.5;
                            this.fillMaterial5.needsUpdate = true;
                        }
                        this.lightMesh1.scale.set(1.5, 1.5, 1)
                        this.lightMesh2.scale.set(1.5, 1.5, 1)
                        this.lightMesh3.scale.set(1.5, 1.5, 1)

                        this.meshMaterial1.color.set(0xfffe66)
                        this.meshMaterial2.color.set(0xfffe66)
                        this.meshMaterial3.color.set(0xfffe66)
                        this.meshMaterial1.opacity = 0.8
                        this.meshMaterial2.opacity = 0.8
                        this.meshMaterial3.opacity = 0.8
 
                        //setting lightss to 0.75
                        this.pointLight.setAttribute('intensity', '0.75');
                        this.pointLight2.setAttribute('intensity', '0.75');
                        this.pointLight3.setAttribute('intensity', '0.75');

                        break;
                    case 2:
                        if (this.fillMaterial) {
                            this.fillMaterial.color = (new THREE.Color(0xffffff));
                            this.fillMaterial.emissiveIntensity = 1;
                            this.fillMaterial.needsUpdate = true;
                        }

                        this.lightMesh1.scale.set(2, 2, 1)
                        this.lightMesh2.scale.set(2, 2, 1)
                        this.lightMesh3.scale.set(2, 2, 1)

                        this.meshMaterial1.color.set(0xffffff)
                        this.meshMaterial2.color.set(0xffffff)
                        this.meshMaterial3.color.set(0xffffff)
                        this.meshMaterial1.opacity = 1
                        this.meshMaterial2.opacity = 1
                        this.meshMaterial3.opacity = 1

                        this.pointLight.setAttribute('intensity', '1');
                        this.pointLight2.setAttribute('intensity', '1');
                        this.pointLight3.setAttribute('intensity', '1');

                        break;
                    default:
                        break;
                }
            };
            const enableFirstSeriesCircuit = () => {
                this.seriesWire.visible = true
                this.seriesAmmeter.visible = true
                this.seriesBatteryCell.visible = true
                this.seriesGlassBulb1.visible = true
                this.seriesGlassBulb2.visible = false
                this.seriesGlassBulb3.visible = false
                this.seriesBulbBase1.visible = true
                this.seriesBulbBase2.visible = false
                this.seriesBulbBase3.visible = false
                this.seriesLampBase1.visible = true
                this.seriesLampBase2.visible = false
                this.seriesLampBase3.visible = false
                this.coil1.visible = true
                this.coil2.visible = false
                this.coil3.visible = false
                // setup light spheres 
                this.lightSphere1.setAttribute('position', this.lightSeries1pos)
                this.pointLight.setAttribute('position', this.lightSeries1pos)
                this.pointLight2.setAttribute('intensity', '0');
                this.pointLight3.setAttribute('intensity', '0');
                this.lightMesh1.visible = true
                this.lightMesh2.visible = false
                this.lightMesh3.visible = false
                // enable map
                this.baseMaterial.aoMap = getAOTexture('map0');
                this.baseMaterial.needsUpdate = true;

            }
            const enableSecondSeriesCircuit = () => {
                this.seriesWire.visible = true
                this.seriesAmmeter.visible = true
                this.seriesBatteryCell.visible = true
                this.seriesGlassBulb1.visible = true
                this.seriesGlassBulb2.visible = true
                this.seriesGlassBulb3.visible = false
                this.seriesBulbBase1.visible = true
                this.seriesBulbBase2.visible = true
                this.seriesBulbBase3.visible = false
                this.seriesLampBase1.visible = true
                this.seriesLampBase2.visible = true
                this.seriesLampBase3.visible = false
                this.coil1.visible = true
                this.coil2.visible = true
                this.coil3.visible = false
                //setup light spheres position
                this.lightSphere1.setAttribute('position', this.lightSeries1pos)
                this.lightSphere2.setAttribute('position', this.lightSeries2pos)
                this.pointLight.setAttribute('position', this.lightSeries1pos)
                this.pointLight2.setAttribute('position', this.lightSeries2pos)
                this.pointLight3.setAttribute('intensity', '0');
                this.lightMesh1.visible = true
                this.lightMesh2.visible = true
                this.lightMesh3.visible = false
                // enable map
                this.baseMaterial.aoMap = getAOTexture('map1');
                this.baseMaterial.needsUpdate = true;
            }
            const enableThirdSeriesCircuit = () => {
                this.seriesWire.visible = true
                this.seriesAmmeter.visible = true
                this.seriesBatteryCell.visible = true
                this.seriesGlassBulb1.visible = true
                this.seriesGlassBulb2.visible = true
                this.seriesGlassBulb3.visible = true
                this.seriesBulbBase1.visible = true
                this.seriesBulbBase2.visible = true
                this.seriesBulbBase3.visible = true
                this.seriesLampBase1.visible = true
                this.seriesLampBase2.visible = true
                this.seriesLampBase3.visible = true
                this.coil1.visible = true
                this.coil2.visible = true
                this.coil3.visible = true
                // set position of light spheres
                this.lightSphere1.setAttribute('position', this.lightSeries1pos)
                this.lightSphere2.setAttribute('position', this.lightSeries2pos)
                this.pointLight.setAttribute('position', this.lightSeries1pos)
                this.pointLight2.setAttribute('position', this.lightSeries2pos)
                this.lightSphere3.setAttribute('position', this.lightSeries3pos)
                this.pointLight3.setAttribute('position', this.lightSeries3pos)
                this.lightMesh3.visible = true
                this.lightMesh2.visible = true
                this.lightMesh1.visible = true
                // enable map
                this.baseMaterial.aoMap = getAOTexture('map2');
                this.baseMaterial.needsUpdate = true;
            }
            const disableSeriesCircuit = () => {
                this.seriesWire.visible = false
                this.seriesAmmeter.visible = false
                this.seriesBatteryCell.visible = false
                this.seriesGlassBulb1.visible = false
                this.seriesGlassBulb2.visible = false
                this.seriesGlassBulb3.visible = false
                this.seriesBulbBase1.visible = false
                this.seriesBulbBase2.visible = false
                this.seriesBulbBase3.visible = false
                this.seriesLampBase1.visible = false
                this.seriesLampBase2.visible = false
                this.seriesLampBase3.visible = false
                this.coil1.visible = false
                this.coil2.visible = false
                this.coil3.visible = false
                this.lightMesh3.visible = false
                this.lightMesh2.visible = false
                this.lightMesh1.visible = false
            }

            const enableFirstParallelCircuit = () => {
                this.parallelAmmeter.visible = true
                this.parallelGlassBulb1.visible = true
                this.parallelGlassBulb2.visible = false
                this.parallelGlassBulb3.visible = false
                this.parallelBulbBase1.visible = true
                this.parallelBulbBase2.visible = false
                this.parallelBulbBase3.visible = false
                this.parallelLampBase1.visible = true
                this.parallelLampBase2.visible = false
                this.parallelLampBase3.visible = false
                this.coil4.visible = true
                this.coil5.visible = false
                this.coil6.visible = false
                this.parallelWires.visible = true
                this.parallelWiresBlack.visible = false
                this.parallelWiresRed.visible = false
                this.parallelWiresBlack1.visible = false
                this.parallelWiresRed1.visible = false
                this.parallelBatteryCell.visible = true
                this.lightSphere1.setAttribute('position', this.lightPara1pos)
                this.pointLight.setAttribute('position', this.lightPara1pos)
                this.pointLight2.setAttribute('intensity', '0');
                this.pointLight3.setAttribute('intensity', '0');
                this.lightMesh1.visible = true
                this.lightMesh2.visible = false
                this.lightMesh3.visible = false
                // enable map
                this.baseMaterial.aoMap = getAOTexture('map3');
                this.baseMaterial.needsUpdate = true;
            }
            const enableSecondParallelCircuit = () => {
                this.parallelAmmeter.visible = true
                this.parallelGlassBulb1.visible = true
                this.parallelGlassBulb2.visible = true
                this.parallelGlassBulb3.visible = false
                this.parallelBulbBase1.visible = true
                this.parallelBulbBase2.visible = true
                this.parallelBulbBase3.visible = false
                this.parallelLampBase1.visible = true
                this.parallelLampBase2.visible = true
                this.parallelLampBase3.visible = false
                this.coil4.visible = true
                this.coil5.visible = true
                this.coil6.visible = false
                this.parallelWires.visible = true
                this.parallelWiresBlack.visible = true
                this.parallelWiresRed.visible = true
                this.parallelWiresBlack1.visible = false
                this.parallelWiresRed1.visible = false
                this.parallelBatteryCell.visible = true
                // setup spheres for lights
                this.lightSphere1.setAttribute('position', this.lightPara1pos)
                this.lightSphere2.setAttribute('position', this.lightPara2pos)
                this.pointLight.setAttribute('position', this.lightPara1pos)
                this.pointLight2.setAttribute('position', this.lightPara2pos)
                this.pointLight3.setAttribute('intensity', '0');
                this.lightMesh1.visible = true
                this.lightMesh2.visible = true
                this.lightMesh3.visible = false
                // enable map
                this.baseMaterial.aoMap = getAOTexture('map4');
                this.baseMaterial.needsUpdate = true;
            }
            const enableThirdParallelCircuit = () => {
                this.parallelAmmeter.visible = true
                this.parallelGlassBulb1.visible = true
                this.parallelGlassBulb2.visible = true
                this.parallelGlassBulb3.visible = true
                this.parallelBulbBase1.visible = true
                this.parallelBulbBase2.visible = true
                this.parallelBulbBase3.visible = true
                this.parallelLampBase1.visible = true
                this.parallelLampBase2.visible = true
                this.parallelLampBase3.visible = true
                this.coil4.visible = true
                this.coil5.visible = true
                this.coil6.visible = true
                this.parallelWires.visible = true
                this.parallelWiresBlack.visible = true
                this.parallelWiresRed.visible = true
                this.parallelWiresBlack1.visible = true
                this.parallelWiresRed1.visible = true
                this.parallelBatteryCell.visible = true
                this.lightSphere1.setAttribute('position', this.lightPara1pos)
                this.lightSphere2.setAttribute('position', this.lightPara2pos)
                this.pointLight.setAttribute('position', this.lightPara1pos)
                this.pointLight2.setAttribute('position', this.lightPara2pos)
                this.lightSphere3.setAttribute('position', this.lightPara3pos)
                this.pointLight3.setAttribute('position', this.lightPara3pos)
                this.lightMesh3.visible = true
                this.lightMesh2.visible = true
                this.lightMesh1.visible = true
                // enable map
                this.baseMaterial.aoMap = getAOTexture('map5');
                this.baseMaterial.needsUpdate = true;
            }
            const disableParallelCircuit = () => {
                this.parallelAmmeter.visible = false
                this.parallelGlassBulb1.visible = false
                this.parallelGlassBulb2.visible = false
                this.parallelGlassBulb3.visible = false
                this.parallelBulbBase1.visible = false
                this.parallelBulbBase2.visible = false
                this.parallelBulbBase3.visible = false
                this.parallelLampBase1.visible = false
                this.parallelLampBase2.visible = false
                this.parallelLampBase3.visible = false
                this.coil4.visible = false
                this.coil5.visible = false
                this.coil6.visible = false
                this.parallelWires.visible = false
                this.parallelWiresBlack.visible = false
                this.parallelWiresRed.visible = false
                this.parallelWiresBlack1.visible = false
                this.parallelWiresRed1.visible = false
                this.parallelBatteryCell.visible = false
                this.lightMesh1.visible = false
                this.lightMesh2.visible = false
                this.lightMesh3.visible = false
            }

            const setAmmeterDigits = () => {
                if (!this.ammText) {
                    const ammText = document.createElement('a-entity')
                    ammText.setAttribute('text', "value: 2.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    ammText.setAttribute('position', '0.262 0.025 0.08');
                    ammText.setAttribute('scale', '0.1 0.1 0.1');
                    ammText.setAttribute('rotation', '-90 0 0');
                    ammText.setAttribute('visible', true);
                    this.el.appendChild(ammText);
                    this.ammText = ammText;
                }

            }

            const updateCircuitDynamicValues = () => {
                if (this.currentAssetid === 0) {
                    switch (this.sliderValue) {
                        case `1`:
                            this.ammText.setAttribute('text', "value: 6.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                            this.ammText.setAttribute('position', '0.3875 0.025 0.1525');
                            setLightBulbIntensity(2)
                            break;
                        case `2`:
                            this.ammText.setAttribute('text', "value: 12.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                            this.ammText.setAttribute('position', '0.375 0.025 0.1525');
                            setLightBulbIntensity(2)
                            break;
                        case `3`:
                            this.ammText.setAttribute('text', "value: 18.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                            this.ammText.setAttribute('position', '0.375 0.025 0.1525');
                            setLightBulbIntensity(2)
                            break;
                    }
                } else {
                    switch (this.sliderValue) {
                        case `1`:
                            this.ammText.setAttribute('text', "value: 6.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                            this.ammText.setAttribute('position', '0.272 0.025 0.08');
                            setLightBulbIntensity(2)
                            break;
                        case `2`:
                            this.ammText.setAttribute('text', "value: 3.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                            this.ammText.setAttribute('position', '0.272 0.025 0.08');
                            setLightBulbIntensity(1)
                            break;
                        case `3`:
                            this.ammText.setAttribute('text', "value: 2.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                            this.ammText.setAttribute('position', '0.272 0.025 0.08');
                            setLightBulbIntensity(0)
                            break;
                    }
                }
            }

            this.seriesCircuitHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Series Circuit';
                    const body = 'The voltage of the cell is shared over all the components. As more components are added their resistances add together, meaning the current in the circuit decreases.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.parallelCircuitHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Parallel Circuit';
                    const body = 'The potential difference over each loop is the same as the voltage of the cell. The current for each loop only depends on the resistance in <b>its own loop</b>.<br/>These currents all add together to make a total current which increases the more loops are added.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const seriesCircuitTriggerBtn = poolButtons.requestEntity()
                seriesCircuitTriggerBtn?.setAttribute('position', '-0.045 0.065 0.18')
                seriesCircuitTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        seriesCircuitTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        seriesCircuitTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                });
                seriesCircuitTriggerBtn?.play()
                seriesCircuitTriggerBtn?.addEventListener('click', () => {
                    this.seriesCircuitHandler()
                    if (seriesCircuitTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(seriesCircuitTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (seriesCircuitTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = seriesCircuitTriggerBtn
                    }
                });
                const parallelCircuitTriggerButton = poolButtons.requestEntity();
                parallelCircuitTriggerButton?.setAttribute('position', '-0.045 0.065 0.18');
                parallelCircuitTriggerButton?.setAttribute('scale', '2 2 2')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        parallelCircuitTriggerButton?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 1) {
                        parallelCircuitTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                parallelCircuitTriggerButton?.play()
                parallelCircuitTriggerButton?.addEventListener('click', () => {
                    this.parallelCircuitHandler()
                    if (parallelCircuitTriggerButton) {
                        this.annotationComponent.setObjectToFollow(parallelCircuitTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (parallelCircuitTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = parallelCircuitTriggerButton

                    }
                });

            };
        },
    },
};
export {CircuitControlComponent}
