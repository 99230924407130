/* eslint-disable react-hooks/rules-of-hooks */
import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import './styles/instruction.css';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IProcessControllAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;
	actionLipidsModel: THREE.AnimationAction;
	actionWaterFill: THREE.AnimationAction;
	actionFoodMix: THREE.AnimationAction;
	actionSudanDropper: THREE.AnimationAction;
	actionDropper: THREE.AnimationAction;
	currentClip: THREE.AnimationAction;
	mixerStep_1: THREE.AnimationMixer;
	mixerStep_2: THREE.AnimationMixer;
	mixerStep_3: THREE.AnimationMixer;
	mixerStep_4: THREE.AnimationMixer;
	mixerStep_5: THREE.AnimationMixer;
	el: AFrame.Entity;
	currentAssetId: number;
	instruction: HTMLElement | null;
	lipids: AFrame.Entity;
	newAssetId: any;
	biology3_1: AFrame.Entity;
	biology3_2: AFrame.Entity;
	biology3_3: AFrame.Entity;
	biology3_4: AFrame.Entity;
	cellModel_0: THREE.Object3D<THREE.Object3DEventMap> | undefined;
	cellModel_1: THREE.Object3D<THREE.Object3DEventMap> | undefined;

	poolButtons: PoolComponent;
	foodTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	pestleTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	waterTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	sudanTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	bottleTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;

	foodButtonHandler: () => void;
	waterButtonHandler: () => void;
	sudanButtonHandler: () => void;
	pestleButtonHandler: () => void;
	bottleButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const FoodTestsLipidsScene = {
	name: 'food-test-scene-lipids',
	val: {
		init(this: IProcessControllAframe) {

			var isInitialised = false;
			var counter = 0;
			this.el.addEventListener('model-loaded', () => {
				counter++;
				if (counter < 4) return
				if (!isInitialised) {
					this.instruction = document.getElementById('instruction-lipids');
					if (this.instruction) {
						this.instruction.classList.add('invisible')
					}

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;
							initialiseButtons();
						});
					}

					this.lipids = document.getElementById('foodTestsLipidsScenes') as AFrame.Entity;

					this.biology3_1 = document.getElementById('Biology-3_1') as AFrame.Entity;
					this.biology3_2 = document.getElementById('Biology-3_2') as AFrame.Entity;
					this.biology3_3 = document.getElementById('Biology-3_3') as AFrame.Entity;
					this.biology3_4 = document.getElementById('Biology-3_4') as AFrame.Entity;

					const foodGroup = this.biology3_1.object3D.getObjectByName('Biology3_1Food_V3_1');

					this.cellModel_1 = foodGroup?.getObjectByName('Model_(1)');
					this.cellModel_0 = foodGroup?.getObjectByName('Model');

					if (this.cellModel_0) { this.cellModel_0.visible = false };
					if (this.cellModel_1) { this.cellModel_1.visible = false };

					this.biology3_1.object3D.visible = true;
					this.biology3_2.object3D.visible = false;
					this.biology3_3.object3D.visible = false;
					this.biology3_4.object3D.visible = false;

					this.biology3_3.object3D.position.x = -0.9;;
					this.waterTriggerBtn?.object3D.position.set(0.088, 0.08, 0.04);
					
					if (this.foodTriggerBtn) {
						this.foodTriggerBtn.object3D.visible = true;
					}
					if (this.pestleTriggerBtn) {
						this.pestleTriggerBtn.object3D.visible = false;
					}
					if (this.waterTriggerBtn) {
						this.waterTriggerBtn.object3D.visible = false;
					}
					if (this.sudanTriggerBtn) {
						this.sudanTriggerBtn.object3D.visible = false;
					}

					const animatedBiolody3_1 = this.biology3_1.object3D.getObjectByName('Biology3_1Food_V3') as any;
					this.mixerStep_1 = new THREE.AnimationMixer(animatedBiolody3_1);
					const LipidsModelAnimation = animatedBiolody3_1.animations[0];
					this.actionLipidsModel = this.mixerStep_1.clipAction(LipidsModelAnimation);

					const animatedBiolody3_2 = this.biology3_2.object3D.getObjectByName('Scene') as any;
					this.mixerStep_2 = new THREE.AnimationMixer(animatedBiolody3_2);
					const FoodMixAnimation = animatedBiolody3_2.animations[0];
					this.actionFoodMix = this.mixerStep_2.clipAction(FoodMixAnimation);

					const animatedBiolody3_3 = this.biology3_3.object3D.getObjectByName('Scene') as any;
					this.mixerStep_3 = new THREE.AnimationMixer(animatedBiolody3_3);
					const WaterFillAnimation = animatedBiolody3_3.animations[0];
					this.actionWaterFill = this.mixerStep_3.clipAction(WaterFillAnimation);

					const animatedBiolody3_4 = this.biology3_4.object3D.getObjectByName('Scene') as any;
					this.mixerStep_4 = new THREE.AnimationMixer(animatedBiolody3_4);
					const SudanDropperAnimation = animatedBiolody3_4.animations[0];
					this.actionSudanDropper = this.mixerStep_4.clipAction(SudanDropperAnimation);

					this.currentAssetId = 0;
					this.el.sceneEl?.addEventListener('asset-change', (event) => {
						const customEvent = event as CustomEvent; // Cast event to CustomEvent
						this.newAssetId = customEvent.detail.assetId;
						if (this.newAssetId === 0) {
							this.biology3_1.object3D.visible = true;
							this.lipids.object3D.position.x = 0;
							this.biology3_2.object3D.visible = false;
							this.biology3_3.object3D.visible = false;
							this.biology3_4.object3D.visible = false;
							this.instruction?.classList.remove('visible');
							this.instruction?.classList.add('invisible');
							if (this.foodTriggerBtn) {
								this.foodTriggerBtn.object3D.visible = true;
							}
							if (this.pestleTriggerBtn) {
								this.pestleTriggerBtn.object3D.visible = false;
							}
							if (this.waterTriggerBtn) {
								this.waterTriggerBtn.object3D.visible = false;
							}
							if (this.sudanTriggerBtn) {
								this.sudanTriggerBtn.object3D.visible = false;
							}
							annotationClose();
						}
						if (this.newAssetId === 1) {
							this.biology3_1.object3D.visible = false;
							this.instruction?.classList.remove('invisible');
							this.instruction?.classList.add('visible');
							const step1 = document.getElementById("step-1");
							if (step1) {
								step1.addEventListener("click", () => {
									this.biology3_2.object3D.visible = true;
									this.lipids.object3D.position.x = 0.6;
									this.biology3_3.object3D.visible = false;
									this.biology3_4.object3D.visible = false;
									if (this.pestleTriggerBtn) {
										this.pestleTriggerBtn.object3D.visible = true;
									}
									if (this.waterTriggerBtn) {
										this.waterTriggerBtn.object3D.visible = false;
									}
									if (this.sudanTriggerBtn) {
										this.sudanTriggerBtn.object3D.visible = false;
									}
									annotationClose();
								});
							}

							const step2 = document.getElementById("step-2");
							if (step2) {
								step2.addEventListener("click", () => {
									this.biology3_2.object3D.visible = false;
									this.biology3_3.object3D.visible = true;
									this.lipids.object3D.position.x = 0;
									this.biology3_4.object3D.visible = false;
									if (this.waterTriggerBtn) {
										this.waterTriggerBtn.object3D.visible = true;
									}
									if (this.pestleTriggerBtn) {
										this.pestleTriggerBtn.object3D.visible = false;
									}
									if (this.sudanTriggerBtn) {
										this.sudanTriggerBtn.object3D.visible = false;
									}
									annotationClose();
								});
							}

							const step3 = document.getElementById("step-3");
							if (step3) {
								step3.addEventListener("click", () => {
									this.biology3_2.object3D.visible = false;
									this.biology3_3.object3D.visible = false;
									this.biology3_4.object3D.visible = true;
									this.lipids.object3D.position.x = 0.2;
									if (this.sudanTriggerBtn) {
										this.sudanTriggerBtn.object3D.visible = true;
									}
									if (this.waterTriggerBtn) {
										this.waterTriggerBtn.object3D.visible = false;
									}
									if (this.pestleTriggerBtn) {
										this.pestleTriggerBtn.object3D.visible = false;
									}
									annotationClose();
								});
							}
						}
					});
					isInitialised = true
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: foodTestsLipidsScenes; scale: 25 25 25;');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}

					const step2 = document.getElementById("step-2");
					if (step2) {
						step2.addEventListener("click", () => {
							this.biology3_3.object3D.position.x = -0.9;
							this.waterTriggerBtn?.object3D.position.set(0.088, 0.08, 0.04)
							// ring.object3D.position.x = 0;
							annotationClose();
						});
					}
				}
			});

			const annotationClose = () => {
				const scene = document.querySelector('a-scene');
				if (scene) {
					scene.emit('annotation-close');
					// close quiz
				}
			}

			this.el.sceneEl?.addEventListener('quiz-opened', () => {
				this.instruction?.classList.add('instruction-slide-up');
				this.instruction?.classList.remove('instruction-slide-down');
			})
			this.el.sceneEl?.addEventListener('quiz-closed', () => {
				this.instruction?.classList.add('instruction-slide-down');
				this.instruction?.classList.remove('instruction-slide-up');
			})

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			this.foodButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionLipidsModel;
				this.actionLipidsModel.reset()
				this.actionLipidsModel.repetitions = 1
				this.actionLipidsModel.clampWhenFinished = true
				this.actionLipidsModel.play()
				if (this.cellModel_0) { this.cellModel_0.visible = true }
				if (this.cellModel_1) { this.cellModel_1.visible = true }
				if (this.onObjectSelected) {
					const title = 'Lipids';
					const body = 'Lipids are made up of one glycerol molecule that’s linked to three fatty acid molecules. Lipids are an important part of the human diet. They serve a variety of functions in the body, such as energy storage, insulation and cell membrane structure.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.pestleButtonHandler = () => {

				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionFoodMix;
				this.actionFoodMix.reset()
				this.actionFoodMix.repetitions = 1
				this.actionFoodMix.clampWhenFinished = true
				this.actionFoodMix.play()
				if (this.onObjectSelected) {
					const title = 'Step 1';
					const body = 'The first step to prepare food for the (Sudan III) lipid test is to grind the food until it is paste-like. A pestle and mortar is used to grind the food.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.waterButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionWaterFill;
				this.actionWaterFill.reset()
				this.actionWaterFill.repetitions = 1
				this.actionWaterFill.clampWhenFinished = true
				this.actionWaterFill.play()

				if (this.onObjectSelected) {
					const title = 'Step 2';
					const body = 'After transferring the food into a test tube, add 5 cm<span class="superscript">3</span> of water to the test tube.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.sudanButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionSudanDropper;
				this.actionSudanDropper.reset()
				this.actionSudanDropper.repetitions = 1
				this.actionSudanDropper.clampWhenFinished = true
				this.actionSudanDropper.play()

				if (this.onObjectSelected) {
					const title = 'Step 3';
					const body = 'The reagent used to test for lipids is <b>Sudan III</b>. Add three drops, shake your test tube gently and observe the results. A positive test forms  a red-stained layer on the surface. If there are no lipids present then the reagent will blend with the sample.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				this.foodTriggerBtn = this.poolButtons.requestEntity()
				this.foodTriggerBtn?.object3D.position.set(0.02, 0.07, 0.02)
				this.foodTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1)
				this.foodTriggerBtn?.play()
				this.foodTriggerBtn?.addEventListener('click', () => {
					this.foodButtonHandler()
					if (this.foodTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.foodTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.foodTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.foodTriggerBtn
					}
				});
				this.el.sceneEl?.addEventListener('asset-change', (event) => {
					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						this.foodTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1)
					}
					if (newAssetId === 1) {
						this.foodTriggerBtn?.object3D.scale.set(0, 0, 0)
					}
				});

				this.pestleTriggerBtn = this.poolButtons.requestEntity()
				this.pestleTriggerBtn?.object3D.position.set(-0.01, 0.08, 0.08)
				this.pestleTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1)
				this.el.sceneEl?.addEventListener('asset-change', (event) => {

					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						this.pestleTriggerBtn?.object3D.scale.set(0, 0, 0)
					}
					if (newAssetId === 1) {
						this.pestleTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1)
					}

				});
				this.pestleTriggerBtn?.play()
				this.pestleTriggerBtn?.addEventListener('click', () => {
					this.pestleButtonHandler()
					if (this.pestleTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.pestleTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.pestleTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.pestleTriggerBtn
					}
				});

				this.waterTriggerBtn = this.poolButtons.requestEntity()
				this.waterTriggerBtn?.object3D.position.set(0.999, 0.08, 0.04)
				this.waterTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1)
				this.el.sceneEl?.addEventListener('asset-change', (event) => {

					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						this.waterTriggerBtn?.object3D.scale.set(0, 0, 0)
					}
					if (newAssetId === 1) {
						this.waterTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1)
					}

				});
				this.waterTriggerBtn?.play()
				this.waterTriggerBtn?.addEventListener('click', () => {
					this.waterButtonHandler()
					if (this.waterTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.waterTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.waterTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.waterTriggerBtn
					}
				});

				this.sudanTriggerBtn = this.poolButtons.requestEntity()
				this.sudanTriggerBtn?.object3D.position.set(-0.002, 0.091, 0.01)
				this.sudanTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1)
				this.el.sceneEl?.addEventListener('asset-change', (event) => {
					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						this.sudanTriggerBtn?.object3D.scale.set(0, 0, 0)
					}
					if (newAssetId === 1) {
						this.sudanTriggerBtn?.object3D.scale.set(0.1, 0.1, 0.1)
					}

				});
				this.sudanTriggerBtn?.play()
				this.sudanTriggerBtn?.addEventListener('click', () => {
					this.sudanButtonHandler()
					if (this.sudanTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.sudanTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.sudanTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.sudanTriggerBtn
					}
				});
			}
		},
		tick(this: IProcessControllAframe, time: number, deltaTime: number) {
			if (this.mixerStep_1) {
				this.mixerStep_1.update(deltaTime * 0.001);
			}
			if (this.mixerStep_2) {
				this.mixerStep_2.update(deltaTime * 0.001);
			}
			if (this.mixerStep_3) {
				this.mixerStep_3.update(deltaTime * 0.001);
			}
			if (this.mixerStep_4) {
				this.mixerStep_4.update(deltaTime * 0.001);
			}

		},
	},
};
export { FoodTestsLipidsScene as FoodTestsStarchSceneComponent }