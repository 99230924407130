import { Stack, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { IconImage } from '../../../shared/components/IconImage';
import { DisplayEnum } from '../../../shared/enums';
import { useTranslation } from 'react-i18next';
import CustomTab from './CustomTab';

import brochures from '../../../assets/brochures.png';
import qrCode from '../../../assets/qrCode.png';

export function QRcode() {
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { t, i18n } = useTranslation();
  const i18nPath = 'LANDING_PAGE.QR_CODE_COMPONENT.';

  return (
    <Stack px={tablet ? 1.6 : 0 }>
      <Box textAlign='center'>
        <Box mx='auto'>
          <IconImage src={brochures} alt='brochures' width={mobile ? '100%' : xlDisplay ? '900px' : '500px'} />
        </Box>
      </Box>
      <Stack width={tablet ? '100%' : 862} mx='auto'>
        <Typography
          fontSize={26}
          fontFamily='GilroyBold'
          color='#262626'
          mb={2}
        >
          {t(i18nPath + 'TITLE')}
        </Typography>
        <Typography
          fontSize={16}
          fontFamily='GilroyMedium'
          color={theme.palette.text.secondary}
          mb={4}
        >
          {t(i18nPath + 'DESCRIPTION')}
        </Typography>
        <CustomTab />
        {/* <Stack mt={tablet ? 3 : 6} textAlign='center'>
          <Box mx='auto'>
            <IconImage src={qrCode} alt='qrCode' width='94px' />
          </Box>
          <Typography
            fontSize={26}
            fontFamily='GilroyBold'
            color='#262626'
            mb={2}
          >
            Snapcodes and QR codes
          </Typography>
          <Typography
            width='100%'
            mx='auto'
            fontSize={16}
            fontFamily='GilroyMedium'
            color={theme.palette.text.secondary}
            mb={tablet ? 3 : 6}
          >
            Integrating with Snapchat, we’re bringing our AR micro-lessons to
            where students already spend their time and feel comfortable. Launch
            our microlearning experiences directly from Snapchat and jump right
            into our microlearning AR experiences. We’re bridging the gap
            between social media and education, making it as easy as possible to
            fuel a curious mind. Not on Snapchat? Simply use a classic QR code.
          </Typography>
        </Stack> */}
      </Stack>
    </Stack>
  );
}

export default QRcode;
