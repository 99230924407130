
import * as THREE from 'three';
import * as AFrame from 'aframe';
import "aframe-troika-text"
import RoundedPlane from '../../../assets/textures/RoundedPanel24.png';

interface IInteruptMessageAframe {
    currentImageIndex: number;
    newScale: string;
    imageIds: string[];
    active: boolean;
    data: {
        imageIds: string[];
      };
    textEl: AFrame.Entity;
    planeEl: AFrame.Entity;
    el: AFrame.Entity;
}

export interface IInteruptMessage extends IInteruptMessageAframe {
    swapImage(index: number): void;
    setPosition(newPosition: string): void;
    setScale(newScale: string): void;
    activate(): void;
    activatePermanent(): void;
    deactivate(): () => void;
}


const InteruptMessageComponent = {
    name: 'interupt-message',
    val: {
        schema: {
            imageIds: { type: 'array' }
        },
        init(this: IInteruptMessage) {
            console.log('Initialising interup message component.')
            this.currentImageIndex = 0;
            // Create the rounded box geometry
            const planeEl = document.createElement('a-plane');
            planeEl.setAttribute('width', '0.12');
            planeEl.setAttribute('height', '0.06');
            planeEl.setAttribute('color', 'white');
            if (this.data.imageIds && this.data.imageIds.length > 0) {
                planeEl.setAttribute('material', `src: #${this.data.imageIds[0]}; transparent: true;`);
            }
            
            planeEl.setAttribute('position', '-0.05 0.05 -0.1');
            // planeEl.setAttribute('visible', 'false');
            planeEl.setAttribute('face-camera', ''); // Face the camera
           

            // Add the rounded box entity to the current entity
            this.el.appendChild(planeEl);
            this.planeEl = planeEl;
            this.active = true;
            this.deactivate();

        },
        setPosition(this: IInteruptMessageAframe, newPosition: string) {
            if (this.active) {               
                return;
            }
            this.planeEl.setAttribute('position', newPosition);
        },
        setScale(this: IInteruptMessageAframe, newScale: string) {
            if (this.active) {               
                return;
            }
            this.newScale = newScale;
            this.planeEl.setAttribute('scale', newScale);
        },
        swapImage(this: IInteruptMessageAframe, index: number) {
            if (this.data.imageIds && this.data.imageIds.length > index) {
                const newImageId = this.data.imageIds[index];
                this.planeEl.setAttribute('material', `src: #${newImageId}; transparent: true;`);
            }
        },
        activate(this: IInteruptMessage) {
            if (!this.active) {
                this.active = true;
                // this.planeEl.setAttribute('visible', true);

                const fromScale = '0.001 0.001 0.001';
                const toScale = this.newScale? this.newScale : '1 1 1';
                const duration = 350; // animation duration in milliseconds

                this.planeEl.setAttribute('animation', `
                  property: scale;
                  from: ${fromScale};
                  to: ${toScale};
                  dur: ${duration};
                  easing: linear;
                `);

                setTimeout(() => {
                    this.deactivate();
                }, 5000);
            }
            
        },
        activatePermanent(this: IInteruptMessage) {
            if (!this.active) {
                this.active = true;
                // this.planeEl.setAttribute('visible', true);

                const fromScale = '0.001 0.001 0.001';
                const toScale = this.newScale? this.newScale : '1 1 1';
                const duration = 350; // animation duration in milliseconds

                this.planeEl.setAttribute('animation', `
                  property: scale;
                  from: ${fromScale};
                  to: ${toScale};
                  dur: ${duration};
                  easing: linear;
                `);
            }
        },
        deactivate(this: IInteruptMessageAframe) {
            if (this.active) {
                this.active = false;
                // this.planeEl.setAttribute('visible', false);

                const fromScale = '1 1 1';
                const toScale = '0.001 0.001 0.001';
                const duration = 350; // animation duration in milliseconds

                this.planeEl.setAttribute('animation', `
                  property: scale;
                  from: ${fromScale};
                  to: ${toScale};
                  dur: ${duration};
                  easing: linear;
                `);
            }
        },
    },
};
export { InteruptMessageComponent as InteruptMessage }