import * as AFrame from "aframe";
import * as THREE from "three";

interface IModelQualityMicrobiology {
    el: AFrame.Entity;
}

const ModelQualityMicrobiologyAframe = {
    name: "model-quality",
    val: {
        init(this: IModelQualityMicrobiology) {
            const totalModelsToLoad = 5;
            let modelsLoaded = 0;
            this.el.addEventListener("model-loaded", () => {
                modelsLoaded++;
                if (modelsLoaded < totalModelsToLoad) return;

                

                fixScene1AgarPlate();
                fixScene21GlassMaterial();
                fixScene22GlassMaterial();
                fixScene23GlassMaterial();
                fixScene24GlassMaterial();
                     
            });

            const fixScene24GlassMaterial = () => {
                const step4 = document.getElementById("step4") as AFrame.Entity;
                const glasObj = step4.object3D.getObjectByName("Lid"); // all share the same glass_mat
                const mat = (glasObj as THREE.Mesh).material as THREE.MeshPhysicalMaterial;
                mat.transmission = 0.2; // reduce transmission
            };

            const fixScene23GlassMaterial = () => {
                const step3 = document.getElementById("step3") as AFrame.Entity;
                const glasObj = step3.object3D.getObjectByName("Lid"); // all share the same glass_mat
                const mat = (glasObj as THREE.Mesh).material as THREE.MeshPhysicalMaterial;
                mat.transmission = 0.2; // reduce transmission

               
            };

            const fixScene22GlassMaterial = () => {
                const step2 = document.getElementById("step2") as AFrame.Entity;
                
                const glasObj = step2.object3D.getObjectByName("Lid"); // all share the same glass_mat
                const mat = (glasObj as THREE.Mesh).material as THREE.MeshPhysicalMaterial;
                mat.transmission = 0.2; // reduce transmission
            };

            const fixScene21GlassMaterial = () => {
                const step1 = document.getElementById("step1") as AFrame.Entity;
                const glasObj = step1.object3D.getObjectByName("Lid"); // all share the same glass_mat
                const mat = (glasObj as THREE.Mesh).material as THREE.MeshPhysicalMaterial;
                mat.transmission = 0.2; // reduce transmission
            };

            const fixScene1AgarPlate = () => {
                // fixing glass material on Base and Lid
                const scene1 = document.getElementById("scene1") as AFrame.Entity;
                const base = scene1.object3D.getObjectByName("Base");
                const mat = (base as THREE.Mesh).material as THREE.MeshPhysicalMaterial;

                // mat.transparent = true;
                // mat.opacity = 0.2;
                // mat.clearcoat = 0.5;
                // mat.reflectivity = 0.5;

                // disable refraction
                // mat.thickness = 0;
                mat.transmission = 0.2;
                mat.needsUpdate = true;
            };
        },
    },
}
export {ModelQualityMicrobiologyAframe as ModelQuality}