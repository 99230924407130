import React, {useRef, useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import {TapPlaceAir} from 'lib/aframe/components/tap-place-air';
import {WorldButtonComponent} from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import {TheMoonAtmosphereComponent} from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
    Annotation,
    AnnotationState,
} from '../../../lib/aframe/components/annotation';
import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
    MODEL3D_HINT,
} from 'shared/constants/hint-items';
import {LoadersPermissonsEnum} from 'shared/enums';

const introText =
    'Explore the annotations to learn about gases why some objects can’t hold atmospheres.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const PhysicsTheMoonsAtmosphereAframe = () => {
    const [toggle, setToggle] = useState(true);

    const [selectedObject, setSelectedObject] = useState<{
        title: string;
        body: string;
    } | null>(null);

    const [annotationState, setAnnotationState] = useState<AnnotationState>(
        AnnotationState.None
    );
    const [lessonStarted, setLessonStarted] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
    const onToggleAnimation = () => {
        setToggle(!toggle);
    };
    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };
    const onLessonStarted = () => {
        setLessonStarted(true);
    };
    const permissionsConfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };

    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };
    const animToggleEventRef = useRef(
        new CustomEvent('anim-toggle', {detail: {toggle: true}})
    );
    React.useEffect(() => {
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            animToggleEventRef.current.detail.toggle = toggle;
            scene.dispatchEvent(animToggleEventRef.current);
        }
    }, [toggle]);
    const onLessonRecenter = () => {
        setLessonStarted(false);
        setToggle(true);
    };
    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                lessonStarted={lessonStarted}
                onToggleAnimation={onToggleAnimation}
                toggleAnimationStatus={toggle}
                onAnnotationStateChanged={onAnnotationStateChanged}
                hintItems={hintItems}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./physics-the-moons-atmosphere-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    TheMoonAtmosphereComponent,
                    TapPlaceAir,
                    WorldButtonComponent,
                    FaceCameraComponent,
                    Annotation,
                ]}
                onObjectSelected={onObjectSelected}
                onLessonStart={onLessonStarted}
                onLessonRecenter={onLessonRecenter}
                annotationState={annotationState}
                permissionsСonfirmation={permissionsConfirmation}
            />
        </React.Fragment>
    );
};
export {PhysicsTheMoonsAtmosphereAframe};
