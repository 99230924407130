import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TriangleToTop } from './TriangleToTop';
import { TriangleToBottom } from './TriangleToBottom';

export function ClickMeStub() {
  const { t, i18n } = useTranslation();

  return (
    <Stack
      position='relative'
      bgcolor='#FFFD01'
      borderRadius='12px'
      width='187px'
      textAlign='center'
      py='10px'
    >
      <Typography
        fontFamily='GilroyBold'
        fontSize={16}
        color='#262626'
        textTransform='none'
      >
        {t('COMMON.CLICK_ME')}
      </Typography>

			<TriangleToTop />
			<TriangleToBottom />
    </Stack>
  );
}
