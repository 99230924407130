
import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IAnnotationSystemAframe } from "../../../lib/aframe/systems/annotation-system";
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { set } from 'date-fns';
import { is } from 'date-fns/locale';

interface IHouseControl {
    loader: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    obj75top: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.Material | THREE.Material[]>;
    obj50top: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.Material | THREE.Material[]>;
    obj25top: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.Material | THREE.Material[]>;
    obj50back: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.Material | THREE.Material[]>;
    obj75back: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.Material | THREE.Material[]>;
    obj25back: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.Material | THREE.Material[]>;
    video: HTMLVideoElement;
    isVideoPlaying: boolean;
    isAnimationInitialised: boolean;
    isModelLoaded: boolean;
    obj75front: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.Material | THREE.Material[]>;
    obj50front: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.Material | THREE.Material[]>;
    obj25front: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.Material | THREE.Material[]>;
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

const WireControlComponent = {
    name: 'wire-control',
    val: {
        init(this: IHouseControl) {
            this.isAnimationInitialised = false;
            this.isVideoPlaying = false;
            this.loader = document.getElementById('circleLoad') as AFrame.Entity;
            this.video = document.getElementById('video') as HTMLVideoElement;

            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                this.isModelLoaded = true;
                modelAdjustment();
                

                if (!this.isAnimationInitialised && this.isVideoPlaying) {
                    initialiseAnimations();
                };
               

                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}

            });

          

            let loaderRotation = 0;
            let previousTimestamp = 0;
            const rotationSpeed = 150;
            let isLoaderPlaying = true;
            const startLoader = () => {
                if (this.loader) {
                    rotateLoader(0);
                }
            };
           

            const removeLoader = () => {
                
                if (this.loader) {
                    this.loader.setAttribute('visible', false);
                    isLoaderPlaying = false;
                }
            };

            const rotateLoader = (timestamp: number) => {
                const deltaTime = timestamp - previousTimestamp;
                previousTimestamp = timestamp;

                loaderRotation += rotationSpeed * deltaTime / 1000;
                this.loader.setAttribute('rotation', `0 0 ${loaderRotation}`);
                if (isLoaderPlaying) {
                    requestAnimationFrame(rotateLoader);
                }
            };

            startLoader();

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: wire; scale: 14 14 14; offset: 0 -4 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            

            this.el.sceneEl?.addEventListener('slider-move', (e) => {
                const event = e as CustomEvent<{ value: number }>;
                const value = event.detail.value;
                if (this.isAnimationInitialised) {
                    setAnimation(value);
                }
            });

            const initialiseAnimations = () => {
                
                this.obj25front = this.el.object3D.getObjectByName('BlackWire_insideAR_25C_Front') as THREE.Mesh;
                this.obj50front = this.el.object3D.getObjectByName('BlackWire_insideAR_50C_Front') as THREE.Mesh;
                this.obj75front = this.el.object3D.getObjectByName('BlackWire_insideAR_75C_Front') as THREE.Mesh;
                this.obj25back = this.el.object3D.getObjectByName('BlackWire_insideAR_25C_Back') as THREE.Mesh;
                this.obj50back = this.el.object3D.getObjectByName('BlackWire_insideAR_50C_Back') as THREE.Mesh;
                this.obj75back = this.el.object3D.getObjectByName('BlackWire_insideAR_75C_Back') as THREE.Mesh;
                this.obj25top = this.el.object3D.getObjectByName('BlackWire_insideAR_25C_Top') as THREE.Mesh;
                this.obj50top = this.el.object3D.getObjectByName('BlackWire_insideAR_50C_Top') as THREE.Mesh;
                this.obj75top = this.el.object3D.getObjectByName('BlackWire_insideAR_75C_Top') as THREE.Mesh;

                const videoTexture = new THREE.VideoTexture(this.video);
                videoTexture.flipY = false;

                videoTexture.needsUpdate = true;
                (this.obj25front.material as THREE.MeshBasicMaterial).map = videoTexture;
                (this.obj25front.material as THREE.MeshBasicMaterial).needsUpdate = true;

                setAnimation(25);
                this.isAnimationInitialised = true;
                
            }

            const setAnimation = (value: number) => {
                value = Number(value);
                switch(value) {
                    case 25:
                        this.obj25front.visible = true;
                        this.obj50front.visible = false;
                        this.obj75front.visible = false;
                        this.obj25back.visible = true;
                        this.obj50back.visible = false;
                        this.obj75back.visible = false;
                        this.obj25top.visible = true;
                        this.obj50top.visible = false;
                        this.obj75top.visible = false;
                        break;
                    case 50:
                        this.obj25front.visible = false;
                        this.obj50front.visible = true;
                        this.obj75front.visible = false;
                        this.obj25back.visible = false;
                        this.obj50back.visible = true;
                        this.obj75back.visible = false;
                        this.obj25top.visible = false;
                        this.obj50top.visible = true;
                        this.obj75top.visible = false;
                        break;
                    case 75:
                        this.obj25front.visible = false;
                        this.obj50front.visible = false;
                        this.obj75front.visible = true;
                        this.obj25back.visible = false;
                        this.obj50back.visible = false;
                        this.obj75back.visible = true;
                        this.obj25top.visible = false;
                        this.obj50top.visible = false;
                        this.obj75top.visible = true;
                        break;
                    default:
                        this.obj25front.visible = true;
                        this.obj50front.visible = false;
                        this.obj75front.visible = false;
                        this.obj25back.visible = true;
                        this.obj50back.visible = false;
                        this.obj75back.visible = false;
                        this.obj25top.visible = true;
                        this.obj50top.visible = false;
                        this.obj75top.visible = false;
                        break;
                }
            };

            if (this.video.readyState >= 4) {
                if (!this.isVideoPlaying) {
                  this.video.pause();
                  this.video.play();
                  this.isVideoPlaying = true;
                  removeLoader();
                  if (this.isModelLoaded && !this.isAnimationInitialised) {
                    initialiseAnimations();
                  }
                }
              } else {
                this.video.addEventListener('canplay', () => {
                  if (this.video && !this.isVideoPlaying) {
                    this.video.pause();
                    this.video.play();
                    this.isVideoPlaying = true;
                    removeLoader();
                    if (this.isModelLoaded && !this.isAnimationInitialised) {
                      initialiseAnimations();
                    }
                  }
                });
              }

            const modelAdjustment = () => {
                // adjusting model to rotate to face screen better
                const obj = this.el.object3D.getObjectByName('Black_Wire_Video_Projection') as THREE.Object3D;
                if (obj) {
                    obj.rotation.x += THREE.MathUtils.degToRad(90);
                }
            };

            const chargesButtonHandler = () => {
                const title = '<span style=\"font-size: 22px;\">Wire Cross Section: Charges</span>'
                const body = 'The flowing charges travel at the same speed but collide with the vibrating ions. The more collisions there are the slower the rate of flow of charge (the smaller the current).'

                if (this.onObjectSelected) {
                    console.log('Calling onObjectSelected callback');
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const ionsButtonHandler = () => {
                const title = '<span style=\"font-size: 22px;\">Wire Cross Section: Ions</span>'
                const body = 'The large ions that make up the conductor vibrate more when the temperature is increased. This increases the number of collisions with charges and slows down their rate of flow (increasing the resistance).'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

           
            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const ionsTriggetBtn = poolButtons.requestEntity();
                ionsTriggetBtn?.setAttribute('position', '0.3 0.025 0.1');
                ionsTriggetBtn?.play();
                ionsTriggetBtn?.addEventListener('click', () => {
                    ionsButtonHandler();
                    if (ionsTriggetBtn) {
                        this.annotationComponent.setObjectToFollow(ionsTriggetBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(ionsTriggetBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = ionsTriggetBtn
                    }
                });

                const chargesTriggerBtn = poolButtons.requestEntity()
                chargesTriggerBtn?.setAttribute('position', '-0.15 0.075 0.1')
                chargesTriggerBtn?.play()
                chargesTriggerBtn?.addEventListener('click', () => {
                    chargesButtonHandler();
                    if (chargesTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(chargesTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(chargesTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = chargesTriggerBtn
                    }
                });
            }
        },
    },
};
export { WireControlComponent as WireControl }