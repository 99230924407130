import * as AFrame from 'aframe';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IChromosomesSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	currentClip: THREE.AnimationAction;

	mixer: THREE.AnimationMixer;
	actionDNAspinning: THREE.AnimationAction;

	el: AFrame.Entity;
	currentAssetId: number;
	poolButtons: PoolComponent;

	modelCell: AFrame.Entity;
	modelChromatid: AFrame.Entity;
	modelDNA: AFrame.Entity;
	modelKaryotype: AFrame.Entity;

	inChromatidDNA: THREE.Mesh;
	genes: THREE.Mesh;

	annotationATriggerBtn: AFrame.Entity | null;
	annotationBTriggerBtn: AFrame.Entity | null;
	annotationCTriggerBtn: AFrame.Entity | null;
	annotationDTriggerBtn: AFrame.Entity | null;
	annotationETriggerBtn: AFrame.Entity | null;

	annotationAButtonHandler: () => void;
	annotationBButtonHandler: () => void;
	annotationCButtonHandler: () => void;
	annotationDButtonHandler: () => void;
	annotationEButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const ChromosomesScene = {
	name: 'experiment',
	val: {
		init(this: IChromosomesSceneAframe) {
			var isInitialised = false;
			let count = 0;
			this.el.addEventListener('model-loaded', () => {
				count++;
				if (count < 4) return
				if (!isInitialised) {
					this.modelCell = document.getElementById('modelCell') as AFrame.Entity;
					this.modelChromatid = document.getElementById('modelChromatid') as AFrame.Entity;
					this.modelDNA = document.getElementById('modelDNA') as AFrame.Entity;
					this.modelKaryotype = document.getElementById('modelKaryotype') as AFrame.Entity;

					this.inChromatidDNA = this.modelChromatid.object3D.getObjectByName('DNAinChromatid') as THREE.Mesh;
					this.genes = this.modelChromatid.object3D.getObjectByName('Genes') as THREE.Mesh;

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;
					cellScene();
				}
				else return
			});

			const initialiseAnimations = () => {
				const animatedEl = this.modelDNA.object3D.getObjectByName("Scene") as any;

				this.mixer = new THREE.AnimationMixer(animatedEl);
				const showDNAspinning = animatedEl.animations[0];
				this.actionDNAspinning = this.mixer.clipAction(showDNAspinning);
			};

			var annotationA_Pressed = false;
			var annotationB_Pressed = false;
			var annotationC_Pressed = false;

			const cellScene = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (annotationA_Pressed) return
				if (this.modelCell) {
					this.modelCell.object3D.visible = true;
					this.modelCell.object3D.scale.set(22, 22, 22);
					this.modelCell.object3D.rotateY(-45);
				}
				this.annotationATriggerBtn?.object3D.position.set(0, 1, 2.5);
				if (this.annotationATriggerBtn) {
					this.annotationATriggerBtn.object3D.visible = true;
				}
				if (this.modelKaryotype) {
					this.modelKaryotype.object3D.visible = false;
					this.modelKaryotype.object3D.position.z = 4;
				}
				if (this.annotationBTriggerBtn) {
					this.annotationBTriggerBtn.object3D.visible = false;
				}
				if (this.modelChromatid) {
					this.modelChromatid.object3D.visible = false;
					this.modelChromatid.object3D.position.x = 3;
					this.modelChromatid.object3D.position.y = 5;
					this.modelChromatid.object3D.rotateZ(25);
				}
				if (this.annotationCTriggerBtn) {
					this.annotationCTriggerBtn.object3D.visible = false;
				}
				if (this.annotationDTriggerBtn) {
					this.annotationDTriggerBtn.object3D.visible = false;
				}
				if (this.modelDNA) {
					this.modelDNA.object3D.visible = false;
					this.modelDNA.object3D.position.x = 1;
					this.modelDNA.object3D.position.y = 6;
					this.modelDNA.object3D.rotateZ(35);
				}
				if (this.annotationETriggerBtn) {
					this.annotationETriggerBtn.object3D.visible = false;
				}
				if (this.inChromatidDNA) {
					this.inChromatidDNA.visible = false;
				}
				if (this.genes) {
					this.genes.visible = false;
				}
			}

			const chromosomesScene = () => {
				if (annotationB_Pressed) return
				new TWEEN.Tween(this.modelCell.object3D.position)
					.to({ x: -2.5 }, 1000)
					.start();

				new TWEEN.Tween(this.modelCell.object3D.position)
					.to({ y: 7.5 }, 1000)
					.start();

				new TWEEN.Tween(this.modelCell.object3D.scale)
					.to({ x: 10, y: 10, z: 10 }, 1000)
					.start();

				if (this.annotationATriggerBtn) {
					new TWEEN.Tween(this.annotationATriggerBtn.object3D.position)
						.to({ x: -1.7, y: 7.5, z: 2.2 }, 1000)
						.start();
				}

				setTimeout(() => {
					if (this.modelKaryotype) {
						this.modelKaryotype.object3D.visible = true;
					}
					if (this.annotationBTriggerBtn) {
						this.annotationBTriggerBtn.object3D.visible = true;
					}
					if (this.modelChromatid) {
						this.modelChromatid.object3D.visible = false;
					}
					if (this.annotationCTriggerBtn) {
						this.annotationCTriggerBtn.object3D.visible = false;
					}
					if (this.annotationDTriggerBtn) {
						this.annotationDTriggerBtn.object3D.visible = false;
					}
					if (this.modelDNA) {
						this.modelDNA.object3D.visible = false;
					}
					if (this.annotationETriggerBtn) {
						this.annotationETriggerBtn.object3D.visible = false;
					}
				}, 1100);
			}

			const largeChromosomesScene = () => {
				if (annotationC_Pressed) return
				if (this.modelKaryotype) {
					this.modelKaryotype.object3D.visible = true;
				}
				if (this.annotationBTriggerBtn) {
					this.annotationBTriggerBtn.object3D.visible = true;
				}
				if (this.modelChromatid) {
					this.modelChromatid.object3D.visible = true;
				}
				if (this.annotationCTriggerBtn) {
					this.annotationCTriggerBtn.object3D.visible = true;
				}
				if (this.annotationDTriggerBtn) {
					this.annotationDTriggerBtn.object3D.visible = true;
				}
				if (this.modelDNA) {
					this.modelDNA.object3D.visible = false;
				}
				if (this.annotationETriggerBtn) {
					this.annotationETriggerBtn.object3D.visible = false;
				}
			}

			const whiteLinesDNAScene = () => {
				if (this.modelKaryotype) {
					this.modelKaryotype.object3D.visible = true;
				}
				if (this.annotationBTriggerBtn) {
					this.annotationBTriggerBtn.object3D.visible = true;
				}
				if (this.modelDNA) {
					this.modelDNA.object3D.visible = true;
				}
				if (this.annotationETriggerBtn) {
					this.annotationETriggerBtn.object3D.visible = true;
				}
				if (this.modelChromatid) {
					this.modelChromatid.object3D.visible = true;
				}
				if (this.annotationCTriggerBtn) {
					this.annotationCTriggerBtn.object3D.visible = true;
				}
				if (this.annotationDTriggerBtn) {
					this.annotationDTriggerBtn.object3D.visible = true;
				}
				if (this.inChromatidDNA) {
					this.inChromatidDNA.visible = true;
				}
				this.currentClip = this.actionDNAspinning;
				this.actionDNAspinning.reset();
				this.actionDNAspinning.play();
			}

			const showLines = () => {
				if (this.genes) {
					this.genes.visible = true;
				}
			}

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			// when model is loaded adding recenter functionality
			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				this.el.sceneEl?.emit('recenter');
				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place-air', 'id: sceneHolder; offset: 0 -5 0');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);
					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}
			});

			this.annotationAButtonHandler = () => {
				annotationA_Pressed = true;
				chromosomesScene();
				if (this.onObjectSelected) {
					const title = 'Nucleus';
					const body = 'The nucleus of a eukaryotic cell contains its genetic material, DNA, in the form of chromosomes. Chromosomes are long, coiled up strands of DNA and proteins that carry genetic information in cells.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.annotationBButtonHandler = () => {
				annotationB_Pressed = true;
				largeChromosomesScene();
				if (this.onObjectSelected) {
					const title = 'Karyotype';
					const body = 'This is a karyotype, a visual representation of the human genome. There are 46 chromosomes, 23 inherited from each parent. A diploid cell has 2 sets of chromosomes, 1 inherited from each parent. Aside from sex cells, all human cells are all diploid.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}

			}

			this.annotationCButtonHandler = () => {
				annotationC_Pressed = true;
				whiteLinesDNAScene();
				if (this.onObjectSelected) {
					const title = 'Chromosome';
					const body = 'Each chromosome is made up of one DNA molecule. The DNA is highly coiled around proteins to fit within the nucleus.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.annotationDButtonHandler = () => {
				showLines();
				if (this.onObjectSelected) {
					const title = 'Genes';
					const body = 'Each chromosome contains genes. Genes are short DNA units that code for aminoacids that make up proteins. Proteins are the building blocks of cells and perform various functions in the body.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.annotationEButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = '<span class="smaller-title-annotation">Deoxyribonucleic acid (DNA)</span>';
					const body = 'DNA is a chemical molecule in the cells’ nuclei that makes up genes. The structure of DNA is a double helix, it looks like a twisted ladder. It carries genetic information that determines an organism’s characteristics: physical traits, behaviors, susceptibility to certain diseases, etc.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.annotationATriggerBtn = poolButtons.requestEntity();
				this.annotationATriggerBtn?.object3D.position.set(-1.7, 7.5, 2.2);
				this.annotationATriggerBtn?.object3D.scale.set(1.1, 1.1, 1.1);
				this.annotationATriggerBtn?.play();
				this.annotationATriggerBtn?.addEventListener('click', () => {
					this.annotationAButtonHandler();
					if (this.annotationATriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationATriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationATriggerBtn;
					}
				});

				this.annotationBTriggerBtn = poolButtons.requestEntity();
				this.annotationBTriggerBtn?.object3D.position.set(0.55, 1.7, 4.2);
				this.annotationBTriggerBtn?.object3D.scale.set(1.1, 1.1, 1.1);
				this.annotationBTriggerBtn?.play();
				this.annotationBTriggerBtn?.addEventListener('click', () => {
					this.annotationBButtonHandler();
					if (this.annotationBTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationBTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationBTriggerBtn;
					}
				});

				this.annotationCTriggerBtn = poolButtons.requestEntity();
				this.annotationCTriggerBtn?.object3D.position.set(2.55, 6.3, 0.2);
				this.annotationCTriggerBtn?.object3D.scale.set(1.1, 1.1, 1.1);
				this.annotationCTriggerBtn?.play();
				this.annotationCTriggerBtn?.addEventListener('click', () => {
					this.annotationCButtonHandler();
					if (this.annotationCTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationCTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationCTriggerBtn;
					}
				});

				this.annotationDTriggerBtn = poolButtons.requestEntity();
				this.annotationDTriggerBtn?.object3D.position.set(3.62, 6, 0.2);
				this.annotationDTriggerBtn?.object3D.scale.set(1.1, 1.1, 1.1);
				this.annotationDTriggerBtn?.play();
				this.annotationDTriggerBtn?.addEventListener('click', () => {
					this.annotationDButtonHandler();
					if (this.annotationDTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationDTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationDTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationDTriggerBtn;
					}
				});

				this.annotationETriggerBtn = poolButtons.requestEntity();
				this.annotationETriggerBtn?.object3D.position.set(0.85, 6.3, 0.3);
				this.annotationETriggerBtn?.object3D.scale.set(1.1, 1.1, 1.1);
				this.annotationETriggerBtn?.play();
				this.annotationETriggerBtn?.addEventListener('click', () => {
					this.annotationEButtonHandler();
					if (this.annotationETriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationETriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationETriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationETriggerBtn;
					}
				});
			}
		},
		tick(this: IChromosomesSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
			TWEEN.update();
		},
	},
};
export { ChromosomesScene as ChromosomesSceneComponent }