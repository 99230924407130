import { styled, Paper } from '@mui/material';
import { DisplayEnum } from 'shared/enums';

export const RoleMenuModal = styled(Paper, { name: 'RoleMenuModal' })({
  background: 'rgba(255, 255, 255, .5)',
  boxShadow: '0px 13px 99px #0000008A',
  backdropFilter: 'blur(10px)',
  width: '100%',
  height: '100%',
  padding: '40px',
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
    padding: '30px 16px',
  },
});
