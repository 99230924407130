import * as THREE from 'three';
import * as AFrame from 'aframe';
import { LinearSpline } from '../../../lib/utils/LinearSpline';
import { vertexShader as _VS, fragmentShader as _FS } from './shaders/spray-shaders';
import { IShaderFireAframe } from './shader-fire';

// this interface is used when passing chosen element id after swapping bottle
// which would in turn alter color of the particles fire
interface ElementSelectedEvent extends Event {
    elementId: number;
  }

interface ParticleData {
  position: THREE.Vector3;
  size: number;
  colour: THREE.Color;
  alpha: number;
  life: number;
  maxLife: number;
  rotation: number;
  velocity: THREE.Vector3;
  dragMultiplier: number;
}

export interface IShaderSprayAframe {
    rayLength: number;
    pointStartPosition: THREE.Vector3;
    aimSphereMaterial: THREE.MeshBasicMaterial;
    aimLineMaterial: THREE.LineBasicMaterial;
    aimColorInactive: THREE.Color;
    aimColorActive: THREE.Color;
    lastRaycastResult: boolean;
    cube: AFrame.Entity;
    burner: AFrame.Entity;
    raycaster: THREE.Raycaster;
    el: AFrame.Entity;
    particleData: ParticleData[];
    geometry: THREE.BufferGeometry;
    startColor: THREE.Color;
    endColor: THREE.Color;
    colourSpline: LinearSpline<THREE.Color>;
    alphaSpline: LinearSpline<number>;
    sizeSpline: LinearSpline<number>;
    points: THREE.Points;
    elapsedTimeSinceEmission: number;
    intervalCounter: number;
    interval?: ReturnType<typeof setInterval>;
    canSpray: boolean;
    fireHit: boolean;
    aimStart: THREE.Vector3;
    aimEnd: THREE.Vector3;
    tempVector: THREE.Vector3;
    tempQuaternion: THREE.Quaternion;  
    localForwardVector: THREE.Vector3;
    maxNumberOfParticles: number;
  
    spray: () => void;
    addParticles(): void;
    updateParticles(deltaTimeS: number): void;
    updateGeometry(): void;
    raycastAgainstFlame(this: IShaderSprayAframe): void;
  }


const shaderSprayComponent = {
  name: 'shader-spray',
  val: {
    init(this: IShaderSprayAframe): void {
        const imgUrl = require('./assets/fireBW.jpg')
        const fireTexture = new THREE.TextureLoader().load(imgUrl)
        const matShader = new THREE.ShaderMaterial({
          uniforms: {
            diffuseTexture: {value: fireTexture},
            pointMultiplier: {
                value: window.innerHeight / (2.0 * Math.tan(0.5 * 60.0 * Math.PI / 180.0))
            }
          },
          vertexShader: _VS,
          fragmentShader: _FS,
          blending: THREE.AdditiveBlending,
          depthTest: true,
          depthWrite: false,
          transparent: true,
          vertexColors: true,
        })
    
        // sphere geometry
        // const geometry3 = new THREE.SphereGeometry(0.1, 32, 32)
        // const matMeshStandard = new THREE.MeshStandardMaterial({color: 0xff0000})
        // this.sphere = new THREE.Mesh(geometry3, matMeshStandard)
        // this.sphere.scale.set(5, 5, 5)
        // this.sphere.position.set(1, 0, 0)
        // this.el.object3D.add(this.sphere)
    
        // points positions
        this.startColor = new THREE.Color(0x2020FF)
        this.endColor = new THREE.Color(0x2020FF)
        this.particleData = []

        this.geometry = new THREE.BufferGeometry()
        // this.matPoints = new THREE.PointsMaterial({color: 0xffffff, size: 1})
        
        // populate buffers with default values
        this.maxNumberOfParticles = 100
        const positions = []
        const sizes = []
        const colors = []
        const angles = []
        for (let i = this.maxNumberOfParticles; i > 0; i--) {
          positions.push(0, 0, 0)
          sizes.push(1)
          colors.push(1, 1, 1, 1)
          angles.push(0)
        }
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))
        this.geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1))
        this.geometry.setAttribute('colour', new THREE.Float32BufferAttribute(colors, 4))
        this.geometry.setAttribute('angle', new THREE.Float32BufferAttribute(angles, 1))

        this.pointStartPosition = new THREE.Vector3(0, 0.015, 0.075);
        this.tempVector = new THREE.Vector3(); // used in update to copy this.pointStartPosition
        this.tempQuaternion = new THREE.Quaternion(); // used in update to copy this.pointStartPosition
        this.localForwardVector = new THREE.Vector3(-0.5, 0, 1);
        this.rayLength = 0.50;

        this.points = new THREE.Points(this.geometry, matShader)
        // this.points.object3D.scale.set('5, 5, 5')
        this.points.position.set(this.pointStartPosition.x, this.pointStartPosition.y, this.pointStartPosition.z)
        this.points.scale.set(0.02, 0.02, 0.02)
        this.el.object3D.add(this.points)

        //aim setup 
        this.aimStart = new THREE.Vector3(this.pointStartPosition.x, this.pointStartPosition.y, this.pointStartPosition.z);
        this.aimEnd = new THREE.Vector3(this.pointStartPosition.x, this.pointStartPosition.y, this.pointStartPosition.z);
        this.aimEnd.add(this.localForwardVector.multiplyScalar(this.rayLength));

        let points = [];
        points.push(this.aimStart);
        points.push(this.aimEnd);
        
        // Create a BufferGeometry
        let geometry = new THREE.BufferGeometry().setFromPoints(points);

        this.aimColorActive = new THREE.Color(0xffd700);
        this.aimColorInactive = new THREE.Color(0x808080);
        
        // Create a LineBasicMaterial
        this.aimLineMaterial = new THREE.LineBasicMaterial({ color: this.aimColorInactive});
        
        // Create the line
        let aimLine = new THREE.Line(geometry, this.aimLineMaterial);
        // aimLine.visible = false;
        this.el.object3D.add(aimLine);

        this.aimSphereMaterial = new THREE.MeshBasicMaterial({ color: this.aimColorInactive});
        const aimSphereGeometry = new THREE.SphereGeometry(0.0025);
        const aimSphereMesh = new THREE.Mesh(aimSphereGeometry, this.aimSphereMaterial);
        aimSphereMesh.position.set(this.aimEnd.x, this.aimEnd.y, this.aimEnd.z);
        // aimSphereMesh.visible = false;
        this.el.object3D.add(aimSphereMesh);

        this.colourSpline = new LinearSpline((t, a, b) => {
          const c = a.clone()
          return c.lerp(b, t)
        })
        this.colourSpline.AddPoint(0.0, this.endColor)
        this.colourSpline.AddPoint(1.0, this.startColor)

        this.alphaSpline = new LinearSpline((t, a, b) => {
          return a + t * (b - a)
        })
        this.alphaSpline.AddPoint(0.0, 0.0)
        this.alphaSpline.AddPoint(0.4, 0.1)
        this.alphaSpline.AddPoint(0.9, 0.15)
        this.alphaSpline.AddPoint(1.0, 0)

        this.sizeSpline = new LinearSpline((t, a, b) => {
          return a + t * (b - a)
        })
        this.sizeSpline.AddPoint(0.0, 50.0 * 0.25)
        this.sizeSpline.AddPoint(1.0, 1.0 * 0.25)

        // raycasting setup
        this.raycaster = new THREE.Raycaster();  
        const scale = this.el.object3D.scale.x;
        this.raycaster.far = scale * this.rayLength; // how far to raycast
        // const camera = document.getElementById('camera') as AFrame.Entity;
        // const threeCamera = camera.getObject3D('camera') as THREE.Camera;
        this.burner = document.getElementById('burner') as AFrame.Entity;
        this.cube = document.getElementById('boxCollider') as AFrame.Entity;
        
        this.lastRaycastResult = false;
        this.fireHit = false;
        this.canSpray = true;
        this.spray = () => {
          if (this.canSpray) {
            // particle related
            this.fireHit = this.lastRaycastResult;
            if (this.fireHit) {
              const shaderFireComponent = this.burner.components['shader-fire'] as unknown as IShaderFireAframe;
              shaderFireComponent.changeColor();
              this.lastRaycastResult = true;
              setTimeout(() => {
                shaderFireComponent.addForce();
              }, 750)
            }
            
            this.addParticles()
            this.intervalCounter = 4
            this.canSpray = false
          }
        }
      },
      tick(this: IShaderSprayAframe, time: number, deltaTime: number): void {
        const deltaTimeS = deltaTime * 0.001;
        this.updateParticles(deltaTimeS);
        this.updateGeometry();
        this.raycastAgainstFlame();

        if (this.intervalCounter <= 0) {
            clearInterval(this.interval);
            this.canSpray = true;
            this.fireHit = false;
        }
      },
      addParticles(this: IShaderSprayAframe): void {
        const dragMultiplier = this.fireHit ? 2.5 : 1;
        this.interval = setInterval(() => {
            const n = Math.random() * 5
            for (let i = 0; i < n; i++) {
              const lifeRand = (Math.random() * 0.1 + 0.9) * 2
              
              this.particleData.push({
                position: new THREE.Vector3(
                    (Math.random() * 2 - 1) * 0.25,
                    (Math.random() * 2 - 1) * 0.25,
                    (Math.random() * 2 - 1) * 0.25
                    ),
                size: (Math.random() * 0.5 + 0.5) * 2.0,
                colour: new THREE.Color(),
                alpha: 1.0,
                life: lifeRand,
                maxLife: lifeRand,
                rotation: Math.random() * 2.0 * Math.PI,
                velocity: new THREE.Vector3(0, 0, 5),
                dragMultiplier: dragMultiplier,
              })
            }
            if (this.intervalCounter) {
                this.intervalCounter -= 1
            }
            
          }, 150)
      },
    
      updateParticles(this: IShaderSprayAframe, deltaTimeS: number): void {
         // Reusable vector to avoid new object creation
        const drag = new THREE.Vector3();

        for (let i = 0; i < this.particleData.length; i++) {
          const p = this.particleData[i];
          p.life = p.life - deltaTimeS;
          if (p.life <= 0.0) {
            this.particleData.splice(i, 1);
            i--;
            continue;
          }
        
          // update rotation
          p.rotation += deltaTimeS * 0.1;
        
          // movement
          p.position.add(p.velocity.clone().multiplyScalar(deltaTimeS));
        
          drag.copy(p.velocity);
          drag.multiplyScalar(deltaTimeS * 0.1 * p.dragMultiplier);
          drag.x = Math.sign(p.velocity.x) * Math.min(Math.abs(drag.x), Math.abs(p.velocity.x));
          drag.y = Math.sign(p.velocity.y) * Math.min(Math.abs(drag.y), Math.abs(p.velocity.y));
          drag.z = Math.sign(p.velocity.z) * Math.min(Math.abs(drag.z), Math.abs(p.velocity.z));
          p.velocity.sub(drag);
        
          const t = p.life / p.maxLife;
          p.alpha = this.alphaSpline.Get(t);
          p.size = this.sizeSpline.Get(t);
        }
      },
    
      updateGeometry(this: IShaderSprayAframe): void {
         const positionBuffer = this.geometry.getAttribute('position') as THREE.BufferAttribute
         const positions = positionBuffer.array as Float32Array;
         const sizeBuffer = this.geometry.getAttribute('size') as THREE.BufferAttribute
         const sizes = sizeBuffer.array as Float32Array;
         const colourBuffer = this.geometry.getAttribute('colour') as THREE.BufferAttribute
         const colours = colourBuffer.array as Float32Array;
         const angleBuffer = this.geometry.getAttribute('angle') as THREE.BufferAttribute
         const angles = angleBuffer.array as Float32Array;
 
         for (let i = 0; i < this.maxNumberOfParticles; i++) {
          if (this.particleData[i]) {
            positions[i * 3] = this.particleData[i].position.x
            positions[i * 3 + 1] = this.particleData[i].position.y
            positions[i * 3 + 2] = this.particleData[i].position.z
            sizes[i] = this.particleData[i].size
            colours[i * 4] = this.particleData[i].colour.r
            colours[i * 4 + 1] = this.particleData[i].colour.g
            colours[i * 4 + 2] = this.particleData[i].colour.b
            colours[i * 4 + 3] = this.particleData[i].alpha
            angles[i] = this.particleData[i].rotation
          } else {
            positions[i * 3] = 0
            positions[i * 3 + 1] = 0
            positions[i * 3 + 2] = 0
            sizes[i] = 0
            colours[i * 4] = 0
            colours[i * 4 + 1] = 0
            colours[i * 4 + 2] = 0
            colours[i * 4 + 3] = 0
            angles[i] = 0
          }
           
         }
 
 
         this.geometry.attributes.position.needsUpdate = true
         this.geometry.attributes.size.needsUpdate = true
         this.geometry.attributes.colour.needsUpdate = true
         this.geometry.attributes.angle.needsUpdate = true
      },
      raycastAgainstFlame(this: IShaderSprayAframe): void {
          // const worldPos = new THREE.Vector3();
          // this.el.object3D.getWorldPosition(worldPos);
          this.tempVector.copy(this.pointStartPosition);
          let worldPos = this.el.object3D.localToWorld(this.tempVector); 
         
          // Get the object's world rotation
          this.el.object3D.getWorldQuaternion(this.tempQuaternion);
          // Apply the object's world rotation to the local forward vector
          this.localForwardVector.set(-0.5, 0, 1);
          const worldForward = this.localForwardVector.applyQuaternion(this.tempQuaternion);
          // The result is the forward direction in world space
          const worldForwardDir = worldForward.normalize();
          // Define a distance to move forward
          // Create a new origin point for the raycast
          const newOrigin = worldPos.clone().add(worldForwardDir);
          // Set the raycaster's origin and direction
          this.raycaster.set(newOrigin, worldForwardDir);
          const intersects = this.raycaster.intersectObject(this.cube.object3D, true)
          
          if (intersects.length > 0 && !this.lastRaycastResult) {
            this.lastRaycastResult = true;
            this.aimLineMaterial.color = this.aimColorActive;
            this.aimSphereMaterial.color = this.aimColorActive;
            this.aimLineMaterial.needsUpdate = true;
            this.aimSphereMaterial.needsUpdate = true;
          } else if (intersects.length <= 0 && this.lastRaycastResult) {
            this.lastRaycastResult = false;
            this.aimLineMaterial.color = this.aimColorInactive;
            this.aimSphereMaterial.color = this.aimColorInactive;
            this.aimLineMaterial.needsUpdate = true;
            this.aimSphereMaterial.needsUpdate = true;
          }
      },
  },
};

export { shaderSprayComponent as ShaderSpray };
