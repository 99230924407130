import { TrainingTypeEnum } from './enums';

export const typeColorUtil = (type: string) => {
  switch (type) {
    case TrainingTypeEnum.Chemistry:
      return '153, 69, 255';
    case TrainingTypeEnum.Physics:
      return '30, 99, 190';
    case TrainingTypeEnum.Biology:
      return '33, 167, 111';
  }
};
