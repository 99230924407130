
import { AFrame } from 'aframe';
import { ta } from 'date-fns/locale';
import * as THREE from 'three';
import { string } from 'yup';

interface ITapPlaceAframeElement {
    el: AFrame['AEntity'];
    raycaster: THREE.Raycaster;
    ground: AFrame['AEntity'];
    threeCamera: THREE.Camera;
    rayOrigin: THREE.Vector2;
    cursorLocation: THREE.Vector3;
    data: {
      id: string;
      scale: string;
      offset: string;
      height: string;
      relativeRotation: string;
    };
    onClickHandler: () => void;
  }

const TapPlaceAirComponent = {
    name: 'tap-place-air',
    val: {
        schema: {
          id: { type: 'string', default: '' },
          scale: { type: 'string', default: '1 1 1' },
          offset: {type: 'string', default: '0 0 0'},
          height: {type: 'string', default: 'camera'}, //if no height is defined, using camera height
          relativeRotation: {type: 'string', default: '0 0 0'}, // rotation relative to the camera
        },
        init(this: ITapPlaceAframeElement) {
            const lessonStartEvent = new Event('lesson-start')
            this.raycaster = new THREE.Raycaster()
            const camera = document.getElementById('camera') as AFrame['AEntity'];
            this.threeCamera = camera.getObject3D('camera') as THREE.Camera;
            this.ground = document.getElementById('ground') as AFrame['AEntity'];
            // 2D coordinates of the raycast origin, in normalized device coordinates (NDC)---X and Y
            // components should be between -1 and 1.  Here we want the cursor in the center of the screen.
            this.rayOrigin = new THREE.Vector2(0, 0)
            this.cursorLocation = new THREE.Vector3(0, 0, 0)

            //minimise object when starting tap-place session
            const objectToPlace = document.getElementById(this.data.id) as AFrame['AEntity'] | null;
            if (objectToPlace) {
              objectToPlace.setAttribute('scale', '0.001 0.001 0.001');
            }

            const generateAnimationString = (property: string, fromScale: string, toScale: string, duration: number) => {
              return `property: ${property}; from: ${fromScale}; to: ${toScale}; dur: ${duration}; easing: linear`;
            }

            this.onClickHandler = () => {
              const objectToPlace = document.getElementById(this.data.id) as AFrame['AEntity'] | null;
              if (objectToPlace) {
      
                // placing model at location of the cursor
                const cursorPos = this.el.object3D.position;
                const [x, y, z] = this.data.offset.split(' ').map(parseFloat);
                const offset = new THREE.Vector3(x, y, z);
                const placementPosition = cursorPos.clone().add(offset);
                objectToPlace.setAttribute('position', placementPosition);
                // objectToPlace.setAttribute('scale', this.data.scale);
                // using animationto scale up object instead of setting scale directly

                // Apply the camera's Y-axis rotation to the object
                const [rx, ry, rz] = this.data.relativeRotation.split(' ').map(parseFloat);
                const cameraEl = this.el.sceneEl?.querySelector('a-camera');
                const cameraRotation = cameraEl?.getAttribute('rotation') as unknown as THREE.Euler;
						    const cameraYRotation = cameraRotation ? cameraRotation.y : 0;
                
                objectToPlace.setAttribute('rotation', {
                    x: 0,
                    y: cameraYRotation + ry,
                    z: 0
                });

                //animating scale
                const fromScale = '0.001 0.001 0.001';
                const toScale = this.data.scale;
                const duration = 350; // animation duration in milliseconds

                const animationString = generateAnimationString('scale', fromScale, toScale, duration);
                objectToPlace.setAttribute('animation', animationString);

                // animating height positioning
                const targetPosition = placementPosition.clone();
                if (this.data.height === 'camera') {
                    const cameraPosition = cameraEl?.getAttribute('position') as unknown as THREE.Vector3;
                    targetPosition.y = cameraPosition.y + offset.y;
                } else {
                    targetPosition.y = 1 * parseFloat(this.data.height);
                }
                
                const heightAnimationString = generateAnimationString('position', `${placementPosition.x} ${placementPosition.y} ${placementPosition.z}`, `${targetPosition.x} ${targetPosition.y} ${targetPosition.z}`, duration);
                objectToPlace.setAttribute('animation__height', heightAnimationString);
               
                objectToPlace.setAttribute('visible', 'true');
                // remove animation after their duration
                setTimeout(() => {
                  objectToPlace.removeAttribute('animation');
                  objectToPlace.removeAttribute('animation__height');
                }, duration + 1000);
      
                this.el.sceneEl?.dispatchEvent(lessonStartEvent); // start lesson experience
              } else {
                console.warn('Could not find element with ID: ', this.data.id, ' to place');
              }
            };
    
            this.el.sceneEl?.addEventListener('click', this.onClickHandler,{ once: true } as any);
        },
        tick(this: ITapPlaceAframeElement) {
            // Raycast from camera to 'ground'
            this.raycaster.setFromCamera(this.rayOrigin, this.threeCamera)
            const intersects = this.raycaster.intersectObject(this.ground.object3D, true)
            if (intersects.length > 0) {
              const [intersect] = intersects
              this.cursorLocation = intersect.point
            }
            this.el.object3D.position.y = 0.1
            this.el.object3D.position.lerp(this.cursorLocation, 0.4)
            this.el.object3D.rotation.y = this.threeCamera.rotation.y
        },
        // remove(this: ITapPlaceAframeElement) {
        //   this.el.sceneEl?.removeEventListener('click', this.onClickHandler);
        // },
    },
};
export { TapPlaceAirComponent as TapPlaceAir }