import { styled } from '@mui/material';
import { DisplayEnum } from '../enums';

export const StyledVideo = styled('video', { name: 'StyledVideo' })({
  width: '742px',
  height: '417px',
  borderRadius: '30px',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
    width: '100%',
    height: 'calc(100% + 2px)',
    minHeight: '215px'
  },
});
