import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CourseList } from './CourseList';
import { CourseStatistic } from './CourseStatistic';
import { DisplayEnum } from '../../../../shared/enums';
import { BackButton } from '../../../../shared/components/BackButton';
import { useTranslation } from 'react-i18next';

type Props = {
  loading: boolean;
};

export function CourseWrapperComponent(props: Props) {
  const { loading } = props;
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { t, i18n } = useTranslation();
  const i18nPath = 'COURSES_WRAPPER_COMPONENT.';

  return (
    <Stack width={xlDisplay ? 1180 : '100%'} mx='auto' boxSizing='border-box'>
      <Stack mb={3} mt={tablet ? 2 : 13}>
        <Stack display='flex' flexDirection='row' alignItems='center' mb={2}>
          <BackButton />
          <Typography
            fontSize={mobile ? 24 : 44}
            fontFamily='GilroyBold'
            color='#262626'
            ml={1}
          >
            {t(i18nPath + 'TITLE')}
          </Typography>
        </Stack>
        <Typography
          px={tablet ? 1.6 : 0}
          fontSize={mobile ? 16 : 18}
          fontFamily='GilroySemiBold'
          color='#000'
        >
          {t(i18nPath + 'DESCRIPTION')}
        </Typography>
      </Stack>
      <Stack
        mb={3}
        px={tablet ? 1.6 : 0}
        direction={mobile ? 'column' : 'row'}
        alignItems='flex-start'
        justifyContent='space-between'
      >
        <CourseStatistic />
        <CourseList loading={loading} />
      </Stack>
    </Stack>
  );
}
