import { useContext } from 'react';
import { TrainingsContext } from '../context';

export function useTrainingsContext() {
  const context = useContext(TrainingsContext);

  if (context === undefined)
    throw Error('Must be descendant of TrainingsContextProvider');

  return context;
}
