
import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IAnnotationSystemAframe } from "../../../lib/aframe/systems/annotation-system";
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IInteruptMessage } from '../../../lib/aframe/components/interupt-message';
import { IStateMachine } from './state-machine';

interface IHouseControl {
    isAnimationPlaying: boolean;
    BaClActions: THREE.AnimationAction[];
    HClActions: THREE.AnimationAction[];
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    mixer: THREE.AnimationMixer;
    foamCavityTriggerBtn: any;
    actionSpray: THREE.AnimationAction;
    actionDog: THREE.AnimationAction;
    actionRoll: THREE.AnimationAction;
    actionCurtains: THREE.AnimationAction;
    actionGlazing: THREE.AnimationAction;
    actionDoor: THREE.AnimationAction;
    stateMachine: IStateMachine;
    el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

const SceneControlComponent = {
    name: 'scene-control',
    val: {
        init(this: IHouseControl) {
            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                // console.log('model loaded');
                
                setupLiquidTextures();
                initialiseAnimations();

                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}

                // get state machine
                this.stateMachine = this.el.components['state-machine'] as unknown as IStateMachine;
                if (this.stateMachine) {
                    this.stateMachine.setState(0);
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                // console.log('Event recenter received')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: base; scale: 40 40 40; relativeRotation: 0 -90 0;');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });

            /**
             * Setting liquid textures to be transparent
             */
            const setupLiquidTextures = () => {
                // tube fluid
                const tubeFluid = this.el.object3D.getObjectByName('TubeFluid') as THREE.Mesh;
                const fluidMat = tubeFluid.material as THREE.MeshStandardMaterial;
                // fluidMat.transparent = true;
                // fluidMat.opacity = 0.25;
                // tubeFluid.visible = false;
                const dissolved = this.el.object3D.getObjectByName('TubeFluidDissolved') as THREE.Mesh;
                dissolved.visible = false;
                const dissolved1 = this.el.object3D.getObjectByName('TubeFluidDissolved001') as THREE.Mesh;
                dissolved1.visible = false;
                const dissolved2 = this.el.object3D.getObjectByName('TubeFluidDissolved002') as THREE.Mesh;
                dissolved2.visible = false;
                
                const dissolvedMaterial = dissolved.material as THREE.MeshStandardMaterial;
                const dissolveMat2 = new THREE.MeshStandardMaterial();
                dissolvedMaterial.copy(dissolveMat2);
                dissolved2.material = dissolveMat2;


            };

            /**
             * Funciton to execute when animation finished
             * Switching state to the next one
             */
            const onAnimationFinished = () => {
                this.stateMachine.nextState();
                this.isAnimationPlaying = false;
            };

            const createClickableAreas = () => {
                const interuptMessage = this.el.components['interupt-message'] as unknown as IInteruptMessage;

                const cube1 = document.createElement('a-entity');
                cube1.setAttribute('geometry', {
                  primitive: 'box',
                  height: 0.1,
                  width: 0.025,
                  depth: 0.032
                });
                cube1.setAttribute('position', '0 0.02 -0.0985');
                cube1.setAttribute('visible', 'false');
                cube1.setAttribute('class', 'cantap');
                cube1.addEventListener('click', () => {
                    if(!this.isAnimationPlaying) {
                        //making sure we are not playng animation at the moment
                        const state = this.stateMachine.getState();
                        if (state === 0) {
                            if (this.HClActions && !this.isAnimationPlaying) {
                                this.HClActions[0].reset();
                                this.HClActions[0].repetitions = 1;
                                this.HClActions[0].play();
                                this.isAnimationPlaying = true;
                                // deativate message if active
                                if (interuptMessage) {
                                    interuptMessage.deactivate();
                                }
                            }
                        } else if (state === 2 && !this.isAnimationPlaying) {
                            if (this.HClActions) {
                                this.HClActions[1].reset();
                                this.HClActions[1].repetitions = 1;
                                this.HClActions[1].play();
                                this.isAnimationPlaying = true;
                                // deativate message if active
                                if (interuptMessage) {
                                    interuptMessage.deactivate();
                                }
                            }
                        } else if (state === 4 && !this.isAnimationPlaying) {
                            if (this.HClActions) {
                                this.HClActions[2].reset();
                                this.HClActions[2].repetitions = 1;
                                this.HClActions[2].play();
                                this.isAnimationPlaying = true;
                                // deativate message if active
                                if (interuptMessage) {
                                    interuptMessage.deactivate();
                                }
                            }
                        } else {
                            if (interuptMessage) {
                                interuptMessage.swapImage(0);
                                interuptMessage.setPosition('-0.05 0.075 -0.1');
                                interuptMessage.activate();
                            }
                        }
                    };
                });

                // create cube A-Entity 2
                const cube2 = document.createElement('a-entity');
                cube2.setAttribute('geometry', {
                  primitive: 'box',
                  height: 0.1,
                  width: 0.025,
                  depth: 0.032
                });
                cube2.setAttribute('visible', 'false');
                cube2.setAttribute('position', '0 0.02 -0.157');
                cube2.setAttribute('class', 'cantap');
                cube2.addEventListener('click', () => {
                    if (!this.isAnimationPlaying) {
                        // making sure we are not in process of playing animation already
                        const state = this.stateMachine.getState();
                        if (state === 0 || state === 2 || state === 4) {
                            if (interuptMessage) {
                                interuptMessage.swapImage(1);
                                interuptMessage.setPosition('-0.05 0.075 -0.2');
                                interuptMessage.activate();
                            } else {
                                console.log('Interupt message not found');
                            }
                        } else if (state === 1 && !this.isAnimationPlaying) {
                            if (this.BaClActions) {
                                this.BaClActions[0].reset();
                                this.BaClActions[0].repetitions = 1;
                                this.BaClActions[0].play();
                                this.isAnimationPlaying = true;
                                activateWhiteSubstance(this.BaClActions[0]);
                                // deativate message if active
                                if (interuptMessage) {
                                    interuptMessage.deactivate();
                                }
                            }
                        } else if (state === 3 && !this.isAnimationPlaying) {
                            if (this.BaClActions) {
                                this.BaClActions[1].reset();
                                this.BaClActions[1].repetitions = 1;
                                this.BaClActions[1].play();
                                this.isAnimationPlaying = true;
                                // deativate message if active
                                if (interuptMessage) {
                                    interuptMessage.deactivate();
                                }
                            }
                        } else if (state === 5 && !this.isAnimationPlaying) {
                            if (this.BaClActions) {
                                this.BaClActions[2].reset();
                                this.BaClActions[2].repetitions = 1;
                                this.BaClActions[2].play();
                                this.isAnimationPlaying = true;
                                activateWhiteSubstance(this.BaClActions[2]);
                                // deativate message if active
                                if (interuptMessage) {
                                    interuptMessage.deactivate();
                                }
                            }
                        };
                    }
                    
                });

                this.mixer.addEventListener('finished', () => {
                    onAnimationFinished();
                });

                this.el.appendChild(cube1);
                this.el.appendChild(cube2);
            };

            const initialiseAnimations = () => {
                this.isAnimationPlaying = false
                const base = document.getElementById('base') as AFrame.Entity;
                if (base) {
                    const animatedEl = base.object3D.getObjectByName('Scene') as THREE.Object3D;
                    this.mixer = new THREE.AnimationMixer(animatedEl);
                    const [clip1, clip2, clip3, clip4, clip5, clip6] = animatedEl.animations;
                    const action1 = this.mixer.clipAction(clip1);
                    const action2 = this.mixer.clipAction(clip2);
                    const action3 = this.mixer.clipAction(clip3);
                    const action4 = this.mixer.clipAction(clip4);
                    const action5 = this.mixer.clipAction(clip5);
                    const action6 = this.mixer.clipAction(clip6);
                    this.HClActions = [action1, action2, action3];
                    this.BaClActions = [action4, action5, action6];
                    // create buttons to trigger animations
                    createClickableAreas();

                } else {
                    console.warn('Scene-control.tsx: Intialising animations - Entity with id: Scene is not found')
                }
                
                // this.mixer = new THREE.AnimationMixer(animatedEl)
                // const [clipDoor, clipGlazing, clipCurtains, clipRoll, clipDog, clipSpray] = animatedEl.animations
                // this.actionDoor = this.mixer.clipAction(clipDoor)
                // this.actionGlazing = this.mixer.clipAction(clipGlazing)
                // this.actionCurtains = this.mixer.clipAction(clipCurtains)
                // this.actionRoll = this.mixer.clipAction(clipRoll)
                // this.actionDog = this.mixer.clipAction(clipDog)
                // this.actionSpray = this.mixer.clipAction(clipSpray)
            }

            const HClButtonHandler = () => {
                const title = 'Hydrochloric Acid'
                const body = 'Hydrochloric acid is used first to acidify the solution, removing carbonate ions to avoid false positive results.<br/><i>Tap the bottle labelled “HCl” to add a few drops of hydrochloric acid to the unknown solution.</i>'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const BaClButtonHandler = () => {
                const title = 'Barium Chloride'
                const body = 'Barium ions react with sulfate ions to form barium sulfate - a white, insoluble precipitate.<br/><i>Tap the bottle labelled “BaCl<sub>2</sub>” to add a few drops of barium chloride to the unknown solution.<i/>'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const HClTriggerBtn = poolButtons.requestEntity();
                if (HClTriggerBtn) {
                    HClTriggerBtn.setAttribute('position', '0.01 0 -0.12');
                    HClTriggerBtn.play();
                    HClTriggerBtn.addEventListener('click', () => {
                        HClButtonHandler();
                        if (HClTriggerBtn) {
                            this.annotationComponent.setObjectToFollow(HClTriggerBtn);
                            if(this.currentDeactivatedButton) {
                                (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                            }
                            (HClTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                            this.currentDeactivatedButton = HClTriggerBtn
                        }
                    });
                }
               

                const BaClTriggerBtn = poolButtons.requestEntity()
                BaClTriggerBtn?.setAttribute('position', '0.01 0 -0.18')
                BaClTriggerBtn?.play()
                BaClTriggerBtn?.addEventListener('click', () => {
                    BaClButtonHandler();
                    if (BaClTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(BaClTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(BaClTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = BaClTriggerBtn
                    }
                });
            }

            /**
             * Activating white substance in the tube
             */
           
            const activateWhiteSubstance = (playedAction: THREE.AnimationAction) => {
                const stateId = this.stateMachine.getState();
                let fluidName;
                let originalFluidName;
            
                switch (stateId) {
                  case 1:
                    fluidName = 'TubeFluidDissolved';
                    break;
                  case 3:
                    fluidName = 'TubeFluidDissolved001';
                    break;
                  case 5:
                    fluidName = 'TubeFluidDissolved002';
                    break;
                  default:
                    return; // If stateId doesn't match, exit the function
                }
            
                const whiteFluid = this.el.object3D.getObjectByName(fluidName) as THREE.Mesh;
                const fluidMaterial = whiteFluid.material as THREE.MeshBasicMaterial;
            
                if (fluidMaterial) {
                  fluidMaterial.opacity = 0;
                  fluidMaterial.transparent = true;
                  whiteFluid.visible = true;
                
                  const targetOpacity = 1;
                  const animationDuration = 3500; // Duration in milliseconds
                  let startTime = performance.now();
                
                  const trackAnimationState = (timestamp: number) => {
                    // get the animation time
                    const actionTime = playedAction.time;
                    if(actionTime > 1.75) {
                        startTime = performance.now();
                        requestAnimationFrame(animateFluid); // start the fluid animation
                        return;
                    }

                    requestAnimationFrame(trackAnimationState);
                  };

                  const animateFluid = (timestamp: number) => {
                    // start animating white substance
                    const elapsed = timestamp - startTime;
                
                    if (elapsed >= animationDuration) {
                      fluidMaterial.opacity = targetOpacity;
                      // originalFluidMaterial.opacity = 0;
                      return; // Terminate the loop
                    }
                
                    // Calculate the current opacity based on the elapsed time
                    fluidMaterial.opacity = (elapsed / animationDuration) * targetOpacity;
                    // originalFluidMaterial.opacity = 1 - (elapsed / animationDuration) * targetOpacity;
                    // console.log('animate fluid')
                    requestAnimationFrame(animateFluid);
                  };
              
                  requestAnimationFrame(trackAnimationState);
                }
            };
  

        },
        tick(this: IHouseControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export { SceneControlComponent }