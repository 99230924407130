import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface ICellSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	actionRespiration: THREE.AnimationAction;
	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;
	el: AFrame.Entity;
	currentAssetId: number;
	poolButtons: PoolComponent;

	controlElement: AFrame.Entity;
	mitochondria: AFrame.Entity;
	cellulose: AFrame.Entity;
	protein: AFrame.Entity;
	starch: AFrame.Entity;
	fat: AFrame.Entity;
	H2O: THREE.Object3D<THREE.Object3DEventMap> | undefined

	mitochondriaTriggerBtn: AFrame.Entity | null;
	celluloseTriggerBtn: AFrame.Entity | null;
	proteinTriggerBtn: AFrame.Entity | null;
	starchTriggerBtn: AFrame.Entity | null;
	fatTriggerBtn: AFrame.Entity | null;

	sceneOneLight: AFrame.Entity;
	sceneTwoLight: AFrame.Entity;
	sceneThreeLight: AFrame.Entity;
	sceneFourLight: AFrame.Entity;
	sceneFiveLight: AFrame.Entity;

	mitochondriaButtonHandler: () => void;
	celluloseButtonHandler: () => void;
	proteinButtonHandler: () => void;
	starchButtonHandler: () => void;
	fatButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const PlantsGlucoseScene = {
	name: 'plants-glucose-scene',
	val: {
		init(this: ICellSceneAframe) {
			this.controlElement = document.getElementById('sceneHolder') as AFrame.Entity;

			this.sceneOneLight = document.getElementById('sceneOneLight') as AFrame.Entity;
			this.sceneTwoLight = document.getElementById('sceneTwoLight') as AFrame.Entity;
			this.sceneThreeLight = document.getElementById('sceneThreeLight') as AFrame.Entity;
			this.sceneFourLight = document.getElementById('sceneFourLight') as AFrame.Entity;
			this.sceneFiveLight = document.getElementById('sceneFiveLight') as AFrame.Entity;
			
			this.controlElement.addEventListener('model-loaded', (e) => {
				const customEvent = e as CustomEvent;
				const modelId = (customEvent.target as HTMLElement).id
				console.log('Loaded model', modelId);
				// only proceeding with the initialisation if the model is the one we want and nothing was yet initialised
				if (modelId !== 'mitochondria') {
					return;
				}

				const scene = this.el.sceneEl as AFrame.Scene & {
					systems: { "annotation-system": IAnnotationSystemAframe };
				};
				const annotationSystem = scene.systems["annotation-system"];
				this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
				this.el.setAttribute('annotation', '');
				this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}
				// moved here
				initialiseAnimations();
				firstScene();
			})

			this.mitochondria = document.getElementById('mitochondria') as AFrame.Entity;
			this.cellulose = document.getElementById('cellulose') as AFrame.Entity;
			this.protein = document.getElementById('protein') as AFrame.Entity;
			this.starch = document.getElementById('starch') as AFrame.Entity;
			this.fat = document.getElementById('fat') as AFrame.Entity;

			const firstScene = () => {
				this.mitochondria.object3D.visible = true;
				this.cellulose.object3D.visible = false;
				this.protein.object3D.visible = false;
				this.starch.object3D.visible = false;
				this.fat.object3D.visible = false;

				this.sceneOneLight.object3D.visible = true;
				this.sceneTwoLight.object3D.visible = false;
				this.sceneThreeLight.object3D.visible = false;
				this.sceneFourLight.object3D.visible = false;
				this.sceneFiveLight.object3D.visible = false;

				this.H2O = this.mitochondria.object3D.getObjectByName('H2O');
				if (this.H2O) {
					this.H2O.visible = false;
				}

				if (this.mitochondriaTriggerBtn) {
					this.mitochondriaTriggerBtn.object3D.visible = true;
					this.mitochondriaTriggerBtn.object3D.position.x = 0;
				}
				if (this.celluloseTriggerBtn) {
					this.celluloseTriggerBtn.object3D.visible = false;
					this.celluloseTriggerBtn.object3D.position.x = -8;
				}
				if (this.proteinTriggerBtn) {
					this.proteinTriggerBtn.object3D.visible = false;
					this.proteinTriggerBtn.object3D.position.x = -8;
				}
				if (this.starchTriggerBtn) {
					this.starchTriggerBtn.object3D.visible = false;
					this.starchTriggerBtn.object3D.position.x = -8;
				}
				if (this.fatTriggerBtn) {
					this.fatTriggerBtn.object3D.visible = false;
					this.fatTriggerBtn.object3D.position.x = -8;
				}
			}

			const secondScene = () => {
				this.mitochondria.object3D.visible = false;
				this.cellulose.object3D.visible = true;
				this.protein.object3D.visible = false;
				this.starch.object3D.visible = false;
				this.fat.object3D.visible = false;

				this.sceneOneLight.object3D.visible = false;
				this.sceneTwoLight.object3D.visible = true;
				this.sceneThreeLight.object3D.visible = false;
				this.sceneFourLight.object3D.visible = false;
				this.sceneFiveLight.object3D.visible = false;

				if (this.mitochondriaTriggerBtn) {
					this.mitochondriaTriggerBtn.object3D.visible = false;
					this.mitochondriaTriggerBtn.object3D.position.x = -8;
				}
				if (this.celluloseTriggerBtn) {
					this.celluloseTriggerBtn.object3D.visible = true;
					this.celluloseTriggerBtn.object3D.position.x = 0;
				}
				if (this.proteinTriggerBtn) {
					this.proteinTriggerBtn.object3D.visible = false;
					this.proteinTriggerBtn.object3D.position.x = -8;
				}
				if (this.starchTriggerBtn) {
					this.starchTriggerBtn.object3D.visible = false;
					this.starchTriggerBtn.object3D.position.x = -8;
				}
				if (this.fatTriggerBtn) {
					this.fatTriggerBtn.object3D.visible = false;
					this.fatTriggerBtn.object3D.position.x = -8;
				}
			}

			const thirdScene = () => {
				this.mitochondria.object3D.visible = false;
				this.cellulose.object3D.visible = false;
				this.protein.object3D.visible = true;
				this.starch.object3D.visible = false;
				this.fat.object3D.visible = false;

				this.sceneOneLight.object3D.visible = false;
				this.sceneTwoLight.object3D.visible = false;
				this.sceneThreeLight.object3D.visible = true;
				this.sceneFourLight.object3D.visible = false;
				this.sceneFiveLight.object3D.visible = false;

				if (this.mitochondriaTriggerBtn) {
					this.mitochondriaTriggerBtn.object3D.visible = false;
					this.mitochondriaTriggerBtn.object3D.position.x = -8;
				}
				if (this.celluloseTriggerBtn) {
					this.celluloseTriggerBtn.object3D.visible = false;
					this.celluloseTriggerBtn.object3D.position.x = -8;
				}
				if (this.proteinTriggerBtn) {
					this.proteinTriggerBtn.object3D.visible = true;
					this.proteinTriggerBtn.object3D.position.x = 0;
				}
				if (this.starchTriggerBtn) {
					this.starchTriggerBtn.object3D.visible = false;
					this.starchTriggerBtn.object3D.position.x = -8;
				}
				if (this.fatTriggerBtn) {
					this.fatTriggerBtn.object3D.visible = false;
					this.fatTriggerBtn.object3D.position.x = -8;
				}
			}

			const fourthScene = () => {
				this.mitochondria.object3D.visible = false;
				this.cellulose.object3D.visible = false;
				this.protein.object3D.visible = false;
				this.starch.object3D.visible = true;
				this.fat.object3D.visible = false;

				this.sceneOneLight.object3D.visible = false;
				this.sceneTwoLight.object3D.visible = false;
				this.sceneThreeLight.object3D.visible = false;
				this.sceneFourLight.object3D.visible = true;
				this.sceneFiveLight.object3D.visible = false;

				if (this.mitochondriaTriggerBtn) {
					this.mitochondriaTriggerBtn.object3D.visible = false;
					this.mitochondriaTriggerBtn.object3D.position.x = -8;
				}
				if (this.celluloseTriggerBtn) {
					this.celluloseTriggerBtn.object3D.visible = false;
					this.celluloseTriggerBtn.object3D.position.x = -8;
				}
				if (this.proteinTriggerBtn) {
					this.proteinTriggerBtn.object3D.visible = false;
					this.proteinTriggerBtn.object3D.position.x = -8;
				}
				if (this.starchTriggerBtn) {
					this.starchTriggerBtn.object3D.visible = true;
					this.starchTriggerBtn.object3D.position.x = 0;
				}
				if (this.fatTriggerBtn) {
					this.fatTriggerBtn.object3D.visible = false;
					this.fatTriggerBtn.object3D.position.x = -8;
				}
			}

			const fifthScene = () => {
				this.mitochondria.object3D.visible = false;
				this.cellulose.object3D.visible = false;
				this.protein.object3D.visible = false;
				this.starch.object3D.visible = false;
				this.fat.object3D.visible = true;

				this.sceneOneLight.object3D.visible = false;
				this.sceneTwoLight.object3D.visible = false;
				this.sceneThreeLight.object3D.visible = false;
				this.sceneFourLight.object3D.visible = false;
				this.sceneFiveLight.object3D.visible = true;

				if (this.mitochondriaTriggerBtn) {
					this.mitochondriaTriggerBtn.object3D.visible = false;
					this.mitochondriaTriggerBtn.object3D.position.x = -8;
				}
				if (this.celluloseTriggerBtn) {
					this.celluloseTriggerBtn.object3D.visible = false;
					this.celluloseTriggerBtn.object3D.position.x = -8;
				}
				if (this.proteinTriggerBtn) {
					this.proteinTriggerBtn.object3D.visible = false;
					this.proteinTriggerBtn.object3D.position.x = -8;
				}
				if (this.starchTriggerBtn) {
					this.starchTriggerBtn.object3D.visible = false;
					this.starchTriggerBtn.object3D.position.x = -8;
				}
				if (this.fatTriggerBtn) {
					this.fatTriggerBtn.object3D.visible = true;
					this.fatTriggerBtn.object3D.position.x = 0;
				}
			}

			this.fat.object3D.rotation.set(0, 60, 0);

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			// when model is loaded adding recenter functionality
			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				this.el.sceneEl?.emit('recenter');
				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place-air', 'id: sceneHolder; offset: 0 0 -3');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}
			});

			this.currentAssetId = 0;
			this.el.sceneEl?.addEventListener('asset-change', (event) => {
				const customEvent = event as CustomEvent; // Cast event to CustomEvent
				const newAssetId = customEvent.detail.assetId;
				switch (newAssetId) {
					case 0:
						firstScene();
						break;
					case 1:
						secondScene();
						break;
					case 2:
						thirdScene();
						break;
					case 3:
						fourthScene();
						break;
					case 4:
						fifthScene();
						break;
					default:
						return;
				}
			});

			const initialiseAnimations = () => {
				this.mitochondria.object3D.traverse((child) => {
					console.log(child);
				});
				const animatedEl = this.mitochondria.object3D.getObjectByName('Mitochondria_V2') as any;
				this.mixer = new THREE.AnimationMixer(animatedEl)
				const respirationClip = animatedEl.animations[0]
				this.actionRespiration = this.mixer.clipAction(respirationClip)
			};



			this.mitochondriaButtonHandler = () => {
				if (this.H2O) {
					this.H2O.visible = true;
				}
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionRespiration;
				this.actionRespiration.reset()
				this.actionRespiration.repetitions = 3
				this.actionRespiration.clampWhenFinished = false
				this.actionRespiration.play()
				if (this.onObjectSelected) {
					const title = 'Cellular Respiration';
					const body = 'Cellular respiration is the way living things gain energy. Plants have mitochondria that break down <b>glucose</b> in the presence of oxygen to produce energy in the form of ATP. Using the energy, a plant is able to grow and maintain its tissues.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.celluloseButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Cellulose';
					const body = 'Cellulose is a complex carbohydrate made up of multiple glucose molecules. Its function is to strengthen cell walls in plants. It helps plants to remain stiff and upright. Humans cannot digest cellulose, but it is important in the diet as fibre.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.proteinButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Protein';
					const body = 'Glucose and nitrate ions absorbed from the soil make amino acids, and many amino acids linked and folded together make proteins. Proteins have structural and functional roles, such as making enzymes, developing seeds, and aiding the plant’s immune system.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.starchButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Starch';
					const body = 'Starch is a complex carbohydrate made up of multiple glucose molecules. It is a way to store glucose in the plant. It is compact and insoluble in water. Starch breaks down into glucose for energy, for example, at night, or through winter months.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.fatButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Oil and Fat';
					const body = 'Oil and fats are other forms of stored energy sources. Plants store fats and oils in their seeds and fruits.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.mitochondriaTriggerBtn = poolButtons.requestEntity()
				this.mitochondriaTriggerBtn?.object3D.position.set(0, 1.2, -0.7)
				this.mitochondriaTriggerBtn?.object3D.scale.set(2, 2, 2)
				this.mitochondriaTriggerBtn?.play()
				this.mitochondriaTriggerBtn?.addEventListener('click', () => {
					this.mitochondriaButtonHandler();
					if (this.mitochondriaTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.mitochondriaTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.mitochondriaTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.mitochondriaTriggerBtn
					}
				});

				this.celluloseTriggerBtn = poolButtons.requestEntity()
				this.celluloseTriggerBtn?.object3D.position.set(0, 0.4, 0.5)
				this.celluloseTriggerBtn?.object3D.scale.set(2, 2, 2)
				this.celluloseTriggerBtn?.play()
				this.celluloseTriggerBtn?.addEventListener('click', () => {
					this.celluloseButtonHandler();
					if (this.celluloseTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.celluloseTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.celluloseTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.celluloseTriggerBtn
					}
				});


				this.proteinTriggerBtn = poolButtons.requestEntity()
				this.proteinTriggerBtn?.object3D.position.set(0, 0.7, 1.2)
				this.proteinTriggerBtn?.object3D.scale.set(2, 2, 2)
				this.proteinTriggerBtn?.play()
				this.proteinTriggerBtn?.addEventListener('click', () => {
					this.proteinButtonHandler();
					if (this.proteinTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.proteinTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.proteinTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.proteinTriggerBtn
					}
				});

				this.starchTriggerBtn = poolButtons.requestEntity()
				this.starchTriggerBtn?.object3D.position.set(0, 0.1, 0.1)
				this.starchTriggerBtn?.object3D.scale.set(2, 2, 2)
				this.starchTriggerBtn?.play()
				this.starchTriggerBtn?.addEventListener('click', () => {
					this.starchButtonHandler();
					if (this.starchTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.starchTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.starchTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.starchTriggerBtn
					}
				});

				this.fatTriggerBtn = poolButtons.requestEntity()
				this.fatTriggerBtn?.object3D.position.set(0, 0.8, 0)
				this.fatTriggerBtn?.object3D.scale.set(2, 2, 2)
				this.fatTriggerBtn?.play()
				this.fatTriggerBtn?.addEventListener('click', () => {
					this.fatButtonHandler();
					if (this.fatTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.fatTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.fatTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.fatTriggerBtn
					}
				});
			}

		},
		tick(this: ICellSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { PlantsGlucoseScene as PlantsGlucoseSceneComponent }