import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';


interface IAtomScene {
    poolEntity: AFrame.Entity;
    hydrogenGas: AFrame.Entity;
    water: AFrame.Entity;
    carbonDioxide: AFrame.Entity;
    hydrogenGasHandler: () => void;
    waterHandler: () => void;
    carbonDioxideHandler: () => void;
    hydrogenGasMixer: THREE.AnimationMixer;
    waterMixer: THREE.AnimationMixer;
    carbonDioxideMixer: THREE.AnimationMixer;
    currentAnimation: THREE.AnimationAction;
    currentRotateAnimation: THREE.AnimationAction;
    hydrogenGasAnimation: THREE.AnimationAction;
    hydrogenGasRotateAnimation: THREE.AnimationAction;
    waterAnimation: THREE.AnimationAction;
    waterRotateAnimation: THREE.AnimationAction;
    carbonDioxideAnimation: THREE.AnimationAction;
    carbonDioxideRotateAnimation: THREE.AnimationAction;
    annotationComponent: IAnnotationAframe;
    currentDeactivatedButton: AFrame.Entity;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    currentAssetId: number;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const AtomSceneComponent = {
    name: "atom-scene",
    val: {
        init(this: IAtomScene) {
            const holder = document.getElementById('holder') as AFrame.Entity;
            // Add 'model-loaded' event listener to the component
            holder.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                // ony initialise buttons once pool has loaded
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // moved button initialisation to lesson-start event
                // if initialising here, the buttons are not parenting to objects correctly
                // only applies to this lesson
            });


            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.hydrogenGas = document.getElementById('hydrogenGas') as AFrame.Entity
                this.water = document.getElementById('water') as AFrame.Entity
                this.carbonDioxide = document.getElementById('carbonDioxide') as AFrame.Entity
                this.annotationComponent.line.visible = false
                initialiseAnimations()
                if (!this.currentAssetId) {
                    this.currentAssetId = 0
                }
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

            });
            // add event listeners
            this.el.sceneEl?.addEventListener('anim-toggle', (e) => {
                const event = e as CustomEvent<{ toggle: boolean }>;
                if (event.detail.toggle) {
                    if (this.currentAnimation) this.currentAnimation.paused = false;
                    if (this.currentRotateAnimation) this.currentRotateAnimation.paused = false;
                } else {
                    if (this.currentAnimation) this.currentAnimation.paused = true;
                    if (this.currentRotateAnimation) this.currentRotateAnimation.paused = true;
                }
            });
            this.el.sceneEl?.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                this.currentAssetId = ce.detail.assetId;
                this.currentAnimation.stop()
                switch (this.currentAssetId) {
                    case 0:
                        this.hydrogenGas.object3D.visible = true
                        this.water.object3D.visible = false
                        this.carbonDioxide.object3D.visible = false
                        this.currentAnimation = this.hydrogenGasAnimation
                        this.currentRotateAnimation = this.hydrogenGasRotateAnimation
                        break;
                    case 1:
                        this.hydrogenGas.object3D.visible = false
                        this.water.object3D.visible = true
                        this.carbonDioxide.object3D.visible = false
                        this.currentAnimation = this.waterAnimation
                        this.currentRotateAnimation = this.waterRotateAnimation
                        break;
                    case 2:
                        this.hydrogenGas.object3D.visible = false
                        this.water.object3D.visible = false
                        this.carbonDioxide.object3D.visible = true
                        this.currentAnimation = this.carbonDioxideAnimation
                        this.currentRotateAnimation = this.carbonDioxideRotateAnimation
                        break;
                }
                this.currentRotateAnimation.stop()
                this.currentRotateAnimation.play()
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: holder; scale: 5 5 5; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });


            const initialiseAnimations = () => {
                const animLithiumChlorideEl = this.hydrogenGas.object3D.getObjectByName('Scene') as any;
                this.hydrogenGasMixer = new THREE.AnimationMixer(animLithiumChlorideEl)
                const [LithiumChloride, LithiumChlorideRotate] = animLithiumChlorideEl.animations
                this.hydrogenGasAnimation = this.hydrogenGasMixer.clipAction(LithiumChloride)
                this.hydrogenGasRotateAnimation = this.hydrogenGasMixer.clipAction(LithiumChlorideRotate)
                this.hydrogenGasRotateAnimation.play()

                const animSodiumOxideEl = this.water.object3D.getObjectByName('Scene') as any;
                this.waterMixer = new THREE.AnimationMixer(animSodiumOxideEl)
                const [SodiumOxide, SodiumOxideRotate] = animSodiumOxideEl.animations
                this.waterAnimation = this.waterMixer.clipAction(SodiumOxide)
                this.waterRotateAnimation = this.waterMixer.clipAction(SodiumOxideRotate)
                this.waterRotateAnimation.play()

                const animPotassiumHydroxideEl = this.carbonDioxide.object3D.getObjectByName('Scene') as any;
                this.carbonDioxideMixer = new THREE.AnimationMixer(animPotassiumHydroxideEl)
                const [PotassiumHydroxide, PotassiumHydroxideRotate] = animPotassiumHydroxideEl.animations
                this.carbonDioxideAnimation = this.carbonDioxideMixer.clipAction(PotassiumHydroxide)
                this.carbonDioxideRotateAnimation = this.carbonDioxideMixer.clipAction(PotassiumHydroxideRotate)
                this.carbonDioxideRotateAnimation.play()
                
                this.currentAnimation = this.hydrogenGasAnimation
                this.currentRotateAnimation = this.hydrogenGasRotateAnimation
            }

            this.hydrogenGasHandler = () => {
                if (this.currentAnimation && !this.currentAnimation.isRunning()) {
                    this.currentAnimation.stop()
                    this.currentAnimation.play()
                }
                if (this.currentRotateAnimation && !this.currentRotateAnimation.isRunning()) {
                    this.currentRotateAnimation.stop()
                    this.currentRotateAnimation.play()
                }
                if (this.onObjectSelected) {
                    const title = 'Hydrogen Gas';
                    const body = `You formed the compound hydrogen gas! Hydrogen is a diatomic molecule that exists. The balanced equation for this reaction is: <div style='display: flex; flex-direction: row; font-size: 20px; width: 100%; text-align: center; justify-content: space-around; padding: 20px'> <div><b><div>H</div></b></div><div>+</div><div><b><div>H</div></b></div><div>→</div><div><b><div>H<sub>2</sub></div></b></div></div>`;
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.waterHandler = () => {
                if (this.currentAnimation && !this.currentAnimation.isRunning()) {
                    this.currentAnimation.stop()
                    this.currentAnimation.play()
                }
                if (this.currentRotateAnimation && !this.currentRotateAnimation.isRunning()) {
                    this.currentRotateAnimation.stop()
                    this.currentRotateAnimation.play()
                }
                if (this.onObjectSelected) {
                    const title = 'Water';
                    const body = 'You formed the compound water! The balanced equation for this reaction is: <div style=\'display: flex; flex-direction: row; font-size: 20px; width: 100%; text-align: center; justify-content: space-around; padding: 20px\'> <div><b><div>2H<sub>2</sub></div></b></div><div>+</div><div><b><div>O<sub>2</sub></div></b></div><div>→</div><div><b><div>2H<sub>2</sub>O</div></b></div></div>';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.carbonDioxideHandler = () => {
                if (this.currentAnimation && !this.currentAnimation.isRunning()) {
                    this.currentAnimation.stop()
                    this.currentAnimation.play()
                }
                if (this.currentRotateAnimation && !this.currentRotateAnimation.isRunning()) {
                    this.currentRotateAnimation.stop()
                    this.currentRotateAnimation.play()
                }
                if (this.onObjectSelected) {
                    const title = 'Carbon Dioxide';
                    const body = 'You formed the compound carbon dioxide! The balanced equation for this reaction is: <div style=\'display: flex; flex-direction: row; font-size: 20px; width: 100%; text-align: center; justify-content: space-around; padding: 20px\'> <div><b><div>C</div></b></div><div>+</div><div><b><div>O<sub>2</sub></div></b></div><div>→</div><div><b><div>CO<sub>2</sub></div></b></div></div>';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }


            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const sodiumOxideTriggerBtn = poolButtons.requestEntity()
                sodiumOxideTriggerBtn?.setAttribute('position', '-0.045 0.035 0.1')
                sodiumOxideTriggerBtn?.setAttribute('scale', '2 2 2')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        sodiumOxideTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (this.currentAssetId === 1) {
                        sodiumOxideTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        sodiumOxideTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                sodiumOxideTriggerBtn?.play()
                sodiumOxideTriggerBtn?.addEventListener('click', () => {
                    this.hydrogenGasHandler()
                    if (sodiumOxideTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(sodiumOxideTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (sodiumOxideTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = sodiumOxideTriggerBtn
                    }
                });
                const magnesiumOxideTriggerButton = poolButtons.requestEntity();
                magnesiumOxideTriggerButton?.setAttribute('position', '-0.245 0.035 0.1');
                magnesiumOxideTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        magnesiumOxideTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        magnesiumOxideTriggerButton?.setAttribute('scale', '2 2 2')
                    }
                    if (this.currentAssetId === 2) {
                        magnesiumOxideTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                magnesiumOxideTriggerButton?.play()
                magnesiumOxideTriggerButton?.addEventListener('click', () => {
                    this.waterHandler()
                    if (magnesiumOxideTriggerButton) {
                        this.annotationComponent.setObjectToFollow(magnesiumOxideTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (magnesiumOxideTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = magnesiumOxideTriggerButton
                    }
                });
                const magnesiumChlorideTriggerButton = poolButtons.requestEntity();
                magnesiumChlorideTriggerButton?.setAttribute('position', '-0.245 0.035 0.1');
                magnesiumChlorideTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        magnesiumChlorideTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        magnesiumChlorideTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        magnesiumChlorideTriggerButton?.setAttribute('scale', '2 2 2')
                    }
                });
                magnesiumChlorideTriggerButton?.play()
                magnesiumChlorideTriggerButton?.addEventListener('click', () => {
                    this.carbonDioxideHandler()
                    if (magnesiumChlorideTriggerButton) {
                        this.annotationComponent.setObjectToFollow(magnesiumChlorideTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (magnesiumChlorideTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = magnesiumChlorideTriggerButton
                    }
                });

            };
        },
        tick(this: IAtomScene, time: number, timeDelta: number) {
            if (this.hydrogenGasMixer) {
                this.hydrogenGasMixer.update(timeDelta / 1000);
            }
            if (this.waterMixer) {
                this.waterMixer.update(timeDelta / 1000);
            }
            if (this.carbonDioxideMixer) {
                this.carbonDioxideMixer.update(timeDelta / 1000);
            }
        },
    }
}
export {AtomSceneComponent as AtomScene}
