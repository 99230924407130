import * as AFrame from 'aframe';
import { Object3D } from 'three';
import * as TWEEN from '@tweenjs/tween.js';

export interface IStateMachine {
    setState(newState: number): void;
    getState(): number;
    nextState(): void;
    state: number;
    el: AFrame.Entity;

};

const StateMachineComponent = {
    name: 'state-machine',
    val: {
        init(this: IStateMachine) {
        },
        setState(this: IStateMachine, newState: number) {
            this.state = newState;
            const solutionA = this.el.object3D.getObjectByName('TestTube_SolutionA') as Object3D;
            const solutionB = this.el.object3D.getObjectByName('TestTube_SolutionB') as Object3D;
            const solutionC = this.el.object3D.getObjectByName('TestTube_SolutionC') as Object3D;
            switch (this.state) {
                case 0:
                    // set the tubes to be less visible
                    // solutionB.visible = false;
                    // solutionC.visible = false;
                    solutionB.scale.set(0.001, 0.001, 0.001);
                    solutionC.scale.set(0.001, 0.001, 0.001);
                    break;
                case 2:
                    //solutionA.visible = false;
                    solutionB.visible = true;
                    new TWEEN.Tween(solutionB.scale)
                        .to({ x: 1, y: 1, z: 1 }, 300)
                        .start();
                    //solutionC.visible = false;
                    break;
                case 4:
                    // solutionA.visible = false;
                    // solutionB.visible = false;
                    new TWEEN.Tween(solutionC.scale)
                        .to({ x: 1, y: 1, z: 1 }, 300)
                        .start();
                    break;
                case 6:
                    // solutionA.visible = true;
                    // solutionB.visible = true;
                    // solutionC.visible = true;
                    break;
                default:

                    break;
            };
        },
        nextState(this: IStateMachine) {
            this.setState(this.state + 1);
        },
        getState(this: IStateMachine) {
            return this.state;
        }, 
        tick() {
            TWEEN.update();
        },
        
    },
};
export {StateMachineComponent as StateMachine};