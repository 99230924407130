import * as THREE from 'three';
import * as AFrame from 'aframe';
import { LinearSpline } from '../../../lib/utils/LinearSpline';
import { vertexShader as _VS, fragmentShader as _FS } from './shaders/fire-shaders';

// this interface is used when passing chosen element id after swapping bottle
// which would in turn alter color of the particles fire
interface ElementSelectedEvent extends Event {
    elementId: number;
  }

interface ParticleData {
  position: THREE.Vector3;
  size: number;
  colour: THREE.Color;
  alpha: number;
  life: number;
  maxLife: number;
  rotation: number;
  velocity: THREE.Vector3;
}

export interface IShaderFireAframe {
    fireOn: boolean;
    maxNumberOfParticles: number;
    assetChanged: (assetId: number) => void;
    currentElementColor: number;
    changeColor: () => void;
    forceFactor: number;
    el: AFrame.Entity;
    particleData: ParticleData[];
    geometry: THREE.BufferGeometry;
    startColor: THREE.Color;
    endColor: THREE.Color;
    colourSpline: LinearSpline<THREE.Color>;
    alphaSpline: LinearSpline<number>;
    sizeSpline: LinearSpline<number>;
    forceColorSpline: LinearSpline<THREE.Color>;
    points: THREE.Points;
    forceAdded: boolean;
    forceMaxTime: number;
    forceTime: number;
    forceVector: THREE.Vector3;
    colors: number[];
    elapsedTimeSinceEmission: number;
    // gdfsghk?: number; // Rename or remove if not needed
  
    setForceColorSpline(color: number): void;
    addForce(): void;
    addParticles(deltaTimeS: number): void;
    updateParticles(deltaTimeS: number): void;
    updateGeometry(): void;
    updateParticleWithForce: (p: ParticleData, tempVec1: THREE.Vector3, deltaTimeS: number) => void;
    updateParticleWithoutForce: (p: ParticleData, tempVec1: THREE.Vector3, deltaTimeS: number) => void;
    setVisibility: (visible: boolean) => void;
    setFireActive: (active: boolean) => void;
    setPosition: (x: number, y: number, z: number) => void;
  }


const shaderFireComponent = {
  name: 'shader-fire',
  val: {
    init(this: IShaderFireAframe): void {
        const imgUrl = require('./assets/fireBW.jpg')
        const fireTexture = new THREE.TextureLoader().load(imgUrl)
        const col = new THREE.Color(0xffffff)
        this.fireOn = false;
    
        const matShader = new THREE.ShaderMaterial({
          uniforms: {
            diffuseTexture: {value: fireTexture},
            pointMultiplier: {
                value: window.innerHeight / (2.0 * Math.tan(0.5 * 60.0 * Math.PI / 180.0))
            }
          },
          vertexShader: _VS,
          fragmentShader: _FS,
          blending: THREE.AdditiveBlending,
          depthTest: true,
          depthWrite: false,
          transparent: true,
          vertexColors: true,
        })
    
        // sphere geometry
        // const geometry3 = new THREE.SphereGeometry(0.1, 32, 32)
        // const matMeshStandard = new THREE.MeshStandardMaterial({color: 0xff0000})
        // this.sphere = new THREE.Mesh(geometry3, matMeshStandard)
        // this.sphere.scale.set(5, 5, 5)
        // this.sphere.position.set(1, 0, 0)
        // this.el.object3D.add(this.sphere)
    
        // points positions
        const numberOfParticles = 30
        const particleLife = 5
        this.startColor = new THREE.Color(0x2020FF)
        this.endColor = new THREE.Color(0x2020FF)
        this.particleData = []
    
        this.geometry = new THREE.BufferGeometry()
        // populate buffers with default values
        this.maxNumberOfParticles = 500
        const positions = []
        const sizes = []
        const colors = []
        const angles = []
        for (let i = this.maxNumberOfParticles; i > 0; i--) {
          positions.push(0, 0, 0)
          sizes.push(1)
          colors.push(1, 1, 1, 1)
          angles.push(0)
        }
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))
        this.geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1))
        this.geometry.setAttribute('colour', new THREE.Float32BufferAttribute(colors, 4))
        this.geometry.setAttribute('angle', new THREE.Float32BufferAttribute(angles, 1))
        // this.matPoints = new THREE.PointsMaterial({color: 0xffffff, size: 1})
    
        this.points = new THREE.Points(this.geometry, matShader)
        // this.points.object3D.scale.set('5, 5, 5')
        this.points.position.set(0.15, 0.112, 0)
        this.points.scale.set(0.005, 0.005, 0.005)
        this.el.object3D.add(this.points)
    
        this.colourSpline = new LinearSpline((t, a, b) => {
          const c = a.clone()
          return c.lerp(b, t)
        })
        this.colourSpline.AddPoint(0.0, this.endColor)
        this.colourSpline.AddPoint(1.0, this.startColor)
    
        this.alphaSpline = new LinearSpline((t, a, b) => {
          return a + t * (b - a)
        })
        this.alphaSpline.AddPoint(0.0, 0.0)
        this.alphaSpline.AddPoint(0.4, 1)
        this.alphaSpline.AddPoint(0.9, 1)
        this.alphaSpline.AddPoint(1.0, 0)
    
        this.sizeSpline = new LinearSpline((t, a, b) => {
          return a + t * (b - a)
        })
        this.sizeSpline.AddPoint(0.0, 1.0 * 0.35)
        this.sizeSpline.AddPoint(0.5, 7.5 * 0.35)
        this.sizeSpline.AddPoint(1.0, 10.0 * 0.35)
    
    
        this.setForceColorSpline = (color) => {
          this.forceColorSpline = new LinearSpline((t, a, b) => {
            const c = a.clone()
            return c.lerp(b, t)
          })
          this.forceColorSpline.AddPoint(1, new THREE.Color(color))
          this.forceColorSpline.AddPoint(0.3, new THREE.Color(color))
          this.forceColorSpline.AddPoint(0, this.startColor)
        }
        
    
        this.addForce = () => {
          this.forceAdded = true
          const forceDuratio = 2
          this.forceMaxTime = forceDuratio
          this.forceTime = forceDuratio
          this.forceVector = new THREE.Vector3(-1, 0.75, -1)
        }
        
        this.colors = [0xDC143C, 0x0d5c38, 0xFF2020, 0xFFFF20, 0x341948];
  
        // this.setForceColorSpline(this.currentElementColor);

        this.changeColor = () => {
          // console.log('SHADERFIRE.ts: changeColor')
          this.setForceColorSpline(this.currentElementColor);
        }

        this.assetChanged = (assetId: number) => {
          // console.log('SHADERFIRE.ts: assetChanged: ', assetId);
          this.currentElementColor = this.colors[assetId];
        } 

        // set initial asset to 0 index
        this.assetChanged(0);

        // this.el.sceneEl?.addEventListener('element-selected', (e) => {
        //   const customEvent = e as ElementSelectedEvent;
        //   this.currentElementColor = this.colors[customEvent.elementId];
        //   // this.setForceColorSpline(this.currentElementColor);
        // })
      },
      tick(this: IShaderFireAframe, time: number, deltaTime: number): void {
        const deltaTimeS = deltaTime * 0.001;
        this.addParticles(deltaTimeS);
        this.updateParticles(deltaTimeS);
        this.updateGeometry();
      },
      addParticles(this: IShaderFireAframe, deltaTimeS: number): void {
        if (!this.fireOn) return;
        if (!this.elapsedTimeSinceEmission) {
            this.elapsedTimeSinceEmission = 0.0
          }
          this.elapsedTimeSinceEmission += deltaTimeS
          const n = Math.floor(this.elapsedTimeSinceEmission * 125.0)
          this.elapsedTimeSinceEmission -= n / 75.0
          
          const tempVec = new THREE.Vector3(); // Temporary vector to avoid creating new instances
          for (let i = 0; i < n; i++) {
            const lifeRand = (Math.random() * 0.1 + 0.9) * 1
            tempVec.set(
              (Math.random() * 2 - 1) * 0.5,
              (Math.random() * 2 - 1) * 0.5,
              (Math.random() * 2 - 1) * 0.5
            );
            this.particleData.push({
              position: tempVec.clone(),
              size: (Math.random() * 0.5 + 0.5) * 2.0,
              colour: new THREE.Color(),
              alpha: 1.0,
              life: lifeRand,
              maxLife: lifeRand,
              rotation: Math.random() * 2.0 * Math.PI,
              velocity: new THREE.Vector3(0, 15, 0),
            })
          }
      },
      updateParticles(this: IShaderFireAframe, deltaTimeS: number): void {
        const tempVec1 = new THREE.Vector3(); // Temporary vector for vector operations
    
        for (let i = 0; i < this.particleData.length; i++) {
            this.particleData[i].life -= deltaTimeS;
        }
    
        this.particleData = this.particleData.filter((p) => p.life > 0.0);
    
        if (this.forceAdded && this.forceTime && this.forceMaxTime) {
            this.forceTime -= deltaTimeS;
            this.forceFactor = this.forceTime / this.forceMaxTime;
            if (this.forceTime <= 0) {
                this.forceAdded = false;
            }
        }
    
        if (this.forceAdded && this.forceColorSpline && this.forceVector) {
            for (let i = 0; i < this.particleData.length; i++) {
                const p = this.particleData[i];
                this.updateParticleWithForce(p, tempVec1, deltaTimeS);
            }
        } else {
            for (let i = 0; i < this.particleData.length; i++) {
                const p = this.particleData[i];
                this.updateParticleWithoutForce(p, tempVec1, deltaTimeS);
            }
        }

        // particle sorting
          // particlePositions.sort((a, b) => {
          //  const d1 = this.el.sceneEl.camera.el.object3D.position.distanceTo(this.points.localToWorld(a.position))
          //  const d2 = this.el.sceneEl.camera.el.object3D.position.distanceTo(this.points.localToWorld(b.position))
          //  if (d1 > d2) {
          //    return -1
          //  }
          //  if (d1 < d2) {
          //    return 1
          //  }
          //  return 0
          //})
    },
    updateParticleWithForce(this: IShaderFireAframe, p: ParticleData, tempVec1: THREE.Vector3, deltaTimeS: number) {
        // Update rotation
        p.rotation += deltaTimeS * 0.5;
    
        // Movement
        tempVec1.copy(p.velocity).multiplyScalar(deltaTimeS);
        p.position.add(tempVec1);
        tempVec1.copy(p.velocity).multiplyScalar(deltaTimeS * 0.25);
        tempVec1.x = Math.sign(p.velocity.x) * Math.min(Math.abs(tempVec1.x), Math.abs(p.velocity.x));
        tempVec1.y = Math.sign(p.velocity.y) * Math.min(Math.abs(tempVec1.y), Math.abs(p.velocity.y));
        tempVec1.z = Math.sign(p.velocity.z) * Math.min(Math.abs(tempVec1.z), Math.abs(p.velocity.z));
        p.velocity.sub(tempVec1);
    
        const t = p.life / p.maxLife;
        p.alpha = this.alphaSpline.Get(t);
        p.size = this.sizeSpline.Get(t);
    
        p.colour.copy(this.forceColorSpline.Get(this.forceFactor));
        // Apply force
        tempVec1.copy(this.forceVector).multiplyScalar(this.forceFactor * Math.random() * 1.5);
        p.velocity.add(tempVec1);
        // Size multiplication
        p.size += p.size * this.forceFactor * Math.random();
    },
    updateParticleWithoutForce(this: IShaderFireAframe, p: ParticleData, tempVec1: THREE.Vector3, deltaTimeS: number) {
        // Update rotation
        p.rotation += deltaTimeS * 0.5;
    
        // Movement
        tempVec1.copy(p.velocity).multiplyScalar(deltaTimeS);
        p.position.add(tempVec1);
        tempVec1.copy(p.velocity).multiplyScalar(deltaTimeS * 0.25);
        tempVec1.x = Math.sign(p.velocity.x) * Math.min(Math.abs(tempVec1.x), Math.abs(p.velocity.x));
        tempVec1.y = Math.sign(p.velocity.y) * Math.min(Math.abs(tempVec1.y), Math.abs(p.velocity.y));
        tempVec1.z = Math.sign(p.velocity.z) * Math.min(Math.abs(tempVec1.z), Math.abs(p.velocity.z));
        p.velocity.sub(tempVec1);
      
        const t = p.life / p.maxLife;
        p.alpha = this.alphaSpline.Get(t);
        p.size = this.sizeSpline.Get(t);
        p.colour.copy(this.colourSpline.Get(t));
      },
      updateGeometry(this: IShaderFireAframe): void {
        const positionBuffer = this.geometry.getAttribute('position') as THREE.BufferAttribute
        const positions = positionBuffer.array as Float32Array;
        const sizeBuffer = this.geometry.getAttribute('size') as THREE.BufferAttribute
        const sizes = sizeBuffer.array as Float32Array;
        const colourBuffer = this.geometry.getAttribute('colour') as THREE.BufferAttribute
        const colours = colourBuffer.array as Float32Array;
        const angleBuffer = this.geometry.getAttribute('angle') as THREE.BufferAttribute
        const angles = angleBuffer.array as Float32Array;
        
        for (let i = 0; i < this.maxNumberOfParticles; i++) {
         if (this.particleData[i]) {
           positions[i * 3] = this.particleData[i].position.x
           positions[i * 3 + 1] = this.particleData[i].position.y
           positions[i * 3 + 2] = this.particleData[i].position.z
           sizes[i] = this.particleData[i].size
           colours[i * 4] = this.particleData[i].colour.r
           colours[i * 4 + 1] = this.particleData[i].colour.g
           colours[i * 4 + 2] = this.particleData[i].colour.b
           colours[i * 4 + 3] = this.particleData[i].alpha
           angles[i] = this.particleData[i].rotation
         } else {
           positions[i * 3] = 0
           positions[i * 3 + 1] = 0
           positions[i * 3 + 2] = 0
           sizes[i] = 0
           colours[i * 4] = 0
           colours[i * 4 + 1] = 0
           colours[i * 4 + 2] = 0
           colours[i * 4 + 3] = 0
           angles[i] = 0
         }
        }
        this.geometry.attributes.position.needsUpdate = true
        this.geometry.attributes.size.needsUpdate = true
        this.geometry.attributes.colour.needsUpdate = true
        this.geometry.attributes.angle.needsUpdate = true
      },
      setVisibility(this: IShaderFireAframe, visible: boolean) {
        this.points.visible = visible;
      },
      setFireActive(this: IShaderFireAframe, active: boolean) {
        this.fireOn = active;
      },
      setPosition(this: IShaderFireAframe, x: number, y: number, z: number) {
        this.points.position.set(x, y, z);
      }
  },
};

export {shaderFireComponent as ShaderFire}


