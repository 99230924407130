import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface IHRDiagramsControl {
    poolEntity: AFrame.Entity;
    theMainSequenceHandler: () => void;
    theSunHandler: () => void;
    redGiantsHandler: () => void;
    whiteDwarfsHandler: () => void;
    theYAxisHandler: () => void;
    theXAxisHandler: () => void;
    spectralClassHandler: () => void;
    gHandler: () => void;
    absorptionLinesHandler: () => void;
    spectralClassTriggerButton: AFrame.Entity;
    absorptionLinesTriggerButton: AFrame.Entity;
    mixer: THREE.AnimationMixer;
    pressSwitchAnimation: THREE.AnimationAction;
    scaleUpInfoAnimation: THREE.AnimationAction;
    annotationComponent: IAnnotationAframe;
    currentDeactivatedButton: AFrame.Entity;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    numbers: THREE.Object3D;
    letters: THREE.Object3D;
    gSwitcher: boolean
    tabIsOpen: boolean;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const HRDiagramsComponent = {
    name: 'lesson-start',
    val: {
        init(this: IHRDiagramsControl) {
            const model = document.getElementById('model') as AFrame.Entity;
            console.log(model.object3D)
            model.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                this.numbers = model.object3D.getObjectByName('Numbers') as THREE.Object3D
                this.letters = model.object3D.getObjectByName('Letters') as THREE.Object3D
                this.numbers.visible = false
                const animatedEl = model.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [PressSwitch, ScaleUpInfo] = animatedEl.animations
                this.pressSwitchAnimation = this.mixer.clipAction(PressSwitch)
                this.scaleUpInfoAnimation = this.mixer.clipAction(ScaleUpInfo)
                this.scaleUpInfoAnimation.setLoop(THREE.LoopPingPong, 1)
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.gSwitcher = true
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: model; scale: 40 40 40; relativeRotation: 0 0 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.theMainSequenceHandler = () => {
                this.annotationComponent.line.visible = true
                if (this.tabIsOpen) {
                    this.spectralClassTriggerButton?.setAttribute('scale', '0 0 0')
                    this.absorptionLinesTriggerButton?.setAttribute('scale', '0 0 0')
                    this.scaleUpInfoAnimation.stop()
                    this.scaleUpInfoAnimation.timeScale = -1
                    this.scaleUpInfoAnimation.repetitions = 1
                    this.scaleUpInfoAnimation.play()
                    this.tabIsOpen = false
                }
                if (this.onObjectSelected) {
                    const title = 'The Main Sequence';
                    const body = 'The diagonal line of the Hertzprung-Russel (HR) diagram shows the main sequence, where stars spend most of their lives fusing hydrogen.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.theSunHandler = () => {
                this.annotationComponent.line.visible = true
                if (this.tabIsOpen) {
                    this.spectralClassTriggerButton?.setAttribute('scale', '0 0 0')
                    this.absorptionLinesTriggerButton?.setAttribute('scale', '0 0 0')
                    this.scaleUpInfoAnimation.stop()
                    this.scaleUpInfoAnimation.timeScale = -1
                    this.scaleUpInfoAnimation.repetitions = 1
                    this.scaleUpInfoAnimation.play()
                    this.tabIsOpen = false
                }
                if (this.onObjectSelected) {
                    const title = 'The Sun';
                    const body = 'The Sun is a main sequence star so it can be found in the diagonal main sequence band on a HR diagram. It has a surface temperature of ~5,800 K making it a G class star.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.redGiantsHandler = () => {
                this.annotationComponent.line.visible = true
                if (this.tabIsOpen) {
                    this.spectralClassTriggerButton?.setAttribute('scale', '0 0 0')
                    this.absorptionLinesTriggerButton?.setAttribute('scale', '0 0 0')
                    this.scaleUpInfoAnimation.stop()
                    this.scaleUpInfoAnimation.timeScale = -1
                    this.scaleUpInfoAnimation.repetitions = 1
                    this.scaleUpInfoAnimation.play()
                    this.tabIsOpen = false
                }
                if (this.onObjectSelected) {
                    const title = 'Red Giants';
                    const body = 'When stars start to run out of hydrogen fuel, they leave the main sequence and expand into much larger and cooler red giant/ supergiant stars. These are all found in the top left of the HR diagram.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.whiteDwarfsHandler = () => {
                this.annotationComponent.line.visible = true
                if (this.tabIsOpen) {
                    this.spectralClassTriggerButton?.setAttribute('scale', '0 0 0')
                    this.absorptionLinesTriggerButton?.setAttribute('scale', '0 0 0')
                    this.scaleUpInfoAnimation.stop()
                    this.scaleUpInfoAnimation.timeScale = -1
                    this.scaleUpInfoAnimation.repetitions = 1
                    this.scaleUpInfoAnimation.play()
                    this.tabIsOpen = false
                }
                if (this.onObjectSelected) {
                    const title = 'White Dwarfs';
                    const body = 'After red giants expel their outer layers, what is left is a very high temperature small object called a white dwarf. Due to their tiny size, their absolute magnitude is very small. They can be found in the bottom left corner of the HR diagram.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.theYAxisHandler = () => {
                this.annotationComponent.line.visible = true
                if (this.tabIsOpen) {
                    this.spectralClassTriggerButton?.setAttribute('scale', '0 0 0')
                    this.absorptionLinesTriggerButton?.setAttribute('scale', '0 0 0')
                    this.scaleUpInfoAnimation.stop()
                    this.scaleUpInfoAnimation.timeScale = -1
                    this.scaleUpInfoAnimation.repetitions = 1
                    this.scaleUpInfoAnimation.play()
                    this.tabIsOpen = false
                }
                if (this.onObjectSelected) {
                    const title = 'The Y axis';
                    const body = 'The Y axis of the HR diagram is <b>absolute magnitude</b>. This is a logarithmic measure of a star’s intrinsic brightness. The scale goes in the opposite direction than you’d expect; the larger the number, the dimmer the star.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.theXAxisHandler = () => {
                this.annotationComponent.line.visible = true
                this.scaleUpInfoAnimation.stop()
                this.scaleUpInfoAnimation.clampWhenFinished = true
                this.scaleUpInfoAnimation.timeScale = 1
                this.scaleUpInfoAnimation.repetitions = 1
                this.scaleUpInfoAnimation.play()
                this.tabIsOpen = true
                setTimeout(() => {
                    this.spectralClassTriggerButton?.setAttribute('scale', '0.5 0.5 0.5')
                    this.absorptionLinesTriggerButton?.setAttribute('scale', '0.5 0.5 0.5')
                }, this.scaleUpInfoAnimation.getClip().duration * 1000)
                if (this.onObjectSelected) {
                    const title = 'The X axis';
                    const body = 'The X axis of the HR diagram can either be the spectral classes of the star or their temperature. On a temperature scale, the axis is not evenly spaced (non-linear) and goes backwards from 50,000 K, down to 2,500 K.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.spectralClassHandler = () => {
                this.annotationComponent.line.visible = true
                if (this.onObjectSelected) {
                    const title = 'Spectral Class';
                    const body = 'Stars can be classified into 7 classes given the letters, O, B, A, F, G, K, and M. O type stars are the hottest blue stars, with temperatures up to 50,000 K. M type stars are red and cool, with temperatures below 3,500 K.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.absorptionLinesHandler = () => {
                this.annotationComponent.line.visible = true
                if (this.onObjectSelected) {
                    const title = 'Absorption Lines';
                    const body = 'When light from a star is split to see all the wavelengths separately, there are gaps; these are known as absorption lines. These gaps come from light interacting with matter in the star so absorption lines can tell us what stars are made from.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.gHandler = () => {
                this.annotationComponent.line.visible = false
                this.pressSwitchAnimation.stop()
                this.pressSwitchAnimation.repetitions = 1
                this.pressSwitchAnimation.play()
                this.gSwitcher = !this.gSwitcher
                this.numbers.visible = !this.gSwitcher
                this.letters.visible = this.gSwitcher
            }

            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const theMainSequenceTriggerBtn = poolButtons.requestEntity()
                theMainSequenceTriggerBtn?.setAttribute('position', '-0.03 0.11 0.012')
                theMainSequenceTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5')
                theMainSequenceTriggerBtn?.play()
                theMainSequenceTriggerBtn?.addEventListener('click', () => {
                    this.theMainSequenceHandler()
                    if (theMainSequenceTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(theMainSequenceTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theMainSequenceTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theMainSequenceTriggerBtn
                    }
                });

                const theSunTriggerButton = poolButtons.requestEntity();
                theSunTriggerButton?.setAttribute('position', '0.025 0.085 0.012');
                theSunTriggerButton?.setAttribute('scale', '0.5 0.5 0.5')
                theSunTriggerButton?.play()
                theSunTriggerButton?.addEventListener('click', () => {
                    this.theSunHandler()
                    if (theSunTriggerButton) {
                        this.annotationComponent.setObjectToFollow(theSunTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theSunTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theSunTriggerButton

                    }
                });

                const redGiantsTriggerButton = poolButtons.requestEntity();
                redGiantsTriggerButton?.setAttribute('position', '0.033 0.12 0.012');
                redGiantsTriggerButton?.setAttribute('scale', '0.5 0.5 0.5')
                redGiantsTriggerButton?.play()
                redGiantsTriggerButton?.addEventListener('click', () => {
                    this.redGiantsHandler()
                    if (redGiantsTriggerButton) {
                        this.annotationComponent.setObjectToFollow(redGiantsTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (redGiantsTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = redGiantsTriggerButton

                    }
                });

                const whiteDwarfsTriggerButton = poolButtons.requestEntity();
                whiteDwarfsTriggerButton?.setAttribute('position', '-0.027 0.06 0.012');
                whiteDwarfsTriggerButton?.setAttribute('scale', '0.5 0.5 0.5')
                whiteDwarfsTriggerButton?.play()
                whiteDwarfsTriggerButton?.addEventListener('click', () => {
                    this.whiteDwarfsHandler()
                    if (whiteDwarfsTriggerButton) {
                        this.annotationComponent.setObjectToFollow(whiteDwarfsTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (whiteDwarfsTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = whiteDwarfsTriggerButton

                    }
                });

                const theYAxisTriggerButton = poolButtons.requestEntity();
                theYAxisTriggerButton?.setAttribute('position', '-0.08 0.09 0.012');
                theYAxisTriggerButton?.setAttribute('scale', '0.5 0.5 0.5')
                theYAxisTriggerButton?.play()
                theYAxisTriggerButton?.addEventListener('click', () => {
                    this.theYAxisHandler()
                    if (theYAxisTriggerButton) {
                        this.annotationComponent.setObjectToFollow(theYAxisTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theYAxisTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theYAxisTriggerButton
                    }
                });

                const theXAxisTriggerButton = poolButtons.requestEntity();
                theXAxisTriggerButton?.setAttribute('position', '0 0.025 0.015');
                theXAxisTriggerButton?.setAttribute('scale', '0.5 0.5 0.5')
                theXAxisTriggerButton?.play()
                theXAxisTriggerButton?.addEventListener('click', () => {
                    this.theXAxisHandler()
                    if (theXAxisTriggerButton) {
                        this.annotationComponent.setObjectToFollow(theXAxisTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theXAxisTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theXAxisTriggerButton

                    }
                });

                this.spectralClassTriggerButton = poolButtons.requestEntity() as AFrame.Entity;
                this.spectralClassTriggerButton?.setAttribute('position', '0.15 0.13 0.012');
                this.spectralClassTriggerButton?.setAttribute('scale', '0 0 0')
                this.spectralClassTriggerButton?.play()
                this.spectralClassTriggerButton?.addEventListener('click', () => {
                    this.spectralClassHandler()
                    if (this.spectralClassTriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.spectralClassTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.spectralClassTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.spectralClassTriggerButton

                    }
                });

                this.absorptionLinesTriggerButton = poolButtons.requestEntity() as AFrame.Entity;
                this.absorptionLinesTriggerButton?.setAttribute('position', '0.2 0.13 0.012');
                this.absorptionLinesTriggerButton?.setAttribute('scale', '0 0 0')
                this.absorptionLinesTriggerButton?.play()
                this.absorptionLinesTriggerButton?.addEventListener('click', () => {
                    this.absorptionLinesHandler()
                    if (this.absorptionLinesTriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.absorptionLinesTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.absorptionLinesTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.absorptionLinesTriggerButton
                    }
                });

                const gTriggerButton = poolButtons.requestEntity();
                gTriggerButton?.setAttribute('position', '-0.055 0.02 0.02');
                gTriggerButton?.setAttribute('scale', '0.5 0.5 0.5')
                gTriggerButton?.play()
                gTriggerButton?.addEventListener('click', () => {
                    this.gHandler()
                    if (gTriggerButton) {
                        this.annotationComponent.setObjectToFollow(gTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (gTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = gTriggerButton

                    }
                });
            };
        },
        tick(this: IHRDiagramsControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },

    },
};
export {HRDiagramsComponent}
