import * as AFrame from 'aframe';
import * as THREE from 'three';

interface IAtomsSceneAframe {
    mixer: THREE.AnimationMixer;
    clipAnimation: THREE.AnimationAction;
    el: AFrame.Entity;
    sliderValue: string;
    prevValue: number;
    prevAnimation: THREE.AnimationAction;
    prevTimeout: NodeJS.Timeout;
}

const ProtonsScene = {
    name: 'protons-scene',
    val: {
        init(this: IAtomsSceneAframe) {
            const protons = document.getElementById('protons') as AFrame.Entity
            protons.addEventListener('model-loaded', () => {
                const animatedEl = protons.object3D.getObjectByName('Physics26_NuclearForces_V2') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const MoveProtons = animatedEl.animations[0]
                this.clipAnimation = this.mixer.clipAction(MoveProtons)
            });
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                    this.clipAnimation.play()
                }
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: protons; scale: 3 3 3; offset: 0 -4 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                }
            });
            this.el.sceneEl?.addEventListener('slider-move', (e) => {
                const ce = e as CustomEvent;
                this.sliderValue = ce.detail.value;

            });
        },
        tick(this: IAtomsSceneAframe, time: number, deltaTime: number) {
            if (this.clipAnimation){
                if (this.mixer) {
                    const time = 2.4 - (parseFloat(this.sliderValue) / 3.5) * (this.clipAnimation.getClip().duration)
                    this.mixer.setTime(time !== 2.4 ? (time < 0 ? 0 : time) : 2.39)
                }
            }
        },
    },
};
export {ProtonsScene as AtomsSceneComponent}
