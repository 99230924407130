import { Stack, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { IconImage } from './IconImage';
import { CustomContainer } from './CustomContainer';
import { HeaderHashLink } from './HeaderHashLink';
import { useGoToHomePage } from '../hooks/useGoToHomePage';
import { useTranslation } from 'react-i18next';
import { LanguageSelects } from './LanguageSelect';
import { DisplayEnum } from '../enums';

import Logo from '../../assets/Logo.png';

type Props = {
  transparentBg?: boolean;
};

export function HeaderComponent(props: Props) {
  const { transparentBg } = props;
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const goToHomePage = useGoToHomePage();
  const { t, i18n } = useTranslation();

  return (
    <Stack
      borderRadius='0 0 30px 30px'
      bgcolor={transparentBg ? 'transparent' : '#fff'}
    >
      <CustomContainer>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box mt={0.5} sx={{ cursor: 'pointer' }} onClick={goToHomePage}>
            <IconImage src={Logo} width='67px' />
          </Box>
          <Box display='flex' alignItems='center'>
            {/* <HeaderHashLink to='/login'>
              <Box display='flex' alignItems='center'>
                <Box mr={0.8} lineHeight='1'>
                  <IconImage src={LogIn} alt='sign in' width='14px' />
                </Box>
                Sign In
              </Box>
            </HeaderHashLink> */}
            <Box
              bgcolor='rgba(255, 253, 1, 1)'
              borderRadius='6px'
              padding='12px 20px'
              ml={3}
              sx={{ cursor: 'pointer' }}
            >
              <HeaderHashLink to='/#joinUs'>
                <Typography fontFamily='GilroySemiBold' color='#262626'>
                  {t('COMMON.BUTTON.JOIN_US')}
                </Typography>
              </HeaderHashLink>
            </Box>
            <Box ml={xlDisplay || mobile ? 3 : 1.5}>
              <LanguageSelects />
            </Box>
          </Box>
        </Stack>
      </CustomContainer>
    </Stack>
  );
}

export default HeaderComponent;
