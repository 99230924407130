import React, { useEffect, useState } from 'react';
import './css/ProximityAlert.css';

// Assuming the distance is communicated via a custom event 'proximity-alert'
const ProximityAlert = () => {
    const [isProximityAlert, setIsProximityAlert] = useState(false);

    useEffect(() => {
        const handleProximityAlert = (e: Event) => {
            console.log("Event received")
            const ce = e as CustomEvent;
            setIsProximityAlert(ce.detail.isProximityAlert);
        };

        const ascene = document.querySelector('a-scene');
        if (ascene) {
            ascene.addEventListener('proximity-alert', handleProximityAlert);
            console.log('Event listener addded')
        } else {
            console.log("Couldn't subscribe to the event")
        }
        
        return () => {
            const ascene = document.querySelector('a-scene');
            if (ascene) {
                ascene.addEventListener('proximity-alert', handleProximityAlert);
            }
        };
    }, []);

    if (!isProximityAlert) {
        return null;
    }

    return (
        <div className="proximity-alert"> 
            <h1>Warning</h1>
            <p>Too close to the model. Safety is important even in augmented reality.<br/> Please step back.</p>
        </div>
    );
};

export default ProximityAlert;
