import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    voltSecondText: AFrame.Entity;
    voltFirstText: AFrame.Entity;
    secondPageMixer: THREE.AnimationMixer;
    thirdPageMixer: THREE.AnimationMixer;
    forthPageMixer: THREE.AnimationMixer;
    fifthPageMixer: THREE.AnimationMixer;
    secondPageAnimation: THREE.AnimationAction;
    thirdPageAnimation: THREE.AnimationAction;
    forthPageAnimation: THREE.AnimationAction;
    fifthPageAnimation: THREE.AnimationAction;
    prevAnimation: THREE.AnimationAction;
    currentDeactivatedButton: AFrame.Entity;
    poolEntity: AFrame.Entity;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    sliderValue: string;
    secondResistor: THREE.Object3D;
    prevValue: string;
    distanceEvent: CustomEvent;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            // Add 'model-loaded' event listener to the component
            const holder = document.getElementById('holder') as AFrame.Entity;
            holder.addEventListener('model-loaded', () => {

                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                this.el.sceneEl?.addEventListener('lesson-start', () => {
                    console.log('lesson started')
                    const ring = document.getElementById('ring')
                    if (ring) {
                        ring.removeAttribute('tap-place')
                        this.el.sceneEl?.removeChild(ring)
                        const book = document.getElementById('book') as AFrame.Entity;
                        const animatedEl = book.object3D.getObjectByName('Scene') as any;
                        this.secondPageMixer = new THREE.AnimationMixer(animatedEl)
                        this.thirdPageMixer = new THREE.AnimationMixer(animatedEl)
                        this.forthPageMixer = new THREE.AnimationMixer(animatedEl)
                        this.fifthPageMixer = new THREE.AnimationMixer(animatedEl)
                        const [TurnPage1, TurnPage2, TurnPage3, TurnPage4] = animatedEl.animations
                        this.secondPageAnimation = this.secondPageMixer.clipAction(TurnPage1)
                        this.secondPageAnimation.setLoop(THREE.LoopPingPong, 1);
                        this.thirdPageAnimation = this.thirdPageMixer.clipAction(TurnPage2)
                        this.thirdPageAnimation.setLoop(THREE.LoopPingPong, 1);
                        this.forthPageAnimation = this.forthPageMixer.clipAction(TurnPage3)
                        this.forthPageAnimation.setLoop(THREE.LoopPingPong, 1);
                        this.fifthPageAnimation = this.fifthPageMixer.clipAction(TurnPage4)
                        this.fifthPageAnimation.setLoop(THREE.LoopPingPong, 1);

                    }
                })

                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
                setFirstVoltmeterDigits()
                setSecondVoltmeterDigits()
            });


            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: holder; scale: 15 15 15; offset: 0 0 -4');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    this.el.sceneEl?.appendChild(ring);
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });

            this.el.sceneEl?.addEventListener('slider-move', (e) => {
                const ce = e as CustomEvent;
                this.el.object3D.traverse(child => console.log(child))
                this.secondResistor = this.el.object3D.getObjectByName('Resistor_AR1') as THREE.Object3D;
                this.sliderValue = ce.detail.value;
                switch (this.sliderValue) {
                    case `1`:
                        this.secondResistor.scale.set(0.8, 0.8, 0.8)
                        updateCircuitDynamicValues()
                        if (this.prevAnimation) {
                            this.prevAnimation.stop()
                            this.prevAnimation.paused = false
                        }
                        if (this.prevValue === '2') {
                            this.secondPageAnimation.timeScale = -4
                            this.secondPageAnimation.repetitions = 1
                            this.secondPageAnimation.clampWhenFinished = true
                            this.secondPageAnimation.play()

                            this.prevAnimation = this.secondPageAnimation
                        } else {
                            this.secondPageAnimation.timeScale = 4
                            this.secondPageAnimation.play()
                            this.secondPageAnimation.paused = true
                            this.prevAnimation = this.secondPageAnimation
                        }
                        this.prevValue = '1'
                        break;
                    case `2`:
                        this.secondResistor.scale.set(1, 1, 1)
                        if (this.prevAnimation) {
                            this.prevAnimation.stop()
                            this.prevAnimation.paused = false
                        }
                        if (this.prevValue === '3') {
                            this.thirdPageAnimation.timeScale = -4
                            this.thirdPageAnimation.repetitions = 1
                            this.thirdPageAnimation.clampWhenFinished = true
                            this.thirdPageAnimation.play()

                            this.prevAnimation = this.thirdPageAnimation
                        } else {
                            this.secondPageAnimation.timeScale = 4
                            this.secondPageAnimation.repetitions = 1
                            this.secondPageAnimation.clampWhenFinished = true
                            this.secondPageAnimation.play()

                            this.prevAnimation = this.secondPageAnimation
                        }
                        this.prevValue = '2'
                        updateCircuitDynamicValues()
                        break;
                    case `3`:
                        this.secondResistor.scale.set(1.2, 1.2, 1.2)
                        if (this.prevAnimation) {
                            this.prevAnimation.stop()
                            this.prevAnimation.paused = false
                        }
                        if (this.prevValue === '4') {
                            this.forthPageAnimation.timeScale = -4
                            this.forthPageAnimation.repetitions = 1
                            this.forthPageAnimation.clampWhenFinished = true
                            this.forthPageAnimation.play()

                            this.prevAnimation = this.forthPageAnimation
                        } else {
                            this.thirdPageAnimation.timeScale = 4
                            this.thirdPageAnimation.repetitions = 1
                            this.thirdPageAnimation.clampWhenFinished = true
                            this.thirdPageAnimation.play()

                            this.prevAnimation = this.thirdPageAnimation
                        }
                        this.prevValue = '3'
                        updateCircuitDynamicValues()
                        break;
                    case `4`:
                        this.secondResistor.scale.set(1.35, 1.35, 1.35)
                        if (this.prevAnimation) {
                            this.prevAnimation.stop()
                            this.prevAnimation.paused = false
                        }
                        if (this.prevValue === '5') {
                            this.fifthPageAnimation.timeScale = -4
                            this.fifthPageAnimation.repetitions = 1
                            this.fifthPageAnimation.clampWhenFinished = true
                            this.fifthPageAnimation.play()

                            this.prevAnimation = this.fifthPageAnimation
                        } else {
                            this.forthPageAnimation.timeScale = 4
                            this.forthPageAnimation.repetitions = 1
                            this.forthPageAnimation.clampWhenFinished = true
                            this.forthPageAnimation.play()

                            this.prevAnimation = this.forthPageAnimation
                        }
                        this.prevValue = '4'
                        updateCircuitDynamicValues()
                        break;
                    case `5`:
                        this.secondResistor.scale.set(1.5, 1.5, 1.5)
                        if (this.prevAnimation) {
                            this.prevAnimation.stop()
                            this.prevAnimation.paused = false
                        }
                        this.fifthPageAnimation.timeScale = 4
                        this.fifthPageAnimation.repetitions = 1
                        this.fifthPageAnimation.clampWhenFinished = true
                        this.fifthPageAnimation.play()

                        this.prevAnimation = this.fifthPageAnimation
                        this.prevValue = '5'
                        updateCircuitDynamicValues()
                        break;
                }
            });
            const updateCircuitDynamicValues = () => {
                switch (this.sliderValue) {
                    case `1`:
                        this.voltFirstText.setAttribute('text', "value: 4.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltFirstText.setAttribute('position', '0.323 0.026 0.245');
                        this.voltSecondText.setAttribute('text', "value: 2.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltSecondText.setAttribute('position', '0.623 0.026 0.245');
                        break;
                    case `2`:
                        this.voltFirstText.setAttribute('text', "value: 3.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltFirstText.setAttribute('position', '0.323 0.026 0.245');
                        this.voltSecondText.setAttribute('text', "value: 3.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltSecondText.setAttribute('position', '0.623 0.026 0.245');
                        break;
                    case `3`:
                        this.voltFirstText.setAttribute('text', "value: 2.4; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltFirstText.setAttribute('position', '0.323 0.026 0.245');
                        this.voltSecondText.setAttribute('text', "value: 3.6; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltSecondText.setAttribute('position', '0.623 0.026 0.245');
                        break;
                    case `4`:
                        this.voltFirstText.setAttribute('text', "value: 2.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltFirstText.setAttribute('position', '0.323 0.026 0.245');
                        this.voltSecondText.setAttribute('text', "value: 4.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltSecondText.setAttribute('position', '0.623 0.026 0.245');
                        break;
                    case `5`:
                        this.voltFirstText.setAttribute('text', "value: 1.7; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltFirstText.setAttribute('position', '0.323 0.026 0.245');
                        this.voltSecondText.setAttribute('text', "value: 4.3; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                        this.voltSecondText.setAttribute('position', '0.623 0.026 0.245');
                        break;
                }

            }
            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const firstResistorTriggerBtn = poolButtons.requestEntity();
                if (firstResistorTriggerBtn) {
                    firstResistorTriggerBtn.setAttribute('position', '-0.15 0.065 0.18');
                    firstResistorTriggerBtn.play();
                    firstResistorTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Resistor R1'
                            const body = 'This resistor has been labelled R1, it has a constant resistance of 2 Ω. The potential difference over the component (shown on the voltmeter) changes depending on the total resistance. The current through R1 is the same as R2.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(firstResistorTriggerBtn);
                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (firstResistorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = firstResistorTriggerBtn

                    });
                } else {
                    console.log('No trigger btn');
                }


                const secondResistorTriggerBtn = poolButtons.requestEntity();
                if (secondResistorTriggerBtn) {
                    secondResistorTriggerBtn.setAttribute('position', '0.15 0.065 0.18');
                    secondResistorTriggerBtn.play();
                    secondResistorTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Resistor R2'
                            const body = 'This resistor has been labelled R2, you can vary the resistance using the slider. The potential difference over the component (shown on the voltmeter) changes depending on the total resistance. The current through R1 is the same as R2.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(secondResistorTriggerBtn);

                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (secondResistorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = secondResistorTriggerBtn

                    });
                } else {
                    console.warn('No trigger btn: wires');
                }

            };


            const setFirstVoltmeterDigits = () => {
                if (!this.voltFirstText) {
                    const voltFirstText = document.createElement('a-entity')
                    voltFirstText.setAttribute('text', "value: 3.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    voltFirstText.setAttribute('position', '0.323 0.026 0.245');
                    voltFirstText.setAttribute('scale', '0.1 0.1 0.1');
                    voltFirstText.setAttribute('rotation', '-90 0 0');
                    voltFirstText.setAttribute('visible', true);
                    this.el.appendChild(voltFirstText);
                    this.voltFirstText = voltFirstText;
                }
            }

            const setSecondVoltmeterDigits = () => {
                if (!this.voltSecondText) {
                    const voltSecondText = document.createElement('a-entity')
                    voltSecondText.setAttribute('text', "value: 3.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    voltSecondText.setAttribute('position', '0.623 0.026 0.245');
                    voltSecondText.setAttribute('scale', '0.1 0.1 0.1');
                    voltSecondText.setAttribute('rotation', '-90 0 0');
                    voltSecondText.setAttribute('visible', true);
                    this.el.appendChild(voltSecondText);
                    this.voltSecondText = voltSecondText;
                }
            }

        },
        tick(this: ICircuitControl, time: number, deltaTime: number) {
            const ring = document.getElementById('ring')
            if(!ring){
                const circuit = document.getElementById('holder') as AFrame.Entity;
                const cameraEl = this.el.sceneEl?.querySelector('a-camera');
                if (cameraEl && circuit) {
                    const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                    let cameraPosition = camPos.clone()
                    let spherePos = circuit.object3D.position.clone()
                    let distance = cameraPosition.distanceTo(spherePos)
                    if (!this.distanceEvent) {
                        this.distanceEvent = new CustomEvent('distance-change', {detail: {value: false}});
                    }
                    if ((distance < 6 && cameraPosition.x < 4) || (distance < 8 && cameraPosition.x > 4) || (distance < 12 && cameraPosition.x > 6)) {
                        console.log(cameraPosition.x)
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = true
                        scene.emit('distance-change',this.distanceEvent.detail.value);
                        circuit.object3D.visible = false
                    } else {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = false
                        scene.emit('distance-change',this.distanceEvent.detail.value);
                        if (!circuit.object3D.visible){
                            circuit.object3D.visible = true
                        }
                    }
                }
            }

            if (this.secondPageMixer) {
                this.secondPageMixer.update(deltaTime * 0.001);
            }
            if (this.thirdPageMixer) {
                this.thirdPageMixer.update(deltaTime * 0.001);
            }
            if (this.forthPageMixer) {
                this.forthPageMixer.update(deltaTime * 0.001);
            }
            if (this.fifthPageMixer) {
                this.fifthPageMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {CircuitControlComponent}
