export const vertexShader = `
varying vec2 vUv;

void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

export const fragmentShader = `
varying vec2 vUv;
uniform sampler2D u_texture;
uniform float u_time;

void main() {
  float yOffset = u_time * 0.45;
  vec2 scaledUV = vec2(1.0 - (vUv.y * 2.0) + yOffset, vUv.x * 6.0);
  vec4 color = texture2D(u_texture, fract(scaledUV));
  
  vec3 blueWaterColor = vec3(0.003, 0.322, 0.388);
  vec3 shadedColor = mix(blueWaterColor, color.rgb, color.r);
  float alpha = color.r;
  
  gl_FragColor = vec4(shadedColor, alpha);
}
`