import * as THREE from 'three';
import * as AFrame from 'aframe';
import {LinearSpline} from '../../../lib/utils/LinearSpline';
import {vertexShader as _VS, fragmentShader as _FS} from './shaders/particle-shaders';

interface ParticleData {
    position: THREE.Vector3;
    size: number;
    colour: THREE.Color;
    alpha: number;
    life: number;
    maxLife: number;
    rotation: number;
    velocity: THREE.Vector3;
}

export interface ISecondParticleImpulseAframe {
    el: AFrame.Entity;
    particleData: ParticleData[];
    geometry: THREE.BufferGeometry;
    startColor: THREE.Color;
    endColor: THREE.Color;
    colourSpline: LinearSpline<THREE.Color>;
    alphaSpline: LinearSpline<number>;
    sizeSpline: LinearSpline<number>;
    alphaSplineShort?: LinearSpline<number>;
    points: THREE.Points;
    elapsedTimeSinceEmission: number;
    intervalCounter: number;
    interval?: ReturnType<typeof setInterval>;
    canPlay: boolean;
    endPos: boolean;

    start: () => void;

    addParticles(): void;

    updateParticles(deltaTimeS: number): void;

    updateGeometry(): void;
}


const secondParticleImpulseComponent = {
    name: 'second-particle-impulse',
    val: {
        init(this: ISecondParticleImpulseAframe): void {
            const imgUrl = require('./assets/particle.png')
            const fireTexture = new THREE.TextureLoader().load(imgUrl)
            fireTexture.encoding = THREE.sRGBEncoding

            const matShader = new THREE.ShaderMaterial({
                uniforms: {
                    diffuseTexture: {value: fireTexture},
                    pointMultiplier: {
                        value: window.innerHeight / (2.0 * Math.tan(0.5 * 60.0 * Math.PI / 180.0))
                    }
                },
                vertexShader: _VS,
                fragmentShader: _FS,
                blending: THREE.AdditiveBlending,
                depthTest: true,
                depthWrite: false,
                transparent: true,
                vertexColors: true,
            })

            // points positions
            this.startColor = new THREE.Color(0x2020FF)
            this.endColor = new THREE.Color(0x2020FF)
            this.particleData = []

            this.geometry = new THREE.BufferGeometry()
            this.geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
            this.geometry.setAttribute('size', new THREE.Float32BufferAttribute([], 1))
            this.geometry.setAttribute('colour', new THREE.Float32BufferAttribute([], 4))
            this.geometry.setAttribute('angle', new THREE.Float32BufferAttribute([], 1))

            this.points = new THREE.Points(this.geometry, matShader)
            this.points.position.set(2.15, 1.5, 0)
            this.points.scale.set(0.001, 0.001, 0.001)
            this.el.object3D.add(this.points)

            this.alphaSpline = new LinearSpline((t, a, b) => {
                return a + t * (b - a)
            })
            this.alphaSpline.AddPoint(0.0, 0.0)

            this.alphaSplineShort = new LinearSpline((t, a: number, b: number) => {
                return a + t * (b - a)
            })
            this.alphaSplineShort.AddPoint(0.0, 0.0)

            this.sizeSpline = new LinearSpline((t, a, b) => {
                return a + t * (b - a)
            })
            this.sizeSpline.AddPoint(0.0, 50.0 * 0.25)
            this.sizeSpline.AddPoint(1.0, 0.25)
            const raycaster = new THREE.Raycaster();
            const camera = document.getElementById('camera') as AFrame.Entity;
            const threeCamera = camera.getObject3D('camera') as THREE.Camera;
            this.endPos = false;
            this.canPlay = true;
            this.start = () => {
                if (this.canPlay) {
                    const origin = new THREE.Vector2(0, 0)
                    raycaster.setFromCamera(origin, threeCamera)
                    this.endPos = true
                    this.addParticles()
                    this.intervalCounter = 4
                    this.canPlay = false
                }
            }
        },
        tick(this: ISecondParticleImpulseAframe, time: number, deltaTime: number): void {
            const deltaTimeS = deltaTime * 0.001
            this.updateParticles(deltaTimeS)
            this.updateGeometry()

            if (this.intervalCounter <= 0) {
                clearInterval(this.interval);
                this.canPlay = true;
                this.endPos = false;
            }
        },
        addParticles(this: ISecondParticleImpulseAframe): void {
            this.interval = setInterval(() => {
                const n = 5
                for (let i = 0; i < n; i++) {
                    const lifeRand = (Math.random() * 0.1 + 0.9) * 2
                    this.particleData.push({
                        position: new THREE.Vector3(
                            (Math.random() * 2) * 0.25,
                            (Math.random() * 2) * 0.25,
                            (Math.random() * 2) * 0.25
                        ),
                        size: 0.2,
                        colour: new THREE.Color(),
                        alpha: 1.0,
                        life: lifeRand,
                        maxLife: lifeRand,
                        rotation: Math.random() * 3.0 * Math.PI,
                        velocity: new THREE.Vector3(1, 0, 5),
                    })
                }
                if (this.intervalCounter) {
                    this.intervalCounter -= 1
                }

            }, 150)
        },

        updateParticles(this: ISecondParticleImpulseAframe, deltaTimeS: number): void {
            // Reusable vector to avoid new object creation
            const drag = new THREE.Vector3();
            for (let i = 0; i < this.particleData.length; i++) {
                const p = this.particleData[i];
                p.life -= deltaTimeS;
                if (p.life <= 0.0) {
                    this.particleData.splice(i, 1);
                    i--;
                    continue;
                }

                // update rotation
                p.rotation += deltaTimeS * 0.1;
                // movement
                p.position.add(p.velocity.clone().multiplyScalar(deltaTimeS));
                drag.copy(p.velocity);
                drag.multiplyScalar(deltaTimeS * 0.1);
                drag.x = (Math.min(Math.abs(drag.x), Math.abs(p.velocity.x)) - i);
                drag.y = (Math.min(Math.abs(drag.y), Math.abs(p.velocity.y)) - i) / 2.5;
                drag.z = (Math.min(Math.abs(drag.z), Math.abs(p.velocity.z)) - i) / 2;
                p.velocity.sub(drag);
            }
        },

        updateGeometry(this: ISecondParticleImpulseAframe): void {
            const positions = []
            const sizes = []
            const colors = []
            const angles = []

            for (let i = 0; i < this.particleData.length; i++) {
                positions.push(this.particleData[i].position.x + i, this.particleData[i].position.y + i, this.particleData[i].position.z + i)
                sizes.push(this.particleData[i].size)
                colors.push(this.particleData[i].colour.r, this.particleData[i].colour.g, this.particleData[i].colour.b, this.particleData[i].alpha)
                angles.push(this.particleData[i].rotation)
            }

            this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))
            this.geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1))
            this.geometry.setAttribute('colour', new THREE.Float32BufferAttribute(colors, 4))
            this.geometry.setAttribute('angle', new THREE.Float32BufferAttribute(angles, 1))

            this.geometry.attributes.position.needsUpdate = true
            this.geometry.attributes.size.needsUpdate = true
            this.geometry.attributes.colour.needsUpdate = true
            this.geometry.attributes.angle.needsUpdate = true
        },
    },
};

export {secondParticleImpulseComponent as SecondParticleImpulse};
