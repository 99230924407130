
import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IAnnotationSystemAframe } from "../../../lib/aframe/systems/annotation-system";
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IStateMachine } from './state-machine';
import { IBubbleMachineAframe } from './bubles-system';

interface IBaseControl {
    smallBubblePositions: { x: number; y: number; z: number; }[];
    bubbleMachineSmall: IBubbleMachineAframe;
    bubblePositions: { x: number; y: number; z: number; }[];
    bubbleMachine: IBubbleMachineAframe;
    liquidActions: THREE.AnimationAction[];
    dropperActions: THREE.AnimationAction[];
    morphs: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>[];
    isAnimationPlaying: boolean;
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    mixer: THREE.AnimationMixer;
    stateMachine: IStateMachine;
    el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

const SceneControlComponent = {
    name: 'scene-control',
    val: {
        init(this: IBaseControl) {
            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                initialiseAnimations();
                initialiseMorphTargets();
                setupLiquidTextures();
                createClickableAreas();
                fixBugsOnTheModel(); // :)

                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}

                // get state machine
                this.stateMachine = this.el.components['state-machine'] as unknown as IStateMachine;
                if (this.stateMachine) {
                    this.stateMachine.setState(0);
                }

                this.bubbleMachine = this.el.components['bubble-machine'] as unknown as IBubbleMachineAframe;
                this.bubblePositions = [{x: 0.0295, y: 0, z: 0.0825}, {x: 0.0295, y: 0, z: 0.0395}, {x: 0.0295, y: 0, z: -0.0035}]

                const childEntity = document.getElementById('smallBubbles') as AFrame.Entity;
                this.bubbleMachineSmall = childEntity.components['bubble-machine'] as unknown as IBubbleMachineAframe;
                if (this.bubbleMachineSmall) {
                    this.bubbleMachineSmall.moveParticles(0.00, 0.015, 0.00);
                } else {
                    console.log('No bubble machine small')
                    console.log(this.el.components)
                }
                this.smallBubblePositions = [{x: 0.000, y: -0.02, z: 0.05235}, {x: 0.0001, y: -0.02, z: 0.0095}, {x: 0.00065, y: -0.02, z: -0.0345}]
                
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                // console.log('Event recenter received')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: base; scale: 40 40 40; relativeRotation: 0 -90 0;');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });

            const createClickableAreas = () => {

                const cube1 = document.createElement('a-entity');
                cube1.setAttribute('geometry', {
                  primitive: 'box',
                  height: 0.1,
                  width: 0.025,
                  depth: 0.032
                });
                cube1.setAttribute('position', '0 0.02 -0.0985');
                cube1.setAttribute('visible', 'false');
                cube1.setAttribute('class', 'cantap');
                cube1.addEventListener('click', () => {
                    if(!this.isAnimationPlaying) {
                        //making sure we are not playng animation at the moment
                        const state = this.stateMachine.getState();
                        if (state === 0) {
                            if (!this.isAnimationPlaying) {
                                playActions(0);
                                this.isAnimationPlaying = true;
                            }
                        } else if (state === 1 && !this.isAnimationPlaying) {
                            if (!this.isAnimationPlaying) {
                                playActions(1);
                                this.isAnimationPlaying = true;
                            }
                        } else if (state === 2 && !this.isAnimationPlaying) {
                            if (!this.isAnimationPlaying) {
                                playActions(2);
                                this.isAnimationPlaying = true;
                            }
                        };
                    };
                });
                this.mixer.addEventListener('finished', (event) => {
                    const actionFinished = event.action;
                    if (this.dropperActions.includes(actionFinished)) {
                        //sequence is complete
                        // onAnimationFinished(); // moved end of state to when bubbles stop
                        return;
                    } else if (this.liquidActions.includes(actionFinished)){
                        // liquid animation has finished --> continue with morphs
                        const index = this.liquidActions.indexOf(actionFinished);
                        morphTubeDown(index);
                    };
                });

                this.el.appendChild(cube1);
            };

            

            const initialiseAnimations = () => {
                this.isAnimationPlaying = false
                const base = document.getElementById('base') as AFrame.Entity;
                if (base) {
                    const animatedEl = base.object3D.getObjectByName('Scene') as THREE.Object3D;
                    this.mixer = new THREE.AnimationMixer(animatedEl);
                    const [clip1, clip2, clip3, clip4, clip5, clip6] = animatedEl.animations;
                    const action1 = this.mixer.clipAction(clip1);
                    const action2 = this.mixer.clipAction(clip2);
                    const action3 = this.mixer.clipAction(clip3);
                    const action4 = this.mixer.clipAction(clip4);
                    const action5 = this.mixer.clipAction(clip5);
                    const action6 = this.mixer.clipAction(clip6);

                    this.dropperActions = [action1, action2, action3];
                    this.liquidActions = [action4, action5, action6];
                } else {
                    console.warn('Scene-control.tsx: Intialising animations - Entity with id: Scene is not found')
                };
            };

            const initialiseMorphTargets = () => {
                const base = document.getElementById('base') as AFrame.Entity;
                if (base) {
                    // testing mosting morphs
                    const morph = base.object3D.getObjectByName('CapwithTube') as THREE.Mesh;
                    const morph2 = base.object3D.getObjectByName('CapwithTube001') as THREE.Mesh;
                    const morph3 = base.object3D.getObjectByName('CapwithTube002') as THREE.Mesh;
                    this.morphs = [morph, morph2, morph3]
                } else {
                    console.warn('Scene-control.tsx: Intialising morph targets - Entity with id: base is not found')
                };
            };

            /**
             * Setting liquid textures to be transparent
             */
            const setupLiquidTextures = () => {
                const dissolved = this.el.object3D.getObjectByName('TubeFluid006') as THREE.Mesh;
                const mat = dissolved.material as THREE.MeshStandardMaterial;
                mat.opacity = 0;
                

                const dissolved1 = this.el.object3D.getObjectByName('TubeFluid005') as THREE.Mesh;
                const mat1 = new THREE.MeshStandardMaterial();
                mat1.copy(mat);
                dissolved1.material = mat1;
  
                const dissolved2 = this.el.object3D.getObjectByName('TubeFluidAfterReaction') as THREE.Mesh;
                const mat2 = new THREE.MeshStandardMaterial();
                mat2.copy(mat);
                dissolved2.material = mat2;
                
                // const dissolvedMaterial = dissolved.material as THREE.MeshStandardMaterial;
                // const dissolveMat2 = new THREE.MeshStandardMaterial();
                // dissolvedMaterial.copy(dissolveMat2);
                // dissolved2.material = dissolveMat2;


            };

            const fixBugsOnTheModel = () => {
                const liquidC = this.el.object3D.getObjectByName('TubeFluidHCl002') as THREE.Object3D;
                liquidC.position.z -= 0.1;
            };

            const HClButtonHandler = () => {
                const title = 'Hydrochloric Acid'
                const body = 'If carbonates are present, the reaction between the salt and hydrochloric acid (HCl) will release CO<sub>2</sub> which turns the limewater cloudy.<i>Tap the bottle labelled “HCl” to add a few drops of hydrochloric acid to the unknown solution.</i>'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const LimewaterButtonHandler = () => {
                const title = 'Limewater'
                const body = 'Limewater (Ca(OH)<sub>2</sub>) is used in this experiment as it turns cloudy when it reacts with CO<sub>2</sub>. If the limewater turns cloudy it tells us carbonate ions are present in the salt. If it doesn’t turn cloudy, there are no carbonate ions.'
                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const HClTriggerBtn = poolButtons.requestEntity();
                if (HClTriggerBtn) {
                    HClTriggerBtn.setAttribute('position', '0.01 0 -0.12');
                    HClTriggerBtn.play();
                    HClTriggerBtn.addEventListener('click', () => {
                        HClButtonHandler();
                        if (HClTriggerBtn) {
                            this.annotationComponent.setObjectToFollow(HClTriggerBtn);
                            if(this.currentDeactivatedButton) {
                                (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                            }
                            (HClTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                            this.currentDeactivatedButton = HClTriggerBtn
                        }
                    });
                }
               

                const LimewaterTriggerBtn = poolButtons.requestEntity()
                LimewaterTriggerBtn?.setAttribute('position', '0.055 0.02 0.09')
                LimewaterTriggerBtn?.play()
                LimewaterTriggerBtn?.addEventListener('click', () => {
                    LimewaterButtonHandler();
                    if (LimewaterTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(LimewaterTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(LimewaterTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = LimewaterTriggerBtn
                    }
                });
            };

            /**
             * Funciton to execute when animation finished
             * Switching state to the next one
             */
            const onAnimationFinished = () => {
                this.stateMachine.nextState();
                this.isAnimationPlaying = false;
            };

            // plays combination of actions required for full animation sequence
            const playActions = (index: number) => {
                const action = this.dropperActions[index];
                const liqAction = this.liquidActions[index];
                // start dropper animation
                if (action) {
                    action.reset();
                    action.repetitions = 1;
                    action.play();
                }
                const morphTarget = this.morphs[index] as THREE.Mesh;
                const startTime = performance.now();
                let elapsed;
                let dropperTime;
                let morphValue;
                let isLiquidUp = false;
                // start animation timeline
                const animationTimeline = (timestamp: number) => {
                    elapsed = timestamp - startTime;
                    dropperTime = action.time;
                    morphValue = elapsed / 400;

                    if(action.isRunning() === false) {
                        console.log('Animation sequence finished');
                        return;
                    }

                    if (dropperTime > 2 && liqAction && !isLiquidUp) {
                        isLiquidUp = true;
                        liqAction.reset();
                        liqAction.repetitions = 1;
                        liqAction.clampWhenFinished = true;
                        liqAction.play();
                        //aactivate small bubbles
                        this.bubbleMachineSmall.moveParticles(this.smallBubblePositions[index].x, this.smallBubblePositions[index].y, this.smallBubblePositions[index].z);
                        this.bubbleMachineSmall.startSpawning();
                    };
                    requestAnimationFrame(animationTimeline);
                };

                requestAnimationFrame(animationTimeline);
                morphTubeUp(index);
            };

            const morphTubeUp = (index: number) => {
                console.log('starting morphs: ', index);
                const morphTube = this.morphs[index] as THREE.Mesh;
                const startTime = performance.now();

                const morph = (timestamp: number) => {
                    const elapsed = timestamp - startTime;
                    let morphValue = elapsed / 400;
                    if(morphTube.morphTargetInfluences) {
                        morphValue = Math.min(Math.max(morphValue, 0), 1);
                        morphTube.morphTargetInfluences[0] = morphValue;
                    }
                    if (morphValue < 1) {
                        requestAnimationFrame(morph);
                    } else {
                        if (morphTube.morphTargetInfluences) {
                            morphTube.morphTargetInfluences[0] = 1;
                        }
                        return;
                    }
                }
                requestAnimationFrame(morph);
            };

            const morphTubeDown = (index: number) => {
                console.log('starting morphs down: ', index);
                const morphTube = this.morphs[index] as THREE.Mesh;
                const startTime = performance.now();

                const morph = (timestamp: number) => {
                    const elapsed = timestamp - startTime;
                    let morphValue = 1 - elapsed / 400;
                    if(morphTube.morphTargetInfluences) {
                        morphValue = Math.min(Math.max(morphValue, 0), 1);
                        morphTube.morphTargetInfluences[0] = morphValue;
                    }
                    if (morphValue > 0) {
                        requestAnimationFrame(morph);
                    } else {
                        if (morphTube.morphTargetInfluences) {
                            morphTube.morphTargetInfluences[0] = 0;
                        }
                        // when tube get's closed we have to check hich stage we are at
                        const state = this.stateMachine.getState();
                        
                        // starting bubbles
                        this.bubbleMachine.moveParticles(this.bubblePositions[index].x, this.bubblePositions[index].y, this.bubblePositions[index].z);
                        this.bubbleMachine.startSpawning();
                        //start forming white substance
                        animateWhiteSubstance(index);
                        
                        return;
                    }
                }
                requestAnimationFrame(morph);
            };

            const animateWhiteSubstance = (index: number) => {
                let fluidName;
                switch (index) {
                    case 0:
                        fluidName = 'TubeFluid006';
                        break;
                    case 1:
                        fluidName = 'TubeFluid005';
                        break;
                    case 2:
                        fluidName = 'TubeFluidAfterReaction';
                        break;
                    default:
                        break;
                }
                if (fluidName) {
                    console.log('Animating substance: ', fluidName);
                    const fluid = this.el.object3D.getObjectByName(fluidName) as THREE.Mesh;
                    const fluidMat = fluid.material as THREE.MeshStandardMaterial;
                    animateSubstanceMaterial(fluidMat, index);
                } 
                
                // const startTime = performance.now(
            
            };

            const animateSubstanceMaterial = (material: THREE.MeshStandardMaterial, index: number) => {
                const startTime = performance.now();
                const delay = 1;
                const animateOpacity = (timestamp: number) => {
                    const elapsed = timestamp - startTime;
                    let opacityValue = elapsed / 2000 - delay;
                    if (index !== 2) {
                        material.opacity = opacityValue;
                    }
                    
                    if (opacityValue < 1) {
                        requestAnimationFrame(animateOpacity);
                    } else {
                        if (index !== 2) {
                            material.opacity = 1;
                        }
                        this.bubbleMachine.stopSpawning();
                        this.bubbleMachineSmall.stopSpawning();
                        onAnimationFinished();
                        return;
                    }
                };

                requestAnimationFrame(animateOpacity);
            };
        },
        tick(this: IBaseControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export { SceneControlComponent }