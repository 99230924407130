import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IRadioactivePenetrationSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolButtons: PoolComponent;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionAlpha_NoBarrier: THREE.AnimationAction;
	actionAlpha_Paper: THREE.AnimationAction;
	actionAlpha_Aluminium: THREE.AnimationAction;
	actionAlpha_Lead: THREE.AnimationAction;

	actionBeta_NoBarrier: THREE.AnimationAction;
	actionBeta_Paper: THREE.AnimationAction;
	actionBeta_Aluminium: THREE.AnimationAction;
	actionBeta_Lead: THREE.AnimationAction;

	actionGamma_NoBarrier: THREE.AnimationAction;
	actionGamma_Paper: THREE.AnimationAction;
	actionGamma_Aluminium: THREE.AnimationAction;
	actionGamma_Lead: THREE.AnimationAction;

	mainAnimations: any[];
	alphaAnimations: THREE.AnimationAction[];
	betaAnimations: THREE.AnimationAction[];
	gammaAnimations: THREE.AnimationAction[];

	currentClips: THREE.AnimationAction[];
	mixer: THREE.AnimationMixer;

	currentAssetId: number;
	currentMainAnimationIndex: number;

	groupElement: AFrame.Entity | null;
	radiationSrc: AFrame.Entity | null;
	barrier: AFrame.Entity | null;

	el: AFrame.Entity;

	positionATriggerBtn: AFrame.Entity | null;
	positionBTriggerBtn: AFrame.Entity | null;
	positionCTriggerBtn: AFrame.Entity | null;

	positionAButtonHandler: () => void;
	positionBButtonHandler: () => void;
	positionCButtonHandler: () => void;

	mainAnimationChange: () => void;
	secondaryAnimationChange: (secondaryAnimations: THREE.AnimationAction[]) => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const RadioactivePenetrationScene = {
	name: 'experiment',
	val: {
		init(this: IRadioactivePenetrationSceneAframe) {
			var isInitialised = false;

			this.el.addEventListener('model-loaded', () => {
				if (!isInitialised) {
					this.groupElement = document.getElementById('Experiment') as AFrame.Entity;
					this.radiationSrc = document.getElementById('radiationSrc') as AFrame.Entity;
					this.barrier = document.getElementById('barrier') as AFrame.Entity;

					// this.groupElement?.object3D.traverse((child) => {
					// 	console.log(child);
					// });

					this.currentClips = [];

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;
					this.alphaAnimations = [this.actionAlpha_NoBarrier, this.actionAlpha_Paper, this.actionAlpha_Aluminium, this.actionAlpha_Lead];
					this.betaAnimations = [this.actionBeta_NoBarrier, this.actionBeta_Paper, this.actionBeta_Aluminium, this.actionBeta_Lead];
					this.gammaAnimations = [this.actionGamma_NoBarrier, this.actionGamma_Paper, this.actionGamma_Aluminium, this.actionGamma_Lead];

					this.currentMainAnimationIndex = 0;
					this.mainAnimationChange = () => {
						if (this.currentClips) {
							this.currentClips.forEach(currentClip => {
								currentClip.stop();
							});
						}
						if (this.positionBTriggerBtn) {
							this.positionBTriggerBtn.object3D.visible = false;
							this.positionBTriggerBtn.object3D.position.x = -10;
						}

						switch (this.currentMainAnimationIndex) {
							case 1:
								this.currentClips[0] = this.actionAlpha_NoBarrier;
								this.actionAlpha_NoBarrier.reset();
								this.actionAlpha_NoBarrier.play();
								break;
							case 2:
								this.currentClips[0] = this.actionBeta_NoBarrier;
								this.actionBeta_NoBarrier.reset();
								this.actionBeta_NoBarrier.play();
								break;
							case 0:
								this.currentClips[0] = this.actionGamma_NoBarrier;
								this.actionGamma_NoBarrier.reset();
								this.actionGamma_NoBarrier.play();
								break;
							default:
								this.currentClips[0] = this.actionAlpha_NoBarrier;
								this.actionAlpha_NoBarrier.reset();
								this.actionAlpha_NoBarrier.play();
								break;
						}
						this.currentMainAnimationIndex = (this.currentMainAnimationIndex + 1) % 3;
						console.log('currentMainAnimationIndex', this.currentMainAnimationIndex)
					}

					let currentSecondaryAnimationIndex = 0;
					this.secondaryAnimationChange = (secondaryAnimations: THREE.AnimationAction[]) => {
						const currentSecondaryAnimation = secondaryAnimations[currentSecondaryAnimationIndex];
						if (this.currentClips) {
							this.currentClips.forEach(currentClip => {
								currentClip.stop();
							});
						}

						this.currentClips[1] = currentSecondaryAnimation;
						currentSecondaryAnimation.reset();
						currentSecondaryAnimation.play();

						if (this.positionBTriggerBtn) {
							switch (currentSecondaryAnimationIndex) {
								case 0:
									this.positionBTriggerBtn.object3D.visible = false;
									this.positionBTriggerBtn.object3D.position.set(0, 0, 0);
									break;
								case 1:
									this.positionBTriggerBtn.object3D.visible = true;
									this.positionBTriggerBtn.object3D.position.set(-0.52, 1.8, 0);
									break;
								case 2:
									this.positionBTriggerBtn.object3D.visible = true;
									this.positionBTriggerBtn.object3D.position.set(-0.6, 1.8, 1);
									break;
								case 3:
									this.positionBTriggerBtn.object3D.visible = true;
									this.positionBTriggerBtn.object3D.position.set(-0.5, 1.1, 0.75);
									break;
								default:
									this.positionBTriggerBtn.object3D.visible = false;
									this.positionBTriggerBtn.object3D.position.x = -10;
									break;
							}
						}


						currentSecondaryAnimationIndex = (currentSecondaryAnimationIndex + 1) % secondaryAnimations.length;
					}

					this.radiationSrc?.addEventListener('click', () => {
						console.log('radiation clicked')
						this.mainAnimationChange();
					});

					this.barrier?.addEventListener('click', () => {

						console.log('barrier clicked')
						if (this.currentClips[0] === this.actionAlpha_NoBarrier) {
							this.secondaryAnimationChange(this.alphaAnimations);
						} else {
							switch (this.currentMainAnimationIndex) {
								case 2:
									this.secondaryAnimationChange(this.alphaAnimations);
									break;
								case 0:
									this.secondaryAnimationChange(this.betaAnimations);
									break;
								case 1:
									this.secondaryAnimationChange(this.gammaAnimations);
									break;
								default:
									this.secondaryAnimationChange(this.alphaAnimations);
									break;
							}
						}
					});
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started');
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place');
					this.el.sceneEl?.removeChild(ring);
					firstSceneAnimation();
				}
			})

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: SceneHolder; offset: 0 0 0');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}

			});
			const firstSceneAnimation = () => {
				if (this.radiationSrc) {
					this.radiationSrc.object3D.visible = false;
				}
				if (this.barrier) {
					this.barrier.object3D.visible = false;
				}
				if (this.positionBTriggerBtn) {
					this.positionBTriggerBtn.object3D.visible = false;
					this.positionBTriggerBtn.object3D.position.x = 10;
				}
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}
				this.currentClips[0] = this.actionAlpha_NoBarrier;
				this.actionAlpha_NoBarrier.reset();
				this.actionAlpha_NoBarrier.play();

			}

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			const initialiseAnimations = () => {
				const animatedModelOneProcess = this.groupElement?.object3D.getObjectByName('Physics21_RadioactivePenetration_V2') as any;

				this.mixer = new THREE.AnimationMixer(animatedModelOneProcess);

				const showAlpha_NoBarrier = animatedModelOneProcess?.animations[0];
				const showAlpha_Paper = animatedModelOneProcess?.animations[1];
				const showAlpha_Aluminium = animatedModelOneProcess?.animations[2];
				const showAlpha_Lead = animatedModelOneProcess?.animations[3];

				const showBeta_NoBarrier = animatedModelOneProcess?.animations[4];
				const showBeta_Paper = animatedModelOneProcess?.animations[5];
				const showBeta_Aluminium = animatedModelOneProcess?.animations[6];
				const showBeta_Lead = animatedModelOneProcess?.animations[7];

				const showGamma_NoBarrier = animatedModelOneProcess?.animations[8];
				const showGamma_Paper = animatedModelOneProcess?.animations[9];
				const showGamma_Aluminium = animatedModelOneProcess?.animations[10];
				const showGamma_Lead = animatedModelOneProcess?.animations[11];

				this.actionAlpha_NoBarrier = this.mixer.clipAction(showAlpha_NoBarrier);
				this.actionAlpha_Paper = this.mixer.clipAction(showAlpha_Paper);
				this.actionAlpha_Aluminium = this.mixer.clipAction(showAlpha_Aluminium);
				this.actionAlpha_Lead = this.mixer.clipAction(showAlpha_Lead);

				this.actionBeta_NoBarrier = this.mixer.clipAction(showBeta_NoBarrier);
				this.actionBeta_Paper = this.mixer.clipAction(showBeta_Paper);
				this.actionBeta_Aluminium = this.mixer.clipAction(showBeta_Aluminium);
				this.actionBeta_Lead = this.mixer.clipAction(showBeta_Lead);

				this.actionGamma_NoBarrier = this.mixer.clipAction(showGamma_NoBarrier);
				this.actionGamma_Paper = this.mixer.clipAction(showGamma_Paper);
				this.actionGamma_Aluminium = this.mixer.clipAction(showGamma_Aluminium);
				this.actionGamma_Lead = this.mixer.clipAction(showGamma_Lead);
			};

			this.positionAButtonHandler = () => {

				if (this.onObjectSelected) {
					const title = 'Radioactive Source';
					const body = 'Inside this insulating box is a source of alpha (α), beta (β<span class="superscript">-</span>), or gamma (γ) radiation. Tap the box to change the type of radiation and investigate the penetration power.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionBButtonHandler = () => {

				if (this.onObjectSelected) {
					const title = 'Barrier';
					const body = 'Radiation can be stopped by a barrier. The thickness of the barrier needed depends on the penetration power of the radiation. You can tap the barrier to change it.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionCButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Geiger–Müller tube';
					const body = 'The count rate is the number of radioactive particles recorded per second and is recorded on a device called a Geiger–Müller tube. The unit for count rate is Becquerel (Bq).';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.positionATriggerBtn = this.poolButtons.requestEntity();
				this.positionATriggerBtn?.object3D.position.set(-3.85, 1.28, 0.47);
				this.positionATriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.positionATriggerBtn?.play();
				this.positionATriggerBtn?.addEventListener('click', () => {
					this.positionAButtonHandler();
					if (this.positionATriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionATriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.positionATriggerBtn;
					}
				});

				this.positionBTriggerBtn = this.poolButtons.requestEntity();
				this.positionBTriggerBtn?.object3D.position.set(0, 0.2, 2);
				this.positionBTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.positionBTriggerBtn?.play();
				this.positionBTriggerBtn?.addEventListener('click', () => {
					this.positionBButtonHandler();
					if (this.positionBTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionBTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionBTriggerBtn;
					}
				});

				this.positionCTriggerBtn = this.poolButtons.requestEntity();
				this.positionCTriggerBtn?.object3D.position.set(2.5, 0.65, 0.4);
				this.positionCTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.positionCTriggerBtn?.play();

				this.positionCTriggerBtn?.addEventListener('click', () => {
					this.positionCButtonHandler();
					if (this.positionCTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionCTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionCTriggerBtn;
					}
				});
			}
		},
		tick(this: IRadioactivePenetrationSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}

		},
	},
};
export { RadioactivePenetrationScene as RadioactivePenetrationComponent }