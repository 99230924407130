import { Stack, styled } from '@mui/material';
import { DisplayEnum } from '../../../../shared/enums';

import Background from '../../../../assets/courseContentBG.png';

export const StyledStack = styled(Stack, { name: 'StyledStack' })({
  backgroundImage: `url('${Background}')`,
  backgroundSize: '100% 681px',
  backgroundPositionY: '0%',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  maxWidth: '1440px',
  margin: '0 auto',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
    backgroundSize: '100% 450px',
    maxWidth: '100%',
  },
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
    backgroundSize: '100% 350px',
  },
});
