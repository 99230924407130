import React, { useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { CellSceneComponent } from './cell-scene';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import { ParticleImpulse } from './particle-impulse';
import { SecondParticleImpulse } from './second-particle-impulse';
import { ThirdParticleImpulse } from './third-particle-impulse';
import { FourthParticleImpulse } from './fourth-particle-impulse';
import { FifthParticleImpulse } from './fifth-particle-impulse';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
} from 'shared/constants/hint-items';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
  'Click on different parts of the nerve cell to learn about adaptations of the cell that support its function. ';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT];

const Biology1BAframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };
  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  }

  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        onAnnotationStateChanged={onAnnotationStateChanged}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./bio1b-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          WorldButtonComponent,
          FaceCameraComponent,
          CellSceneComponent,
          Annotation,
          ParticleImpulse,
          SecondParticleImpulse,
          ThirdParticleImpulse,
          FourthParticleImpulse,
          FifthParticleImpulse,
        ]}
        onObjectSelected={onObjectSelected}
        annotationState={annotationState}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { Biology1BAframe };
