import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { StyledLanguageInput } from './StyledLanguageInput';
import { useTranslation } from 'react-i18next';
import { DisplayEnum, LanguageEnum } from '../enums';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IconImage } from './IconImage';

import USA from '../../assets/united-states.svg';
import UK from '../../assets/united-kingdom.svg';
import KOREA from '../../assets/south-korea.svg';

export function LanguageSelects() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const changeLanguage = (event: { target: { value: string } }): void => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('arLanguage', event.target.value);
  };
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));

  return (
    <Select
      value={i18n.language as LanguageEnum}
      onChange={changeLanguage}
      input={<StyledLanguageInput />}
    >
      <MenuItem value={LanguageEnum.USA}>
        <Stack direction='row' alignItems='center'>
          <IconImage src={USA} width={'16px'} height={'16px'} />
          {!tablet && (
            <Typography
              fontFamily='GilroySemiBold'
              fontSize={18}
              color={theme.palette.text.primary}
              lineHeight={1}
              ml={1}
            >
              {t('COMMON.LANGUAGE.USA')}
            </Typography>
          )}
        </Stack>
      </MenuItem>
      <MenuItem value={LanguageEnum.UK}>
        <Stack direction='row' alignItems='center'>
          <IconImage src={UK} width={'16px'} height={'16px'} />
          {!tablet && (
            <Typography
              fontFamily='GilroySemiBold'
              fontSize={18}
              color={theme.palette.text.primary}
              lineHeight={1}
              ml={1}
            >
              {t('COMMON.LANGUAGE.UK')}
            </Typography>
          )}
        </Stack>
      </MenuItem>
    </Select>
  );
}
