import { AFrame } from "aframe";
import { vertexShader as _VS, fragmentShader as _FS } from './shaders/line-shader';
import { vertexShader as _VSf, fragmentShader as _FSf } from './shaders/cloud-shader';
import * as THREE from "three";

interface CloudsAframe {
    cloudShader: THREE.ShaderMaterial;
    lineShader: THREE.ShaderMaterial;
    line_1Shader: THREE.ShaderMaterial;
    line_2Shader: THREE.ShaderMaterial;
    line_3Shader: THREE.ShaderMaterial;
    line_4Shader: THREE.ShaderMaterial;
    el: AFrame['AEntity'];
}

const CloudsComponent = {
    name: "clouds",
    val: {
        init(this: CloudsAframe) {
            this.el.addEventListener('model-loaded', () => {
                const textureAnimation = () => {
                    const backgroud = this.el.object3D.getObjectByName('CloudPlane') as THREE.Mesh;
                    const backgroudLine_1 = this.el.object3D.getObjectByName('Line001') as THREE.Mesh;
                    const backgroudLine_2 = this.el.object3D.getObjectByName('Line002') as THREE.Mesh;
                    const backgroudLine_3 = this.el.object3D.getObjectByName('Line003') as THREE.Mesh;
                    const backgroudLine_4 = this.el.object3D.getObjectByName('Line004') as THREE.Mesh;
                    // console.log('background', backgroud)
                    const material = backgroud.material as THREE.MeshStandardMaterial;
                    const materialLine_1 = backgroudLine_1.material as THREE.MeshStandardMaterial;
                    const materialLine_2 = backgroudLine_1.material as THREE.MeshStandardMaterial;
                    const materialLine_3 = backgroudLine_1.material as THREE.MeshStandardMaterial;
                    const materialLine_4 = backgroudLine_1.material as THREE.MeshStandardMaterial;

                    // console.log('material', material)
                    let texture = new THREE.Texture();
                    let textureLine_1 = new THREE.Texture();
                    let textureLine_2 = new THREE.Texture();
                    let textureLine_3 = new THREE.Texture();
                    let textureLine_4 = new THREE.Texture();
                    
                    if (material) {
                        if (material.map) {
                            texture = material.map;
                        } else {
                            console.log('no texture');
                        }

                        this.cloudShader = new THREE.ShaderMaterial({
                            uniforms: {
                                u_time: { value: 0.0 },
                                u_texture: { value: texture },
                            },
                            vertexShader: _VSf,
                            fragmentShader: _FSf,
                            transparent: true,
                            blending: THREE.CustomBlending,
                            blendEquation: THREE.AddEquation,
                            blendSrc: THREE.SrcAlphaFactor,
                            blendDst: THREE.OneMinusSrcAlphaFactor,
                            depthTest: true,
                            depthWrite: false,
                        });
                        backgroud.material = this.cloudShader;
                        backgroud.material.needsUpdate = true;
                    }

                    if (materialLine_1) {
                        if (materialLine_1.map) {
                            textureLine_1 = materialLine_1.map;
                        } else {
                            console.log('no texture');
                        }

                        this.line_1Shader = new THREE.ShaderMaterial({
                            uniforms: {
                                u_time: { value: 0.0 },
                                u_texture: { value: textureLine_1 },
                            },
                            vertexShader: _VS,
                            fragmentShader: _FS,
                            transparent: true,
                            blending: THREE.CustomBlending,
                            blendEquation: THREE.AddEquation,
                            blendSrc: THREE.SrcAlphaFactor,
                            blendDst: THREE.OneMinusSrcAlphaFactor,
                            depthTest: true,
                            depthWrite: false,
                        });
                        backgroudLine_1.material = this.line_1Shader;
                        backgroudLine_1.material.needsUpdate = true;
                    }

                    if (materialLine_2) {
                        if (materialLine_2.map) {
                            textureLine_2 = materialLine_2.map;
                        } else {
                            console.log('no texture');
                        }

                        this.line_2Shader = new THREE.ShaderMaterial({
                            uniforms: {
                                u_time: { value: 0.0 },
                                u_texture: { value: textureLine_2 },
                            },
                            vertexShader: _VS,
                            fragmentShader: _FS,
                            transparent: true,
                            blending: THREE.CustomBlending,
                            blendEquation: THREE.AddEquation,
                            blendSrc: THREE.SrcAlphaFactor,
                            blendDst: THREE.OneMinusSrcAlphaFactor,
                            depthTest: true,
                            depthWrite: false,
                        });
                        backgroudLine_2.material = this.line_2Shader;
                        backgroudLine_2.material.needsUpdate = true;
                    }

                    if (materialLine_3) {
                        if (materialLine_3.map) {
                            textureLine_3 = materialLine_3.map;
                        } else {
                            console.log('no texture');
                        }

                        this.line_3Shader = new THREE.ShaderMaterial({
                            uniforms: {
                                u_time: { value: 0.0 },
                                u_texture: { value: textureLine_3 },
                            },
                            vertexShader: _VS,
                            fragmentShader: _FS,
                            transparent: true,
                            blending: THREE.CustomBlending,
                            blendEquation: THREE.AddEquation,
                            blendSrc: THREE.SrcAlphaFactor,
                            blendDst: THREE.OneMinusSrcAlphaFactor,
                            depthTest: true,
                            depthWrite: false,
                        });
                        backgroudLine_3.material = this.line_3Shader;
                        backgroudLine_3.material.needsUpdate = true;
                    }

                    if (materialLine_4) {
                        if (materialLine_4.map) {
                            textureLine_4 = materialLine_4.map;
                        } else {
                            console.log('no texture');
                        }

                        this.line_4Shader = new THREE.ShaderMaterial({
                            uniforms: {
                                u_time: { value: 0.0 },
                                u_texture: { value: textureLine_4 },
                            },
                            vertexShader: _VSf,
                            fragmentShader: _FSf,
                            transparent: true,
                            blending: THREE.CustomBlending,
                            blendEquation: THREE.AddEquation,
                            blendSrc: THREE.SrcAlphaFactor,
                            blendDst: THREE.OneMinusSrcAlphaFactor,
                            depthTest: true,
                            depthWrite: false,
                        });
                        backgroudLine_4.material = this.line_4Shader;
                        backgroudLine_4.material.needsUpdate = true;
                    }
                }
                textureAnimation();
            });
        },
        tick(this: CloudsAframe, time: number, timeDelta: number) {
            if (this.cloudShader) {
                this.cloudShader.uniforms.u_time.value += timeDelta * 0.001;
            }
            if (this.line_1Shader) {
                this.line_1Shader.uniforms.u_time.value += timeDelta * 0.00022;
            }
            if (this.line_2Shader) {
                this.line_2Shader.uniforms.u_time.value += timeDelta * 0.00018;
            }
            if (this.line_3Shader) {
                this.line_3Shader.uniforms.u_time.value += timeDelta * 0.00026;
            }
            if (this.line_4Shader) {
                this.line_4Shader.uniforms.u_time.value += timeDelta * 0.00032;
            }
        },

    },
};
export { CloudsComponent as ShaderCloudsComponent }