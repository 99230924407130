import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface ICarbonFootprintSceneAframe {
    buttonHolder: AFrame.Entity[];
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    carbonFootprintButtonHandler: () => void;
    taxesButtonHandler: () => void;
    carbonCaptureButtonHandler: () => void;
    reducingWasteButtonHandler: () => void;
    renewableEnergyButtonHandler: () => void;
    mixer: THREE.AnimationMixer;
    carbonFootprintAnimation: THREE.AnimationAction;
    carbonCaptureAnimation: THREE.AnimationAction;
    taxesAnimation: THREE.AnimationAction;
    reducingWasteAnimation: THREE.AnimationAction;
    renewableEnergyAnimation: THREE.AnimationAction;
    currentAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; imageExtra?: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    currentAssetId: number;
    smoke: any;
    smoke2: any;
    smokeFarm: any;
    smokeCows: any;
}

const LessonStart = {
    name: 'planet-scene',
    val: {
        init(this: ICarbonFootprintSceneAframe) {
            const planet = document.getElementById('planetHolder') as AFrame.Entity;
            planet.addEventListener('model-loaded', () => {
                const planet = document.getElementById('planet') as AFrame.Entity;
                planet.setAttribute('lightning', '');
                let animatedEl = planet.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [, , , , , CarbonCapture, Taxes, CarbonFootprint, ReducingWaste, RenewableEnergy] = animatedEl.animations
                this.carbonFootprintAnimation = this.mixer.clipAction(CarbonFootprint)
                this.taxesAnimation = this.mixer.clipAction(Taxes)
                this.carbonCaptureAnimation = this.mixer.clipAction(CarbonCapture)
                this.reducingWasteAnimation = this.mixer.clipAction(ReducingWaste)
                this.renewableEnergyAnimation = this.mixer.clipAction(RenewableEnergy)
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: planetHolder; scale: 10 10 10; offset: 0 -2 -1');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.carbonFootprintButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.carbonFootprintAnimation.setLoop(THREE.LoopOnce, 1)
                this.carbonFootprintAnimation.clampWhenFinished = true
                this.carbonFootprintAnimation.play()
                this.currentAnimation = this.carbonFootprintAnimation
                if (this.onObjectSelected) {
                    const title = 'Carbon Footprint';
                    const body = 'Almost everything, such as buses, factories, and even your phone have carbon footprints - the CO<sub>2</sub> emissions produced over their life cycle. The carbon footprint calculation isn\'t perfect, but enables us to identify the worst emitters.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.taxesButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.taxesAnimation.play()
                this.currentAnimation = this.taxesAnimation
                if (this.onObjectSelected) {
                    const title = 'Taxes';
                    const body = 'Some governments tax companies (or individuals) on the amount of emissions or waste they produce, or require paid licences to emit high amounts of greenhouse gases.<br/><b>Problem</b>: governments worry this may reduce economic growth.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.carbonCaptureButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.carbonCaptureAnimation.play()
                this.currentAnimation = this.carbonCaptureAnimation
                if (this.onObjectSelected) {
                    const title = 'Carbon Capture';
                    const body = 'New technologies are being developed to reduce greenhouse gas emissions from the atmosphere. Carbon capture works by capturing CO<sub>2</sub> and storing it deep within the Earth, often in old oil wells.<br/><b>Problem</b>: high-cost and many concerns around effectiveness.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.reducingWasteButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.reducingWasteAnimation.play()
                this.currentAnimation = this.reducingWasteAnimation
                if (this.onObjectSelected) {
                    const title = 'Reducing Waste';
                    const body = 'Some waste produces CO<sub>2</sub> and methane as it decomposes. Processes that produce less waste can reduce carbon footprints.<br/><b>Problem</b>: alternative processes may be more costly or have other environmental implications.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.renewableEnergyButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.renewableEnergyAnimation.play()
                this.currentAnimation = this.renewableEnergyAnimation
                if (this.onObjectSelected) {
                    const title = 'Renewable energy';
                    const body = 'The burning of fossil fuels releases billions of tonnes of CO<sub>2</sub> per year. Opting for renewable energy sources (such as wind or solar) or nuclear energy are ways to reduce carbon footprints.<br/><b>Problem</b>: renewable energy is intermittent (sometimes unreliable)';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // button holder
                this.buttonHolder = [];
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const carbonFootprintTriggerBtn = poolButtons.requestEntity()
                carbonFootprintTriggerBtn?.setAttribute('position', '0.18 0.28 0.1')
                carbonFootprintTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3');
                carbonFootprintTriggerBtn?.play()
                carbonFootprintTriggerBtn?.addEventListener('click', () => {
                    this.carbonFootprintButtonHandler()
                    if (carbonFootprintTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(carbonFootprintTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (carbonFootprintTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = carbonFootprintTriggerBtn
                    }
                });
                if (carbonFootprintTriggerBtn)
                    this.buttonHolder.push(carbonFootprintTriggerBtn);

                const taxesTriggerBtn = poolButtons.requestEntity()
                taxesTriggerBtn?.setAttribute('position', '-0.25 0.27 0')
                taxesTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                taxesTriggerBtn?.play()
                taxesTriggerBtn?.addEventListener('click', () => {
                    this.taxesButtonHandler()
                    if (taxesTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(taxesTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (taxesTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = taxesTriggerBtn
                    }
                });
                if (taxesTriggerBtn)
                    this.buttonHolder.push(taxesTriggerBtn);

                const carbonCaptureTriggerBtn = poolButtons.requestEntity()
                carbonCaptureTriggerBtn?.setAttribute('position', '-0.21 0.02 0.3')
                carbonCaptureTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                carbonCaptureTriggerBtn?.play()
                carbonCaptureTriggerBtn?.addEventListener('click', () => {
                    this.carbonCaptureButtonHandler()
                    if (carbonCaptureTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(carbonCaptureTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (carbonCaptureTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = carbonCaptureTriggerBtn
                    }
                });
                if (carbonCaptureTriggerBtn)
                    this.buttonHolder.push(carbonCaptureTriggerBtn);

                const reducingWasteTriggerBtn = poolButtons.requestEntity()
                reducingWasteTriggerBtn?.setAttribute('position', '0.29 0.15 0.05')
                reducingWasteTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                reducingWasteTriggerBtn?.play()
                reducingWasteTriggerBtn?.addEventListener('click', () => {
                    this.reducingWasteButtonHandler()
                    if (reducingWasteTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(reducingWasteTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (reducingWasteTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = reducingWasteTriggerBtn
                    }
                });
                if (reducingWasteTriggerBtn)
                    this.buttonHolder.push(reducingWasteTriggerBtn);

                const renewableEnergyTriggerButton = poolButtons.requestEntity();
                renewableEnergyTriggerButton?.setAttribute('position', '-0.22 -0.32 -0.13');
                renewableEnergyTriggerButton?.setAttribute('scale', '0.3 0.3 0.3');
                renewableEnergyTriggerButton?.play()
                renewableEnergyTriggerButton?.addEventListener('click', () => {
                    this.renewableEnergyButtonHandler()
                    if (renewableEnergyTriggerButton) {
                        this.annotationComponent.setObjectToFollow(renewableEnergyTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (renewableEnergyTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = renewableEnergyTriggerButton
                    }
                });
                if (renewableEnergyTriggerButton)
                    this.buttonHolder.push(renewableEnergyTriggerButton);
            };
        },
        tick(this: ICarbonFootprintSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {LessonStart as CarbonFootprintSceneComponent}

