// A React component for 8th Wall AFrame scenes. The scene HTML can be supplied, along with
// any components or primitives that should be registered, and any image targets that should be
// loaded if something other than the automatically loaded set is wanted. Passing
// DISABLE_IMAGE_TARGETS will prevent any image targets from loading, including ones that would

import { PrimitiveDefinition } from "aframe"
import React, { useEffect } from 'react'
import { LoadersPermissonsEnum } from "shared/enums"

// otherwise enabled automatically.
declare let window: any
declare let XR8: any
// Helper function to make sure that aframe components are only registered once, since they can't
// be cleanly unregistered.
const registeredComponents = new Set()
const registerComponents = (components: Component[]) => components.forEach(({ name, val }) => {
    if (registeredComponents.has(name)) {
        return
    }
    registeredComponents.add(name)
    AFRAME.registerComponent(name, val)
})
// Helper function to make sure that aframe primitives are only registered once, since they can't
// be cleanly unregistered.
const registeredPrimitives = new Set()
const registerPrimitives = (primitives: Primitives[]) => primitives.forEach(({ name, val }) => {
    if (registeredPrimitives.has(name)) {
        return
    }
    registeredPrimitives.add(name)
    AFRAME.registerPrimitive(name, val)
})

// Helper function to make sure that aframe systems are only registered once, since they can't
// be cleanly unregistered.
const registeredSystems = new Set();
const registerSystems = (systems: INamedObject[]) => systems.forEach(({ name, val }) => {
    if (registeredSystems.has(name)) {
        return;
    }
    registeredSystems.add(name);
    AFRAME.registerSystem(name, val);
});

interface INamedObject {
    name: string
    val: object
}

interface ISystemFunctions {
    [functionName: string]: Function;
}

interface IMethodSystem {
    name: string;
    val: {
      registerCallback: (name: string, fn: Function) => void;
    };
  }

interface IAFrameSceneProps {
    sceneHtml: string
    imageTargets?: string[]
    components?: INamedObject[]
    primitives?: INamedObject[]
    systems?: (INamedObject | IMethodSystem)[]
    systemFunctions?: ISystemFunctions;
    permissionsСonfirmation?: (status: LoadersPermissonsEnum) => void;
}
// A react component for loading and unloading an aframe scene. The initial scene contents should
// be specified as an html string in sceneHtml. All props must be specified when the component
// mounts. Updates to props will be ignored.
//
// Optionally, aframe coponents to register for this scene can be passed as [{name, val}] arrays.
// Care is needed here to not define the same component different across scenes, since aframe
// components can't be unloaded.
//
// Optionally imageTargets can be specified to override the set loaded by default.
function AFrameComponent({ sceneHtml, imageTargets, components, primitives, systems, systemFunctions, permissionsСonfirmation}: IAFrameSceneProps) {
 
    React.useEffect(() => {
        if (imageTargets) {
            const configureImageTargets = () => {
                XR8.XrController.configure({ imageTargets })
                XR8.addCameraPipelineModule({
                    name: 'camerastartupmodule',
                        onCameraStatusChange: ({status}: any) => {
                            if (status === 'hasVideo') {
                                permissionsСonfirmation!(LoadersPermissonsEnum.CameraAccessSuccess);
                            } else if (status === 'failed') {
                                permissionsСonfirmation!(LoadersPermissonsEnum.CameraAccessDenied);
                            }
                        },
                })

                XR8.addCameraPipelineModule({
                    name: 'mycamerapipelinemodule',
                        onException : (error: any) => {
                        if (error && error?.permission === 'deviceorientation') {
                            permissionsСonfirmation!(LoadersPermissonsEnum.MotionOrientationDenied);
                            return
                        }
                        permissionsСonfirmation!(LoadersPermissonsEnum.WallPermissionsDenied);
                    },
                })
            }
            window.XR8 ? configureImageTargets() : window.addEventListener('xrloaded', configureImageTargets)
        }
        if (components) {
            registerComponents(components)
        }
        if (primitives) {
            registerPrimitives(primitives)
        }

        if (systems) {
            registerSystems(systems);

            // Look for the 'method-system'
            const methodSystem = systems.find(system => system.name === 'method-system');

            // If found, register each function in systemFunctions with it
            if (methodSystem && systemFunctions) {
                Object.entries(systemFunctions).forEach(([name, fn]) => {
                    (methodSystem as IMethodSystem).val.registerCallback(name, fn);
                });
            }
        }
        
        const html = document.getElementsByTagName('html')[0]
        const origHtmlClass = html.className
        document.body.insertAdjacentHTML('beforeend', sceneHtml)

        // Cleanup
        return () => {
            const ascene = document.getElementsByTagName('a-scene')[0]
            if (ascene && ascene.parentNode) {
                ascene.parentNode.removeChild(ascene)
                html.className = origHtmlClass
            }
        }
    }, [])
    return (
        <React.Fragment></React.Fragment>
    )
}
const DISABLE_IMAGE_TARGETS: string[] = []
export { AFrameComponent, DISABLE_IMAGE_TARGETS }


interface Primitives {
    name: string, 
    val: PrimitiveDefinition
}

interface Component {
    name: string,
    val: any
}