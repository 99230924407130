import { Box, Typography, useTheme } from '@mui/material';
import { StyledOverviewMenuItem } from './StyledOverviewMenuItem';

type Props = {
  title: string;
  value: number;
  isActive: boolean;
  handleStatusChange: (type?: number) => void;
};

export function CourseStatisticItem(props: Props) {
  const { isActive, value, title, handleStatusChange } = props;
  const theme = useTheme();

  if (isActive) {
    return (
      <StyledOverviewMenuItem onClick={() => handleStatusChange()}>
        <Typography fontFamily='GilroySemiBold' color='#262626'>
          {title}
        </Typography>
        <Typography fontFamily='GilroySemiBold' color='#262626'>
          {value}
        </Typography>
      </StyledOverviewMenuItem>
    );
  }

  return (
    <Box
      sx={{ cursor: 'pointer' }}
      onClick={() => handleStatusChange()}
      display='flex'
      justifyContent='space-between'
      padding='13px 20px'
    >
      <Typography fontFamily='GilroyMedium' color={theme.palette.text.primary}>
        {title}
      </Typography>
      <Typography fontFamily='GilroyMedium' color={theme.palette.text.primary}>
        {value}
      </Typography>
    </Box>
  );
}
