import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    pointLight2: AFrame.Entity;
    pointLight: AFrame.Entity;
    fillMaterial: THREE.MeshStandardMaterial;
    fillMaterial2: THREE.MeshStandardMaterial;
    lightMesh1: THREE.Mesh;
    lightMesh2: THREE.Mesh;
    meshMaterial1: THREE.MeshStandardMaterial;
    meshMaterial2: THREE.MeshStandardMaterial;
    coil1: THREE.Mesh;
    coil2: THREE.Mesh;
    lampActive: boolean;
    cellActive: boolean;
    ammeterActive: boolean;
    voltmeterActive: boolean;
    voltText: AFrame.Entity;
    ammText: AFrame.Entity;
    voltTriggerBtn: AFrame.Entity;
    ampTriggerBtn: AFrame.Entity;
    lampTriggerBtn2: AFrame.Entity;
    cellTriggerBtn2: AFrame.Entity;
    currentDeactivatedButton: AFrame.Entity;
    poolEntity: AFrame.Entity;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    baseMesh: THREE.Mesh;
    material: THREE.MeshStandardMaterial;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                this.baseMesh = this.el.object3D.getObjectByName('Base') as THREE.Mesh
                this.material = this.baseMesh.material as THREE.MeshStandardMaterial;
            
                createShaderMaterials();

                setDefaultState();


                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                    aoMapSwitcher()
                }
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: circuit; scale: 21 21 21; offset: 0 0 -3');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }

            });

            this.el.sceneEl?.addEventListener('asset-toggle', (e) => {
                const ce = e as CustomEvent;
                const assetId = ce.detail.assetId;
                switch (assetId) {
                    case 0:
                        toggleCells();
                        break;
                    case 1:
                        toggleLamps();
                        break;
                    case 2:
                        toggleAmmeter();
                        break;
                    case 3:
                        toggleVoltmeter();
                        break;
                    default:
                        console.warn('lesson-start.tsx:Unknown asset id: ', assetId);
                }
                aoMapSwitcher()

            });

            const textureDictionary: { [key: string]: THREE.Texture } = {};
            const aoMapSwitcher = () => {

                const getKey = (lampActive: boolean, ammeterActive: boolean, voltmeterActive: boolean, cellActive: boolean) => {
                    return `${Number(lampActive)}${Number(ammeterActive)}${Number(voltmeterActive)}${Number(cellActive)}`;
                };

                const key = getKey(this.lampActive, this.ammeterActive, this.voltmeterActive, this.cellActive);
               
                if (textureDictionary[key]) {
                    this.material.aoMap = textureDictionary[key];
                    this.material.needsUpdate = true;
                } else {
                    const imgEl = document.getElementById(`${key}`) as HTMLImageElement;
                    const text = new THREE.Texture();
                    text.image = imgEl;
                    text.flipY = false;
                    text.needsUpdate = true;
                    textureDictionary[key] = text;
                    this.material.aoMap = text;
                    this.material.needsUpdate = true;
                }
            };

            const createShaderMaterials = () => {
                const lightBulb = this.el.object3D.getObjectByName('FilamentBulb1') as THREE.Object3D;
                const lightBulb2 = this.el.object3D.getObjectByName('FilamentBulb2') as THREE.Object3D;
                // gradients on the model should be removed
                this.lightMesh1 = this.el.object3D.getObjectByName('Light001') as THREE.Mesh;
                this.lightMesh2 = this.el.object3D.getObjectByName('Light002') as THREE.Mesh;
                this.lightMesh1.visible = false;
                this.lightMesh2.visible = false;
                // get the new gradients
                const obj1 = document.getElementById('plane1') as AFrame.Entity;
                const obj2 = document.getElementById('plane2') as AFrame.Entity;
                this.lightMesh1 = obj1.object3D.children[0] as THREE.Mesh;
                this.lightMesh1.visible = false;
                this.lightMesh2 = obj2.object3D.children[0] as THREE.Mesh;

                this.coil1 = this.el.object3D.getObjectByName('Coil') as THREE.Mesh;
                this.fillMaterial = this.coil1.material as THREE.MeshStandardMaterial;
                this.fillMaterial.emissiveIntensity = 0;
                this.coil2 = this.el.object3D.getObjectByName('Coil001') as THREE.Mesh;
                this.fillMaterial2 = this.coil2.material as THREE.MeshStandardMaterial;
                this.fillMaterial2.emissiveIntensity = 0;

                const fill = lightBulb.getObjectByName('Coil') as THREE.Mesh;
                const fill2 = lightBulb2.getObjectByName('Coil001') as THREE.Mesh;

                this.fillMaterial = fill.material as THREE.MeshStandardMaterial;
                this.fillMaterial2 = fill2.material as THREE.MeshStandardMaterial;

                this.fillMaterial.emissiveIntensity = 0;

                const light = document.createElement('a-light');
                light.setAttribute('type', 'point');
                light.setAttribute('color', '#ffffff');
                light.setAttribute('intensity', '1');
                light.setAttribute('distance', '100');
                light.setAttribute('decay', '2');
                light.setAttribute('position', '-0.0795 0.115 0.15');
                this.pointLight = light;
                this.el.appendChild(this.pointLight);

                const light2 = document.createElement('a-light');
                light2.setAttribute('type', 'point');
                light2.setAttribute('color', '#ffffff');
                light2.setAttribute('intensity', '1');
                light2.setAttribute('distance', '100');
                light2.setAttribute('decay', '2');
                light2.setAttribute('position', '0.0795 0.115 0.15');
                this.pointLight2 = light2;
                this.el.appendChild(this.pointLight2);

            };
            const setLightbulbIntensity = (intensity: number) => {
                this.meshMaterial1 = this.lightMesh1.material as THREE.MeshStandardMaterial;

                this.meshMaterial2 = this.lightMesh2.material as THREE.MeshStandardMaterial;

                switch (intensity) {
                    case 0:
                        if (this.fillMaterial) {
                            this.fillMaterial.emissiveIntensity = 0.25;
                            this.fillMaterial.needsUpdate = true;
                        }
                        if (this.fillMaterial2) {
                            this.fillMaterial2.emissiveIntensity = 0.25;
                            this.fillMaterial2.needsUpdate = true;
                        }
                     
                        this.lightMesh1.scale.set(1, 1, 1)
                        this.lightMesh2.scale.set(1, 1, 1)
                        this.meshMaterial1.color.set(0xffa500)
                        this.meshMaterial2.color.set(0xffa500)
                        this.meshMaterial1.opacity = 0.6
                        this.meshMaterial2.opacity = 0.6
                        this.pointLight.setAttribute('intensity', '0.4');
                        if (this.lampActive) {
                            this.pointLight2.setAttribute('intensity', '0.4');
                        } else {
                            this.pointLight2.setAttribute('intensity', '0');
                        }
                        
                        break;
                    case 1:
                        if (this.fillMaterial) {
                            this.fillMaterial.emissiveIntensity = 0.5;
                            this.fillMaterial.needsUpdate = true;
                        }
                        if (this.fillMaterial2) {
                            this.fillMaterial2.emissiveIntensity = 0.5;
                            this.fillMaterial2.needsUpdate = true;
                        }
                        this.lightMesh1.scale.set(1.5, 1.5, 1)
                        this.lightMesh2.scale.set(1.5, 1.5, 1)
                        
                        this.meshMaterial1.color.set(0xfffe66)
                        this.meshMaterial2.color.set(0xfffe66)
                        this.meshMaterial1.opacity = 0.8
                        this.meshMaterial2.opacity = 0.8
                        //setting lightss to 0.75
                        this.pointLight.setAttribute('intensity', '0.75');
                        if (this.lampActive) {
                            this.pointLight2.setAttribute('intensity', '0.75');
                        } else {
                            this.pointLight2.setAttribute('intensity', '0');
                        }
                        break;
                    case 2:
                        if (this.fillMaterial) {
                            this.fillMaterial.color = new THREE.Color(0xffffff);
                            this.fillMaterial.emissiveIntensity = 1;
                            this.fillMaterial.needsUpdate = true;
                        }
                        if (this.fillMaterial2) {
                            this.fillMaterial2.color = new THREE.Color(0xffffff);
                            this.fillMaterial2.emissiveIntensity = 1;
                            this.fillMaterial2.needsUpdate = true;
                        }
                        this.lightMesh1.scale.set(2, 2, 1)
                        this.lightMesh2.scale.set(2, 2, 1)
                        this.meshMaterial1.color.set(0xffffff)
                        this.meshMaterial2.color.set(0xffffff)
                        this.meshMaterial1.opacity = 1
                        this.meshMaterial2.opacity = 1
                        this.pointLight.setAttribute('intensity', '1');
                        if (this.lampActive) {
                            this.pointLight2.setAttribute('intensity', '1');
                        } else {
                            this.pointLight2.setAttribute('intensity', '0');
                        }
                        break;
                    default:
                        break;
                }
            };

            /**
             * Set the default state of the model
             * Only one bulb, one battery cell are visible
             */
            const setDefaultState = () => {
                this.el.object3D.traverse((mesh) => {
                    if (mesh.name && mesh.name === 'Voltmeter') {
                        mesh.visible = false
                    } else if (mesh.name && mesh.name === 'Wires_Voltmeter') {
                        mesh.visible = false
                    } else if (mesh.name && mesh.name === 'Ammeter2') {
                        mesh.visible = false
                    } else if (mesh.name && mesh.name === 'FilamentBulb2') {
                        mesh.visible = false
                    } else if (mesh.name && mesh.name === 'BatteryCell2') {
                        mesh.visible = false
                    }
                });
                setVoltmeterDigits();
                setAmmeterDigits();
                updateCircuitDyamicValues();
            };

            /**
             * Toggle between lamps
             */
            this.lampActive = false;
            const toggleLamps = () => {
                const lamp2 = this.el.object3D.getObjectByName('FilamentBulb2') as THREE.Object3D;
                if (lamp2) {
                    this.lampActive = !this.lampActive;
                    lamp2.visible = this.lampActive;
                    this.lightMesh1.visible = this.lampActive
                    this.lampTriggerBtn2?.setAttribute('visible', this.lampActive);
                    updateCircuitDyamicValues();
                }
            };

            /**
             * Toggle between battery cells
             */
            this.cellActive = false;
            const toggleCells = () => {
                const cell2 = this.el.object3D.getObjectByName('BatteryCell2') as THREE.Object3D;
                if (cell2) {
                    this.cellActive = !this.cellActive;
                    cell2.visible = this.cellActive;
                    this.cellTriggerBtn2?.setAttribute('visible', this.cellActive);
                    updateCircuitDyamicValues();
                }
            };

            /**
             * Toggle between ammeter visibility
             */
            this.ammeterActive = false;
            const toggleAmmeter = () => {
                const ammeter = this.el.object3D.getObjectByName('Ammeter2') as THREE.Object3D;
                if (ammeter) {
                    this.ammeterActive = !this.ammeterActive;
                    ammeter.visible = this.ammeterActive;
                    this.ampTriggerBtn?.setAttribute('visible', this.ammeterActive);
                    this.ammText?.setAttribute('visible', this.ammeterActive);
                }
            };

            /**
             * Toggle between voltmeter visibility
             */
            this.voltmeterActive = false;
            const toggleVoltmeter = () => {
                const voltmeter = this.el.object3D.getObjectByName('Voltmeter') as THREE.Object3D;
                const voltmeterWires = this.el.object3D.getObjectByName('Wires_Voltmeter') as THREE.Mesh;
                if (voltmeter) {
                    this.voltmeterActive = !this.voltmeterActive;
                    voltmeter.visible = this.voltmeterActive;
                    voltmeterWires.visible = this.voltmeterActive;
                    this.voltTriggerBtn?.setAttribute('visible', this.voltmeterActive);
                    this.voltText?.setAttribute('visible', this.voltmeterActive);
                }
            };

            /**
             * Initialise the world buttons from pool
             */
            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                // first cell trigger btn
                const cellTriggerBtn = poolButtons.requestEntity();
                if (cellTriggerBtn) {
                    cellTriggerBtn.setAttribute('position', '-0.075 0.065 0');
                    cellTriggerBtn.play();
                    cellTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Cell'
                            const body = 'A cell provides potential difference (voltage) to a circuit. This gives power to all of the components in the circuit. Without a source of potential difference, current will not flow around the circuit.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(cellTriggerBtn);
                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (cellTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = cellTriggerBtn

                    });
                } else {
                    console.log('No trigger btn');
                }

                // 2nd cell trigger btn
                this.cellTriggerBtn2 = poolButtons.requestEntity() as AFrame.Entity;
                if (this.cellTriggerBtn2) {
                    this.cellTriggerBtn2.setAttribute('position', '0.075 0.065 0');
                    this.cellTriggerBtn2.play();
                    this.cellTriggerBtn2.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Cell'
                            const body = 'A cell provides potential difference (voltage) to a circuit. This gives power to all of the components in the circuit. Without a source of potential difference, current will not flow around the circuit.'
                            this.onObjectSelected({title, body})
                            if (this.cellTriggerBtn2) {
                                this.annotationComponent.setObjectToFollow(this.cellTriggerBtn2);
                            }

                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.cellTriggerBtn2?.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.cellTriggerBtn2

                    });
                } else {
                    console.warn('No trigger btn: cellTriggerBtn2');
                }
                this.cellTriggerBtn2?.setAttribute('visible', false);

                // first lamp trigger btn
                const lampTriggerBtn = poolButtons.requestEntity();
                if (lampTriggerBtn) {
                    lampTriggerBtn.setAttribute('position', '-0.045 0.065 0.18');
                    lampTriggerBtn.play();
                    lampTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Filament Bulb'
                            const body = 'A filament bulb is a component that lights up when a current runs through it. The brightness of a bulb increases when there is a higher current running through it.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(lampTriggerBtn);
                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (lampTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = lampTriggerBtn

                    });
                } else {
                    console.log('No trigger btn');
                }

                // 2nd lamp trigger btn
                this.lampTriggerBtn2 = poolButtons.requestEntity() as AFrame.Entity;
                if (this.lampTriggerBtn2) {
                    this.lampTriggerBtn2.setAttribute('position', '0.09 0.065 0.18');
                    this.lampTriggerBtn2.play();
                    this.lampTriggerBtn2.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Filament Bulb'
                            const body = 'A filament bulb is a component that lights up when a current runs through it. The brightness of a bulb increases when there is a higher current running through it.'
                            this.onObjectSelected({title, body})
                            if (this.lampTriggerBtn2) {
                                this.annotationComponent.setObjectToFollow(this.lampTriggerBtn2);
                            }
                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.lampTriggerBtn2?.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.lampTriggerBtn2

                    });
                } else {
                    console.log('No trigger btn');
                }
                this.lampTriggerBtn2?.setAttribute('visible', false);

                // amp trigger btn
                this.ampTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                if (this.ampTriggerBtn) {
                    this.ampTriggerBtn.setAttribute('position', '-0.18 0.05 0.13');
                    this.ampTriggerBtn.play();
                    this.ampTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Ammeter'
                            const body = 'When placed in series, an ammeter displays the current flowing through the circuit in amperes A. In a series circuit, the current is the same all the way around the loop.'
                            this.onObjectSelected({title, body})
                            if (this.ampTriggerBtn) {
                                this.annotationComponent.setObjectToFollow(this.ampTriggerBtn);
                            }
                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.ampTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.ampTriggerBtn

                    });
                } else {
                    console.log('No trigger btn');
                }
                this.ampTriggerBtn?.setAttribute('visible', false);

                // amp trigger btn
                this.voltTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                if (this.voltTriggerBtn) {
                    this.voltTriggerBtn.setAttribute('position', '0.025 0.05 0.225');
                    this.voltTriggerBtn.play();
                    this.voltTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Voltmeter'
                            const body = 'When placed over a component (in parallel), a voltmeter displays the potential difference over that component in volts V.<br/>In a series circuit, the voltage over the power supply is the voltage of all the other components added together.'
                            this.onObjectSelected({title, body})
                            if (this.voltTriggerBtn) {
                                this.annotationComponent.setObjectToFollow(this.voltTriggerBtn);
                            }
                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.voltTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.voltTriggerBtn

                    });
                } else {
                    console.warn('No trigger btn Voltmeter');
                }
                this.voltTriggerBtn?.setAttribute('visible', false);

                // wire trigger btn
                const wireTriggerBtn = poolButtons.requestEntity();
                if (wireTriggerBtn) {
                    wireTriggerBtn.setAttribute('position', '0.2 0.025 0.075');
                    wireTriggerBtn.play();
                    wireTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Wire'
                            const body = 'The conductive wires allow the flow of electric charges around the circuit. Charges carry energy from the power supply to the components.<br/>The wire must connect the circuit into a closed loop with no breaks for current to flow.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(wireTriggerBtn);

                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (wireTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = wireTriggerBtn

                    });
                } else {
                    console.warn('No trigger btn: wires');
                }
            };


            const setAmmeterDigits = () => {
                if (!this.ammText) {
                    const ammText = document.createElement('a-entity')
                    ammText.setAttribute('text', "value: 2.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    ammText.setAttribute('position', '0.272 0.025 0.08');
                    ammText.setAttribute('scale', '0.1 0.1 0.1');
                    ammText.setAttribute('rotation', '-90 0 0');
                    ammText.setAttribute('visible', false);
                    this.el.appendChild(ammText);
                    this.ammText = ammText;
                }

            }

            const setVoltmeterDigits = () => {
                const voltText = document.createElement('a-entity')
                voltText.setAttribute('text', "value: 1.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                voltText.setAttribute('position', '0.4705 0.025 0.2475');
                voltText.setAttribute('scale', '0.1 0.1 0.1');
                voltText.setAttribute('rotation', '-90 0 0');
                voltText.setAttribute('visible', false);
                this.el.appendChild(voltText);
                this.voltText = voltText;
            }

            /**
             * updating all the values that could change on certain interactions
             * Volt/Amm readings and bulb brightness
             */
            const updateCircuitDyamicValues = () => {
                if (this.cellActive && !this.lampActive) {
                    // 1 lamp and two cells
                    this.ammText.setAttribute('text', "value: 4.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    this.voltText.setAttribute('text', "value: 4.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    setLightbulbIntensity(2); // very bright
                } else if (this.lampActive && !this.cellActive) {
                    // 2 lamps and one cell
                    this.ammText.setAttribute('text', "value: 1.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    this.voltText.setAttribute('text', "value: 2.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    setLightbulbIntensity(0); // very bright
                } else if (this.lampActive && this.cellActive) {
                    // 2 lamps and two cells
                    this.ammText.setAttribute('text', "value: 2.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    this.voltText.setAttribute('text', "value: 4.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    setLightbulbIntensity(1); // bright
                } else {
                    // 1 lamp and one cell
                    this.ammText.setAttribute('text', "value: 2.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    this.voltText.setAttribute('text', "value: 2.0; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    setLightbulbIntensity(1); // bright
                }
            }
        },
    },
};
export {CircuitControlComponent}
