import React from 'react';
import * as AFRAME from 'aframe'
import './ai-button.css'



class AIButton extends React.Component {

    recognition: SpeechRecognition | undefined;
    audioElement: HTMLAudioElement = new Audio();
    isRecordingAllowed = false;
    isAudioUnlocked = false;
    isPlayingAllowed = false;

    componentDidMount() {
        this.requestMicrophonePermissions();
        // Attach the event listener when the component mounts
        window.addEventListener('robot-clicked', this.startRecording);
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        window.addEventListener('robot-state-change', this.handleRobotStateChange as EventListener);

        if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
            this.recognition = new (window.SpeechRecognition ||
              window.webkitSpeechRecognition)();
      
            this.recognition.interimResults = true;
      
            this.recognition.onresult = (event) => {
              // don't do anything when minimised
              if (!this.isRecordingAllowed) return;
      
              const lastResultIndex = event.results.length - 1;
              const transcript = event.results[lastResultIndex][0].transcript;
      
              if (event.results[lastResultIndex].isFinal) {
                console.log("Final result received.");
                this.handleTranscriptionComplete(transcript);
              }
            };
      
            this.recognition.onerror = (event) => {
              console.error("Error occurred in recognition:", event.error);

            };
        }
    }

    componentWillUnmount() {
        // Clean up the event listener when the component unmounts
        window.removeEventListener('robot-clicked', this.startRecording);
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        window.removeEventListener('robot-state-change', this.handleRobotStateChange as EventListener);
        
    }

    startRecording = () => {
        // Start recording here
        console.log('Event received! Start recording...');
        // Your recording logic goes here
        if (!this.recognition) {
            console.error("Speech recognition is not supported in this browser.");
            return;
        }

        
        const ascene = document.getElementsByTagName('a-scene')[0];
        const aframeScene = ascene as AFRAME.Scene;
        aframeScene.emit('recognition-started');

        this.isRecordingAllowed = true;
        this.isPlayingAllowed = true;
        this.recognition.start();
    }

    requestMicrophonePermissions = () => {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            stream.getTracks().forEach((track) => track.stop()); // Stop capturing the stream
          })
          .catch((error) => {
            console.error("Error accessing microphone:", error);
          });
    };

    handleTranscriptionComplete = (transcription: string) => {
    
        this.recognition?.stop();
        this.isRecordingAllowed = false;
        // send message to afram scene
        // NOT using this event currently, using fetch-answer instead
        // const ascene = document.getElementsByTagName('a-scene')[0];
        // const aframeScene = ascene as AFRAME.Scene;
        // aframeScene.emit('transcription-done');

        //request response from server
        this.getResponse(transcription);
    };

    getResponse = async (transcription: string) => {
        const base64 = btoa(transcription);
        const encoded = encodeURIComponent(base64);
        const url = `https://bridge-api-ar.azurewebsites.net/api/voiceover?text=${encoded}`;
    
        try {
          const ascene = document.getElementsByTagName('a-scene')[0];
          const aframeScene = ascene as AFRAME.Scene;
          aframeScene.emit('fetching-response');

          const response = await fetch(url);
          const data = await response.json();
    
          // Use the responseData to update A-Frame entities or components.
          // For instance, you can initiate lip sync or play the voice.
          if (data.voiceUrl && data.visemes) {
            // Use the data as required.
            // This might include playing audio, initiating lip-sync, etc.
            console.log("Response received: ", data);
            // this.currentVisemes = data.visemes;
            
            this.playAudio(data.voiceUrl);
           
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          
        }
      };

      playAudio = (voiceUrl: string) => {
        // if (!this.isAudioUnlocked) return;
        if (!this.isPlayingAllowed) return;

        this.audioElement.src = voiceUrl;
    
        this.audioElement.addEventListener("playing", () => {
          
          const ascene = document.getElementsByTagName("a-scene")[0];
          const aframeScene = ascene as AFRAME.Scene;
          aframeScene.emit("audio-started");

          this.isPlayingAllowed = false; // no more playing allowed until next request
        });
    
        this.audioElement
          .play()
          .then()
          .catch((err) => {
            console.error("Error playing audio:", err);

          });
    
        this.audioElement.addEventListener("ended", () => {
          const ascene = document.getElementsByTagName("a-scene")[0];
          const aframeScene = ascene as AFRAME.Scene;
          aframeScene.emit("audio-ended");
        });
      };

      handleVisibilityChange = () => {
        if (document.hidden && !this.audioElement.paused) {
          // The tab is not in focus or the browser is minimized and the audio is playing
          this.audioElement.pause();
          this.isPlayingAllowed = false;

          const ascene = document.getElementsByTagName('a-scene')[0];
          const aframeScene = ascene as AFRAME.Scene;
          aframeScene.emit('window-minimised');
        } 
      };

    handleRobotStateChange = (event: CustomEvent) => {
        const val = event.detail.isActive;
        if (!val) {
            this.audioElement.pause();
            this.isPlayingAllowed = false;
        }
    };

    unlockAudio = () => {
        if (this.isAudioUnlocked) return;
    
        // Create a silent audio Blob
        const silentData =
          "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBAAAAABAAEAgD4AAESsAAACABAAZGF0YQQAAAAA";
        this.audioElement.src = silentData;
        // Play the silent audio
        this.audioElement
          .play()
          .then(() => {
            this.isAudioUnlocked = true;
          })
          .catch((err) => {
            console.error("Error unlocking audio:", err);
          });
    };

    handleClick = () => {
        this.unlockAudio();

        const ascene = document.getElementsByTagName('a-scene')[0];
        const aframeScene = ascene as AFRAME.Scene;
        aframeScene.emit('ai-robot');

    };

    render() {
      return (
        <button className="aiButtonContainer" onClick={this.handleClick}>
          {/* <img src={btnIcon} alt='AI button' /> */}
          AI
        </button>
      );
    }
}

export default AIButton;
