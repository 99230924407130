import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';
import {IShaderSprayAframe} from './shader-spray';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface IIsotopesSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    relativeAtomicMassButtonHandler: () => void;
    isotopesButtonHandler: () => void;
    atomMixer: THREE.AnimationMixer;
    waterGunMixer: THREE.AnimationMixer;
    rotateAtomAnimation: THREE.AnimationAction;
    neutronsAnimation: THREE.AnimationAction;
    waterGunAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; image?: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    waterGun: AFrame.Entity;
    tap: AFrame.Entity;
    pointStartPosition: THREE.Vector3
    count: number
    canShot: boolean
    fullNeutrons: boolean
    plane: AFrame.Entity
    nText: AFrame.Entity
    amText: AFrame.Entity
    elText: AFrame.Entity
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: IIsotopesSceneAframe) {
            this.pointStartPosition = new THREE.Vector3(0, 0.015, 0.075);
            this.canShot = true
            const atom = document.getElementById('atomsHolder') as AFrame.Entity;
            atom.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.plane = document.getElementById('plane') as AFrame.Entity;
            this.plane.addEventListener('model-loaded', () => {
                setNeutronsDigits()
                setAtomicMassDigits()
                setOxygenIsotopeDigits()
            })
            this.waterGun = document.getElementById('waterGun') as AFrame.Entity
            this.waterGun.addEventListener('model-loaded', () => {
                this.waterGun.setAttribute('scale', '7 7 7')
                // target position => sprayBottle.setAttribute('position', '1 -3.5 -5')
                this.waterGun.setAttribute('position', '0 -5 10')
                this.waterGun.setAttribute('rotation', '-10 -170 0')
                this.waterGun.setAttribute('visible', 'true')
                this.waterGun.setAttribute('shader-spray', '')
                this.tap = document.createElement('a-entity');
                this.tap.setAttribute('geometry', {
                    primitive: 'box',
                    height: 0.3,
                    width: 0.25,
                    depth: 0.75
                });
                this.tap.setAttribute('position', '0 0 -0.7');
                this.tap.setAttribute('visible', 'false');
                this.tap.setAttribute('class', 'cantap');
                this.tap.addEventListener('click', (e) => {
                    const sprayComponent = this.waterGun.components['shader-spray'] as unknown as IShaderSprayAframe;
                    if (this.canShot && ! this.fullNeutrons) {
                        this.canShot = false
                        if (sprayComponent.canSpray) {
                            // play trigger animation
                            console.log('Spray allowed. Playing animation.')
                            this.waterGunAnimation.stop()
                            this.waterGunAnimation.play()
                            if (sprayComponent.lastRaycastResult && this.count < 18 && !this.fullNeutrons) {
                                setTimeout(() => {
                                    if (this.count === 3 && !this.fullNeutrons) {
                                        this.neutronsAnimation.play()
                                        this.neutronsAnimation.clampWhenFinished = true
                                        setTimeout(() => {
                                            this.neutronsAnimation.paused = true
                                        }, this.neutronsAnimation.getClip().duration * 1000 / 15)
                                    } else if (this.count > 3 && this.count < 18 && !this.fullNeutrons) {
                                        this.neutronsAnimation.paused = false
                                        setTimeout(() => {
                                            this.neutronsAnimation.paused = true
                                        }, this.neutronsAnimation.getClip().duration * 1000 / 15)
                                    } else {
                                        this.count = 3
                                        this.fullNeutrons = true
                                    }
                                    this.count += 1
                                    this.nText.setAttribute('text', 'value:'+this.count+'; color: #000000; align: left; width: 10; wrap-count: 50;');
                                    this.amText.setAttribute('text', `value: `+(this.count+8)+`; color: #000000; align: left; width: 10; wrap-count: 50;`);
                                    this.elText.setAttribute('text', `value: `+(this.count+8)+`; color: #000000; align: left; width: 10; wrap-count: 50;`);
                                    this.canShot = true
                                }, this.waterGunAnimation.getClip().duration * 1000)
                            } else {
                                this.canShot = true
                            }
                        }
                    }
                    e.stopPropagation()

                });
                this.waterGun.appendChild(this.tap);

                const camera = this.el.sceneEl?.camera as any;
                camera.el.object3D.add(this.waterGun.object3D)
            })
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                const waterGunPositionNormal = new THREE.Vector3(0.5, -1, -10)
                this.fullNeutrons = false
                this.count = 3
                this.waterGun.setAttribute('animation__position', {
                    property: 'position',
                    to: waterGunPositionNormal,
                    easing: 'linear',
                    dur: 1000,
                })
                initialiseAnimations()
            })
            // when model is loaded adding recenter functionality
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: atomsHolder; scale: 8 8 8; offset: 0 -2 -3');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                }
                const waterGunPositionHiden = new THREE.Vector3(0, -5, 10)
                this.fullNeutrons = false
                this.count = 3
                this.waterGun.setAttribute('animation__position', {
                    property: 'position',
                    to: waterGunPositionHiden,
                    easing: 'linear',
                    dur: 1000,
                })
                this.nText.setAttribute('text', 'value: 3; color: #000000; align: left; width: 10; wrap-count: 50;');
                this.amText.setAttribute('text', `value: 11; color: #000000; align: left; width: 10; wrap-count: 50;`);
                this.elText.setAttribute('text', `value: 11; color: #000000; align: left; width: 10; wrap-count: 50;`);
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    if (this.rotateAtomAnimation) {
                        this.rotateAtomAnimation.paused = false;
                    }
                } else {
                    if (this.rotateAtomAnimation) {
                        this.rotateAtomAnimation.paused = true;
                    }
                }
            })
            this.relativeAtomicMassButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = '';
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/19.Isotopes/RelativeAtomicMass.png';
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            this.isotopesButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Isotopes';
                    const body = 'Isotopes are different forms of an element with the same number of protons but different numbers of neutrons. Oxygen atoms have 8 protons, so the isotope oxygen-12 (<sup>12</sup>O) has 4 neutrons and an atomic mass of 12.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseAnimations = () => {
                const atom = document.getElementById('atom') as AFrame.Entity
                const waterGun = document.getElementById('waterGun') as AFrame.Entity
                const animatedAtomEl = atom.object3D.getObjectByName('Scene') as any;
                const animatedwaterGunEl = waterGun.object3D.getObjectByName('Chemistry19_WaterGun') as any;
                if (animatedAtomEl && animatedwaterGunEl) {
                    this.atomMixer = new THREE.AnimationMixer(animatedAtomEl)
                    this.waterGunMixer = new THREE.AnimationMixer(animatedwaterGunEl)
                    const [RotateAtom, AddNeutronsToNucleus] = animatedAtomEl.animations
                    const [Fire] = animatedwaterGunEl.animations
                    this.rotateAtomAnimation = this.atomMixer.clipAction(RotateAtom)
                    this.neutronsAnimation = this.atomMixer.clipAction(AddNeutronsToNucleus)
                    this.waterGunAnimation = this.waterGunMixer.clipAction(Fire)
                    this.waterGunAnimation.setLoop(THREE.LoopOnce, 1)
                    this.rotateAtomAnimation.play()
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const relativeAtomicMassTriggerBtn = poolButtons.requestEntity()
                relativeAtomicMassTriggerBtn?.setAttribute('position', '0.2 0.071 0.01')
                relativeAtomicMassTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                relativeAtomicMassTriggerBtn?.play()
                relativeAtomicMassTriggerBtn?.addEventListener('click', () => {
                    this.relativeAtomicMassButtonHandler()
                    if (relativeAtomicMassTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(relativeAtomicMassTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (relativeAtomicMassTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = relativeAtomicMassTriggerBtn
                    }
                });
                const isotopesTriggerBtn = poolButtons.requestEntity()
                isotopesTriggerBtn?.setAttribute('position', '-0.12 0.5 0.03')
                isotopesTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                isotopesTriggerBtn?.play()
                isotopesTriggerBtn?.addEventListener('click', () => {
                    this.isotopesButtonHandler()
                    if (isotopesTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(isotopesTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (isotopesTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = isotopesTriggerBtn
                    }
                });
            };
            const setNeutronsDigits = () => {
                const nText = document.createElement('a-entity')
                nText.setAttribute('text', "value: 3; color: #000000; align: left; width: 10; wrap-count: 50;");
                nText.setAttribute('position', '1.13 -0.135 0.03');
                nText.setAttribute('scale', '0.2 0.2 0.2');
                nText.setAttribute('rotation', '0 0 0');
                nText.setAttribute('visible', true);
                this.plane.appendChild(nText);
                this.nText = nText;
            }
            const setAtomicMassDigits = () => {
                const amText = document.createElement('a-entity')
                amText.setAttribute('text', "value: 11; color: #000000; align: left; width: 10; wrap-count: 50;");
                amText.setAttribute('position', '1.18 -0.225 0.03');
                amText.setAttribute('scale', '0.2 0.2 0.2');
                amText.setAttribute('rotation', '0 0 0');
                amText.setAttribute('visible', true);
                this.plane.appendChild(amText);
                this.amText = amText;
            }
            const setOxygenIsotopeDigits = () => {
                const elText = document.createElement('a-entity')
                elText.setAttribute('text', "value: 11; color: #000000; align: left; width: 10; wrap-count: 50;");
                elText.setAttribute('position', '0.9 0.12 0.03');
                elText.setAttribute('scale', '0.2 0.2 0.2');
                elText.setAttribute('rotation', '0 0 0');
                elText.setAttribute('visible', true);
                this.plane.appendChild(elText);
                this.elText = elText;
            }
        },
        tick(this: IIsotopesSceneAframe, time: number, deltaTime: number) {
            if (this.atomMixer) {
                this.atomMixer.update(deltaTime * 0.001);
            }
            if (this.waterGunMixer) {
                this.waterGunMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {AtomsScene as AtomsSceneComponent}
