export const vertexShader = `
  varying vec3 vWorldPosition;
  varying vec3 vNormal;

  void main() {
    vec4 worldPosition = modelMatrix * vec4(position, 1.0);
    vWorldPosition = worldPosition.xyz;
    vNormal = normalize(normalMatrix * normal);

    gl_Position = projectionMatrix * viewMatrix * worldPosition;
  }
`;

export const fragmentShader = `
  uniform vec3 color;
  uniform vec3 fixedColor;
  uniform vec3 baseColor;
  uniform float fresnelBias;
  uniform float fresnelScale;
  uniform float fresnelPower;
  uniform float transparencyFactor;


  varying vec3 vWorldPosition;
  varying vec3 vNormal;

  void main() {
    vec3 normalizedWorldPos = normalize(vWorldPosition);
    float fresnel = fresnelBias + fresnelScale * pow(1.0 + dot(normalizedWorldPos, vNormal), fresnelPower);

    vec3 emissiveColor = vec3(color * fresnel);
    float alpha = mix(1.0 - transparencyFactor, 1.0, fresnel);

    // Set the color to yellow
    emissiveColor = mix(baseColor, fixedColor, emissiveColor);

    gl_FragColor = vec4(emissiveColor, alpha);
  }
`;