import React from 'react';
import '../../../styles/loader-screen.css';
import { Box, Typography } from '@mui/material';
import WallLogo from './wall-logo';

const WallPermissionsDeniedScreen: React.FC<any> = () => {
  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,253,3)',
        padding: '16px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99,
      }}
    >
      <Typography
        variant='h5'
        color={'inherit'}
        fontSize='28px'
        marginBottom={'12px'}
        fontWeight={'bold'}
      >
        Permissions were denied.
      </Typography>
      <Typography
        variant='body1'
        color={'inherit'}
        fontSize='16px'
        marginBottom={'12px'}
      >
        You need to accept motion permissions to continue.
      </Typography>
      <button className='refresh-button' onClick={handleReloadClick}>
        Refresh
      </button>

      <WallLogo />
    </Box>
  );
};

export default WallPermissionsDeniedScreen;
