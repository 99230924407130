import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    poolEntity: AFrame.Entity;
    theSunHandler: () => void;
    theRockyPlanetsHandler: () => void;
    theEarthHandler: () => void;
    theGiantPlanetsHandler: () => void;
    plutoHandler: () => void;
    mixer: THREE.AnimationMixer;
    asteroidOrbitAnimation: THREE.AnimationAction;
    earthOrbitAnimation: THREE.AnimationAction;
    moonOrbitAnimation: THREE.AnimationAction;
    jupiterOrbitAnimation: THREE.AnimationAction;
    marsOrbitAnimation: THREE.AnimationAction;
    mercuryOrbitAnimation: THREE.AnimationAction;
    saturnOrbitAnimation: THREE.AnimationAction;
    uranusOrbitAnimation: THREE.AnimationAction;
    venusOrbitAnimation: THREE.AnimationAction;
    currentHightlights: THREE.AnimationAction[];
    fullAnimationsList: THREE.AnimationAction[];
    annotationComponent: IAnnotationAframe;
    currentDeactivatedButton: AFrame.Entity;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            const model = document.getElementById('model') as AFrame.Entity;

            model.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');

                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                initialiseAnimations()
                toggleHighlights('Highlight', false)
            });

            const toggleCurrentAnimations = (currentAnimationsList: THREE.AnimationAction[], toggle: boolean) => {
                if (currentAnimationsList.length > 0) {
                    currentAnimationsList.forEach((currentAnimation: THREE.AnimationAction) => {
                        toggle ? currentAnimation.play() : currentAnimation.stop()
                    })
                }
            }

            const toggleHighlights = (item: string, toggle: boolean) => {
                    this.el.object3D.traverse((child) => {
                        if (child.name.includes(item)) child.visible = toggle
                    })
            }
            this.el.sceneEl?.addEventListener('annotation-state-change', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const annotationState = customEvent.detail.annotationState;
                if (annotationState === 0) {
                    toggleCurrentAnimations(this.fullAnimationsList, true)
                }
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: model; scale: 8 8 8; offset: 0 -4 -2');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    this.fullAnimationsList.forEach((currentAnimation: THREE.AnimationAction) => {
                        currentAnimation.paused = false
                    })
                } else {
                    this.fullAnimationsList.forEach((currentAnimation: THREE.AnimationAction) => {
                        currentAnimation.paused = true
                    })
                }
            });

            const initialiseAnimations = () => {
                const animatedEl = model.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [AsteroidOrbit, EarthOrbit, MoonOrbit, JupiterOrbit, MarsOrbit, MercuryOrbit, SaturnOrbit, UranusOrbit, VenusOrbit] = animatedEl.animations
                this.asteroidOrbitAnimation = this.mixer.clipAction(AsteroidOrbit)
                this.earthOrbitAnimation = this.mixer.clipAction(EarthOrbit)
                this.moonOrbitAnimation = this.mixer.clipAction(MoonOrbit)
                this.jupiterOrbitAnimation = this.mixer.clipAction(JupiterOrbit)
                this.marsOrbitAnimation = this.mixer.clipAction(MarsOrbit)
                this.mercuryOrbitAnimation = this.mixer.clipAction(MercuryOrbit)
                this.saturnOrbitAnimation = this.mixer.clipAction(SaturnOrbit)
                this.uranusOrbitAnimation = this.mixer.clipAction(UranusOrbit)
                this.venusOrbitAnimation = this.mixer.clipAction(VenusOrbit)
                this.fullAnimationsList = [
                    this.asteroidOrbitAnimation,
                    this.earthOrbitAnimation,
                    this.moonOrbitAnimation,
                    this.jupiterOrbitAnimation,
                    this.marsOrbitAnimation,
                    this.mercuryOrbitAnimation,
                    this.saturnOrbitAnimation,
                    this.uranusOrbitAnimation,
                    this.venusOrbitAnimation,
                ]
                toggleCurrentAnimations(this.fullAnimationsList, true)
            }
            this.theSunHandler = () => {
                toggleHighlights('Highlight', false)
                toggleHighlights('Sun', true)
                if (this.onObjectSelected) {
                    const title = 'The Sun';
                    const body = 'In the solar system is one star at the centre, the Sun. The Sun contains 99% of the mass of the solar system. Its powerful gravity causes all the planets to orbit around on circular paths.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.theRockyPlanetsHandler = () => {
                toggleHighlights('Highlight', false)
                toggleHighlights('Mercury', true)
                toggleHighlights('Venus', true)
                toggleHighlights('Earth', true)
                toggleHighlights('Mars', true)
                if (this.onObjectSelected) {
                    const title = 'The Rocky Planets';
                    const body = 'Close to the Sun are the four rocky planets: Mercury, Venus, Earth, and Mars. To be called a planet, an object must have cleared the neighbourhood around its orbit of all large debris. This doesn’t include satellites.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.theEarthHandler = () => {
                toggleHighlights('Highlight', false)
                toggleHighlights('Earth', true)
                toggleHighlights('Moon', true)
                if (this.onObjectSelected) {
                    const title = 'The Earth';
                    const body = 'Instead of orbiting directly around the Sun, a satellite is in orbit around a planet. The Moon is a natural satellite of Earth. Humans have also launched artificial satellites which orbit around the planet for communications and astronomy.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.theGiantPlanetsHandler = () => {
                toggleHighlights('Highlight', false)
                toggleHighlights('Jupiter', true)
                toggleHighlights('Saturn', true)
                toggleHighlights('Uranus', true)
                toggleHighlights('Neptune', true)
                if (this.onObjectSelected) {
                    const title = 'The Giant Planets';
                    const body = 'Further from the Sun lie the giant planets: Jupiter, Saturn, Uranus, and Neptune. These huge planets are made up of gas and ice, with masses up to 300 times larger than Earth. They have also all cleared their local orbit of debris.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.plutoHandler = () => {
                toggleHighlights('Highlight', false)
                toggleHighlights('Pluto', true)
                if (this.onObjectSelected) {
                    const title = 'Pluto';
                    const body = 'Pluto is a dwarf planet; it’s large enough to be a planet but it hasn’t cleared its neighbourhood of other large masses. This is one of the defining properties of a planet so instead, Pluto is one of the many dwarf planets.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const theSunTriggerBtn = poolButtons.requestEntity()
                theSunTriggerBtn?.setAttribute('position', '-0.045 0.035 0.1')
                theSunTriggerBtn?.setAttribute('scale', '3 3 3')
                theSunTriggerBtn?.play()
                theSunTriggerBtn?.addEventListener('click', () => {
                    this.theSunHandler()
                    if (theSunTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(theSunTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theSunTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theSunTriggerBtn
                    }
                });

                const theRockyPlanetsTriggerButton = poolButtons.requestEntity();
                theRockyPlanetsTriggerButton?.setAttribute('position', '-0.155 0.075 0.4');
                theRockyPlanetsTriggerButton?.setAttribute('scale', '3 3 3')
                theRockyPlanetsTriggerButton?.play()
                theRockyPlanetsTriggerButton?.addEventListener('click', () => {
                    this.theRockyPlanetsHandler()
                    if (theRockyPlanetsTriggerButton) {
                        this.annotationComponent.setObjectToFollow(theRockyPlanetsTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theRockyPlanetsTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theRockyPlanetsTriggerButton
                    }
                });

                const theEarthTriggerBtn = poolButtons.requestEntity()
                theEarthTriggerBtn?.setAttribute('position', '0.155 0.065 0.45')
                theEarthTriggerBtn?.setAttribute('scale', '3 3 3')
                theEarthTriggerBtn?.play()
                theEarthTriggerBtn?.addEventListener('click', () => {
                    this.theEarthHandler()
                    if (theEarthTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(theEarthTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theEarthTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theEarthTriggerBtn
                    }
                });

                const theGiantPlanetsTriggerButton = poolButtons.requestEntity();
                theGiantPlanetsTriggerButton?.setAttribute('position', '0.205 0.065 0.75');
                theGiantPlanetsTriggerButton?.setAttribute('scale', '3 3 3')
                theGiantPlanetsTriggerButton?.play()
                theGiantPlanetsTriggerButton?.addEventListener('click', () => {
                    this.theGiantPlanetsHandler()
                    if (theGiantPlanetsTriggerButton) {
                        this.annotationComponent.setObjectToFollow(theGiantPlanetsTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theGiantPlanetsTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theGiantPlanetsTriggerButton
                    }
                });

                const plutoTriggerButton = poolButtons.requestEntity();
                plutoTriggerButton?.setAttribute('position', '0.05 0.065 1.05');
                plutoTriggerButton?.setAttribute('scale', '3 3 3')
                plutoTriggerButton?.play()
                plutoTriggerButton?.addEventListener('click', () => {
                    this.plutoHandler()
                    if (plutoTriggerButton) {
                        this.annotationComponent.setObjectToFollow(plutoTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (plutoTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = plutoTriggerButton
                    }
                });
            };
        },
        tick(this: ICircuitControl, time: number, deltaTime: number) {
            const camera = document.querySelector("#camera");
            const cameraPosition = camera.getAttribute("position");
            this.el.object3D.traverse(child => {
                if (child.name === 'AsteroidHighlight') child.visible = false
                if (child.name.includes('Highlight')) {
                    child.lookAt(cameraPosition)
                }
            })
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {CircuitControlComponent}
