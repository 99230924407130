import * as AFRAME from 'aframe';
import * as THREE from 'three';

interface IDynamicLineAframe {
    isLineCreated: boolean;
    offset: THREE.Vector3;
    tempVector2: THREE.Vector3;
    tempVector: THREE.Vector3;
    data: {
        id: string;
        offset: {x: number, y: number, z: number};
      };
    el: AFRAME.Entity;
    line: THREE.Line;
    createLineEntity(): void;
    updateLinePosition(): void;
}

const DynamicLineAFrame = {
    name: "dynamic-line",
    val: {
        schema: {
            id: { type: 'string', default: '' },
            offset: {type: 'vec3', default: {x: 0, y: 0, z: 0}},
        },
        init(this: IDynamicLineAframe) {
          this.isLineCreated = false;
            this.el.sceneEl?.addEventListener('lesson-start', () => {
              console.log("Event received");
              if (!this.isLineCreated) {
                this.createLineEntity();
                this.isLineCreated = true;
              }
              this.line.visible = true;
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
              this.line.visible = false;
            });
        },
        createLineEntity(this: IDynamicLineAframe) {
            const { id } = this.data;
      
            // Find the target element by its ID
            const targetEl = document.getElementById(id);
      
            if (!targetEl) {
              console.warn(`Element with ID '${id}' not found.`);
              return;
            }
      
            // Create a THREE.js line
            const geometry = new THREE.BufferGeometry();
            const vertices = new Float32Array(6); // 3 vertices per point * 2 points
            geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
            // const geometry2 = new THREE.BufferGeometry();
            // const vertices2 = new Float32Array(6); // 3 vertices per point * 2 points
            // geometry2.setAttribute('position', new THREE.BufferAttribute(vertices2, 3));
            const material = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 1, visible: true });
            this.line = new THREE.Line(geometry, material);
            this.line.frustumCulled = false; // disable frustum culling on the line to avoid it disappearing under some angles
            // this.line2 = new THREE.Line(geometry2, material);
            // this.line2.frustumCulled = false; // disable frustum culling on the line to avoid it disappearing under some angles
            const holder = document.getElementById('lineHolder') as AFRAME.Entity;
            holder.object3D.add(this.line);
            console.log("Line added: ", this.el.sceneEl?.object3D);

            this.tempVector = new THREE.Vector3();
            this.tempVector2 = new THREE.Vector3();
            this.offset = new THREE.Vector3(this.data.offset.x, this.data.offset.y, this.data.offset.z);

            
          },
      
          updateLinePosition(this: IDynamicLineAframe) {
            const { id, offset } = this.data;
      
            if (!this.line) return;
      
            const targetEl = document.getElementById(id) as AFRAME.Entity;
            if (!targetEl) {
              console.warn(`Element with ID '${id}' not found.`);
              return;
            }
      
            const startPosition = this.el.object3D.getWorldPosition(this.tempVector);
            const endPosition = targetEl.object3D.getWorldPosition(this.tempVector2);
            startPosition.add(this.offset);
            // const startPosition = new THREE.Vector3(0, 0, 0);
      
            const positionAttribute = this.line.geometry.attributes.position as THREE.BufferAttribute;
            const positions = positionAttribute.array as Float32Array;
            positions[0] = startPosition.x;
            positions[1] = startPosition.y;
            positions[2] = startPosition.z;
            positions[3] = endPosition.x;
            positions[4] = endPosition.y;
            positions[5] = endPosition.z;
      
            this.line.geometry.attributes.position.needsUpdate = true;
          },
        tick(this: IDynamicLineAframe) {
            this.updateLinePosition()
        },
    },
};
export { DynamicLineAFrame as DynamicLine };