import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import './styles/physics-semiconductors.css';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface PhysicsSemiconductorsSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionShowTemperatureGraph: THREE.AnimationAction;
	actionShowCO2Graph: THREE.AnimationAction;
	actionShowLightIntensityGraph: THREE.AnimationAction;
	actionMolecules: THREE.AnimationAction;

	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;
	distanceEvent: CustomEvent;

	sceneLight: AFrame.Entity;
	el: AFrame.Entity;

	thermistorTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	bulbTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	ammeterTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	voltmeterTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	bookTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;

	thermistorButtonHandler: () => void;
	bulpButtonHandler: () => void;
	ammeterButtonHandler: () => void;
	voltmeterButtonHandler: () => void;
	bookButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;

	sliderValue: number;

	pointLight: AFrame.Entity;
	gradientPlane: THREE.Object3D<THREE.Object3DEventMap> | undefined;

	ammText: AFrame.Entity;
	voltmText: AFrame.Entity;
}

const PhysicsSemiconductorsScene = {
	name: 'physics-semiconductors',
	val: {
		init(this: PhysicsSemiconductorsSceneAframe) {
			var isInitialised = false;
			this.el.addEventListener('model-loaded', () => {
				const physicsSemiconductors = document.getElementById('PhysicsSemiconductors') as AFrame.Entity;
				this.gradientPlane = physicsSemiconductors.object3D.getObjectByName('GradientPlane') as THREE.Object3D;
				console.log(this.gradientPlane)

				if (!isInitialised) {
					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					setAmmeterDigits();
					setVoltmeterDigits();
					createLightSource();
					isInitialised = true;
				}
				else return
			});


			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: PhysicsSemiconductorsHolder; scale: 15 15 15; ');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}

			});

			const createLightSource = () => {
				const light = document.createElement('a-light');
				light.setAttribute('type', 'point');
				light.setAttribute('color', '#ffffff');
				light.setAttribute('intensity', '0.25');
				light.setAttribute('distance', '100');
				light.setAttribute('decay', '2');
				light.setAttribute('position', '-0.11, 0.07, 0.03');
				light.setAttribute('shadow', 'cast: true');
				this.pointLight = light;
				this.el.appendChild(this.pointLight);
			}

			const setLightIntensity = (intensity: number) => {
				this.pointLight.setAttribute('intensity', intensity);
			}

			const setAmmeterDigits = () => {
				if (!this.ammText) {
					const ammText = document.createElement('a-entity')
					ammText.setAttribute('text', "value: 0.1; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
					ammText.setAttribute('position', '0.168 0.025 0.155');
					ammText.setAttribute('scale', '0.06 0.06 0.06');
					ammText.setAttribute('rotation', '-90 0 0');
					ammText.setAttribute('visible', true);
					this.el.appendChild(ammText);
					this.ammText = ammText;
				}
			}

			const setVoltmeterDigits = () => {
				if (!this.voltmText) {
					const voltmText = document.createElement('a-entity')
					voltmText.setAttribute('text', "value: 5.9; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
					voltmText.setAttribute('position', '0.39 0.025 0.155');
					voltmText.setAttribute('scale', '0.06 0.06 0.06');
					voltmText.setAttribute('rotation', '-90 0 0');
					voltmText.setAttribute('visible', true);
					this.el.appendChild(voltmText);
					this.voltmText = voltmText;
				}
			}

			this.el.sceneEl?.addEventListener('slider-move', (e) => {
				const ce = e as CustomEvent;

				this.sliderValue = ce.detail.value;

				if (this.sliderValue <= 25) {
					this.ammText.setAttribute('text', "value: 0.1; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
					this.voltmText.setAttribute('text', "value: 5.9; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
					this.gradientPlane?.scale.set(0.0028, 0.0028, 0.0028);
					setLightIntensity(0.25);
				} else if (this.sliderValue <= 50) {
					this.ammText.setAttribute('text', "value: 0.2; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
					this.voltmText.setAttribute('text', "value: 5.8; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
					this.gradientPlane?.scale.set(0.0035, 0.0035, 0.0035);
					setLightIntensity(0.5);
				} else if (this.sliderValue <= 75) {
					this.ammText.setAttribute('text', "value: 0.3; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
					this.voltmText.setAttribute('text', "value: 5.7; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
					this.gradientPlane?.scale.set(0.0042, 0.0035, 0.0042);
					setLightIntensity(0.75);
				}
			});

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			this.thermistorButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'NTC Thermistor';
					const body = 'A negative temperature coefficient (NTC) thermistor decreases in resistivity as the temperature of its surroundings increases. This is the opposite relation between resistivity and a regular conductor.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.bulpButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Filament Bulb';
					const body = 'In a series circuit, the potential difference over each component depends on its resistance. The brightness of the bulb therefore acts as a visual indicator of the resistance of the thermistor.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.ammeterButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Ammeter';
					const body = 'The ammeter displays the current through the circuit measured in amps. It must be placed in series with the circuit to function. We assume the ammeter is an ideal ammeter, with zero resistance.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.voltmeterButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Voltmeter';
					const body = 'The ammeter displays the potential difference over the thermistor measured in volts. It must be placed in parallel with the component to function. We assume the voltmeter is an ideal voltmeter, with infinite resistance.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.bookButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = '<span class="smaller-title-annotation">Plotting a Resistance Temperature Graph</span>';
					const body = 'To create a graph of resistance against temperature, place the thermistor into a hot water bath. As the water bath cools, record the temperature, current, and voltage and use Ohm’s law to calculate the resistance.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.thermistorTriggerBtn = poolButtons.requestEntity();
				this.thermistorTriggerBtn?.object3D.position.set(0.1022, 0.04, 0.01);
				this.thermistorTriggerBtn?.object3D.scale.set(0.08, 0.08, 0.08);
				this.thermistorTriggerBtn?.play();
				this.thermistorTriggerBtn?.addEventListener('click', () => {
					this.thermistorButtonHandler();
					if (this.thermistorTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.thermistorTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.thermistorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.thermistorTriggerBtn;
					}
				});

				this.bulbTriggerBtn = poolButtons.requestEntity();
				this.bulbTriggerBtn?.object3D.position.set(-0.11, 0.04, 0.04);
				this.bulbTriggerBtn?.object3D.scale.set(0.08, 0.08, 0.08);
				this.bulbTriggerBtn?.play();
				this.bulbTriggerBtn?.addEventListener('click', () => {
					this.bulpButtonHandler();
					if (this.bulbTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.bulbTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.bulbTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.bulbTriggerBtn;
					}
				});


				this.ammeterTriggerBtn = poolButtons.requestEntity();
				this.ammeterTriggerBtn?.object3D.position.set(0.103, 0.031, 0.19);
				this.ammeterTriggerBtn?.object3D.scale.set(0.08, 0.08, 0.08);
				this.ammeterTriggerBtn?.play();
				this.ammeterTriggerBtn?.addEventListener('click', () => {
					this.ammeterButtonHandler();
					if (this.ammeterTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.ammeterTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.ammeterTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.ammeterTriggerBtn;
					}
				});

				this.voltmeterTriggerBtn = poolButtons.requestEntity();
				this.voltmeterTriggerBtn?.object3D.position.set(-0.115, 0.031, 0.19);
				this.voltmeterTriggerBtn?.object3D.scale.set(0.08, 0.08, 0.08);
				this.voltmeterTriggerBtn?.play();
				this.voltmeterTriggerBtn?.addEventListener('click', () => {
					this.voltmeterButtonHandler();
					if (this.voltmeterTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.voltmeterTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.voltmeterTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.voltmeterTriggerBtn;
					}
				});

				this.bookTriggerBtn = poolButtons.requestEntity();
				this.bookTriggerBtn?.object3D.position.set(0.57, 0.035, 0.07);
				this.bookTriggerBtn?.object3D.scale.set(0.08, 0.08, 0.08);
				this.bookTriggerBtn?.play();
				this.bookTriggerBtn?.addEventListener('click', () => {
					this.bookButtonHandler();
					if (this.bookTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.bookTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.bookTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.bookTriggerBtn
					}
				});
			}
		},
		tick(this: PhysicsSemiconductorsSceneAframe, time: number, deltaTime: number) {
			const ring = document.getElementById('ring')
			if (!ring) {
				const circuit = document.getElementById('PhysicsSemiconductorsHolder') as AFrame.Entity;
				const cameraEl = this.el.sceneEl?.querySelector('a-camera');
				if (cameraEl && circuit) {
					const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
					let cameraPosition = camPos.clone()
					let spherePos = circuit.object3D.position.clone()
					let distance = cameraPosition.distanceTo(spherePos)
					if (!this.distanceEvent) {
						this.distanceEvent = new CustomEvent('distance-change', { detail: { value: false } });
					}
					if ((distance < 6 && cameraPosition.x < 4) || (distance < 8 && cameraPosition.x > 4) || (distance < 12 && cameraPosition.x > 6)) {
						console.log(cameraPosition.x)
						const scene = this.el.sceneEl as AFrame.Scene
						this.distanceEvent.detail.value = true
						scene.emit('distance-change', this.distanceEvent.detail.value);
						circuit.object3D.visible = false
					} else {
						const scene = this.el.sceneEl as AFrame.Scene
						this.distanceEvent.detail.value = false
						scene.emit('distance-change', this.distanceEvent.detail.value);
						if (!circuit.object3D.visible) {
							circuit.object3D.visible = true
						}
					}
				}
			}

			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}

		},
	},
};
export { PhysicsSemiconductorsScene as PhysicsSemiconductorsComponent }