import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTrainingsEffect } from '../../../effects';
import { useTrainingsContext } from '../../../context';
import {
  TrainingLevelEnum,
  TrainingStatusEnum,
  TrainingTypeEnum,
} from '../../../utils/enums';
import type { Filters } from '../../../typings';
import { changeFiltersActionCreator } from '../../../state';

export function useHandlers(id: string) {
  const navigate = useNavigate();
  const [{ filters }, dispatch] = useTrainingsContext();

  const [loading, setIsLoading] = useState(false);

  const handleTrainingCardClick = useCallback(() => {
    navigate(`/simulations/${id}`);
  }, [id, navigate]);

  const handleFilterChange = useCallback((filters: Filters) => {
    let mounted = true;

    const execute = async () => {
      const response = await fetchTrainingsEffect(filters);

      if (!mounted || response.status === 'error') return;

      dispatch(response.action);
    };

    execute();
    setIsLoading(false);
    return () => {
      mounted = false;
    };
  }, []);

  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(changeFiltersActionCreator({ Search: value }));
      handleFilterChange({ ...filters, Search: value.replace(/^\s+/, '').replace(/\s+$/, '') });
    },
    [dispatch, filters, handleFilterChange]
  );

  const handleStatusChange = useCallback(
    (value?: TrainingStatusEnum) => {
      dispatch(changeFiltersActionCreator({ Status: value }));
      handleFilterChange({ ...filters, Status: value });
    },
    [dispatch, filters, handleFilterChange]
  );

  const handleTypeChange = useCallback(
    (value?: TrainingTypeEnum) => {
      dispatch(changeFiltersActionCreator({ Type: value }));
      handleFilterChange({ ...filters, Type: value });
    },
    [dispatch, filters, handleFilterChange]
  );

  const handleLevelChange = useCallback(
    (value?: TrainingLevelEnum) => {
      dispatch(changeFiltersActionCreator({ Level: value }));
      handleFilterChange({ ...filters, Level: value });
    },
    [dispatch, filters, handleFilterChange]
  );

  return {
    handleTrainingCardClick,
    handleFilterChange,
    handleSearchChange,
    handleStatusChange,
    handleTypeChange,
    handleLevelChange,
  };
}
