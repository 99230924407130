import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    pointLight: AFrame.Entity;
    fillMaterial: THREE.MeshStandardMaterial;
    meshMaterial: THREE.MeshStandardMaterial;
    circuit: AFrame.Entity;
    thermistor: THREE.Mesh;
    lightMesh: THREE.Mesh;
    coil: THREE.Mesh;
    LDR: THREE.Mesh;
    book: THREE.Object3D;
    lampActive: boolean;
    currentDeactivatedButton: AFrame.Entity;
    poolEntity: AFrame.Entity;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    sliderValue: string;
    prevValue: string;
    distanceEvent: CustomEvent;
    currentAssetid: number;
    recenter: boolean;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                // this.el.object3D.traverse(child => console.log(child))
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                this.circuit = document.getElementById('circuit') as AFrame.Entity;
                this.thermistor = this.circuit.object3D.getObjectByName('Thermistor') as THREE.Mesh;
                this.LDR = this.circuit.object3D.getObjectByName('LDR') as THREE.Mesh;
                this.thermistor.visible = false
                this.currentAssetid = 0
                createShaderMaterials();

                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
            })
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: circuit; scale: 15 15 15; offset: 0 0 -4');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    this.el.sceneEl?.appendChild(ring);
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.el.sceneEl?.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                this.currentAssetid = ce.detail.assetId;
                this.book = this.circuit.object3D.getObjectByName('NoteBook') as THREE.Object3D;
                const firstPage = this.book.getObjectByName('Page1') as THREE.Mesh;
                const secondPage = this.book.getObjectByName('Page2') as THREE.Mesh;
                switch (this.currentAssetid) {
                    case 0:
                        this.LDR.visible = true
                        this.thermistor.visible = false
                        firstPage.visible = true
                        secondPage.visible = true
                        break;
                    case 1:
                        this.LDR.visible = false
                        this.thermistor.visible = true
                        firstPage.visible = false
                        secondPage.visible = true
                        break;
                    default:
                        console.warn('lesson-start.tsx:Unknown asset id: ', this.currentAssetid);
                }
            });
            const createShaderMaterials = () => {
                this.lightMesh = this.el.object3D.getObjectByName('LightPlane') as THREE.Mesh;
                this.coil = this.el.object3D.getObjectByName('Coil') as THREE.Mesh;
                this.fillMaterial = this.coil.material as THREE.MeshStandardMaterial;
                this.fillMaterial.emissiveIntensity = 0;

                const light = document.createElement('a-light');
                light.setAttribute('type', 'point');
                light.setAttribute('color', '#ffffff');
                light.setAttribute('intensity', '1');
                light.setAttribute('distance', '100');
                light.setAttribute('decay', '2');
                light.setAttribute('position', '-0.0795 0.115 0.15');
                this.pointLight = light;
                this.el.appendChild(this.pointLight);
            };

            const setLightbulbIntensity = (intensity: number) => {
                this.meshMaterial = this.lightMesh.material as THREE.MeshStandardMaterial;
                if (this.fillMaterial) {
                    this.fillMaterial.emissiveIntensity = intensity;
                    this.fillMaterial.needsUpdate = true;
                }
                const scale = intensity * 0.003;
                this.lightMesh.scale.set(scale * 1.2, scale, scale);

                const color = interpolateColorWhiteOrange(intensity);
                this.meshMaterial.color.set(color);

                this.pointLight.setAttribute('intensity', intensity);
            };
            const interpolateColorWhiteOrange = (value: number) => {
                // Define the blue and orange colors
                // const white = new THREE.Color(0xd27500);
                const white = new THREE.Color(0xffffff);
                // const orange = new THREE.Color(0xffcc05);
                const orange = new THREE.Color(0xff0000);
                // Interpolate between the colors based on the value
                return orange.clone().lerp(white, value);
            }
            this.el.sceneEl?.addEventListener('slider-move', (e) => {
                const ce = e as CustomEvent;
                const sliderValue = Number(ce.detail.value); // Convert value to number

                const intensity = sliderValue / 50; // Calculate intensity based on slider value

                setLightbulbIntensity(intensity);
            });
            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const daylightSensorTriggerBtn = poolButtons.requestEntity();
                if (daylightSensorTriggerBtn) {
                    daylightSensorTriggerBtn.setAttribute('position', '0 0.035 0');
                    this.el.sceneEl?.addEventListener('asset-change', (e) => {
                        const ce = e as CustomEvent;
                        this.currentAssetid = ce.detail.assetId;
                        switch (this.currentAssetid) {
                            case 0:
                                daylightSensorTriggerBtn.setAttribute('scale', '1 1 1');
                                break;
                            case 1:
                                daylightSensorTriggerBtn.setAttribute('scale', '0 0 0');
                                break;
                        }
                    });
                    daylightSensorTriggerBtn.play();
                    daylightSensorTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Daylight Sensor'
                            const body = 'When light intensity is low (at night), the LDR takes a large share of the potential difference in the circuit. As a result, the bulb in the circuit is very dim. This can be used as an indicator of the light intensity at the LDR.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(daylightSensorTriggerBtn);
                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (daylightSensorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = daylightSensorTriggerBtn

                    });
                } else {
                    console.log('No trigger btn');
                }


                const LDRTriggerBtn = poolButtons.requestEntity();
                if (LDRTriggerBtn) {
                    LDRTriggerBtn.setAttribute('position', '0.29 0.055 -0.05');
                    this.el.sceneEl?.addEventListener('asset-change', (e) => {
                        const ce = e as CustomEvent;
                        this.currentAssetid = ce.detail.assetId;
                        switch (this.currentAssetid) {
                            case 0:
                                LDRTriggerBtn.setAttribute('scale', '1 1 1');
                                break;
                            case 1:
                                LDRTriggerBtn.setAttribute('scale', '0 0 0');
                                break;
                        }
                    });
                    LDRTriggerBtn.play();
                    LDRTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'LDR'
                            const body = 'A light dependent resistor drops in resistance when the light intensity is increased. LDRs are commonly used in daylight sensors (for example on street lights) turning on lighting when the surroundings become dark.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(LDRTriggerBtn);

                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (LDRTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = LDRTriggerBtn


                    });
                } else {
                    console.warn('No trigger btn: wires');
                }

                const thermostatTriggerBtn = poolButtons.requestEntity();
                if (thermostatTriggerBtn) {
                    thermostatTriggerBtn.setAttribute('position', '0 0.035 0');
                    thermostatTriggerBtn.setAttribute('scale', '0 0 0');

                    thermostatTriggerBtn.play();
                    this.el.sceneEl?.addEventListener('asset-change', (e) => {
                        const ce = e as CustomEvent;
                        this.currentAssetid = ce.detail.assetId;
                        switch (this.currentAssetid) {
                            case 0:
                                thermostatTriggerBtn.setAttribute('scale', '0 0 0');
                                break;
                            case 1:
                                thermostatTriggerBtn.setAttribute('scale', '1 1 1');
                                break;
                        }
                    });
                    thermostatTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Thermostat'
                            const body = 'When it is cold, a thermistor takes a large share of the potential difference in the circuit. As a result, the bulb in the circuit is very dim. This can be used as an indicator of the temperature of the thermistor.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(thermostatTriggerBtn);
                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (thermostatTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = thermostatTriggerBtn

                    });
                } else {
                    console.log('No trigger btn');
                }


                const thermistorTriggerBtn = poolButtons.requestEntity();
                if (thermistorTriggerBtn) {
                    thermistorTriggerBtn.setAttribute('position', '0.29 0.055 -0.05');
                    thermistorTriggerBtn.setAttribute('scale', '0 0 0');
                    this.el.sceneEl?.addEventListener('asset-change', (e) => {
                        const ce = e as CustomEvent;
                        this.currentAssetid = ce.detail.assetId;
                        switch (this.currentAssetid) {
                            case 0:
                                thermistorTriggerBtn.setAttribute('scale', '0 0 0');
                                break;
                            case 1:
                                thermistorTriggerBtn.setAttribute('scale', '1 1 1');
                                break;
                        }
                    });
                    thermistorTriggerBtn.play();
                    thermistorTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Thermistor'
                            const body = 'A thermistor is a component that decreases its resistance when the temperature of its surroundings is increased. Thermistors are commonly used in thermostats, devices that will turn on heating or cooling if their surroundings get too hot or cold.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(thermistorTriggerBtn);

                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (thermistorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = thermistorTriggerBtn

                    });
                } else {
                    console.warn('No trigger btn: wires');
                }

            };
        },
        tick(this: ICircuitControl, time: number, deltaTime: number) {
            const ring = document.getElementById('ring')
            if (!ring) {
                const circuit = document.getElementById('circuit') as AFrame.Entity;
                const cameraEl = this.el.sceneEl?.querySelector('a-camera') as AFrame.Entity;
                if (cameraEl && circuit) {
                    const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                    this.lightMesh.lookAt(camPos)
                    this.lightMesh.rotation.x += ((Math.PI / 180))
                    this.lightMesh.rotation.y += (90 * (Math.PI / 180))
                    this.lightMesh.rotation.z += (90 * (Math.PI / 180))
                    let cameraPosition = camPos.clone()
                    let spherePos = circuit.object3D.position.clone()
                    let distance = cameraPosition.distanceTo(spherePos)
                    if (!this.distanceEvent) {
                        this.distanceEvent = new CustomEvent('distance-change', {detail: {value: false}});
                    }
                    if ((distance < 8 && cameraPosition.x < 4) || (distance < 10 && cameraPosition.x > 4)) {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = true
                        scene.emit('distance-change',this.distanceEvent.detail.value);
                        circuit.object3D.visible = false
                    } else {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = false
                        scene.emit('distance-change',this.distanceEvent.detail.value);
                        if (!circuit.object3D.visible){
                            circuit.object3D.visible = true
                        }
                    }
                }
            }
        }
    },
};
export {CircuitControlComponent}
