import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';
import {IShaderFireAframe} from './shader-fire';
import {IShaderSmokeAframe} from './shader-smoke';
import {tr} from "date-fns/locale";

interface IExothermicAndEndothermicReactionsSceneAframe {
    tempText: AFrame.Entity;
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System>>>;
    annotationComponent: IAnnotationAframe;
    morphWater: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>;
    morphIce: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>;
    matchAnimation: THREE.AnimationAction;
    matchArrow1Animation: THREE.AnimationAction;
    matchArrow2Animation: THREE.AnimationAction;
    matchArrow3Animation: THREE.AnimationAction;
    matchArrow4Animation: THREE.AnimationAction;
    matchArrow5Animation: THREE.AnimationAction;
    matchArrow6Animation: THREE.AnimationAction;
    snowFirstCloudAnimation: THREE.AnimationAction;
    snowSecondCloudAnimation: THREE.AnimationAction;
    snowArrow1Animation: THREE.AnimationAction;
    snowArrow2Animation: THREE.AnimationAction;
    snowArrow3Animation: THREE.AnimationAction;
    snowArrow4Animation: THREE.AnimationAction;
    snowArrow5Animation: THREE.AnimationAction;
    snowArrow6Animation: THREE.AnimationAction;
    OO1Animation: THREE.AnimationAction;
    OO2Animation: THREE.AnimationAction;
    OO3Animation: THREE.AnimationAction;
    CO21Animation: THREE.AnimationAction;
    CO22Animation: THREE.AnimationAction;
    CO23Animation: THREE.AnimationAction;
    plantArrow1Animation: THREE.AnimationAction;
    plantArrow2Animation: THREE.AnimationAction;
    plantArrow3Animation: THREE.AnimationAction;
    sodiumArrow1Animation: THREE.AnimationAction;
    sodiumArrow2Animation: THREE.AnimationAction;
    sodiumArrow3Animation: THREE.AnimationAction;
    sodiumArrow4Animation: THREE.AnimationAction;
    sodiumArrow5Animation: THREE.AnimationAction;
    sodiumArrow6Animation: THREE.AnimationAction;
    sodiumFallAnimation: THREE.AnimationAction;
    sodiumRoundAnimation: THREE.AnimationAction;
    iceArrow1Animation: THREE.AnimationAction;
    iceArrow2Animation: THREE.AnimationAction;
    iceArrow3Animation: THREE.AnimationAction;
    iceArrow4Animation: THREE.AnimationAction;
    iceArrow5Animation: THREE.AnimationAction;
    iceArrow6Animation: THREE.AnimationAction;
    matchMixer: THREE.AnimationMixer;
    matchArrowMixer: THREE.AnimationMixer;
    iceArrowMixer: THREE.AnimationMixer;
    snowCloudsMixer: THREE.AnimationMixer;
    snowArrowsMixer: THREE.AnimationMixer;
    OOMixer: THREE.AnimationMixer;
    CO2Mixer: THREE.AnimationMixer;
    plantArrowMixer: THREE.AnimationMixer;
    sodiumArrowMixer: THREE.AnimationMixer;
    sodiumFallMixer: THREE.AnimationMixer;
    sodiumRoundMixer: THREE.AnimationMixer;
    combustionHandler: () => void;
    snowHandler: () => void;
    photosynthesisHandler: () => void;
    oxidationHandler: () => void;
    meltingIceHandler: () => void;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    buttonsInitialised: boolean;
    match: AFrame.Entity;
    snow: AFrame.Entity;
    photosynthesis: AFrame.Entity;
    sodium: AFrame.Entity;
    ice: AFrame.Entity;
    el: AFrame.Entity;
    prevEl: AFrame.Entity;
    currentAssetId: number;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const LessonStart = {
    name: 'lesson-scene',
    val: {
        init(this: IExothermicAndEndothermicReactionsSceneAframe) {
            const holder = document.getElementById('holder') as AFrame.Entity;

            holder.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;

                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
                const initializeEntity = (id: string) => {
                    const entity = document.getElementById(id) as AFrame.Entity;
                    if (entity) {
                        entity.object3D.traverse((child: any) => {
                            if (child.name.includes('Arrow')) {
                                child.visible = false;
                            }
                        });
                    }
                    return entity;
                };
                this.match = initializeEntity('match');
                this.snow = initializeEntity('snow');
                this.photosynthesis = initializeEntity('photosynthesis');
                this.photosynthesis.object3D.traverse((child: any) => {
                    if (child.name.includes('O2') || child.name.includes('C02') || child.name.includes('O0')) {
                        child.visible = false;
                    }
                });
                this.sodium = initializeEntity('sodium');
                this.ice = initializeEntity('ice');
                this.sodium = document.getElementById('sodium') as AFrame.Entity;
                const sphere = this.sodium.object3D.getObjectByName('Sphere') as any;
                if (this.sodium.object3D) {
                    if (sphere) {
                        sphere.material.opacity = 0
                        sphere.material.needsUpdate = true
                    }
                }
                this.morphWater = this.ice.object3D.getObjectByName('Water') as THREE.Mesh;
                this.morphIce = this.ice.object3D.getObjectByName('IceCube') as THREE.Mesh;
                this.prevEl = this.match
            });
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                    this.annotationComponent.line.visible = false
                    initialiseAnimations()
                    if (!this.currentAssetId) {
                        this.match.object3D.visible = true
                        this.currentAssetId = 0
                    }
                    if (this.match.hasAttribute('shader-fire')) {
                        const fire = this.match.components['shader-fire'] as unknown as IShaderFireAframe;
                        fire.setVisibility(false);
                    }
                    if (this.sodium.hasAttribute('shader-smoke')) {
                        const smoke = this.sodium.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                        smoke.setVisibility(false);
                    }
                }
            })
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: holder; scale: 25 25 25; offset: 0 0 -4');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                    if (this.match.hasAttribute('shader-fire')) {
                        const fire = this.match.components['shader-fire'] as unknown as IShaderFireAframe;
                        fire.setVisibility(false);
                    }
                    if (this.sodium.hasAttribute('shader-smoke')) {
                        const smoke = this.sodium.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                        smoke.setVisibility(false);
                    }
                }

            });

            this.el.sceneEl?.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                this.currentAssetId = ce.detail.assetId;
                this.annotationComponent.line.visible = false
                switch (this.currentAssetId) {
                    case 0:
                        setVisibility(this.match, true)
                        setVisibility(this.prevEl, false);
                        if (this.match.hasAttribute('shader-fire')) {
                            const fire = this.match.components['shader-fire'] as unknown as IShaderFireAframe;
                            fire.setVisibility(false);
                        }
                        if (this.match)
                            this.match.object3D.traverse((child: any) => {
                                if (child.name.includes('Arrow')) {
                                    child.visible = false;
                                }
                            });
                        this.prevEl = this.match
                        break;
                    case 1:
                        setVisibility(this.snow, true)
                        setVisibility(this.prevEl, false);
                        const snowFlakes = this.ice.object3D.getObjectByName('SnowFlake') as THREE.Points
                        if (snowFlakes) snowFlakes.visible = false
                        if (this.snow)
                            this.snow.object3D.traverse((child: any) => {
                                if (child.name.includes('Arrow')) {
                                    child.visible = false;
                                }
                            });
                        this.prevEl = this.snow
                        break;
                    case 2:
                        setVisibility(this.photosynthesis, true)
                        setVisibility(this.prevEl, false);
                        if (this.photosynthesis)
                            this.photosynthesis.object3D.traverse((child: any) => {
                                if (child.name.includes('Arrow')) {
                                    child.visible = false;
                                }
                                if (child.name.includes('O2') || child.name.includes('C02') || child.name.includes('O0')) {
                                    child.visible = false;
                                }
                            });
                        this.prevEl = this.photosynthesis
                        break;
                    case 3:
                        setVisibility(this.sodium, true)
                        setVisibility(this.prevEl, false);
                        this.sodiumRoundAnimation.stop()
                        this.sodiumFallAnimation.stop()
                        this.sodiumFallAnimation.play()
                        this.sodiumFallAnimation.paused = true
                        const sphere = this.sodium.object3D.getObjectByName('Sphere') as any;
                        sphere.material.opacity = 0
                        sphere.material.needsUpdate = true
                        if (this.sodium.hasAttribute('shader-smoke')) {
                            const smoke = this.sodium.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                            smoke.setVisibility(false);
                        }
                        if (this.sodium)
                            this.sodium.object3D.traverse((child: any) => {
                                if (child.name.includes('Arrow')) {
                                    child.visible = false;
                                }
                            });

                        const beaker3 = this.sodium.object3D.getObjectByName('Beaker_500ml')
                        const water3 = this.sodium.object3D.getObjectByName('BeakerFluid')
                        const mat3 = (beaker3 as THREE.Mesh).material as THREE.MeshPhysicalMaterial;
                        let waterMat3 = (water3 as THREE.Mesh).material as THREE.MeshPhysicalMaterial;
                        mat3.transparent = true;
                        mat3.color = new THREE.Color(0x4f4f4f)
                        mat3.side = 2
                        mat3.metalness = 0.2;
                        mat3.reflectivity = 0.3;
                        mat3.thickness = 2;
                        mat3.transmission = 0.8;
                        mat3.needsUpdate = true;
                        waterMat3.color = new THREE.Color(0x79edfc)
                        waterMat3.needsUpdate = true;
                        this.sodiumFallAnimation.play()
                        this.sodiumFallAnimation.paused = true
                        this.prevEl = this.sodium
                        break;
                    case 4:
                        setVisibility(this.ice, true)
                        setVisibility(this.prevEl, false);
                        if (this.ice)
                            this.ice.object3D.traverse((child: any) => {
                                if (child.name.includes('Arrow')) {
                                    child.visible = false;
                                }
                            });
                        this.prevEl = this.ice
                        break;
                }
            });

            // Extract common visibility toggling logic
            function setVisibility(el: AFrame.Entity, visible: boolean) {
                if (el && el.object3D) {
                    el.object3D.visible = visible;
                }
            }

            // Function for handling animations
            function animate(el: AFrame.Entity, animation: THREE.AnimationAction, duration: number) {
                el.object3D.visible = true;
                animation.play();
                animation.repetitions = 1;
                animation.clampWhenFinished = true;
                return new Promise((resolve) => setTimeout(resolve, duration * 1000));
            }

            function createSnowAnimation() {
                const snowGeometry = new THREE.BufferGeometry();
                // Number of snowflakes
                const numSnowflakes = 20;
                const positions = new Float32Array(numSnowflakes * 3);

                // Create random snowflakes
                for (let i = 0; i < numSnowflakes; i++) {
                    const x = (Math.random() - 0.5) * 0.5;
                    const y = Math.random() * 15;
                    const z = (Math.random() - 0.5) * 0.5;

                    positions[i * 3] = x;
                    positions[i * 3 + 1] = y;
                    positions[i * 3 + 2] = z;
                }

                snowGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

                const snowMaterial = new THREE.PointsMaterial({color: 0xffffff, size: 0.05});
                const snow = new THREE.Points(snowGeometry, snowMaterial)
                snow.name = 'SnowFlake'
                return snow;
            }

            // Function to animate the snowfall
            function animateSnow() {
                // Get the snow object
                const snowObject = document.getElementById('snow') as AFrame.Entity;
                // Create the snow animation
                const snowAnimation = createSnowAnimation();
                // Add the snow animation to the snowObject
                snowObject.object3D.add(snowAnimation);

                function animate() {
                    const positions = snowAnimation.geometry.attributes.position.array;
                    for (let i = 0; i < positions.length; i += 3) {
                        let y = positions[i + 1];

                        y -= 0.01; // Move the snowflake along the y-axis

                        // Check if the snowflake is above the A-Frame object
                        if (y < -5) {
                            // Move the snowflake to a random position above the A-Frame object
                            const xRandom = (Math.random() - 0.5) * 2;
                            const zRandom = (Math.random() - 0.5) * 2;
                            // @ts-ignore
                            positions[i] = xRandom;
                            // @ts-ignore
                            positions[i + 1] = 0;
                            // @ts-ignore
                            positions[i + 2] = zRandom;
                        } else {
                            // Check if the snowflake is above the A-Frame object
                            // Get the A-Frame object's position (assuming it's at (0, 0, 0))
                            const aframeObjectY = 1;

                            // Define the height above the A-Frame object where snowflakes are visible
                            const visibleHeight = 10;

                            // If the snowflake is below the visible height, hide it
                            if (y < aframeObjectY + visibleHeight) {
                                // @ts-ignore
                                positions[i + 1] = -1000; // Move the snowflake far below the scene to hide it
                            }
                        }
                    }

                    snowAnimation.geometry.attributes.position.needsUpdate = true;

                    requestAnimationFrame(animate);
                }

                // Animate the snowflakes
                animate();
            }

            const morphTubeUp = () => {
                const morphTubeWater = this.morphWater as THREE.Mesh;
                const morphTubeIce = this.morphIce as THREE.Mesh;
                const startTime = performance.now();
                const morph = (timestamp: number) => {
                    const elapsed = timestamp - startTime;
                    let morphValue = elapsed / 1600;
                    if (morphTubeWater.morphTargetInfluences && morphTubeIce.morphTargetInfluences) {
                        morphValue = Math.min(Math.max(morphValue, 0), 1);
                        morphTubeWater.morphTargetInfluences[0] = morphValue;
                        morphTubeIce.morphTargetInfluences[0] = morphValue;
                    }
                    if (morphValue < 1) {
                        requestAnimationFrame(morph);
                    } else {
                        if (morphTubeWater.morphTargetInfluences && morphTubeIce.morphTargetInfluences) {
                            morphTubeWater.morphTargetInfluences[0] = 1;
                            morphTubeIce.morphTargetInfluences[0] = 1;
                        }
                        return;
                    }
                }
                requestAnimationFrame(morph);
            };
            const initialiseAnimations = () => {
                const match = document.getElementById('match') as AFrame.Entity;
                const snow = document.getElementById('snow') as AFrame.Entity;
                const photosynthesis = document.getElementById('photosynthesis') as AFrame.Entity;
                const sodium = document.getElementById('sodium') as AFrame.Entity;
                const ice = document.getElementById('ice') as AFrame.Entity;
                const animatedMatchEl = match.object3D.getObjectByName('Scene') as any;
                const animatedSnowEl = snow.object3D.getObjectByName('Scene') as any;
                const animatedPhotosyntesisEl = photosynthesis.object3D.getObjectByName('Scene') as any;
                const animatedSodiumEl = sodium.object3D.getObjectByName('Scene') as any;
                const animatedIceEl = ice.object3D.getObjectByName('Scene') as any;

                this.matchMixer = new THREE.AnimationMixer(animatedMatchEl)
                this.matchArrowMixer = new THREE.AnimationMixer(animatedMatchEl)
                const [Match, MatchArrow1, MatchArrow2, MatchArrow3, MatchArrow4, MatchArrow5, MatchArrow6] = animatedMatchEl.animations
                this.matchAnimation = this.matchMixer.clipAction(Match)
                this.matchArrow1Animation = this.matchArrowMixer.clipAction(MatchArrow1)
                this.matchArrow1Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.matchArrow2Animation = this.matchArrowMixer.clipAction(MatchArrow2)
                this.matchArrow2Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.matchArrow3Animation = this.matchArrowMixer.clipAction(MatchArrow3)
                this.matchArrow3Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.matchArrow4Animation = this.matchArrowMixer.clipAction(MatchArrow4)
                this.matchArrow4Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.matchArrow5Animation = this.matchArrowMixer.clipAction(MatchArrow5)
                this.matchArrow5Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.matchArrow6Animation = this.matchArrowMixer.clipAction(MatchArrow6)
                this.matchArrow6Animation.setLoop(THREE.LoopPingPong, Infinity)

                this.snowCloudsMixer = new THREE.AnimationMixer(animatedSnowEl)
                this.snowArrowsMixer = new THREE.AnimationMixer(animatedSnowEl)
                const [Cloud1, Cloud2, SnowArrow1, SnowArrow2, SnowArrow3, SnowArrow4, SnowArrow5, SnowArrow6] = animatedSnowEl.animations
                this.snowFirstCloudAnimation = this.snowCloudsMixer.clipAction(Cloud1)
                this.snowSecondCloudAnimation = this.snowCloudsMixer.clipAction(Cloud2)
                this.snowArrow1Animation = this.snowArrowsMixer.clipAction(SnowArrow1)
                this.snowArrow1Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.snowArrow2Animation = this.snowArrowsMixer.clipAction(SnowArrow2)
                this.snowArrow2Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.snowArrow3Animation = this.snowArrowsMixer.clipAction(SnowArrow3)
                this.snowArrow3Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.snowArrow4Animation = this.snowArrowsMixer.clipAction(SnowArrow4)
                this.snowArrow4Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.snowArrow5Animation = this.snowArrowsMixer.clipAction(SnowArrow5)
                this.snowArrow5Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.snowArrow6Animation = this.snowArrowsMixer.clipAction(SnowArrow6)
                this.snowArrow6Animation.setLoop(THREE.LoopPingPong, Infinity)

                this.OOMixer = new THREE.AnimationMixer(animatedPhotosyntesisEl)
                this.CO2Mixer = new THREE.AnimationMixer(animatedPhotosyntesisEl)
                this.plantArrowMixer = new THREE.AnimationMixer(animatedPhotosyntesisEl)
                const [OO1Clip, CO21Clip, CO22Clip, CO23Clip, OO2Clip, OO3Clip, PlantArrow1, PlantArrow2, PlantArrow3] = animatedPhotosyntesisEl.animations
                this.OO1Animation = this.OOMixer.clipAction(OO1Clip)
                this.OO2Animation = this.OOMixer.clipAction(OO2Clip)
                this.OO3Animation = this.OOMixer.clipAction(OO3Clip)
                this.CO21Animation = this.CO2Mixer.clipAction(CO21Clip)
                this.CO22Animation = this.CO2Mixer.clipAction(CO22Clip)
                this.CO23Animation = this.CO2Mixer.clipAction(CO23Clip)
                this.plantArrow1Animation = this.plantArrowMixer.clipAction(PlantArrow1)
                this.plantArrow1Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.plantArrow2Animation = this.plantArrowMixer.clipAction(PlantArrow2)
                this.plantArrow2Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.plantArrow3Animation = this.plantArrowMixer.clipAction(PlantArrow3)
                this.plantArrow3Animation.setLoop(THREE.LoopPingPong, Infinity)

                this.sodiumFallMixer = new THREE.AnimationMixer(animatedSodiumEl)
                this.sodiumRoundMixer = new THREE.AnimationMixer(animatedSodiumEl)
                this.sodiumArrowMixer = new THREE.AnimationMixer(animatedSodiumEl)
                const [, SodiumRound, SodiumFall, , SodiumArrow1, SodiumArrow2, SodiumArrow3, SodiumArrow4, SodiumArrow5, SodiumArrow6] = animatedSodiumEl.animations
                this.sodiumRoundAnimation = this.sodiumRoundMixer.clipAction(SodiumRound)
                this.sodiumFallAnimation = this.sodiumFallMixer.clipAction(SodiumFall)
                this.sodiumArrow1Animation = this.sodiumArrowMixer.clipAction(SodiumArrow1)
                this.sodiumArrow1Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.sodiumArrow2Animation = this.sodiumArrowMixer.clipAction(SodiumArrow2)
                this.sodiumArrow2Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.sodiumArrow3Animation = this.sodiumArrowMixer.clipAction(SodiumArrow3)
                this.sodiumArrow3Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.sodiumArrow4Animation = this.sodiumArrowMixer.clipAction(SodiumArrow4)
                this.sodiumArrow4Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.sodiumArrow5Animation = this.sodiumArrowMixer.clipAction(SodiumArrow5)
                this.sodiumArrow5Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.sodiumArrow6Animation = this.sodiumArrowMixer.clipAction(SodiumArrow6)
                this.sodiumArrow6Animation.setLoop(THREE.LoopPingPong, Infinity)

                this.iceArrowMixer = new THREE.AnimationMixer(animatedIceEl)
                const [IceArrow1, IceArrow2, IceArrow3, IceArrow4, IceArrow5, IceArrow6] = animatedIceEl.animations
                this.iceArrow1Animation = this.iceArrowMixer.clipAction(IceArrow1)
                this.iceArrow1Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.iceArrow2Animation = this.iceArrowMixer.clipAction(IceArrow2)
                this.iceArrow2Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.iceArrow3Animation = this.iceArrowMixer.clipAction(IceArrow3)
                this.iceArrow3Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.iceArrow4Animation = this.iceArrowMixer.clipAction(IceArrow4)
                this.iceArrow4Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.iceArrow5Animation = this.iceArrowMixer.clipAction(IceArrow5)
                this.iceArrow5Animation.setLoop(THREE.LoopPingPong, Infinity)
                this.iceArrow6Animation = this.iceArrowMixer.clipAction(IceArrow6)
                this.iceArrow6Animation.setLoop(THREE.LoopPingPong, Infinity)
            }
            const matchAnnotation = () => {
                this.matchAnimation.stop()
                animate(this.match, this.matchAnimation, this.matchAnimation.getClip().duration)
                    .then(() => {
                        if (this.match.hasAttribute('shader-fire')) {
                            const fire = this.match.components['shader-fire'] as unknown as IShaderFireAframe;
                            fire.setVisibility(true);
                        } else {
                            this.match.setAttribute('shader-fire', '');
                        }
                        if (this.match)
                            this.match.object3D.traverse((child: any) => {
                                if (child.name.includes('Arrow')) {
                                    child.visible = true;
                                }
                            });
                        this.matchArrow1Animation.play()
                        this.matchArrow2Animation.play()
                        this.matchArrow3Animation.play()
                        this.matchArrow4Animation.play()
                        this.matchArrow5Animation.play()
                        this.matchArrow6Animation.play()
                    })
                    .catch((error) => console.error(error));
                this.prevEl = this.match
            }
            const snowAnnotation = () => {
                this.snowFirstCloudAnimation.stop();
                this.snowSecondCloudAnimation.stop();
                Promise.all([
                    animate(this.snow, this.snowFirstCloudAnimation, this.snowFirstCloudAnimation.getClip().duration),
                    animate(this.snow, this.snowSecondCloudAnimation, this.snowSecondCloudAnimation.getClip().duration)
                ])
                    .then(() => {
                        const snowFlakes = this.ice.object3D.getObjectByName('SnowFlake') as THREE.Points
                        if (snowFlakes) {
                            snowFlakes.visible = true
                        } else {
                            animateSnow();
                        }
                        this.snow.object3D.traverse((child: any) => {
                            if (child.name.includes('Arrow')) {
                                child.visible = true;
                            }
                        });
                    })
                    .catch((error) => console.error(error));
                this.snow.object3D.traverse((child: any) => {
                    if (child.name.includes('Arrow')) {
                        child.visible = false;
                    }
                    if (child.name === 'SnowFlake') {
                        child.visible = false;
                    }
                });
                this.snowArrow1Animation.play();
                this.snowArrow2Animation.play();
                this.snowArrow3Animation.play();
                this.snowArrow4Animation.play();
                this.snowArrow5Animation.play();
                this.snowArrow6Animation.play();
                this.prevEl = this.snow
            }
            const photosynthesisAnnotation = () => {
                this.photosynthesis.object3D.traverse((child: any) => {
                    if (child.name.includes('Arrow') || child.name.includes('O2') || child.name.includes('C02') || child.name.includes('O0')) {
                        child.visible = true;
                    }
                });
                this.OO1Animation.play()
                this.OO2Animation.play()
                this.OO3Animation.play()
                this.CO21Animation.play()
                this.CO22Animation.play()
                this.CO23Animation.play()
                this.plantArrow1Animation.play()
                this.plantArrow2Animation.play()
                this.plantArrow3Animation.play()
                this.prevEl = this.photosynthesis

            }
            const sodiumAnnotation = () => {
                this.sodiumFallAnimation.play()
                this.sodiumFallAnimation.paused = true
                const sphere = this.sodium.object3D.getObjectByName('Sphere') as any;
                this.sodium.object3D.traverse((child: any) => {
                    if (child.name.includes('Arrow')) {
                        child.visible = false;
                    }
                });
                setTimeout(() => {
                    let opacity = 0
                    const opacityInterval = setInterval(() => {
                        if (opacity < 0.7) {
                            opacity += 0.01
                            sphere.material.opacity = opacity
                            sphere.material.needsUpdate = true
                        } else {
                            clearInterval(opacityInterval)
                        }
                    }, 100)
                    this.sodium.object3D.traverse((child: any) => {
                        if (child.name.includes('Arrow')) {
                            child.visible = true;
                            if (this.sodium.hasAttribute('shader-smoke')) {
                                const smoke = this.sodium.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                                smoke.setVisibility(true);
                            } else {
                                this.sodium.setAttribute('shader-smoke', '');
                            }
                        }
                    });
                }, this.sodiumFallAnimation.getClip().duration * 1000)
                this.sodiumFallAnimation.stop()
                this.sodiumFallAnimation.repetitions = 1
                this.sodiumFallAnimation.play()
                setTimeout(() => {
                    this.sodium.object3D.traverse((child: any) => {
                        if (child.name.includes('Arrow')) {
                            child.visible = true;
                        }
                    });
                    this.sodiumRoundAnimation.stop()
                    this.sodiumArrow1Animation.play()
                    this.sodiumArrow2Animation.play()
                    this.sodiumArrow3Animation.play()
                    this.sodiumArrow4Animation.play()
                    this.sodiumArrow5Animation.play()
                    this.sodiumArrow6Animation.play()
                    this.sodiumRoundAnimation.play()
                }, this.sodiumFallAnimation.getClip().duration * 1000)
                this.prevEl = this.sodium
            }
            const iceAnnotation = () => {
                setTimeout(() => {
                    this.ice.object3D.traverse((child: any) => {
                        if (child.name.includes('Arrow')) {
                            child.visible = true;
                        }
                    });
                    this.iceArrow1Animation.play()
                    this.iceArrow2Animation.play()
                    this.iceArrow3Animation.play()
                    this.iceArrow4Animation.play()
                    this.iceArrow5Animation.play()
                    this.iceArrow6Animation.play()
                }, 2000)
                morphTubeUp()
            }
            this.combustionHandler = () => {
                matchAnnotation()
                if (this.onObjectSelected) {
                    const title = 'Combustion';
                    const body = 'Combustion reactions release energy, often in the form of thermal energy (heat).'
                    this.onObjectSelected({title, body})
                    this.annotationComponent.line.visible = false
                } else {
                    console.log('No object selected method')
                }
            }
            this.snowHandler = () => {
                snowAnnotation()
                if (this.onObjectSelected) {
                    const title = 'Formation of Snow';
                    const body = 'As snow (or ice) forms, heat exits the clouds (or water) into the environment.'
                    this.onObjectSelected({title, body})
                    this.annotationComponent.line.visible = false
                } else {
                    console.log('No object selected method')
                }
            }
            this.photosynthesisHandler = () => {
                photosynthesisAnnotation()
                if (this.onObjectSelected) {
                    const title = 'Photosynthesis';
                    const body = 'Photosynthesis is fuelled by energy from the Sun. It requires more energy than it releases.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.oxidationHandler = () => {
                sodiumAnnotation()
                if (this.onObjectSelected) {
                    const title = 'Oxidation';
                    const body = 'Combustion is a type of oxidation reaction. Oxidation reactions usually release heat.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.meltingIceHandler = () => {
                iceAnnotation()
                if (this.onObjectSelected) {
                    const title = 'Melting Ice';
                    const body = 'Ice melts as heat from the environment breaks the bonds that maintain the ice’s rigid structure.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const combustionTriggerBtn = poolButtons.requestEntity()
                combustionTriggerBtn?.setAttribute('position', '0 0.14 0.025')
                combustionTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        combustionTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                    }
                    if (this.currentAssetId === 1) {
                        combustionTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        combustionTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 3) {
                        combustionTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 4) {
                        combustionTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                combustionTriggerBtn?.play()
                combustionTriggerBtn?.addEventListener('click', () => {
                    this.combustionHandler()
                    if (combustionTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(combustionTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (combustionTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = combustionTriggerBtn
                    }
                });
                const snowTriggerButton = poolButtons.requestEntity();
                snowTriggerButton?.setAttribute('position', '0 0.17 0.022');
                snowTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        snowTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        snowTriggerButton?.setAttribute('scale', '0.1 0.1 0.1')
                    }
                    if (this.currentAssetId === 2) {
                        snowTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 3) {
                        snowTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 4) {
                        snowTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                snowTriggerButton?.play()
                snowTriggerButton?.addEventListener('click', () => {
                    this.snowHandler()
                    if (snowTriggerButton) {
                        this.annotationComponent.setObjectToFollow(snowTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (snowTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = snowTriggerButton
                    }
                });
                const photosynthesisTriggerButton = poolButtons.requestEntity();
                photosynthesisTriggerButton?.setAttribute('position', '0 0.035 0.022');
                photosynthesisTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        photosynthesisTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        photosynthesisTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        photosynthesisTriggerButton?.setAttribute('scale', '0.1 0.1 0.1')
                    }
                    if (this.currentAssetId === 3) {
                        photosynthesisTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 4) {
                        photosynthesisTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                photosynthesisTriggerButton?.play()
                photosynthesisTriggerButton?.addEventListener('click', () => {
                    this.photosynthesisHandler()
                    if (photosynthesisTriggerButton) {
                        this.annotationComponent.setObjectToFollow(photosynthesisTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (photosynthesisTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = photosynthesisTriggerButton
                    }
                });
                const oxidationTriggerButton = poolButtons.requestEntity();
                oxidationTriggerButton?.setAttribute('position', '0 0.1 0.05');
                oxidationTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        oxidationTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        oxidationTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        oxidationTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 3) {
                        oxidationTriggerButton?.setAttribute('scale', '0.1 0.1 0.1')
                    }
                    if (this.currentAssetId === 4) {
                        oxidationTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                oxidationTriggerButton?.play()
                oxidationTriggerButton?.addEventListener('click', () => {
                    this.oxidationHandler()
                    if (oxidationTriggerButton) {
                        this.annotationComponent.setObjectToFollow(oxidationTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (oxidationTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = oxidationTriggerButton
                    }
                });
                const meltingIceTriggerButton = poolButtons.requestEntity();
                meltingIceTriggerButton?.setAttribute('position', '0 0.1 0.05');
                meltingIceTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        meltingIceTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        meltingIceTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        meltingIceTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 3) {
                        meltingIceTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 4) {
                        meltingIceTriggerButton?.setAttribute('scale', '0.1 0.1 0.1')
                    }
                });
                meltingIceTriggerButton?.play()
                meltingIceTriggerButton?.addEventListener('click', () => {
                    this.meltingIceHandler()
                    if (meltingIceTriggerButton) {
                        this.annotationComponent.setObjectToFollow(meltingIceTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (meltingIceTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = meltingIceTriggerButton
                    }
                });
            };

        },
        tick(this: IExothermicAndEndothermicReactionsSceneAframe, time: number, deltaTime: number) {
            if (this.matchMixer) {
                this.matchMixer.update(deltaTime * 0.001);
            }
            if (this.matchArrowMixer) {
                this.matchArrowMixer.update(deltaTime * 0.001);
            }
            if (this.iceArrowMixer) {
                this.iceArrowMixer.update(deltaTime * 0.001);
            }
            if (this.snowCloudsMixer) {
                this.snowCloudsMixer.update(deltaTime * 0.001);
            }
            if (this.snowArrowsMixer) {
                this.snowArrowsMixer.update(deltaTime * 0.001);
            }
            if (this.OOMixer) {
                this.OOMixer.update(deltaTime * 0.001);
            }
            if (this.CO2Mixer) {
                this.CO2Mixer.update(deltaTime * 0.001);
            }
            if (this.plantArrowMixer) {
                this.plantArrowMixer.update(deltaTime * 0.001);
            }
            if (this.sodiumArrowMixer) {
                this.sodiumArrowMixer.update(deltaTime * 0.001);
            }
            if (this.sodiumFallMixer) {
                this.sodiumFallMixer.update(deltaTime * 0.001);
            }
            if (this.sodiumRoundMixer) {
                this.sodiumRoundMixer.update(deltaTime * 0.001);
            }
        },

    },
};
export {LessonStart as ExoAndEndothermicReactionsSceneComponent}
