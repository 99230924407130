import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    poolEntity: AFrame.Entity;
    magneticTrackHandler: () => void;
    superconductorHandler: () => void;
    mixer: THREE.AnimationMixer;
    clipAnimation: THREE.AnimationAction;
    cube1: AFrame.Entity;
    annotationComponent: IAnnotationAframe;
    currentDeactivatedButton: AFrame.Entity;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    interval: NodeJS.Timer;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            const model = document.getElementById('model') as AFrame.Entity;

            model.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                const animatedEl = model.object3D.getObjectByName('Physics12') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [MoveDisc] = animatedEl.animations
                this.clipAnimation = this.mixer.clipAction(MoveDisc)
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                    createClickableAreas()
                }
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: model; scale: 15 15 15; offset: 0 -4 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            const createClickableAreas = () => {
                this.cube1 = document.createElement('a-entity');
                this.cube1.setAttribute('geometry', {
                    primitive: 'box',
                    height: 0.1,
                    width: 0.25,
                    depth: 0.25
                });
                this.cube1.setAttribute('position', '0 0 0');
                this.cube1.setAttribute('visible', 'false');
                this.cube1.setAttribute('class', 'cantap');
                this.cube1.addEventListener('click', () => {
                    if (this.clipAnimation.isRunning()) {
                        clearInterval(this.interval)
                    } else {
                        this.clipAnimation.play()
                    }
                    createIntervalForClip()

                });
                this.el.appendChild(this.cube1);
            }
            const createIntervalForClip = () => {
                this.clipAnimation.timeScale = 3
                let animationVariable = 0;
                this.interval = setInterval(() => {
                    animationVariable += 0.1
                    if (animationVariable !== 3) {
                        this.clipAnimation.timeScale = 3 - animationVariable
                    } else {
                        this.clipAnimation.timeScale = 0
                    }
                }, 1000)
            }
            this.magneticTrackHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Magnetic Track';
                    const body = 'When near a magnetic track, the superconductor creates its own magnetic field, keeping the superconductor a fixed distance from the track. This could be used to create levitating ‘mag-lev’ trains.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.superconductorHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Superconductor';
                    const body = 'To become a superconductor, this material must be cooled below a critical temperature. Current superconductors can reach this temperature when cooled with liquid nitrogen. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const magneticTrackTriggerBtn = poolButtons.requestEntity()
                magneticTrackTriggerBtn?.setAttribute('position', '-0.045 0.035 0.1')
                magneticTrackTriggerBtn?.setAttribute('scale', '2 2 2')
                magneticTrackTriggerBtn?.play()
                magneticTrackTriggerBtn?.addEventListener('click', () => {
                    this.magneticTrackHandler()
                    if (magneticTrackTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(magneticTrackTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (magneticTrackTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = magneticTrackTriggerBtn
                    }
                });
                const superconductorTriggerButton = poolButtons.requestEntity();
                superconductorTriggerButton?.setAttribute('position', '0.025 0.075 0.05');
                superconductorTriggerButton?.setAttribute('scale', '2 2 2')
                superconductorTriggerButton?.play()
                superconductorTriggerButton?.addEventListener('click', () => {
                    this.superconductorHandler()
                    if (superconductorTriggerButton) {
                        this.annotationComponent.setObjectToFollow(superconductorTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (superconductorTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = superconductorTriggerButton

                    }
                });

            };
        },
        tick(this: ICircuitControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },

    },
};
export {CircuitControlComponent}
