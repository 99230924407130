import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from "../../../lib/aframe/components/world-button";

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}
interface IAtomsSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    daltonHandler: () => void;
    plumPuddingHandler: () => void;
    rutherfordHandler: () => void;
    bohrsHandler: () => void;
    modernAtomHandler: () => void;
    rutherfordMixer: THREE.AnimationMixer;
    rutherfordAnimation: THREE.AnimationAction;
    bohrsMixer: THREE.AnimationMixer;
    bohrsAnimation: THREE.AnimationAction;
    modernAtomMixer: THREE.AnimationMixer;
    modernAtomAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    prevEl: AFrame.Entity;
    currentAssetId: number;
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: IAtomsSceneAframe) {
            const atomsHolder = document.getElementById('atomsHolder') as AFrame.Entity;
            atomsHolder.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                const torusGeometry = new THREE.TorusGeometry( 10, 0.2, 64, 100, 5.1);
                const material = new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.1, side: THREE.DoubleSide });
                const mesh = new THREE.Mesh( torusGeometry, material );
                mesh.rotation.x = 11
                mesh.rotation.z = 2.8
                this.el.object3D.add( mesh );
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });
            const rutherford = document.getElementById('rutherford') as AFrame.Entity;
            rutherford.addEventListener('model-loaded', () => {
                const rutherfordAnimatedEl = rutherford.object3D.getObjectByName('Scene') as any;
                this.rutherfordMixer = new THREE.AnimationMixer(rutherfordAnimatedEl)
                const [rutherfordClip] = rutherfordAnimatedEl.animations
                this.rutherfordAnimation = this.rutherfordMixer.clipAction(rutherfordClip)
                this.rutherfordAnimation.play()
            });
            const bohrs = document.getElementById('bohrs') as AFrame.Entity;
            bohrs.addEventListener('model-loaded', () => {
                const bohrsAnimatedEl = bohrs.object3D.getObjectByName('Scene') as any;
                this.bohrsMixer = new THREE.AnimationMixer(bohrsAnimatedEl)
                const [bohrsClip] = bohrsAnimatedEl.animations
                this.bohrsAnimation = this.bohrsMixer.clipAction(bohrsClip)
                this.bohrsAnimation.play()
            });
            const modernAtom = document.getElementById('modernAtom') as AFrame.Entity;
            modernAtom.addEventListener('model-loaded', () => {
                const modernAtomAnimatedEl = modernAtom.object3D.getObjectByName('LithiumAtom') as any;
                this.modernAtomMixer = new THREE.AnimationMixer(modernAtomAnimatedEl)
                const [modernAtomClip] = modernAtomAnimatedEl.animations
                this.modernAtomAnimation = this.modernAtomMixer.clipAction(modernAtomClip)
                this.modernAtomAnimation.play()
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                // position this el in fron of camera with the following offset "3 4.5 -5"
                // Get the camera and the desired offset.
                // Get the A-Frame camera component.
                const cameraEl = this.el.sceneEl?.querySelector('a-camera');

                // Set the position of the element to the camera's position.

                if (cameraEl) {
                    console.log('Camera found: ', cameraEl.getAttribute('rotation'));

                    console.log('Rotation before: ', this.el.getAttribute('rotation'));


                    // Get the camera's Y rotation.
                    const cameraRotation = cameraEl.getAttribute('rotation') as unknown as THREE.Euler;
                    const cameraRotationY = cameraRotation ? cameraRotation.y : 0;

                    // Get the current rotation of the element.
                    const elementRotation = this.el.getAttribute('rotation');

                    // Set the element's Y rotation to match the camera's Y rotation.
                    this.el.setAttribute('rotation', {
                        x: elementRotation.x,
                        y: cameraRotationY,
                        z: elementRotation.z
                    });
                    console.log('Rotation after: ', this.el.getAttribute('rotation'))

                    const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                    // Create an offset vector.
                    const offset = new THREE.Vector3(0, -8, -8);
                    offset.applyQuaternion(this.el.object3D.quaternion);

                    // Add the offset to the camera's position.
                    const newPosition = camPos.clone().add(offset);

                    // Set the position of the element with the offset.
                    this.el.setAttribute('position', newPosition);
                }
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    this.rutherfordAnimation.paused = true
                    this.bohrsAnimation.paused = true
                    this.modernAtomAnimation.paused = true
                } else {
                    this.rutherfordAnimation.paused = false
                    this.bohrsAnimation.paused = false
                    this.modernAtomAnimation.paused = false
                }
            });

            this.daltonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Dalton’s Model';
                    const body = 'The first atomic model imagined atoms as tiny spheres that could not be divided. This idea of indivisible atoms can be traced all the way back to Democritus, a philosopher from ancient Greece. '
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.plumPuddingHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'The Plum Pudding Model';
                    const body = 'After the electron was discovered by J. J. Thompson, a new model was created where the tiny electrons are embedded into a large positively charged sphere like plums in a plum pudding.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.rutherfordHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Rutherford’s Model';
                    const body = 'Rutherford’s alpha scattering experiments showed that atoms must be largely empty space where most of the mass is centralised into a positively charged <b>nucleus</b>. The electrons orbit around the nucleus.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.bohrsHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Bohr`s Model';
                    const body = 'Niels Bohr took the model further by suggesting electrons can only orbit at specific distances around the atom. The calculations from this addition agree with experiential evidence.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.modernAtomHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'The Modern Atom';
                    const body = 'Finally, experiments found the nucleus was subdivided into positively charged protons. The nucleus also contains neutral particles called neutrons.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const daltonTriggerBtn = poolButtons.requestEntity()
                daltonTriggerBtn?.setAttribute('position', '0 0 -6')
                daltonTriggerBtn?.setAttribute('scale', '1 1 1')
                daltonTriggerBtn?.play()
                daltonTriggerBtn?.addEventListener('click', () => {
                    this.daltonHandler()
                    if (daltonTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(daltonTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (daltonTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = daltonTriggerBtn
                    }
                });
                const plumPuddingTriggerBtn = poolButtons.requestEntity()
                plumPuddingTriggerBtn?.setAttribute('position', '6 0 -2')
                plumPuddingTriggerBtn?.setAttribute('scale', '1 1 1')
                plumPuddingTriggerBtn?.play()
                plumPuddingTriggerBtn?.addEventListener('click', () => {
                    this.plumPuddingHandler()
                    if (plumPuddingTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(plumPuddingTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (plumPuddingTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = plumPuddingTriggerBtn
                    }
                });
                const rutherfordTriggerBtn = poolButtons.requestEntity()
                rutherfordTriggerBtn?.setAttribute('position', '5 0 3')
                rutherfordTriggerBtn?.setAttribute('scale', '1 1 1')
                rutherfordTriggerBtn?.play()
                rutherfordTriggerBtn?.addEventListener('click', () => {
                    this.rutherfordHandler()
                    if (rutherfordTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(rutherfordTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (rutherfordTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = rutherfordTriggerBtn
                    }
                });
                const bohrsTriggerBtn = poolButtons.requestEntity()
                bohrsTriggerBtn?.setAttribute('position', '-5 0 3')
                bohrsTriggerBtn?.setAttribute('scale', '1 1 1')
                bohrsTriggerBtn?.play()
                bohrsTriggerBtn?.addEventListener('click', () => {
                    this.bohrsHandler()
                    if (bohrsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(bohrsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (bohrsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = bohrsTriggerBtn
                    }
                });
                const modernAtomTriggerBtn = poolButtons.requestEntity()
                modernAtomTriggerBtn?.setAttribute('position', '-6 0 -2')
                modernAtomTriggerBtn?.setAttribute('scale', '1 1 1')
                modernAtomTriggerBtn?.play()
                modernAtomTriggerBtn?.addEventListener('click', () => {
                    this.modernAtomHandler()
                    if (modernAtomTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(modernAtomTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (modernAtomTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = modernAtomTriggerBtn
                    }
                    console.log(this.currentDeactivatedButton)
                });
            };
        },
        tick(this: IAtomsSceneAframe, time: number, deltaTime: number) {
            if (this.rutherfordMixer) {
                this.rutherfordMixer.update(deltaTime * 0.001);
            }
            if (this.bohrsMixer) {
                this.bohrsMixer.update(deltaTime * 0.001);
            }
            if (this.modernAtomMixer) {
                this.modernAtomMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {AtomsScene as AtomsSceneComponent}
