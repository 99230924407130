
import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IMethodSystemAframe } from "lib/aframe/systems/method-system";
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IShaderFireAframe } from './shader-fire';
import './styles/instruction.css';

interface IMicrobiology {
  assetListActive: boolean;
  scene1ButtonsClicked: boolean[];
  activateAssetList: (...args: any[]) => void;
  step42ButtonEndPos: string;
  step42ButtonOriginalPos: string;
  step41ButtonEndPos: string;
  step41ButtonOriginalPos: string;
  step3AnimationsPlayed: boolean[];
  step4ActionPlayed: boolean;
  step4Action: THREE.AnimationAction;
  step4Mixer: THREE.AnimationMixer;
  step42TriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
  step41TriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
  step3IncubatorTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
  step3AnimationCounter: number;
  step3AgarTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
  step3Action2: THREE.AnimationAction;
  step3Action1: THREE.AnimationAction;
  step3Mixer: THREE.AnimationMixer;
  step4: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
  step3: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
  step2AnimationCounter: number;
  step2AnimationsPlayed: boolean[];
  step2DAction: THREE.AnimationAction;
  step2CAction: THREE.AnimationAction;
  step2BAction: THREE.AnimationAction;
  step2Mixer: THREE.AnimationMixer;
  currentAsset: number;
  step2DTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
  step2CTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
  step2BTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;   
  step2: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
  step1TriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
  step1Action: THREE.AnimationAction;
  isStepActivated: boolean[];  
  scene1Buttons: AFrame.Entity[];
  scene2Buttons: AFrame.Entity[];
  step: number;
  instruction: HTMLElement | null;
  actionIncubatorClose: THREE.AnimationAction;
  actionBurnerOn: THREE.AnimationAction;
  actionIncubatorOpen: THREE.AnimationAction;
  actionStep1: THREE.AnimationAction;
  onLessonStart: (...args: any[]) => void;
  currentDeactivatedButton: any;
  mixer: THREE.AnimationMixer;
  step1Mixer: THREE.AnimationMixer;
  poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
  annotationComponent: IAnnotationAframe;
  onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
  step1: AFrame.Entity;
  scene2: AFrame.Entity;
  scene1: AFrame.Entity;
  el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

const MicrobiologyComponentAframe = {
    name: 'microbiology',
    val: {
        init(this: IMicrobiology) {
            // Add 'model-loaded' event listener to the component
            this.scene1ButtonsClicked = [false, false, false, false, false];
            this.assetListActive = false;
            this.currentAsset = 0;
            this.step = 0;
            this.isStepActivated = [false,false,false, false];
            this.step2AnimationsPlayed = [false, false, false]; // allow animations to be played only once per step
            let counter = 0;
            const totalNumberOfModels = 5
            this.scene1Buttons = [];
            this.scene2Buttons = [];
            this.el.addEventListener('model-loaded', () => {
                counter++;
                if (counter < totalNumberOfModels) return;
               

                this.scene1 = document.getElementById('scene1') as AFrame.Entity;
                this.scene2 = document.getElementById('scene2') as AFrame.Entity;
                this.step1 = document.getElementById('step1') as AFrame.Entity;
                this.step2 = document.getElementById('step2') as AFrame.Entity;
                this.step3 = document.getElementById('step3') as AFrame.Entity;
                this.step4 = document.getElementById('step4') as AFrame.Entity;

                this.scene2.setAttribute('visible', 'false');

                initialiseAnimations();

                // setup callbacks
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "method-system": IMethodSystemAframe };
                };
                const methodSystem = scene.systems["method-system"];
                const callback = methodSystem.getCallback('onLessonStart');
                if (callback) {
                    this.onLessonStart = callback;
                }

                const callback2 = methodSystem.getCallback('onObjectSelected');
                if (callback2) {
                    this.onObjectSelected = callback2;
                }

                const callback3 = methodSystem.getCallback('onSetAssetListActive');
                if (callback3) {
                    this.activateAssetList = callback3;
                }

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}

                //create UI on top for steps
                this.instruction = document.getElementById('instruction-micro');
				if (this.instruction) {
					this.instruction.classList.add('instruction-slide-up')
                    this.instruction.children[0].classList.remove('inactive');
				}


            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.onLessonStart();
                // make fire visible
                const fireEl = document.getElementById("holder") as AFrame.Entity;
                const fireComp = fireEl.components['shader-fire'] as unknown as IShaderFireAframe;
                fireComp.setVisibility(true);

            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: holder; scale: 25 25 25; relativeRotation: 0 0 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }

                    // stop fire
                    const fireEl = document.getElementById("holder") as AFrame.Entity;
                    const fireComp = fireEl.components['shader-fire'] as unknown as IShaderFireAframe;
                    fireComp.setVisibility(false);
                }
                
            });

            this.el.sceneEl?.addEventListener('asset-change', (event) => {
            
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const newAssetId = customEvent.detail.assetId;
                changeAsset(newAssetId);
                
            });

            const closeAnnotation = () => {
				const scene = document.querySelector('a-scene');
				if (scene) {
					scene.emit('annotation-close');
					// close quiz
				}
			}

            // checks if all scene1 buttons were clicked
            const scene1ButtonCounter = (index: number) => {
                this.scene1ButtonsClicked[index] = true;
                for (let i = 0; i < this.scene1ButtonsClicked.length; i++) {
                    if (!this.scene1ButtonsClicked[i]) {
                        return false;
                    }
                }
                if (!this.assetListActive) {
                    this.activateAssetList();
                    this.assetListActive = true;
                }
            };

			this.el.sceneEl?.addEventListener('quiz-opened', () => {
				this.instruction?.classList.add('instruction-slide-up');
			})
			this.el.sceneEl?.addEventListener('quiz-closed', () => {
				if (this.currentAsset === 1) this.instruction?.classList.remove('instruction-slide-up');
				
			})
			
			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

            const initialiseAnimations = () => {
                const scene1 = document.getElementById('scene1') as AFrame.Entity;
               
                const animatedEl = scene1.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [clipIncubator, clipBurner, clipIncubatorClose] = animatedEl.animations
                this.actionIncubatorOpen = this.mixer.clipAction(clipIncubator) // not used
                this.actionBurnerOn = this.mixer.clipAction(clipBurner)
                this.actionIncubatorClose = this.mixer.clipAction(clipIncubatorClose)

                const step1 = document.getElementById('step1') as AFrame.Entity;
                const step1Anim = step1.object3D.getObjectByName('Scene') as any;
                this.step1Mixer = new THREE.AnimationMixer(step1Anim)
                const [step1Clip] = step1Anim.animations
                this.step1Action = this.step1Mixer.clipAction(step1Clip)
                this.step1Mixer.addEventListener('finished', () => {
                    if (!this.isStepActivated[1]) {
                        // set step 2 button blinking
                        if (this.instruction) {
                            this.instruction.children[1].classList.remove('inactive');
                            this.instruction.children[2].classList.remove('inactive');
                            this.instruction.children[2].classList.add('opacity-animate');
                            this.instruction.children[2].addEventListener('click', () => {
                                if (this.instruction) {
                                    this.instruction.children[2].classList.remove('opacity-animate');
                                }
                                this.step = 1;
                                setCurrentStepActive();
                                
                            });
                        }
                        // now this step is activated next time it will only be responding to click handler
                        this.isStepActivated[1] = true;
                    }
                });
                // step 2 animations 
                const step2 = document.getElementById('step2') as AFrame.Entity;
                const step2Anim = step2.object3D.getObjectByName('Scene') as any;
                this.step2Mixer = new THREE.AnimationMixer(step2Anim)
                const [step2BClip, step2DClip, step2CClip] = step2Anim.animations // Luke has animatons in different order rather than script
                this.step2BAction = this.step2Mixer.clipAction(step2BClip)
                this.step2CAction = this.step2Mixer.clipAction(step2CClip)
                this.step2DAction = this.step2Mixer.clipAction(step2DClip)
                this.step2AnimationCounter = 0
                this.step2Mixer.addEventListener('finished', () => {
                    this.step2AnimationCounter++;
                    if (this.step2AnimationCounter === 3) {
                        if (!this.isStepActivated[2]) {
                            // set step 2 button blinking
                            if (this.instruction) {
                                this.instruction.children[3].classList.remove('inactive');
                                this.instruction.children[4].classList.remove('inactive');
                                this.instruction.children[4].classList.add('opacity-animate');
                                this.instruction.children[4].addEventListener('click', () => {
                                    if (this.instruction) {
                                        this.instruction.children[4].classList.remove('opacity-animate');
                                    }
                                    this.step = 2;
                                    setCurrentStepActive();
                                });
                            }
                            // now this step is activated next time it will only be responding to click handler
                            this.isStepActivated[2] = true;
                        }
                    } else if (this.step2AnimationCounter === 2) {
                        // 2 animation finished 1 left
                        // activate extra button
                        if (this.step2CTriggerBtn) {
                            const button = this.step2CTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                            button.setEnabled();
                        }
                    } else if (this.step2AnimationCounter === 1) {
                        // 1 animation finished 2 left
                        // activate extra button
                        if (this.step2DTriggerBtn) {
                            const button = this.step2DTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                            button.setEnabled();
                        }
                    }
                });

                // step 3 animations
                const step3 = document.getElementById('step3') as AFrame.Entity;
                const step3Anim = step3.object3D.getObjectByName('Scene') as any;
                this.step3Mixer = new THREE.AnimationMixer(step3Anim)
                const [step3Clip2, step3Clip1] = step3Anim.animations
                this.step3Action1 = this.step3Mixer.clipAction(step3Clip1)
                this.step3Action2 = this.step3Mixer.clipAction(step3Clip2)
                this.step3AnimationCounter = 0
                this.step3Mixer.addEventListener('finished', () => {
                    this.step3AnimationCounter++;
                    if (this.step3AnimationCounter > 1) {
                        // both animations have played
                        if (!this.isStepActivated[3]) {
                            // set step 3 button blinking
                            if (this.instruction) {
                                this.instruction.children[5].classList.remove('inactive');
                                this.instruction.children[6].classList.remove('inactive');
                                this.instruction.children[6].classList.add('opacity-animate');
                                this.instruction.children[6].addEventListener('click', () => {
                                    if (this.instruction) {
                                        this.instruction.children[6].classList.remove('opacity-animate');
                                    }
                                    this.step = 3;
                                    setCurrentStepActive();
                                });
                            }
                            // now this step is activated next time it will only be responding to click handler
                            this.isStepActivated[3] = true;
                        }
                    } else if (this.step3AnimationCounter <= 1) {
                        // activating incubator button
                        if (this.step3IncubatorTriggerBtn) {
                            const button = this.step3IncubatorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                            button.setEnabled();
                        }

                    }
                });

                // step 4 animations
                const step4 = document.getElementById('step4') as AFrame.Entity;
                const step4Anim = step4.object3D.getObjectByName('Scene') as any;
                this.step4Mixer = new THREE.AnimationMixer(step4Anim)
                const [step4Clip] = step4Anim.animations
                this.step4Action = this.step4Mixer.clipAction(step4Clip)



            }

            const changeAsset = (assetId: number) => {

                this.currentAsset = assetId;
                switch (assetId) {
                    case 1:
                        // setting scene2 
                        // disabling scene 1
                        this.scene1.setAttribute('visible', 'false');

                        // activating panel
                        if (this.instruction) {
                            this.instruction.classList.remove('instruction-slide-up')
                        }

                        //disable scene 1 buttons
                        for (let i = 0; i < this.scene1Buttons.length; i++) {
                            const button = this.scene1Buttons[i].components['world-button'] as unknown as WorldButtonAframeInstance;
                            button.setDisabled();
                        };
                        setCurrentStepActive();
                        break;
                    default:
                        closeAnnotation();
                        // setting normal scene
                        this.scene2.setAttribute('visible', 'false');
                        // disable scene 2 buttons
                        deactivateScene2Buttons();
                        //remoove step panel
                        if (this.instruction) {
                            this.instruction.classList.add('instruction-slide-up')
                        }


                        this.scene1.setAttribute('visible', 'true');

                        // enabling buttons
                        // enable scene 1 buttons
                        for (let i = 0; i < this.scene1Buttons.length; i++) {
                            const button = this.scene1Buttons[i].components['world-button'] as unknown as WorldButtonAframeInstance;
                            button.setEnabled();

                        };
                        // position fire
                        const fireEl = document.getElementById("holder") as AFrame.Entity;
                        const fireComp = fireEl.components['shader-fire'] as unknown as IShaderFireAframe;
                        fireComp.setPosition(0.15, 0.112, 0);
                        fireComp.setVisibility(true);
                        fireComp.setFireActive(true);
                        break;
                }
            };

            const setCurrentStepActive = () => {
                closeAnnotation();
                const fireEl = document.getElementById("holder") as AFrame.Entity;
                const fireComp = fireEl.components['shader-fire'] as unknown as IShaderFireAframe;

                switch (this.step) {
                    case 0:
                        this.step1.setAttribute('visible', 'true');
                        this.step2.setAttribute('visible', 'false');
                        this.step3.setAttribute('visible', 'false');
                        this.step4.setAttribute('visible', 'false');
                        deactivateScene2Buttons();
                        if (this.step1TriggerBtn) {
                            const button = this.step1TriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                            button.setEnabled();
                        }
                        
                        //start fire
                        fireComp.setPosition(0.15, 0.112, 0);
                        fireComp.setVisibility(true);
                        fireComp.setFireActive(true);

                        // if (!this.isStepComplete[0]) {
// 
                        // }
                        break;
                    case 1:
                        this.step1.setAttribute('visible', 'false');
                        this.step2.setAttribute('visible', 'true');
                        this.step3.setAttribute('visible', 'false');
                        this.step4.setAttribute('visible', 'false');
                        // setup buttons
                        deactivateScene2Buttons();
                        if (this.step2BTriggerBtn) {
                            const button = this.step2BTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                            button.setEnabled();
                        }
                        
                        //move fire
                        fireComp.setPosition(-0.20, 0.112, 0);
                        fireComp.setVisibility(true);
                        fireComp.setFireActive(true);

                        // reset animation
                        this.step2BAction.stop();
                        this.step2CAction.stop();
                        this.step2DAction.stop();

                        // set up some variables
                        this.step2AnimationCounter = 0;
                        this.step2AnimationsPlayed = [false, false, false];

                        // if first scene is not enabled yet, enable button
                        if (!this.isStepActivated[0] && this.instruction) {
                            this.instruction.children[0].addEventListener('click', () => {
                                this.step = 0;
                                setCurrentStepActive();
                            });
                        }
                        break;
                    case 2:
                        this.step1.setAttribute('visible', 'false');
                        this.step2.setAttribute('visible', 'false');
                        this.step3.setAttribute('visible', 'true');
                        this.step4.setAttribute('visible', 'false');

                        // setup buttons
                        deactivateScene2Buttons();
                        if (this.step3AgarTriggerBtn) {
                            const button = this.step3AgarTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                            button.setEnabled();
                        }

                        //fire off
                        fireComp.setFireActive(false);
                        fireComp.setVisibility(false);

                        // reset animation
                        this.step3Action1.stop();
                        this.step3Action2.stop();

                        // setup some variables
                        this.step3AnimationCounter = 0;
                        this.step3AnimationsPlayed = [false, false];
                        break;
                    case 3:
                        this.step1.setAttribute('visible', 'false');
                        this.step2.setAttribute('visible', 'false');
                        this.step3.setAttribute('visible', 'false');
                        this.step4.setAttribute('visible', 'true');

                        // setup buttons
                        deactivateScene2Buttons();
                        if (this.step41TriggerBtn) {
                            const button = this.step41TriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                            button.setEnabled();
                        }

                        //fire off
                        fireComp.setFireActive(false);
                        fireComp.setVisibility(false);

                        // rest animation
                        this.step4Action.stop();

                        this.step4ActionPlayed = false;

                        // put buttons to normal positions
                        this.step41TriggerBtn?.setAttribute('position', this.step41ButtonOriginalPos);
                        this.step42TriggerBtn?.setAttribute('position', this.step42ButtonOriginalPos);
                        break;
                    default:
                        break;
                }

                this.scene2.setAttribute('visible', 'true');
            };

            const deactivateScene2Buttons = () => {
                for (let i = 0; i < this.scene2Buttons.length; i++) {
                    const button = this.scene2Buttons[i].components['world-button'] as unknown as WorldButtonAframeInstance;
                    button.setDisabled();
                };
            };

    
            let incubatorAnimPlaying = false
            const incubatorButtonHandler = () => {
                const title = 'Incubator'
                const body = 'In an incubator the bacterial culture will be stored safely at 25 ℃ for 48 - 72 hours to observe bacterial growth. The temperature is kept at 25 degrees to reduce the chances of harmful bacteria from growing.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }

                if (!incubatorAnimPlaying) {
                    incubatorAnimPlaying = true

                    this.actionIncubatorClose.stop();
                    this.actionIncubatorOpen.reset();
                    this.actionIncubatorOpen.timeScale = 0.5;
                    this.actionIncubatorOpen.clampWhenFinished = true
                    this.actionIncubatorOpen.repetitions = 1
                    this.actionIncubatorOpen.play();
                    setTimeout(() => {
                        incubatorAnimPlaying = false
                        this.actionIncubatorOpen.stop();
                        this.actionIncubatorClose.timeScale = 0.5;
                        this.actionIncubatorClose.repetitions = 1
                        this.actionIncubatorClose.clampWhenFinished = true;
                        this.actionIncubatorClose.play();
                    }, 2000);
                }

                scene1ButtonCounter(0);
            }
            
            let isBurnerActive = false;
            const burnerButtonHandler = () => {
                // enable sprayButton if not enabled
                const title = 'Bunsen Burner'
                const body = 'A flame is used to maintain the sterility of the air and surfaces around you. Remain close to the flame at all times during the experiment to avoid contamination.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
                
                if (!isBurnerActive) {
                    isBurnerActive = true;
                    this.actionBurnerOn.reset();
                    this.actionBurnerOn.repetitions = 1;
                    this.actionBurnerOn.timeScale = 1;
                    this.actionBurnerOn.clampWhenFinished = true;
                    this.actionBurnerOn.play();

                    //start fire
                    const fireEl = document.getElementById("holder") as AFrame.Entity;
                    const fireComp = fireEl.components['shader-fire'] as unknown as IShaderFireAframe;
                    fireComp.setPosition(0.15, 0.112, 0);
                    fireComp.setFireActive(true);
                }

                scene1ButtonCounter(1);
            }

            const inocLoopButtonHandler = () => {
                const title = 'Inoculating Loop'
                const body = 'This is an inoculating loop. It is used to transfer the bacteria from the nutrient broth to the agar gel plate. Before using it, remember to sterilise it by running it through the bunsen burner flame.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
                scene1ButtonCounter(2);
            }

            const flaskButtonHandler = () => {
                const title = '<span style=\"font-size: 19px;\">Bacterial Culture in Nutrient Broth</span>'
                const body = 'This pre-sterilised nutrient broth contains necessary nutrients for bacteria to thrive and multiply. The foam on the surface of the broth is an indicator of the vigorous growth of your bacteria culture. This is the source of bacteria in your experiment.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
                scene1ButtonCounter(3);
            }

            const agarGelButtonHandler = () => {
                const title = 'Agar Gel Plate'
                const body = 'Agar gel plate contains nutrient broth in gel form to provide bacteria with the nutrients needed to divide and grow. It helps bacterial colonies become visible. Keep it closed and only open next to a flame to avoid the risk of contamination.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }
                scene1ButtonCounter(4);
            }

            const step1ButtonHandler = () => {
                const title = 'Step 1'
                const body = 'After sterilising the inoculating loop in the flame, dip it in the bacterial culture and drag it over the agar plate. Try to spread the bacteria evenly over the surface.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }
                
                this.step1Action.reset();
                this.step1Action.repetitions = 1;
                this.step1Action.timeScale = 1;
                this.step1Action.clampWhenFinished = true;
                this.step1Action.play();
            }

            const step2BButtonHandler = () => {
                const title = 'Antibiotic'
                const body = 'Antibiotics are medicines that kill bacteria inside the body. They’re used with bacterial and fungal infections. Using sterilised forceps, place sterile filter paper discs into the antibiotic and then place them onto the plate.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }

                if (!this.step2AnimationsPlayed[0]) {
                    this.step2AnimationsPlayed[0] = true;
                    this.step2BAction.reset();
                    this.step2BAction.repetitions = 1;
                    this.step2BAction.timeScale = 1;
                    this.step2BAction.clampWhenFinished = true;
                    this.step2BAction.play();
                }
            }

            const step2CButtonHandler = () => {
                const title = 'Antiseptic'
                const body = 'Antiseptics are chemicals that reduce the number of microorganisms on the skin. Using sterilised forceps, place sterile filter paper discs into the antiseptic and then place them onto the plate.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }
                
                if (!this.step2AnimationsPlayed[1]) {
                    this.step2AnimationsPlayed[1] = true;
                    this.step2CAction.reset();
                    this.step2CAction.repetitions = 1;
                    this.step2CAction.timeScale = 1;
                    this.step2CAction.clampWhenFinished = true;
                    this.step2CAction.play();
                }
            }

            const step2DButtonHandler = () => {
                const title = 'Disinfectant '
                const body = 'Disinfectants are chemicals that reduce the number of microorganisms on non living surfaces. Using sterilised forceps, place sterile filter paper discs into the disinfectant and then place them onto the plate.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }

                if (!this.step2AnimationsPlayed[2]) {
                    this.step2AnimationsPlayed[2] = true;
                    this.step2DAction.reset();
                    this.step2DAction.repetitions = 1;
                    this.step2DAction.timeScale = 1;
                    this.step2DAction.clampWhenFinished = true;
                    this.step2DAction.play();
                }
            }

            const step3AgarButtonHandler = () => {
                const title = 'Step 3'
                const body = 'Using a marker, the agar plate should be divided into 3 sections, one per substance being tested, and labelled clearly to avoid mixed results.</br>Secure the plate with adhesive tape. This is to ensure that the plate stays shut and prevents any contamination.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }

                // only playing once as a first animation
                if (this.step3AnimationCounter < 1 && !this.step3AnimationsPlayed[0]) {
                    this.step3Action2.stop();
                    this.step3Action1.reset();
                    this.step3Action1.repetitions = 1;
                    this.step3Action1.timeScale = 1;
                    this.step3Action1.clampWhenFinished = true;
                    this.step3Action1.play();

                    this.step3AnimationsPlayed[0] = true;
                }
            }

            const step3IncubatorButtonHandler = () => {
                const title = 'Step 4'
                const body = 'Place the agar plate <strong>upside down</strong> in the 25 ℃ incubator to prevent water droplets from forming and falling onto the agar gel which could compromise the integrity of the sample. Shut the door securely and allow bacteria to grow for 48-72 hours.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }

                // only playing once as a first animation
                if (this.step3AnimationCounter < 2 && !this.step3AnimationsPlayed[1]) {
                    this.step3Action1.stop();
                    this.step3Action2.reset();
                    this.step3Action2.repetitions = 1;
                    this.step3Action2.timeScale = 0.75;
                    this.step3Action2.clampWhenFinished = true;
                    this.step3Action2.play();

                    // also remove the button since item is not there
                    if (this.step3AgarTriggerBtn) {
                        const button = this.step3AgarTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                        button.setDisabled();
                    }

                    this.step3AnimationsPlayed[1] = true;
                }
            }

            const step41ButtonHandler = () => {
                const title = 'Growing Bacteria'
                const body = 'After 48 hours of incubation, the bacteria on the agar plate multiplied through binary fission and covered the plate. The clear areas do not contain bacteria because their growth is inhibited by the chemicals on the filter papers.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }

                if(this.step42TriggerBtn) {
                    const button = this.step42TriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                    button.setEnabled();
                }
                
            };

            const step42ButtonHandler = () => {
                const title = 'Inhibition Zone'
                const body = 'This is the area on the plate where bacteria have been cleared. The chemical with the largest cleared area is most effective at inhibiting bacterial growth.</br>The antibiotic inhibition zone area:<span style=\"font-size: 12px; line-height: 0.2;\"></br>Diameter = 25 mm<span style="margin-left: 48px;">Radius = d/2 = 12.5 mm</span></br>Area = πr² = 490.8 mm<sup>2</sup></span>'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })   
                } else {
                    console.log('No object selected method')
                }

                if (!this.step4ActionPlayed) {
                    this.step4Action.reset();
                    this.step4Action.repetitions = 1;
                    this.step4Action.timeScale = 1;
                    this.step4Action.clampWhenFinished = true;
                    this.step4Action.play();

                    this.step4ActionPlayed = true;

                    // set button positions
                    if (this.step41TriggerBtn) {
                        this.step41TriggerBtn.setAttribute('animation', {
                            property: 'position',
                            to: this.step41ButtonEndPos,
                            dur: 1250, // duration in ms
                            easing: 'linear',
                            delay: 750
                        });
                    }

                    if (this.step42TriggerBtn) {
                        this.step42TriggerBtn.setAttribute('animation', {
                            property: 'position',
                            to: this.step42ButtonEndPos,
                            dur: 1250, // duration in ms
                            easing: 'linear',
                            delay: 750
                        });
                    }
                }
                
                
            };

            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const agarGelTriggerBtn = poolButtons.requestEntity();
                agarGelTriggerBtn?.setAttribute('position', '0 0.05 0');
                agarGelTriggerBtn?.play();
                agarGelTriggerBtn?.addEventListener('click', () => {
                    agarGelButtonHandler();
                    if (agarGelTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(agarGelTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(agarGelTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = agarGelTriggerBtn
                        
                    }
                });
                if (agarGelTriggerBtn) {
                    this.scene1Buttons.push(agarGelTriggerBtn);
                }
                

                const flaskTriggerBtn = poolButtons.requestEntity()
                flaskTriggerBtn?.setAttribute('position', '0.225 0.075 0.05')
                flaskTriggerBtn?.play()
                flaskTriggerBtn?.addEventListener('click', () => {
                    flaskButtonHandler();
                    if (flaskTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(flaskTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(flaskTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = flaskTriggerBtn
                    }
                });

                if (flaskTriggerBtn) {
                    this.scene1Buttons.push(flaskTriggerBtn);
                }

                const inocLoopTriggerBtn = poolButtons.requestEntity()
                inocLoopTriggerBtn?.setAttribute('position', '0.08 0.02 0.1')
                inocLoopTriggerBtn?.play()
                inocLoopTriggerBtn?.addEventListener('click', () => {
                    inocLoopButtonHandler();
                    if (inocLoopTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(inocLoopTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(inocLoopTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = inocLoopTriggerBtn
                    }
                });
                if (inocLoopTriggerBtn) {
                    this.scene1Buttons.push(inocLoopTriggerBtn);
                }



                const burnerTriggerBtn = poolButtons.requestEntity()
                burnerTriggerBtn?.setAttribute('position', '0.125 0.05 0.05')
                burnerTriggerBtn?.play()
                burnerTriggerBtn?.addEventListener('click', () => {
                    burnerButtonHandler();
                    if (burnerTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(burnerTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(burnerTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = burnerTriggerBtn
                    }
                });
                if (burnerTriggerBtn) {
                    this.scene1Buttons.push(burnerTriggerBtn);
                }

                const incubatorTriggerBtn = poolButtons.requestEntity()
                incubatorTriggerBtn?.setAttribute('position', '-0.11 0.2 0.18')
                incubatorTriggerBtn?.play()
                incubatorTriggerBtn?.addEventListener('click', () => {
                    incubatorButtonHandler();
                    if (incubatorTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(incubatorTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(incubatorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = incubatorTriggerBtn
                    }
                });
                if (incubatorTriggerBtn) {
                    this.scene1Buttons.push(incubatorTriggerBtn);
                }

                // scene 2 buttons
                this.step1TriggerBtn = poolButtons.requestEntity()
                this.step1TriggerBtn?.setAttribute('position', '0.275 0.075 0.05')
                this.step1TriggerBtn?.play()
                this.step1TriggerBtn?.addEventListener('click', () => {
                    step1ButtonHandler();
                    if (this.step1TriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.step1TriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.step1TriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.step1TriggerBtn
                    }
                });
                if (this.step1TriggerBtn) {
                    this.scene2Buttons.push(this.step1TriggerBtn);
                }


                this.step2BTriggerBtn = poolButtons.requestEntity()
                this.step2BTriggerBtn?.setAttribute('position', '0.15 0.075 0.05')
                this.step2BTriggerBtn?.play()
                this.step2BTriggerBtn?.addEventListener('click', () => {
                    step2BButtonHandler();
                    if (this.step2BTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.step2BTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.step2BTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.step2BTriggerBtn
                    }
                });
                if (this.step2BTriggerBtn) {
                    this.scene2Buttons.push(this.step2BTriggerBtn);
                }

                this.step2CTriggerBtn = poolButtons.requestEntity()
                this.step2CTriggerBtn?.setAttribute('position', '0.45 0.075 0.05')
                this.step2CTriggerBtn?.play()
                this.step2CTriggerBtn?.addEventListener('click', () => {
                    step2CButtonHandler();
                    if (this.step2CTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.step2CTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.step2CTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.step2CTriggerBtn
                    }
                });
                if (this.step2CTriggerBtn) {
                    this.scene2Buttons.push(this.step2CTriggerBtn);
                }

                this.step2DTriggerBtn = poolButtons.requestEntity()
                this.step2DTriggerBtn?.setAttribute('position', '0.3 0.075 0.05')
                this.step2DTriggerBtn?.play()
                this.step2DTriggerBtn?.addEventListener('click', () => {
                    step2DButtonHandler();
                    if (this.step2DTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.step2DTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.step2DTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.step2DTriggerBtn
                    }
                });
                if (this.step2DTriggerBtn) {
                    this.scene2Buttons.push(this.step2DTriggerBtn);
                }

                this.step3AgarTriggerBtn = poolButtons.requestEntity()
                this.step3AgarTriggerBtn?.setAttribute('position', '0.175 0.05 0.0')
                this.step3AgarTriggerBtn?.play()
                this.step3AgarTriggerBtn?.addEventListener('click', () => {
                    step3AgarButtonHandler();
                    if (this.step3AgarTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.step3AgarTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.step3AgarTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.step3AgarTriggerBtn
                    }
                });
                if (this.step3AgarTriggerBtn) {
                    this.scene2Buttons.push(this.step3AgarTriggerBtn);
                }

                this.step3IncubatorTriggerBtn = poolButtons.requestEntity()
                this.step3IncubatorTriggerBtn?.setAttribute('position', '0 0.2 0.18')
                this.step3IncubatorTriggerBtn?.play()
                this.step3IncubatorTriggerBtn?.addEventListener('click', () => {
                    step3IncubatorButtonHandler();
                    if (this.step3IncubatorTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.step3IncubatorTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.step3IncubatorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.step3IncubatorTriggerBtn
                    }
                });
                if (this.step3IncubatorTriggerBtn) {
                    this.scene2Buttons.push(this.step3IncubatorTriggerBtn);
                }

                this.step41TriggerBtn = poolButtons.requestEntity()
                this.step41ButtonOriginalPos = '0.05 0.025 0.0425'
                this.step41ButtonEndPos = '0.035 0.2 0.025'
                this.step41TriggerBtn ?.setAttribute('position', this.step41ButtonOriginalPos)
                this.step41TriggerBtn ?.play()
                this.step41TriggerBtn ?.addEventListener('click', () => {
                    step41ButtonHandler();
                    if (this.step41TriggerBtn ) {
                        this.annotationComponent.setObjectToFollow(this.step41TriggerBtn );
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.step41TriggerBtn .components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.step41TriggerBtn
                    }
                });
                
                
                if (this.step41TriggerBtn ) {
                    this.scene2Buttons.push(this.step41TriggerBtn );
                }

                this.step42ButtonOriginalPos = '-0.015 0.025 -0.01'
                this.step42ButtonEndPos = '-0.05 0.256 0.025'

                this.step42TriggerBtn = poolButtons.requestEntity()
                this.step42TriggerBtn ?.setAttribute('position', this.step42ButtonOriginalPos)  
                this.step42TriggerBtn ?.play()
                this.step42TriggerBtn ?.addEventListener('click', () => {
                    step42ButtonHandler();
                    if (this.step42TriggerBtn ) {
                        this.annotationComponent.setObjectToFollow(this.step42TriggerBtn );
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.step42TriggerBtn .components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.step42TriggerBtn
                    }
                });
                if (this.step42TriggerBtn ) {
                    this.scene2Buttons.push(this.step42TriggerBtn );
                }
                


                // disable scene 2 buttons
                deactivateScene2Buttons();
            }
        },
        tick(this: IMicrobiology, time: number, deltaTime: number) {
           if (this.mixer) {
                this.mixer.update(deltaTime / 1000);
           }

           if (this.step1Mixer) {
                this.step1Mixer.update(deltaTime / 1000);
           }

           if (this.step2Mixer) {
                this.step2Mixer.update(deltaTime / 1000);
           }

           if (this.step3Mixer) {
                this.step3Mixer.update(deltaTime / 1000);
           }

           if (this.step4Mixer) {
                this.step4Mixer.update(deltaTime / 1000);
           }
        },
    },
};
export { MicrobiologyComponentAframe as Microbiology }