import { Stack, styled } from '@mui/material';
import { DisplayEnum } from '../../../../shared/enums';

import Background from '../../../../assets/coursesBG.png';

export const StyledStack = styled(Stack, { name: 'StyledStack' })({
  width: '100%',
  maxWidth: '1440px',
  margin: '0 auto',
  backgroundImage: `url('${Background}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0 0',
  backgroundSize: '100% 378px',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
    backgroundSize: '100% 200px',
    maxWidth: '100%',
  },
});
