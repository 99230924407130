import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface IChromatographySceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    sliderValue: string;
    mixer: THREE.AnimationMixer;
    clipAnimation: THREE.AnimationAction;
    newTexture: THREE.Texture;
    paperMesh: THREE.Mesh;
    material: THREE.MeshStandardMaterial;
    tapHandler: () => void;
    tapTriggerBtn: AFrame.Entity;
    duration: number;
    startTime: number;
    texture2: string;
    onObjectSelected: ((selectedObject: { title: string; body: string; imageExtra?: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    updateTexture: boolean;
    canPlay: boolean;
}

const LessonStart = {
    name: 'lesson-scene',
    val: {
        init(this: IChromatographySceneAframe) {
            const blankLayer = require('../../../assets/img/Chromatography/1.png')
            const layer5 = require('../../../assets/img/Chromatography/2.png')
            const layer10 = require('../../../assets/img/Chromatography/3.png')
            const layer15 = require('../../../assets/img/Chromatography/4.png')
            const layer20 = require('../../../assets/img/Chromatography/5.png')
            const tap_hand = require('../../../assets/img/tap_hand.png');

            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                // ony initialise buttons once pool has loaded
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // moved button initialisation to lesson-start event
                // if initialising here, the buttons are not parenting to objects correctly
                // only applies to this lesson
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
                const slider = document.getElementById('sliderAnnotationContainer')
                if (slider) {
                    slider.style.opacity = '0'
                }
                this.paperMesh = this.el.object3D.getObjectByName('Mesh') as THREE.Mesh;
                this.material = this.paperMesh.material as THREE.MeshStandardMaterial
                defaultTexture()
                const model = document.getElementById('model') as AFrame.Entity;

                const animatedEl = model.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [PaperAnimatedAction] = animatedEl.animations
                this.clipAnimation = this.mixer.clipAction(PaperAnimatedAction)
                this.clipAnimation.setLoop(THREE.LoopPingPong, 1)
            });
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                    const model = document.getElementById('model') as AFrame.Entity;
                    const glass = model.object3D.getObjectByName('Beaker_500ml') as THREE.Mesh
                    const water = model.object3D.getObjectByName('BeakerFluid') as THREE.Mesh
                    const glassMaterial = glass.material as THREE.MeshStandardMaterial
                    const waterMaterial = water.material as THREE.MeshStandardMaterial
                    glassMaterial.transparent = true;
                    glassMaterial.color = new THREE.Color(0x4f4f4f);
                    glassMaterial.opacity = 0.3
                    glassMaterial.side = 2;
                    glassMaterial.metalness = 0.2;
                    glassMaterial.needsUpdate = true;
                    waterMaterial.color = new THREE.Color(0x79edfc)
                    waterMaterial.needsUpdate = true;
                }
            })

            this.el.sceneEl?.addEventListener('slider-move', (e) => {
                const ce = e as CustomEvent;
                const sliderValue = Number(ce.detail.value); // Convert value to number
                const textureLoader = new THREE.TextureLoader();
                this.paperMesh = this.el.object3D.getObjectByName('Mesh') as THREE.Mesh;
                this.material = this.paperMesh.material as THREE.MeshStandardMaterial
                if (this.canPlay){
                    switch (sliderValue) {
                        case 0:
                            defaultTexture()
                            break;
                        case 5:
                            this.newTexture = textureLoader.load(layer5, () => {
                                this.material.map = this.newTexture
                                this.material.needsUpdate = true
                            })
                            this.newTexture.flipY = false
                            break;
                        case 10:
                            this.newTexture = textureLoader.load(layer10, () => {
                                this.material.map = this.newTexture
                                this.material.needsUpdate = true
                            })
                            this.newTexture.flipY = false
                            break;
                        case 15:
                            this.newTexture = textureLoader.load(layer15, () => {
                                this.material.map = this.newTexture
                                this.material.needsUpdate = true
                            })
                            this.newTexture.flipY = false
                            break;
                        case 20:
                            this.newTexture = textureLoader.load(layer20, () => {
                                this.material.map = this.newTexture
                                this.material.needsUpdate = true
                            })
                            this.newTexture.flipY = false
                            this.clipAnimation.stop()
                            this.clipAnimation.timeScale = -1
                            this.clipAnimation.clampWhenFinished = false
                            this.clipAnimation.play()
                            setTimeout(() => {
                                const slider = document.getElementById('sliderAnnotationContainer')
                                if (slider) {
                                    slider.style.opacity = '0'
                                    const bubble = document.getElementsByClassName('bubble')
                                    const range = document.getElementById('range')
                                    // @ts-ignore
                                    range.value = 0
                                    bubble[0].innerHTML = '0'
                                    // @ts-ignore
                                    bubble[0].style = 'left: calc(0% + 20px);'
                                    this.tapTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3');
                                    this.canPlay = false
                                }
                            }, this.clipAnimation.getClip().duration * 1000)
                            break;
                    }
                }

            });

            const defaultTexture = () => {
                const textureLoader = new THREE.TextureLoader();

                this.paperMesh = this.el.object3D.getObjectByName('Mesh') as THREE.Mesh;
                this.material = this.paperMesh.material as THREE.MeshStandardMaterial
                this.newTexture = textureLoader.load(blankLayer, () => {
                    this.material.map = this.newTexture
                    this.material.needsUpdate = true
                })
                this.newTexture.flipY = false
            }
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: model; scale: 30 30 30; offset: -1.5 0 -0.5');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                }

            });
            this.tapHandler = () => {
                this.tapTriggerBtn?.setAttribute('scale', '0 0 0');
                defaultTexture()
                this.clipAnimation.stop()
                this.clipAnimation.clampWhenFinished = true
                this.clipAnimation.repetitions = 1
                this.clipAnimation.timeScale = 1
                this.clipAnimation.play()
                setTimeout(() => {
                    const slider = document.getElementById('sliderAnnotationContainer')
                    if (slider) {
                        slider.style.opacity = '1'
                        this.canPlay = true
                    }
                    this.clipAnimation.paused = true
                }, this.clipAnimation.getClip().duration * 1000 - 100)
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                this.tapTriggerBtn = poolButtons.requestEntity() as AFrame.Entity

                this.tapTriggerBtn?.setAttribute('position', '0.131 0.105 0.005');
                this.tapTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3');
                this.tapTriggerBtn?.setAttribute('rotation', '0 0 30');
                if ((this.tapTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance)){
                    (this.tapTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(tap_hand);
                    (this.tapTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance).childPlane.object3D.visible = false
                }
                this.tapTriggerBtn?.addEventListener('click', () => {
                    this.tapHandler()
                });


            };
        },
        tick(this: IChromatographySceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },

    },
};
export {LessonStart as TemperatureChangeSceneComponent}
