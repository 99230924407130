import * as AFrame from 'aframe';
import { Object3D } from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { IInteruptMessage } from 'lib/aframe/components/interupt-message';

interface IStateMachineAframe {
    interuptMessage: IInteruptMessage;
    solutionB: Object3D;
    solutionC: Object3D;
    solutionD: Object3D;
    solutionE: Object3D;
    solutionF: Object3D;
    number1: Object3D;
    number2: Object3D;
    number3: Object3D;
    number4: Object3D;
    number5: Object3D;
    state: number;
    el: AFrame.Entity;
}

export interface IStateMachine extends IStateMachineAframe {
    setState(newState: number): void;
    getState(): number;
    nextState(): void;
    deactivateAlert(): void;
};



const StateMachineComponent = {
    name: 'state-machine',
    val: {
        init(this: IStateMachine) {
            this.el.addEventListener('model-loaded', () => {
                console.log('State machine model loaded')
                this.solutionB = this.el.object3D.getObjectByName('TestTube_SolutionB') as Object3D;
                this.solutionC = this.el.object3D.getObjectByName('TestTube_SolutionC') as Object3D;
                this.solutionD = this.el.object3D.getObjectByName('TestTube_SolutionD') as Object3D;
                this.solutionE = this.el.object3D.getObjectByName('TestTube_SolutionE') as Object3D;
                this.solutionF = this.el.object3D.getObjectByName('TestTube_SolutionF') as Object3D;

                this.number1 = this.el.object3D.getObjectByName('Number_0-1') as Object3D;
                this.number2 = this.el.object3D.getObjectByName('Number_0-2') as Object3D;
                this.number3 = this.el.object3D.getObjectByName('Number_1-1') as Object3D;
                this.number4 = this.el.object3D.getObjectByName('Number_1-2') as Object3D;
                this.number5 = this.el.object3D.getObjectByName('Number_2-2') as Object3D;

                this.interuptMessage = this.el.components['interupt-message'] as unknown as IInteruptMessage;
                // this.interuptMessage.setText("Try adding some more NaOH to see if this alters the solution.");
                this.interuptMessage.setPosition('-0.05 0.075 -0.1');

                this.setState(0);
            });

            
        },
        setState(this: IStateMachineAframe, newState: number) {
            this.state = newState;
            console.log('setting state')
            switch (this.state) {
                
                case 0:
                    // set the tubes to be less visible
                    // solutionB.visible = false;
                    // solutionC.visible = false;
                    this.solutionB.scale.set(0.001, 0.001, 0.001);
                    this.solutionC.scale.set(0.001, 0.001, 0.001);
                    this.solutionD.scale.set(0.001, 0.001, 0.001);
                    this.solutionE.scale.set(0.001, 0.001, 0.001);
                    this.solutionF.scale.set(0.001, 0.001, 0.001);

                    //numbers
                    this.number1.visible = true;
                    this.number2.visible = false;
                    this.number3.visible = false;
                    this.number4.visible = false;
                    this.number5.visible = false;
                    break;
                case 1:
                    //solutionA.visible = false;
                    // this.solutionB.visible = true;
                    new TWEEN.Tween(this.solutionB.scale)
                        .to({ x: 1, y: 1, z: 1 }, 300)
                        .start();
                    //solutionC.visible = false;
                    this.number1.visible = false;
                    this.number2.visible = true;
                    this.number3.visible = false;
                    this.number4.visible = false;
                    this.number5.visible = false;
                    break;
                case 2:
                    // state for adding another NaOH to same tube
                    // no new tube is required
                    if (this.interuptMessage) {
                        this.interuptMessage.activatePermanent();
                    } 
                    
                    break;
                case 3:
                    new TWEEN.Tween(this.solutionC.scale)
                        .to({ x: 1, y: 1, z: 1 }, 300)
                        .start();

                    this.number1.visible = true;
                    this.number2.visible = false;
                    this.number3.visible = false;
                    this.number4.visible = false;
                    this.number5.visible = false;
                    break;
                case 4:
                    new TWEEN.Tween(this.solutionD.scale)
                        .to({ x: 1, y: 1, z: 1 }, 300)
                        .start();
                    
                    this.number1.visible = false;
                    this.number2.visible = true;
                    this.number3.visible = false;
                    this.number4.visible = false;
                    this.number5.visible = false;
                    break;
                case 5:
                    if (this.interuptMessage) {
                        this.interuptMessage.activatePermanent();
                    }
                    break;
                case 6:
                    // activating solution E
                    new TWEEN.Tween(this.solutionE.scale)
                        .to({ x: 1, y: 1, z: 1 }, 300)
                        .start();

                    this.number1.visible = false;
                    this.number2.visible = true;
                    this.number3.visible = false;
                    this.number4.visible = false;
                    this.number5.visible = false;
                    break;
                case 7:
                    if (this.interuptMessage) {
                        this.interuptMessage.activatePermanent();
                    }
                    break;
                case 8:
                    // activating solution F
                    new TWEEN.Tween(this.solutionF.scale)
                        .to({ x: 1, y: 1, z: 1 }, 300)
                        .start();
                    
                    this.number1.visible = true;
                    this.number2.visible = false;
                    this.number3.visible = false;
                    this.number4.visible = false;
                    this.number5.visible = false;
                    break;
                default:

                    break;
            };
        },
        nextState(this: IStateMachine) {
            this.setState(this.state + 1);
        },
        getState(this: IStateMachineAframe) {
            return this.state;
        },
        deactivateAlert(this: IStateMachineAframe) {
            this.interuptMessage.deactivate();
        },
        tick() {
            TWEEN.update();
        },
        
    },
};
export {StateMachineComponent as StateMachine};