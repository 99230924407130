import {Routes, Route} from 'react-router-dom';
import {Physics1Aframe} from './first-insulation/aframe';
import {Physics2Aframe} from './2-circuits/aframe';
import {Physics3Aframe} from './3-aqueduct/aframe';
import {PhysicsParallelCircuitsAframe} from './4-parallel-circuits/aframe';
import {PhysicsResistanceAframe} from './5-resistance/aframe';
import {PhysicsDividingPotentialAframe} from './6-dividing-potential/aframe';
import {PhysicsSpecialsFirstAframe} from './specials-first/aframe';
import {PhysicsResistanceAlongAWireAframe} from './7-resistance-along-a-wire/aframe';
import {Physics8Aframe} from './8-heating-resistance/aframe';
import {PhysicsIVCharacteristicsAframe} from './9-I–V-characteristics/aframe';
import {PhysicsSensorsAframe} from './10-sensors/aframe';
import {PhysicsSemiconductorsAframe} from './semiconductors/aframe';
import {PhysicsSuperconductorsAframe} from './12-superconductors/aframe';
import {PhysicsEMFAframe} from './14-emf/aframe';
import {PhysicsResistivityOfAWireAframe} from './13-resistivity-of-a-wire/aframe';
import {PhysicsHistoryOfTheAtomAframe} from "./15-history-of-a-atom/aframe";
import {PhysicsModernAtomAframe} from './17-modern-atom/aframe';
import {PhysicsGoldFoilExperimentAframe} from './gold-foil-experiment/aframe';
import {PhysicsNuclideNotationAframe} from './19-nuclide-notation/aframe';
import {PhysicsNuclideNotationALevelAframe} from "./25-nuclide-notation-a-level/aframe";
import {PhysicsRadioactivePenetrationAframe} from './radioactive-penetration/aframe';
import {PhysicsNuclearForcesAframe} from "./26-nuclear-forces/aframe";
import {PhysicsSpeedOfSoundAframe} from './speed-of-sound/aframe';
import {PhysicsIonsAndIsotopesAframe} from "./18-ions-and-isotopes/aframe";
import {PhysicsRadioactivityAframe} from "./20-radioactivity/aframe";
import {PhysicsTheSolarSystemAframe} from "./41-the-solar-system/aframe";
import {PhysicsRocketsAframe} from './rockets/aframe';
import {PhysicsSpeedOfSoundAframeAI} from './speed-of-sound-ai/aframe';
import {PhysicsTheMoonsAtmosphereAframe} from "./39-the-moons-atmosphere/aframe";
import {PhysicsHRDiagramsAframe} from "./45-hr-diagrams/aframe";
import {PhysicsMassAndWeightAframe} from './mass-and-weight/aframe';
import {PhysicsEarthsGravityAframe} from './escaping-earths-gravity/aframe';
import {PhysicsSupercooledCombustionAframe} from './supercooled-combustion/aframe';
import {PhysicsRadiationCurvesAframe} from './black-body-radiation-curves/aframe';


export function PhysicsSimulationsRoutes() {
    return (
        <Routes>
            <Route path='/physics-insulation' element={<Physics1Aframe/>}/>
            <Route path='/physics-series-circuits' element={<Physics2Aframe/>}/>
            <Route path='/physics-modelling-circuit' element={<Physics3Aframe/>}/>
            <Route path='/physics-parallel-circuits' element={<PhysicsParallelCircuitsAframe/>}/>
            <Route path='/physics-resistance' element={<PhysicsResistanceAframe/>}/>
            <Route path='/physics-dividing-potential' element={<PhysicsDividingPotentialAframe/>}/>
            <Route path='/physics-specials-first' element={<PhysicsSpecialsFirstAframe/>}/>
            <Route path='/physics-resistance-along-a-wire' element={<PhysicsResistanceAlongAWireAframe/>}/>
            <Route path='/physics-heating-resistance' element={<Physics8Aframe/>}/>
            <Route path='/physics-i-v-characteristics' element={<PhysicsIVCharacteristicsAframe/>}/>
            <Route path='/physics-sensors' element={<PhysicsSensorsAframe/>}/>
            <Route path='/physics-semiconductors' element={<PhysicsSemiconductorsAframe/>}/>
            <Route path='/physics-superconductors' element={<PhysicsSuperconductorsAframe/>}/>
            <Route path='/physics-emf' element={<PhysicsEMFAframe/>}/>
            <Route path='/physics-resistivity-of-a-wire' element={<PhysicsResistivityOfAWireAframe/>}/>
            <Route path='/physics-history-of-the-atom' element={<PhysicsHistoryOfTheAtomAframe/>}/>
            <Route path='/physics-nuclide-notation' element={<PhysicsNuclideNotationAframe/>}/>
            <Route path='/physics-nuclide-notation-a-level' element={<PhysicsNuclideNotationALevelAframe/>}/>
            <Route path='/physics-nuclear-forces' element={<PhysicsNuclearForcesAframe/>}/>
            <Route path='/physics-modern-atom' element={<PhysicsModernAtomAframe/>}/>
            <Route path='/physics-gold-foil-experiment' element={<PhysicsGoldFoilExperimentAframe/>}/>
            <Route path='/physics-radioactive-penetration' element={<PhysicsRadioactivePenetrationAframe/>}/>
            <Route path='/physics-speed-of-sound' element={<PhysicsSpeedOfSoundAframe/>}/>
            <Route path='/physics-ions-and-isotopes' element={<PhysicsIonsAndIsotopesAframe/>}/>
            <Route path='/physics-radioactivity' element={<PhysicsRadioactivityAframe/>}/>
            <Route path='/physics-the-solar-system' element={<PhysicsTheSolarSystemAframe/>}/>
            <Route path='/physics-rockets' element={<PhysicsRocketsAframe/>}/>
            <Route path='/physics-speed-of-sound-ai' element={<PhysicsSpeedOfSoundAframeAI/>}/>
            <Route path='/physics-the-moons-atmosphere' element={<PhysicsTheMoonsAtmosphereAframe/>}/>
            <Route path='/physics-hr-diagrams' element={<PhysicsHRDiagramsAframe/>}/>
            <Route path='/physics-mass-and-weight' element={<PhysicsMassAndWeightAframe/>}/>
            <Route path='/physics-escaping-earths-gravity' element={<PhysicsEarthsGravityAframe/>}/>
            <Route path='/physics-supercooled-combustion' element={<PhysicsSupercooledCombustionAframe/>}/>
            <Route path='/physics-radiation-curves' element={<PhysicsRadiationCurvesAframe/>}/>
        </Routes>
    );
}
