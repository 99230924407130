// src/components/QuizComponent.tsx
import React, { useState, useEffect } from 'react';
import '../styles/quiz.css';
import '../styles/transitions.css';
import closeIcon from '../../assets/img/Close.png';
import DragAndDropQuestion from './drag-and-drop-question';
import { QuestionTypeEnum } from 'lib/enums/question-type.enum';
import Confetti from './confetti';

interface QuizQuestion {
  question: string;
  questionImage?: string;
  questionImageWide?: boolean;
  answers: {
    answer: string;
    result: boolean | string;
  }[];
  type?: string;
}

interface QuizComponentProps {
  data: QuizQuestion[];
  visible: boolean;
  closeQuizButton: () => void;
}

const QuizComponent: React.FC<QuizComponentProps> = ({ data, closeQuizButton, visible }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState<QuizQuestion[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<QuizQuestion>(data[0]);
  const [completed, setCompleted] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState<number | null>(null);
  const [correctAnswer, setCorrectAnswer] = useState<number | null>(null);
  const [quizContainerClass, setQuizContainerClass] = useState(visible ? 'quizContainer' : 'quizContainer slide-down');
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  useEffect(() => {
    const shuffledQuestions = shuffle([...data]);
    shuffledQuestions.forEach((question) => {
      question.answers = shuffle(question.answers, question?.type === QuestionTypeEnum.Order);
    });
    setQuestions(shuffledQuestions);
    setCurrentQuestion(shuffledQuestions[0]);
  }, [data]);

  useEffect(() => {
    setQuizContainerClass(visible ? 'quizContainer' : 'quizContainer slide-down');
  }, [visible]);

  function shuffle(array: any[], orderType?: boolean) {
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    if (orderType && checkCorrectOrder(array)) {
      array = shuffle(array, orderType);
    }

    return array;
  }

  const checkCorrectOrder = (array: any[]): boolean => {
    const correctOrder = array.map((_, i) => i + 1).toString();
    const shuffleOrder = array.map((a) => a.result).toString();
    return correctOrder === shuffleOrder;
  } 

  const handleAnswerClick = (index: number, correct: boolean | string) => {
    setButtonsDisabled(true);
    if (correct) {
      setCorrectAnswer(index);
      proceedToNextQuestion(1000);
    } else {
      setWrongAnswer(index);
      setTimeout(() => {
        const correctIndex = currentQuestion.answers.findIndex((answer) => answer.result);
        setCorrectAnswer(correctIndex);
        proceedToNextQuestion(1000);
      }, 1000);
    }
  };

  const proceedToNextQuestion = (delay: number) => {
    setTimeout(() => {
      setWrongAnswer(null);
      setCorrectAnswer(null);
  
      if (currentQuestionIndex === questions.length - 1) {
        setCompleted(true);
      } else {
        setQuizContainerClass((prevClass) => `${prevClass} slide-down`);
  
        setTimeout(() => {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          
          // Update the current question
          setCurrentQuestion(questions[currentQuestionIndex + 1]);
  
          setQuizContainerClass('quizContainer');
          setButtonsDisabled(false);
        }, 500);
      }
    }, delay);
  };
  

  const getAnswerClass = (index: number) => {
    if (index === wrongAnswer) {
      return 'wrongAnswer';
    }
    if (index === correctAnswer) {
      return 'correctAnswer';
    }
    return 'unanswered';
  };

  if (completed) {
    return (
      <div id="quizContainer" className={quizContainerClass}>
        <Confetti />
        <div className="quizQuestion">
          <div className="quizHeader">
           <span className="quizTitle">QUIZ</span>
           <img className="quizImage" src={closeIcon} alt="Quiz Image" onClick={closeQuizButton}/>
          </div>
          <p>Quiz Complete!</p>
        </div>
      </div>
    );
  }

  return (
    <div id="quizContainer" className={quizContainerClass}>
      <div className="quizQuestion">
        <div className="quizHeader">
         <span className="quizTitle">QUIZ</span>
         <img className="quizImage" src={closeIcon} alt="Quiz Image" onClick={closeQuizButton}/>
        </div>
        {currentQuestion.questionImage && (<img className={currentQuestion.questionImageWide ? 'questionImage wide': 'questionImage'} src={currentQuestion.questionImage} alt="Quiz"/>)}
        <p dangerouslySetInnerHTML={{ __html: currentQuestion.question }} ></p>
      </div>
      <div className="quizAnswers">
        {!currentQuestion?.type && (
          <>
            {currentQuestion.answers.map((answer, index) => (
              <button
                key={index}
                className={`answer ${getAnswerClass(index)}`}
                onClick={() => handleAnswerClick(index, answer.result)}
                disabled={buttonsDisabled}
                dangerouslySetInnerHTML={{ __html: answer.answer }}
              >
              </button>
            ))}
          </>
        )}
        {currentQuestion?.type === QuestionTypeEnum.Order && (
          <DragAndDropQuestion
            data={currentQuestion}
            emitCorrectOrder={() => {
              handleAnswerClick(0, true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default QuizComponent;
