import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import './styles/microscope.css';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IMicroscopeSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionCoarseKnobRotating: THREE.AnimationAction;
	actionFineKnobRotation: THREE.AnimationAction;
	actionObjectiveRotation: THREE.AnimationAction;

	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;

	currentAssetId: number;

	sceneHolder: AFrame.Entity | null;
	microscope: AFrame.Entity | null;
	microscopeScene: AFrame.Entity | null;
	slides: AFrame.Entity | null;
	muscleCell: THREE.Object3D | undefined;
	animalCell: THREE.Object3D | undefined;
	rootHairCell: THREE.Object3D | undefined;
	nerveCell: THREE.Object3D | undefined;
	phloemCell: THREE.Object3D | undefined;
	plantCell: THREE.Object3D | undefined;
	xylemCell: THREE.Object3D | undefined;

	el: AFrame.Entity;

	eyepieceTriggerBtn: AFrame.Entity | null;
	lightSrcTriggerBtn: AFrame.Entity | null;
	stageTriggerBtn: AFrame.Entity | null;
	coarseTriggerBtn: AFrame.Entity | null;
	foarseTriggerBtn: AFrame.Entity | null;
	lensesTriggerBtn: AFrame.Entity | null;
	armTriggerBtn: AFrame.Entity | null;

	eyepieceButtonHandler: () => void;
	lightSrcButtonHandler: () => void;
	stageButtonHandler: () => void;
	coarseButtonHandler: () => void;
	foarseButtonHandler: () => void;
	lensesButtonHandler: () => void;
	armButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const MicroscopeScene = {
	name: 'microscope',
	val: {
		init(this: IMicroscopeSceneAframe) {
			var isInitialised = false;

			this.el.addEventListener('model-loaded', () => {
				this.sceneHolder = document.getElementById('SceneHolder') as AFrame.Entity;
				this.microscope = document.getElementById('Microscope') as AFrame.Entity;
				this.microscopeScene = document.getElementById('microscopeScene') as AFrame.Entity;
				this.slides = document.getElementById('Slides') as AFrame.Entity;
				
				// this.slides?.object3D.traverse((child) => {
				// 	console.log(child);
				// });
	
				this.muscleCell = this.slides.object3D.getObjectByName('MuscleCell');
				this.animalCell = this.slides.object3D.getObjectByName('AnimalCell');
				this.rootHairCell = this.slides.object3D.getObjectByName('RootHairCell');
				this.nerveCell = this.slides.object3D.getObjectByName('NerveCell');
				this.phloemCell = this.slides.object3D.getObjectByName('PhloemCell');
				this.plantCell = this.slides.object3D.getObjectByName('PlantCell');
				this.xylemCell = this.slides.object3D.getObjectByName('XylemCell');

				const cellsSlides = [this.muscleCell, this.animalCell, this.rootHairCell, this.nerveCell, this.phloemCell, this.plantCell, this.xylemCell];
				let currentSlideIndex = 0;

				cellsSlides.forEach(slide => {
					if (slide) {
						slide.visible = false;
					}
				});

				const currentSlide = cellsSlides[currentSlideIndex];
				if (currentSlide) {
					currentSlide.visible = true;
				}

				const showSlide = (index: number) => {
					if (index >= 0 && index < cellsSlides.length) {
						const currentSlide = cellsSlides[currentSlideIndex];
						if (currentSlide) {
							currentSlide.visible = false;
						}

						const newSlide = cellsSlides[index];
						if (newSlide) {
							newSlide.visible = true;
							currentSlideIndex = index;
						}
					}
					console.log('currentSlide', currentSlide)
				};

				const goToNextSlide = () => {
					const nextSlideIndex = (currentSlideIndex + 1) % cellsSlides.length;
					showSlide(nextSlideIndex);
					console.log('next slide')
				};

				const goToPrevSlide = () => {
					const prevSlideIndex = (currentSlideIndex - 1 + cellsSlides.length) % cellsSlides.length;
					showSlide(prevSlideIndex);
					console.log('prev slide')
				};

				this.el.sceneEl?.addEventListener('next-slide', goToNextSlide);
				this.el.sceneEl?.addEventListener('prev-slide', goToPrevSlide);

				if (!isInitialised) {
					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					firstScene();
					isInitialised = true
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			const firstScene = () => {
				if (this.microscopeScene) {
					this.microscopeScene.object3D.rotation.set(0, -95, 0);
				}
				if (this.slides) {
					this.slides.object3D.visible = false;
				}
				if (this.eyepieceTriggerBtn) {
					this.eyepieceTriggerBtn.object3D.visible = true;
				}
				if (this.lightSrcTriggerBtn) {
					this.lightSrcTriggerBtn.object3D.visible = true;
				}
				if (this.stageTriggerBtn) {
					this.stageTriggerBtn.object3D.visible = true;
				}
				if (this.lensesTriggerBtn) {
					this.lensesTriggerBtn.object3D.visible = true;
				}
				if (this.coarseTriggerBtn) {
					this.coarseTriggerBtn.object3D.visible = true;
				}
				if (this.foarseTriggerBtn) {
					this.foarseTriggerBtn.object3D.visible = true;
				}
				if (this.armTriggerBtn) {
					this.armTriggerBtn.object3D.visible = true;
				}
			}

			const secondScene = () => {
				if (this.microscopeScene) {
					this.microscopeScene.object3D.rotation.set(0, 0, 0);
				}
				if (this.slides) {
					this.slides.object3D.visible = true;
				}
				if (this.eyepieceTriggerBtn) {
					this.eyepieceTriggerBtn.object3D.visible = false;
				}
				if (this.lightSrcTriggerBtn) {
					this.lightSrcTriggerBtn.object3D.visible = false;
				}
				if (this.stageTriggerBtn) {
					this.stageTriggerBtn.object3D.visible = false;
				}
				if (this.lensesTriggerBtn) {
					this.lensesTriggerBtn.object3D.visible = false;
				}
				if (this.coarseTriggerBtn) {
					this.coarseTriggerBtn.object3D.visible = false;
				}
				if (this.foarseTriggerBtn) {
					this.foarseTriggerBtn.object3D.visible = false;
				}
				if (this.armTriggerBtn) {
					this.armTriggerBtn.object3D.visible = false;
				}
			}

			this.currentAssetId = 0;
			this.el.sceneEl?.addEventListener('asset-change', (event) => {
				const customEvent = event as CustomEvent; // Cast event to CustomEvent
				const newAssetId = customEvent.detail.assetId;
				this.currentAssetId = newAssetId;
				if (newAssetId === 0) {
					firstScene();
				}
				if (newAssetId === 1) {
					secondScene();
				}

			});

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: SceneHolder; offset: 0 0 -3;');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}

			});

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			const initialiseAnimations = () => {
				const animatedProcess = this.microscope?.object3D.getObjectByName('Biology11_Microscope') as any;

				this.mixer = new THREE.AnimationMixer(animatedProcess);

				const showCoarseKnobRotating = animatedProcess?.animations[0];
				const showFineKnobRotation = animatedProcess?.animations[1];
				const showObjectiveRotation = animatedProcess?.animations[2];

				this.actionCoarseKnobRotating = this.mixer.clipAction(showCoarseKnobRotating);
				this.actionFineKnobRotation = this.mixer.clipAction(showFineKnobRotation);
				this.actionObjectiveRotation = this.mixer.clipAction(showObjectiveRotation);
			};

			this.eyepieceButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Eyepiece';
					const body = 'The eyepiece is the lens used to look at the slides you prepared. Its magnification power is 10x.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.lightSrcButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				
				if (this.onObjectSelected) {
					const title = 'Light Source';
					const body = 'The light source emits light and illuminates your vision field.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.stageButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Stage';
					const body = 'The stage is where you place your slide. There are stage clips that secure the microscope slide in place.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.coarseButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionCoarseKnobRotating;
				this.actionCoarseKnobRotating.reset();
				this.actionCoarseKnobRotating.repetitions = 1;
				this.actionCoarseKnobRotating.clampWhenFinished = false;
				this.actionCoarseKnobRotating.play();
				if (this.onObjectSelected) {
					const title = 'Coarse Adjustment Knob';
					const body = 'The coarse adjustment knob adjusts the focus of the image when using low and medium objective lenses. Rotating it focuses the image, making it clearer to see.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.foarseButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionFineKnobRotation;
				this.actionFineKnobRotation.reset();
				this.actionFineKnobRotation.repetitions = 1;
				this.actionFineKnobRotation.clampWhenFinished = false;
				this.actionFineKnobRotation.play();
				if (this.onObjectSelected) {
					const title = 'Foarse Adjustment Knob';
					const body = 'The fine adjustment knob adjusts the focus of the image when using the high objective lens. Rotating it focuses the image further, making it clearer to see at 400x.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.lensesButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionObjectiveRotation;
				this.actionObjectiveRotation.reset();
				this.actionObjectiveRotation.repetitions = 1;
				this.actionObjectiveRotation.clampWhenFinished = false;
				this.actionObjectiveRotation.play();
				if (this.onObjectSelected) {
					const title = 'Objective Lenses';
					const body = '<p style="margin-top: -5px;">There are three objective lenses: low, medium and high magnifications. Their magnification powers are 4x, 10x, and 40x respectively.</p><p style="margin-top: -10px;">Eyepiece lens X objective lens = Total magnification<br>Example: 10x X 40x = 400x</p>';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.armButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Microscope Arm';
					const body = 'The correct way to carry a microscope is to place one hand under the base and the other hand holding the arm.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.eyepieceTriggerBtn = poolButtons.requestEntity();
				this.eyepieceTriggerBtn?.object3D.position.set(-0.2, 8, 2.08);
				this.eyepieceTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.eyepieceTriggerBtn?.play();
				this.eyepieceTriggerBtn?.addEventListener('click', () => {
					this.eyepieceButtonHandler();
					if (this.eyepieceTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.eyepieceTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.eyepieceTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.eyepieceTriggerBtn;
					}
				});

				this.lightSrcTriggerBtn = poolButtons.requestEntity();
				this.lightSrcTriggerBtn?.object3D.position.set(-0.2, 1.8, -0.92);
				this.lightSrcTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.lightSrcTriggerBtn?.play();
				this.lightSrcTriggerBtn?.addEventListener('click', () => {
					this.lightSrcButtonHandler();
					if (this.lightSrcTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.lightSrcTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.lightSrcTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.lightSrcTriggerBtn;
					}
				});

				this.stageTriggerBtn = poolButtons.requestEntity();
				this.stageTriggerBtn?.object3D.position.set(-0.2, 2.7, -0.6);
				this.stageTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.stageTriggerBtn?.play();
				this.stageTriggerBtn?.addEventListener('click', () => {
					this.stageButtonHandler();
					if (this.stageTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.stageTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.stageTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.stageTriggerBtn;
					}
				});

				this.coarseTriggerBtn = poolButtons.requestEntity();
				this.coarseTriggerBtn?.object3D.position.set(0.7, 2.6, 2.1);
				this.coarseTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.coarseTriggerBtn?.play();
				this.coarseTriggerBtn?.addEventListener('click', () => {
					this.coarseButtonHandler();
					if (this.coarseTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.coarseTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.coarseTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.coarseTriggerBtn;
					}
				});

				this.foarseTriggerBtn = poolButtons.requestEntity();
				this.foarseTriggerBtn?.object3D.position.set(1.4, 2.6, 2.1);
				this.foarseTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.foarseTriggerBtn?.play();
				this.foarseTriggerBtn?.addEventListener('click', () => {
					this.foarseButtonHandler();
					if (this.foarseTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.foarseTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.foarseTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.foarseTriggerBtn;
					}
				});

				this.lensesTriggerBtn = poolButtons.requestEntity();
				this.lensesTriggerBtn?.object3D.position.set(-0.2, 4.2, -1.45);
				this.lensesTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.lensesTriggerBtn?.play();
				this.lensesTriggerBtn?.addEventListener('click', () => {
					this.lensesButtonHandler();
					if (this.lensesTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.lensesTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.lensesTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.lensesTriggerBtn;
					}
				});

				this.armTriggerBtn = poolButtons.requestEntity();
				this.armTriggerBtn?.object3D.position.set(0.42, 4, 1.95);
				this.armTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.armTriggerBtn?.play();
				this.armTriggerBtn?.addEventListener('click', () => {
					this.armButtonHandler();
					if (this.armTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.armTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.armTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.armTriggerBtn;
					}
				});
			}
		},
		tick(this: IMicroscopeSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { MicroscopeScene as MicroscopeComponent }