import * as AFRAME from 'aframe';
import * as THREE from 'three';

interface IProximityDetectorComponent {
    isLessonStarted: boolean;
    camera: any;
    eventExit: CustomEvent<{ isProximityAlert: boolean; }>;
    eventEnter: CustomEvent<{ isProximityAlert: boolean; }>;
    init: () => void,
    tick: () => void,
    el?: any,
    targetPosition: THREE.Vector3,
    thisPosition: THREE.Vector3,
    isInProximity: boolean,

    data: {
        distance: number,
    }
};

const ProximityDetectorComponent = {
    name: "proximity-detector",
    val: {
        schema: {
            distance: {type: 'number', default: 1.0},
        },
      
        init(this: IProximityDetectorComponent) {
            this.isInProximity = false;
            this.isLessonStarted = false;

            const offsetPosition = new THREE.Vector3(0, 6.25, 0);
            this.thisPosition = this.el.object3D.position.clone().add(offsetPosition);

            this.camera = this.el.sceneEl?.camera
            this.eventEnter = new CustomEvent('proximity-alert', {detail: {isProximityAlert: true}});
            this.eventExit = new CustomEvent('proximity-alert', {detail: {isProximityAlert: false}});

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                this.isLessonStarted = true;
                const offsetPosition = new THREE.Vector3(0, 6.25, 0);
                this.thisPosition = this.el.object3D.position.clone().add(offsetPosition);

            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.isLessonStarted = false;
                if (this.isInProximity) {
                    this.isInProximity = false;
                    this.el.sceneEl.dispatchEvent(this.eventExit);
                }
            });
        },
        tick (this: IProximityDetectorComponent) {
            const distanceToCamera = this.thisPosition.distanceTo(this.camera.el.object3D.position);
            // check if the distance is less than the threshold
            if (distanceToCamera <= this.data.distance) {
                // console.log('Close')
                // if proximity alert is not already active, activate it
                if (!this.isInProximity && this.isLessonStarted) {
                    this.isInProximity = true;
                    this.el.sceneEl.dispatchEvent(this.eventEnter); 
                }
            } else {
                // console.log('Far')
                // if proximity alert is active, deactivate it
                if (this.isInProximity) {
                    this.isInProximity = false;
                    this.el.sceneEl.dispatchEvent(this.eventExit);
                }
            }
            
        },
    },
};
export { ProximityDetectorComponent as ProximityDetector} 