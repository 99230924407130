import { Box, styled } from '@mui/material';
import { DisplayEnum } from '../../../shared/enums';
import Background from '../../../assets/howItWorksBG.png';

export const HowItWorksBG = styled(Box, { name: 'HowItWorksBG' })({
  backgroundImage: `url('${Background}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 0',
  backgroundSize: '556px 128px',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
    backgroundPosition: '50% -13px',
  backgroundSize: '406px 90px',
  },
});
