import React, { useRef, useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { CellSceneComponent } from './cell-scene';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import assetA from '../../../assets/img/UI_A.png';
import assetB from '../../../assets/img/UI_B.png';
import assetC from '../../../assets/img/UI_C.png';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  MODEL3D_HINT,
} from 'shared/constants/hint-items';
import {TapPlaceAir} from "../../../lib/aframe/components/tap-place-air";
import { LoadersPermissonsEnum } from 'shared/enums';
const assetIcons = [assetA, assetB, assetC];
const introText =
  'Use the drop down menu to explore viral diseases that affect humans and plants.';
const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const BiologyViralDiseasesAframe = () => {
  const [toggle, setToggle] = useState(true);

  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [assetId, setAssetId] = useState(0);
  const [lessonStarted, setLessonStarted] = useState(false);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
  const onToggleAnimation = () => {
    setToggle(!toggle);
  };
  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };
  const changeAsset = (assetId: number) => {
    setToggle(true);
    setAssetId(assetId);
  };
  const onLessonStarted = () => {
    setLessonStarted(true);
    setToggle(true);
  };
  const permissionsConfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  }

  const assetChangeEventRef = useRef(
    new CustomEvent('asset-change', { detail: { assetId: 5 } })
  );
  React.useEffect(() => {
    // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      console.log('Scene loaded');
      // Update the assetChangeEvent detail before dispatching
      assetChangeEventRef.current.detail.assetId = assetId;
      scene.dispatchEvent(assetChangeEventRef.current);
    }
  }, [assetId]);
  const animToggleEventRef = useRef(
      new CustomEvent('anim-toggle', { detail: { toggle: true } })
  );
  React.useEffect(() => {
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      animToggleEventRef.current.detail.toggle = toggle;
      scene.dispatchEvent(animToggleEventRef.current);
    }
  }, [toggle]);
  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        onAnnotationStateChanged={onAnnotationStateChanged}
        lessonStarted={lessonStarted}
        assetIcons={assetIcons}
        onToggleAnimation={onToggleAnimation}
        toggleAnimationStatus={toggle}
        onAssetButtonClick={changeAsset}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./bio-viral-diseases-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          TapPlaceAir,
          WorldButtonComponent,
          FaceCameraComponent,
          CellSceneComponent,
          Annotation,
        ]}
        onObjectSelected={onObjectSelected}
        annotationState={annotationState}
        onLessonStart={onLessonStarted}
        permissionsСonfirmation={permissionsConfirmation}
      />
    </React.Fragment>
  );
};
export { BiologyViralDiseasesAframe };
