import { AFrame } from "aframe";
import { vertexShader as _VS, fragmentShader as _FS } from './shaders/flowing-shader';
import { vertexShader as _VSf, fragmentShader as _FSf } from './shaders/fall-shader';
import * as THREE from "three";

interface FlowingWaterAframe {
    fallShader: THREE.ShaderMaterial;
    waterShader: THREE.ShaderMaterial;
    el: AFrame['AEntity'];
}

const FlowingWaterComponent = {
    name: "flowing-water",
    val: {
        init(this: FlowingWaterAframe) {
            this.el.addEventListener('model-loaded', () => {
                // creating water effect
                const waterMesh = this.el.object3D.getObjectByName('WaterSurface') as THREE.Mesh;
                this.waterShader = new THREE.ShaderMaterial({
                    uniforms: {
                      u_time: { value: 0.0 },
                    },
                    vertexShader: _VS,
                    fragmentShader: _FS,
                    transparent: true,
                    blending: THREE.CustomBlending,
                    blendEquation: THREE.AddEquation,
                    blendSrc: THREE.SrcAlphaFactor,
                    blendDst: THREE.OneMinusSrcAlphaFactor,
                    depthTest: true,
                    depthWrite: false,
                });
                for (let i = 0; i < waterMesh.children.length; i++) {
                    const mesh = waterMesh.children[i] as THREE.Mesh;
                    mesh.material = this.waterShader;
                    mesh.material.needsUpdate = true;
                }

                // creating waterall effect
                const onLoadTexture = (texture: THREE.Texture) => {
                    const arc1 = this.el.object3D.getObjectByName('WaterFallArch') as THREE.Mesh;
                    const arc2 = this.el.object3D.getObjectByName('WaterFallArch_(1)') as THREE.Mesh;
                    this.fallShader = new THREE.ShaderMaterial({
                        uniforms: {
                          u_time: { value: 0.0 },
                          u_texture: { value: texture },
                        },
                        vertexShader: _VSf,
                        fragmentShader: _FSf,
                        transparent: true,
                        blending: THREE.CustomBlending,
                        blendEquation: THREE.AddEquation,
                        blendSrc: THREE.SrcAlphaFactor,
                        blendDst: THREE.OneMinusSrcAlphaFactor,
                        depthTest: true,
                        depthWrite: false,
                        });
                    arc1.material = this.fallShader;
                    arc1.material.needsUpdate = true;
                    arc2.material = this.fallShader;
                    arc2.material.needsUpdate = true;
                }
                const textureElement = document.querySelector('#noiseTexture');
                if (textureElement) {
                    const texture = new THREE.TextureLoader().load(textureElement.getAttribute('src'), onLoadTexture);
                } else {
                    console.log('textureElement not found');
                }
            });
        },
        tick(this: FlowingWaterAframe, time: number, timeDelta: number) {
            if (this.waterShader) {
                this.waterShader.uniforms.u_time.value += timeDelta * 0.001;
            }
            if (this.fallShader) {
                this.fallShader.uniforms.u_time.value += timeDelta * 0.001;
            }
          },
        
    },
};
export {FlowingWaterComponent as FlowingWater}