import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';


interface ICloningControl {
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    returnToStartButton: any;

    el: AFrame.Entity;
    scalpel: THREE.Mesh;
    mixer: THREE.AnimationMixer;
    cutOffAnimations: THREE.AnimationAction;
    dipPlantAnimations: THREE.AnimationAction;
    growthAnimations: THREE.AnimationAction;
    parentPlantTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    cuttingTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    rootingHormoneTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    suitableEnvironmentTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
    baseTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CloningControlComponent = {
    name: 'lesson-start',
    val: {
        init(this: ICloningControl) {

            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                this.returnToStartButton = document.getElementById("return-to-start-button") as any;

                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                const model = document.getElementById('model') as AFrame.Entity
                this.scalpel = model.object3D.getObjectByName('Scalpel') as THREE.Mesh
                this.scalpel.visible = false
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.returnToStartButton.classList.remove("return-to-start-button-visible");
                this.returnToStartButton.classList.add("return-to-start-button-invisible");
                initialiseAnimations()
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: holder; scale: 20 20 20; relativeRotation: 0 0 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.el.sceneEl?.addEventListener('return-to-start', () => {
                this.scalpel.visible = false
                this.parentPlantTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2');
                this.cuttingTriggerBtn?.setAttribute('scale', '0 0 0');
                this.rootingHormoneTriggerBtn?.setAttribute('scale', '0 0 0');
                this.suitableEnvironmentTriggerBtn?.setAttribute('scale', '0 0 0');
                this.cutOffAnimations.stop()
                this.dipPlantAnimations.stop()
                this.growthAnimations.stop()
            })

            const initialiseAnimations = () => {
                const model = document.getElementById('model') as AFrame.Entity;
                const animatedEl = model?.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [CutOff, DipPlant, Growth] = animatedEl.animations
                this.cutOffAnimations = this.mixer.clipAction(CutOff);
                this.cutOffAnimations.setLoop(THREE.LoopOnce, 1)
                this.cutOffAnimations.clampWhenFinished = true
                this.dipPlantAnimations = this.mixer.clipAction(DipPlant);
                this.dipPlantAnimations.setLoop(THREE.LoopOnce, 1)
                this.dipPlantAnimations.clampWhenFinished = true
                this.growthAnimations = this.mixer.clipAction(Growth);
                this.growthAnimations.setLoop(THREE.LoopOnce, 1)
                this.growthAnimations.clampWhenFinished = true
            }
            const parentPlantButtonHandler = () => {
                this.cuttingTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                this.scalpel.visible = true
                const title = 'Parent Plant'
                const body = 'The first step of the cutting cloning method is to choose a healthy plant that possesses desirable traits to be cloned. Desirable traits can include things like showy flowers, tasty produce, or pest resistance.'
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const cuttingButtonHandler = () => {
                this.parentPlantTriggerBtn?.setAttribute('scale', '0 0 0');

                this.cutOffAnimations.play()
                setTimeout(() => {
                    this.cuttingTriggerBtn?.setAttribute('scale', '0 0 0')
                    this.rootingHormoneTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                }, this.cutOffAnimations.getClip().duration * 1000)
                const title = 'Cutting'
                const body = 'The next step is to carefully cut off a section of the parent plant, usually a stem. The cut should be smooth and clean, so the tool used should be sharp and sanitised.'
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const rootingHormoneButtonHandler = () => {
                this.cutOffAnimations.stop()
                this.dipPlantAnimations.play()
                setTimeout(() => {
                    this.rootingHormoneTriggerBtn?.setAttribute('scale', '0 0 0')
                    this.growthAnimations.play()
                    this.growthAnimations.paused = true
                    this.suitableEnvironmentTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2')
                }, this.dipPlantAnimations.getClip().duration * 1000)
                const title = 'Rooting Hormone'
                const body = 'To encourage the growth of roots from the cutting, it is often treated with a rooting hormone. The hormone helps stimulate root formation and development, increasing the chances of successful cloning.'
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const suitableEnvironmentButtonHandler = () => {
                this.growthAnimations.paused = false
                this.dipPlantAnimations.stop()
                this.growthAnimations.play()
                setTimeout(() => {
                    this.returnToStartButton.classList.remove("return-to-start-button-invisible");
                    this.returnToStartButton.classList.add("return-to-start-button-visible");
                }, this.growthAnimations.getClip().duration * 1000)
                const title = 'Suitable Environment'
                const body = 'The treated cutting is then planted in a growing medium, such as soil. The environment should provide the necessary conditions for development, including moisture, nutrients, and suitable temperature.'
                if (this.onObjectSelected) {
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                this.parentPlantTriggerBtn = poolButtons.requestEntity();
                this.parentPlantTriggerBtn?.setAttribute('position', '0 0.2 0.03');
                this.parentPlantTriggerBtn?.setAttribute('scale', '0.2 0.2 0.2');
                this.parentPlantTriggerBtn?.play();
                this.parentPlantTriggerBtn?.addEventListener('click', () => {
                    parentPlantButtonHandler();
                    if (this.parentPlantTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.parentPlantTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.parentPlantTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.parentPlantTriggerBtn
                    }
                });

                this.cuttingTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.cuttingTriggerBtn?.setAttribute('position', '-0.05 0.035 0.05')
                this.cuttingTriggerBtn?.setAttribute('scale', '0 0 0');
                this.cuttingTriggerBtn?.play()
                this.cuttingTriggerBtn?.addEventListener('click', () => {
                    cuttingButtonHandler();
                    if (this.cuttingTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.cuttingTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.cuttingTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.cuttingTriggerBtn
                    }
                });

                this.rootingHormoneTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.rootingHormoneTriggerBtn?.setAttribute('position', '0.08 0.05 0.06');
                this.rootingHormoneTriggerBtn?.setAttribute('scale', '0 0 0');
                this.rootingHormoneTriggerBtn?.play();
                this.rootingHormoneTriggerBtn?.addEventListener('click', () => {
                    rootingHormoneButtonHandler();
                    if (this.rootingHormoneTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.rootingHormoneTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.rootingHormoneTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.rootingHormoneTriggerBtn
                    }
                });

                this.suitableEnvironmentTriggerBtn = poolButtons.requestEntity() as AFrame.Entity;
                this.suitableEnvironmentTriggerBtn?.setAttribute('position', '0 0.05 0.03')
                this.suitableEnvironmentTriggerBtn?.setAttribute('scale', '0 0 0');
                this.suitableEnvironmentTriggerBtn?.play()
                this.suitableEnvironmentTriggerBtn?.addEventListener('click', () => {
                    suitableEnvironmentButtonHandler();
                    if (this.suitableEnvironmentTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(this.suitableEnvironmentTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.suitableEnvironmentTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.suitableEnvironmentTriggerBtn
                    }
                });
            }
        },
        tick(this: ICloningControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }

        },
    },
};
export {CloningControlComponent as CloningControl}
