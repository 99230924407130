import { IconImage } from '../../../shared/components/IconImage';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DisplayEnum } from '../../../shared/enums';
import { BridgeStack } from './BridgeCard';
import { useTranslation } from 'react-i18next';

import bridgeLg from '../../../assets/bridge_lg.png';
import Yahoo from '../../../assets/yahoo.svg';
import MarketWatch from '../../../assets/marketwatch.svg';
import Fox from '../../../assets/fox.svg';
import Abc from '../../../assets/abc.svg';
import Finanzen from '../../../assets/finanzen.svg';
import SeekingAlpha from '../../../assets/alpha.svg';
import MarketsInsider from '../../../assets/markets.svg';
import AP from '../../../assets/ap.svg';
import MorningStar from '../../../assets/morning.svg';
import USN from '../../../assets/us_news.svg';

export function AboutTheCompany() {
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const { t, i18n } = useTranslation();
  const i18nPath = 'LANDING_PAGE.ABOUT_THE_COMPANY.';

  return (
    <Stack textAlign='center'>
      <BridgeStack>
        <Box mr={2}>
          <IconImage src={bridgeLg} alt='bridge' width='162px' />
        </Box>
        <Typography
          fontFamily='GilroyBold'
          fontSize={26}
          color='#fff'
          textAlign={mobile ? 'center' : 'left'}
        >
          {t(i18nPath + 'BRIDGE')}
        </Typography>
      </BridgeStack>
      <Stack
        width={xlDisplay ? 862 : '100%'}
        mt={mobile ? -3 : -9}
        mx='auto'
        px={xlDisplay ? 8 : mobile ? 2 : 3}
        pb={xlDisplay ? 8 : 3}
        pt={5}
        boxSizing='border-box'
        position='relative'
        bgcolor='#fff'
        borderRadius='30px'
        boxShadow='0px 24px 76px rgba(0, 0, 0, 0.11)'
      >
        <Typography
          fontFamily='GilroyBold'
          fontSize={26}
          color='#000'
          textAlign='left'
          mb={3}
        >
          {t(i18nPath + 'TITLE')}
        </Typography>
        <Typography
          fontFamily='GilroyMedium'
          fontSize={16}
          color={theme.palette.text.secondary}
          textAlign='left'
          mb={3}
        >
          {t(i18nPath + 'DESCRIPTION.PART_1')}
        </Typography>
        <Typography
          fontFamily='GilroyMedium'
          fontSize={16}
          color={theme.palette.text.secondary}
          textAlign='left'
          mb={3}
        >
          {t(i18nPath + 'DESCRIPTION.PART_2')}
        </Typography>
        <Typography
          fontFamily='GilroyMedium'
          fontSize={16}
          color={theme.palette.text.secondary}
          textAlign='left'
          mb={3}
        >
          {t(i18nPath + 'DESCRIPTION.PART_3')}
        </Typography>
        <Typography
          fontFamily='GilroyMedium'
          fontSize={16}
          color={theme.palette.text.secondary}
          textAlign='left'
        >
          {t(i18nPath + 'DESCRIPTION.PART_4')}
        </Typography>
        {xlDisplay && (
          <>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              mt={6}
              flexWrap='nowrap'
            >
              <Box>
                <IconImage src={Yahoo} alt='Yahoo' />
              </Box>
              <Box>
                <IconImage src={MarketWatch} alt='MarketWatch' />
              </Box>
              <Box>
                <IconImage src={Fox} alt='Fox' />
              </Box>
              <Box>
                <IconImage src={Abc} alt='Abc' />
              </Box>
              <Box>
                <IconImage src={Finanzen} alt='Finanzen' />
              </Box>
            </Stack>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              mt={4}
              flexWrap='nowrap'
            >
              <Box>
                <IconImage src={MarketsInsider} alt='MarketsInsider' />
              </Box>
              <Box>
                <IconImage src={MorningStar} alt='MorningStar' />
              </Box>
              <Box>
                <IconImage src={AP} alt='AP' />
              </Box>
              <Box>
                <IconImage src={USN} alt='USN' />
              </Box>
              <Box>
                <IconImage src={SeekingAlpha} alt='SeekingAlpha' />
              </Box>
            </Stack>
          </>
        )}
        {!xlDisplay && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mt={3}
            flexWrap='wrap'
          >
            <Box mb={1.5} mr={1}>
              <IconImage src={Yahoo} alt='Yahoo' />
            </Box>
            <Box mr={1}>
              <IconImage src={MarketWatch} alt='MarketWatch' />
            </Box>
            <Box mr={1}>
              <IconImage src={Fox} alt='Fox' />
            </Box>
            <Box mb={1.5} mr={1}>
              <IconImage src={Abc} alt='Abc' />
            </Box>
            <Box mr={1}>
              <IconImage src={Finanzen} alt='Finanzen' />
            </Box>
            <Box mr={1}>
              <IconImage src={MarketsInsider} alt='MarketsInsider' />
            </Box>
            <Box mb={1.5} mr={1}>
              <IconImage src={MorningStar} alt='MorningStar' />
            </Box>
            <Box mr={1}>
              <IconImage src={AP} alt='AP' />
            </Box>
            <Box mr={1}>
              <IconImage src={USN} alt='USN' />
            </Box>
            <Box>
              <IconImage src={SeekingAlpha} alt='SeekingAlpha' />
            </Box>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
