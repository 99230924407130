import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import './styles/biology-light-intensity.css';


interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface BiologyLightIntensitySceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionShowTemperatureGraph: THREE.AnimationAction;
	actionShowCO2Graph: THREE.AnimationAction;
	actionShowLightIntensityGraph: THREE.AnimationAction;
	actionMolecules: THREE.AnimationAction;

	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;
	distanceEvent: CustomEvent;
	actionBubbles: THREE.AnimationAction;

	groupElement: AFrame.Entity;
	sceneLight: AFrame.Entity;
	lampLight: AFrame.Entity;
	el: AFrame.Entity;

	pondweedTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	waterTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	thermometerTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	funnelTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	lightTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;

	pondweedButtonHandler: () => void;
	waterButtonHandler: () => void;
	thermometerButtonHandler: () => void;
	funnelButtonHandler: () => void;
	lightButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;

	sliderValue: number;

	lamp: THREE.Object3D;
	lampHead: THREE.Object3D;
	lampRays: THREE.Object3D;
	aimPoint: THREE.Object3D;
	
	originalQuaternion: THREE.Quaternion;
	targetPosition: THREE.Vector3
}

const BiologyLightIntensityScene = {
	name: 'biology-light-intensity',
	val: {
		init(this: BiologyLightIntensitySceneAframe) {
			var isInitialised = false;
			this.el.addEventListener('model-loaded', () => {
				this.groupElement = document.getElementById('LightIntensity') as AFrame.Entity;
				
				// this.groupElement?.object3D.traverse((child) => {
				// 	console.log(child);
				// });
				this.lamp = this.groupElement?.object3D.getObjectByName('LEDLamp') as THREE.Object3D;
				this.lampHead = this.groupElement?.object3D.getObjectByName('Light001') as THREE.Object3D;
				
				this.aimPoint = this.groupElement?.object3D.getObjectByName('TestTube') as THREE.Object3D;
				this.originalQuaternion = this.lampHead.quaternion.clone();
				
				this.lampHead.lookAt(this.aimPoint.position.x, this.aimPoint.position.y, 1)
				
				if (!isInitialised) {
					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					startAnimation();
					isInitialised = true;
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: BiologyLightIntensityHolder; scale: 8 8 8;');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}
			});

			this.el.sceneEl?.addEventListener('slider-move', (e) => {
				const ce = e as CustomEvent;

				this.sliderValue = ce.detail.value;
				console.log('lamp', this.lampHead.position)
				if (this.sliderValue <= 10) {
					this.lamp.position.x = -0.1;
					this.actionBubbles.timeScale = 1;
					this.lampHead.quaternion.copy(this.originalQuaternion);
					if (this.lightTriggerBtn) {
						this.lightTriggerBtn.object3D.position.x = -0.24;
					}
				} else if (this.sliderValue <= 20) {
					this.lamp.position.x = 0.1;
					this.actionBubbles.timeScale = 0.5;
					this.lampHead.quaternion.copy(this.originalQuaternion);
					if (this.lightTriggerBtn) {
						this.lightTriggerBtn.object3D.position.x = -0.05;
					}
				} else if (this.sliderValue <= 30) {
					this.lamp.position.x = 0.3;
					this.actionBubbles.timeScale = 0.25;
					this.lampHead.quaternion.copy(this.originalQuaternion);
					this.lampHead.lookAt(this.aimPoint.position.x, this.aimPoint.position.y, 11)
					if (this.lightTriggerBtn) {
						this.lightTriggerBtn.object3D.position.x = 0.15;
					}
				}
			});

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			const initialiseAnimations = () => {
				const animatedProcess = this.groupElement?.object3D.getObjectByName('Scene') as any;
				this.mixer = new THREE.AnimationMixer(animatedProcess);
				const showBubblesAnimation = animatedProcess?.animations[0];
				this.actionBubbles = this.mixer.clipAction(showBubblesAnimation);
			};

			const startAnimation = () => {
				this.currentClip = this.actionBubbles;
				this.actionBubbles.reset();
				this.actionBubbles.play();
			}

			

			this.pondweedButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Pondweed';
					const body = 'Pondweed is an aquatic plant that photosynthesises. We cut its stem diagonally to watch the bubbles get released easily.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.waterButtonHandler = () => {

				if (this.onObjectSelected) {
					const title = 'Sodium Bicarbonate';
					const body = 'Sodium bicarbonate is dissolved in water and releases carbon dioxide which is a necessary reactant of photosynthesis.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.thermometerButtonHandler = () => {

				if (this.onObjectSelected) {
					const title = 'Thermometer';
					const body = 'This is a digital thermometer, the temperature should be kept at 25 ℃.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.funnelButtonHandler = () => {


				if (this.onObjectSelected) {
					const title = 'Funnel';
					const body = 'The funnel when inverted ensures all the oxygen being released goes up through the neck and is visible.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.lightButtonHandler = () => {


				if (this.onObjectSelected) {
					const title = 'Light Source';
					const body = 'A LED lamp is used because it does not emit heat. This ensures the temperature of the water doesn’t change because the temperature is a controlled variable in this experiment.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.pondweedTriggerBtn = poolButtons.requestEntity();
				this.pondweedTriggerBtn?.object3D.position.set(-0.34, 0.08, 0.02);
				this.pondweedTriggerBtn?.object3D.scale.set(0.12, 0.12, 0.12);
				this.pondweedTriggerBtn?.play();
				this.pondweedTriggerBtn?.addEventListener('click', () => {
					this.pondweedButtonHandler();
					if (this.pondweedTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.pondweedTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.pondweedTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.pondweedTriggerBtn;
					}
				});

				this.waterTriggerBtn = poolButtons.requestEntity();
				this.waterTriggerBtn?.object3D.position.set(-0.32, 0.2, 0.02);
				this.waterTriggerBtn?.object3D.scale.set(0.12, 0.12, 0.12);
				this.waterTriggerBtn?.play();
				this.waterTriggerBtn?.addEventListener('click', () => {
					this.waterButtonHandler();
					if (this.waterTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.waterTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.waterTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.waterTriggerBtn;
					}
				});


				this.thermometerTriggerBtn = poolButtons.requestEntity();
				this.thermometerTriggerBtn?.object3D.position.set(-0.4, 0.45, 0.01);
				this.thermometerTriggerBtn?.object3D.scale.set(0.12, 0.12, 0.12);
				this.thermometerTriggerBtn?.play();
				this.thermometerTriggerBtn?.addEventListener('click', () => {
					this.thermometerButtonHandler();
					if (this.thermometerTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.thermometerTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.thermometerTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.thermometerTriggerBtn;
					}
				});

				this.funnelTriggerBtn = poolButtons.requestEntity();
				this.funnelTriggerBtn?.object3D.position.set(-0.36, 0.25, 0.02);
				this.funnelTriggerBtn?.object3D.scale.set(0.12, 0.12, 0.12);
				this.funnelTriggerBtn?.play();
				this.funnelTriggerBtn?.addEventListener('click', () => {
					this.funnelButtonHandler();
					if (this.funnelTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.funnelTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.funnelTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.funnelTriggerBtn;
					}
				});

				this.lightTriggerBtn = poolButtons.requestEntity();
				this.lightTriggerBtn?.object3D.position.set(0.15, 0.35, 0.06);
				this.lightTriggerBtn?.object3D.scale.set(0.12, 0.12, 0.12);
				this.lightTriggerBtn?.play();
				this.lightTriggerBtn?.addEventListener('click', () => {
					this.lightButtonHandler();
					if (this.lightTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.lightTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.lightTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.lightTriggerBtn
					}
				});
			}
		},
		tick(this: BiologyLightIntensitySceneAframe, time: number, deltaTime: number) {
			const ring = document.getElementById('ring')
			if (!ring) {
				const circuit = document.getElementById('BiologyLightIntensityHolder') as AFrame.Entity;
				const cameraEl = this.el.sceneEl?.querySelector('a-camera');
				if (cameraEl && circuit) {
					const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
					let cameraPosition = camPos.clone()
					let spherePos = circuit.object3D.position.clone()
					let distance = cameraPosition.distanceTo(spherePos)
					if (!this.distanceEvent) {
						this.distanceEvent = new CustomEvent('distance-change', { detail: { value: false } });
					}
					if ((distance < 6 && cameraPosition.x < 4) || (distance < 8 && cameraPosition.x > 4) || (distance < 12 && cameraPosition.x > 6)) {
						console.log(cameraPosition.x)
						const scene = this.el.sceneEl as AFrame.Scene
						this.distanceEvent.detail.value = true
						scene.emit('distance-change', this.distanceEvent.detail.value);
						circuit.object3D.visible = false
					} else {
						const scene = this.el.sceneEl as AFrame.Scene
						this.distanceEvent.detail.value = false
						scene.emit('distance-change', this.distanceEvent.detail.value);
						if (!circuit.object3D.visible) {
							circuit.object3D.visible = true
						}
					}
				}
			}
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { BiologyLightIntensityScene as BiologyLightIntensityComponent }