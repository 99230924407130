import * as AFrame from 'aframe';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';
import {ILightningAframe} from './lightning';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}
interface IClimateChangeSceneAframe {
    buttonHolder: AFrame.Entity[];
    fadeInCycloneTween: TWEEN.Tween<THREE.MeshBasicMaterial>;
    fadeOutCycloneTween: TWEEN.Tween<THREE.MeshBasicMaterial>;
    fadeInTween: TWEEN.Tween<THREE.MeshPhongMaterial>;
    fadeOutTween: TWEEN.Tween<THREE.MeshPhongMaterial>;
    updateMaterials: () => void;
    setupCyclone: () => void;
    cycloneAction: THREE.AnimationAction;
    setupClouds: () => void;
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
	climateDataButtonHandler: () => void;
    weatherPatternsButtonHandler: () => void;
    risingSeaLevelsButtonHandler: () => void;
    habitatChangesButtonHandler: () => void;
    lossOfTheCryosphereButtonHandler: () => void;
    mixer: THREE.AnimationMixer;
    oceanAnimation: THREE.AnimationAction;
    iceAnimation: THREE.AnimationAction;
    rainAnimation: THREE.AnimationAction;
    forestAnimation: THREE.AnimationAction;
    currentAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; imageExtra?: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    currentAssetId: number;
}

const LessonStart = {
    name: 'planet-scene',
    val: {
        init(this: IClimateChangeSceneAframe) {
            const planet = document.getElementById('planetHolder') as AFrame.Entity;
            
            planet.addEventListener('model-loaded', () => {
                const planet = document.getElementById('planet') as AFrame.Entity;
                planet.setAttribute('lightning', '');
                let animatedEl = planet.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [Forest, IceCapsShrink, OceansRise, RainClouds, Cyclone] = animatedEl.animations
                this.iceAnimation = this.mixer.clipAction(IceCapsShrink)
                this.oceanAnimation = this.mixer.clipAction(OceansRise)
                this.rainAnimation = this.mixer.clipAction(RainClouds)
                this.forestAnimation = this.mixer.clipAction(Forest)
                this.cycloneAction = this.mixer.clipAction(Cyclone)
                this.rainAnimation.paused = true
                this.rainAnimation.play()

                this.updateMaterials();

                // start clouds animation
                this.setupClouds();

                // start cyclone
                this.setupCyclone();

                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });
            
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: planetHolder; scale: 10 10 10; offset: 0 -3 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });

            this.updateMaterials = () => {
                const sea = planet.object3D.getObjectByName('Sea') as THREE.Object3D;
                const seaMesh = sea as THREE.Mesh;
                const seaMat = seaMesh.material as THREE.MeshPhysicalMaterial;

                // create new material
                // Create a new material with the desired properties
                const newSeaMat = new THREE.MeshPhysicalMaterial({
                    // color: seaMat.color,
                    map: seaMat.map,
                    // Set the reflectivity to 0 to remove specular reflection
                    reflectivity: 0,
                  });

                  // Apply the new material to the mesh
                  seaMesh.material = newSeaMat;
                  seaMesh.material.needsUpdate = true;

                const land = planet.object3D.getObjectByName('Land') as THREE.Object3D;
                const landMesh = land as THREE.Mesh;
                const landMat = landMesh.material as THREE.MeshPhysicalMaterial;

                // create new material
                // Create a new material with the desired properties
                const newLandMat = new THREE.MeshPhysicalMaterial({
                    // color: seaMat.color,
                    map: landMat.map,
                    // Set the reflectivity to 0 to remove specular reflection
                    reflectivity: 0,
                  });

                  // Apply the new material to the mesh
                  landMesh.material = newLandMat;

                  //console.log('Land material: ', landMesh.material)

            };

            this.setupClouds = () => {
                const cloudSphere = planet.object3D.getObjectByName('Sphere') as THREE.Object3D;
                cloudSphere.visible = true;
                const currentScale = cloudSphere.scale.clone();
                const increasedScale = currentScale.multiplyScalar(1.04);

                cloudSphere.scale.copy(increasedScale);
                // console.log('Cloud sphere: ', cloudSphere); 
                const cloudsMesh = cloudSphere as THREE.Mesh;
                const mat = cloudsMesh.material as THREE.MeshPhongMaterial;
                mat.needsUpdate = true;
             
                // start rotwtion animation
                const rotationTween = new TWEEN.Tween(cloudSphere.rotation)
                    .to({ y: Math.PI * 2}, 200000) // Target rotation values for both y-axis and x-axis
                    .repeat(Infinity) // Repeat the animation indefinitely  
                    .easing(TWEEN.Easing.Linear.None); // Animation easing (linear)
                rotationTween.start(); // Start the rotation animation

                // Opacity Animation: Fade Out (from 1 to 0)
                this.fadeOutTween = new TWEEN.Tween(mat)
                    .to({ opacity: 0 }, 500) // Target opacity value (0 means completely transparent)
                    .easing(TWEEN.Easing.Linear.None); // Animation easing (linear)

                // Opacity Animation: Fade In (from 0 to 1)
                this.fadeInTween = new TWEEN.Tween(mat)
                    .to({ opacity: 1 }, 500) // Target opacity value (1 means completely opaque)
                    .delay(3500) // Delay before starting the animation (optional)
                    .easing(TWEEN.Easing.Linear.None); // Animation easing (linear)

                
                this.fadeOutTween.chain(this.fadeInTween); // Chain the fade out and fade in animations

            };

            this.setupCyclone = () => {
                const videoImg = document.getElementById('video') as HTMLVideoElement;
                const videoText = new THREE.VideoTexture(videoImg);
                videoText.format = THREE.RGBAFormat;
                videoText.needsUpdate = true;

                const alphaMapImg = document.getElementById('alphaMapImage') as HTMLImageElement;
                const alphaMapTexture = new THREE.Texture(alphaMapImg);
                alphaMapTexture.flipY = false;
                alphaMapTexture.format = THREE.RGBAFormat;
                alphaMapTexture.needsUpdate = true;

                const sphere001 = planet.object3D.getObjectByName('Sphere001') as THREE.Object3D;
                const cycloneMesh = sphere001 as THREE.Mesh;

                const cycloneMat = new THREE.MeshBasicMaterial({
                    map: videoText,
                    alphaMap: alphaMapTexture,
                    transparent: true,
                });
                

                cycloneMesh.material = cycloneMat;
                cycloneMat.needsUpdate = true;

                // console.log("Cyclone material: ", cycloneMat);
                videoImg.playbackRate = 0.55;
                videoImg.play();
          
                this.cycloneAction.setLoop(THREE.LoopRepeat, Infinity)
                this.cycloneAction.play();

                // Opacity Animation: Fade Out (from 1 to 0)
                this.fadeOutCycloneTween = new TWEEN.Tween(cycloneMat)
                    .to({ opacity: 0 }, 500) // Target opacity value (0 means completely transparent)
                    .easing(TWEEN.Easing.Linear.None); // Animation easing (linear)

                // Opacity Animation: Fade In (from 0 to 1)
                this.fadeInCycloneTween = new TWEEN.Tween(cycloneMat)
                    .to({ opacity: 1 }, 500) // Target opacity value (1 means completely opaque)
                    .delay(3500) // Delay before starting the animation (optional)
                    .easing(TWEEN.Easing.Linear.None); // Animation easing (linear)

                
                this.fadeOutCycloneTween.chain(this.fadeInCycloneTween); // Chain the fade out and fade in animations
            };

            const onAnimationStart = (delay: number) => {
                // animate clouds to be more transparent
                this.fadeInTween.delay(delay);
                this.fadeOutTween.start();

                // animate cyclone
                this.fadeInCycloneTween.delay(delay);
                this.fadeOutCycloneTween.start();

                // set buttons to be invissible while animation is playing
                for (let i=0; i < this.buttonHolder.length; i++) {
                    if (this.buttonHolder[i] !== this.currentDeactivatedButton) {
                        this.buttonHolder[i].setAttribute('visible', false);
                    }
                }

                setTimeout(() => {
                    // set buttons to be invissible while animation is playing
                    for (let i=0; i < this.buttonHolder.length; i++) {
                        this.buttonHolder[i].setAttribute('visible', true);
                    }
                }, delay + 500);

            };

            this.climateDataButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Climate Data';
                    const body = 'Many scientists believe in climate change due to the correlation between rising temperatures and increasing CO<sub>2</sub> levels in the atmosphere.'
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/ClimateChange/Graph.png'
                    this.onObjectSelected({title, body, imageExtra})
                } else {
                    console.log('No object selected method')
                }
            }
            this.weatherPatternsButtonHandler = () => {
                this.rainAnimation.setLoop(THREE.LoopOnce, 1);
                this.rainAnimation.paused = false;
                this.rainAnimation.play()
                const planet = document.getElementById('planet') as AFrame.Entity;
                const lightning = planet.components['lightning'] as unknown as ILightningAframe;
                if (lightning.canPlay) {
                    lightning.start()
                }
                setTimeout(() => {
                    this.rainAnimation.reset()
                    this.rainAnimation.paused = true
                    this.rainAnimation.play()
                }, 6000)
                if (this.onObjectSelected) {
                    const title = 'Weather Patterns';
                    const body = 'Changes in weather patterns, particularly increased storms and changes in rainfall, would lead to increased flooding as well as drought. This could have negative effects on growing crops to feed the population.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.risingSeaLevelsButtonHandler = () => {
                this.oceanAnimation.reset()
                this.oceanAnimation.setLoop(THREE.LoopOnce, 1);
                this.oceanAnimation.play()
                if (this.onObjectSelected) {
                    const title = 'Rising Sea Levels';
                    const body = 'As global temperatures increase, this also increases the temperature of the ocean. Seawater expands as it warms. This, along with melting ice caps, is predicted to cause sea levels to rise which would submerge many coastal towns and habitats.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.habitatChangesButtonHandler = () => {
                this.forestAnimation.reset()
                this.forestAnimation.setLoop(THREE.LoopOnce, 1);
                this.forestAnimation.play()
                if (this.onObjectSelected) {
                    const title = 'Habitat Changes';
                    const body = 'Changes in microclimates, due to increasing temperature and weather patterns, will likely cause changes in the distribution of forest, desert, grassland, tundra and other habitats. This may lead to a loss of biodiversity and the distribution of species.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.lossOfTheCryosphereButtonHandler = () => {
                this.iceAnimation.reset()
                this.iceAnimation.setLoop(THREE.LoopOnce, 1);
                this.iceAnimation.play()
                if (this.onObjectSelected) {
                    const title = 'Loss of the Cryosphere';
                    const body = 'As the temperature at the Poles increases, the glaciers, ice caps, and sea ice will continue to melt. It’s predicted this will cause sea levels to rise, increase coastal flooding, as well as damage many cryospheric habitats.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // button holder
                this.buttonHolder = [];
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const climateDataTriggerBtn = poolButtons.requestEntity()
                climateDataTriggerBtn?.setAttribute('position', '0.27 0.29 0.12')
                climateDataTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5');
                (climateDataTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance).setAsGraphButton();
                climateDataTriggerBtn?.play()
                climateDataTriggerBtn?.addEventListener('click', () => {
                    this.climateDataButtonHandler()
                    if (climateDataTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(climateDataTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (climateDataTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = climateDataTriggerBtn
                    }
                });
                if (climateDataTriggerBtn) 
                    this.buttonHolder.push(climateDataTriggerBtn);

                const weatherPatternsTriggerBtn = poolButtons.requestEntity()
                weatherPatternsTriggerBtn?.setAttribute('position', '-0.25 0.29 0.12')
                weatherPatternsTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                weatherPatternsTriggerBtn?.play()
                weatherPatternsTriggerBtn?.addEventListener('click', () => {
                    this.weatherPatternsButtonHandler()
                    if (weatherPatternsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(weatherPatternsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (weatherPatternsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = weatherPatternsTriggerBtn
                    }
                    onAnimationStart(3500);
                });
                if (weatherPatternsTriggerBtn)
                    this.buttonHolder.push(weatherPatternsTriggerBtn);

                const risingSeaLevelsTriggerBtn = poolButtons.requestEntity()
                risingSeaLevelsTriggerBtn?.setAttribute('position', '-0.29 -0.05 0.19')
                risingSeaLevelsTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                risingSeaLevelsTriggerBtn?.play()
                risingSeaLevelsTriggerBtn?.addEventListener('click', () => {

                    this.risingSeaLevelsButtonHandler()
                    if (risingSeaLevelsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(risingSeaLevelsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (risingSeaLevelsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = risingSeaLevelsTriggerBtn
                    }
                    onAnimationStart(4000);
                });
                if (risingSeaLevelsTriggerBtn) 
                    this.buttonHolder.push(risingSeaLevelsTriggerBtn);
                
                const habitatChangesTriggerBtn = poolButtons.requestEntity()
                habitatChangesTriggerBtn?.setAttribute('position', '-0.02 -0.16 0.325')
                habitatChangesTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                habitatChangesTriggerBtn?.play()
                habitatChangesTriggerBtn?.addEventListener('click', () => {
                    this.habitatChangesButtonHandler()
                    if (habitatChangesTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(habitatChangesTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (habitatChangesTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = habitatChangesTriggerBtn
                    }
                    onAnimationStart(4200);
                });
                if (habitatChangesTriggerBtn)
                    this.buttonHolder.push(habitatChangesTriggerBtn);

                const lossOfTheCryosphereTriggerButton = poolButtons.requestEntity();
                lossOfTheCryosphereTriggerButton?.setAttribute('position', '0.1 0.31 0.17');
                lossOfTheCryosphereTriggerButton?.setAttribute('scale', '0.3 0.3 0.3');
                lossOfTheCryosphereTriggerButton?.play()
                lossOfTheCryosphereTriggerButton?.addEventListener('click', () => {
                    this.lossOfTheCryosphereButtonHandler()
                    if (lossOfTheCryosphereTriggerButton) {
                        this.annotationComponent.setObjectToFollow(lossOfTheCryosphereTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (lossOfTheCryosphereTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = lossOfTheCryosphereTriggerButton
                    }
                    onAnimationStart(5000);
                });
                if (lossOfTheCryosphereTriggerButton)
                    this.buttonHolder.push(lossOfTheCryosphereTriggerButton);

            };
        },
        tick(this: IClimateChangeSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
            TWEEN.update();
        },
    },
};
export {LessonStart as ClimateSceneComponent}
