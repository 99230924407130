import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface IAtomScene {
    poolEntity: AFrame.Entity;
    lithiumChloride: AFrame.Entity;
    sodiumOxide: AFrame.Entity;
    potassiumHydroxide: AFrame.Entity;
    lithiumChlorideHandler: () => void;
    sodiumOxideHandler: () => void;
    potassiumHydroxideHandler: () => void;
    lithiumChlorideMixer: THREE.AnimationMixer;
    sodiumOxideMixer: THREE.AnimationMixer;
    potassiumHydroxideMixer: THREE.AnimationMixer;
    currentAnimation: THREE.AnimationAction;
    currentRotateAnimation: THREE.AnimationAction;
    lithiumChlorideAnimation: THREE.AnimationAction;
    sodiumOxideAnimation: THREE.AnimationAction;
    potassiumHydroxideAnimation: THREE.AnimationAction;
    lithiumChlorideRotateAnimation: THREE.AnimationAction;
    sodiumOxideRotateAnimation: THREE.AnimationAction;
    potassiumHydroxideRotateAnimation: THREE.AnimationAction;
    annotationComponent: IAnnotationAframe;
    currentDeactivatedButton: AFrame.Entity;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    currentAssetId: number;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const AtomSceneComponent = {
    name: "atom-scene",
    val: {
        init(this: IAtomScene) {
            const holder = document.getElementById('holder') as AFrame.Entity;
            // Add 'model-loaded' event listener to the component
            holder.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                // ony initialise buttons once pool has loaded
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // moved button initialisation to lesson-start event
                // if initialising here, the buttons are not parenting to objects correctly
                // only applies to this lesson
            });


            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.lithiumChloride = document.getElementById('lithiumChloride') as AFrame.Entity
                this.sodiumOxide = document.getElementById('sodiumOxide') as AFrame.Entity
                this.potassiumHydroxide = document.getElementById('potassiumHydroxide') as AFrame.Entity
                this.annotationComponent.line.visible = false
                initialiseAnimations()
                if (!this.currentAssetId) {
                    this.currentAssetId = 0
                }
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

            });
            // add event listeners
            this.el.sceneEl?.addEventListener('anim-toggle', (e) => {
                const event = e as CustomEvent<{ toggle: boolean }>;
                if (event.detail.toggle) {
                    if (this.currentAnimation) this.currentAnimation.paused = false;
                    if (this.currentRotateAnimation) this.currentRotateAnimation.paused = false;
                } else {
                    if (this.currentAnimation) this.currentAnimation.paused = true;
                    if (this.currentRotateAnimation) this.currentRotateAnimation.paused = true;
                }
            });
            this.el.sceneEl?.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                this.currentAssetId = ce.detail.assetId;
                this.currentAnimation.stop()
                switch (this.currentAssetId) {
                    case 0:
                        this.lithiumChloride.object3D.visible = true
                        this.sodiumOxide.object3D.visible = false
                        this.potassiumHydroxide.object3D.visible = false
                        this.currentAnimation = this.lithiumChlorideAnimation
                        this.currentRotateAnimation = this.lithiumChlorideRotateAnimation
                        break;
                    case 1:
                        this.lithiumChloride.object3D.visible = false
                        this.sodiumOxide.object3D.visible = true
                        this.potassiumHydroxide.object3D.visible = false
                        this.currentAnimation = this.sodiumOxideAnimation
                        this.currentRotateAnimation = this.sodiumOxideRotateAnimation
                        break;
                    case 2:
                        this.lithiumChloride.object3D.visible = false
                        this.sodiumOxide.object3D.visible = false
                        this.potassiumHydroxide.object3D.visible = true
                        this.currentAnimation = this.potassiumHydroxideAnimation
                        this.currentRotateAnimation = this.potassiumHydroxideRotateAnimation
                        break;
                }
                this.currentRotateAnimation.stop()
                this.currentRotateAnimation.play()
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: holder; scale: 5 5 5; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });


            const initialiseAnimations = () => {
                const animLithiumChlorideEl = this.lithiumChloride.object3D.getObjectByName('Scene') as any;
                this.lithiumChlorideMixer = new THREE.AnimationMixer(animLithiumChlorideEl)
                const [LithiumChlorideRotate, LithiumChloride] = animLithiumChlorideEl.animations
                this.lithiumChlorideRotateAnimation = this.lithiumChlorideMixer.clipAction(LithiumChlorideRotate)
                this.lithiumChlorideAnimation = this.lithiumChlorideMixer.clipAction(LithiumChloride)
                this.lithiumChlorideRotateAnimation.play()

                const animSodiumOxideEl = this.sodiumOxide.object3D.getObjectByName('Scene') as any;
                this.sodiumOxideMixer = new THREE.AnimationMixer(animSodiumOxideEl)
                const [SodiumOxideRotate, SodiumOxide] = animSodiumOxideEl.animations
                this.sodiumOxideRotateAnimation = this.sodiumOxideMixer.clipAction(SodiumOxideRotate)
                this.sodiumOxideAnimation = this.sodiumOxideMixer.clipAction(SodiumOxide)
                this.sodiumOxideRotateAnimation.play()

                const animPotassiumHydroxideEl = this.potassiumHydroxide.object3D.getObjectByName('Scene') as any;
                this.potassiumHydroxideMixer = new THREE.AnimationMixer(animPotassiumHydroxideEl)
                const [PotassiumHydroxide, PotassiumHydroxideRotate] = animPotassiumHydroxideEl.animations
                this.potassiumHydroxideRotateAnimation = this.potassiumHydroxideMixer.clipAction(PotassiumHydroxideRotate)
                this.potassiumHydroxideAnimation = this.potassiumHydroxideMixer.clipAction(PotassiumHydroxide)
                this.potassiumHydroxideRotateAnimation.play()

                this.currentAnimation = this.lithiumChlorideAnimation
                this.currentRotateAnimation = this.lithiumChlorideRotateAnimation
            }

            this.lithiumChlorideHandler = () => {
                if (this.currentAnimation && !this.currentAnimation.isRunning()) {
                    this.currentAnimation.stop()
                    this.currentAnimation.play()
                }
                if (this.currentRotateAnimation && !this.currentRotateAnimation.isRunning()) {
                    this.currentRotateAnimation.stop()
                    this.currentRotateAnimation.play()
                }
                if (this.onObjectSelected) {
                    const title = 'Lithium Chloride';
                    const body = `You formed lithium chloride! The balanced equation for this reaction is: <div style='display: flex; flex-direction: row; font-size: 20px; width: 100%; text-align: center; justify-content: space-around; padding: 20px'> <div><b><div>2Li</div></b></div><div>+</div><div><b><div>Cl<sub>2</sub></div></b></div><div>→</div><div><b><div>2LiCl</div></b></div></div>`;
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.sodiumOxideHandler = () => {
                if (this.currentAnimation && !this.currentAnimation.isRunning()) {
                    this.currentAnimation.stop()
                    this.currentAnimation.play()
                }
                if (this.currentRotateAnimation && !this.currentRotateAnimation.isRunning()) {
                    this.currentRotateAnimation.stop()
                    this.currentRotateAnimation.play()
                }
                if (this.onObjectSelected) {
                    const title = 'Sodium Oxide';
                    const body = 'You formed sodium oxide! The balanced equation for this reaction is: <div style=\'display: flex; flex-direction: row; font-size: 20px; width: 100%; text-align: center; justify-content: space-around; padding: 20px\'> <div><b><div>4Na</div></b></div><div>+</div><div><b><div>O<sub>2</sub></div></b></div><div>→</div><div><b><div>2Na<sub>2</sub>O</div></b></div></div>';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.potassiumHydroxideHandler = () => {
                if (this.currentAnimation && !this.currentAnimation.isRunning()) {
                    this.currentAnimation.stop()
                    this.currentAnimation.play()
                }
                if (this.currentRotateAnimation && !this.currentRotateAnimation.isRunning()) {
                    this.currentRotateAnimation.stop()
                    this.currentRotateAnimation.play()
                }
                if (this.onObjectSelected) {
                    const title = 'Potassium Hydroxide';
                    const body = 'You formed potassium hydroxide! This reaction also produces hydrogen gas. The balanced equation for this reaction is: <div style=\'display: flex; flex-direction: row; font-size: 20px; width: 100%; text-align: center; justify-content: space-around; padding: 20px\'> <div><b><div>2K</div></b></div><div>+</div><div><b><div>2H<sub>2</sub>O</div></b></div><div>→</div><div><b><div>2KOH</div></b></div><div>+</div><div><b><div>H<sub>2</sub></div></b></div></div>';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }


            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const lithiumChlorideTriggerBtn = poolButtons.requestEntity()
                lithiumChlorideTriggerBtn?.setAttribute('position', '-0.045 0.035 0.1')
                lithiumChlorideTriggerBtn?.setAttribute('scale', '2 2 2')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        lithiumChlorideTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (this.currentAssetId === 1) {
                        lithiumChlorideTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        lithiumChlorideTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                lithiumChlorideTriggerBtn?.play()
                lithiumChlorideTriggerBtn?.addEventListener('click', () => {
                    this.lithiumChlorideHandler()
                    if (lithiumChlorideTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(lithiumChlorideTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (lithiumChlorideTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = lithiumChlorideTriggerBtn
                    }
                });
                const sodiumOxideTriggerButton = poolButtons.requestEntity();
                sodiumOxideTriggerButton?.setAttribute('position', '-0.245 0.035 0.1');
                sodiumOxideTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        sodiumOxideTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        sodiumOxideTriggerButton?.setAttribute('scale', '2 2 2')
                    }
                    if (this.currentAssetId === 2) {
                        sodiumOxideTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                sodiumOxideTriggerButton?.play()
                sodiumOxideTriggerButton?.addEventListener('click', () => {
                    this.sodiumOxideHandler()
                    if (sodiumOxideTriggerButton) {
                        this.annotationComponent.setObjectToFollow(sodiumOxideTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (sodiumOxideTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = sodiumOxideTriggerButton
                    }
                });
                const potassiumHydroxideTriggerButton = poolButtons.requestEntity();
                potassiumHydroxideTriggerButton?.setAttribute('position', '-0.045 0.035 0.1');
                potassiumHydroxideTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        potassiumHydroxideTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        potassiumHydroxideTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        potassiumHydroxideTriggerButton?.setAttribute('scale', '2 2 2')
                    }
                });
                potassiumHydroxideTriggerButton?.play()
                potassiumHydroxideTriggerButton?.addEventListener('click', () => {
                    this.potassiumHydroxideHandler()
                    if (potassiumHydroxideTriggerButton) {
                        this.annotationComponent.setObjectToFollow(potassiumHydroxideTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (potassiumHydroxideTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = potassiumHydroxideTriggerButton
                    }
                });

            };
        },
        tick(this: IAtomScene, time: number, timeDelta: number) {
            if (this.lithiumChlorideMixer) {
                this.lithiumChlorideMixer.update(timeDelta / 1000);
            }
            if (this.sodiumOxideMixer) {
                this.sodiumOxideMixer.update(timeDelta / 1000);
            }
            if (this.potassiumHydroxideMixer) {
                this.potassiumHydroxideMixer.update(timeDelta / 1000);
            }
        },
    }
}
export {AtomSceneComponent as AtomScene}
