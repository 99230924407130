import { alpha, createTheme, Theme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    get_started: true;
    primary_btn: true;
    purple_btn: true;
    primary_btn_large: true;
    contact_us_purple: true;
    go_to_courses: true;
    back_btn: true;
  }
}

export const theme: Theme = createTheme({
  palette: {
    text: {
      primary: alpha('#000', 0.7),
      secondary: alpha('#262626', 0.7),
      disabled: alpha('#fff', 0.5),
    },
    background: {
      default: '#fff',
    },
  },
  spacing: 10,
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: 'get_started',
          },
          style: {
            border: 'none',
            borderRadius: '6px',
            background: 'rgba(255, 253, 1, 1)',
            color: '#fff',
            fontSize: '16px',
            fontFamily: 'GilroySemiBold',
            textTransform: 'none',
            padding: '12px 20px',
            boxSizing: 'border-box',
            lineHeight: 1,
            marginLeft: '30px',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'rgba(255, 253, 1, 0.7)',
            },
          },
        },
        {
          props: {
            variant: 'primary_btn',
          },
          style: {
            border: 'none',
            borderRadius: '6px 6px 0 0',
            background: 'rgba(37, 204, 172, 1)',
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'GilroySemiBold',
            textTransform: 'none',
            padding: '25px 45px',
            boxSizing: 'border-box',
            lineHeight: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'rgba(37, 204, 172, 0.7)',
            },
          },
        },
        {
          props: {
            variant: 'primary_btn_large',
          },
          style: {
            border: 'none',
            borderRadius: '6px 6px 0 0',
            background: 'rgba(37, 204, 172, 1)',
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'GilroySemiBold',
            textTransform: 'none',
            padding: '25px 45px',
            boxSizing: 'border-box',
            lineHeight: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'rgba(37, 204, 172, 0.7)',
            },
          },
        },
        {
          props: {
            variant: 'purple_btn',
          },
          style: {
            border: 'none',
            borderRadius: '6px 6px 0 0',
            background: 'rgba(122, 108, 244, 1)',
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'GilroySemiBold',
            textTransform: 'none',
            padding: '25px 45px',
            boxSizing: 'border-box',
            lineHeight: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'rgba(122, 108, 244, 0.7)',
            },
          },
        },
        {
					props: {
						variant: 'contact_us_purple',
					},
					style: {
						borderRadius: 6,
						background: '#7A6CF4',
						color: '#fff',
						fontSize: '16px',
						height: '55px',
						boxSizing: 'border-box',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '18px 50px',
						textAlign: 'center',
						':hover': {
							backgroundColor: 'rgba(122, 108, 244, 0.7)',
						},
					},
				},
        {
					props: {
						variant: 'go_to_courses',
					},
					style: {
						borderRadius: '5px 5px 0 0',
						background: 'rgba(255, 253, 1, 1)',
						color: '#262626',
						fontSize: '16px',
						height: '55px',
						boxSizing: 'border-box',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '13px 20px',
						textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
						':hover': {
							backgroundColor: 'rgba(255, 253, 1, 0.7)',
						},
					},
				},
        {
					props: {
						variant: 'back_btn',
					},
					style: {
						width: '38px',
						height: '38px',
						minWidth: '38px',
						padding: 0,
						display: 'flex',
						alignItem: 'center',
						justifyContent: 'center'
					},
				},
      ],
    },
  },
});
