
import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IAnnotationSystemAframe } from "../../../lib/aframe/systems/annotation-system";
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IWaterMachineAframe } from './particle-effects';

interface IHouseControl {
    waterMachine: IWaterMachineAframe;
    actionWheels: THREE.AnimationAction;
    actionMeterWheel: THREE.AnimationAction;
    actionHeight: THREE.AnimationAction;
    actionLift: THREE.AnimationAction;
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    mixer: THREE.AnimationMixer;
    el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

const HouseControlComponent = {
    name: 'lesson-control',
    val: {
        init(this: IHouseControl) {
            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                initialiseAnimations();

                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}

                // fix all issues related to the current model
                fixModelIssues();
                fix2MAnimation();

            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.waterMachine = this.el.components['water-machine'] as unknown as IWaterMachineAframe;
                this.waterMachine.startSpawning();
                this.waterMachine.setVisible(true);
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: aqueduct; scale: 3.75 3.75 3.75; relativeRotation: 0 -90 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }

                    if (this.waterMachine) {
                        this.waterMachine.setVisible(false);
                    }
                }
            });

            const fix2MAnimation = () => {
                const model = document.getElementById('aqueduct') as AFrame.Entity;
                const obj = model?.object3D.getObjectByName('HeightArrows') as THREE.Object3D;
                obj.position.x -= 0.125;
            };

            const initialiseAnimations = () => {
                const model = document.getElementById('aqueduct') as AFrame.Entity;
                const animatedEl = model?.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [clipWheels, clipLift, clipHeight, clipMeterWheel] = animatedEl.animations
                this.actionWheels = this.mixer.clipAction(clipWheels)
                this.actionMeterWheel = this.mixer.clipAction(clipMeterWheel)
                this.actionHeight = this.mixer.clipAction(clipHeight)
                this.actionLift = this.mixer.clipAction(clipLift)
                // start animations when
                startAnimations();
            }

            const startAnimations = () => {
                // this.mixer.timeScale = 0.1;
                this.actionWheels.timeScale = 0.1;
                this.actionWheels.play();
                this.actionLift.timeScale = 0.1;
                this.actionLift.play();

                // just to put the object in the right position
                this.actionMeterWheel.reset();
                this.actionMeterWheel.timeScale = 0.5;
                this.actionMeterWheel.time = 0.3;
                this.actionMeterWheel.play();
                this.actionMeterWheel.paused = true;

                // setup loop for wheel action
                const loopStartTime = 2; // Specify the loop start time in seconds
                this.mixer.addEventListener('finished', (event) => {
                  if (event.action === this.actionMeterWheel) {
                    this.actionMeterWheel.reset();
                    this.actionMeterWheel.setLoop(THREE.LoopOnce, 1);
                    this.actionMeterWheel.time = loopStartTime;
                    this.actionMeterWheel.play(); // Restart the animation
                  }
                });
            };

            const liftButtonHandler = () => {
                const title = 'Water Lift'
                const body = "The water lift carries water against gravity from low potential energy (at the bottom) to high potential (at the top).<br/>Now the water can flow around the circuit and transfer it's energy.<br/>The water would not flow without the lift."

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const lowerWheelButtonHandler = () => {
                const title = 'Water Wheel'
                const body = "Potential energy from the height of the water is transferred into kinetic energy as water falls over the wheel. The wheel's kinetic energy could be used to do work in many useful ways."

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const testWheelButtonHandler = () => {
                
                // enable sprayButton if not enabled
                const title = 'Test Wheel'
                const body = 'Placing the test wheel into the water stream tells you about the rate of flow of water around the channels.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
                this.actionMeterWheel.reset();
                this.actionMeterWheel.time = 0.5;
                this.actionMeterWheel.setLoop(THREE.LoopOnce, 1)
                this.actionMeterWheel.play();

            }

            const aqueductButtonHandler = () => {
                const title = 'Aqueducts'
                const body = "Carried along in the aqueducts, the water travels around the closed circuit, falling over the water wheels. As the water drops, it transfers it's potential energy to the water wheels kinetic energy."

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
               
            }

            const upperWheelButtonHandler = () => {
                const title = 'Change in Height'
                const body = 'The difference in height before and after a component shows the difference in potential that is transferred to the component. The height changes over the components adds up to the height of the water lift.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }

                this.actionHeight.reset();
                this.actionHeight.timeScale = 4;
                this.actionHeight.repetitions = 1;
                this.actionHeight.clampWhenFinished = true;
                this.actionHeight.play();
            }

         
            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const liftTriggerBtn = poolButtons.requestEntity();
                liftTriggerBtn?.setAttribute('position', '-0.45 1.4 0.15');
                liftTriggerBtn?.play();
                liftTriggerBtn?.addEventListener('click', () => {
                    liftButtonHandler();
                    if (liftTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(liftTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(liftTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = liftTriggerBtn
                    }
                });

                const lowerWheelTriggerBtn = poolButtons.requestEntity()
                lowerWheelTriggerBtn?.setAttribute('position', '0.1 0.55 0.8')
                lowerWheelTriggerBtn?.play()
                lowerWheelTriggerBtn?.addEventListener('click', () => {
                    lowerWheelButtonHandler();
                    if (lowerWheelTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(lowerWheelTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(lowerWheelTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = lowerWheelTriggerBtn
                    }
                });

                const testWheelTriggerBtn = poolButtons.requestEntity()
                testWheelTriggerBtn?.setAttribute('position', '0.85 1 0.2')
                testWheelTriggerBtn?.play()
                testWheelTriggerBtn?.addEventListener('click', () => {
                    testWheelButtonHandler();
                    if (testWheelTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(testWheelTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(testWheelTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = testWheelTriggerBtn
                    }
                });

                const aqueductTriggerBtn = poolButtons.requestEntity()
                aqueductTriggerBtn?.setAttribute('position', '0.85 1 -0.35')
                aqueductTriggerBtn?.play()
                aqueductTriggerBtn?.addEventListener('click', () => {
                    aqueductButtonHandler();
                    if (aqueductTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(aqueductTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(aqueductTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = aqueductTriggerBtn
                    }
                });

                const upperWheelTriggerBtn = poolButtons.requestEntity()
                upperWheelTriggerBtn?.setAttribute('position', '0.18 1.1 -0.5')
                upperWheelTriggerBtn?.play()
                upperWheelTriggerBtn?.addEventListener('click', () => {
                    upperWheelButtonHandler();
                    if (upperWheelTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(upperWheelTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(upperWheelTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = upperWheelTriggerBtn
                    }
                });

            }

            const fixModelIssues = () => {
                // fix frustum culling bug
                const conveyor = this.el.object3D.getObjectByName('Conveyor') as THREE.Object3D;
                conveyor.traverse((child) => {
                    child.frustumCulled = false;
                });
            }
        },
        tick(this: IHouseControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }

        },
    },
};
export { HouseControlComponent }