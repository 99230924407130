import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    ammText: AFrame.Entity;
    voltText: AFrame.Entity;
    toggleSensor: AFrame.Entity;
    switchToggle: boolean;
    wireFirstMixer: THREE.AnimationMixer;
    wireSecondMixer: THREE.AnimationMixer;
    wireThirdMixer: THREE.AnimationMixer;
    wireFourthMixer: THREE.AnimationMixer;
    wireFifthMixer: THREE.AnimationMixer;
    wireSixthMixer: THREE.AnimationMixer;
    wireSeventhMixer: THREE.AnimationMixer;
    wireEightMixer: THREE.AnimationMixer;
    wireNinthMixer: THREE.AnimationMixer;
    pageMixer: THREE.AnimationMixer;
    switchMixer: THREE.AnimationMixer;
    wireFirstAnimation: THREE.AnimationAction;
    wireSecondAnimation: THREE.AnimationAction;
    wireThirdAnimation: THREE.AnimationAction;
    wireFourthAnimation: THREE.AnimationAction;
    wireFifthAnimation: THREE.AnimationAction;
    wireSixthAnimation: THREE.AnimationAction;
    wireSeventhAnimation: THREE.AnimationAction;
    wireEightAnimation: THREE.AnimationAction;
    wireNinthAnimation: THREE.AnimationAction;
    firstPageAnimation: THREE.AnimationAction;
    secondPageAnimation: THREE.AnimationAction;
    thirdPageAnimation: THREE.AnimationAction;
    forthPageAnimation: THREE.AnimationAction;
    fifthPageAnimation: THREE.AnimationAction;
    sixthPageAnimation: THREE.AnimationAction;
    seventhPageAnimation: THREE.AnimationAction;
    eightPageAnimation: THREE.AnimationAction;
    ninthPageAnimation: THREE.AnimationAction;
    switchAnimation: THREE.AnimationAction;
    prevAnimation: THREE.AnimationAction;
    currentDeactivatedButton: AFrame.Entity;
    poolEntity: AFrame.Entity;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    el: AFrame.Entity;
    circuit: AFrame.Entity;
    book: AFrame.Entity;
    sliderValue: string;
    prevValue: string;
    prevTimeout: NodeJS.Timeout;
    screensTimeout: NodeJS.Timeout;
    switchTimeout: NodeJS.Timeout;
    distanceEvent: CustomEvent;
    plug: THREE.Mesh;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            const holder = document.getElementById('holder') as AFrame.Entity;
            holder.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });
            const circuit = document.getElementById('circuit') as AFrame.Entity;
            circuit.addEventListener('model-loaded', () => {
                console.log(circuit.object3D)
                this.circuit = document.getElementById('circuit') as AFrame.Entity;
                initialiseCircuitAnimations()
                setVoltmeterDigits()
                setAmmeterDigits()

                const geometry = new THREE.CircleGeometry(1, 32);
                const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
                this.plug = new THREE.Mesh(geometry, material);
                this.plug.scale.set(0.006, 0.006, 0.006)
                this.plug.position.set(-0.0315, 0.0444, 0.0457)
                const obj = circuit.object3D.getObjectByName('Physics7_Resistance_Circuit_V2') as THREE.Object3D;
                obj.add(this.plug);

            });
            const book = document.getElementById('book') as AFrame.Entity;
            book.addEventListener('model-loaded', () => {
                this.book = document.getElementById('book') as AFrame.Entity;
                initialiseBookAnimations()
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: holder; scale: 15 15 15; offset: 0 0 -4');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    this.el.sceneEl?.appendChild(ring);
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        this.annotationComponent.deactivate();
                    }
                }
            });

            this.el.sceneEl?.addEventListener('slider-move', (e) => {
                const ce = e as CustomEvent;
                this.sliderValue = ce.detail.value;
                if (this.circuit && this.book) {
                    if (!this.switchToggle) {
                        this.switchToggle = true
                        this.switchAnimation.stop()
                        this.switchAnimation.repetitions = 1
                        this.switchAnimation.play()
                        setTimeout(() => {
                            this.switchAnimation.paused = true
                        }, (this.switchAnimation.getClip().duration * 1000) / 2)
                    }
                    this.plug.visible = true
                    clearTimeout(this.screensTimeout);
                    if (this.prevAnimation) {
                        this.prevAnimation.stop();
                        this.prevAnimation.paused = false;
                    }
                    if (this.prevTimeout) {
                        clearTimeout(this.prevTimeout);
                    }
                    switch (this.sliderValue) {
                        case `0.1`:
                            if (this.prevValue === '0.2') {
                                this.wireFirstAnimation.stop()
                                this.wireFirstAnimation.timeScale = -1
                                this.wireFirstAnimation.repetitions = 1
                                this.wireFirstAnimation.clampWhenFinished = true
                                this.wireFirstAnimation.play()

                                this.firstPageAnimation.timeScale = -1
                                this.firstPageAnimation.repetitions = 1
                                this.firstPageAnimation.clampWhenFinished = true
                                this.firstPageAnimation.play()

                                this.prevAnimation = this.firstPageAnimation
                            } else {
                                this.wireFirstAnimation.timeScale = 1
                                this.wireFirstAnimation.play()
                                this.wireFirstAnimation.paused = true

                                this.firstPageAnimation.timeScale = 1
                                this.firstPageAnimation.play()
                                this.firstPageAnimation.paused = true

                                this.prevAnimation = this.firstPageAnimation
                            }
                            this.prevValue = '0.1'
                            updateCircuitDynamicValues('4.3', '4.3')
                            break;
                        case `0.2`:
                            if (this.prevValue === '0.3') {
                                this.wireSecondAnimation.stop()
                                this.wireSecondAnimation.timeScale = -1
                                this.wireSecondAnimation.repetitions = 1
                                this.wireSecondAnimation.clampWhenFinished = true
                                this.wireSecondAnimation.play()

                                this.secondPageAnimation.timeScale = -1
                                this.secondPageAnimation.repetitions = 1
                                this.secondPageAnimation.clampWhenFinished = true
                                this.secondPageAnimation.play()

                                this.prevAnimation = this.secondPageAnimation
                            } else {
                                this.wireFirstAnimation.stop()
                                this.wireFirstAnimation.timeScale = 1
                                this.wireFirstAnimation.repetitions = 1
                                this.wireFirstAnimation.clampWhenFinished = true
                                this.wireFirstAnimation.play()

                                this.firstPageAnimation.timeScale = 1
                                this.firstPageAnimation.repetitions = 1
                                this.firstPageAnimation.clampWhenFinished = true
                                this.firstPageAnimation.play()

                                this.prevAnimation = this.firstPageAnimation
                            }
                            this.prevValue = '0.2'
                            updateCircuitDynamicValues('5', '2.5')
                            break;
                        case `0.3`:
                            if (this.prevValue === '0.4') {
                                this.wireThirdAnimation.stop()
                                this.wireThirdAnimation.timeScale = -1
                                this.wireThirdAnimation.repetitions = 1
                                this.wireThirdAnimation.clampWhenFinished = true
                                this.wireThirdAnimation.play()

                                this.thirdPageAnimation.timeScale = -1
                                this.thirdPageAnimation.repetitions = 1
                                this.thirdPageAnimation.clampWhenFinished = true
                                this.thirdPageAnimation.play()

                                this.prevAnimation = this.thirdPageAnimation
                            } else {
                                this.wireSecondAnimation.stop()
                                this.wireSecondAnimation.timeScale = 1
                                this.wireSecondAnimation.repetitions = 1
                                this.wireSecondAnimation.clampWhenFinished = true
                                this.wireSecondAnimation.play()

                                this.secondPageAnimation.timeScale = 1
                                this.secondPageAnimation.repetitions = 1
                                this.secondPageAnimation.clampWhenFinished = true
                                this.secondPageAnimation.play()

                                this.prevAnimation = this.secondPageAnimation
                            }
                            this.prevValue = '0.3'
                            updateCircuitDynamicValues('5.3', '1.8')
                            break;
                        case `0.4`:
                            if (this.prevValue === '0.5') {
                                this.wireFourthAnimation.stop()
                                this.wireFourthAnimation.timeScale = -1
                                this.wireFourthAnimation.repetitions = 1
                                this.wireFourthAnimation.clampWhenFinished = true
                                this.wireFourthAnimation.play()

                                this.forthPageAnimation.timeScale = -1
                                this.forthPageAnimation.repetitions = 1
                                this.forthPageAnimation.clampWhenFinished = true
                                this.forthPageAnimation.play()

                                this.prevAnimation = this.forthPageAnimation
                            } else {
                                this.wireThirdAnimation.stop()
                                this.wireThirdAnimation.timeScale = 1
                                this.wireThirdAnimation.repetitions = 1
                                this.wireThirdAnimation.clampWhenFinished = true
                                this.wireThirdAnimation.play()

                                this.thirdPageAnimation.timeScale = 1
                                this.thirdPageAnimation.repetitions = 1
                                this.thirdPageAnimation.clampWhenFinished = true
                                this.thirdPageAnimation.play()

                                this.prevAnimation = this.thirdPageAnimation
                            }
                            this.prevValue = '0.4'
                            updateCircuitDynamicValues('5.5', '1.4')
                            break;
                        case `0.5`:
                            if (this.prevValue === '0.6') {
                                this.wireFifthAnimation.stop()
                                this.wireFifthAnimation.timeScale = -1
                                this.wireFifthAnimation.repetitions = 1
                                this.wireFifthAnimation.clampWhenFinished = true
                                this.wireFifthAnimation.play()

                                this.fifthPageAnimation.timeScale = -1
                                this.fifthPageAnimation.repetitions = 1
                                this.fifthPageAnimation.clampWhenFinished = true
                                this.fifthPageAnimation.play()

                                this.prevAnimation = this.fifthPageAnimation
                            } else {
                                this.wireFourthAnimation.stop()
                                this.wireFourthAnimation.timeScale = 1
                                this.wireFourthAnimation.repetitions = 1
                                this.wireFourthAnimation.clampWhenFinished = true
                                this.wireFourthAnimation.play()

                                this.forthPageAnimation.timeScale = 1
                                this.forthPageAnimation.repetitions = 1
                                this.forthPageAnimation.clampWhenFinished = true
                                this.forthPageAnimation.play()

                                this.prevAnimation = this.forthPageAnimation
                            }
                            this.prevValue = '0.5'
                            updateCircuitDynamicValues('5.6', '1.1')
                            break;
                        case `0.6`:
                            if (this.prevValue === '0.7') {
                                this.wireSixthAnimation.stop()
                                this.wireSixthAnimation.timeScale = -1
                                this.wireSixthAnimation.repetitions = 1
                                this.wireSixthAnimation.clampWhenFinished = true
                                this.wireSixthAnimation.play()

                                this.sixthPageAnimation.timeScale = -1
                                this.sixthPageAnimation.repetitions = 1
                                this.sixthPageAnimation.clampWhenFinished = true
                                this.sixthPageAnimation.play()

                                this.prevAnimation = this.sixthPageAnimation
                            } else {
                                this.wireFifthAnimation.stop()
                                this.wireFifthAnimation.timeScale = 1
                                this.wireFifthAnimation.repetitions = 1
                                this.wireFifthAnimation.clampWhenFinished = true
                                this.wireFifthAnimation.play()

                                this.fifthPageAnimation.timeScale = 1
                                this.fifthPageAnimation.repetitions = 1
                                this.fifthPageAnimation.clampWhenFinished = true
                                this.fifthPageAnimation.play()

                                this.prevAnimation = this.fifthPageAnimation
                            }
                            this.prevValue = '0.6'
                            updateCircuitDynamicValues('5.6', '0.9')
                            break;
                        case `0.7`:
                            if (this.prevValue === '0.8') {
                                this.wireSeventhAnimation.stop()
                                this.wireSeventhAnimation.timeScale = -1
                                this.wireSeventhAnimation.repetitions = 1
                                this.wireSeventhAnimation.clampWhenFinished = true
                                this.wireSeventhAnimation.play()

                                this.seventhPageAnimation.timeScale = -1
                                this.seventhPageAnimation.repetitions = 1
                                this.seventhPageAnimation.clampWhenFinished = true
                                this.seventhPageAnimation.play()

                                this.prevAnimation = this.seventhPageAnimation
                            } else {
                                this.wireSixthAnimation.stop()
                                this.wireSixthAnimation.timeScale = 1
                                this.wireSixthAnimation.repetitions = 1
                                this.wireSixthAnimation.clampWhenFinished = true
                                this.wireSixthAnimation.play()

                                this.sixthPageAnimation.timeScale = 1
                                this.sixthPageAnimation.repetitions = 1
                                this.sixthPageAnimation.clampWhenFinished = true
                                this.sixthPageAnimation.play()

                                this.prevAnimation = this.sixthPageAnimation
                            }
                            this.prevValue = '0.7'
                            updateCircuitDynamicValues('5.7', '0.8')
                            break;
                        case `0.8`:
                            if (this.prevValue === '0.9') {
                                this.wireEightAnimation.stop()
                                this.wireEightAnimation.timeScale = -1
                                this.wireEightAnimation.repetitions = 1
                                this.wireEightAnimation.clampWhenFinished = true
                                this.wireEightAnimation.play()

                                this.eightPageAnimation.timeScale = -1
                                this.eightPageAnimation.repetitions = 1
                                this.eightPageAnimation.clampWhenFinished = true
                                this.eightPageAnimation.play()

                                this.prevAnimation = this.eightPageAnimation
                            } else {
                                this.wireSeventhAnimation.stop()
                                this.wireSeventhAnimation.timeScale = 1
                                this.wireSeventhAnimation.repetitions = 1
                                this.wireSeventhAnimation.clampWhenFinished = true
                                this.wireSeventhAnimation.play()

                                this.seventhPageAnimation.timeScale = 1
                                this.seventhPageAnimation.repetitions = 1
                                this.seventhPageAnimation.clampWhenFinished = true
                                this.seventhPageAnimation.play()

                                this.prevAnimation = this.seventhPageAnimation
                            }
                            this.prevValue = '0.8'
                            updateCircuitDynamicValues('5.7', '0.7')
                            break;
                        case `0.9`:
                            if (this.prevValue === '1') {
                                this.wireNinthAnimation.stop()
                                this.wireNinthAnimation.timeScale = -1
                                this.wireNinthAnimation.repetitions = 1
                                this.wireNinthAnimation.clampWhenFinished = true
                                this.wireNinthAnimation.play()

                                this.ninthPageAnimation.timeScale = -1
                                this.ninthPageAnimation.repetitions = 1
                                this.ninthPageAnimation.clampWhenFinished = true
                                this.ninthPageAnimation.play()

                                this.prevAnimation = this.ninthPageAnimation
                            } else {
                                this.wireEightAnimation.stop()
                                this.wireEightAnimation.timeScale = 1
                                this.wireEightAnimation.repetitions = 1
                                this.wireEightAnimation.clampWhenFinished = true
                                this.wireEightAnimation.play()

                                this.eightPageAnimation.timeScale = 1
                                this.eightPageAnimation.repetitions = 1
                                this.eightPageAnimation.clampWhenFinished = true
                                this.eightPageAnimation.play()

                                this.prevAnimation = this.eightPageAnimation
                            }
                            this.prevValue = '0.9'
                            updateCircuitDynamicValues('5.7', '0.6')
                            break;
                        case `1`:
                            this.wireNinthAnimation.stop()
                            this.wireNinthAnimation.timeScale = 1
                            this.wireNinthAnimation.repetitions = 1
                            this.wireNinthAnimation.clampWhenFinished = true
                            this.wireNinthAnimation.play()

                            this.ninthPageAnimation.timeScale = 1
                            this.ninthPageAnimation.repetitions = 1
                            this.ninthPageAnimation.clampWhenFinished = true
                            this.ninthPageAnimation.play()

                            this.prevAnimation = this.ninthPageAnimation
                            this.prevValue = '1'
                            updateCircuitDynamicValues('5.8', '0.6',)
                            break;
                    }
                }
            });
            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const systematicErrorTriggerBtn = poolButtons.requestEntity();
                if (systematicErrorTriggerBtn) {
                    systematicErrorTriggerBtn.setAttribute('position', '0.25 0.065 0.18');
                    systematicErrorTriggerBtn.play();
                    systematicErrorTriggerBtn.addEventListener('click', () => {
                        // activate annotation
                        if (this.onObjectSelected) {
                            const title = 'Systematic Error'
                            const body = 'When doing this experiment your line of best fit may not go through 0. This could be due to the position of the clips slightly different to where they’re recorded to be or there being other large resistances in the circuit. This is a <b>systematic error</b>.'
                            this.onObjectSelected({title, body})
                            this.annotationComponent.setObjectToFollow(systematicErrorTriggerBtn);
                        } else {
                            console.log('No object selected method')
                        }
                        // deactivate previous button
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (systematicErrorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = systematicErrorTriggerBtn

                    });
                } else {
                    console.log('No trigger btn');
                }
            };
            const setVoltmeterDigits = () => {
                if (!this.voltText) {
                    const voltText = document.createElement('a-entity')
                    voltText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    voltText.setAttribute('position', '0.325 0.02 0.25');
                    voltText.setAttribute('scale', '0.1 0.1 0.1');
                    voltText.setAttribute('rotation', '-90 0 0');
                    voltText.setAttribute('visible', true);
                    this.el.appendChild(voltText);
                    this.voltText = voltText;
                }
            }
            const setAmmeterDigits = () => {
                if (!this.ammText) {
                    const ammText = document.createElement('a-entity')
                    ammText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    ammText.setAttribute('position', '0.56 0.02 0.25');
                    ammText.setAttribute('scale', '0.1 0.1 0.1');
                    ammText.setAttribute('rotation', '-90 0 0');
                    ammText.setAttribute('visible', true);
                    this.el.appendChild(ammText);
                    this.ammText = ammText;
                }
            }
            const initialiseCircuitAnimations = () => {
                const circuit = document.getElementById('circuit') as AFrame.Entity;
                circuit.object3D.traverse(child => {child.animations.length > 0 && console.log(child.name)})
                const animatedWireEl = circuit.object3D.getObjectByName('Physics7_Resistance_Circuit_V2') as any;
                this.wireFirstMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireSecondMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireThirdMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireFourthMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireFifthMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireSixthMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireSeventhMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireEightMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireNinthMixer = new THREE.AnimationMixer(animatedWireEl)
                this.switchMixer = new THREE.AnimationMixer(animatedWireEl)
                const [Clip10to20, Clip20to30, Clip30to40, Clip40to50, Clip50to60, Clip60to70, Clip70to80, Clip80to90, Clip90to100, OnOffSwitch] = animatedWireEl.animations
                this.wireFirstAnimation = this.wireFirstMixer.clipAction(Clip10to20)
                this.wireSecondAnimation = this.wireSecondMixer.clipAction(Clip20to30)
                this.wireThirdAnimation = this.wireThirdMixer.clipAction(Clip30to40)
                this.wireFourthAnimation = this.wireFourthMixer.clipAction(Clip40to50)
                this.wireFifthAnimation = this.wireFifthMixer.clipAction(Clip50to60)
                this.wireSixthAnimation = this.wireSixthMixer.clipAction(Clip60to70)
                this.wireSeventhAnimation = this.wireSeventhMixer.clipAction(Clip70to80)
                this.wireEightAnimation = this.wireEightMixer.clipAction(Clip80to90)
                this.wireNinthAnimation = this.wireNinthMixer.clipAction(Clip90to100)
                this.switchAnimation = this.switchMixer.clipAction(OnOffSwitch)
                this.switchAnimation.setLoop(THREE.LoopPingPong, 1);
                this.wireFirstAnimation.timeScale = 1
                this.wireFirstAnimation.play()
                this.wireFirstAnimation.paused = true
            }
            const initialiseBookAnimations = () => {
                const book = document.getElementById('book') as AFrame.Entity;
                const animatedPageEl = book.object3D.getObjectByName('Scene') as any;
                this.pageMixer = new THREE.AnimationMixer(animatedPageEl)
                const [TurnPage1, TurnPage2, TurnPage3, TurnPage4, TurnPage5, TurnPage6, TurnPage7, TurnPage8, TurnPage9] = animatedPageEl.animations
                this.firstPageAnimation = this.pageMixer.clipAction(TurnPage1)
                this.secondPageAnimation = this.pageMixer.clipAction(TurnPage2)
                this.thirdPageAnimation = this.pageMixer.clipAction(TurnPage3)
                this.forthPageAnimation = this.pageMixer.clipAction(TurnPage4)
                this.fifthPageAnimation = this.pageMixer.clipAction(TurnPage5)
                this.sixthPageAnimation = this.pageMixer.clipAction(TurnPage6)
                this.seventhPageAnimation = this.pageMixer.clipAction(TurnPage7)
                this.eightPageAnimation = this.pageMixer.clipAction(TurnPage8)
                this.ninthPageAnimation = this.pageMixer.clipAction(TurnPage9)
            }
            const updateCircuitDynamicValues = (voltValue: string, ammValue: string) => {
                this.voltText.setAttribute('text', "value:; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                this.voltText.setAttribute('position', '0.383 0.02 0.3');
                this.ammText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                this.ammText.setAttribute('position', '0.56 0.02 0.3');
                this.screensTimeout = setTimeout(() => {
                    this.plug.visible = false
                    this.switchAnimation.paused = false
                    this.switchToggle = false
                    this.voltText.setAttribute('text', `value: ${voltValue}; color: #8ad1a9; align: left; width: 10; wrap-count: 50;`);
                    this.voltText.setAttribute('position', '0.383 0.02 0.3');
                    this.ammText.setAttribute('text', `value: ${ammValue}; color: #8ad1a9; align: left; width: 10; wrap-count: 50;`);
                    this.ammText.setAttribute('position', '0.56 0.02 0.3');
                }, 500)
            }
        },
        tick(this: ICircuitControl, time: number, deltaTime: number) {
            const ring = document.getElementById('ring')
            if(!ring){
                const circuit = document.getElementById('holder') as AFrame.Entity;
                const cameraEl = this.el.sceneEl?.querySelector('a-camera');
                if (cameraEl && circuit) {
                    const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                    let cameraPosition = camPos.clone()
                    let spherePos = circuit.object3D.position.clone()
                    let distance = cameraPosition.distanceTo(spherePos)
                    if (!this.distanceEvent) {
                        this.distanceEvent = new CustomEvent('distance-change', {detail: {value: false}});
                    }
                    if ((distance < 6 && cameraPosition.x < 3) || (distance < 8 && cameraPosition.x > 3) || (distance < 10 && cameraPosition.x > 5)) {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = true
                        scene.emit('distance-change',this.distanceEvent.detail.value);
                        circuit.object3D.visible = false
                    } else {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = false
                        scene.emit('distance-change',this.distanceEvent.detail.value);
                        if (!circuit.object3D.visible){
                            circuit.object3D.visible = true
                        }
                    }
                }
            }
            if (this.switchMixer) {
                this.switchMixer.update(deltaTime * 0.001);
            }
            if (this.wireFirstMixer) {
                this.wireFirstMixer.update(deltaTime * 0.001);
            }
            if (this.wireSecondMixer) {
                this.wireSecondMixer.update(deltaTime * 0.001);
            }
            if (this.wireThirdMixer) {
                this.wireThirdMixer.update(deltaTime * 0.001);
            }
            if (this.wireFourthMixer) {
                this.wireFourthMixer.update(deltaTime * 0.001);
            }
            if (this.wireFifthMixer) {
                this.wireFifthMixer.update(deltaTime * 0.001);
            }
            if (this.wireSixthMixer) {
                this.wireSixthMixer.update(deltaTime * 0.001);
            }
            if (this.wireSeventhMixer) {
                this.wireSeventhMixer.update(deltaTime * 0.001);
            }
            if (this.wireEightMixer) {
                this.wireEightMixer.update(deltaTime * 0.001);
            }
            if (this.wireNinthMixer) {
                this.wireNinthMixer.update(deltaTime * 0.001);
            }
            if (this.pageMixer) {
                this.pageMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {CircuitControlComponent}
