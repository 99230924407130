import React from 'react';
import '../styles/tapPlaceScreen.css';

interface TapPlaceScreenComponentProps {
  visible: boolean;
  onTap?: () => void;
}

const TapPlaceScreenComponent: React.FC<TapPlaceScreenComponentProps> = ({ visible, onTap }) => {
  if (!visible) return null;

  return (
    <div className="tap-place-screen" onClick={onTap}>
      <button className="tap-place-button">Tap To Start</button>
    </div>
  );
};

export default TapPlaceScreenComponent;
