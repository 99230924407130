// src/components/AssetToggleListComponent.tsx
import React, { useState } from 'react';

interface AssetToggleListComponentProps {
  icons: string[]; // This should be an array of paired icons, e.g., [icon0, icon1, icon2, icon3, ...]
  onAssetToggleClick: (assetId: number) => void;
  assetSelectionOn: boolean;
  selectedAsset: number;
}

const AssetToggleListComponent: React.FC<AssetToggleListComponentProps> = ({ icons, onAssetToggleClick, assetSelectionOn, selectedAsset }) => {
  const [toggledAssets, setToggledAssets] = useState<number[]>([]);

  const renderAssetToggle = (iconSrc: string, index: number) => {
    const assetId = Math.floor(index / 2);
    const isToggled = toggledAssets.includes(assetId);
    const handleClick = () => {
      onAssetToggleClick(assetId);
      setToggledAssets((prevToggledAssets) => {
        if (isToggled) {
          return prevToggledAssets.filter((asset) => asset !== assetId);
        } else {
          return [...prevToggledAssets, assetId];
        }
      });
    };

    if (assetSelectionOn && (index % 2 === 0) !== isToggled) {
      return (
        <div key={index} className="menuItem">
          <img
            src={iconSrc}
            alt={`Icon ${index + 1}`}
            onClick={handleClick}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className='assetToggleList'>
      {icons.map((iconSrc, index) => renderAssetToggle(iconSrc, index))}
    </div>
  );
};

export default AssetToggleListComponent;
