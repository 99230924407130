import * as AFrame from 'aframe'
import * as THREE from 'three'

export interface ILenseRaycast {
    activeButton: { name: string; object: THREE.Object3D; callback: () => void; } | null;
    btnData: { name: string; object: THREE.Object3D, callback: () => void }[];
    isMaskActive: boolean;
    atoms: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    maskHolder: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    tempVectorLense: THREE.Vector3;
    lenseSphere: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    line: THREE.Line<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.LineBasicMaterial>;
    cameraElement: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    tempVectorCam: THREE.Vector3;
    raycastStarted: boolean;
    raycaster: THREE.Raycaster;
    raycastPlane: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    cameraSphere: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    el: AFrame.Entity;

    doRaycast: () => void;
    setMaskingActive: (value: boolean) => void;
    addRaycastButton: (btnName: string, btnObj: THREE.Object3D, btnCallback: () => void) => void;
    activateButton: (btnName: string) => void;
}

const lenseRaycastComponent = {
    name: "lense-raycaster",
    val: {
        init(this: ILenseRaycast) {
            this.cameraElement = document.querySelector('#camera') as AFrame.Entity;
      
            // camera sphere is an object attached to the camera to render the line between screen and world space object
            this.cameraSphere = document.createElement('a-sphere');
            this.cameraSphere.setAttribute('radius', 0.02);
            this.cameraSphere.setAttribute('material', { shader: 'flat', color: '#00FF00', alphaTest: 0.5, transparent: true, opacity: 1 });
            this.cameraSphere.setAttribute('segments-height', 12);
            this.cameraSphere.setAttribute('segments-width', 12);
            this.cameraSphere.setAttribute('position', '0 -0.445 -2');
            this.cameraSphere.setAttribute('visible', 'false');

            if (this.cameraElement) {
              this.cameraElement.appendChild(this.cameraSphere)
              // cameraElement.appendChild(this.cameraSphere2)
            } else {
              console.error('No camera element found')
            }

            // raycast target as a sphere to calculate raycast direction
            this.lenseSphere = document.getElementById('lenseSphere') as AFrame.Entity;


            // raycast hit target
            this.raycastPlane = document.getElementById('raycastTarget') as AFrame.Entity;
            
            this.raycaster = new THREE.Raycaster();
            this.tempVectorCam = new THREE.Vector3();
            this.tempVectorLense = new THREE.Vector3();

            this.maskHolder = document.getElementById('maskHolder') as AFrame.Entity;
            this.atoms = document.getElementById('atoms') as AFrame.Entity;
            this.isMaskActive = false;

            this.btnData = [];

            this.raycastStarted = true;
           
        },
        doRaycast(this: ILenseRaycast) {
            
            //racast origin is the camera sphere world position
            this.cameraSphere.object3D.getWorldPosition(this.tempVectorCam);

            // calculate lense sphere world position
            this.lenseSphere.object3D.getWorldPosition(this.tempVectorLense);
            
            // the raycast
            this.raycaster.set(this.tempVectorCam, this.tempVectorLense.sub(this.tempVectorCam).normalize());
            const intersects = this.raycaster.intersectObject(this.raycastPlane.object3D, true)
            if (intersects.length > 0 && !this.isMaskActive) {
                // if there's an intersection and mask is not active
                this.setMaskingActive(true);
            } else if (intersects.length === 0 && this.isMaskActive) {
                // if no intersection but mask is active, deactivate it
                this.setMaskingActive(false);
            };

            // do raycasting for buttons
            if (this.activeButton) {
                this.activeButton.object.getWorldPosition(this.tempVectorLense);
                this.raycaster.set(this.tempVectorCam, this.tempVectorLense.sub(this.tempVectorCam).normalize());
                const intersects = this.raycaster.intersectObject(this.raycastPlane.object3D, true);
                if (intersects.length === 0) {
                    this.activeButton.callback();
                    this.activeButton = null;
                }
            };

        },
        setMaskingActive(this: ILenseRaycast, value: boolean) {
            this.maskHolder.setAttribute('visible', value);
            this.atoms.setAttribute('visible', value);
            this.isMaskActive = value;
        },
        addRaycastButton(this: ILenseRaycast, btnName: string, btnObj: THREE.Object3D, btnCallback: () => void) {
            this.btnData.push({ name: btnName, object: btnObj, callback: btnCallback });
        },
        activateButton(this: ILenseRaycast, btnName: string) {
            const btn = this.btnData.find((b) => b.name === btnName);
            if (btn) {
                this.activeButton = btn;
            }
        },
        tick(this: ILenseRaycast) {
            if (this.raycastStarted) {
                this.doRaycast();
            }
            
        },
    },
}
export {lenseRaycastComponent as lenseRaycaster}