// src/components/Menu.tsx
import React, {useState} from 'react';
import IconButton from './icon-button';
import flashcardIcon from '../../assets/img/book_icon.png';
import IconPanel from './icon-panel';
import quizIcon from '../../assets/img/q.png';
import recenterIcon from '../../assets/img/crosshair.svg';
import play from '../../assets/img/play_circle.png';
import stop from '../../assets/img/stop_circle.png';

import boxIcon from '../../assets/img/box.png';
import HomeButton from './home-button';
import HintButton from './hint-button';

interface SideMenuProps {
  onMenuButtonClick: () => void;
  onQuizButtonClick: () => void;
  onRecenterButtonClick: () => void;
  onHintButtonClick: () => void;
  assetSelectionActive: boolean;
  onAssetSelectionToggle: () => void;
  onAssetButtonClick?: (assetId: number) => void;
  onAssetToggleClick?: (assetId: number) => void;
  onToggleAnimation?: (toggle: boolean) => void;
  toggleAnimationStatus?: boolean;
  assetIcons?: string[];
  lessonStarted?: boolean;
  topPosition?: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({topPosition, onMenuButtonClick, toggleAnimationStatus, onToggleAnimation, onQuizButtonClick, onRecenterButtonClick, onHintButtonClick, assetSelectionActive, onAssetSelectionToggle, onAssetButtonClick, onAssetToggleClick, assetIcons, lessonStarted }) => {
  const [toggleIcon, setToggleIcon] = useState(play)
  React.useEffect(() => {
    if (!toggleAnimationStatus) {
      setToggleIcon(play)
    } else {
      setToggleIcon(stop)
    }
  }, [toggleAnimationStatus]);
  const icons = [recenterIcon, toggleIcon, quizIcon];
  // if asset icons are provided, add asset list trigger icon, then add asset icons to the list of icons
  if (assetIcons) {
    icons.push(boxIcon);
    icons.push(...assetIcons);
  }
  return (
    <div className={topPosition ? 'topPosition' : void 0}>
      <IconButton imgSrc={flashcardIcon} onClick={onMenuButtonClick} />
      <IconPanel icons={icons}
                  onRecenterIconClick={onRecenterButtonClick}
                  onQuizIconClick={onQuizButtonClick}
                  assetSelectionActive={assetSelectionActive}
                  onAssetSelectionToggle={onAssetSelectionToggle}
                  onToggleAnimation={onToggleAnimation}
                  onAssetButtonClick={onAssetButtonClick}
                  onAssetToggleClick={onAssetToggleClick}
                  lessonStarted={lessonStarted}/>
      <HomeButton/>
      <HintButton showHintEvent={onHintButtonClick}/>
    </div>
  );
};

export default SideMenu;
