import { useEffect, useRef, useState } from 'react';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { StyledVideo } from './StyledVideo';
import { IconImage } from './IconImage';
import { useTranslation } from 'react-i18next';
import { DisplayEnum, LanguageEnum, RoleEnum } from '../enums';
import { useSharedContext } from '../context';

import TeacherUK from '../../assets/video/teacher-UK.mp4';
import TeacherUS from '../../assets/video/teacher-US.mp4';
import StudentUK from '../../assets/video/student-UK.mp4';
import StudentUS from '../../assets/video/student-US.mp4';
import VideoTeacherStub from '../../assets/Video_Teacher_Stub.jpg';
import VideoStudentStub from '../../assets/Video_Student_Stub.jpg';
import Play from '../../assets/Play_btn.svg';
import Pause from '../../assets/Pause_btn.svg';

export function VideoPlayer() {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showStub, setShowStub] = useState(true);
  const [showControls, setShowControls] = useState(true);
  const [{ roleType }] = useSharedContext();
  const { i18n } = useTranslation();

  const handlePlayPauseClick = () => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    setShowStub(false);

    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
      setShowControls(true);
    } else {
      video.play();
      setIsPlaying(true);
      if (tablet) {
        setTimeout(() => {
          setShowControls(false);
        }, 450);
      }
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    setShowStub(true);
    setShowControls(true);
  };

  const handleMouseEnter = () => {
    if (isPlaying) {
      setShowControls(true);
    }
  };

  const handleMouseLeave = () => {
    if (isPlaying) {
      setShowControls(false);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    if (isPlaying) {
      video.pause();
    } else {
      setShowStub(true);
      setShowControls(true);
    }

    if (roleType === RoleEnum.Student) {
      video.src = i18n.language === LanguageEnum.USA ? StudentUS : StudentUK;
    } else {
      video.src = i18n.language === LanguageEnum.USA ? TeacherUS : TeacherUK;
    }

    video.load();
    if (isPlaying) {
      video.play();
    }
  }, [i18n.language, roleType]);

  return (
    <Stack
      position='relative'
      mb={tablet ? 3 : 0}
      sx={{ cursor: 'pointer' }}
      onMouseEnter={() => {
        !tablet && handleMouseEnter();
      }}
      onMouseLeave={() => {
        !tablet && handleMouseLeave();
      }}
      onClick={handlePlayPauseClick}
    >
      <Box
        sx={{
          width: '100%',
          opacity: showStub ? 1 : 0,
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: '30px',
          overflow: 'hidden',
          height: tablet ? 'calc(100% + 2px)' : '417px',
        }}
      >
        <IconImage
          src={
            roleType === RoleEnum.Student ? VideoStudentStub : VideoTeacherStub
          }
          alt='stub'
          width={tablet ? '100%' : '742px'}
          height={tablet ? 'calc(100% + 2px)' : '417px'}
        />
      </Box>
      <StyledVideo ref={videoRef} onEnded={handleVideoEnd} playsInline>
        {roleType !== RoleEnum.Student && (
          <source
            src={i18n.language === LanguageEnum.USA ? TeacherUS : TeacherUK}
            type='video/mp4'
          />
        )}
        {roleType === RoleEnum.Student && (
          <source
            src={i18n.language === LanguageEnum.USA ? StudentUS : StudentUK}
            type='video/mp4'
          />
        )}
      </StyledVideo>
      <Box
        sx={{
          position: 'absolute',
          bottom: '50%',
          left: '50%',
          transform: 'translate(-50%, 50%)',
          width: tablet ? '70px' : '80px',
          height: tablet ? '70px' : '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(153, 69, 255, .49)',
          borderRadius: '50%',
          backdropFilter: 'blur(8px)',
          opacity: showControls ? 1 : 0,
          transition: 'opacity .35s',
        }}
      >
        {isPlaying && <IconImage src={Pause} alt='stub' width={'26px'} />}
        {!isPlaying && (
          <Box
            ml={0.6}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <IconImage src={Play} alt='stub' width={'26px'} />
          </Box>
        )}
      </Box>
    </Stack>
  );
}
