import React, {useRef, useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {AnnotationState} from '../../../lib/aframe/components/annotation';
import {ImageAnnotation} from '../../../lib/aframe/components/image-annotation';
import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import {WorldButtonComponent} from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import {AtomsSceneComponent} from './atoms-scene';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import CrIcon from '../../../assets/img/Cr.png';
import MnIcon from '../../../assets/img/Mn.png';
import FeIcon from '../../../assets/img/Fe.png';
import CoIcon from '../../../assets/img/Co.png';
import NiIcon from '../../../assets/img/Ni.png';
import CuIcon from '../../../assets/img/Cu.png';
import {
    ANNOTATION_HINT,
    INFO_HINT,
    MODEL3D_HINT,
    PLAY_HINT,
    QUIZ_HINT,
} from 'shared/constants/hint-items';
import {TapPlaceAir} from "../../../lib/aframe/components/tap-place-air";
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
    'Explore some transition metals which make up the middle of the periodic table. Use the side menu to see a model of each atom and learn a little about each one.';

const hintItems = [
    ANNOTATION_HINT,
    QUIZ_HINT,
    INFO_HINT,
    PLAY_HINT,
    MODEL3D_HINT,
];

const ChemistryTransitionMetalsAframe = () => {
    const [assetId, setAssetId] = useState(0);

    const [toggle, setToggle] = useState(true);
    const [selectedObject, setSelectedObject] = useState<{
        title: string;
        body: string;
    } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(
        AnnotationState.None
    );
    const [lessonStarted, setLessonStarted] = useState(false);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

    const onLessonStarted = () => {
        setLessonStarted(true);
    };
    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };
    const assetIcons = [CrIcon, MnIcon, FeIcon, CoIcon, NiIcon, CuIcon];
    const changeAsset = (assetId: number) => {
        setAssetId(assetId);
        setToggle(true);
    };
    const onToggleAnimation = () => {
        setToggle(!toggle);
    };
    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };
    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };

    const assetChangeEventRef = useRef(
        new CustomEvent('asset-change', {detail: {assetId: 0}})
    );
    React.useEffect(() => {
        // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            console.log('Scene loaded');
            // Update the assetChangeEvent detail before dispatching
            assetChangeEventRef.current.detail.assetId = assetId;
            scene.dispatchEvent(assetChangeEventRef.current);
        }
    }, [assetId]);
    const animToggleEventRef = useRef(
        new CustomEvent('anim-toggle', {detail: {toggle: false}})
    );
    React.useEffect(() => {
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            animToggleEventRef.current.detail.toggle = toggle;
            scene.dispatchEvent(animToggleEventRef.current);
        }
    }, [toggle]);
    const onLessonRecenter = () => {
        setLessonStarted(false);
        setToggle(true);
    };
    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                onAssetButtonClick={changeAsset}
                assetIcons={assetIcons}
                lessonStarted={lessonStarted}
                onToggleAnimation={onToggleAnimation}
                toggleAnimationStatus={toggle}
                onAnnotationStateChanged={onAnnotationStateChanged}
                hintItems={hintItems}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./chemistry-transition-metals-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    TapPlaceAir,
                    WorldButtonComponent,
                    FaceCameraComponent,
                    AtomsSceneComponent,
                    ImageAnnotation,
                ]}
                onObjectSelected={onObjectSelected}
                annotationState={annotationState}
                onLessonRecenter={onLessonRecenter}
                onLessonStart={onLessonStarted}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>
    );
};
export {ChemistryTransitionMetalsAframe};
