import { TrainingsListResponse } from '../typings';
import type { Filters } from '../typings';
import { fetchTrainingsActionCreator, initialState } from '../state';
import { RequestService } from '../../../shared/services';
import { URLS } from '../../../shared/utils';

export async function fetchTrainingsEffect(filters?: any) {
  const checkedFilters = Object.fromEntries(
    Object.entries(filters || {}).filter(
      (filter) => typeof filter[1] !== 'undefined'
    )
  ) as Filters;
  const response = await RequestService.get<Filters, TrainingsListResponse>(
    URLS.fetchTrainings,
    checkedFilters,
    true
  );

  if (response.status === 200) {
    return {
      status: 'success',
      action: fetchTrainingsActionCreator(response.data),
    };
  }

  return {
    status: 'error',
    action: fetchTrainingsActionCreator(initialState.trainingsInfo),
  };
}
