import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IRadiationCurvesSceneAframe {
	scene: AFrame.Scene;
	sliderValue: any;
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolButtons: PoolComponent;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionBottomBlockInfo: THREE.AnimationAction;
	actionCurveFill: THREE.AnimationAction;
	actionTopBlockInfo: THREE.AnimationAction;

	currentClips: THREE.AnimationAction[];
	mixer: THREE.AnimationMixer;

	groupElement: AFrame.Entity | null;

	temp_4000: THREE.Mesh;
	temp_6000: THREE.Mesh;
	temp_8000: THREE.Mesh;
	temp_9500: THREE.Mesh;
	temp_11000: THREE.Mesh;

	curve_1: THREE.Mesh;
	curve_2: THREE.Mesh;
	curve_3: THREE.Mesh;
	curve_4: THREE.Mesh;
	curve_5: THREE.Mesh;

	temps: THREE.Mesh[];
	curves: THREE.Mesh[];

	el: AFrame.Entity;

	slider: any;

	positionATriggerBtn: AFrame.Entity | null;
	positionBTriggerBtn: AFrame.Entity | null;
	positionCTriggerBtn: AFrame.Entity | null;
	positionDTriggerBtn: AFrame.Entity | null;
	positionETriggerBtn: AFrame.Entity | null;

	positionAButtonHandler: () => void;
	positionBButtonHandler: () => void;
	positionCButtonHandler: () => void;
	positionDButtonHandler: () => void;
	positionEButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const RadiationCurvesScene = {
	name: 'experiment',
	val: {
		init(this: IRadiationCurvesSceneAframe) {
			var isInitialised = false;
			const slider = document.getElementById('sliderAnnotationContainer');
			slider?.classList.add('shrink-complete');
			this.el.addEventListener('model-loaded', () => {
				if (!isInitialised) {
					this.groupElement = document.getElementById('Experiment') as AFrame.Entity;
					this.scene = document.querySelector('a-scene');

					const rangeForm = document.getElementById('rangeForm');
					this.slider = rangeForm?.querySelector('#range');

					this.temp_4000 = this.groupElement.object3D.getObjectByName('Temp_4000') as THREE.Mesh;
					this.temp_6000 = this.groupElement.object3D.getObjectByName('Temp_6000') as THREE.Mesh;
					this.temp_8000 = this.groupElement.object3D.getObjectByName('Temp_8000') as THREE.Mesh;
					this.temp_9500 = this.groupElement.object3D.getObjectByName('Temp_9500') as THREE.Mesh;
					this.temp_11000 = this.groupElement.object3D.getObjectByName('Temp_11000') as THREE.Mesh;

					this.curve_1 = this.groupElement.object3D.getObjectByName('Curve1') as THREE.Mesh;
					this.curve_2 = this.groupElement.object3D.getObjectByName('Curve2') as THREE.Mesh;
					this.curve_3 = this.groupElement.object3D.getObjectByName('Curve3') as THREE.Mesh;
					this.curve_4 = this.groupElement.object3D.getObjectByName('Curve4') as THREE.Mesh;
					this.curve_5 = this.groupElement.object3D.getObjectByName('Curve5') as THREE.Mesh;

					this.curves = [this.curve_1, this.curve_2, this.curve_3, this.curve_4, this.curve_5,];
					this.temps = [this.temp_4000, this.temp_6000, this.temp_8000, this.temp_9500, this.temp_11000];

					this.currentClips = [];

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;
					firstScene();
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started');
				// remove tap place
				this.scene.emit('slider-invisible');
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place');
					this.el.sceneEl?.removeChild(ring);
				}
			})

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: SceneHolder; offset: 0 0 0');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
					this.scene.emit('slider-invisible');
					if (this.currentClips[1]) {
						this.currentClips[1].stop();
					}
				}
			});

			const hideSlider = () => {
				const sliderContainder = document.getElementById('sliderAnnotationContainer');
				sliderContainder?.classList.add('shrink-complete');
			} 

			const firstScene = () => {
				this.scene.emit('slider-invisible');

				this.curves?.forEach(curve => curve.visible = false);
				this.temps?.forEach(temp => temp.visible = false);
				this.curves[0].visible = true;
				this.temps[0].visible = true;
			}

			this.el.sceneEl?.addEventListener('slider-visible', () => {
				if (this.currentClips[1]) {
					this.currentClips[1].stop();
				}
			})

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentClips[1]) {
					this.currentClips[1].stop();
				}
			})

			this.el.sceneEl?.addEventListener('slider-move', (e) => {
								
				const ce = e as CustomEvent;

				this.sliderValue = ce.detail.value;

				this.curves?.forEach(curve => curve.visible = false);
				this.temps?.forEach(temp => temp.visible = false);

				if (this.sliderValue >= 0 && this.sliderValue < this.curves.length) {
					this.curves[this.sliderValue].visible = true;
					this.temps[this.sliderValue].visible = true;
				}
				
			});

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			const initialiseAnimations = () => {
				const animatedModelOneProcess = this.groupElement?.object3D.getObjectByName('Scene') as any;

				this.mixer = new THREE.AnimationMixer(animatedModelOneProcess);

				const showBottomBlockInfo = animatedModelOneProcess?.animations[0];
				const showCurveFill = animatedModelOneProcess?.animations[1];
				const showTopBlockInfo = animatedModelOneProcess?.animations[2];

				this.actionBottomBlockInfo = this.mixer.clipAction(showBottomBlockInfo);
				this.actionCurveFill = this.mixer.clipAction(showCurveFill);
				this.actionTopBlockInfo = this.mixer.clipAction(showTopBlockInfo);
			};

			this.positionAButtonHandler = () => {
				this.scene.emit('slider-invisible');
				hideSlider();
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}
				if (this.onObjectSelected) {
					const title = 'Black Bodies';
					const body = 'A ‘black body’ is the technical term to describe an object that can absorb and emit all wavelengths of electromagnetic radiation in some amount. We can assume that stars behave as black bodies.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionBButtonHandler = () => {
				this.scene.emit('slider-invisible');
				hideSlider();
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}
				if (this.onObjectSelected) {
					const title = '<span class="smaller-title-annotation">The Black Body Radiation Curve</span>';
					const body = 'Black bodies don’t emit all wavelengths of light evenly, there is a distribution known as the black body radiation curve. Low temperature objects mainly emit infrared radiation, while very hot objects like stars also emit visible light.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionCButtonHandler = () => {
				this.scene.emit('slider-invisible');
				hideSlider();

				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}

				this.slider.value = 4;
				
				this.curves?.forEach(curve => curve.visible = false);
				this.temps?.forEach(temp => temp.visible = false);

				this.curves[4].visible = true;
				this.temps[4].visible = true;

				this.currentClips[0] = this.actionTopBlockInfo;
				this.actionTopBlockInfo.reset();
				this.actionTopBlockInfo.repetitions = 1;
				this.actionTopBlockInfo.clampWhenFinished = true;
				this.actionTopBlockInfo.play();

				this.currentClips[1] = this.actionCurveFill;
				this.actionCurveFill.reset();
				this.actionCurveFill.repetitions = 1;
				this.actionCurveFill.clampWhenFinished = true;
				this.actionCurveFill.play();

				if (this.onObjectSelected) {
					const title = 'Stefan’s Law';
					const body = 'Stefan’s law is an equation that tells us the total power emitted by a black body over all wavelengths. This is equivalent to the total area under the radiation curve which increases dramatically as temperature is increased.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionDButtonHandler = () => {
				this.scene.emit('slider-invisible');
				hideSlider();

				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}

				this.slider.value = 0;
				
				this.curves?.forEach(curve => curve.visible = false);
				this.temps?.forEach(temp => temp.visible = false);

				this.curves[0].visible = true;
				this.temps[0].visible = true;

				this.currentClips[0] = this.actionBottomBlockInfo;
				this.actionBottomBlockInfo.reset();
				this.actionBottomBlockInfo.repetitions = 1;
				this.actionBottomBlockInfo.clampWhenFinished = true;
				this.actionBottomBlockInfo.play();

				if (this.onObjectSelected) {
					const title = 'Wien’s Displacement  Law';
					const body = 'As the temperature of a black body increases, the peak of its radiation curve moves to smaller wavelengths (higher energy photons). This allows us to identify a star’s temperature from its peak wavelength.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionEButtonHandler = () => {
				this.scene.emit('slider-visible');
				this.scene.emit('annotation-close');
			}

			const initialiseButtons = () => {
				this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.positionATriggerBtn = this.poolButtons.requestEntity();
				this.positionATriggerBtn?.object3D.position.set(1.6, 6.05, 0.4);
				this.positionATriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.positionATriggerBtn?.play();
				this.positionATriggerBtn?.addEventListener('click', () => {
					this.positionAButtonHandler();
					if (this.positionATriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionATriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.positionATriggerBtn;
					}
				});

				this.positionBTriggerBtn = this.poolButtons.requestEntity();
				this.positionBTriggerBtn?.object3D.position.set(1, 2.8, 0.4);
				this.positionBTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.positionBTriggerBtn?.play();
				this.positionBTriggerBtn?.addEventListener('click', () => {
					this.positionBButtonHandler();
					if (this.positionBTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionBTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionBTriggerBtn;
					}
				});

				this.positionCTriggerBtn = this.poolButtons.requestEntity();
				this.positionCTriggerBtn?.object3D.position.set(1.6, 5.5, 0.4);
				this.positionCTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.positionCTriggerBtn?.play();
				this.positionCTriggerBtn?.addEventListener('click', () => {
					this.positionCButtonHandler();
					if (this.positionCTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionCTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionCTriggerBtn;
					}
				});

				this.positionDTriggerBtn = this.poolButtons.requestEntity();
				this.positionDTriggerBtn?.object3D.position.set(1.6, 3.3, 0.4);
				this.positionDTriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.positionDTriggerBtn?.play();
				this.positionDTriggerBtn?.addEventListener('click', () => {
					this.positionDButtonHandler();
					if (this.positionDTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionDTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionDTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionDTriggerBtn;
					}
				});

				this.positionETriggerBtn = this.poolButtons.requestEntity();
				this.positionETriggerBtn?.object3D.position.set(0, 3.8, 0.4);
				this.positionETriggerBtn?.object3D.scale.set(1.2, 1.2, 1.2);
				this.positionETriggerBtn?.play();
				this.positionETriggerBtn?.addEventListener('click', () => {
					this.positionEButtonHandler();

				});
			}
		},
		tick(this: IRadiationCurvesSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { RadiationCurvesScene as RadiationCurvesComponent }