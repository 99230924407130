import * as AFRAME from 'aframe';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { swap } from 'formik';

interface IAIRobotComponent {
    videoTextures: THREE.VideoTexture[];
    videoElements: HTMLVideoElement[];

    isVideoPlaneRemoved: boolean;
    gpuMesh2: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial, THREE.Object3DEventMap>;
    gpuMesh1: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial, THREE.Object3DEventMap>;
    robotStateEvent: Event;

    robotClickedEvent: Event;

    screenFace: any;
    actionTalk: THREE.AnimationAction;
    actionIdle: THREE.AnimationAction;
    mixer: THREE.AnimationMixer;
    minScale: string;
    isActive: boolean;
    el: AFRAME.Entity;
    data: {
      scale: string;
      position: string;
    };
}

const AIRobotCompoennt = {
    name: 'ai-robot',
    val: {
      schema: {
        scale: { type: 'string', default: '1 1 1' },
        position: { type: 'string', default: '0 0 0' },
      },
      init(this: IAIRobotComponent) {
        this.el.addEventListener('model-loaded', () => {
          // set scale
          const scale = this.data.scale;
          this.minScale = "0.01 0.01 0.01"
          this.el.setAttribute('scale', this.minScale);
          this.el.setAttribute('position', this.data.position);
          this.isActive = false;
          this.robotClickedEvent = new Event('robot-clicked');

          this.el.sceneEl?.addEventListener('ai-robot', onRobotButton);

          // this.el.sceneEl?.addEventListener('transcription-done', () => {
          //   this.micPlane.setAttribute('visible', false);
          // });

          this.el.sceneEl?.addEventListener('audio-started', startTalking);
          this.el.sceneEl?.addEventListener('audio-ended', stopTalking);
          this.el.sceneEl?.addEventListener('recognition-started', startRecognition);
          this.el.sceneEl?.addEventListener('fetching-response', startThinking);
          this.el.sceneEl?.addEventListener('window-minimised', stopTalking);
          this.el.sceneEl?.addEventListener('lesson-recenter', () => {
            // stop audio playback
            const robotStateEvent = new CustomEvent('robot-state-change', {
              detail: {
                  isActive: false,
              }
            });
            window.dispatchEvent(robotStateEvent);
            // stop talking animations
            stopTalking();
          });

          startFloatAnimation();
          initialiseAnimation();
          setVideoTexture();
          
          setupCollider();
        });

        const onRobotButton = () => {
          toggleRobotAnimation();
        };

        const initialiseAnimation = () => {
          const animEl = this.el.object3D.getObjectByName("Scene") as THREE.Object3D;
         
          this.mixer = new THREE.AnimationMixer(animEl);
          const [clip1, clip2] = animEl.animations;
          this.actionIdle = this.mixer.clipAction(clip1);
          this.actionTalk = this.mixer.clipAction(clip2);

          this.actionIdle.play();
        
        };


        const setupCollider = () => {
          // Create the a-box entity
          const boxEntity = document.createElement('a-box');

          // Assign the 'cantap' class to it
          boxEntity.classList.add('cantap');
          boxEntity.setAttribute('scale', '0.5 1.2 0.5');
          boxEntity.setAttribute('position', '0.08 1.2 0');
          boxEntity.setAttribute('material', 'opacity: 0; transparent: true');

          // Append the box to this.el (the parent entity)
          this.el.appendChild(boxEntity);

          boxEntity.addEventListener('click', onRobotClicked);
        };

        const setVideoTexture = () => {

          this.screenFace = this.el.object3D.getObjectByName('FaceScreen001') as any;
          const videoElement_Idle = document.getElementById('videoBlink') as HTMLVideoElement;
          const videoTextureIdle= new THREE.VideoTexture(videoElement_Idle);
          videoTextureIdle.flipY = false;
          const emissiveColor = new THREE.Color(0x03ecfc);
         

          // const envTexture = videoTexture.clone();
          // Set the texture offset on the Y axis
         // videoTexture.offset.set(0, -0.25); // Adjust 0.05 to whatever offset value you desire

          // 2. Apply the VideoTexture to the doorFrame's material
         
          // 3. If the video is not playing (depending on how you've set it up), you might need to play it:
          videoElement_Idle.play();

          const videoElement_Talk = document.getElementById('videoTalk') as HTMLVideoElement;
          const videoTextureTalk = new THREE.VideoTexture(videoElement_Talk);
          videoTextureTalk.flipY = false;

          this.screenFace.material = new THREE.MeshStandardMaterial({
            map: videoTextureTalk,
            color: emissiveColor,  
            emissiveMap: videoTextureTalk,
            emissive: emissiveColor,
          });
          this.screenFace.material.needsUpdate = true;
          videoElement_Talk.play();

          // swap back to idle video after 500ms
          // this is done to push talk video texture to GPU
          setTimeout(() => {
            swapVideo(0);
          }, 500)

          // unlock other two videos
          const videoElement_Mic = document.getElementById('videoRecord') as HTMLVideoElement;
          const videoTexture = new THREE.VideoTexture(videoElement_Mic);
          videoTexture.flipY = false;
          
          const planeGeometry = new THREE.PlaneGeometry(1, 1);
          const planeMaterial = new THREE.MeshBasicMaterial({ map: videoTexture });
          this.gpuMesh1 = new THREE.Mesh(planeGeometry, planeMaterial);
          this.el.object3D.add(this.gpuMesh1);
          videoElement_Mic.play();

          const videoElement_Load = document.getElementById('videoLoading') as HTMLVideoElement;
          const videoTexture2 = new THREE.VideoTexture(videoElement_Load);
          videoTexture2.flipY = false;
          const planeGeometry2 = new THREE.PlaneGeometry(1, 1);
          const planeMaterial2 = new THREE.MeshBasicMaterial({ map: videoTexture2 });
          this.gpuMesh2 = new THREE.Mesh(planeGeometry2, planeMaterial2);
          this.el.object3D.add(this.gpuMesh2);
          videoElement_Load.play();

          this.isVideoPlaneRemoved = false;

          // create arrays for video elements and textures
          this.videoElements = [videoElement_Idle, videoElement_Talk, videoElement_Mic, videoElement_Load];
          this.videoTextures = [videoTextureIdle, videoTextureTalk, videoTexture, videoTexture2];
        };

        const startFloatAnimation = () => {
          const currentPosition = this.el.getAttribute('position');

          // Define the target floating position
          const floatingPosition = { x: currentPosition.x, y: currentPosition.y + 1, z: currentPosition.z };

          // Remove any existing floating animation to avoid conflicts
          if (this.el.components['animation__float']) {
            this.el.removeAttribute('animation__float');
          }

          // Add the floating animation to the robot entity
          this.el.setAttribute('animation__float', {
            property: 'position',
            to: `${floatingPosition.x} ${floatingPosition.y} ${floatingPosition.z}`,
            dur: 1500,
            dir: 'alternate',
            easing: 'easeInOutSine',
            loop: true,
          });
        };

        const toggleRobotAnimation = () => {
          //check if the planes are removed
          if (!this.isVideoPlaneRemoved) {
            // this.el.object3D.remove(this.gpuMesh1);
            // this.el.object3D.remove(this.gpuMesh2);
            this.gpuMesh1.visible = false;
            this.gpuMesh2.visible = false;
            this.isVideoPlaneRemoved = true;
          }
          const currentScale = this.el.object3D.scale;
          let targetScale = new THREE.Vector3();
        
          if (this.isActive) {
            // Parse the minScale into a Vector3
            const [x, y, z] = this.minScale.split(' ').map(Number);
            targetScale.set(x, y, z);
          } else {
            // Parse the scale into a Vector3
            const [x, y, z] = this.data.scale.split(' ').map(Number);
            targetScale.set(x, y, z);
          }

          new TWEEN.Tween(currentScale)
            .to(targetScale, 1000)  // duration: 1000ms = 1 second
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(() => {
              this.el.object3D.scale.set(currentScale.x, currentScale.y, currentScale.z);
            })
            .onComplete(() => {
              this.isActive = !this.isActive;
            })
            .start();

          if (!this.isActive) {
            // dispatch event
            const active = this.isActive;
            const robotStateEvent = new CustomEvent('robot-state-change', {
              detail: {
                  isActive: active,
              }
            });
            window.dispatchEvent(robotStateEvent);
            
            stopTalking();
          }
        };

        const onRobotClicked = () => {
          if (!this.isActive) return;
          window.dispatchEvent(this.robotClickedEvent);

        };

        const startTalking = () => {
          // this.isTalking = true;
          // this.isTransitioning = true;
          if (this.actionIdle && this.actionTalk) {
            console.log("Transition from Idle to Talk");
    
            // Ensure the talk action is not paused and is enabled
            this.actionTalk.enabled = true;
            this.actionTalk.setEffectiveTimeScale(1);
            this.actionTalk.setEffectiveWeight(1);
            this.actionTalk.time = 0;
    
            // Crossfade from idle to talking
            this.actionTalk.crossFadeFrom(this.actionIdle, 0.5, false);
            this.actionTalk.play();
          }
          swapVideo(1);
          
        };

          const stopTalking = () => {
            // this.isTalking = false;
            // this.isTransitioning = true;
            if (this.actionIdle && this.actionTalk) {
              console.log("Transition from Talk to Idle");
            
              // Ensure the idle action is not paused and is enabled
              this.actionIdle.enabled = true;
              this.actionIdle.setEffectiveTimeScale(1);
              this.actionIdle.setEffectiveWeight(1);
              this.actionIdle.time = 0;
            
              // Crossfade from talking to idle
              this.actionIdle.crossFadeFrom(this.actionTalk, 0.5, false);
              this.actionIdle.play();
            }
            swapVideo(0);
          };

          const startRecognition = () => {
            swapVideo(2);
          };

          const startThinking = () => {
            swapVideo(3);
          };

          const swapVideo = (id: number) => {
            pauseAllVideos();
            if (this.screenFace && this.videoTextures[id] && this.videoElements[id]) {
              const material = this.screenFace.material as THREE.MeshStandardMaterial;
              material.map = this.videoTextures[id];
              material.emissiveMap = this.videoTextures[id];
              material.needsUpdate = true;

              this.videoElements[id].play();
            }

          };

          const pauseAllVideos = () => {
            this.videoElements.forEach((video) => {
              video.pause();
            });
          };
          
      },
      tick(this: IAIRobotComponent, time: number, timeDelta: number) {
        if (this.mixer) {
          this.mixer.update(timeDelta / 1000);
        }
        TWEEN.update();
      },
    
    },
};
export { AIRobotCompoennt };   