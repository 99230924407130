import * as THREE from 'three';
import * as AFrame from 'aframe';
import {IAnnotationSystemAframe} from "../../../lib/aframe/systems/annotation-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface ICircuitControl {
    voltText: AFrame.Entity;
    ammText: AFrame.Entity;
    wireFirstMixer: THREE.AnimationMixer;
    wireSecondMixer: THREE.AnimationMixer;
    wireThirdMixer: THREE.AnimationMixer;
    wireFourthMixer: THREE.AnimationMixer;
    wireFifthMixer: THREE.AnimationMixer;
    wireSixthMixer: THREE.AnimationMixer;
    wireSeventhMixer: THREE.AnimationMixer;
    wireEightMixer: THREE.AnimationMixer;
    wireNinthMixer: THREE.AnimationMixer;
    switchMixer: THREE.AnimationMixer;
    micrometerMixer: THREE.AnimationMixer;
    wireFirstAnimation: THREE.AnimationAction;
    wireSecondAnimation: THREE.AnimationAction;
    wireThirdAnimation: THREE.AnimationAction;
    wireFourthAnimation: THREE.AnimationAction;
    wireFifthAnimation: THREE.AnimationAction;
    wireSixthAnimation: THREE.AnimationAction;
    wireSeventhAnimation: THREE.AnimationAction;
    wireEightAnimation: THREE.AnimationAction;
    wireNinthAnimation: THREE.AnimationAction;
    switchAnimation: THREE.AnimationAction;
    micrometerAnimation: THREE.AnimationAction;
    currentDeactivatedButton: AFrame.Entity;
    poolEntity: AFrame.Entity;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    measuringWireDiameterHandler: () => void;
    crossSectionalAreaHandler: () => void;
    calculatingResistivityHandler: () => void;
    el: AFrame.Entity;
    circuit: AFrame.Entity;
    book1: AFrame.Entity;
    book2: AFrame.Entity;
    micrometer: THREE.Object3D;
    notebook: THREE.Object3D;
    clipRed: THREE.Mesh;
    wires: THREE.Mesh;
    ammeter: THREE.Mesh;
    base: THREE.Mesh;
    clipBlack: THREE.Mesh;
    powerPack: THREE.Mesh;
    rulerWire: THREE.Mesh;
    voltmeter: THREE.Mesh;
    sliderValue: string;
    prevValue: string;
    prevTimeout: NodeJS.Timeout;
    screensTimeout: NodeJS.Timeout;
    switchTimeout: NodeJS.Timeout;
    distanceEvent: CustomEvent;
    plug: THREE.Mesh;
    switchToggle: boolean;
    currentAssetid: number;
    cube1: AFrame.Entity;
    canClick: boolean;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
    name: 'circuit-control',
    val: {
        init(this: ICircuitControl) {
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

            });
            this.circuit = document.getElementById('circuit') as AFrame.Entity;
            this.circuit.addEventListener('model-loaded', () => {
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                this.ammeter = this.el.object3D.getObjectByName('Ammeter') as THREE.Mesh
                this.voltmeter = this.el.object3D.getObjectByName('Voltmeter') as THREE.Mesh
                this.clipBlack = this.el.object3D.getObjectByName('Clip_Black') as THREE.Mesh
                this.clipRed = this.el.object3D.getObjectByName('Clip_Red') as THREE.Mesh
                this.wires = this.el.object3D.getObjectByName('Wires') as THREE.Mesh
                this.micrometer = this.el.object3D.getObjectByName('Micrometer') as THREE.Object3D
                this.book1 = document.getElementById('book1') as AFrame.Entity;
                this.book2 = document.getElementById('book2') as AFrame.Entity;
                const geometry = new THREE.CircleGeometry(1, 32);
                const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
                this.plug = new THREE.Mesh(geometry, material);
                this.plug.scale.set(0.006, 0.006, 0.006)
                this.plug.position.set(-0.0315, 0.0444, 0.0457)
                this.circuit.object3D.add(this.plug);
                initialiseCircuitAnimations()
                createClickableAreas()
                setVoltmeterDigits()
                setAmmeterDigits()
                setFirstScene()

            });
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                    this.canClick = true

                }
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: holder; scale: 15 15 15; offset: 0 0 -4');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');
                    this.el.sceneEl?.appendChild(ring);
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.el.sceneEl?.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                this.currentAssetid = ce.detail.assetId;
                switch (this.currentAssetid) {
                    case 0:
                        setFirstScene()
                        this.canClick = true
                        break;
                    case 1:
                        this.canClick = false
                        setSecondScene()
                        break;
                    default:
                        console.warn('lesson-start.tsx:Unknown asset id: ', this.currentAssetid);
                }
            });
            const setFirstScene = () => {
                updateCircuitDynamicValues('', '', '')
                this.micrometer.visible = true
                this.plug.visible = true
                this.book1.object3D.visible = false
                this.book2.object3D.visible = false
            }

            const setSecondScene = () => {
                this.plug.visible = false
                this.micrometer.visible = false
                this.book1.object3D.visible = false
                this.book2.object3D.visible = true
                this.book2.object3D.traverse(child => {
                    if (child.name.includes('Pos')) {
                        child.visible = false
                    }
                })
            }
            const setNotebookValue = (index: string) => {
                this.book2.object3D.traverse(child => {
                    if (child.name.includes('Pos')){
                        child.visible = child.name === (`Pos${index}`);
                    }
                })
            }
            const createClickableAreas = () => {
                this.cube1 = document.createElement('a-entity');
                this.cube1.setAttribute('geometry', {
                    primitive: 'box',
                    height: 0.03,
                    width: 0.4,
                    depth: 0.4
                });
                this.cube1.setAttribute('position', '0 0.01 0.15');
                this.cube1.setAttribute('visible', 'false');
                this.cube1.setAttribute('class', 'cantap');
                this.cube1.addEventListener('click', () => {
                    if (this.canClick){
                        this.canClick = false
                        this.micrometerAnimation.stop()
                        this.micrometerAnimation.play()
                        setTimeout(() => {
                            this.book1.object3D.visible = true
                            setTimeout(() => {
                                this.canClick = true
                            }, (this.micrometerAnimation.getClip().duration * 1000) / 2)
                        }, (this.micrometerAnimation.getClip().duration * 1000) / 2)
                    }
                });
                this.circuit.appendChild(this.cube1);
            }
            this.el.sceneEl?.addEventListener('slider-move', (e) => {
                const ce = e as CustomEvent;
                this.sliderValue = ce.detail.value;
                this.plug.visible = true
                if (!this.switchToggle) {
                    this.switchToggle = true
                    this.switchAnimation.stop()
                    this.switchAnimation.repetitions = 1
                    this.switchAnimation.play()
                    setTimeout(() => {
                        this.switchAnimation.paused = true
                    }, (this.switchAnimation.getClip().duration * 1000) / 2)
                }
                clearTimeout(this.screensTimeout);
                if (this.prevTimeout) {
                    clearTimeout(this.prevTimeout);
                }
                switch (this.sliderValue) {
                    case `0.1`:
                        if (this.prevValue === '0.2') {
                            this.wireFirstAnimation.stop()
                            this.wireFirstAnimation.timeScale = -1
                            this.wireFirstAnimation.repetitions = 1
                            this.wireFirstAnimation.clampWhenFinished = true
                            this.wireFirstAnimation.play()
                        } else {
                            this.wireFirstAnimation.timeScale = 1
                            this.wireFirstAnimation.play()
                            this.wireFirstAnimation.paused = true
                        }
                        this.prevValue = '0.1'
                        updateCircuitDynamicValues('4.3', '4.3', '1')
                        break;
                    case `0.2`:
                        if (this.prevValue === '0.3') {
                            this.wireSecondAnimation.stop()
                            this.wireSecondAnimation.timeScale = -1
                            this.wireSecondAnimation.repetitions = 1
                            this.wireSecondAnimation.clampWhenFinished = true
                            this.wireSecondAnimation.play()
                        } else {
                            this.wireFirstAnimation.stop()
                            this.wireFirstAnimation.timeScale = 1
                            this.wireFirstAnimation.repetitions = 1
                            this.wireFirstAnimation.clampWhenFinished = true
                            this.wireFirstAnimation.play()
                        }
                        this.prevValue = '0.2'
                        updateCircuitDynamicValues('5', '2.5', '2')
                        break;
                    case `0.3`:
                        if (this.prevValue === '0.4') {
                            this.wireThirdAnimation.stop()
                            this.wireThirdAnimation.timeScale = -1
                            this.wireThirdAnimation.repetitions = 1
                            this.wireThirdAnimation.clampWhenFinished = true
                            this.wireThirdAnimation.play()
                        } else {
                            this.wireSecondAnimation.stop()
                            this.wireSecondAnimation.timeScale = 1
                            this.wireSecondAnimation.repetitions = 1
                            this.wireSecondAnimation.clampWhenFinished = true
                            this.wireSecondAnimation.play()
                        }
                        this.prevValue = '0.3'
                        updateCircuitDynamicValues('5.3', '1.8', '3')
                        break;
                    case `0.4`:
                        if (this.prevValue === '0.5') {
                            this.wireFourthAnimation.stop()
                            this.wireFourthAnimation.timeScale = -1
                            this.wireFourthAnimation.repetitions = 1
                            this.wireFourthAnimation.clampWhenFinished = true
                            this.wireFourthAnimation.play()
                        } else {
                            this.wireThirdAnimation.stop()
                            this.wireThirdAnimation.timeScale = 1
                            this.wireThirdAnimation.repetitions = 1
                            this.wireThirdAnimation.clampWhenFinished = true
                            this.wireThirdAnimation.play()
                        }
                        this.prevValue = '0.4'
                        updateCircuitDynamicValues('5.5', '1.4', '4')
                        break;
                    case `0.5`:
                        if (this.prevValue === '0.6') {
                            this.wireFifthAnimation.stop()
                            this.wireFifthAnimation.timeScale = -1
                            this.wireFifthAnimation.repetitions = 1
                            this.wireFifthAnimation.clampWhenFinished = true
                            this.wireFifthAnimation.play()
                        } else {
                            this.wireFourthAnimation.stop()
                            this.wireFourthAnimation.timeScale = 1
                            this.wireFourthAnimation.repetitions = 1
                            this.wireFourthAnimation.clampWhenFinished = true
                            this.wireFourthAnimation.play()
                        }
                        this.prevValue = '0.5'
                        updateCircuitDynamicValues('5.6', '1.1', '5')
                        break;
                    case `0.6`:
                        if (this.prevValue === '0.7') {
                            this.wireSixthAnimation.stop()
                            this.wireSixthAnimation.timeScale = -1
                            this.wireSixthAnimation.repetitions = 1
                            this.wireSixthAnimation.clampWhenFinished = true
                            this.wireSixthAnimation.play()
                        } else {
                            this.wireFifthAnimation.stop()
                            this.wireFifthAnimation.timeScale = 1
                            this.wireFifthAnimation.repetitions = 1
                            this.wireFifthAnimation.clampWhenFinished = true
                            this.wireFifthAnimation.play()
                        }
                        this.prevValue = '0.6'
                        updateCircuitDynamicValues('5.6', '0.9', '6')
                        break;
                    case `0.7`:
                        if (this.prevValue === '0.8') {
                            this.wireSeventhAnimation.stop()
                            this.wireSeventhAnimation.timeScale = -1
                            this.wireSeventhAnimation.repetitions = 1
                            this.wireSeventhAnimation.clampWhenFinished = true
                            this.wireSeventhAnimation.play()
                        } else {
                            this.wireSixthAnimation.stop()
                            this.wireSixthAnimation.timeScale = 1
                            this.wireSixthAnimation.repetitions = 1
                            this.wireSixthAnimation.clampWhenFinished = true
                            this.wireSixthAnimation.play()
                        }
                        this.prevValue = '0.7'
                        updateCircuitDynamicValues('5.7', '0.8', '7')
                        break;
                    case `0.8`:
                        if (this.prevValue === '0.9') {
                            this.wireEightAnimation.stop()
                            this.wireEightAnimation.timeScale = -1
                            this.wireEightAnimation.repetitions = 1
                            this.wireEightAnimation.clampWhenFinished = true
                            this.wireEightAnimation.play()
                        } else {
                            this.wireSeventhAnimation.stop()
                            this.wireSeventhAnimation.timeScale = 1
                            this.wireSeventhAnimation.repetitions = 1
                            this.wireSeventhAnimation.clampWhenFinished = true
                            this.wireSeventhAnimation.play()
                        }
                        this.prevValue = '0.8'
                        updateCircuitDynamicValues('5.7', '0.7', '8')
                        break;
                    case `0.9`:
                        if (this.prevValue === '1') {
                            this.wireNinthAnimation.stop()
                            this.wireNinthAnimation.timeScale = -1
                            this.wireNinthAnimation.repetitions = 1
                            this.wireNinthAnimation.clampWhenFinished = true
                            this.wireNinthAnimation.play()
                        } else {
                            this.wireEightAnimation.stop()
                            this.wireEightAnimation.timeScale = 1
                            this.wireEightAnimation.repetitions = 1
                            this.wireEightAnimation.clampWhenFinished = true
                            this.wireEightAnimation.play()
                        }
                        this.prevValue = '0.9'
                        updateCircuitDynamicValues('5.7', '0.6', '9')
                        break;
                    case `1`:
                        this.wireNinthAnimation.stop()
                        this.wireNinthAnimation.timeScale = 1
                        this.wireNinthAnimation.repetitions = 1
                        this.wireNinthAnimation.clampWhenFinished = true
                        this.wireNinthAnimation.play()
                        this.prevValue = '1'
                        updateCircuitDynamicValues('5.8', '0.6', '10')
                        break;
                }
            });
            this.measuringWireDiameterHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Measuring Wire Diameter';
                    const body = 'A micrometer is a very precise tool to measure small distances. This is used to find the diameter of the wire. We take multiple results and find the average to increase confidence in our results.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.crossSectionalAreaHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Cross Sectional Area';
                    const body = 'To calculate the cross sectional area of the wire ‘A’, divide the mean diameter of the wire by two to find the radius ‘r’, then use the equation for the area of a circle; A = πr<sup>2</sup>.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.calculatingResistivityHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Calculating Resistivity';
                    const body = 'To find the resistivity of a material, find the resistance of the wire (using the current and voltage) and plot a graph of how the resistance depends on the wire’s length. The gradient of the graph’s line of best fit multiplied by the cross sectional area is the resistivity.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const measuringWireDiameterTriggerBtn = poolButtons.requestEntity()
                measuringWireDiameterTriggerBtn?.setAttribute('position', '0.14 0.05 0')
                measuringWireDiameterTriggerBtn?.setAttribute('scale', '2 2 2')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        measuringWireDiameterTriggerBtn?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 1) {
                        measuringWireDiameterTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                measuringWireDiameterTriggerBtn?.play()
                measuringWireDiameterTriggerBtn?.addEventListener('click', () => {
                    this.measuringWireDiameterHandler()
                    if (measuringWireDiameterTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(measuringWireDiameterTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (measuringWireDiameterTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = measuringWireDiameterTriggerBtn
                    }
                });

                const crossSectionalAreaTriggerButton = poolButtons.requestEntity();
                crossSectionalAreaTriggerButton?.setAttribute('position', '0.13 0.035 0.18');
                crossSectionalAreaTriggerButton?.setAttribute('scale', '2 2 2')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        crossSectionalAreaTriggerButton?.setAttribute('scale', '2 2 2')
                    }
                    if (newAssetId === 1) {
                        crossSectionalAreaTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                });
                crossSectionalAreaTriggerButton?.play()
                crossSectionalAreaTriggerButton?.addEventListener('click', () => {
                    this.crossSectionalAreaHandler()
                    if (crossSectionalAreaTriggerButton) {
                        this.annotationComponent.setObjectToFollow(crossSectionalAreaTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (crossSectionalAreaTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = crossSectionalAreaTriggerButton

                    }
                });

                const calculatingResistivityTriggerButton = poolButtons.requestEntity();
                calculatingResistivityTriggerButton?.setAttribute('position', '0.215 0.035 0.18');
                calculatingResistivityTriggerButton?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const newAssetId = customEvent.detail.assetId;
                    if (newAssetId === 0) {
                        calculatingResistivityTriggerButton?.setAttribute('scale', '0 0 0')
                    }
                    if (newAssetId === 1) {
                        calculatingResistivityTriggerButton?.setAttribute('scale', '2 2 2')
                    }
                });
                calculatingResistivityTriggerButton?.play()
                calculatingResistivityTriggerButton?.addEventListener('click', () => {
                    this.calculatingResistivityHandler()
                    if (calculatingResistivityTriggerButton) {
                        this.annotationComponent.setObjectToFollow(calculatingResistivityTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (calculatingResistivityTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = calculatingResistivityTriggerButton

                    }
                });
            };
            const setVoltmeterDigits = () => {
                if (!this.ammText) {
                    const voltText = document.createElement('a-entity')
                    voltText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    voltText.setAttribute('position', '0.325 0.02 0.25');
                    voltText.setAttribute('scale', '0.1 0.1 0.1');
                    voltText.setAttribute('rotation', '-90 0 0');
                    voltText.setAttribute('visible', true);
                    this.el.appendChild(voltText);
                    this.ammText = voltText;
                }
            }
            const setAmmeterDigits = () => {
                if (!this.voltText) {
                    const ammText = document.createElement('a-entity')
                    ammText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    ammText.setAttribute('position', '0.56 0.02 0.25');
                    ammText.setAttribute('scale', '0.1 0.1 0.1');
                    ammText.setAttribute('rotation', '-90 0 0');
                    ammText.setAttribute('visible', true);
                    this.el.appendChild(ammText);
                    this.voltText = ammText;
                }
            }
            const initialiseCircuitAnimations = () => {
                const animatedWireEl = this.circuit.object3D.getObjectByName('Scene') as any;
                this.wireFirstMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireSecondMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireThirdMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireFourthMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireFifthMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireSixthMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireSeventhMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireEightMixer = new THREE.AnimationMixer(animatedWireEl)
                this.wireNinthMixer = new THREE.AnimationMixer(animatedWireEl)
                this.switchMixer = new THREE.AnimationMixer(animatedWireEl)
                this.micrometerMixer = new THREE.AnimationMixer(animatedWireEl)
                const [Clip10to20, Clip20to30, Clip30to40, Clip40to50, Clip50to60, Clip60to70, Clip70to80, Clip80to90, Clip90to100, MicrometerAnim, OnOffSwitch] = animatedWireEl.animations
                this.wireFirstAnimation = this.wireFirstMixer.clipAction(Clip10to20)
                this.wireSecondAnimation = this.wireSecondMixer.clipAction(Clip20to30)
                this.wireThirdAnimation = this.wireThirdMixer.clipAction(Clip30to40)
                this.wireFourthAnimation = this.wireFourthMixer.clipAction(Clip40to50)
                this.wireFifthAnimation = this.wireFifthMixer.clipAction(Clip50to60)
                this.wireSixthAnimation = this.wireSixthMixer.clipAction(Clip60to70)
                this.wireSeventhAnimation = this.wireSeventhMixer.clipAction(Clip70to80)
                this.wireEightAnimation = this.wireEightMixer.clipAction(Clip80to90)
                this.wireNinthAnimation = this.wireNinthMixer.clipAction(Clip90to100)
                this.switchAnimation = this.switchMixer.clipAction(OnOffSwitch)
                this.micrometerAnimation = this.micrometerMixer.clipAction(MicrometerAnim)
                this.micrometerAnimation.repetitions = 1
                this.switchAnimation.setLoop(THREE.LoopPingPong, 1);
                this.wireFirstAnimation.timeScale = 1
                this.wireFirstAnimation.play()
                this.wireFirstAnimation.paused = true
            }
            const updateCircuitDynamicValues = (voltValue: string, ammValue: string, index: string) => {
                this.voltText.setAttribute('text', "value:; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                this.voltText.setAttribute('position', '0.383 0.02 0.3');
                this.ammText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                this.ammText.setAttribute('position', '0.56 0.02 0.3');
                this.screensTimeout = setTimeout(() => {
                    setNotebookValue(index)
                    this.plug.visible = false
                    this.switchAnimation.paused = false
                    this.switchToggle = false
                    this.voltText.setAttribute('text', `value: ${voltValue}; color: #8ad1a9; align: left; width: 10; wrap-count: 50;`);
                    this.voltText.setAttribute('position', '0.383 0.02 0.3');
                    this.ammText.setAttribute('text', `value: ${ammValue}; color: #8ad1a9; align: left; width: 10; wrap-count: 50;`);
                    this.ammText.setAttribute('position', '0.56 0.02 0.3');
                }, 500)
            }
        },
        tick(this: ICircuitControl, time: number, deltaTime: number) {
            const ring = document.getElementById('ring')
            if (!ring) {
                const circuit = document.getElementById('holder') as AFrame.Entity;
                const cameraEl = this.el.sceneEl?.querySelector('a-camera');
                if (cameraEl && circuit) {
                    const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                    let cameraPosition = camPos.clone()
                    let spherePos = circuit.object3D.position.clone()
                    let distance = cameraPosition.distanceTo(spherePos)
                    if (!this.distanceEvent) {
                        this.distanceEvent = new CustomEvent('distance-change', {detail: {value: false}});
                    }
                    if ((distance < 6 && cameraPosition.x < 3) || (distance < 8 && cameraPosition.x > 3) || (distance < 10 && cameraPosition.x > 5)) {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = true
                        scene.emit('distance-change', this.distanceEvent.detail.value);
                        circuit.object3D.visible = false
                    } else {
                        const scene = this.el.sceneEl as AFrame.Scene
                        this.distanceEvent.detail.value = false
                        scene.emit('distance-change', this.distanceEvent.detail.value);
                        if (!circuit.object3D.visible) {
                            circuit.object3D.visible = true
                        }
                    }
                }
            }
            if (this.switchMixer) {
                this.switchMixer.update(deltaTime * 0.001);
            }
            if (this.wireFirstMixer) {
                this.wireFirstMixer.update(deltaTime * 0.001);
            }
            if (this.wireSecondMixer) {
                this.wireSecondMixer.update(deltaTime * 0.001);
            }
            if (this.wireThirdMixer) {
                this.wireThirdMixer.update(deltaTime * 0.001);
            }
            if (this.wireFourthMixer) {
                this.wireFourthMixer.update(deltaTime * 0.001);
            }
            if (this.wireFifthMixer) {
                this.wireFifthMixer.update(deltaTime * 0.001);
            }
            if (this.wireSixthMixer) {
                this.wireSixthMixer.update(deltaTime * 0.001);
            }
            if (this.wireSeventhMixer) {
                this.wireSeventhMixer.update(deltaTime * 0.001);
            }
            if (this.wireEightMixer) {
                this.wireEightMixer.update(deltaTime * 0.001);
            }
            if (this.wireNinthMixer) {
                this.wireNinthMixer.update(deltaTime * 0.001);
            }
            if (this.micrometerMixer) {
                this.micrometerMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {CircuitControlComponent}
