import React, { useRef, useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { AtomsSceneComponent } from './atoms-scene';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  PLAY_HINT,
} from 'shared/constants/hint-items';
import {TapPlaceAir} from "../../../lib/aframe/components/tap-place-air";
import { LoadersPermissonsEnum } from 'shared/enums';
const introText = 'Explore the atom to learn all about electronic structure!';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, PLAY_HINT];

const ChemistryElectronStructureAframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const onLessonStarted = () => {
    setLessonStarted(true);
  };
  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };

  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };
  const onToggleAnimation = () => {
    setToggle(!toggle);
  };
  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };

  const animToggleEventRef = useRef(
    new CustomEvent('anim-toggle', { detail: { toggle: false } })
  );
  React.useEffect(() => {
    // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      console.log('Scene loaded');
      // Update the assetChangeEvent detail before dispatching
      animToggleEventRef.current.detail.toggle = toggle;
      scene.dispatchEvent(animToggleEventRef.current);
    }
  }, [toggle]);
  const onLessonRecenter = () => {
    setLessonStarted(false);
    setToggle(true);
  };
  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        lessonStarted={lessonStarted}
        onToggleAnimation={onToggleAnimation}
        toggleAnimationStatus={toggle}
        onAnnotationStateChanged={onAnnotationStateChanged}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./chemistry-electron-structure-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          TapPlaceAir,
          WorldButtonComponent,
          FaceCameraComponent,
          AtomsSceneComponent,
          Annotation,
        ]}
        onObjectSelected={onObjectSelected}
        annotationState={annotationState}
        onLessonRecenter={onLessonRecenter}
        onLessonStart={onLessonStarted}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { ChemistryElectronStructureAframe };
