import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { StyledInput } from './StyledInput';
import { StyledTextArea } from './StyledTextArea';
import { useFormikContext } from 'formik';

type Props = {
  label: string;
  type?: 'text' | 'email' | 'password';
  placeholder?: string;
  name: string;
  disabled?: any;
  initialValue?: string;
  textArea?: boolean;
  id: string;
  defaultValue?: string;
  required?: boolean;
};

export function TextField(props: Props) {
  const {
    label,
    type,
    placeholder = 'text',
    name,
    id,
    disabled,
    initialValue = '',
    textArea = false,
    defaultValue,
    required = true,
  } = props;
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext<any>();

  const handleChange = useCallback(
    (event: any) => {
      setFieldValue(name || id, event.target.value);
    },
    [setFieldValue, name, id]
  );

  return (
    <Stack>
      {!required && (
        <Typography
          fontSize={16}
          fontFamily='GilroySemiBold'
          color='rgba(43, 43, 43, .7)'
          mb={1}
        >
          {label}
        </Typography>
      )}
      {required && (
        <Box
          position='relative'
          width='fit-content'
          fontFamily='GilroySemiBold'
          color='rgba(43, 43, 43, .7)'
          fontSize={16}
					mb={1}
        >
          {label}
          <Box position='absolute' top='0' right='-10px'>
            *
          </Box>
        </Box>
      )}
      {!textArea && (
        <StyledInput
          id={name}
          name={name}
          type={type}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          defaultValue={defaultValue || ''}
        />
      )}
      {textArea && (
        <StyledTextArea
          id={name}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          defaultValue={defaultValue || ''}
        />
      )}
    </Stack>
  );
}
