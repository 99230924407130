import { TrainingLevelEnum } from './enums';

export const levelColorUtil = (type: string) => {
  switch (type) {
    case TrainingLevelEnum.HighSchool:
      return '141, 141, 141';
    case TrainingLevelEnum.MiddleSchool:
      return '141, 141, 141';
    case TrainingLevelEnum.ElementarySchool:
      return '141, 141, 141';
  }
};
