// src/components/Introduction.tsx
import React from 'react';
import '../styles/introduction.css';
import '../styles/transitions.css';

interface IntroductionProps {
    text: string;
    visible: boolean;
  }

const Introduction: React.FC<IntroductionProps> = ({ text, visible }) => {
  const introClass = visible ? 'introContainer' : 'introContainer slide-down';
  
  return (
    <div id="introContainer" className={introClass}>
      <div className="introduction">
        <p className="introductionTitle">Introduction</p>
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
    </div>
  );
};

export default Introduction;