import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';

interface IAtomsSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    lithiumHandler: () => void;
    sodiumHandler: () => void;
    potassiumHandler: () => void;
    rubidiumHandler: () => void;
    caesiumHandler: () => void;
    franciumHandler: () => void;
    currentHandler: () => void;
    lithiumAtom: AFrame.Entity;
    sodiumAtom: AFrame.Entity;
    potassiumAtom: AFrame.Entity;
    rubidiumAtom: AFrame.Entity;
    caesiumAtom: AFrame.Entity;
    franciumAtom: AFrame.Entity;
    lithiumMixer: THREE.AnimationMixer;
    lithiumCoreAnimation: THREE.AnimationAction;
    lithiumElectronsAnimation: THREE.AnimationAction;
    sodiumMixer: THREE.AnimationMixer;
    sodiumCoreAnimation: THREE.AnimationAction;
    sodiumElectronsAnimation: THREE.AnimationAction;
    potassiumMixer: THREE.AnimationMixer;
    potassiumCoreAnimation: THREE.AnimationAction;
    potassiumElectronsAnimation: THREE.AnimationAction;
    rubidiumMixer: THREE.AnimationMixer;
    rubidiumCoreAnimation: THREE.AnimationAction;
    rubidiumElectronsAnimation: THREE.AnimationAction;
    caesiumMixer: THREE.AnimationMixer;
    caesiumCoreAnimation: THREE.AnimationAction;
    caesiumElectronsAnimation: THREE.AnimationAction;
    franciumMixer: THREE.AnimationMixer;
    franciumCoreAnimation: THREE.AnimationAction;
    franciumElectronsAnimation: THREE.AnimationAction;
    currentCoreAnimation: THREE.AnimationAction;
    currentElectronAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; image: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    prevEl: AFrame.Entity;
    currentAssetId: number;
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: IAtomsSceneAframe) {
            const atoms = document.getElementById('atomsHolder') as AFrame.Entity;
            atoms.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                this.el.sceneEl?.addEventListener('lesson-start', () => {
                    // remove tap place
                    const ring = document.getElementById('ring')
                    if (ring) {
                        ring.removeAttribute('tap-place-air')
                        this.el.sceneEl?.removeChild(ring)
                    }
                    this.lithiumAtom = document.getElementById('lithiumAtom') as AFrame.Entity;
                    this.sodiumAtom = document.getElementById('sodiumAtom') as AFrame.Entity;
                    this.potassiumAtom = document.getElementById('potassiumAtom') as AFrame.Entity;
                    this.rubidiumAtom = document.getElementById('rubidiumAtom') as AFrame.Entity;
                    this.caesiumAtom = document.getElementById('caesiumAtom') as AFrame.Entity;
                    this.franciumAtom = document.getElementById('franciumAtom') as AFrame.Entity;
                    initialiseAnimations()

                    if (!this.currentHandler) this.currentHandler = this.lithiumHandler
                    if (!this.prevEl) this.prevEl = this.lithiumAtom
                    this.prevEl.object3D.visible = true
                    this.currentHandler()
                    this.currentCoreAnimation.play()
                    this.currentElectronAnimation.play()
                })
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: atomsHolder; scale: 10 10 10; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                }
            });

            this.currentAssetId = 0;
            this.el.sceneEl?.addEventListener('asset-change', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const newAssetId = customEvent.detail.assetId;
                switch (newAssetId) {
                    case 0:
                        this.lithiumHandler()
                        this.currentHandler = this.lithiumHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.lithiumAtom.object3D.visible = true
                        this.lithiumElectronsAnimation.play()
                        this.lithiumCoreAnimation.play()
                        this.prevEl = this.lithiumAtom
                        break;
                    case 1:
                        this.sodiumHandler()
                        this.currentHandler = this.sodiumHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.sodiumAtom.object3D.visible = true
                        this.sodiumElectronsAnimation.play()
                        this.sodiumCoreAnimation.play()
                        this.prevEl = this.sodiumAtom
                        break;
                    case 2:
                        this.potassiumHandler()
                        this.currentHandler = this.potassiumHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.potassiumAtom.object3D.visible = true
                        this.potassiumElectronsAnimation.play()
                        this.potassiumCoreAnimation.play()
                        this.prevEl = this.potassiumAtom
                        break;
                    case 3:
                        this.rubidiumHandler()
                        this.currentHandler = this.rubidiumHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.rubidiumAtom.object3D.visible = true
                        this.rubidiumElectronsAnimation.play()
                        this.rubidiumCoreAnimation.play()
                        this.prevEl = this.rubidiumAtom
                        break;
                    case 4:
                        this.caesiumHandler()
                        this.currentHandler = this.caesiumHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.caesiumAtom.object3D.visible = true
                        this.caesiumElectronsAnimation.play()
                        this.caesiumCoreAnimation.play()
                        this.prevEl = this.caesiumAtom
                        break;
                    case 5:
                        this.franciumHandler()
                        this.currentHandler = this.franciumHandler
                        if (this.prevEl) this.prevEl.object3D.visible = false
                        this.franciumAtom.object3D.visible = true
                        this.franciumElectronsAnimation.play()
                        this.franciumCoreAnimation.play()
                        this.prevEl = this.franciumAtom
                        break;
                }
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    if (this.currentCoreAnimation && this.currentElectronAnimation) {
                        this.currentCoreAnimation.paused = false;
                        this.currentElectronAnimation.paused = false;
                    }
                } else {
                    if (this.currentCoreAnimation && this.currentElectronAnimation) {
                        this.currentCoreAnimation.paused = true;
                        this.currentElectronAnimation.paused = true;
                    }
                }
            })
            this.lithiumHandler = () => {
                this.currentCoreAnimation = this.lithiumCoreAnimation
                this.currentElectronAnimation = this.lithiumElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Alkali-metals-annotations/LithiumCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.sodiumHandler = () => {
                this.currentCoreAnimation = this.sodiumCoreAnimation
                this.currentElectronAnimation = this.sodiumElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = '';
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Alkali-metals-annotations/SodiumCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.potassiumHandler = () => {
                this.currentCoreAnimation = this.potassiumCoreAnimation
                this.currentElectronAnimation = this.potassiumElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Alkali-metals-annotations/PotassiumCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.rubidiumHandler = () => {
                this.currentCoreAnimation = this.rubidiumCoreAnimation
                this.currentElectronAnimation = this.rubidiumElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Alkali-metals-annotations/RubidiumCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            this.caesiumHandler = () => {
                this.currentCoreAnimation = this.caesiumCoreAnimation
                this.currentElectronAnimation = this.caesiumElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Alkali-metals-annotations/CaesiumCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            this.franciumHandler = () => {
                this.currentCoreAnimation = this.franciumCoreAnimation
                this.currentElectronAnimation = this.franciumElectronsAnimation
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/Alkali-metals-annotations/FranciumCard.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseAnimations = () => {
                const animatedLithiumEl = this.lithiumAtom.object3D.getObjectByName('Scene') as any;
                this.lithiumMixer = new THREE.AnimationMixer(animatedLithiumEl)
                const [LithiumElectronsAnim, LithiumCoreAnim] = animatedLithiumEl.animations
                this.lithiumElectronsAnimation = this.lithiumMixer.clipAction(LithiumElectronsAnim)
                this.lithiumCoreAnimation = this.lithiumMixer.clipAction(LithiumCoreAnim)

                const animatedSodiumEl = this.sodiumAtom.object3D.getObjectByName('Scene') as any;
                this.sodiumMixer = new THREE.AnimationMixer(animatedSodiumEl)
                const [SodiumElectronsAnim, SodiumCoreAnim] = animatedSodiumEl.animations
                this.sodiumElectronsAnimation = this.sodiumMixer.clipAction(SodiumElectronsAnim)
                this.sodiumCoreAnimation = this.sodiumMixer.clipAction(SodiumCoreAnim)

                const animatedPotassiumEl = this.potassiumAtom.object3D.getObjectByName('Scene') as any;
                this.potassiumMixer = new THREE.AnimationMixer(animatedPotassiumEl)
                const [PotassiumElectronsAnim, PotassiumCoreAnim] = animatedPotassiumEl.animations
                this.potassiumElectronsAnimation = this.potassiumMixer.clipAction(PotassiumElectronsAnim)
                this.potassiumCoreAnimation = this.potassiumMixer.clipAction(PotassiumCoreAnim)

                const animatedRubidiumEl = this.rubidiumAtom.object3D.getObjectByName('Scene') as any;
                this.rubidiumMixer = new THREE.AnimationMixer(animatedRubidiumEl)
                const [RubidiumElectronsAnim, RubidiumCoreAnim] = animatedRubidiumEl.animations
                this.rubidiumElectronsAnimation = this.rubidiumMixer.clipAction(RubidiumElectronsAnim)
                this.rubidiumCoreAnimation = this.rubidiumMixer.clipAction(RubidiumCoreAnim)

                const animatedCaesiumEl = this.caesiumAtom.object3D.getObjectByName('Scene') as any;
                this.caesiumMixer = new THREE.AnimationMixer(animatedCaesiumEl)
                const [CaesiumElectronsAnim, CaesiumCoreAnim] = animatedCaesiumEl.animations
                this.caesiumElectronsAnimation = this.caesiumMixer.clipAction(CaesiumElectronsAnim)
                this.caesiumCoreAnimation = this.caesiumMixer.clipAction(CaesiumCoreAnim)

                const animatedFranciumEl = this.franciumAtom.object3D.getObjectByName('Scene') as any;
                this.franciumMixer = new THREE.AnimationMixer(animatedFranciumEl)
                const [FranciumElectronsAnim, FranciumCoreAnim] = animatedFranciumEl.animations
                this.franciumElectronsAnimation = this.franciumMixer.clipAction(FranciumElectronsAnim)
                this.franciumCoreAnimation = this.franciumMixer.clipAction(FranciumCoreAnim)

            }
        },
        tick(this: IAtomsSceneAframe, time: number, deltaTime: number) {
            if (this.lithiumMixer) {
                this.lithiumMixer.update(deltaTime * 0.001);
            }
            if (this.sodiumMixer) {
                this.sodiumMixer.update(deltaTime * 0.001);
            }
            if (this.potassiumMixer) {
                this.potassiumMixer.update(deltaTime * 0.001);
            }
            if (this.rubidiumMixer) {
                this.rubidiumMixer.update(deltaTime * 0.001);
            }
            if (this.caesiumMixer) {
                this.caesiumMixer.update(deltaTime * 0.001);
            }
            if (this.franciumMixer) {
                this.franciumMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {AtomsScene as AtomsSceneComponent}
