import React, { useRef, useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { FoodTestsStarchSceneComponent } from './food-tests-lipids-scene';
import { TapPlaceCustom } from 'lib/aframe/components/tap-place-custom';
import { TapPlaceComponent } from '../../../lib/aframe/components/tap-place-object';

import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';

import Flatware from '../../../assets/img/bio-food-test-starch/Flatware.png';
import Glassware from '../../../assets/img/bio-food-test-starch/Glassware.png';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  MODEL3D_HINT,
} from 'shared/constants/hint-items';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
  '<p>These are examples of different foods that contain lipids.</p><p>Explore the drop down menu to find the steps to test for lipids.</p>';

const assetIcons = [Flatware, Glassware];

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const BiologyFoodTextsLipidsAframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
    image?: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };
  const [assetId, setAssetId] = useState(0);
  const changeAsset = (assetId: number) => {
    setAssetId(assetId);
  };
  const onLesstonStarted = () => {
    setLessonStarted(true);
  };
  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };

  /**
   * Asset changing functionality
   * Lesson ui would update the asset id state
   * Then dispatch an event to the aframe scene
   * And listen to it in any of the aframe scene components
   */
  const assetChangeEventRef = useRef(
    new CustomEvent('asset-change', { detail: { assetId: 0 } })
  );
  React.useEffect(() => {
    // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      console.log('Scene loaded');
      // Update the assetChangeEvent detail before dispatching
      assetChangeEventRef.current.detail.assetId = assetId;
      scene.dispatchEvent(assetChangeEventRef.current);
    }
  }, [assetId]);
  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        onAnnotationStateChanged={onAnnotationStateChanged}
        lessonStarted={lessonStarted}
        assetIcons={assetIcons}
        onAssetButtonClick={changeAsset}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./bio-food-test-lipids-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          WorldButtonComponent,
          FaceCameraComponent,
          TapPlaceComponent,
          TapPlaceCustom,
          FoodTestsStarchSceneComponent,
          Annotation,
        ]}
        onObjectSelected={onObjectSelected}
        onLessonStart={onLesstonStarted}
        annotationState={annotationState}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { BiologyFoodTextsLipidsAframe };
