import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IMassAndWeightSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolButtons: PoolComponent;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionDropAnim: THREE.AnimationAction;
	actionFloatAnim: THREE.AnimationAction;

	currentClips: THREE.AnimationAction[];
	mixer: THREE.AnimationMixer;

	groupElement: AFrame.Entity | null;
	el: AFrame.Entity;

	positionATriggerBtn: AFrame.Entity | null;
	positionBTriggerBtn: AFrame.Entity | null;
	positionCTriggerBtn: AFrame.Entity | null;
	positionDTriggerBtn: AFrame.Entity | null;

	positionAButtonHandler: () => void;
	positionBButtonHandler: () => void;
	positionCButtonHandler: () => void;
	positionDButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const MassAndWeightScene = {
	name: 'experiment',
	val: {
		init(this: IMassAndWeightSceneAframe) {
			var isInitialised = false;
			this.el.addEventListener('model-loaded', () => {
				if (!isInitialised) {
					this.groupElement = document.getElementById('model') as AFrame.Entity;

					this.currentClips = [];

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;
					firstScene();
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started');
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place');
					this.el.sceneEl?.removeChild(ring);
				}
			})

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: SceneHolder; offset: 0 0 -3');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}
			});

			const firstScene = () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}
				this.currentClips[0] = this.actionFloatAnim;
				this.actionFloatAnim.reset();
				this.actionFloatAnim.play();

				this.currentClips[1] = this.actionDropAnim;
				this.actionDropAnim.reset();
				this.actionDropAnim.repetitions = 1;
				this.actionDropAnim.clampWhenFinished = false;
				this.actionDropAnim.play();
				this.actionDropAnim.paused = true;
			}

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			const initialiseAnimations = () => {
				const animatedProcess = this.groupElement?.object3D.getObjectByName('Physics38_MassAndWeight') as any;

				this.mixer = new THREE.AnimationMixer(animatedProcess);

				const showDropAnim = animatedProcess?.animations[0];
				const showFloatAnim = animatedProcess?.animations[1];

				this.actionDropAnim = this.mixer.clipAction(showDropAnim);
				this.actionFloatAnim = this.mixer.clipAction(showFloatAnim);
			};

			var buttonAEnabled = true;
			this.positionAButtonHandler = () => {

				if (buttonAEnabled) {
					this.actionDropAnim.paused = false;
					buttonAEnabled = false;

					setTimeout(() => {
						firstScene();
						buttonAEnabled = true;
					}, 8100);
				}
				if (this.onObjectSelected) {
					const title = 'Hammer and Feather';
					const body = 'The acceleration due to gravity is the same for all objects. On Earth there are other factors like air resistance, but on the Moon, any two objects <b>dropped</b> at the same time will <b>land</b> at the same time.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionBButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Mass vs Weight';
					const body = 'Mass (measured in kg) is a measure of how much of a substance there is. Objects with a larger mass feel a greater force due to gravity; this is known as their weight (measured in Newtons).';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionCButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = '<span class="smaller-title-annotation">Gravitational Field Strength</span>';
					const body = 'The weight of an object depends on the gravitational field strength caused by very large objects. Since the Moon’s field strength is roughly a sixth of Earth’s, astronauts have a smaller weight on the Lunar surface even though their mass is unchanged.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionDButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Apollo 15';
					const body = 'In 1971 on the Apollo 15 mission, Commander David Scott demonstrated that all objects feel the same acceleration due to gravity by simultaneously dropping a hammer and feather and watching them hit the ground at the exact same time.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.positionATriggerBtn = this.poolButtons.requestEntity();
				this.positionATriggerBtn?.object3D.position.set(-0.4, 6, 5);
				this.positionATriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionATriggerBtn?.play();
				this.positionATriggerBtn?.addEventListener('click', () => {
					this.positionAButtonHandler();
					if (this.positionATriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionATriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.positionATriggerBtn;
					}
				});

				this.positionBTriggerBtn = this.poolButtons.requestEntity();
				this.positionBTriggerBtn?.object3D.position.set(2.2, 8.5, 0.6);
				this.positionBTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionBTriggerBtn?.play();
				this.positionBTriggerBtn?.addEventListener('click', () => {
					this.positionBButtonHandler();
					if (this.positionBTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionBTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionBTriggerBtn;
					}
				});

				this.positionCTriggerBtn = this.poolButtons.requestEntity();
				this.positionCTriggerBtn?.object3D.position.set(3.4, 5.5, 0.6);
				this.positionCTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionCTriggerBtn?.play();
				this.positionCTriggerBtn?.addEventListener('click', () => {
					this.positionCButtonHandler();
					if (this.positionCTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionCTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionCTriggerBtn;
					}
				});

				this.positionDTriggerBtn = this.poolButtons.requestEntity();
				this.positionDTriggerBtn?.object3D.position.set(-1.6, 1.6, 5);
				this.positionDTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionDTriggerBtn?.play();
				this.positionDTriggerBtn?.addEventListener('click', () => {
					this.positionDButtonHandler();
					if (this.positionDTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionDTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.positionDTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.positionDTriggerBtn
					}
				});
			}
		},
		tick(this: IMassAndWeightSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};

export { MassAndWeightScene as MassAndWeightComponent }