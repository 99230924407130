import { useCallback } from 'react';
import { useSharedContext } from '../context';
import { changeRoleTypeActionCreator } from 'shared/state/Actions/ChangeRoleType';

export function useSetRoleType() {
  const [, dispatch] = useSharedContext();

  return useCallback(() => {
    dispatch(
      changeRoleTypeActionCreator(localStorage.getItem('roleType') ?? '')
    );
  }, [dispatch]);
}
