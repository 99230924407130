import React, {useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import {TapPlaceComponent} from '../../../lib/aframe/components/tap-place-object';
import {WorldButtonComponent} from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import {HRDiagramsComponent} from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
    Annotation,
    AnnotationState,
} from '../../../lib/aframe/components/annotation';
import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
    MODEL3D_HINT,
} from 'shared/constants/hint-items';
import {LoadersPermissonsEnum} from 'shared/enums';

const introText =
    'Explore the annotations to learn about the Hertzprung-Russell diagram, its interesting shapes and unconventional axes.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const PhysicsHRDiagramsAframe = () => {
    const [selectedObject, setSelectedObject] = useState<{
        title: string;
        body: string;
    } | null>(null);

    const [annotationState, setAnnotationState] = useState<AnnotationState>(
        AnnotationState.None
    );
    const [lessonStarted, setLessonStarted] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };
    const onLessonStarted = () => {
        setLessonStarted(true);
    };
    const permissionsConfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };

    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };
    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                lessonStarted={lessonStarted}
                onAnnotationStateChanged={onAnnotationStateChanged}
                hintItems={hintItems}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./physics-hr-diagrams-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    HRDiagramsComponent,
                    TapPlaceComponent,
                    WorldButtonComponent,
                    FaceCameraComponent,
                    Annotation,
                ]}
                onObjectSelected={onObjectSelected}
                onLessonStart={onLessonStarted}
                annotationState={annotationState}
                permissionsСonfirmation={permissionsConfirmation}
            />
        </React.Fragment>
    );
};
export {PhysicsHRDiagramsAframe};
