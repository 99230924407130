import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';

export function useGoToMain(showThankYou: any) {
  const navigate = useNavigate();
  const [, setShowThankYou] = useState(showThankYou);

  return useCallback(() => {
    setShowThankYou(false);

    navigate('/');
  }, [setShowThankYou, navigate]);
}
