import React, { useRef, useState } from 'react';
import { AFrameScene, DISABLE_IMAGE_TARGETS } from '../../../lib/aframe-component';
import { Annotation, AnnotationState } from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';

import { TapPlaceCustom } from 'lib/aframe/components/tap-place-custom';
import { TapPlaceComponent } from '../../../lib/aframe/components/tap-place-object'

import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';


import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
    MODEL3D_HINT,
} from 'shared/constants/hint-items';

import { PlantResponsesComponent } from './plant-responses-scene';
import { LoadersPermissonsEnum } from 'shared/enums';

const sceneA = 'https://bridgear.blob.core.windows.net/public/Biology/PlantResponses/UI_ButtonA.png';
const sceneB = 'https://bridgear.blob.core.windows.net/public/Biology/PlantResponses/UI_ButtonB.png';
const sceneC = 'https://bridgear.blob.core.windows.net/public/Biology/PlantResponses/UI_ButtonC.png';
const sceneD = 'https://bridgear.blob.core.windows.net/public/Biology/PlantResponses/UI_ButtonD.png';


const assetIcons = [sceneA, sceneB, sceneC, sceneD];

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const introText = 'Use the drop down menu to investigate the effect of light and gravity on newly germinated mustard seeds.'

const BiologyPlantResponsesAframe = (() => {
    const [selectedObject, setSelectedObject] = useState<{ title: string; body: string; image?: string } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(AnnotationState.None);
    const [lessonStarted, setLessonStarted] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
    
    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };
    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };

    const onLesstonStarted = () => {
        setLessonStarted(true);
    };

    const [assetId, setAssetId] = useState(0);
    const changeAsset = (assetId: number) => {
        
        setAssetId(assetId);
    }
    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    }
    /**
     * Asset changing functionality
     * Lesson ui would update the asset id state
     * Then dispatch an event to the aframe scene
     * And listen to it in any of the aframe scene components
     */
    const assetChangeEventRef = useRef(new CustomEvent('asset-change', { detail: { assetId: 0 } }));
    React.useEffect(() => {
        // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            console.log('Scene loaded');
            // Update the assetChangeEvent detail before dispatching
            assetChangeEventRef.current.detail.assetId = assetId;
            scene.dispatchEvent(assetChangeEventRef.current);
        }
    }, [assetId]);


    return (
        <React.Fragment>
            <LessonUI flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                onAnnotationStateChanged={onAnnotationStateChanged}
                lessonStarted={lessonStarted}
                assetIcons={assetIcons}
                onAssetButtonClick={changeAsset}
                hintItems={hintItems}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./byology-plant-responses-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[WorldButtonComponent, FaceCameraComponent, TapPlaceComponent, TapPlaceCustom, PlantResponsesComponent, Annotation]}
                onObjectSelected={onObjectSelected}
                onLessonStart={onLesstonStarted}
                annotationState={annotationState}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>)
})
export { BiologyPlantResponsesAframe }