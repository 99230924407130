import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Form, useFormikContext } from 'formik';
import { ContactUs } from '../typings';
import { DisplayEnum, RoleEnum } from '../enums';
import { CustomTextField } from './CustomTextField';
import { useCallback, useEffect, useState } from 'react';
import { useGoToMain } from '../hooks/useGoToMain';
import { useSharedContext } from '../context';
import { useTranslation } from 'react-i18next';

type Props = {
  onMainPage: boolean;
};

export function ContactUsForm(props: Props) {
  const { onMainPage } = props;
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const { touched, errors, isSubmitting, isValid, values } =
    useFormikContext<ContactUs>();
  const enabled =
    (isValid && (touched.name || touched.email)) ||
    (!touched.name && !touched.email);
  const [showThankYou, setShowThankYou] = useState(false);
  const goToMain = useGoToMain(setShowThankYou);
  const [{ roleType }] = useSharedContext();
  const { t, i18n } = useTranslation();
  const i18nPath = 'LANDING_PAGE.JOIN_THE_COMMUNITY_COMPONENT.';

  useEffect(() => {
    if (
      isValid &&
      isSubmitting &&
      Object.keys(touched).length &&
      !Object.keys(errors).length &&
      Object.values(values).filter((value) => !!value).length > 2
    ) {
      setShowThankYou(true);
    }
  }, [isSubmitting, isValid, errors, touched, values]);

  const handleSendAgain = useCallback(() => {
    setShowThankYou(false);
  }, [setShowThankYou]);

  return (
    <Form>
      <Stack
        width={tablet ? '100vw' : 1050}
        height={'100%'}
        boxSizing='border-box'
      >
        {/* Displayed when a message is sent */}
        {showThankYou && (
          <Stack
            width='100%'
            boxShadow='0px 24px 76px #0000001C'
            borderRadius='30px'
            bgcolor='#fff'
            py={tablet ? 3 : 6}
            px={tablet ? 1.6 : 6}
            boxSizing='border-box'
            height={505}
            alignItems='center'
            justifyContent='center'
          >
            <Typography
              color='#000'
              fontFamily='GilroyBold'
              fontSize={26}
              mb={3}
              lineHeight={1}
              zIndex={4}
            >
              {t(i18nPath + 'THANK_YOU')}
            </Typography>
            <Typography
              color={theme.palette.text.secondary}
              fontFamily='GilroyMedium'
              fontSize={16}
              mb={3}
              width={mobile ? '100%' : 445}
              mx={'auto'}
            >
              {t(i18nPath + 'INFO_PART_1')} <br /> {t(i18nPath + 'INFO_PART_2')}
            </Typography>
            <Box mb={2}>
              <Button variant='contact_us_purple' onClick={handleSendAgain}>
                {t('COMMON.BUTTON.SEND_MESSAGES_AGAIN')}
              </Button>
            </Box>
            {!onMainPage && (
              <Box>
                <Button variant='contact_us_purple' onClick={goToMain}>
                  {t('COMMON.BUTTON.GO_TO_MAIN_PAGE')}
                </Button>
              </Box>
            )}
          </Stack>
        )}
        {/* Displayed by default */}
        {!showThankYou && (
          <Stack
            width='100%'
            maxWidth={tablet ? '100vw' : 1050}
            boxShadow='0px 24px 76px #0000001C'
            borderRadius='30px'
            bgcolor='#fff'
            py={tablet ? 3 : 6}
            px={tablet ? 1.6 : 6}
            boxSizing='border-box'
            direction={tablet ? 'column' : 'row'}
            justifyContent='space-between'
          >
            <Stack width={tablet ? '100%' : 420}>
              <Typography
                color='#000'
                fontFamily='GilroyBold'
                fontSize={tablet ? 26 : 44}
                mb={3}
                lineHeight={1}
                zIndex={4}
              >
                {t(i18nPath + 'TITLE')}
              </Typography>
              <Typography
                color={theme.palette.text.secondary}
                fontFamily='GilroyMedium'
                fontSize={16}
                mb={3}
                width={tablet ? '100%' : 420}
                mx={'auto'}
                textAlign='justify'
              >
                {roleType === RoleEnum.Student
                  ? t(i18nPath + 'DESCRIPTION.STUDENT')
                  : t(i18nPath + 'DESCRIPTION.TEACHER')}
              </Typography>
            </Stack>
            <Stack width={tablet ? '100%' : 420} mx={tablet ? 'auto' : 0}>
              <Box mb={1} pb={2} textAlign='left'>
                <CustomTextField<ContactUs>
                  id='name'
                  name='name'
                  label={t(i18nPath + 'INPUTS.NAME.LABEL')}
                  type='text'
                  placeholder={t(i18nPath + 'INPUTS.NAME.PLACEHOLDER') || ''}
                />
              </Box>

              <Box mb={1} pb={2} textAlign='left'>
                <CustomTextField<ContactUs>
                  id='email'
                  name='email'
                  label={t(i18nPath + 'INPUTS.EMAIL.LABEL')}
                  type='email'
                  placeholder={t(i18nPath + 'INPUTS.EMAIL.PLACEHOLDER') || ''}
                />
              </Box>

              <Box mb={1} pb={2} textAlign='left'>
                <CustomTextField<ContactUs>
                  id='organization'
                  name='organization'
                  label={t(i18nPath + 'INPUTS.ORGANIZATION.LABEL')}
                  type='text'
                  placeholder={
                    t(i18nPath + 'INPUTS.ORGANIZATION.PLACEHOLDER') || ''
                  }
                />
              </Box>

              {/* <Box mb={1} pb={2} textAlign='left'>
                <CustomTextField<ContactUs>
                  id='message'
                  name='message'
                  label='Message'
                  type='text'
                  placeholder='Here you can write your message'
                  textArea
                />
              </Box> */}

              <Button
                variant='contact_us_purple'
                fullWidth
                type='submit'
                disabled={!enabled}
              >
                {t('COMMON.BUTTON.JOIN_THE_COMMUNITY')}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Form>
  );
}
