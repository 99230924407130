import annotation from '../../assets/hint/annotation.gif';
import info from '../../assets/hint/info.gif';
import play from '../../assets/hint/play.gif';
import quiz from '../../assets/hint/quiz.gif';
import model3d from '../../assets/hint/model3d.gif';

export const ANNOTATION_HINT = { path: annotation, alt: 'annotation' };

export const QUIZ_HINT = { path: quiz, alt: 'quiz' };

export const INFO_HINT = { path: info, alt: 'more information' };

export const PLAY_HINT = { path: play, alt: 'play' };

export const MODEL3D_HINT = { path: model3d, alt: 'model 3D' };
