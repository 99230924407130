
import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IAnnotationSystemAframe } from "../../../lib/aframe/systems/annotation-system";
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IWaterMachineAframe } from './particle-effects';

interface IHouseControl {
    waterMachine: IWaterMachineAframe;
    actionWheels: THREE.AnimationAction;
    actionLift: THREE.AnimationAction;
    currentDeactivatedButton: any;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    mixer: THREE.AnimationMixer;
    el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

const HouseControlComponent = {
    name: 'lesson-control',
    val: {
        init(this: IHouseControl) {
            // Add 'model-loaded' event listener to the component
            this.el.addEventListener('model-loaded', () => {
                initialiseAnimations();
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				// ony initialise buttons once pool has loaded
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}

                // fix all issues related to the current model
                fixModelIssues();

            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                }

                this.waterMachine = this.el.components['water-machine'] as unknown as IWaterMachineAframe;
                this.waterMachine.startSpawning();
                this.waterMachine.setVisible(true);

            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: aqueduct; scale: 3.75 3.75 3.75; relativeRotation: 0 -90 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }

                    if (this.waterMachine) {
                        this.waterMachine.setVisible(false);
                        
                    }
                }
                
            });

            const initialiseAnimations = () => {
                this.el.object3D.traverse((child) => {
                    if (child.animations.length > 0) {
                        console.log(child);
                    }
                });

                const model = document.getElementById('aqueduct') as AFrame.Entity;
                const animatedEl = model?.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [MoveV2, RotateWheels] = animatedEl.animations
                this.actionWheels = this.mixer.clipAction(RotateWheels)
                this.actionLift = this.mixer.clipAction(MoveV2)
                // start animations when
                startAnimations();
            }

            const startAnimations = () => {
                this.actionWheels.timeScale = 0.145;
                // this.actionWheels.setDuration(2);
                this.actionWheels.play();
                this.actionLift.timeScale = 0.145;
                this.actionLift.play();
            };

            const maintainingButtonHandler = () => {
                const title = 'Maintaining Potential'
                const body = "The height of the water shows its potential. Splitting the channels does not change the water’s height, so it has the same potential.<br/>Similarly, two loops of a parallel circuit each get the same voltage supplied to them."

                if (this.onObjectSelected) {
                    console.log('Calling onObjectSelected callback');
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const componentButtonHandler = () => {
                const title = 'The Components'
                const body = "The water converts its potential energy to do work on the water wheels. Similarly, in a circuit, charges exchange their potential (given to them by a power supply) to do work on the components."

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const combiningButtonHandler = () => {
                const title = 'Combining Currents'
                const body = 'When two aqueducts combine, the amount of water flowing per second (the current) adds together. Similarly in a circuit, when two loops in a circuit combine, their currents add together.'

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
            }

            const workingOvertimeButtonHandler = () => {
                const title = 'Working Overtime'
                const body = "By adding another loop, the water lift must work twice as fast but it still brings water to the same height. <br />Similarly, electrical cells always have the same voltage but have to do more work to accommodate for the higher current."

                if (this.onObjectSelected) {
                    this.onObjectSelected({ title, body })
                } else {
                    console.log('No object selected method')
                }
               
            }

            const initialiseButtons = () => {
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const maintainingTriggerBtn = poolButtons.requestEntity();
                maintainingTriggerBtn?.setAttribute('position', '-0.3 1.5 -1');
                maintainingTriggerBtn?.play();
                maintainingTriggerBtn?.addEventListener('click', () => {
                    maintainingButtonHandler();
                    if (maintainingTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(maintainingTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(maintainingTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = maintainingTriggerBtn
                    }
                });

                const componentTriggerBtn = poolButtons.requestEntity()
                componentTriggerBtn?.setAttribute('position', '0.5 0.5 -1.1')
                componentTriggerBtn?.play()
                componentTriggerBtn?.addEventListener('click', () => {
                    componentButtonHandler();
                    if (componentTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(componentTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(componentTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = componentTriggerBtn
                    }
                });

                const combiningTriggerBtn = poolButtons.requestEntity()
                combiningTriggerBtn?.setAttribute('position', '0.6 0.2 0.7')
                combiningTriggerBtn?.play()
                combiningTriggerBtn?.addEventListener('click', () => {
                    combiningButtonHandler();
                    if (combiningTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(combiningTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(combiningTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = combiningTriggerBtn
                    }
                });

                const workingOvertimeTriggerBtn = poolButtons.requestEntity()
                workingOvertimeTriggerBtn?.setAttribute('position', '-0.9 1.5 0.5')
                workingOvertimeTriggerBtn?.play()
                workingOvertimeTriggerBtn?.addEventListener('click', () => {
                    workingOvertimeButtonHandler();
                    if (workingOvertimeTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(workingOvertimeTriggerBtn);
                        if(this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(workingOvertimeTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = workingOvertimeTriggerBtn
                    }
                });
            }

            const fixModelIssues = () => {
                // fix frustum culling bug
                const conveyor = this.el.object3D.getObjectByName('Conveyor') as THREE.Object3D;
                conveyor.traverse((child) => {
                    child.frustumCulled = false;
                });
            }
        },
        tick(this: IHouseControl, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }

        },
    },
};
export { HouseControlComponent }
