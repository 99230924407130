export const vertexShader = `
  uniform float pointMultiplier;

  attribute float size;
  attribute vec4 colour;
  attribute float angle;

  varying vec4 vColour;
  varying vec2 vAngle;

  void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    gl_PointSize = size * pointMultiplier / gl_Position.w;

    vAngle = vec2(cos(angle), sin(angle));
    vColour = colour;
  }
`;

export const fragmentShader = `
  uniform sampler2D diffuseTexture;
  varying vec4 vColour;
  varying vec2 vAngle;

  void main() {
    vec2 coords = (gl_PointCoord - 0.5) * mat2(vAngle.x, vAngle.y, -vAngle.y, vAngle.x) + 0.5;
    vec4 texel = texture2D(diffuseTexture, coords);
    gl_FragColor = texel * vColour;
  }
`;