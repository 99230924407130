import { useTrainingsContext } from '../../context';
import { useEffect, useRef } from 'react';
import { fetchTrainingsEffect } from '../../effects';

export function useFetchTrainingsOnMount(setLoading: (value: boolean) => void) {
  const [{ filters }, dispatch] = useTrainingsContext();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    const execute = async () => {
      setLoading(true);
      const response = await fetchTrainingsEffect(filters);
      setLoading(false);
      if (response.status === 'error') return;

      dispatch(response.action);
    };

    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    execute();
  }, [dispatch, setLoading, filters]);
}
