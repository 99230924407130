import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { contactusEffect } from '../../../../shared/effects';
import { ContactUs } from '../../../../shared/typings';
import { useTrainingsContext } from '../../../Сourses/context';
import { changeFiltersActionCreator } from '../../../Сourses/state';
import {
  TrainingTypeEnum,
  TrainingLevelEnum,
} from '../../../Сourses/utils/enums';

export function useHandlers() {
  const navigate = useNavigate();
  const [, dispatch] = useTrainingsContext();

  const handleCourses = useCallback(() => {
    navigate('/simulations');
  }, [navigate]);

  const handleSubmit = useCallback(async (values: ContactUs) => {
    if (!values.email || !values.name || !values.organization) return;

    localStorage.setItem('joinEmail', values.email);

    let mounted = true;
    const response = await contactusEffect(values);

    if (!mounted) return;

    return () => {
      mounted = false;
    };
  }, []);

  const handleTypeChange = useCallback(
    (value?: TrainingTypeEnum) => {
      dispatch(
        changeFiltersActionCreator({
          Type: value,
          Level: TrainingLevelEnum.All,
        })
      );
      handleCourses();
    },
    [dispatch, handleCourses]
  );

  return {
    handleSubmit,
    handleTypeChange,
  };
}
