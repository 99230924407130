import {Routes, Route} from 'react-router-dom';
import {Chemistry1Aframe} from './1-metal-ions/aframe';
import {Chemistry2Aframe} from './2-identify-ions/aframe';
import {Chemistry3Aframe} from './3-halide-test/aframe';
import {Chemistry4Aframe} from 'views/chemistry/4-metal-hydroxide/aframe';
import {Chemistry5Aframe} from './5-carbonate-test/aframe';
import {ChemistryAlkaliMetalsAframe} from './7-alkali-metals/aframe';
import {ChemistryHalogensAframe} from './8-halogens/aframe';
import {ChemistryNobleGasesAframe} from './9-noble-gases/aframe';
import {ChemistryTransitionMetalsAframe} from './10-transition-metals/aframe';
import {ChemistryElectronStructureAframe} from './11-electron-structure/aframe';
import {ChemistryPeriodicTableAframe} from './12-periodic-table/aframe';
import {ChemistryClimateChangeAframe} from './climate-change/aframe';
import {ChemistryMetallicBondsAframe} from './metallic-bonds/aframe';
import {ChemistryTemperatureChangesAframe} from './temperature-changes/aframe';
import {ChemistryChromatographyAframe} from './chromatography/aframe';
import {ChemistryExothermicAndEndothermicReactionsAframe} from './exothermic-and-endothermic-reactions/aframe';
import {ChemistryReactionsOfAlkaliMetalsAframe} from "./32-reactions-of-alkali-metals/aframe";
import {ChemistryReactionsOfHalogensAframe} from "./33-reactions-of-halogens/aframe";
import {ChemistryIonicBondsAframe} from "./34-ionic-bonds/aframe";
import {ChemistryCovalentBondsAframe} from "./35-covalent-bonds/aframe";
import {ChemistryGreenhouseGasesAframe} from "./17-greenhouse-gases/aframe";
import {ChemistryAcidsAndBasesAframe} from "./28-acids-and-bases/aframe";
import {ChemistryCarbonFootprintAframe} from "./carbon-footprint/aframe";
import {ChemistryEvaporationAndCrystallisationAframe} from "./evaporation-and-crystallisation/aframe";
import {ChemistryTestsForCommonGasessAframe} from './tests-for-common-gases/aframe';
import {ChemistryIsotopesAframe} from "./19-isotopes/aframe";
import {ChemistryElectrolysisAframe} from "./24-electrolysis/aframe";

export function ChemistrySimulationsRoutes() {
    return (
        <Routes>
            <Route path='/chemistry-metal-ions' element={<Chemistry1Aframe/>}/>
            <Route path='/chemistry-sulfate-test' element={<Chemistry2Aframe/>}/>
            <Route path='/chemistry-halide-test' element={<Chemistry3Aframe/>}/>
            <Route path='/chemistry-metal-hydroxide' element={<Chemistry4Aframe/>}/>
            <Route path='/chemistry-carbonate-test' element={<Chemistry5Aframe/>}/>
            <Route path='/chemistry-alkali-metals' element={<ChemistryAlkaliMetalsAframe/>}/>
            <Route path='/chemistry-halogens' element={<ChemistryHalogensAframe/>}/>
            <Route path='/chemistry-noble-gases' element={<ChemistryNobleGasesAframe/>}/>
            <Route path='/chemistry-transition-metals' element={<ChemistryTransitionMetalsAframe/>}/>
            <Route path='/chemistry-electronic-structure' element={<ChemistryElectronStructureAframe/>}/>
            <Route path='/chemistry-periodic-table' element={<ChemistryPeriodicTableAframe/>}/>
            <Route path='/chemistry-climate-change' element={<ChemistryClimateChangeAframe/>}/>
            <Route path='/chemistry-metallic-bonds' element={<ChemistryMetallicBondsAframe/>}/>
            <Route path='/chemistry-temperature-changes' element={<ChemistryTemperatureChangesAframe/>}/>
            <Route path='/chemistry-chromatography' element={<ChemistryChromatographyAframe/>}/>
            <Route path='/chemistry-exothermic-and-endothermic-reactions' element={<ChemistryExothermicAndEndothermicReactionsAframe/>}/>
            <Route path='/chemistry-reactions-of-alkali-metals' element={<ChemistryReactionsOfAlkaliMetalsAframe/>}/>
            <Route path='/chemistry-reactions-of-halogens' element={<ChemistryReactionsOfHalogensAframe/>}/>
            <Route path='/chemistry-ionic-bonds' element={<ChemistryIonicBondsAframe/>}/>
            <Route path='/chemistry-covalent-bonds' element={<ChemistryCovalentBondsAframe/>}/>
            <Route path='/chemistry-greenhouse-gases' element={<ChemistryGreenhouseGasesAframe/>}/>
            <Route path='/chemistry-acids-and-bases' element={<ChemistryAcidsAndBasesAframe/>}/>
            <Route path='/chemistry-carbon-footprint' element={<ChemistryCarbonFootprintAframe/>}/>
            <Route path='/chemistry-evaporation-and-crystallisation' element={<ChemistryEvaporationAndCrystallisationAframe/>}/>
            <Route path='/chemistry-tests-for-common-gases' element={<ChemistryTestsForCommonGasessAframe/>}/>
            <Route path='/chemistry-isotopes' element={<ChemistryIsotopesAframe/>}/>
            <Route path='/chemistry-electrolysis' element={<ChemistryElectrolysisAframe/>}/>
        </Routes>
    );
}
