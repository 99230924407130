import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IGoldFoilExperimentSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolButtons: PoolComponent;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionRutherford_A: THREE.AnimationAction;
	actionRutherford_B: THREE.AnimationAction;
	actionRutherford_C: THREE.AnimationAction;
	actionRotateElectrons: THREE.AnimationAction;
	actionGoldFoil_A: THREE.AnimationAction;
	actionGoldFoil_B: THREE.AnimationAction;
	actionGoldFoil_C: THREE.AnimationAction;
	actionGoldFoil_PowerSource: THREE.AnimationAction;

	currentClips: THREE.AnimationAction[];
	mixerOne: THREE.AnimationMixer;
	mixerTwo: THREE.AnimationMixer;

	currentAssetId: number;

	groupOneElement: AFrame.Entity | null;
	groupTwoElement: AFrame.Entity | null;
	rutherfordAtom: THREE.Object3D<THREE.Object3DEventMap> | undefined;

	el: AFrame.Entity;

	positionATriggerBtn: AFrame.Entity | null;
	positionBTriggerBtn: AFrame.Entity | null;
	positionCTriggerBtn: AFrame.Entity | null;
	positionAOutsideTriggerBtn: AFrame.Entity | null;
	positionBOutsideTriggerBtn: AFrame.Entity | null;
	positionCOutsideTriggerBtn: AFrame.Entity | null;
	positionDTriggerBtn: AFrame.Entity | null;


	positionAButtonHandler: () => void;
	positionBButtonHandler: () => void;
	positionCButtonHandler: () => void;
	positionDButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const GoldFoilExperimentScene = {
	name: 'experiment',
	val: {
		init(this: IGoldFoilExperimentSceneAframe) {
			var isInitialised = false;
			var counter = 0;
			this.el.addEventListener('model-loaded', () => {
				counter++
				if (counter < 2) return

				if (!isInitialised) {
					this.groupOneElement = document.getElementById('Experiment') as AFrame.Entity;
					this.groupTwoElement = document.getElementById('Atom') as AFrame.Entity;
					this.currentClips = [];

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started');
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place');
					this.el.sceneEl?.removeChild(ring);
					firstSceneAnimation();
				}
			})

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: SceneHolder; offset: 0 0 0');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}

			});

			const firstSceneAnimation = () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}	
				this.currentClips[0] = this.actionGoldFoil_PowerSource;
				this.actionGoldFoil_PowerSource.reset();
				this.actionGoldFoil_PowerSource.repetitions = 1;
				this.actionGoldFoil_PowerSource.clampWhenFinished = true;
				this.actionGoldFoil_PowerSource.play();

				this.actionRotateElectrons.reset();
				this.actionRotateElectrons.play();
			}

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			const initialiseAnimations = () => {
				const animatedModelOneProcess = this.groupOneElement?.object3D.getObjectByName('Biology16_GoldFoilExperiment') as any;
				const animatedModelTwoProcess = this.groupTwoElement?.object3D.getObjectByName('Biology16_RutherfordAtom') as any;

				this.mixerOne = new THREE.AnimationMixer(animatedModelOneProcess);
				this.mixerTwo = new THREE.AnimationMixer(animatedModelTwoProcess);

				const showRutherford_A = animatedModelTwoProcess?.animations[0];
				const showRutherford_B = animatedModelTwoProcess?.animations[1];
				const showRutherford_C = animatedModelTwoProcess?.animations[2];
				const showRotateElectrons = animatedModelTwoProcess?.animations[3];

				const showGoldFoil_A = animatedModelOneProcess?.animations[0];
				const showGoldFoil_B = animatedModelOneProcess?.animations[1];
				const showGoldFoil_C = animatedModelOneProcess?.animations[2];
				const showGoldFoil_PowerSource = animatedModelOneProcess?.animations[3];

				this.actionRutherford_A = this.mixerTwo.clipAction(showRutherford_A);
				this.actionRutherford_B = this.mixerTwo.clipAction(showRutherford_B);
				this.actionRutherford_C = this.mixerTwo.clipAction(showRutherford_C);
				this.actionRotateElectrons = this.mixerTwo.clipAction(showRotateElectrons);

				this.actionGoldFoil_A = this.mixerOne.clipAction(showGoldFoil_A);
				this.actionGoldFoil_B = this.mixerOne.clipAction(showGoldFoil_B);
				this.actionGoldFoil_C = this.mixerOne.clipAction(showGoldFoil_C);
				this.actionGoldFoil_PowerSource = this.mixerOne.clipAction(showGoldFoil_PowerSource);
			};

			this.positionAButtonHandler = () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					})
				}	
				
				this.currentClips[0] = this.actionRutherford_A;
				this.actionRutherford_A.reset();
				this.actionRutherford_A.play();

				this.currentClips[1] = this.actionGoldFoil_A;
				this.actionGoldFoil_A.reset();
				this.actionGoldFoil_A.play();

				if (this.onObjectSelected) {
					const title = 'Empty Atoms';
					const body = 'Most of the alpha particles are detected passing straight through the gold foil. This is strong evidence that atoms are mainly empty space, with most of the mass concentrated in a central nucleus.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionBButtonHandler = () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					})
				}	
				
				this.currentClips[0] = this.actionRutherford_B;
				this.actionRutherford_B.reset();
				this.actionRutherford_B.play();

				this.currentClips[1] = this.actionGoldFoil_B;
				this.actionGoldFoil_B.reset();
				this.actionGoldFoil_B.play();

				if (this.onObjectSelected) {
					const title = 'Central Charge';
					const body = 'Some alpha particles deflected significantly from their original trajectory, suggesting the atom’s nucleus is electrically charged. Deflections require close proximity to the nucleus, since deflection is rare this implies the nucleus is very small.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionCButtonHandler = () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					})
				}	
				
				this.currentClips[0] = this.actionRutherford_C;
				this.actionRutherford_C.reset();
				this.actionRutherford_C.play();

				this.currentClips[1] = this.actionGoldFoil_C;
				this.actionGoldFoil_C.reset();
				this.actionGoldFoil_C.play();
						
				if (this.onObjectSelected) {
					const title = 'Positive Charge';
					const body = 'A tiny fraction of the alpha particles are reflected back from the gold foil. This rare back scattering suggests the nucleus is very small compared to the atom as a whole. The nucleus must also be positively charged to repel positive alpha particles.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.positionDButtonHandler = () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					})
				}	

				if (this.onObjectSelected) {
					const title = 'Alpha Particles';
					const body = 'A radioactive source emits alpha particles, small positively charged particles that are aimed at the gold foil. The alpha particles can only be scattered by the gold since the experiment is performed in a vacuum.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.positionATriggerBtn = this.poolButtons.requestEntity();
				this.positionATriggerBtn?.object3D.position.set(0.2, 0.6, -2.81);
				this.positionATriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionATriggerBtn?.play();
				this.positionATriggerBtn?.addEventListener('click', () => {
					this.positionAButtonHandler();
					if (this.positionATriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionATriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.positionATriggerBtn;
					}
				});

				this.positionBTriggerBtn = this.poolButtons.requestEntity();
				this.positionBTriggerBtn?.object3D.position.set(2, 0.6, -2);
				this.positionBTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionBTriggerBtn?.play();
				this.positionBTriggerBtn?.addEventListener('click', () => {
					this.positionBButtonHandler();
					if (this.positionBTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionBTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionBTriggerBtn;
					}
				});

				this.positionCTriggerBtn = this.poolButtons.requestEntity();
				this.positionCTriggerBtn?.object3D.position.set(2, 0.6, 2);
				this.positionCTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionCTriggerBtn?.play();

				this.positionCTriggerBtn?.addEventListener('click', () => {
					this.positionCButtonHandler();
					if (this.positionCTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionCTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionCTriggerBtn;
					}
				});


				this.positionDTriggerBtn = this.poolButtons.requestEntity();
				this.positionDTriggerBtn?.object3D.position.set(0, 0.5, 5.55);
				this.positionDTriggerBtn?.object3D.scale.set(0.9, 0.9, 0.9);
				this.positionDTriggerBtn?.play();
				this.positionDTriggerBtn?.addEventListener('click', () => {
					this.positionDButtonHandler();
					if (this.positionDTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionDTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.positionDTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.positionDTriggerBtn
					}
				});
			}
		},
		tick(this: IGoldFoilExperimentSceneAframe, time: number, deltaTime: number) {
			if (this.mixerOne) {
				this.mixerOne.update(deltaTime * 0.001);
			}
			if (this.mixerTwo) {
				this.mixerTwo.update(deltaTime * 0.001);
			}
		},
	},
};
export { GoldFoilExperimentScene as GoldFoilExperimentComponent }