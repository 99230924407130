import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { ILenseRaycast } from './lense-raycaster';

interface IMetallicBondsControl {
  lenseRaycast: ILenseRaycast;
  currentDeactivatedButton: AFrame.Entity;
  poolEntity: AFrame.Entity;
  annotationComponent: IAnnotationAframe;
  onObjectSelected:
    | ((selectedObject: { title: string; body: string }) => void)
    | null;
  el: AFrame.Entity;
 
  annotation1ButtonHandler: () => void;
  annotation2ButtonHandler: () => void;

  annotation1Button: AFrame.Entity<
    AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>
  > | null;
  annotation2Button: AFrame.Entity<
    AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>
  > | null;
  mixer: THREE.AnimationMixer;
}

interface PoolComponent extends AFrame.Component {
  requestEntity(): AFrame.Entity | null;

  returnEntity(entity: AFrame.Entity): void;
}

const MetallicBondsComponent = {
  name: 'metallic-bonds-control',
  val: {
    init(this: IMetallicBondsControl) {

      this.el.sceneEl?.addEventListener('lesson-recenter', () => {
        const ring = document.getElementById('ring');
        if (ring) {
          return;
        } else {
          const ring = document.createElement('a-ring');
          ring.setAttribute('id', 'ring');
          ring.setAttribute(
            'tap-place',
            'id: holder; scale: 15 15 15; offset: 0 0 -2'
          );
          ring.setAttribute('material', 'shader: flat; color: #ffffff');
          ring.setAttribute('rotation', '-90 0 0');
          ring.setAttribute('radius-inner', '0.5');
          ring.setAttribute('radius-outer', '0.8');
          this.el.sceneEl?.appendChild(ring);
          if (this.currentDeactivatedButton) {
            (
              this.currentDeactivatedButton.components[
                'world-button'
              ] as unknown as WorldButtonAframeInstance
            ).activate();
            this.annotationComponent.deactivate();
          }
        }
      });

      this.el.sceneEl?.addEventListener('lesson-start', () => {
          const ring = document.getElementById('ring');
          if (ring) {
            ring.removeAttribute('tap-place');
            this.el.sceneEl?.removeChild(ring);
          }
        });
      
      const atoms = document.getElementById("atoms") as AFrame.Entity;
      atoms?.addEventListener('model-loaded', (e) => {
        
        initialiseAnimations();
        
        //obtain annotation system
        const scene = this.el.sceneEl as AFrame.Scene & {
          systems: { 'annotation-system': IAnnotationSystemAframe };
        };
        const annotationSystem = scene.systems['annotation-system'];
        this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
        this.el.setAttribute('annotation', '');
        this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
        
        //obtain raycaster
        this.lenseRaycast = this.el.sceneEl?.components['lense-raycaster'] as unknown as ILenseRaycast;
        if (this.lenseRaycast) {
          onRaycasterLoaded();
        } else {
          this.el.sceneEl?.addEventListener('loaded', () => {
            this.lenseRaycast = this.el.sceneEl?.components['lense-raycaster'] as unknown as ILenseRaycast;
            onRaycasterLoaded();
          });
        }
        
        e.stopPropagation();
      });

      const onRaycasterLoaded = () => {
        //get pool entity
        this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
        // ony initialise buttons once pool has loaded
        if (this.poolEntity.hasLoaded) {
            initialiseButtons();
        } else {
            this.poolEntity.addEventListener('loaded', () => {
                initialiseButtons();
            });
        }
      };


      const initialiseAnimations = () => {
        const anim = atoms.object3D.getObjectByName("Scene");
        if (anim) {
          this.mixer = new THREE.AnimationMixer(anim);
          for (let i = 0; i < anim.animations.length; i++) {
            const actions = this.mixer.clipAction(anim.animations[i]);
            actions.play();
          }
        }
        
      };

      const deactivateButton = () => {
        this.el.sceneEl?.emit('annotation-close');
      };

      const initialiseButtons = () => {
        const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

        this.annotation1Button = poolButtons.requestEntity();
        this.annotation1Button?.setAttribute('position', '0.11 0.05 -0.1');
        this.annotation1Button?.setAttribute('scale', '2.5 2.5 2.5');
        this.annotation1Button?.play();
        this.annotation1Button!.object3D.visible = true;
        this.lenseRaycast.addRaycastButton('Button1', this.annotation1Button!.object3D, deactivateButton)
        this.annotation1Button?.addEventListener('click', () => {
          this.annotation1ButtonHandler();
          if (this.annotation1Button) {
            this.lenseRaycast.activateButton('Button1');
            this.annotationComponent.setObjectToFollow(this.annotation1Button);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                  'world-button'
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.annotation1Button.components[
                'world-button'
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.annotation1Button;
          }
        });

        this.annotation2Button = poolButtons.requestEntity();
        this.annotation2Button?.setAttribute('position', '-0.05 0.05 0');
        this.annotation2Button?.setAttribute('scale', '2.5 2.5 2.5');
        this.annotation2Button?.play();
        this.annotation2Button!.object3D.visible = true;
        this.lenseRaycast.addRaycastButton('Button2', this.annotation2Button!.object3D, deactivateButton)
        this.annotation2Button?.addEventListener('click', () => {
          this.annotation2ButtonHandler();
          this.lenseRaycast.activateButton('Button2');
          if (this.annotation2Button) {
            this.annotationComponent.setObjectToFollow(this.annotation2Button);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                  'world-button'
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.annotation2Button.components[
                'world-button'
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.annotation2Button;
          }
        });
      };


      this.annotation1ButtonHandler = () => {
        if (this.onObjectSelected) {
          const title = 'Metal Ions';
          const body =
            'When metals bond, they create positively charged ions (cations) arranged in layers. This arrangement grants metals their malleable and ductile (can be drawn out into wires) characteristics as ions can slide over each other, making them ideal for electric cables.';
          this.onObjectSelected({ title, body });
        } else {
          console.log('No object selected method');
        }
      };

      this.annotation2ButtonHandler = () => {
        if (this.onObjectSelected) {
          const title = 'Delocalised Electrons';
          const body =
            'When an electron is delocalised it means it is not associated with a particular atom and can move freely in the structure. This makes metals great conductors, and gives them high boiling and melting points. The more delocalised electrons, the stronger the bond.';
          this.onObjectSelected({ title, body });
        } else {
          console.log('No object selected method');
        }
      };
    },

    tick(this: IMetallicBondsControl, time: number, deltaTime: number) {
      if (this.mixer) {
        this.mixer.update(deltaTime * 0.001);
      }
    },
  },
};
export { MetallicBondsComponent };
