import React, {useRef, useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
    Annotation,
    AnnotationState,
} from '../../../lib/aframe/components/annotation';
import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import {WorldButtonComponent} from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import {ExoAndEndothermicReactionsSceneComponent} from './lesson-start';
import {ShaderFireComponent} from './shader-fire';
import {ShaderSmokeComponent} from './shader-smoke';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
} from 'shared/constants/hint-items';
import {TapPlaceComponent} from "../../../lib/aframe/components/tap-place-object";
import match from "../../../assets/img/Buttons/mode_heat_FILL0_wght400_GRAD0_opsz48 1.png";
import snow from "../../../assets/img/Buttons/weather_snowy_FILL0_wght400_GRAD0_opsz48 1.png";
import photosynthesis from "../../../assets/img/Buttons/nest_eco_leaf_FILL0_wght400_GRAD0_opsz48 1.png";
import sodium from "../../../assets/img/Buttons/ButtonNa.png";
import ice from "../../../assets/img/Buttons/IceCubeMelt.png";
import {ShaderSnow} from "./shader-snow";
import { LoadersPermissonsEnum } from 'shared/enums';

const introText = 'Tap the experiment and identify what type of reactions they are! Use the side menu to switch between experiments.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT];

const ChemistryExothermicAndEndothermicReactionsAframe = () => {
    const [selectedObject, setSelectedObject] = useState<{
        title: string;
        body: string;
        imageExtra?: string;
    } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(
        AnnotationState.None
    );
    const [lessonStarted, setLessonStarted] = useState(false);
    const assetIcons = [match, snow, photosynthesis, sodium, ice];
    const [assetId, setAssetId] = useState(0);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

    const changeAsset = (assetId: number) => {
        setAssetId(assetId);
    };
    const assetChangeEventRef = useRef(
        new CustomEvent('asset-change', {detail: {assetId: 0}})
    );
    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };
    React.useEffect(() => {
        // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            console.log('Scene loaded');
            // Update the assetChangeEvent detail before dispatching
            assetChangeEventRef.current.detail.assetId = assetId;
            scene.dispatchEvent(assetChangeEventRef.current);
        }
    }, [assetId]);
    const onObjectSelected = (data: { title: string; body: string; imageExtra?: string; }) => {
        setSelectedObject(data);
    };
    const onLessonStarted = () => {
        setLessonStarted(true);
    };
    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };

    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                lessonStarted={lessonStarted}
                selectedObject={selectedObject}
                assetIcons={assetIcons}
                onAssetButtonClick={changeAsset}
                onAnnotationStateChanged={onAnnotationStateChanged}
                hintItems={hintItems}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./chemistry-exothermic-and-endothermic-reactions-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    WorldButtonComponent,
                    ShaderFireComponent,
                    ShaderSmokeComponent,
                    ShaderSnow,
                    TapPlaceComponent,
                    FaceCameraComponent,
                    ExoAndEndothermicReactionsSceneComponent,
                    Annotation,
                ]}
                onObjectSelected={onObjectSelected}
                annotationState={annotationState}
                onLessonStart={onLessonStarted}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>
    );
};
export {ChemistryExothermicAndEndothermicReactionsAframe};
