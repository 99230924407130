import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

interface ICellsSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    salmonellaMixer: THREE.AnimationMixer;
    gonorrhoeaMixer: THREE.AnimationMixer;
    salmonellaAnimation: THREE.AnimationAction
    gonorrhoeaAnimation: THREE.AnimationAction
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    buttonsInitialised: boolean;
    salmonellaButtonHandler: () => void;
    salmonellaSymptomsButtonHandler: () => void;
    gonorrhoeaButtonHandler: () => void;
    gonorrhoeaSymptomsButtonHandler: () => void;
    salmonella: AFrame.Entity;
    gonorrhoea: AFrame.Entity;
    el: AFrame.Entity;
    currentAssetId: number;
}

const CellScene = {
    name: 'cell-scene',
    val: {
        init(this: ICellsSceneAframe) {
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded

            })
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                    if (!this.currentAssetId) this.currentAssetId = 0

                    if (this.poolEntity.hasLoaded) {
                        initialiseButtons();
                    } else {
                        this.poolEntity.addEventListener('loaded', () => {
                            initialiseButtons();
                        });
                    }
                    initialiseAnimation()
                }
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: holder; scale: 5 5 5; offset: 0 -4 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.el.sceneEl?.addEventListener('asset-change', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                this.currentAssetId = customEvent.detail.assetId;
                if (this.currentAssetId === 0) {
                    this.gonorrhoea.object3D.visible = false
                    this.salmonella.object3D.visible = true
                    this.gonorrhoeaAnimation.stop()
                    this.salmonellaAnimation.play()
                }
                if (this.currentAssetId === 1) {
                    this.salmonella.object3D.visible = false
                    this.gonorrhoea.object3D.visible = true
                    this.salmonellaAnimation.stop()
                    this.gonorrhoeaAnimation.play()
                }
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    this.salmonellaAnimation.paused = false
                    this.gonorrhoeaAnimation.paused = false
                } else {
                    this.salmonellaAnimation.paused = true
                    this.gonorrhoeaAnimation.paused = true
                }
            });
            this.salmonellaButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Salmonella';
                    const body = 'Salmonella is a harmful bacteria that causes food poisoning which can be ingested through undercooked meat, especially chicken or food prepared in unhygienic conditions.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.salmonellaSymptomsButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Symptoms of Salmonella';
                    const body = 'The toxins released by salmonella cause symptoms such as abdominal cramps, diarrhoea, fever and vomiting. Prevent getting salmonella poisoning by cooking food well and in hygienic conditions.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.gonorrhoeaButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Gonorrhoea';
                    const body = 'Gonorrhoea is a harmful bacteria that is a sexually transmitted disease (STD). It is a common infection in teens and young adults. Gonorrhoea can be treated with antibiotics, but the bacteria is evolving and becoming resistant to traditional methods.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.gonorrhoeaSymptomsButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Symptoms of Gonorrhoea';
                    const body = 'Gonorrhoea causes various symptoms such as pain when urinating, pelvic pain, and green or yellow discharge. More serious symptoms include infertility if left untreated. To avoid gonorrhoea, people should use condoms and practise safe sex.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseAnimation = () => {
                this.salmonella = document.getElementById('salmonella') as AFrame.Entity
                this.gonorrhoea = document.getElementById('gonorrhoea') as AFrame.Entity
                const animatedSalmonellaEl = this.salmonella.object3D.getObjectByName('Scene') as any;
                this.salmonellaMixer = new THREE.AnimationMixer(animatedSalmonellaEl)
                const [SalmonellaAnim] = animatedSalmonellaEl.animations
                this.salmonellaAnimation = this.salmonellaMixer.clipAction(SalmonellaAnim)
                this.salmonellaAnimation.play()
                const animatedGonorrhoeaEl = this.gonorrhoea.object3D.getObjectByName('Scene') as any;
                this.gonorrhoeaMixer = new THREE.AnimationMixer(animatedGonorrhoeaEl)
                const [GonorrheaAnim] = animatedGonorrhoeaEl.animations
                this.gonorrhoeaAnimation = this.gonorrhoeaMixer.clipAction(GonorrheaAnim)
                this.gonorrhoeaAnimation.play()
            }

            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const salmonellaTriggerBtn = poolButtons.requestEntity()
                salmonellaTriggerBtn?.setAttribute('position', '-0.15 0.2 0.2')
                this.currentAssetId === 0 ? salmonellaTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5') : salmonellaTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        salmonellaTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5')
                    }
                    if (this.currentAssetId === 1) {
                        salmonellaTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                salmonellaTriggerBtn?.play()
                salmonellaTriggerBtn?.addEventListener('click', () => {
                    this.salmonellaButtonHandler()
                    if (salmonellaTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(salmonellaTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (salmonellaTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = salmonellaTriggerBtn
                    }
                });
                const salmonellaSymptomsTriggerBtn = poolButtons.requestEntity()
                salmonellaSymptomsTriggerBtn?.setAttribute('position', '0.15 0.15 0.2')
                this.currentAssetId === 0 ? salmonellaSymptomsTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5') : salmonellaSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        salmonellaSymptomsTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5')
                    }
                    if (this.currentAssetId === 1) {
                        salmonellaSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                salmonellaSymptomsTriggerBtn?.play()
                salmonellaSymptomsTriggerBtn?.addEventListener('click', () => {
                    this.salmonellaSymptomsButtonHandler()
                    if (salmonellaSymptomsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(salmonellaSymptomsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (salmonellaSymptomsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = salmonellaSymptomsTriggerBtn
                    }
                });
                const gonorrhoeaTriggerBtn = poolButtons.requestEntity()
                gonorrhoeaTriggerBtn?.setAttribute('position', '-0.15 0.2 0.2')
                gonorrhoeaTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        gonorrhoeaTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        gonorrhoeaTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5')
                    }
                });
                gonorrhoeaTriggerBtn?.play()
                gonorrhoeaTriggerBtn?.addEventListener('click', () => {
                    this.gonorrhoeaButtonHandler()
                    if (gonorrhoeaTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(gonorrhoeaTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (gonorrhoeaTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = gonorrhoeaTriggerBtn
                    }
                });
                const gonorrhoeaSymptomsTriggerBtn = poolButtons.requestEntity()
                gonorrhoeaSymptomsTriggerBtn?.setAttribute('position', '0.15 0.1 0.2')
                gonorrhoeaSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        gonorrhoeaSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        gonorrhoeaSymptomsTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5')
                    }
                });
                gonorrhoeaSymptomsTriggerBtn?.play()
                gonorrhoeaSymptomsTriggerBtn?.addEventListener('click', () => {
                    this.gonorrhoeaSymptomsButtonHandler()
                    if (gonorrhoeaSymptomsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(gonorrhoeaSymptomsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (gonorrhoeaSymptomsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = gonorrhoeaSymptomsTriggerBtn
                    }
                });
            };
        },
        tick(this: ICellsSceneAframe, time: number, deltaTime: number) {
            if (this.salmonellaMixer) {
                this.salmonellaMixer.update(deltaTime * 0.001);
            }
            if (this.gonorrhoeaMixer) {
                this.gonorrhoeaMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {CellScene as CellSceneComponent}
