import React, { useRef, useState } from 'react';
import { AFrameComponent, DISABLE_IMAGE_TARGETS } from 'lib/aframe-component-2';
import { AnnotationState } from '../../../lib/aframe/components/annotation';
import { Annotation } from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import { TapPlaceComponent } from 'lib/aframe/components/tap-place-object';
import { Microbiology } from './main-scene';
import { MethodSystem } from 'lib/aframe/systems/method-system';

import AssetA from './assets/UI_ButtonA_V2.png';
import AssetB from './assets/UI_ButtonB_V2.png';

import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  MODEL3D_HINT,
} from 'shared/constants/hint-items';
import { ShaderFire } from './shader-fire';
import { ModelQuality } from './model_quality';
import { LoadersPermissonsEnum } from 'shared/enums';



const introText = 'The aim of this experiment is to investigate the effect of disinfectants, antiseptics and antibiotics on bacterial growth using agar plates and measuring zones of inhibition.</br></br>Explore the equipment and use dropdown to complete experiment.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const assetIcons = [AssetA, AssetB];

const BiologyMicrobiologyAframe = () => {
    const [assetId, setAssetId] = useState(0);
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState(false);
  const [assetListActive, setAssetListActive] = useState(false);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const onLessonStarted = () => {
    setLessonStarted(true);
  };
  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };

  const onSetAssetListActive = () => {
    setAssetListActive(true);
  };

  const changeAsset = (assetId: number) => {
    // only change asset if lesson has started
    if (lessonStarted) {
      setAssetId(assetId);
    }
  };

  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };

  const assetChangeEventRef = useRef(
    new CustomEvent('asset-change', { detail: { assetId: 0 } })
  );
  React.useEffect(() => {
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      // Update the assetChangeEvent detail before dispatching
      assetChangeEventRef.current.detail.assetId = assetId;
      scene.dispatchEvent(assetChangeEventRef.current);
    }
  }, [assetId]);


  const annotationStateEventRef = React.useRef(new CustomEvent('annotation-state-change', { detail: { annotationState: annotationState } }));
  React.useEffect(() => {
    if (annotationState !== undefined) {
      const scene = document.querySelector('a-scene');
      if (scene) {
        annotationStateEventRef.current.detail.annotationState = annotationState;
        scene.dispatchEvent(annotationStateEventRef.current);
      }
    }
  }, [annotationState]);


  // these are callbacks passed to aframe using methodSystem
  // these are accesed in aframe using associated string keys  
  const systemFunctions = {
    'onLessonStart': onLessonStarted,
    'onObjectSelected': onObjectSelected,
    'onSetAssetListActive': onSetAssetListActive,
  };

  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        lessonStarted={lessonStarted}
        onAnnotationStateChanged={onAnnotationStateChanged}
        onAssetButtonClick={changeAsset}
        {...(assetListActive ? { assetIcons: assetIcons } : {})}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameComponent
        sceneHtml={require('./microbiology-aframe.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          TapPlaceComponent,
          WorldButtonComponent,
          FaceCameraComponent,
          Microbiology,
          Annotation,
          ShaderFire,
          ModelQuality,
        ]}
        systems={[MethodSystem]}
        systemFunctions={systemFunctions}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { BiologyMicrobiologyAframe };
