import { useTrainingsContext } from '../../../context';

export function useData() {
  const [
    {
      trainingsInfo: {
        new: newTrainings,
        inProgress,
        all,
        completed,
        physics,
        chemistry,
        biology,
        elementarySchool,
        highSchool,
        middleSchool,
      },
      filters: { Status, Type, Level },
    },
  ] = useTrainingsContext();

  return {
    newTrainings,
    inProgress,
    completed,
    all,
    physics,
    chemistry,
    biology,
    elementarySchool,
    highSchool,
    middleSchool,
    filterStatus: Status,
    filterType: Type,
    filterLevel: Level,
  };
}
