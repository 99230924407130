import { Stack, styled } from '@mui/material'
import { CONTAINER_WIDTH } from '../../constants/ContainerWidth'
import { DisplayEnum } from '../enums'

export const CustomContainer = styled(Stack, { name: 'CustomContainer' })({
	width: '100%',
  height: '100%',
  maxWidth: `${CONTAINER_WIDTH}px`,
  margin: '0 auto',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
    maxWidth: '100%',
    padding: '0 16px'
  },
})