import type { Dispatch } from 'react';
import { createContext } from 'react';
import { Actions, SharedState } from '../state';

type TSharedContext = [
  state: SharedState,
  dispatch: Dispatch<Actions | Promise<Actions>>
];

export const SharedContext = createContext<TSharedContext | undefined>(
  undefined
);
