import * as THREE from 'three';
import * as AFrame from 'aframe';
import { LinearSpline } from '../../../lib/utils/LinearSpline';
import { vertexShader as _VS, fragmentShader as _FS } from './shaders/radial-shaders';

interface ParticleData {
  position: THREE.Vector3;
  size: number;
  life: number;
  maxLife: number;
  rotation: number;
  velocity: THREE.Vector3;
}

export interface IBubbleMachineAframe {
    spawningEnabled: boolean;
    el: AFrame.Entity;
    data: {
        lifetimex: number;
        spawnRate: number;
        sizeMax: number;
        spawnRadius: number;
    };
    particleData: ParticleData[];
    geometry: THREE.BufferGeometry;
    startColor: THREE.Color;
    endColor: THREE.Color;
    sizeSpline: LinearSpline<number>;
    points: THREE.Points;
    elapsedTimeSinceEmission: number;
  
    addParticles(deltaTimeS: number): void;
    updateGeometry(): void;
    updateParticles(deltaTimeS: number): void;
    moveParticles(x: number, y: number, z: number): void;
    startSpawning(): void;
    stopSpawning(): void;
  }


const bubbleMachineComponent = {
  name: 'bubble-machine',
  val: {
    schema: {
        lifetimex: { type: 'number', default: 2.75 },
        spawnRate: { type: 'number', default: 0.25 },
        sizeMax: { type: 'number', default: 0.3 },
        spawnRadius: { type: 'number', default: 0.85 },
    },
    init(this: IBubbleMachineAframe): void {
        const imgUrl = require('../../../assets/img/Handle_Plain.png') //simple circle
        const fireTexture = new THREE.TextureLoader().load(imgUrl)
        fireTexture.encoding = THREE.sRGBEncoding
    
        const matShader = new THREE.ShaderMaterial({
          uniforms: {
            diffuseTexture: {value: fireTexture},
            pointMultiplier: {
                value: window.innerHeight / (2.0 * Math.tan(0.5 * 60.0 * Math.PI / 180.0))
            }
          },
          vertexShader: _VS,
          fragmentShader: _FS,
          blending: THREE.NormalBlending,
          depthTest: true,
          depthWrite: true,
          transparent: true,
          vertexColors: true,
        })
    
    
        // points positions
        this.particleData = []
    
        this.geometry = new THREE.BufferGeometry()
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
        this.geometry.setAttribute('size', new THREE.Float32BufferAttribute([], 1))
        this.geometry.setAttribute('angle', new THREE.Float32BufferAttribute([], 1))
        // this.matPoints = new THREE.PointsMaterial({color: 0xffffff, size: 1})
    
        this.points = new THREE.Points(this.geometry, matShader)
        // this.points.object3D.scale.set('5, 5, 5')
        this.points.position.set(0, 0.15, 0)
        this.points.scale.set(0.005, 0.005, 0.005)
        this.points.renderOrder = 1;
        this.el.object3D.add(this.points)
    
    
        this.sizeSpline = new LinearSpline((t, a, b) => {
          return a + t * (b - a)
        })
        this.sizeSpline.AddPoint(0.0, this.data.sizeMax)
        this.sizeSpline.AddPoint(1.0, 0.1)

        this.spawningEnabled = false;
    
      },
      tick(this: IBubbleMachineAframe, time: number, deltaTime: number): void {
        const deltaTimeS = deltaTime * 0.001;
        this.addParticles(deltaTimeS);
        this.updateParticles(deltaTimeS);
        this.updateGeometry();
      },
      addParticles(this: IBubbleMachineAframe, deltaTimeS: number): void {
        if (!this.spawningEnabled) {
            return;
        }
        // Your implementation...
        if (!this.elapsedTimeSinceEmission) {
            this.elapsedTimeSinceEmission = 0.0
          }
          const spawnRate = this.data.spawnRate; // Adjust this value to control the spawn rate (lower value means fewer particles)
          this.elapsedTimeSinceEmission += deltaTimeS * spawnRate;
          
          const n = Math.floor(this.elapsedTimeSinceEmission * 125.0)
          this.elapsedTimeSinceEmission -= n / 75.0
          
          const tempVec = new THREE.Vector3(); // Temporary vector to avoid creating new instances
          for (let i = 0; i < n; i++) {
            const lifeRand = (Math.random() * 0.1 + 0.9) * this.data.lifetimex;
            tempVec.set(
              (Math.random() * 2 - 1) * this.data.spawnRadius,
              (Math.random() * 2 - 1) * 0.1,
              (Math.random() * 2 - 1) * this.data.spawnRadius
            );
            this.particleData.push({
              position: tempVec.clone(),
              size: (Math.random() * 0.5 + 0.5) * 2.0,
              life: lifeRand,
              maxLife: lifeRand,
              rotation: Math.random() * 2.0 * Math.PI,
              velocity: new THREE.Vector3(0, 7.5, 0),
            })
          }
      },
      updateParticles(this: IBubbleMachineAframe, deltaTimeS: number): void {
        const tempVec1 = new THREE.Vector3(); // Temporary vector for vector operations
    
        for (let i = 0; i < this.particleData.length; i++) {
            this.particleData[i].life -= deltaTimeS;
        }
    
        this.particleData = this.particleData.filter((p) => p.life > 0.0);
    
        for (let i = 0; i < this.particleData.length; i++) {
            const p = this.particleData[i];
            // Update rotation
            p.rotation += deltaTimeS * 0.5;
            
            // Movement
            tempVec1.copy(p.velocity).multiplyScalar(deltaTimeS);
            p.position.add(tempVec1);
            tempVec1.copy(p.velocity).multiplyScalar(deltaTimeS * 0.25);
            tempVec1.x = Math.sign(p.velocity.x) * Math.min(Math.abs(tempVec1.x), Math.abs(p.velocity.x));
            tempVec1.y = Math.sign(p.velocity.y) * Math.min(Math.abs(tempVec1.y), Math.abs(p.velocity.y));
            tempVec1.z = Math.sign(p.velocity.z) * Math.min(Math.abs(tempVec1.z), Math.abs(p.velocity.z));
            p.velocity.sub(tempVec1);
            
            const t = p.life / p.maxLife;
            p.size = this.sizeSpline.Get(t);
        }

        // particle sorting
          // particlePositions.sort((a, b) => {
          //  const d1 = this.el.sceneEl.camera.el.object3D.position.distanceTo(this.points.localToWorld(a.position))
          //  const d2 = this.el.sceneEl.camera.el.object3D.position.distanceTo(this.points.localToWorld(b.position))
          //  if (d1 > d2) {
          //    return -1
          //  }
          //  if (d1 < d2) {
          //    return 1
          //  }
          //  return 0
          //})
    },
    
      updateGeometry(this: IBubbleMachineAframe): void {
        const positions = []
        const sizes = []
        const angles = []
    
        for (let i = 0; i < this.particleData.length; i++) {
          positions.push(this.particleData[i].position.x, this.particleData[i].position.y, this.particleData[i].position.z)
          sizes.push(this.particleData[i].size)
          angles.push(this.particleData[i].rotation)
        }
    
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))
        this.geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1))
        this.geometry.setAttribute('angle', new THREE.Float32BufferAttribute(angles, 1))
    
        this.geometry.attributes.position.needsUpdate = true
        this.geometry.attributes.size.needsUpdate = true
        this.geometry.attributes.angle.needsUpdate = true
      },
      moveParticles(this: IBubbleMachineAframe, x: number, y: number, z: number): void {
        this.points.position.set(x, y, z);
      },
      startSpawning(this: IBubbleMachineAframe): void {
        this.spawningEnabled = true;
      },
      stopSpawning(this: IBubbleMachineAframe): void {
        this.spawningEnabled = false;
      },
  },
};

export { bubbleMachineComponent as BubbleMachine };

