import * as AFrame from 'aframe';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IShaderFireAframe } from './shader-fire';
import { IShaderSmokeAframe } from './shader-smoke';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface ISupercooledCombustionSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	currentClips: THREE.AnimationAction[];
	mixer: THREE.AnimationMixer;
	mixer_1: THREE.AnimationMixer;
	el: AFrame.Entity;
	currentAssetId: number;
	poolButtons: PoolComponent;

	modelRocket: AFrame.Entity;
	modelRain: AFrame.Entity;
	rainPlane: THREE.Mesh;

	video: HTMLVideoElement;

	positionATriggerBtn: AFrame.Entity | null;
	positionBTriggerBtn: AFrame.Entity | null;
	positionCTriggerBtn: AFrame.Entity | null;
	positionDTriggerBtn: AFrame.Entity | null;
	positionETriggerBtn: AFrame.Entity | null;
	positionFTriggerBtn: AFrame.Entity | null;

	positonAButtonHandler: () => void;
	positonBButtonHandler: () => void;
	positonCButtonHandler: () => void;
	positonDButtonHandler: () => void;
	positonEButtonHandler: () => void;
	positonFButtonHandler: () => void;

	actionRocket: THREE.AnimationAction;
	actionButton: THREE.AnimationAction;
	actionClouds: THREE.AnimationAction;

	fireMain: IShaderFireAframe;
	fireR: IShaderFireAframe;
	fireL: IShaderFireAframe;
	smoke: IShaderSmokeAframe;

	fireMainStart: IShaderFireAframe;
	fireRStart: IShaderFireAframe;
	fireLStart: IShaderFireAframe;
	smokeStart: IShaderSmokeAframe;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const SupercooledCombustionScene = {
	name: 'experiment',
	val: {
		init(this: ISupercooledCombustionSceneAframe) {
			var isInitialised = false;
			this.currentClips = [];
			var modelCount = 0;
			this.el.addEventListener('model-loaded', () => {
				modelCount++;
				if (modelCount < 2) return
				if (!isInitialised) {
					this.modelRocket = document.getElementById('modelRocket') as AFrame.Entity;
					this.modelRain = document.getElementById('modelRain') as AFrame.Entity;

					this.modelRain?.object3D.traverse((child) => {
						console.log(child);
					});

					this.rainPlane = this.modelRain.object3D.getObjectByName('RainPlane') as THREE.Mesh;

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {

						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;
				}
				else return
			});

			const firstScene = () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}

				if (this.modelRocket.hasAttribute('shader-fire')) {
					this.fireMain = this.modelRocket.components['shader-fire'] as unknown as IShaderFireAframe;
					this.fireMain.setVisibility(false);
				}
				if (this.modelRocket.hasAttribute('shader-smoke')) {
					this.smoke = this.modelRocket.components['shader-smoke'] as unknown as IShaderSmokeAframe;
					this.smoke.setVisibility(false);
				}
				if (this.modelRocket.hasAttribute('shader-fire_stage_l')) {
					this.fireL = this.modelRocket.components['shader-fire_stage_l'] as unknown as IShaderFireAframe;
					this.fireL.setVisibility(false);
				}
				if (this.modelRocket.hasAttribute('shader-fire_stage_r')) {
					this.fireR = this.modelRocket.components['shader-fire_stage_r'] as unknown as IShaderFireAframe;
					this.fireR.setVisibility(false);
				}
				if (this.modelRocket.hasAttribute('shader-fire-start')) {
					this.fireMainStart = this.modelRocket.components['shader-fire-start'] as unknown as IShaderFireAframe;
					this.fireMainStart.setVisibility(false);
				}
				if (this.modelRocket.hasAttribute('shader-smoke-start')) {
					this.smokeStart = this.modelRocket.components['shader-smoke-start'] as unknown as IShaderSmokeAframe;
					this.smokeStart.setVisibility(false);
				}
				if (this.modelRocket.hasAttribute('shader-fire_stage_l-start')) {
					this.fireLStart = this.modelRocket.components['shader-fire_stage_l-start'] as unknown as IShaderFireAframe;
					this.fireLStart.setVisibility(false);
				}
				if (this.modelRocket.hasAttribute('shader-fire_stage_r-start')) {
					this.fireRStart = this.modelRocket.components['shader-fire_stage_r-start'] as unknown as IShaderFireAframe;
					this.fireRStart.setVisibility(false);
				}

				if (this.rainPlane) {
					this.rainPlane.visible = false;
				}

				if (this.positionATriggerBtn) {
					this.positionATriggerBtn.object3D.visible = true;
					this.positionATriggerBtn.object3D.position.x = 0;
				}
				if (this.positionBTriggerBtn) {
					this.positionBTriggerBtn.object3D.visible = true;
					this.positionBTriggerBtn.object3D.position.x = 0;
				}
				if (this.positionCTriggerBtn) {
					this.positionCTriggerBtn.object3D.visible = true;
					this.positionCTriggerBtn.object3D.position.x = 0;
				}
				if (this.positionDTriggerBtn) {
					this.positionDTriggerBtn.object3D.visible = true;
					this.positionDTriggerBtn.object3D.position.x = 0;
				}

				this.positionETriggerBtn?.object3D.position.set(-2, 4, 1);
			}

			this.el.sceneEl?.addEventListener('return-to-start', () => {
				firstScene();
			})

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
				firstScene();
			})

			// when model is loaded adding recenter functionality
			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				this.el.sceneEl?.emit('recenter');
				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: sceneHolder; offset: 0 0.5 0');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
					this.fireMain.setVisibility(false);
					this.fireL.setVisibility(false);
					this.fireR.setVisibility(false);
					this.smoke.setVisibility(false);

					this.fireMainStart.setVisibility(false);
					this.fireLStart.setVisibility(false);
					this.fireRStart.setVisibility(false);
					this.smokeStart.setVisibility(false);
				}
			});

			const initialiseAnimations = () => {
				const animatedEl = this.modelRocket.object3D.getObjectByName('Scene') as any;
				const animatedEl_2 = this.modelRain.object3D.getObjectByName('Scene') as any;


				this.mixer = new THREE.AnimationMixer(animatedEl);
				this.mixer_1 = new THREE.AnimationMixer(animatedEl_2);

				const showRocketAction = animatedEl.animations[0];
				const showButtonAction = animatedEl.animations[9];
				const showCloudsAction = animatedEl_2.animations[0];

				this.actionRocket = this.mixer.clipAction(showRocketAction);
				this.actionButton = this.mixer.clipAction(showButtonAction);
				this.actionClouds = this.mixer_1.clipAction(showCloudsAction);
			};

			this.positonAButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Condensed Fuel';
					const body = 'Rockets condense their fuels that are usually gases into liquids. As a liquid, the same mass of gas occupies a much smaller volume; it is more <b>dense</b>. This allows rockets to have smaller fuel tanks.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.positonBButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Combustion Reactions';
					const body = 'In a combustion reaction, a fuel (like hydrogen) reacts with oxygen, releasing lots of energy and some byproducts (like water). A reaction that releases energy is known as an <b>exothermic</b> reaction.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.positonCButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Hydrogen Combustion';
					const body = '<p>Rocket fuel is liquid hydrogen, cooled below -253°C, reacting with liquid oxygen (cooled below -183°C) to create water vapour and release lots of energy.</p><img src="https://bridgear.blob.core.windows.net/public/Physics/SupercooledCombustion/2H2 (l) + O2 (l) = 2H2O (g).png" alt="HydrogenCombustion" />';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.positonDButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Newton’s Third Law';
					const body = 'When two objects interact, the forces they exert on each other are equal and opposite. So, when the rocket ejects gas from the exhaust with a huge force, the gas forces the rocket upwards; this force is known as thrust.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.positonEButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Hydrogen Combustion';
					const body = 'The byproduct of hydrogen combustion is water. As the water vapour cools down in the air after launch, it condenses into a liquid, just like in a cloud. This is why it always rains right after a rocket has launched.F';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			var buttonFEnabled = true;
			this.positonFButtonHandler = () => {
				if (buttonFEnabled) {
					buttonFEnabled = false;
					this.currentClips[0] = this.actionButton;
					this.actionButton.reset();
					this.actionButton.repetitions = 1;
					this.actionButton.clampWhenFinished = false;
					this.actionButton.play();

					this.currentClips[1] = this.actionRocket;
					this.actionRocket.reset();
					this.actionRocket.repetitions = 1;
					this.actionRocket.clampWhenFinished = true;
					this.actionRocket.play();

					this.currentClips[2] = this.actionClouds;
					this.actionClouds.reset();
					this.actionClouds.repetitions = 1;
					this.actionClouds.clampWhenFinished = true;
					this.actionClouds.play();

					setTimeout(() => {
						this.rainPlane.visible = true;
					}, 2000);

					this.fireMainStart.setVisibility(true);
					this.fireRStart.setVisibility(true);
					this.fireLStart.setVisibility(true);
					this.smokeStart.setVisibility(true);

					setTimeout(() => {
						this.fireMain.setVisibility(true);
						this.fireR.setVisibility(true);
						this.fireL.setVisibility(true);

						this.fireMainStart.setVisibility(false);
						this.fireRStart.setVisibility(false);
						this.fireLStart.setVisibility(false);
						this.smokeStart.setVisibility(false);

						if (this.positionETriggerBtn) {
							new TWEEN.Tween(this.positionETriggerBtn.object3D.position)
								.to({ x: -2, y: 7, z: 1 }, 3400)
								.start();
						}
					}, 1200);

					setTimeout(() => {
						this.smoke.setVisibility(true);
					}, 2300);

					setTimeout(() => {
						if (this.positionATriggerBtn) {
							this.positionATriggerBtn.object3D.visible = false;
							this.positionATriggerBtn.object3D.position.x = -50;
						}
						if (this.positionBTriggerBtn) {
							this.positionBTriggerBtn.object3D.visible = false;
							this.positionBTriggerBtn.object3D.position.x = -50;
						}
						if (this.positionCTriggerBtn) {
							this.positionCTriggerBtn.object3D.visible = false;
							this.positionCTriggerBtn.object3D.position.x = -50;
						}
						if (this.positionDTriggerBtn) {
							this.positionDTriggerBtn.object3D.visible = false;
							this.positionDTriggerBtn.object3D.position.x = -50;
						}
					}, 1000);

					const scene = document.querySelector('a-scene');

					setTimeout(() => {
						scene.emit('return-to-start');
						buttonFEnabled = true;
					}, 15000);
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.positionATriggerBtn = poolButtons.requestEntity();
				this.positionATriggerBtn?.object3D.position.set(0, 5.3, 0.4);
				this.positionATriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionATriggerBtn?.play();
				this.positionATriggerBtn?.addEventListener('click', () => {
					this.positonAButtonHandler();
					if (this.positionATriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionATriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionATriggerBtn;
					}
				});

				this.positionBTriggerBtn = poolButtons.requestEntity();
				this.positionBTriggerBtn?.object3D.position.set(0, 4, 0.4);
				this.positionBTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionBTriggerBtn?.play();
				this.positionBTriggerBtn?.addEventListener('click', () => {
					this.positonBButtonHandler();
					if (this.positionBTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionBTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionBTriggerBtn;
					}
				});

				this.positionCTriggerBtn = poolButtons.requestEntity();
				this.positionCTriggerBtn?.object3D.position.set(0, 2.5, 0.4);
				this.positionCTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionCTriggerBtn?.play();
				this.positionCTriggerBtn?.addEventListener('click', () => {
					this.positonCButtonHandler();
					if (this.positionCTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionCTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionCTriggerBtn;
					}
				});

				this.positionDTriggerBtn = poolButtons.requestEntity();
				this.positionDTriggerBtn?.object3D.position.set(0, 1, 0.4);
				this.positionDTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionDTriggerBtn?.play();
				this.positionDTriggerBtn?.addEventListener('click', () => {
					this.positonDButtonHandler();
					if (this.positionDTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionDTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionDTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionDTriggerBtn;
					}
				});

				this.positionETriggerBtn = poolButtons.requestEntity();
				this.positionETriggerBtn?.object3D.position.set(-2, 5, 1);
				this.positionETriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionETriggerBtn?.play();
				this.positionETriggerBtn?.addEventListener('click', () => {
					this.positonEButtonHandler();
					if (this.positionETriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionETriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionETriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionETriggerBtn;
					}
				});

				this.positionFTriggerBtn = poolButtons.requestEntity();
				this.positionFTriggerBtn?.object3D.position.set(5, 0, -1);
				this.positionFTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionFTriggerBtn?.play();
				this.positionFTriggerBtn?.addEventListener('click', () => {
					this.positonFButtonHandler();
				});
			}
		},
		tick(this: ISupercooledCombustionSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
			if (this.mixer_1) {
				this.mixer_1.update(deltaTime * 0.001);
			}
			TWEEN.update();
		},
	},
};
export { SupercooledCombustionScene as SupercooledCombustionSceneComponent }