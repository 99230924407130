import {
  Stack,
  Box,
  Typography,
  useTheme,
  Button,
  useMediaQuery,
} from '@mui/material';
import { IconImage } from '../../../shared/components/IconImage';
import { CustomContainer } from '../../../shared/components/CustomContainer';
import { CoursesBG } from './CoursesBG';
import { useHandlers } from './hooks';
import { DisplayEnum } from '../../../shared/enums';
import { TrainingTypeEnum } from '../../Сourses/utils/enums';
import { useTranslation } from 'react-i18next';

import Book from '../../../assets/book.svg';
import Physics from '../../../assets/physics.png';
import Biology from '../../../assets/biology.png';
import Chemistry from '../../../assets/chemistry.png';

export function Courses() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { handleTypeChange } = useHandlers();
  const { t, i18n } = useTranslation();
  const i18nPath = 'LANDING_PAGE.SIMULATIONS_COMPONENT.';

  return (
    <Stack pt={tablet ? 3 : 6} id='simulations'>
      <CustomContainer>
        <CoursesBG>
          <Box mt={tablet ? 3 : 10} textAlign='center'>
            <Typography
              fontSize={tablet ? 28 : 44}
              fontFamily='GilroyBold'
              color='#000'
              lineHeight={1}
              mb={2}
            >
              {t(i18nPath + 'TITLE')}
            </Typography>
            <Typography
              fontSize={16}
              fontFamily='GilroySemiBold'
              color={theme.palette.text.secondary}
              mb={5}
              maxWidth={880}
              mx='auto'
            >
              {t(i18nPath + 'DESCRIPTION')}
            </Typography>
          </Box>
        </CoursesBG>
        <Stack
          direction={mobile ? 'column' : 'row'}
          alignItems='center'
          justifyContent='space-between'
          mb={mobile ? 3 : 7}
        >
          <Stack
            width={mobile ? '100%' : 380}
            alignItems='center'
            mb={mobile ? 2 : 0}
            borderRadius='30px'
            padding={mobile ? 0.8 : 1.2}
            sx={{
              cursor: 'pointer',
              '&:hover': { backgroundColor: 'rgba(238, 128, 101, 0.2)' },
            }}
            onClick={() => {
              handleTypeChange(TrainingTypeEnum.Physics);
            }}
          >
            <Box>
              <IconImage src={Physics} width='159px' />
            </Box>
            <Typography
              fontSize={22}
              fontFamily='GilroyBold'
              color='#262626'
              lineHeight={1}
              mt={2}
              mb={1}
            >
              {t(i18nPath + 'PHYSICS.TITLE')}
            </Typography>
            <Typography
              fontSize={tablet ? 14 : 16}
              fontFamily='GilroyMedium'
              color={theme.palette.text.secondary}
              lineHeight={1}
              textAlign='center'
            >
              {t(i18nPath + 'PHYSICS.DESCRIPTION')}
            </Typography>
          </Stack>

          <Stack
            width={mobile ? '100%' : 380}
            alignItems='center'
            mb={mobile ? 2 : 0}
            borderRadius='30px'
            padding={mobile ? 0.8 : 1.2}
            sx={{
              cursor: 'pointer',
              '&:hover': { backgroundColor: 'rgba(171, 206, 106, 0.2)' },
            }}
            onClick={() => {
              handleTypeChange(TrainingTypeEnum.Biology);
            }}
          >
            <Box>
              <IconImage src={Biology} width='159px' />
            </Box>
            <Typography
              fontSize={22}
              fontFamily='GilroyBold'
              color='#262626'
              lineHeight={1}
              mt={2}
              mb={1}
            >
              {t(i18nPath + 'BIOLOGY.TITLE')}
            </Typography>
            <Typography
              fontSize={tablet ? 14 : 16}
              fontFamily='GilroyMedium'
              color={theme.palette.text.secondary}
              lineHeight={1}
              textAlign='center'
            >
              {t(i18nPath + 'BIOLOGY.DESCRIPTION')}
            </Typography>
          </Stack>

          <Stack
            width={mobile ? '100%' : 380}
            alignItems='center'
            borderRadius='30px'
            padding={mobile ? 0.8 : 1.2}
            sx={{
              cursor: 'pointer',
              '&:hover': { backgroundColor: 'rgba(164, 131, 236, 0.2)' },
            }}
            onClick={() => {
              handleTypeChange(TrainingTypeEnum.Chemistry);
            }}
          >
            <Box>
              <IconImage src={Chemistry} width='159px' />
            </Box>
            <Typography
              fontSize={22}
              fontFamily='GilroyBold'
              color='#262626'
              lineHeight={1}
              mt={2}
              mb={1}
            >
              {t(i18nPath + 'CHEMISTRY.TITLE')}
            </Typography>
            <Typography
              fontSize={tablet ? 14 : 16}
              fontFamily='GilroyMedium'
              color={theme.palette.text.secondary}
              lineHeight={1}
              textAlign='center'
            >
              {t(i18nPath + 'CHEMISTRY.DESCRIPTION')}
            </Typography>
          </Stack>
        </Stack>
      </CustomContainer>
      <Box mt={-0.6} display='flex' justifyContent='center'>
        <Button
          variant='go_to_courses'
          onClick={() => {
            handleTypeChange(TrainingTypeEnum.All);
          }}
        >
          <Box mr={1}>
            <IconImage src={Book} width='14px' height='17px' />
          </Box>
          {t('COMMON.BUTTON.GO_TO_SIMULATIONS')}
        </Button>
      </Box>
    </Stack>
  );
}

export default Courses;
