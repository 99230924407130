import {Routes, Route} from 'react-router-dom';
import {Biology1Aframe} from './1-sperm-cell/aframe';
import {Biology1BAframe} from './1.b-nerve-cell/aframe';
import {BiologyFoodTextsStarchAframe} from './food-test-starch/aframe';
import {BiologySpecialisedCellsAframe} from './2-specialised-cells/aframe';
import {BiologyFoodTextsLipidsAframe} from './food-tests-lipids/aframe';
import {BiologyFoodTextsProteinAframe} from './food-tests-protein/aframe';
import {BiologyPhotosynthesisAframe} from './photosynthesis/aframe';
import {PlantsGlucoseAframe} from './plants-glucose/aframe';
import {BacteriaCellAframe} from './bacteria-cell/aframe';
import {BiologyFoodTestSugarAframe} from './food-test-sugar/aframe';
import {BiologyDigestiveSystemAframe} from './26-digestive-system/aframe';
import {AnimalCellAframe} from './animal-cell/aframe';
import {PlantCellAframe} from './plant-cell/aframe';
import {BiologyMuscleCellAframe} from './muscle-cell/aframe';
import {BiologyPhotosynthesisLimitingFactorsAframe} from './photosynthesis-limiting-factors/aframe';
import {BiologyLightIntensityAframe} from './light-intensity/aframe';
import {BiologyPlantHormonesAframe} from './plant-hormones/aframe';
import {BiologyPlantResponsesAframe} from './plant-responses/aframe';
import {BiologyMicroscopeAframe} from './microscope/aframe';
import {BiologyDNAAframe} from './32-dna/aframe';
import {BiologyMicrobiologyAframe} from './microbiology-af-17/aframe';
import {BiologyNervousSystemAframe} from './nervous-system/aframe';
import {BiologyBacterialDiseasesAframe} from "./27-bacterial-diseases/aframe";
import {BiologyViralDiseasesAframe} from "./29-viral-diseases/aframe";
import {BiologyCloningCuttingAframe} from "./45-cloning-cutting/aframe";
import {BiologyCloningTissueCultureAframe} from "./46-cloning-tissue-culture/aframe";
import {BiologyEyeAframe} from './eye/aframe';
import {BiologyChromosomesAframe} from './chromosomes/aframe';
import {BiologyProtistDiseasesAframe} from "./30-protist-diseases/aframe";
import {BiologyBrainAframe} from "./20-brain/aframe";

export function BiologySimulationsRoutes() {
    return (
        <Routes>
            <Route path='/biology-sperm-cell' element={<Biology1Aframe/>}/>
            <Route path='/biology-nerve-cell' element={<Biology1BAframe/>}/>
            <Route path='/biology-muscle-cell' element={<BiologyMuscleCellAframe/>}/>
            <Route path='/biology-food-tests-starch' element={<BiologyFoodTextsStarchAframe/>}/>
            <Route path='/biology-food-tests-lipids' element={<BiologyFoodTextsLipidsAframe/>}/>
            <Route path='/biology-food-tests-protein' element={<BiologyFoodTextsProteinAframe/>}/>
            <Route path='/biology-food-tests-sugar' element={<BiologyFoodTestSugarAframe/>}/>
            <Route path='/biology-specialised-cells' element={<BiologySpecialisedCellsAframe/>}/>
            <Route path='/biology-photosynthesis' element={<BiologyPhotosynthesisAframe/>}/>
            <Route path='/biology-plants-glucose' element={<PlantsGlucoseAframe/>}/>
            <Route path='/biology-bacteria-cell' element={<BacteriaCellAframe/>}/>
            <Route path='/biology-digestive-system' element={<BiologyDigestiveSystemAframe/>}/>
            <Route path='/biology-animal-cell' element={<AnimalCellAframe/>}/>
            <Route path='/biology-plant-cell' element={<PlantCellAframe/>}/>
            <Route path='/biology-photosynthesis-limiting-factors'
                   element={<BiologyPhotosynthesisLimitingFactorsAframe/>}/>
            <Route path='/biology-plant-hormones' element={<BiologyPlantHormonesAframe/>}/>
            <Route path='/biology-photosynthesis-light-intensity' element={<BiologyLightIntensityAframe/>}/>
            <Route path='/biology-plant-responses' element={<BiologyPlantResponsesAframe/>}/>
            <Route path='/biology-microscope' element={<BiologyMicroscopeAframe/>}/>
            <Route path='/biology-dna' element={<BiologyDNAAframe/>}/>
            <Route path='/biology-microbiology' element={<BiologyMicrobiologyAframe/>}/>
            <Route path='/biology-nervous-system' element={<BiologyNervousSystemAframe/>}/>
            <Route path='/biology-bacterial-diseases' element={<BiologyBacterialDiseasesAframe/>}/>
            <Route path='/biology-viral-diseases' element={<BiologyViralDiseasesAframe/>}/>
            <Route path='/biology-protist-diseases' element={<BiologyProtistDiseasesAframe/>}/>
            <Route path='/biology-cloning-cutting' element={<BiologyCloningCuttingAframe/>}/>
            <Route path='/biology-cloning-tissue-culture' element={<BiologyCloningTissueCultureAframe/>}/>
            <Route path='/biology-eye' element={<BiologyEyeAframe/>}/>
            <Route path='/biology-brain' element={<BiologyBrainAframe/>}/>
            <Route path='/biology-chromosomes' element={<BiologyChromosomesAframe/>}/>
        </Routes>
    );
}
