import type { Actions, SharedState } from './typings';
import { openBurgerMenuProducer } from './Actions';
import { changeRoleTypeProducer } from './Actions/ChangeRoleType';

export function sharedReducer(state: SharedState, action: Actions) {
  switch (action.type) {
    case 'shared/openBurgerMenu':
      return openBurgerMenuProducer(state, action.payload);
    case 'shared/changeRoleType':
      return changeRoleTypeProducer(state, action.payload);
    default:
      return { ...state };
  }
}
