import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { IconImage } from 'shared/components/IconImage';
import WallLogo from './wall-logo';
import Logo from '../../../../assets/Logo.png';

const CircularProgressScreen: React.FC<any> = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,253,3)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99,
      }}
    >
      <Box mb={1}>
        <IconImage src={Logo} width='140px' />
      </Box>
      <CircularProgress size={40} color={'inherit'} />

      <WallLogo />
    </Box>
  );
};

export default CircularProgressScreen;
