import * as AFrame from "aframe";
import * as THREE from "three";
import * as TWEEN from '@tweenjs/tween.js';
import { IAnnotationAframe } from "../../../lib/aframe/components/annotation";
import { IAnnotationSystemAframe } from "../../../lib/aframe/systems/annotation-system";
import { WorldButtonAframeInstance } from "../../../lib/aframe/components/world-button";

interface PoolComponent extends AFrame.Component {
  requestEntity(): AFrame.Entity | null;
  returnEntity(entity: AFrame.Entity): void;
}
interface INervousSystemSceneAframe {
  currentDeactivatedButton: AFrame.Entity<
    AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>
  >;
  poolEntity: AFrame.Entity<
    AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>
  >;
  annotationComponent: IAnnotationAframe;
  actionRespiration: THREE.AnimationAction;
  currentClips: THREE.AnimationAction[];

  mixer_1: THREE.AnimationMixer;
  mixer_2: THREE.AnimationMixer;
  mixer_3: THREE.AnimationMixer;
  mixer_4: THREE.AnimationMixer;

  el: AFrame.Entity;
  currentAssetId: number;
  poolButtons: PoolComponent;

  sound: any;
  returnToStartButton: any;

  stimulusPhone: AFrame.Entity;
  receptorEar: AFrame.Entity;
  motorNeuron: AFrame.Entity;
  centralNervSys: AFrame.Entity;
  sensoryNeuron: AFrame.Entity;
  effectorMuscle: AFrame.Entity;
  responsePhone: AFrame.Entity;

  stimulusTriggerBtn: AFrame.Entity | null;
  receptorTriggerBtn: AFrame.Entity | null;
  sensoryTriggerBtn: AFrame.Entity | null;
  CNSTriggerBtn: AFrame.Entity | null;
  neuronTriggerBtn: AFrame.Entity | null;
  effectorTriggerBtn: AFrame.Entity | null;
  responseTriggerBtn: AFrame.Entity | null;
  cancelTriggerBtn: AFrame.Entity | null;
  answerTriggerBtn: AFrame.Entity | null;

  stimulusButtonHandler: () => void;
  receptorButtonHandler: () => void;
  sensoryButtonHandler: () => void;
  CNSButtonHandler: () => void;
  neuronButtonHandler: () => void;
  effectorButtonHandler: () => void;
  responseButtonHandler: () => void;
  cancelButtonHandler: () => void;
  answerButtonHandler: () => void;

  actionMotorAnim: THREE.AnimationAction;
  actionSensoryAnim: THREE.AnimationAction;
  actionResponse_AnswerAnim: THREE.AnimationAction;
  actionResponse_RingAnim: THREE.AnimationAction;
  actionPhoneAnim: THREE.AnimationAction;

  onObjectSelected:
  | ((selectedObject: { title: string; body: string }) => void)
  | null;
}

const NervousSystemScene = {
  name: "experiment",
  val: {
    init(this: INervousSystemSceneAframe) {
      var isInitialised = false;
      let counter = 0;
      this.el.addEventListener("model-loaded", () => {
        counter++;
        if (counter < 7) return
        if (!isInitialised) {
          this.currentClips = [];
          this.sound = document.getElementById("call") as any;
          this.returnToStartButton = document.getElementById("return-to-start-button") as any;

          this.stimulusPhone = document.getElementById("Stimulus_Phone") as AFrame.Entity;
          this.receptorEar = document.getElementById("Receptor_Ear") as AFrame.Entity;
          this.sensoryNeuron = document.getElementById("Sensory_Neuron") as AFrame.Entity;
          this.centralNervSys = document.getElementById("CNS") as AFrame.Entity;
          this.motorNeuron = document.getElementById("Motor_Neuron") as AFrame.Entity;
          this.effectorMuscle = document.getElementById("Effector_Muscle") as AFrame.Entity;
          this.responsePhone = document.getElementById("Response_Phone") as AFrame.Entity;

          const scene = this.el.sceneEl as AFrame.Scene & {
            systems: { "annotation-system": IAnnotationSystemAframe };
          };
          const annotationSystem = scene.systems["annotation-system"];
          this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

          this.el.setAttribute("annotation", "");
          this.annotationComponent = this.el.components
            .annotation as IAnnotationAframe;
          //get pool entity
          this.poolEntity = document.querySelector("[pool]") as AFrame.Entity;
          // ony initialise buttons once pool has loaded
          if (this.poolEntity.hasLoaded) {
            initialiseButtons();
          } else {
            this.poolEntity.addEventListener("loaded", () => {
              initialiseButtons();
            });
          }
          initialiseAnimations();
          isInitialised = true;
          firstScene();
        } else return;
      });

      const playSound = () => {
        if (this.sound) {
          this.sound.play();
        }
      };

      const stopSound = () => {
        if (this.sound) {
          this.sound.pause();
          this.sound.currentTime = 0;
        }
      };

      const firstScene = () => {
        this.returnToStartButton.classList.remove("return-to-start-button-visible");
        this.returnToStartButton.classList.add("return-to-start-button-invisible");
        if (this.currentClips) {
          this.currentClips.forEach((currentClip) => {
            currentClip.stop();
          });
        }

        if (this.stimulusPhone) {
          this.stimulusPhone.object3D.visible = true;
          this.stimulusPhone.object3D.scale.set(25, 25, 25);
          this.stimulusPhone.object3D.position.set(0, 0, 0);
        }
        if (this.stimulusTriggerBtn) {
          this.stimulusTriggerBtn.object3D.visible = true;
          this.stimulusTriggerBtn.object3D.position.set(0, 0, 0.5);

        }
        if (this.receptorEar) {
          this.receptorEar.object3D.visible = false;
          this.receptorEar.object3D.scale.set(25, 25, 25);
          this.receptorEar.object3D.position.set(0, 0, 0);
        }
        if (this.receptorTriggerBtn) {
          this.receptorTriggerBtn.object3D.visible = false;
          this.receptorTriggerBtn.object3D.position.x = -50;
        }
        if (this.sensoryNeuron) {
          this.sensoryNeuron.object3D.visible = false;
          this.sensoryNeuron.object3D.scale.set(25, 25, 25);
          this.sensoryNeuron.object3D.position.set(0, 0, 0);
        }
        if (this.sensoryTriggerBtn) {
          this.sensoryTriggerBtn.object3D.visible = false;
          this.sensoryTriggerBtn.object3D.position.x = -50;
        }
        if (this.centralNervSys) {
          this.centralNervSys.object3D.visible = false;
          this.centralNervSys.object3D.scale.set(18, 18, 18);
          this.centralNervSys.object3D.position.set(0, 2, 0);
        }
        if (this.CNSTriggerBtn) {
          this.CNSTriggerBtn.object3D.visible = false;
          this.CNSTriggerBtn.object3D.position.x = -50;
        }
        if (this.motorNeuron) {
          this.motorNeuron.object3D.visible = false;
          this.motorNeuron.object3D.scale.set(25, 25, 25);
          this.motorNeuron.object3D.position.set(0, -2, 0);
        }
        if (this.neuronTriggerBtn) {
          this.neuronTriggerBtn.object3D.visible = false;
          this.neuronTriggerBtn.object3D.position.x = -50;
        }
        if (this.effectorMuscle) {
          this.effectorMuscle.object3D.visible = false;
          this.effectorMuscle.object3D.scale.set(35, 35, 35);
          this.effectorMuscle.object3D.position.set(0, 0, 0);
        }
        if (this.effectorTriggerBtn) {
          this.effectorTriggerBtn.object3D.visible = false;
          this.effectorTriggerBtn.object3D.position.x = -50;
        }
        if (this.responsePhone) {
          this.responsePhone.object3D.visible = false;
          this.responsePhone.object3D.scale.set(25, 25, 25);
          this.responsePhone.object3D.position.set(0, 0, 0);
        }
        if (this.responseTriggerBtn) {
          this.responseTriggerBtn.object3D.visible = false;
          this.responseTriggerBtn.object3D.position.x = -50;
        }
        if (this.cancelTriggerBtn) {
          this.cancelTriggerBtn.object3D.visible = false;
        }
        if (this.answerTriggerBtn) {
          this.answerTriggerBtn.object3D.visible = false;
        }
        movePhoneButtonOutside();

        stimulusButtonPressed = false;
        receptorButtonPressed = false;
        sensoryButtonPressed = false;
        CNSButtonPressed = false;
        neuronButtonPressed = false;
        effectorButtonPressed = false;
        responseButtonPressed = false;
      };

      this.el.sceneEl?.addEventListener("lesson-start", () => {
        console.log("lesson started");
        // remove tap place
        const ring = document.getElementById("ring");
        if (ring) {
          ring.removeAttribute("tap-place");
          this.el.sceneEl?.removeChild(ring);
        }
      });

      const movePhoneButtonOutside = () => {
        if (this.cancelTriggerBtn) {
          this.cancelTriggerBtn.object3D.position.x = -50;
        }
        if (this.answerTriggerBtn) {
          this.answerTriggerBtn.object3D.position.x = -50;
        }
      };

      // when model is loaded adding recenter functionality
      this.el.sceneEl?.addEventListener("lesson-recenter", () => {
        this.el.sceneEl?.emit("recenter");
        // check if the ring exists
        // if it does ignore the event
        const ring = document.getElementById("ring");
        if (ring) {
          return;
        } else {
          const ring = document.createElement("a-ring");

          ring.setAttribute("id", "ring");
          ring.setAttribute(
            "tap-place-air",
            "id: sceneHolder; offset: 0 -3 -3"
          );
          ring.setAttribute("material", "shader: flat; color: #ffffff");
          ring.setAttribute("rotation", "-90 0 0");
          ring.setAttribute("radius-inner", "0.5");
          ring.setAttribute("radius-outer", "0.8");

          // Attach the created ring element to the scene or another parent entity.
          this.el.sceneEl?.appendChild(ring);
          // fix the annotations if there is an active button
          if (this.currentDeactivatedButton) {
            (
              this.currentDeactivatedButton.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).activate();
            // remove the line
            this.annotationComponent.deactivate();
          }
          stopSound();
        }
      });

      this.el.sceneEl?.addEventListener('return-to-start', () => {
        firstScene();
      })

      const closeAnnotation = () => {
        const scene = document.querySelector('a-scene');
        scene.emit('annotation-close');
      };
      const initialiseAnimations = () => {
        const animatedEl_1 = this.stimulusPhone.object3D.getObjectByName(
          "Scene"
        ) as any;

        const animatedEl_2 = this.sensoryNeuron.object3D.getObjectByName(
          "Scene"
        ) as any;

        const animatedEl_3 = this.motorNeuron.object3D.getObjectByName(
          "Scene"
        ) as any;

        const animatedEl_4 = this.responsePhone.object3D.getObjectByName(
          "Scene"
        ) as any;

        this.mixer_1 = new THREE.AnimationMixer(animatedEl_1);
        this.mixer_2 = new THREE.AnimationMixer(animatedEl_2);
        this.mixer_3 = new THREE.AnimationMixer(animatedEl_3);
        this.mixer_4 = new THREE.AnimationMixer(animatedEl_4);

        const showPhoneAnim = animatedEl_1.animations[0];
        const showSensoryAnim = animatedEl_2.animations[0];
        const showMotorAnim = animatedEl_3.animations[0];
        const showResponse_AnswerAnim = animatedEl_4.animations[0];
        const showResponse_RingAnim = animatedEl_4.animations[1];

        this.actionPhoneAnim = this.mixer_1.clipAction(showPhoneAnim);
        this.actionSensoryAnim = this.mixer_2.clipAction(showSensoryAnim);
        this.actionMotorAnim = this.mixer_3.clipAction(showMotorAnim);
        this.actionResponse_AnswerAnim = this.mixer_4.clipAction(showResponse_AnswerAnim);
        this.actionResponse_RingAnim = this.mixer_4.clipAction(showResponse_RingAnim);
      };
      var stimulusButtonPressed = false;
      this.stimulusButtonHandler = () => {
        if (this.currentClips) {
          this.currentClips.forEach((currentClip) => {
            currentClip.stop();
          });
        }

        this.currentClips[0] = this.actionPhoneAnim;
        this.actionPhoneAnim.reset();
        this.actionPhoneAnim.clampWhenFinished = false;
        this.actionPhoneAnim.play();

        if (!stimulusButtonPressed) {
          setTimeout(() => {
            new TWEEN.Tween(this.stimulusPhone.object3D.position)
              .to({ x: -6, y: 3, z: -6 }, 1400)
              .start();
  
            if (this.stimulusTriggerBtn) {
              new TWEEN.Tween(this.stimulusTriggerBtn.object3D.position)
                .to({ x: -5.9, y: 3.1, z: -5.9 }, 1400)
                .start();
            }
            setTimeout(() => {
              if (this.receptorEar) {
                this.receptorEar.object3D.visible = true;
              }
              if (this.receptorTriggerBtn) {
                this.receptorTriggerBtn.object3D.visible = true;
                this.receptorTriggerBtn.object3D.position.set(0, 0, 0.5);
              }
            }, 500);
            closeAnnotation();
            stopSound();
          }, 5000);
          
          playSound();
          stimulusButtonPressed = true;
        }

        if (this.onObjectSelected) {
          const title = "Stimulus";
          const body =
            "A stimulus is a trigger from the environment such as light, sound, touch pressure, etc. Humans are complex organisms and need to be able to respond to various stimuli. In this instance, the stimulus is the noise of the phone ringing.";
          this.onObjectSelected({ title, body });
        } else {
          console.log("No object selected method");
        }
        
        movePhoneButtonOutside();
      };

      var receptorButtonPressed = false;
      this.receptorButtonHandler = () => {
        if (this.currentClips) {
          this.currentClips.forEach((currentClip) => {
            currentClip.stop();
          });
        }

        if (!receptorButtonPressed) {
          setTimeout(() => {
            new TWEEN.Tween(this.receptorEar.object3D.position)
              .to({ x: -3.2, y: 3, z: -6 }, 1400)
              .start();
  
            new TWEEN.Tween(this.receptorEar.object3D.scale)
              .to({ x: 18, y: 18, z: 18 }, 1400)
              .start();
  
            if (this.receptorTriggerBtn) {
              new TWEEN.Tween(this.receptorTriggerBtn.object3D.position)
                .to({ x: -3.1, y: 3.1, z: -5.6 }, 1400)
                .start();
            }
            setTimeout(() => {
              if (this.sensoryNeuron) {
                this.sensoryNeuron.object3D.visible = true;
              }
              if (this.sensoryTriggerBtn) {
                this.sensoryTriggerBtn.object3D.visible = true;
                this.sensoryTriggerBtn.object3D.position.set(0.1, 0.7, 0.5);
              }
            }, 500);
            closeAnnotation();
            stopSound();
          }, 5000);
          receptorButtonPressed = true;
        }

        if (this.onObjectSelected) {
          const title = "Receptor";
          const body =
            "Stimuli are detected by receptors in our body. There are receptors for taste on your tongue, for light in your eye, and for smell in your nose. In this example, the sound receptors in the ears are detecting the ringing sound.";
          this.onObjectSelected({ title, body });
        } else {
          console.log("No object selected method");
        }
        stopSound();
        movePhoneButtonOutside();
      };
      var sensoryButtonPressed = false;
      this.sensoryButtonHandler = () => {
        if (this.currentClips) {
          this.currentClips.forEach((currentClip) => {
            currentClip.stop();
          });
        }

        this.currentClips[0] = this.actionSensoryAnim;
        this.actionSensoryAnim.reset();
        this.actionSensoryAnim.play();

        if (!sensoryButtonPressed) {
          setTimeout(() => {
            new TWEEN.Tween(this.sensoryNeuron.object3D.position)
              .to({ x: -6, y: -1, z: -6 }, 1400)
              .start();
  
            if (this.sensoryTriggerBtn) {
              new TWEEN.Tween(this.sensoryTriggerBtn.object3D.position)
                .to({ x: -5.9, y: -0.8, z: -5.9 }, 1400)
                .start();
            }
            setTimeout(() => {
              if (this.centralNervSys) {
                this.centralNervSys.object3D.visible = true;
              }
              if (this.CNSTriggerBtn) {
                this.CNSTriggerBtn.object3D.visible = true;
                this.CNSTriggerBtn.object3D.position.set(0, 3, 1.2);
              }
            }, 500);
            closeAnnotation();
            stopSound();
          }, 5000);
          sensoryButtonPressed = true;
        }

        if (this.onObjectSelected) {
          const title = "Sensory Neuron";
          const body =
            "There are sensory neurones that carry information as electrical impulses from receptors in sensory organs, in this example, the ear to the central nervous system or CNS.";
          this.onObjectSelected({ title, body });
        } else {
          console.log("No object selected method");
        }
        stopSound();
        movePhoneButtonOutside();
      };
      var CNSButtonPressed = false;
      this.CNSButtonHandler = () => {
        if (this.currentClips) {
          this.currentClips.forEach((currentClip) => {
            currentClip.stop();
          });
        }

        if (!CNSButtonPressed) {
          setTimeout(() => {
            new TWEEN.Tween(this.centralNervSys.object3D.position)
              .to({ x: 0, y: 3.2, z: -6 }, 1400)
              .start();
  
            if (this.CNSTriggerBtn) {
              new TWEEN.Tween(this.CNSTriggerBtn.object3D.position)
                .to({ x: 0, y: 4.4, z: -4.5 }, 1400)
                .start();
            }
            setTimeout(() => {
              if (this.motorNeuron) {
                this.motorNeuron.object3D.visible = true;
              }
              if (this.neuronTriggerBtn) {
                this.neuronTriggerBtn.object3D.visible = true;
                this.neuronTriggerBtn.object3D.position.set(-1.2, 0.5, 0.5);
              }
            }, 500);
            closeAnnotation();
            stopSound();
          }, 5000);
          CNSButtonPressed = true;
        }

        if (this.onObjectSelected) {
          const title =
            '<span style="font-size: 20px;">Central Nervous System (CNS)</span>';
          const body =
            "The impulses from sensory neurones are carried to the CNS - which is made up of the brain and spinal cord. That’s where the sensory information gets processed; in this case, sound. Once the CNS has processed the stimulus, it wants to make you move.";
          this.onObjectSelected({ title, body });
        } else {
          console.log("No object selected method");
        }
        stopSound();
        movePhoneButtonOutside();
      };
      var neuronButtonPressed = false;
      this.neuronButtonHandler = () => {
        if (this.currentClips) {
          this.currentClips.forEach((currentClip) => {
            currentClip.stop();
          });
        }

        this.currentClips[0] = this.actionMotorAnim;
        this.actionMotorAnim.reset();
        this.actionMotorAnim.play();

        if (!neuronButtonPressed) {
          setTimeout(() => {
            new TWEEN.Tween(this.motorNeuron.object3D.position)
              .to({ x: 4, y: 0, z: -6 }, 1400)
              .start();
  
            new TWEEN.Tween(this.motorNeuron.object3D.scale)
              .to({ x: 22, y: 22, z: 22 }, 1400)
              .start();
  
            if (this.neuronTriggerBtn) {
              new TWEEN.Tween(this.neuronTriggerBtn.object3D.position)
                .to({ x: 2.9, y: 2.4, z: -5.9 }, 1400)
                .start();
            }
            setTimeout(() => {
              if (this.effectorMuscle) {
                this.effectorMuscle.object3D.visible = true;
              }
              if (this.effectorTriggerBtn) {
                this.effectorTriggerBtn.object3D.visible = true;
                this.effectorTriggerBtn.object3D.position.set(0, 0, 1.2);
              }
            }, 500);
            closeAnnotation();
            stopSound();
          }, 5000);
          neuronButtonPressed = true;
        }

        if (this.onObjectSelected) {
          const title = "Motor Neuron";
          const body =
            "The impulses are then carried from the CNS to motor neurons. Motor neurons carry impulses to the effectors.";
          this.onObjectSelected({ title, body });
        } else {
          console.log("No object selected method");
        }
        stopSound();
        movePhoneButtonOutside();
      };
      var effectorButtonPressed = false;
      this.effectorButtonHandler = () => {
        if (this.currentClips) {
          this.currentClips.forEach((currentClip) => {
            currentClip.stop();
          });
        }

        if (!effectorButtonPressed) {
          setTimeout(() => {
            new TWEEN.Tween(this.effectorMuscle.object3D.position)
              .to({ x: 3.7, y: -2, z: -6 }, 1400)
              .start();
  
            new TWEEN.Tween(this.effectorMuscle.object3D.scale)
              .to({ x: 22, y: 22, z: 22 }, 1400)
              .start();
  
            if (this.effectorTriggerBtn) {
              new TWEEN.Tween(this.effectorTriggerBtn.object3D.position)
                .to({ x: 3.4, y: -1.8, z: -5.9 }, 1400)
                .start();
            }
            setTimeout(() => {
              if (this.responsePhone) {
                this.responsePhone.object3D.visible = true;
              }
              if (this.responseTriggerBtn) {
                this.responseTriggerBtn.object3D.visible = true;
                this.responseTriggerBtn.object3D.position.set(0, 0, 0.5);
              }
            }, 500);
            closeAnnotation();
            stopSound();
          }, 5000);
          effectorButtonPressed = false;
        }

        if (this.onObjectSelected) {
          const title = "Effector";
          const body =
            "Effectors can be muscles for physical responses eg. moving. Effectors can be glands that release hormones that can cause internal changes in your body such as changes in temperature, heart rate, etc. The effector then is responsible for a response.";
          this.onObjectSelected({ title, body });
        } else {
          console.log("No object selected method");
        }
        stopSound();
        movePhoneButtonOutside();
      };
      var responseButtonPressed = false;
      this.responseButtonHandler = () => {
        if (!responseButtonPressed) {
          if (this.currentClips) {
            this.currentClips.forEach((currentClip) => {
              currentClip.stop();
            });
          }
          this.currentClips[0] = this.actionResponse_RingAnim;
          this.actionResponse_RingAnim.reset();
          this.actionResponse_RingAnim.repetitions = 1;
          this.actionResponse_RingAnim.clampWhenFinished = true;
          this.actionResponse_RingAnim.play();

          this.responseTriggerBtn?.object3D.position.set(0, 1, 8.6);
          this.answerTriggerBtn?.object3D.position.set(1, -2.8, 8.6);
          this.cancelTriggerBtn?.object3D.position.set(-1, -2.8, 8.6);
          playSound();
          responseButtonPressed = true;
        }
        if (this.onObjectSelected) {
          const title = "Response";
          const body =
            "Muscle movement after hearing a sound is the response. When your phone rings your usual response is to use your neck and eye muscles to look at the screen, and then use your arm, hand and finger muscles to tap ‘accept’ or ‘decline’.";
          this.onObjectSelected({ title, body });
        } else {
          console.log("No object selected method");
        }

      };

      this.cancelButtonHandler = () => {
        const scene = document.querySelector("a-scene");
        if (this.currentClips) {
          this.currentClips.forEach((currentClip) => {
            currentClip.stop();
          });
        }

        this.responseTriggerBtn?.object3D.position.set(0, 0, 0.5);

        new TWEEN.Tween(this.responsePhone.object3D.position)
          .to({ x: 6, y: 3, z: -6 }, 1400)
          .start();

        if (this.responseTriggerBtn) {
          new TWEEN.Tween(this.responseTriggerBtn.object3D.position)
            .to({ x: 5.9, y: 3.1, z: -5.9 }, 1400)
            .start();
        }

        movePhoneButtonOutside();
        stopSound();

        scene.emit("annotation-close");
        this.returnToStartButton.classList.remove("return-to-start-button-invisible");
        this.returnToStartButton.classList.add("return-to-start-button-visible");

        setTimeout(() => {
          firstScene();
        }, 15000);
      };

      this.answerButtonHandler = () => {
        if (this.currentClips) {
          this.currentClips.forEach((currentClip) => {
            currentClip.stop();
          });
        }
        this.currentClips[0] = this.actionResponse_AnswerAnim;
        this.actionResponse_AnswerAnim.reset();
        this.actionResponse_AnswerAnim.repetitions = 1;
        this.actionResponse_AnswerAnim.clampWhenFinished = true;
        this.actionResponse_AnswerAnim.play();

        stopSound();

        this.responseTriggerBtn?.object3D.position.set(0.1, 0, 0.5);
        if (this.answerTriggerBtn) {
          this.answerTriggerBtn.object3D.position.x = -50;
        }
        this.cancelTriggerBtn?.object3D.position.set(0, -2.8, 8.6)

        const scene = document.querySelector("a-scene");
        scene.emit("annotation-close");
        this.returnToStartButton.classList.remove("return-to-start-button-invisible");
        this.returnToStartButton.classList.add("return-to-start-button-visible");

        setTimeout(() => {
          firstScene();
        }, 15000);
      }

      const initialiseButtons = () => {
        const poolButtons = this.poolEntity.components["pool"] as PoolComponent;

        this.stimulusTriggerBtn = poolButtons.requestEntity();
        this.stimulusTriggerBtn?.object3D.position.set(0, 0, 0.5);
        this.stimulusTriggerBtn?.object3D.scale.set(2, 2, 2);
        this.stimulusTriggerBtn?.play();
        this.stimulusTriggerBtn?.addEventListener("click", () => {
          this.stimulusButtonHandler();
          if (this.stimulusTriggerBtn) {
            this.annotationComponent.setObjectToFollow(this.stimulusTriggerBtn);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                "world-button"
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.stimulusTriggerBtn.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.stimulusTriggerBtn;
          }
        });

        this.receptorTriggerBtn = poolButtons.requestEntity();
        this.receptorTriggerBtn?.object3D.position.set(0, 0, 0.5);
        this.receptorTriggerBtn?.object3D.scale.set(2, 2, 2);
        this.receptorTriggerBtn?.play();
        this.receptorTriggerBtn?.addEventListener("click", () => {
          this.receptorButtonHandler();
          if (this.receptorTriggerBtn) {
            this.annotationComponent.setObjectToFollow(this.receptorTriggerBtn);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                "world-button"
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.receptorTriggerBtn.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.receptorTriggerBtn;
          }
        });

        this.sensoryTriggerBtn = poolButtons.requestEntity();
        this.sensoryTriggerBtn?.object3D.position.set(0, 0, 0.5);
        this.sensoryTriggerBtn?.object3D.scale.set(2, 2, 2);
        this.sensoryTriggerBtn?.play();
        this.sensoryTriggerBtn?.addEventListener("click", () => {
          this.sensoryButtonHandler();
          if (this.sensoryTriggerBtn) {
            this.annotationComponent.setObjectToFollow(this.sensoryTriggerBtn);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                "world-button"
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.sensoryTriggerBtn.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.sensoryTriggerBtn;
          }
        });

        this.CNSTriggerBtn = poolButtons.requestEntity();
        this.CNSTriggerBtn?.object3D.position.set(0, 0, 1.2);
        this.CNSTriggerBtn?.object3D.scale.set(2, 2, 2);
        this.CNSTriggerBtn?.play();
        this.CNSTriggerBtn?.addEventListener("click", () => {
          this.CNSButtonHandler();
          if (this.CNSTriggerBtn) {
            this.annotationComponent.setObjectToFollow(this.CNSTriggerBtn);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                "world-button"
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.CNSTriggerBtn.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.CNSTriggerBtn;
          }
        });

        this.neuronTriggerBtn = poolButtons.requestEntity();
        this.neuronTriggerBtn?.object3D.position.set(-1, 2, 0.5);
        this.neuronTriggerBtn?.object3D.scale.set(2, 2, 2);
        this.neuronTriggerBtn?.play();
        this.neuronTriggerBtn?.addEventListener("click", () => {
          this.neuronButtonHandler();
          if (this.neuronTriggerBtn) {
            this.annotationComponent.setObjectToFollow(this.neuronTriggerBtn);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                "world-button"
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.neuronTriggerBtn.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.neuronTriggerBtn;
          }
        });

        this.effectorTriggerBtn = poolButtons.requestEntity();
        this.effectorTriggerBtn?.object3D.position.set(0.1, 0, 0.5);
        this.effectorTriggerBtn?.object3D.scale.set(2, 2, 2);
        this.effectorTriggerBtn?.play();
        this.effectorTriggerBtn?.addEventListener("click", () => {
          this.effectorButtonHandler();
          if (this.effectorTriggerBtn) {
            this.annotationComponent.setObjectToFollow(this.effectorTriggerBtn);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                "world-button"
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.effectorTriggerBtn.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.effectorTriggerBtn;
          }
        });

        this.responseTriggerBtn = poolButtons.requestEntity();
        this.responseTriggerBtn?.object3D.position.set(0.1, 0, 0.5);
        this.responseTriggerBtn?.object3D.scale.set(2, 2, 2);
        this.responseTriggerBtn?.play();
        this.responseTriggerBtn?.addEventListener("click", () => {
          this.responseButtonHandler();
          if (this.responseTriggerBtn) {
            this.annotationComponent.setObjectToFollow(this.responseTriggerBtn);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                "world-button"
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.responseTriggerBtn.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.responseTriggerBtn;
          }
        });

        this.cancelTriggerBtn = poolButtons.requestEntity();
        this.cancelTriggerBtn?.object3D.position.set(13.7, -2.8, 8.5);
        this.cancelTriggerBtn?.object3D.scale.set(8, 8, 8);
        this.cancelTriggerBtn?.play();
        this.cancelTriggerBtn?.addEventListener("click", () => {
          this.cancelButtonHandler();
          if (this.cancelTriggerBtn) {
            this.annotationComponent.setObjectToFollow(this.cancelTriggerBtn);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                "world-button"
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.cancelTriggerBtn.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.cancelTriggerBtn;
          }
        });
        this.answerTriggerBtn = poolButtons.requestEntity();
        this.answerTriggerBtn?.object3D.position.set(16.4, -2.8, 8.5);
        this.answerTriggerBtn?.object3D.scale.set(8, 8, 8);
        this.answerTriggerBtn?.play();
        this.answerTriggerBtn?.addEventListener("click", () => {
          this.answerButtonHandler();
          if (this.answerTriggerBtn) {
            this.annotationComponent.setObjectToFollow(this.answerTriggerBtn);
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                "world-button"
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              this.answerTriggerBtn.components[
              "world-button"
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = this.answerTriggerBtn;
          }
        });
      };
    },
    tick(this: INervousSystemSceneAframe, time: number, deltaTime: number) {
      if (this.mixer_1) {
        this.mixer_1.update(deltaTime * 0.001);
      }
      if (this.mixer_2) {
        this.mixer_2.update(deltaTime * 0.001);
      }
      if (this.mixer_3) {
        this.mixer_3.update(deltaTime * 0.001);
      }
      if (this.mixer_4) {
        this.mixer_4.update(deltaTime * 0.001);
      }
      TWEEN.update();
    },
  },
};
export { NervousSystemScene as NervousSystemSceneComponent };
