import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import '../../styles/hintCarousel.css';
import { LoadersPermissonsEnum } from 'shared/enums';
import CircularProgressScreen from './components/circular-progress-screen';
import CameraAccessDeniedScreen from './components/camera-access-denied-screen';
import WallPermissionsDeniedScreen from './components/wall-permissions-denied-screen';
import MotionOrientationDeniedScreen from './components/motion-orientation-denied-screen';

export interface CarouselItem {
  path: string;
  alt: string;
}

interface LessonLoaderProps {
  closeHintEvent: () => void;
  closeLoaderEvent: () => void;
  items: CarouselItem[];
  showItemIndex: number;
  permissions?: LoadersPermissonsEnum | undefined;
  showHint: boolean;
}

const LessonLoader: React.FC<LessonLoaderProps> = ({
  closeHintEvent,
  closeLoaderEvent,
  items,
  showItemIndex,
  permissions,
  showHint,
}) => {
  const [currentIndex, setCurrentIndex] = useState(showItemIndex);
  const [orderedItems, setOrderedItems] = useState([
    ...items,
    { path: '', alt: '' },
  ]);
  const [showCarousel, setShowCarousel] = useState(true);
  const [permissionsStatus, setPermissionsStatus] = useState(permissions);
  const [wallPromptShow, SetWallPromptShow] = useState(false);

  const handleNext = (now: number) => {
    setCurrentIndex(now);
  };

  const handlePrevious = (now: number) => {
    setCurrentIndex(now);
  };

  const closeLoader = () => {
    closeLoaderEvent();
  };

  useEffect(() => {
    setShowCarousel(showHint);
  }, [showHint]);

  useEffect(() => {
    setCurrentIndex(showItemIndex);
    setOrderedItems([...items, { path: '', alt: '' }]);
  }, [items, showItemIndex]);

  useEffect(() => {
    if (currentIndex === orderedItems.length - 1) {
      setShowCarousel(false);
      closeHintEvent();
    }
  }, [currentIndex, orderedItems]);

  useEffect(() => {
    if (
      permissionsStatus === LoadersPermissonsEnum.CameraAccessSuccess &&
      !showCarousel
    ) {
      closeLoader();
    }
  }, [permissionsStatus, showCarousel]);

  useEffect(() => {
    setPermissionsStatus(permissions);
  }, [permissions]);

  let inDom = false;
  const observer = new MutationObserver(() => {
    if (document.querySelector('.prompt-box-8w')) {
      if (!inDom) {
        SetWallPromptShow(true);
      }
      inDom = true;
    } else if (inDom) {
      SetWallPromptShow(false);
      inDom = false;
      observer.disconnect();
    }
  });
  observer.observe(document.body, { childList: true });

  function Item(props: { key: number; item: CarouselItem }) {
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
      setLoaded(true);
    };
    return (
      <img
        className='carousel-item'
        src={props.item.path}
        alt={props.item.alt}
        style={{ display: loaded ? 'block' : 'none' }}
        onLoad={handleImageLoad}
      />
    );
  }

  return (
    <>
      {showCarousel &&
        (!permissionsStatus ||
          permissionsStatus === LoadersPermissonsEnum.CameraAccessSuccess) && (
          <>
            <Carousel
              className={
                wallPromptShow ? 'freez hint-carousel' : 'hint-carousel'
              }
              autoPlay={false}
              animation='slide'
              swipe={true}
              navButtonsAlwaysInvisible={true}
              index={currentIndex}
              cycleNavigation={false}
              indicatorContainerProps={{ className: 'indicator-container' }}
              indicatorIconButtonProps={{ className: 'indicator-icon-button' }}
              activeIndicatorIconButtonProps={{
                className: 'active-indicator-icon-button',
              }}
              next={(now: number | undefined) => handleNext(now || 0)}
              prev={(now: number | undefined) => handlePrevious(now || 0)}
            >
              {orderedItems.map((item: CarouselItem, i) => (
                <Item key={i} item={item} />
              ))}
            </Carousel>
            {currentIndex !== orderedItems.length - 1 && (
              <p className='slider-text'>Swipe to continue</p>
            )}
          </>
        )}
      {!showCarousel && !permissionsStatus && <CircularProgressScreen />}
      {permissionsStatus === LoadersPermissonsEnum.WallPermissionsDenied && (
        <WallPermissionsDeniedScreen />
      )}
      {permissionsStatus === LoadersPermissonsEnum.MotionOrientationDenied && (
        <MotionOrientationDeniedScreen />
      )}
      {permissionsStatus === LoadersPermissonsEnum.CameraAccessDenied && (
        <CameraAccessDeniedScreen />
      )}
    </>
  );
};

export default LessonLoader;
