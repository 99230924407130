import { Stack, styled } from '@mui/material';
import { DisplayEnum } from '../../../shared/enums';

import Background from '../../../assets/bridge_bg.png';

export const BridgeStack = styled(Stack, { name: 'BridgeStack' })({
  backgroundImage: `url('${Background}')`,
  backgroundSize: '100% 140%',
  backgroundPositionY: '0%',
  borderRadius: '30px',
  boxSizing: 'border-box',
  padding: '45px 60px 127px',
  width: '862px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '70px auto 0',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
    width: '100%',
    margin: '30px auto 0',
    padding: '45px 30px 127px',
  },
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
    padding: '30px 30px 60px',
    flexDirection: 'column',
  },
});
