import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from "../../../lib/aframe/components/world-button";


interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface IAtomsSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    ionsHandler: () => void;
    isotopesHandler: () => void;
    definingAnElementHandler: () => void;
    onObjectSelected: ((selectedObject: { title: string; body: string;}) => void) | null;
    buttonsInitialised: boolean;
    waveAnimation: THREE.AnimationAction
    addNewProtonsAnimation: THREE.AnimationAction
    rotateAnimation: THREE.AnimationAction
    currentAnimation: THREE.AnimationAction
    mixer: THREE.AnimationMixer
    el: AFrame.Entity;
    timeout: NodeJS.Timeout;
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: IAtomsSceneAframe) {
            this.el.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                initialiseAnimations()

            });
            this.el.sceneEl?.addEventListener('anim-toggle', (e) => {
                const event = e as CustomEvent<{ toggle: boolean }>;
                if (event.detail.toggle) {
                    if (this.currentAnimation) this.currentAnimation.paused = false;
                } else {
                    if (this.currentAnimation) this.currentAnimation.paused = true;
                }
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                if (this.timeout) clearTimeout(this.timeout)
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: atom; scale: 20 20 20; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.ionsHandler = () => {
                this.rotateAnimation.stop()
                this.addNewProtonsAnimation.stop()
                this.waveAnimation.play()
                this.currentAnimation = this.waveAnimation
                this.annotationComponent.line.visible = false
                    if (this.onObjectSelected) {
                        const title = 'Ions';
                        const body = 'Atoms are charge neutral because they have the same number of protons as electrons. If an electron is added or removed from an atom, the result is a charged ion. Electrons can be removed by absorbing high energy radiation.'
                        this.onObjectSelected({title, body})
                        this.annotationComponent.line.visible = true
                    } else {
                        console.log('No object selected method')
                    }

            }

            this.isotopesHandler = () => {
                this.rotateAnimation.stop()
                this.waveAnimation.stop()
                this.addNewProtonsAnimation.play()
                this.currentAnimation = this.addNewProtonsAnimation
                if (this.onObjectSelected) {
                    const title = 'Isotopes';
                    const body = 'A nucleus of an element can have different numbers of neutrons, these different types of atoms are known as <b>isotopes</b>. Having too many or too few neutrons will make an atom radioactive.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.definingAnElementHandler = () => {
                this.rotateAnimation.play()
                this.waveAnimation.stop()
                this.addNewProtonsAnimation.stop()
                this.currentAnimation = this.rotateAnimation

                if (this.onObjectSelected) {
                    const title = 'Defining an Element';
                    const body = 'An element is all the different types of atom that have the same number of protons. For example, any atom with three protons in the nucleus is lithium.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseAnimations = () => {
                const atom = document.getElementById('atom') as AFrame.Entity;
                const animEl = atom.object3D.getObjectByName('Physics18_IonsandIsotopes');
                if (animEl) {
                    this.mixer = new THREE.AnimationMixer(animEl);
                    const [wave, protons, rotate] = animEl.animations;
                    this.waveAnimation = this.mixer.clipAction(wave);
                    this.addNewProtonsAnimation = this.mixer.clipAction(protons)
                    this.rotateAnimation = this.mixer.clipAction(rotate)
                    this.rotateAnimation.play()
                    this.currentAnimation = this.rotateAnimation

                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const ionsTriggerBtn = poolButtons.requestEntity()
                ionsTriggerBtn?.setAttribute('position', '0 0.18 0.05')
                ionsTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                ionsTriggerBtn?.play()
                ionsTriggerBtn?.addEventListener('click', () => {
                    this.ionsHandler()
                    if (ionsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(ionsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (ionsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = ionsTriggerBtn
                    }
                });
                const isotopesTriggerBtn = poolButtons.requestEntity()
                isotopesTriggerBtn?.setAttribute('position', '0 0.05 0.05')
                isotopesTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                isotopesTriggerBtn?.play()
                isotopesTriggerBtn?.addEventListener('click', () => {
                    this.isotopesHandler()
                    if (isotopesTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(isotopesTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (isotopesTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = isotopesTriggerBtn
                    }
                });
                const definingAnElementTriggerBtn = poolButtons.requestEntity()
                definingAnElementTriggerBtn?.setAttribute('position', '0 -0.01 0.05')
                definingAnElementTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                definingAnElementTriggerBtn?.play()
                definingAnElementTriggerBtn?.addEventListener('click', () => {
                    this.definingAnElementHandler()
                    if (definingAnElementTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(definingAnElementTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (definingAnElementTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = definingAnElementTriggerBtn
                    }
                });
            };

        },
        tick(this: IAtomsSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {AtomsScene as AtomsSceneComponent}
