import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IInteruptMessage } from 'lib/aframe/components/interupt-message';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface ISpeedOfSoundSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolButtons: PoolComponent;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionMicrophone0CM: THREE.AnimationAction;
	actionMicrophone5CM: THREE.AnimationAction;
	actionMicrophone10CM: THREE.AnimationAction;
	actionMicrophone15CM: THREE.AnimationAction;
	actionMicrophone20CM: THREE.AnimationAction;
	actionMicrophone25CM: THREE.AnimationAction;
	actionSpeakerAnim: THREE.AnimationAction;

	currentClips: THREE.AnimationAction[];
	mixer: THREE.AnimationMixer;

	sliderValue: number;
	muted: boolean;

	groupElement: AFrame.Entity | null;
	speaker: AFrame.Entity | null;
	sound: any;
	baseMesh: THREE.Mesh;
	material: THREE.MeshStandardMaterial;
	startTexture: THREE.Texture;

	interuptMessage: IInteruptMessage;

	el: AFrame.Entity;

	oscilloscopeTriggerBtn: AFrame.Entity | null;
	rulerTriggerBtn: AFrame.Entity | null;
	loudspeakerTriggerBtn: AFrame.Entity | null;
	openedBookTriggerBtn: AFrame.Entity | null;

	oscilloscopeButtonHandler: () => void;
	rulerButtonHandler: () => void;
	loudspeakerButtonHandler: () => void;
	openedBookButtonHandler: () => void;

	mainAnimationChange: () => void;
	secondaryAnimationChange: (secondaryAnimations: THREE.AnimationAction[]) => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const SpeedOfSoundScene = {
	name: 'experiment',
	val: {
		init(this: ISpeedOfSoundSceneAframe) {
			var isInitialised = false;
			this.el.addEventListener('model-loaded', () => {
				if (!isInitialised) {
					this.groupElement = document.getElementById('Experiment') as AFrame.Entity;
					this.speaker = document.getElementById('Speaker') as AFrame.Entity;
					this.baseMesh = this.el.object3D.getObjectByName('Base') as THREE.Mesh
					this.material = this.baseMesh.material as THREE.MeshStandardMaterial;
					this.startTexture = this.material.aoMap as THREE.Texture;
					this.sound = document.getElementById('sound') as any;
					this.interuptMessage = this.el.components['interupt-message'] as unknown as IInteruptMessage;

					this.currentClips = [];

					if (this.speaker) {
						this.speaker.object3D.visible = false;
					}

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;

					const playSound = () => {
						if (this.sound) {
							this.sound.play()
							if (this.interuptMessage) {
								this.interuptMessage.swapImage(1);
								this.interuptMessage.setPosition('-4.5 3 -0.5');
								this.interuptMessage.setScale('40 40 40')
								this.interuptMessage.activate();
							}
						}
					}

					const stopSound = () => {
						if (this.sound && !this.sound.paused) {
							this.sound.pause();
							this.sound.currentTime = 0;
						}
						this.interuptMessage.swapImage(0);
						this.interuptMessage.setPosition('-4.5 3 -0.5');
						this.interuptMessage.setScale('40 40 40')
						this.interuptMessage.activate();
					}

					this.muted = false;

					let clickCounter = 0;
					this.speaker?.addEventListener('click', () => {
						clickCounter++
						if (clickCounter % 2 === 0) {
							stopSound();
						} else {
							if (!this.muted) {
								playSound();
								this.currentClips[0] = this.actionSpeakerAnim;
								this.actionSpeakerAnim.reset();
								this.actionSpeakerAnim.repetitions = 1;
								this.actionSpeakerAnim.clampWhenFinished = false;
								this.actionSpeakerAnim.play();
							} else return
						}
					});

					this.el.sceneEl?.addEventListener('slider-move', (e) => {
						const ce = e as CustomEvent;

						this.sliderValue = ce.detail.value;
						const value = Number(this.sliderValue);

						aoMapSwitcher();
						switch (value) {
							case 0:
								this.material.aoMap = this.startTexture;
								this.material.needsUpdate = true;
								if (this.currentClips) {
									this.currentClips.forEach(currentClip => {
										currentClip.stop();
									});
								}
								this.currentClips[0] = this.actionSpeakerAnim;
								this.actionSpeakerAnim.reset();
								this.actionSpeakerAnim.repetitions = 1;
								this.actionSpeakerAnim.clampWhenFinished = false;
								this.actionSpeakerAnim.play();

								this.currentClips[1] = this.actionMicrophone5CM;
								this.actionMicrophone5CM.reset();
								this.actionMicrophone5CM.repetitions = 1;
								this.actionMicrophone5CM.clampWhenFinished = false;
								this.actionMicrophone5CM.play();
								this.actionMicrophone5CM.paused = true;
								break;
							case 5:
								if (this.interuptMessage) {
									this.interuptMessage.deactivate();
								}
								if (this.currentClips) {
									this.currentClips.forEach(currentClip => {
										currentClip.stop();
									});
								}
								this.currentClips[0] = this.actionSpeakerAnim;
								this.actionSpeakerAnim.reset();
								this.actionSpeakerAnim.repetitions = 1;
								this.actionSpeakerAnim.clampWhenFinished = false;
								this.actionSpeakerAnim.play();

								this.currentClips[1] = this.actionMicrophone0CM;
								this.actionMicrophone0CM.reset();
								this.actionMicrophone0CM.repetitions = 1;
								this.actionMicrophone0CM.clampWhenFinished = false;
								this.actionMicrophone0CM.play();
								this.actionMicrophone0CM.paused = true;
								break;
							case 10:
								if (this.currentClips) {
									this.currentClips.forEach(currentClip => {
										currentClip.stop();
									});
								}
								this.currentClips[0] = this.actionSpeakerAnim;
								this.actionSpeakerAnim.reset();
								this.actionSpeakerAnim.repetitions = 1;
								this.actionSpeakerAnim.clampWhenFinished = false;
								this.actionSpeakerAnim.play();

								this.currentClips[1] = this.actionMicrophone10CM;
								this.actionMicrophone10CM.reset();
								this.actionMicrophone10CM.repetitions = 1;
								this.actionMicrophone10CM.clampWhenFinished = false;
								this.actionMicrophone10CM.play();
								this.actionMicrophone10CM.paused = true;
								break;
							case 15:
								if (this.currentClips) {
									this.currentClips.forEach(currentClip => {
										currentClip.stop();
									});
								}
								this.currentClips[0] = this.actionSpeakerAnim;
								this.actionSpeakerAnim.reset();
								this.actionSpeakerAnim.repetitions = 1;
								this.actionSpeakerAnim.clampWhenFinished = false;
								this.actionSpeakerAnim.play();

								this.currentClips[1] = this.actionMicrophone15CM;
								this.actionMicrophone15CM.reset();
								this.actionMicrophone15CM.repetitions = 1;
								this.actionMicrophone15CM.clampWhenFinished = false;
								this.actionMicrophone15CM.play();
								this.actionMicrophone15CM.paused = true;
								break;
							case 20:
								if (this.currentClips) {
									this.currentClips.forEach(currentClip => {
										currentClip.stop();
									});
								}
								this.currentClips[0] = this.actionSpeakerAnim;
								this.actionSpeakerAnim.reset();
								this.actionSpeakerAnim.repetitions = 1;
								this.actionSpeakerAnim.clampWhenFinished = false;
								this.actionSpeakerAnim.play();

								this.currentClips[1] = this.actionMicrophone20CM;
								this.actionMicrophone20CM.reset();
								this.actionMicrophone20CM.repetitions = 1;
								this.actionMicrophone20CM.clampWhenFinished = false;
								this.actionMicrophone20CM.play();
								this.actionMicrophone20CM.paused = true;
								break;
							case 25:
								if (this.currentClips) {
									this.currentClips.forEach(currentClip => {
										currentClip.stop();
									});
								}
								this.currentClips[0] = this.actionSpeakerAnim;
								this.actionSpeakerAnim.reset();
								this.actionSpeakerAnim.repetitions = 1;
								this.actionSpeakerAnim.clampWhenFinished = false;
								this.actionSpeakerAnim.play();

								this.currentClips[1] = this.actionMicrophone25CM;
								this.actionMicrophone25CM.reset();
								this.actionMicrophone25CM.repetitions = 1;
								this.actionMicrophone25CM.clampWhenFinished = false;
								this.actionMicrophone25CM.play();
								this.actionMicrophone25CM.paused = true;
								break;
						}
					});
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started');
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place');
					this.el.sceneEl?.removeChild(ring);
				}
				const scene = document.querySelector('a-scene');
				scene?.emit('slider-visible');
				if (this.interuptMessage) {
					this.interuptMessage.swapImage(0);
					this.interuptMessage.setPosition('-4.5 3 -0.5');
					this.interuptMessage.setScale('40 40 40')
					this.interuptMessage.activatePermanent();
				}
			})

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: SceneHolder; offset: 0 0 0; scale: 0.6 0.6 0.6');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}
			});


			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			});

			const textureDictionary: { [key: string]: THREE.Texture } = {};
			const aoMapSwitcher = () => {
				if (textureDictionary[this.sliderValue]) {
					this.material.aoMap = textureDictionary[this.sliderValue];
					this.material.needsUpdate = true;
				} else if (this.sliderValue >= 5) {
					const imgEl = document.getElementById(`${this.sliderValue}`) as HTMLImageElement;
					console.log('imgEl', imgEl)
					const text = new THREE.Texture();
					text.image = imgEl;
					text.flipY = false;
					text.needsUpdate = true;
					textureDictionary[this.sliderValue] = text;
					this.material.aoMap = text;
					this.material.needsUpdate = true;
				}
			};

			const initialiseAnimations = () => {
				const animatedModelOneProcess = this.groupElement?.object3D.getObjectByName('Physics49_SpeedOfSound') as any;

				this.mixer = new THREE.AnimationMixer(animatedModelOneProcess);

				const showMicrophone0CM = animatedModelOneProcess?.animations[0];
				const showMicrophone10CM = animatedModelOneProcess?.animations[1];
				const showMicrophone15CM = animatedModelOneProcess?.animations[2];
				const showMicrophone20CM = animatedModelOneProcess?.animations[3];
				const showMicrophone25CM = animatedModelOneProcess?.animations[4];
				const showMicrophone5CM = animatedModelOneProcess?.animations[5];
				const showSpeakerAnim = animatedModelOneProcess?.animations[6];


				this.actionMicrophone0CM = this.mixer.clipAction(showMicrophone0CM);
				this.actionMicrophone5CM = this.mixer.clipAction(showMicrophone5CM);
				this.actionMicrophone10CM = this.mixer.clipAction(showMicrophone10CM);
				this.actionMicrophone15CM = this.mixer.clipAction(showMicrophone15CM);
				this.actionMicrophone20CM = this.mixer.clipAction(showMicrophone20CM);
				this.actionMicrophone25CM = this.mixer.clipAction(showMicrophone25CM);
				this.actionSpeakerAnim = this.mixer.clipAction(showSpeakerAnim);
			};

			this.oscilloscopeButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Oscilloscope';
					const body = 'An oscilloscope displays the electrical signals created by the microphones due to the loudspeaker. The signals (one from each microphone) are displayed as transverse waves but remember, sound is a longitudinal wave.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.rulerButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Ruler';
					const body = 'The ruler is used to measure the separation of the microphones. When the two signals on the oscilloscope line up, the microphones are separated by exactly one wavelength.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.loudspeakerButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Loudspeaker';
					const body = 'The loudspeaker is playing a single tone from a frequency generator. The microphones convert these longitudinal sound waves into electrical signals which are displayed on the oscilloscope.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.openedBookButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = '<span class="smaller-title-annotation">Calculating the speed of sound</span>';
					const body = 'Using the wavelength <em>‘λ’</em> of 0.25 m, and the frequency <em>‘f’</em> of 1320 Hz, we can calculate the wave speed <em>‘v’</em> using the equation: <em>v = f ✕ λ.</em> This gives a value of <em>330 m/s</em>, the speed of sound!';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.oscilloscopeTriggerBtn = this.poolButtons.requestEntity();
				this.oscilloscopeTriggerBtn?.object3D.position.set(0, 1.6, 0);
				this.oscilloscopeTriggerBtn?.object3D.scale.set(1.8, 1.8, 1.8);
				this.oscilloscopeTriggerBtn?.play();
				this.oscilloscopeTriggerBtn?.addEventListener('click', () => {
					this.oscilloscopeButtonHandler();
					if (this.oscilloscopeTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.oscilloscopeTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.oscilloscopeTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.oscilloscopeTriggerBtn;
					}
				});

				this.rulerTriggerBtn = this.poolButtons.requestEntity();
				this.rulerTriggerBtn?.object3D.position.set(4.8, 0.2, 4.5);
				this.rulerTriggerBtn?.object3D.scale.set(1.6, 1.6, 1.6);
				this.rulerTriggerBtn?.play();
				this.rulerTriggerBtn?.addEventListener('click', () => {
					this.rulerButtonHandler();
					if (this.rulerTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.rulerTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.rulerTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.rulerTriggerBtn;
					}
				});

				this.loudspeakerTriggerBtn = this.poolButtons.requestEntity();
				this.loudspeakerTriggerBtn?.object3D.position.set(-4.5, 1, 4.5);
				this.loudspeakerTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.loudspeakerTriggerBtn?.play();

				this.loudspeakerTriggerBtn?.addEventListener('click', () => {
					this.loudspeakerButtonHandler();
					if (this.loudspeakerTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.loudspeakerTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.loudspeakerTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.loudspeakerTriggerBtn;
					}
				});

				this.openedBookTriggerBtn = this.poolButtons.requestEntity();
				this.openedBookTriggerBtn?.object3D.position.set(11.85, 0.4, 5);
				this.openedBookTriggerBtn?.object3D.scale.set(2, 2, 2);
				this.openedBookTriggerBtn?.play();

				this.openedBookTriggerBtn?.addEventListener('click', () => {
					this.openedBookButtonHandler();
					if (this.openedBookTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.openedBookTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.openedBookTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.openedBookTriggerBtn;
					}
				});
			}
		},
		tick(this: ISpeedOfSoundSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { SpeedOfSoundScene as SpeedOfSoundComponent }