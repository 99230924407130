import React, {useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';

import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import {AtomsSceneComponent} from './protons-scene';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';

import {TapPlaceAir} from 'lib/aframe/components/tap-place-air';
import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
    MODEL3D_HINT,
} from 'shared/constants/hint-items';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
    'Use the slider to investigate the electrostatic forces and strong nuclear forces that occur at different distances inside the nucleus.';

const hintItems = [
    ANNOTATION_HINT,
    QUIZ_HINT,
    INFO_HINT,
    MODEL3D_HINT,
];

const PhysicsNuclearForcesAframe = () => {
    const [sliderLoaded, ] = useState({
        title: 'Separation/ fm',
        range: [0, 3.5],
        step: 0.1,
        firstValue: 3.5,
        segments:[[0, 0.4],[0.5, 0.9],[1.0, 1.9],[2.0, 2.9],[3.0, 3.5]],
        content: [
            'Below 0.5 fm, the strong force flips and also becomes <b>repulsive</b>. Thankfully this means that all the nuclei in the universe don\'t implode! ',
            'The electrostatic repulsion gets very large at small distances due to its <b>inverse square law</b>. Even with the attractive strong force, the net force is repulsive.',
            'The strong force is very attractive at short distances and overpowers the electrostatic repulsion. This is the scale that nuclei can be stable, where the strong force can hold positive charges together.',
            'As the separation is reduced, both the electrostatic repulsion and strong nuclear attraction (green vector) increase.',
            'At separations greater than 3.0 fm, the attractive strong force between the protons is negligible. The electrostatic force (orange vectors) between two like charges has an infinite range and is repulsive.']
    });
    const [lessonStarted, setLessonStarted] = useState(false);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
    const onLessonStarted = () => {
        setLessonStarted(true);
    };
    const onLessonRecenter = () => {
        setLessonStarted(false);
    };
    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };
    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                sliderLoad={sliderLoaded}
                introductionText={introText}
                lessonStarted={lessonStarted}
                hintItems={hintItems}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./physics-nuclear-forces-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    TapPlaceAir,
                    FaceCameraComponent,
                    AtomsSceneComponent,
                ]}
                onLessonRecenter={onLessonRecenter}
                onLessonStart={onLessonStarted}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>
    );
};
export {PhysicsNuclearForcesAframe};
