import { IconImage } from '../../../shared/components/IconImage';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { DisplayEnum } from '../../../shared/enums';
import { ContactUsForm } from '../../../shared/components/ContactUsForm';
import { useContactUsValidationSchema } from '../../../shared/hooks/useContactUsValidationSchema';
import { useHandlers } from './hooks';

import joinCommunity from '../../../assets/joinCommunity.png';

const initialValues = { email: '', name: '', organization: '' };
type Props = {
  onMainPage: boolean;
};

export function JoinCommunity() {
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { handleSubmit } = useHandlers();
  const validationSchema = useContactUsValidationSchema();

  useEffect(() => {
    const { hash } = window.location;
    const form = document.getElementById(hash.replace('#', ''));
    const to = setTimeout(() => {
      clearTimeout(to);
      form?.scrollIntoView();
    }, 1000);
  }, []);

  return (
    <Stack textAlign='center'>
      <Box mt={tablet ? -3 : -5}>
        <IconImage src={joinCommunity} alt='Oculus' width='100%' />
      </Box>
      <Stack
        alignItems='center'
        mt={xlDisplay ? -25 : mobile ? -3 : -9}
        id='joinUs'
        width='100%'
        maxWidth={tablet ? '100%' : 1180}
        mx='auto'
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <ContactUsForm onMainPage={true} />
        </Formik>
      </Stack>
    </Stack>
  );
}
