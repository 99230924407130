import React, { useCallback, useEffect, useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import '../styles/drag-and-drop-question.css';

interface QuizQuestion {
  question: string;
  answers: {
    answer: string;
    result: boolean | string;
  }[];
  type?: string;
}

interface DragAndDropQuestionProps {
  data: QuizQuestion;
  emitCorrectOrder: () => void;
}

interface ItemProps {
  value: string;
}

interface ListProps {
  items: string[];
}

const DragAndDropQuestion: React.FC<DragAndDropQuestionProps> = ({
  data,
  emitCorrectOrder,
}) => {
  const [answers, setAnswers] = useState(data.answers.map((a) => a.answer));
  const [correct, setCorrect] = useState(false);
  const [correctOrder, setCorrectOrder] = useState(
    data.answers.map((_, i) => i + 1).toString()
  );

  const updateAnswers = useCallback(() => {
    setAnswers(data.answers.map((a) => a.answer));
  }, [data.answers]);

  const updateCorrect = useCallback(() => {
    setCorrect(false);
  }, []);

  const updateCorrectOrder = useCallback(() => {
    setCorrectOrder(data.answers.map((_, i) => i + 1).toString());
  }, [data.answers]);

  useEffect(() => {
    updateAnswers();
    updateCorrect();
    updateCorrectOrder();
  }, [data, updateAnswers, updateCorrect, updateCorrectOrder]);

  const checkAnswers = (currentAnswersArray: string[]): boolean => {
    const mapResultArray = currentAnswersArray
      .map((i) => data.answers.find((a) => a.answer === i)?.result)
      .toString();
    return mapResultArray === correctOrder;
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    setAnswers(arrayMove(answers, oldIndex, newIndex));

    if (checkAnswers(arrayMove(answers, oldIndex, newIndex))) {
      setCorrect(true);
      emitCorrectOrder();
    }
  };

  const SortableItem = SortableElement<ItemProps>(
    ({ value }: { value: string }) => <li className={`answer-card`}>{value}</li>
  );

  const SortableList = SortableContainer<ListProps>(
    ({ items }: { items: string[] }) => {
      return (
        <ul className={`answers-list ${correct ? 'correct' : ''}`}>
          {items.map((value, index) => (
            <SortableItem key={`item-${value}`} index={index} value={value} />
          ))}
        </ul>
      );
    }
  );

  return (
    <SortableList
      items={answers}
      onSortEnd={onSortEnd}
      lockAxis='y'
      lockToContainerEdges={true}
      lockOffset={['0%', '-16px']}
      helperClass='draggable-item'
    />
  );
};

export default DragAndDropQuestion;
