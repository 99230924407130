import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import './styles/photosynthesis-limiting-factors-style.css';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IPhotosynthesisSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;

	actionShowTemperatureGraph: THREE.AnimationAction;
	actionShowCO2Graph: THREE.AnimationAction;
	actionShowLightIntensityGraph: THREE.AnimationAction;
	actionMolecules: THREE.AnimationAction;

	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;

	currentAssetId: number;

	groupElement: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	sceneLight: AFrame.Entity;
	el: AFrame.Entity;

	temperatureTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	temperatureGraphTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	carbonDioxideTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	carbonDioxideGraphTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	lightIntensityTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	lightIntensityGraphTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	chloroplastsTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;

	leavesGreen: THREE.Object3D | undefined;
	leavesYellow: THREE.Object3D | undefined;
	leavesOrange: THREE.Object3D | undefined;

	temperatureButtonHandler: () => void;
	temperatureGraphButtonHandler: () => void;
	carbonDioxideButtonHandler: () => void;
	carbonDioxideGraphButtonHandler: () => void;
	lightIntensityGraphButtonHandler: () => void;
	lightIntensityButtonHandler: () => void;
	chloroplastsButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;

	sliderValue: number;

	graphButtonTemperature: HTMLElement | null;
	graphButtonCO2: HTMLElement | null;
	graphButtonLight: HTMLElement | null;
}

const PhotosynthesisLimitingFactorsScene = {
	name: 'photosynthesis-limiting-factors',
	val: {
		init(this: IPhotosynthesisSceneAframe) {
			var isInitialised = false;

			this.el.addEventListener('model-loaded', () => {
				this.groupElement = document.getElementById('PhotosynthesisLimitingFactors') as AFrame.Entity;
				this.groupElement.object3D.rotation.set(0, 210, 0);
				this.sceneLight = document.getElementById('sceneLight') as AFrame.Entity;
				// this.groupElement?.object3D.traverse((child) => {
				// 	console.log(child);
				// });

				this.leavesGreen = this.groupElement?.object3D.getObjectByName('Leaves_Green');
				this.leavesYellow = this.groupElement?.object3D.getObjectByName('Leaves_Yellow');
				this.leavesOrange = this.groupElement?.object3D.getObjectByName('Leaves_Orange');

				if (this.leavesGreen) {
					this.leavesGreen.visible = true;
				}
				if (this.leavesYellow) {
					this.leavesYellow.visible = false;
				}
				if (this.leavesOrange) {
					this.leavesOrange.visible = false;
				}

				if (!isInitialised) {
					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					moleculeAnimationStart();
					isInitialised = true
				}
				else return
			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			this.graphButtonTemperature = document.getElementById('graph-button-1');
			this.graphButtonCO2 = document.getElementById('graph-button-2');
			this.graphButtonLight = document.getElementById('graph-button-3');

			if (this.graphButtonTemperature) {
				this.graphButtonTemperature.setAttribute("visible", "true");
			}
			if (this.graphButtonCO2) {
				this.graphButtonCO2.setAttribute("visible", "false");
			}
			if (this.graphButtonLight) {
				this.graphButtonLight.setAttribute("visible", "false");
			}

			this.currentAssetId = 0;
			this.el.sceneEl?.addEventListener('asset-change', (event) => {
				const customEvent = event as CustomEvent; // Cast event to CustomEvent
				const newAssetId = customEvent.detail.assetId;
				this.currentAssetId = newAssetId;
				if (newAssetId === 0) {
					if (this.graphButtonTemperature) {
						this.graphButtonTemperature.setAttribute("visible", "true");
					}
					if (this.graphButtonCO2) {
						this.graphButtonCO2.setAttribute("visible", "false");
					}
					if (this.graphButtonLight) {
						this.graphButtonLight.setAttribute("visible", "false");
					}
					this.temperatureTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5);
					this.carbonDioxideTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.lightIntensityTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.chloroplastsTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.currentAssetId = newAssetId;
				}
				if (newAssetId === 1) {
					if (this.graphButtonTemperature) {
						this.graphButtonTemperature.setAttribute("visible", "false");
					}
					if (this.graphButtonCO2) {
						this.graphButtonCO2.setAttribute("visible", "true");
					}
					if (this.graphButtonLight) {
						this.graphButtonLight.setAttribute("visible", "false");
					}
					this.temperatureTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.carbonDioxideTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5);
					this.lightIntensityTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.chloroplastsTriggerBtn?.object3D.scale.set(0, 0, 0);
				}
				if (newAssetId === 2) {
					this.actionMolecules.timeScale = 0.25;
					if (this.graphButtonTemperature) {
						this.graphButtonTemperature.setAttribute("visible", "false");
					}
					if (this.graphButtonCO2) {
						this.graphButtonCO2.setAttribute("visible", "false");
					}
					if (this.graphButtonLight) {
						this.graphButtonLight.setAttribute("visible", "true");
					}

					this.temperatureTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.carbonDioxideTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.lightIntensityTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5);
					this.chloroplastsTriggerBtn?.object3D.scale.set(0, 0, 0);
				}
				if (newAssetId === 3) {
					this.sceneLight.setAttribute("intensity", "0.5");
					this.actionMolecules.timeScale = 0.25;
					if (this.leavesGreen) {
						this.leavesGreen.visible = false;
					}
					if (this.leavesYellow) {
						this.leavesYellow.visible = false;
					}
					if (this.leavesOrange) {
						this.leavesOrange.visible = true;
					}
					if (this.graphButtonTemperature) {
						this.graphButtonTemperature.setAttribute("visible", "false");
					}
					if (this.graphButtonCO2) {
						this.graphButtonCO2.setAttribute("visible", "false");
					}
					if (this.graphButtonLight) {
						this.graphButtonLight.setAttribute("visible", "false");
					}

					this.temperatureTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.carbonDioxideTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.lightIntensityTriggerBtn?.object3D.scale.set(0, 0, 0);
					this.chloroplastsTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5);
				}
			});

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: PhotosynthesisLimitingFactorsHolder; scale: 3 3 3; offset: -1 0 -1');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}

			});

			const moleculeAnimationStart = () => {
				// this.currentClip = this.actionMolecules;
				this.actionMolecules.reset();
				this.actionMolecules.play();
				this.el.sceneEl?.addEventListener('slider-move', (e) => {
					const ce = e as CustomEvent;

					this.sliderValue = ce.detail.value;

					if (this.currentAssetId === 0) {
						if (this.sliderValue < 12) {
							this.actionMolecules.timeScale = 0.25;
							this.sceneLight.setAttribute("intensity", "0.5");
							if (this.leavesGreen) {
								this.leavesGreen.visible = true;
							}
							if (this.leavesYellow) {
								this.leavesYellow.visible = false;
							}
							if (this.leavesOrange) {
								this.leavesOrange.visible = false;
							}
						} else {
							if (this.sliderValue < 25) {
								this.actionMolecules.timeScale = 0.5;
							} else {
								if (this.sliderValue < 33) {
									this.actionMolecules.timeScale = 0.75;
								} else {
									if (this.sliderValue < 38) {
										this.actionMolecules.timeScale = 1;
									} else {
										if (this.sliderValue < 45) {
											this.actionMolecules.timeScale = 0.5;
										} else {
											if (this.sliderValue < 48) {
												this.actionMolecules.timeScale = 0.25;
											} else {
												if (this.sliderValue < 50) {
													this.actionMolecules.timeScale = 0.0001;
												}
											}
										}
									}
								}
							}
						}
					}
					if (this.currentAssetId === 1) {
						if (this.sliderValue < 0.25) {
							this.actionMolecules.timeScale = 0.15;
							this.sceneLight.setAttribute("intensity", "0.5");
							if (this.leavesGreen) {
								this.leavesGreen.visible = true;
							}
							if (this.leavesYellow) {
								this.leavesYellow.visible = false;
							}
							if (this.leavesOrange) {
								this.leavesOrange.visible = false;
							}
						} else {
							if (this.sliderValue < 0.35) {
								this.actionMolecules.timeScale = 0.25;
							} else {
								if (this.sliderValue < 0.45) {
									this.actionMolecules.timeScale = 0.5;
								} else {
									if (this.sliderValue < 0.55) {
										this.actionMolecules.timeScale = 0.75;
									} else {
										if (this.sliderValue < 0.65) {
											this.actionMolecules.timeScale = 0.85;
										} else {
											if (this.sliderValue < 0.75) {
												this.actionMolecules.timeScale = 0.95;
											} else {
												if (this.sliderValue < 0.85) {
													this.actionMolecules.timeScale = 1.05;
												} else {
													if (this.sliderValue < 1) {
														this.actionMolecules.timeScale = 1.15;
													}
												}
											}
										}
									}
								}
							}
						}
					}
					if (this.currentAssetId === 2) {
						if (this.sliderValue <= 10) {
							this.actionMolecules.timeScale = 0.25;
							this.sceneLight.setAttribute("intensity", "0.1");
							if (this.leavesGreen) {
								this.leavesGreen.visible = true;
							}
							if (this.leavesYellow) {
								this.leavesYellow.visible = false;
							}
							if (this.leavesOrange) {
								this.leavesOrange.visible = false;
							}
						} else {
							if (this.sliderValue <= 20) {
								this.actionMolecules.timeScale = 0.5;
								this.sceneLight.setAttribute("intensity", "0.25");
							} else {
								if (this.sliderValue >= 30) {
									this.actionMolecules.timeScale = 0.75;
									this.sceneLight.setAttribute("intensity", "0.5");
								}
							}
						}
					}
					if (this.currentAssetId === 3) {
						if (this.sliderValue <= 200) {
							this.sceneLight.setAttribute("intensity", "0.5");
							this.actionMolecules.timeScale = 0.25;
							if (this.leavesGreen) {
								this.leavesGreen.visible = false;
							}
							if (this.leavesYellow) {
								this.leavesYellow.visible = false;
							}
							if (this.leavesOrange) {
								this.leavesOrange.visible = true;
							}
						} else {
							if (this.sliderValue <= 300) {
								this.actionMolecules.timeScale = 0.5;
								if (this.leavesGreen) {
									this.leavesGreen.visible = false;
								}
								if (this.leavesYellow) {
									this.leavesYellow.visible = true;
								}
								if (this.leavesOrange) {
									this.leavesOrange.visible = false;
								}
							} else {
								if (this.sliderValue >= 400) {
									this.actionMolecules.timeScale = 0.75;
									if (this.leavesGreen) {
										this.leavesGreen.visible = true;
									}
									if (this.leavesYellow) {
										this.leavesYellow.visible = false;
									}
									if (this.leavesOrange) {
										this.leavesOrange.visible = false;
									}
								}
							}
						}
					}
				});
			}


			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
				moleculeAnimationStart();
			})

			const initialiseAnimations = () => {
				const animatedProcess = this.groupElement?.object3D.getObjectByName('Biology8_PhotosynthesisLimitingFactors_V3') as any;

				this.mixer = new THREE.AnimationMixer(animatedProcess);

				const showTemperatureGraphAnimation = animatedProcess?.animations[0];
				const showCO2GraphAnimation = animatedProcess?.animations[1];
				const showLightIntensityGraphAnimation = animatedProcess?.animations[2];
				const MoleculesAnimation = animatedProcess?.animations[3];


				this.actionShowTemperatureGraph = this.mixer.clipAction(showTemperatureGraphAnimation);
				this.actionShowCO2Graph = this.mixer.clipAction(showCO2GraphAnimation);
				this.actionShowLightIntensityGraph = this.mixer.clipAction(showLightIntensityGraphAnimation);
				this.actionMolecules = this.mixer.clipAction(MoleculesAnimation);
			};

			this.temperatureButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Temperature';
					const body = 'Temperature is a limiting factor that affects the rate of photosynthesis. At optimum temperatures a plant’s enzymes work most efficiently. Colder temperatures slow down enzymes, while really high temperatures denature the enzymes and stop photosynthesis.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.temperatureGraphButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionShowTemperatureGraph;
				this.actionShowTemperatureGraph.reset();
				this.actionShowTemperatureGraph.repetitions = 1;
				this.actionShowTemperatureGraph.clampWhenFinished = true;
				this.actionShowTemperatureGraph.play();
				if (this.onObjectSelected) {
					const title = 'Temperature Graph';
					const body = 'At 0℃ enzymes slow down or stop working. As the temperature increases the photosynthesis rate increases. The optimum temperature is 35℃. As the temperature increases even further, the photosynthesis rate decreases until it stops due to enzymes denaturing.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.carbonDioxideButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Carbon Dioxide';
					const body = 'Carbon dioxide is one of the limiting factors that affect the rate of photosynthesis. Carbon dioxide is a reactant needed for photosynthesis. CO2 gas enters leaves through the stoma, which opens with the help of guard cells, to allow gasses to diffuse.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.carbonDioxideGraphButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionShowCO2Graph;
				this.actionShowCO2Graph.reset();
				this.actionShowCO2Graph.repetitions = 1;
				this.actionShowCO2Graph.clampWhenFinished = true;
				this.actionShowCO2Graph.play();

				if (this.onObjectSelected) {
					const title = 'Carbon Dioxide Graph';
					const body = 'Without CO<span class="subscript">2</span> in the atmosphere, photosynthesis cannot occur. As the CO<span class="subscript">2</span> concentration increases in the atmosphere the rate of photosynthesis increases until CO<span class="subscript">2</span> is not the limiting factor. At 0.4% the graph plateaus at the maximum rate of photosynthesis.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.lightIntensityButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Light Intensity';
					const body = 'Light intensity is a photosynthesis limiting factor. Light energy is absorbed by pigments in the chloroplasts, called chlorophyll. Photosynthesis is an endothermic reaction because energy is transferred from the environment to the chloroplasts by light.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.lightIntensityGraphButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionShowLightIntensityGraph;
				this.actionShowLightIntensityGraph.reset();
				this.actionShowLightIntensityGraph.repetitions = 1;
				this.actionShowLightIntensityGraph.clampWhenFinished = true;
				this.actionShowLightIntensityGraph.play();
				if (this.onObjectSelected) {
					const title = 'Light Intensity Graph';
					const body = 'If there is insufficient light, there is not enough energy to power photosynthesis. If the light intensity becomes too high, the rate of photosynthesis will no longer increase; other factors may become the limiting factors.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.chloroplastsButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Chloroplasts';
					const body = 'Chlorophyll, the green pigment found in chloroplasts, absorbs light energy from the environment, making it essential for the photosynthesis process. Observe the change in photosynthesis rate as chlorophyll levels fluctuate.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.temperatureTriggerBtn = poolButtons.requestEntity();
				this.temperatureTriggerBtn?.object3D.position.set(0.04, 2.15, 0.3);
				this.temperatureTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5);
				this.temperatureTriggerBtn?.play();
				this.temperatureTriggerBtn?.addEventListener('click', () => {
					this.temperatureButtonHandler();
					if (this.temperatureTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.temperatureTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.temperatureTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.temperatureTriggerBtn;
					}
				});

				this.temperatureGraphTriggerBtn = poolButtons.requestEntity();
				this.temperatureGraphTriggerBtn?.object3D.position.set(1, 2, 0.3);
				this.temperatureGraphTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5);
				if (this.temperatureGraphTriggerBtn) {
					this.temperatureGraphTriggerBtn.object3D.visible = false;
				}


				this.temperatureGraphTriggerBtn?.addEventListener('click', () => {
					this.temperatureGraphButtonHandler();
					if (this.temperatureGraphTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.temperatureGraphTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.temperatureGraphTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.temperatureGraphTriggerBtn;
					}
				});


				this.carbonDioxideTriggerBtn = poolButtons.requestEntity();
				this.carbonDioxideTriggerBtn?.object3D.position.set(0.52, 1.72, 0.2);
				this.carbonDioxideTriggerBtn?.object3D.scale.set(0, 0, 0);
				this.carbonDioxideTriggerBtn?.play();
				this.carbonDioxideTriggerBtn?.addEventListener('click', () => {
					this.carbonDioxideButtonHandler();
					if (this.carbonDioxideTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.carbonDioxideTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.carbonDioxideTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.carbonDioxideTriggerBtn;
					}
				});

				this.carbonDioxideGraphTriggerBtn = poolButtons.requestEntity();
				this.carbonDioxideGraphTriggerBtn?.object3D.position.set(1, 1.4, 0.3);
				this.carbonDioxideGraphTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5);

				if (this.carbonDioxideGraphTriggerBtn) {
					this.carbonDioxideGraphTriggerBtn.object3D.visible = false;
				}

				this.carbonDioxideGraphTriggerBtn?.addEventListener('click', () => {
					this.carbonDioxideGraphButtonHandler();
					if (this.carbonDioxideGraphTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.carbonDioxideGraphTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.carbonDioxideGraphTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.carbonDioxideGraphTriggerBtn;
					}
				});

				this.lightIntensityTriggerBtn = poolButtons.requestEntity();
				this.lightIntensityTriggerBtn?.object3D.position.set(-0.4, 1.08, 0.5);
				this.lightIntensityTriggerBtn?.object3D.scale.set(0, 0, 0);
				this.lightIntensityTriggerBtn?.play();
				this.lightIntensityTriggerBtn?.addEventListener('click', () => {
					this.lightIntensityButtonHandler();
					if (this.lightIntensityTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.lightIntensityTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.lightIntensityTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.lightIntensityTriggerBtn
					}
				});

				this.lightIntensityGraphTriggerBtn = poolButtons.requestEntity();
				this.lightIntensityGraphTriggerBtn?.object3D.position.set(1, 1, 0.3);
				this.lightIntensityGraphTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5);

				if (this.lightIntensityGraphTriggerBtn) {
					this.lightIntensityGraphTriggerBtn.object3D.visible = false;
				}
				this.lightIntensityGraphTriggerBtn?.addEventListener('click', () => {
					this.lightIntensityGraphButtonHandler();
					if (this.lightIntensityGraphTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.lightIntensityGraphTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.lightIntensityGraphTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.lightIntensityGraphTriggerBtn;
					}
				});

				this.chloroplastsTriggerBtn = poolButtons.requestEntity();
				this.chloroplastsTriggerBtn?.object3D.position.set(0.58, 1.21, 0.4);
				this.chloroplastsTriggerBtn?.object3D.scale.set(0, 0, 0);
				this.chloroplastsTriggerBtn?.play();
				this.chloroplastsTriggerBtn?.addEventListener('click', () => {
					this.chloroplastsButtonHandler();
					if (this.chloroplastsTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.chloroplastsTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.chloroplastsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.chloroplastsTriggerBtn;
					}
				});


			}
		},
		tick(this: IPhotosynthesisSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}

		},
	},
};
export { PhotosynthesisLimitingFactorsScene as PhotosynthesisLimitingFactorsComponent }