// src/components/TrackingStatusPanel.tsx
import React from 'react';
import '../styles/distancePanel.css';



const DistancePanel: React.FC = () => {


  return (
    <div className="distancePanel">
      <p className="distancePanelTitle">Distance status alert</p>
      <p className="distancePanelContent">
        Tracking quality is low. <br /> Please try not to bring the camera so close to the subject.
      </p>
    </div>
  );
};

export default DistancePanel;
