import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface ITemperatureChangesSceneAframe {
    cube1: AFrame.Entity;
    tempText: AFrame.Entity;
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    morph: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>;
    dropAnimation: THREE.AnimationAction;
    capAnimation: THREE.AnimationAction;
    thermometerAnimation: THREE.AnimationAction;
    pourAnimation: THREE.AnimationAction;
    dropMixer: THREE.AnimationMixer;
    capMixer: THREE.AnimationMixer;
    thermometerMixer: THREE.AnimationMixer;
    pourMixer: THREE.AnimationMixer;
    hydrochloricAcidHandler: () => void;
    sodiumHydroxideHandler: () => void;
    afterIntroAnnHandler: () => void;
    onAddAcidHandler: () => void;
    onAddNaOHHandler: () => void;
    regularAddNaOHHandler: () => void;
    finalAnnHandler: () => void;
    graph1TriggerButton: AFrame.Entity;
    graph1Handler: () => void;
    graph2TriggerButton: AFrame.Entity;
    graph2Handler: () => void;
    graph3TriggerButton: AFrame.Entity;
    graph3Handler: () => void;
    graph4TriggerButton: AFrame.Entity;
    graph4Handler: () => void;
    graph5TriggerButton: AFrame.Entity;
    graph5Handler: () => void;
    graph6TriggerButton: AFrame.Entity;
    graph6Handler: () => void;
    graph7TriggerButton: AFrame.Entity;
    graph7Handler: () => void;
    graph8TriggerButton: AFrame.Entity;
    graph8Handler: () => void;
    graph9TriggerButton: AFrame.Entity;
    graph9Handler: () => void;
    graphFullTriggerButton: AFrame.Entity;
    graphFullHandler: () => void;
    onObjectSelected: ((selectedObject: { title: string; body: string; imageExtra?: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    currentClick: number;
    prevGraphButton: AFrame.Entity;
    mainTimeout: NodeJS.Timeout;
    canClick: boolean;
}

const LessonStart = {
    name: 'lesson-scene',
    val: {
        init(this: ITemperatureChangesSceneAframe) {
            const graphBtnUrl = require('../../../assets/img/GraphOption4.png');
            const model = document.getElementById('model') as AFrame.Entity;

            model.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                this.currentClick = 0

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;

                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                    this.morph = this.el.object3D.getObjectByName('Box002') as THREE.Mesh;
                    this.canClick = true
                    this.afterIntroAnnHandler()
                    initialiseAnimations()
                    setAmmeterDigits()
                    createClickableAreas()
                    this.annotationComponent.line.visible = false
                }
            })
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: model; scale: 25 25 25; offset: 0 0 -4');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }

            });
            const createClickableAreas = () => {
                this.cube1 = document.createElement('a-entity');
                this.cube1.setAttribute('geometry', {
                    primitive: 'box',
                    height: 0.12,
                    width: 0.08,
                    depth: 0.1
                });
                this.cube1.setAttribute('position', '0 0.055 0');
                this.cube1.setAttribute('visible', 'false');
                this.cube1.setAttribute('class', 'cantap');
                this.cube1.addEventListener('click', () => {
                    if (this.canClick) {
                        this.canClick = false
                        this.currentClick += 1
                        clearTimeout(this.mainTimeout)
                        this.annotationComponent.line.visible = false
                        switch (this.currentClick) {
                            case 1 :
                                playAnimation(this.pourAnimation, '22.3');
                                setTimeout(() => {
                                    morphTubeUp(0);
                                    setTimeout(() => {
                                        morphTubeUp(1)
                                        setTimeout(() => {
                                            morphTubeDown(1)
                                            morphTubeUp(2)
                                            this.graph1TriggerButton?.setAttribute('scale', '0.2 0.2 0.2');
                                            this.prevGraphButton = this.graph1TriggerButton
                                        }, 500)
                                    }, 1000)
                                }, 8000)
                                this.onAddAcidHandler()
                                break;
                            case 2 :
                                this.capAnimation.play()
                                playAnimation(this.dropAnimation, '25.6');
                                this.prevGraphButton?.setAttribute('scale', '0 0 0')
                                this.graph2TriggerButton?.setAttribute('scale', '0.2 0.2 0.2');
                                this.prevGraphButton = this.graph2TriggerButton
                                this.onAddNaOHHandler()
                                break;
                            case 3 :
                                this.capAnimation.play()
                                playAnimation(this.dropAnimation, '27.8');
                                this.prevGraphButton?.setAttribute('scale', '0 0 0')
                                this.graph3TriggerButton?.setAttribute('scale', '0.2 0.2 0.2');
                                this.prevGraphButton = this.graph3TriggerButton
                                this.regularAddNaOHHandler()
                                break;
                            case 4 :
                                this.capAnimation.play()
                                playAnimation(this.dropAnimation, '29.9');
                                this.prevGraphButton?.setAttribute('scale', '0 0 0')
                                this.graph4TriggerButton?.setAttribute('scale', '0.2 0.2 0.2');
                                this.prevGraphButton = this.graph4TriggerButton
                                this.regularAddNaOHHandler()
                                break;
                            case 5 :
                                this.capAnimation.play()
                                playAnimation(this.dropAnimation, '31.2');
                                this.prevGraphButton?.setAttribute('scale', '0 0 0')
                                this.graph5TriggerButton?.setAttribute('scale', '0.2 0.2 0.2');
                                this.prevGraphButton = this.graph5TriggerButton
                                this.regularAddNaOHHandler()
                                break;
                            case 6 :
                                this.capAnimation.play()
                                playAnimation(this.dropAnimation, '32.5');
                                this.prevGraphButton?.setAttribute('scale', '0 0 0')
                                this.graph6TriggerButton?.setAttribute('scale', '0.2 0.2 0.2');
                                this.prevGraphButton = this.graph6TriggerButton
                                this.regularAddNaOHHandler()
                                break;
                            case 7 :
                                this.capAnimation.play()
                                playAnimation(this.dropAnimation, '32.9');
                                this.prevGraphButton?.setAttribute('scale', '0 0 0')
                                this.graph7TriggerButton?.setAttribute('scale', '0.2 0.2 0.2');
                                this.prevGraphButton = this.graph7TriggerButton
                                this.regularAddNaOHHandler()
                                break;
                            case 8 :
                                this.capAnimation.play()
                                playAnimation(this.dropAnimation, '32.4');
                                this.prevGraphButton?.setAttribute('scale', '0 0 0')
                                this.graph8TriggerButton?.setAttribute('scale', '0.2 0.2 0.2');
                                this.prevGraphButton = this.graph8TriggerButton
                                this.regularAddNaOHHandler()
                                break;
                            case 9 :
                                this.capAnimation.play()
                                playAnimation(this.dropAnimation, '31.8');
                                this.prevGraphButton?.setAttribute('scale', '0 0 0')
                                this.graphFullTriggerButton?.setAttribute('scale', '0.2 0.2 0.2');
                                this.prevGraphButton = this.graphFullTriggerButton
                                this.finalAnnHandler()
                                break;
                        }
                    }
                });
                this.el.appendChild(this.cube1);
            }


            const playAnimation = (animation2: THREE.AnimationAction, tempValue: string) => {
                updateTempDynamicValues('')
                const termDuration = this.thermometerAnimation.getClip().duration
                this.thermometerAnimation.stop()
                this.thermometerAnimation.setDuration(termDuration)
                this.thermometerAnimation.play()
                this.mainTimeout = setTimeout(() => {
                    // Calculate the duration of the first animation
                    const firstAnimationDuration = this.capAnimation.getClip().duration;
                    const firstAnimationHalfDuration = firstAnimationDuration / 2;
                    this.thermometerAnimation.paused = true
                    this.capAnimation.stop()
                    animation2.stop()
                    // Play the first animation
                    this.capAnimation.setDuration(firstAnimationDuration)
                    this.capAnimation.play();
                    // Pause the first animation after the first half
                    setTimeout(() => {
                        this.capAnimation.paused = true;
                        // Play the second animation
                        animation2.play();
                        // Calculate the duration of the second animation
                        const secondAnimationDuration = animation2.getClip().duration;
                        // Resume the first animation after the second animation finishes
                        setTimeout(() => {
                            this.capAnimation.setDuration(firstAnimationDuration / 4)
                            this.capAnimation.paused = false;
                            setTimeout(() => {
                                this.thermometerAnimation.setDuration(termDuration / 4)
                                this.thermometerAnimation.paused = false
                                setTimeout(() => {
                                    updateTempDynamicValues(tempValue)
                                    this.canClick = true
                                }, termDuration / 2 * 1000 / 2)
                            }, firstAnimationHalfDuration * 1000 - 4000)
                        }, secondAnimationDuration * 1000);
                    }, firstAnimationHalfDuration * 1000);
                }, termDuration / 2 * 1000 - 2000)
            }

            const setAmmeterDigits = () => {
                if (!this.tempText) {
                    const tempText = document.createElement('a-entity')
                    tempText.setAttribute('text', "value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;");
                    tempText.setAttribute('position', '0.46 0.42 0.015');
                    tempText.setAttribute('scale', '0.1 0.1 0.1');
                    tempText.setAttribute('rotation', '0 0 0');
                    tempText.setAttribute('visible', true);
                    this.el.appendChild(tempText);
                    this.tempText = tempText;
                }
            }
            const updateTempDynamicValues = (value: string) => {
                this.tempText.setAttribute('text', `value: ${value}; color: #8ad1a9; align: left; width: 10; wrap-count: 50;`);
                this.tempText.setAttribute('position', '0.46 0.42 0.015');
            }
            const morphTubeUp = (index: number) => {
                console.log('starting morphs: ', index);
                const morphTube = this.morph as THREE.Mesh;
                const startTime = performance.now();
                const morph = (timestamp: number) => {
                    const elapsed = timestamp - startTime;
                    let morphValue = elapsed / 400;
                    if (morphTube.morphTargetInfluences) {
                        morphValue = Math.min(Math.max(morphValue, 0), 1);
                        morphTube.morphTargetInfluences[index] = morphValue;
                    }
                    if (morphValue < 1) {
                        requestAnimationFrame(morph);
                    } else {
                        if (morphTube.morphTargetInfluences) {
                            morphTube.morphTargetInfluences[index] = 1;
                        }
                        return;
                    }
                }
                requestAnimationFrame(morph);
            };

            const morphTubeDown = (index: number) => {
                console.log('starting morphs down ');
                const morphTube = this.morph as THREE.Mesh;
                const startTime = performance.now();
                const morph = (timestamp: number) => {
                    const elapsed = timestamp - startTime;
                    let morphValue = 1 - elapsed / 400;
                    if (morphTube.morphTargetInfluences) {
                        morphValue = Math.min(Math.max(morphValue, 0), 1);
                        morphTube.morphTargetInfluences[index] = morphValue;
                    }
                    if (morphValue > 0) {
                        requestAnimationFrame(morph);
                    }
                }
                requestAnimationFrame(morph);
            };
            const initialiseAnimations = () => {
                const model = document.getElementById('model') as AFrame.Entity;
                const animatedEl = model.object3D.getObjectByName('Scene') as any;
                this.dropMixer = new THREE.AnimationMixer(animatedEl)
                this.capMixer = new THREE.AnimationMixer(animatedEl)
                this.pourMixer = new THREE.AnimationMixer(animatedEl)
                this.thermometerMixer = new THREE.AnimationMixer(animatedEl)
                const [, DropAction, , , Thermometer, CapAction, , , PourAnimation] = animatedEl.animations
                this.dropAnimation = this.dropMixer.clipAction(DropAction)
                this.dropAnimation.setLoop(THREE.LoopPingPong, 1)
                this.capAnimation = this.capMixer.clipAction(CapAction)
                this.capAnimation.setLoop(THREE.LoopPingPong, 1)
                this.thermometerAnimation = this.thermometerMixer.clipAction(Thermometer)
                this.thermometerAnimation.setLoop(THREE.LoopPingPong, 1)
                this.pourAnimation = this.pourMixer.clipAction(PourAnimation)
                this.pourAnimation.setLoop(THREE.LoopPingPong, 1)
            }

            this.hydrochloricAcidHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Hydrochloric Acid';
                    const body = 'In this experiment we will use 30 cm<sup>3</sup> of hydrochloric acid.'
                    this.onObjectSelected({title, body})
                    this.annotationComponent.line.visible = true

                } else {
                    console.log('No object selected method')
                }
            }
            this.sodiumHydroxideHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Sodium Hydroxide';
                    const body = 'In this experiment we will use sodium hydroxide as the alkali. 5 cm<sup>3</sup> will be added at a time until 40 cm<sup>3</sup> is reached.'
                    this.onObjectSelected({title, body})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.afterIntroAnnHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = 'In this experiment, analyse an exothermic reaction by recording the temperature every time 5 cm<sup>3</sup> of NaOH (alkali) is added to HCl (acid). Tap the polystyrene cup to complete each step of the experiment.<br/> There are nine steps in total!';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.onAddAcidHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = 'Hydrochloric acid (30 cm<sup>3</sup>) is added to the polystyrene beaker. It’s important to use polystyrene as it is a thermal insulator and reduces heat loss! Tap the graph <img src="'+graphBtnUrl+'" alt="icon" style="width: 16px"> to see the first recorded temperature.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.onAddNaOHHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = 'NaOH (5 cm<sup>3</sup>) is added to the polystyrene beaker - this is where the exothermic reaction starts! Tap the graph <img src="'+graphBtnUrl+'" alt="icon" style="width: 16px"> to see what has happened to the temperature of the solution.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.regularAddNaOHHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = 'NaOH (5 cm<sup>3</sup>) is added to the polystyrene beaker. Keep an eye on the graph <img src="'+graphBtnUrl+'" alt="icon" style="width: 16px"> to see how the temperature changes.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.finalAnnHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = 'The final 5 cm<sup>3</sup> of NaOH is added, so in total 40 cm<sup>3</sup> has been added to the beaker, Check the graph <img src="'+graphBtnUrl+'" alt="icon" style="width: 16px"> and then answer the quiz questions <b>Q</b> to analyse your results.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.graph1Handler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardPoint1.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.graph2Handler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardPoint2.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.graph3Handler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardPoint3.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.graph4Handler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardPoint4.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.graph5Handler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardPoint5.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.graph6Handler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardPoint6.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.graph7Handler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardPoint7.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.graph8Handler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardPoint8.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.graph9Handler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardPoint9.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            this.graphFullHandler = () => {
                if (this.onObjectSelected) {
                    const title = ' ';
                    const body = ' ';
                    const imageExtra = 'https://bridgear.blob.core.windows.net/public/Chemistry/TempChangesAR/2D/GraphCardFull.png'
                    this.onObjectSelected({title, body, imageExtra})
                    this.annotationComponent.line.visible = true
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const hydrochloricAcidTriggerBtn = poolButtons.requestEntity()
                hydrochloricAcidTriggerBtn?.setAttribute('position', '0.15 0.15 0')
                hydrochloricAcidTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                hydrochloricAcidTriggerBtn?.play()
                hydrochloricAcidTriggerBtn?.addEventListener('click', () => {
                    this.hydrochloricAcidHandler()
                    if (hydrochloricAcidTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(hydrochloricAcidTriggerBtn);

                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (hydrochloricAcidTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = hydrochloricAcidTriggerBtn
                    }
                });
                const sodiumHydroxineTriggerBtn = poolButtons.requestEntity()
                sodiumHydroxineTriggerBtn?.setAttribute('position', '-0.15 0.05 0')
                sodiumHydroxineTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                sodiumHydroxineTriggerBtn?.play()
                sodiumHydroxineTriggerBtn?.addEventListener('click', () => {
                    this.sodiumHydroxideHandler()
                    if (sodiumHydroxineTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(sodiumHydroxineTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (sodiumHydroxineTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = sodiumHydroxineTriggerBtn
                    }
                });
                // @ts-ignore
                this.graph1TriggerButton = poolButtons.requestEntity();
                this.graph1TriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graph1TriggerButton?.setAttribute('scale', '0 0 0');
                (this.graph1TriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graph1TriggerButton?.play()
                this.graph1TriggerButton?.addEventListener('click', () => {
                    this.graph1Handler()
                    if (this.graph1TriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graph1TriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graph1TriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graph1TriggerButton

                    }
                });
                // @ts-ignore
                this.graph2TriggerButton = poolButtons.requestEntity();
                this.graph2TriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graph2TriggerButton?.setAttribute('scale', '0 0 0');
                (this.graph2TriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graph2TriggerButton?.play()
                this.graph2TriggerButton?.addEventListener('click', () => {
                    this.graph2Handler()
                    if (this.graph2TriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graph2TriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graph2TriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graph2TriggerButton

                    }
                });
                // @ts-ignore
                this.graph3TriggerButton = poolButtons.requestEntity();
                this.graph3TriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graph3TriggerButton?.setAttribute('scale', '0 0 0');
                (this.graph3TriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graph3TriggerButton?.play()
                this.graph3TriggerButton?.addEventListener('click', () => {
                    this.graph3Handler()
                    if (this.graph3TriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graph3TriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graph3TriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graph3TriggerButton

                    }
                });
                // @ts-ignore
                this.graph4TriggerButton = poolButtons.requestEntity();
                this.graph4TriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graph4TriggerButton?.setAttribute('scale', '0 0 0');
                (this.graph4TriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graph4TriggerButton?.play()
                this.graph4TriggerButton?.addEventListener('click', () => {
                    this.graph4Handler()
                    if (this.graph4TriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graph4TriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graph4TriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graph4TriggerButton

                    }
                });
                // @ts-ignore
                this.graph5TriggerButton = poolButtons.requestEntity();
                this.graph5TriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graph5TriggerButton?.setAttribute('scale', '0 0 0');
                (this.graph5TriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graph5TriggerButton?.play()
                this.graph5TriggerButton?.addEventListener('click', () => {
                    this.graph5Handler()
                    if (this.graph5TriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graph5TriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graph5TriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graph5TriggerButton

                    }
                });
                // @ts-ignore
                this.graph6TriggerButton = poolButtons.requestEntity();
                this.graph6TriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graph6TriggerButton?.setAttribute('scale', '0 0 0');
                (this.graph6TriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graph6TriggerButton?.play()
                this.graph6TriggerButton?.addEventListener('click', () => {
                    this.graph6Handler()
                    if (this.graph6TriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graph6TriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graph6TriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graph6TriggerButton

                    }
                });
                // @ts-ignore
                this.graph7TriggerButton = poolButtons.requestEntity();
                this.graph7TriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graph7TriggerButton?.setAttribute('scale', '0 0 0');
                (this.graph7TriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graph7TriggerButton?.play()
                this.graph7TriggerButton?.addEventListener('click', () => {
                    this.graph7Handler()
                    if (this.graph7TriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graph7TriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graph7TriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graph7TriggerButton

                    }
                });
                // @ts-ignore
                this.graph8TriggerButton = poolButtons.requestEntity();
                this.graph8TriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graph8TriggerButton?.setAttribute('scale', '0 0 0');
                (this.graph8TriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graph8TriggerButton?.play()
                this.graph8TriggerButton?.addEventListener('click', () => {
                    this.graph8Handler()
                    if (this.graph8TriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graph8TriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graph8TriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graph8TriggerButton

                    }
                });
                // @ts-ignore
                this.graph9TriggerButton = poolButtons.requestEntity();
                this.graph9TriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graph9TriggerButton?.setAttribute('scale', '0 0 0');
                (this.graph9TriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graph9TriggerButton?.play()
                this.graph9TriggerButton?.addEventListener('click', () => {
                    this.graph9Handler()
                    if (this.graph9TriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graph9TriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graph9TriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graph9TriggerButton

                    }
                });
                // @ts-ignore
                this.graphFullTriggerButton = poolButtons.requestEntity();
                this.graphFullTriggerButton?.setAttribute('position', '0.1 0.3 0');
                this.graphFullTriggerButton?.setAttribute('scale', '0 0 0');
                (this.graphFullTriggerButton?.components['world-button'] as unknown as WorldButtonAframeInstance).setInnerSrcImage(graphBtnUrl);
                this.graphFullTriggerButton?.play()
                this.graphFullTriggerButton?.addEventListener('click', () => {
                    this.graphFullHandler()
                    if (this.graphFullTriggerButton) {
                        this.annotationComponent.setObjectToFollow(this.graphFullTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (this.graphFullTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = this.graphFullTriggerButton

                    }
                });

            };
        },
        tick(this: ITemperatureChangesSceneAframe, time: number, deltaTime: number) {
            if (this.capMixer) {
                this.capMixer.update(deltaTime * 0.001);
            }
            if (this.dropMixer) {
                this.dropMixer.update(deltaTime * 0.001);
            }
            if (this.thermometerMixer) {
                this.thermometerMixer.update(deltaTime * 0.001);
            }
            if (this.pourMixer) {
                this.pourMixer.update(deltaTime * 0.001);
            }
        },

    },
};
export {LessonStart as TemperatureChangeSceneComponent}
