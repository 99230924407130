import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IEyeSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	actionRespiration: THREE.AnimationAction;

	el: AFrame.Entity;
	currentAssetId: number;
	poolButtons: PoolComponent;

	model: AFrame.Entity;
	modelHighlights: AFrame.Entity;
	imgHighLightsMap: HTMLImageElement;

	annotationATriggerBtn: AFrame.Entity | null;
	annotationBTriggerBtn: AFrame.Entity | null;
	annotationCTriggerBtn: AFrame.Entity | null;
	annotationDTriggerBtn: AFrame.Entity | null;
	annotationETriggerBtn: AFrame.Entity | null;
	annotationFTriggerBtn: AFrame.Entity | null;
	annotationGTriggerBtn: AFrame.Entity | null;
	annotationHTriggerBtn: AFrame.Entity | null;

	retina: THREE.Mesh | null;
	startMaterial: THREE.MeshStandardMaterial;
	startMaterialTransparent: THREE.MeshStandardMaterial;
	startMaterialTransparent_2: THREE.MeshStandardMaterial;
	highlightMaterial: THREE.MeshStandardMaterial;

	nerve: THREE.Mesh | null;
	sclera: THREE.Mesh | null;
	cornea: THREE.Mesh | null;
	iris: THREE.Mesh | null;
	cillary: THREE.Mesh | null;
	ligament: THREE.Mesh | null;
	lens: THREE.Mesh | null;

	annotationAButtonHandler: () => void;
	annotationBButtonHandler: () => void;
	annotationCButtonHandler: () => void;
	annotationDButtonHandler: () => void;
	annotationEButtonHandler: () => void;
	annotationFButtonHandler: () => void;
	annotationGButtonHandler: () => void;
	annotationHButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const EyeScene = {
	name: 'experiment',
	val: {
		init(this: IEyeSceneAframe) {
			var isInitialised = false;
			var count = 0;
			this.el.addEventListener('model-loaded', () => {
				count++;
				if (count < 2) return
				if (!isInitialised) {
					this.model = document.getElementById('model') as AFrame.Entity;
					this.modelHighlights = document.getElementById('modelHighlights') as AFrame.Entity;
					this.imgHighLightsMap = document.getElementById('Highlights') as HTMLImageElement;

					this.retina = this.modelHighlights.object3D.getObjectByName('Retina') as THREE.Mesh;
					this.nerve = this.modelHighlights.object3D.getObjectByName('OpticNerve') as THREE.Mesh;
					this.sclera = this.modelHighlights.object3D.getObjectByName('Sclera') as THREE.Mesh;
					this.cornea = this.modelHighlights.object3D.getObjectByName('Cornea') as THREE.Mesh;
					this.iris = this.modelHighlights.object3D.getObjectByName('Iris') as THREE.Mesh;
					this.cillary = this.modelHighlights.object3D.getObjectByName('Cillary') as THREE.Mesh;
					this.ligament = this.modelHighlights.object3D.getObjectByName('SupersensoryLigament') as THREE.Mesh;
					this.lens = this.modelHighlights.object3D.getObjectByName('Lens') as THREE.Mesh;

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					isInitialised = true;
					firstScene();
				}
				else return
			});

			const highlightsToggle = (object: THREE.Mesh | null) => {
				if (object) {
					object.visible = true;
				}
				setTimeout(() => {
					if (object) {
						object.visible = false;
					}
				}, 1000);
				setTimeout(() => {
					if (object) {
						object.visible = true;
					}
				}, 2000);
				setTimeout(() => {
					if (object) {
						object.visible = false;
					}
				}, 3000);
			}

			const firstScene = () => {
				if (this.retina) {
					this.retina.visible = false;
				}
				if (this.nerve) {
					this.nerve.visible = false;
				}
				if (this.sclera) {
					this.sclera.visible = false;
				}
				if (this.cornea) {
					this.cornea.visible = false;
				}
				if (this.iris) {
					this.iris.visible = false;
				}
				if (this.cillary) {
					this.cillary.visible = false;
				}
				if (this.ligament) {
					this.ligament.visible = false;
				}
				if (this.lens) {
					this.lens.visible = false;
				}
			}

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			// when model is loaded adding recenter functionality
			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				this.el.sceneEl?.emit('recenter');
				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place-air', 'id: sceneHolder; offset: 0 -3 -2');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);
					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}
			});

			this.annotationAButtonHandler = () => {
				highlightsToggle(this.retina);
				if (this.onObjectSelected) {
					const title = 'Retina';
					const body = 'The retina is where receptor cells are located. There are two types of photo (light) receptors: rods and cones. Rods detect light intensity and cones are responsible for colour vision.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.annotationBButtonHandler = () => {
				highlightsToggle(this.nerve);
				if (this.onObjectSelected) {
					const title = 'Optic Nerve';
					const body = 'The optic nerve is a bundle of nerve fibres that carries electrical signals from the retina to the brain. These signals are then processed by the brain to form visual images.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.annotationCButtonHandler = () => {
				highlightsToggle(this.sclera);
				if (this.onObjectSelected) {
					const title = 'Sclera';
					const body = 'The sclera is the white outer layer of the eye. It is tough to help protect the eye.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.annotationDButtonHandler = () => {
				highlightsToggle(this.cornea);
				if (this.onObjectSelected) {
					const title = 'Cornea';
					const body = 'The cornea is the clear, outermost curved lens that helps bend (refract) incoming light.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.annotationEButtonHandler = () => {
				highlightsToggle(this.iris);
				if (this.onObjectSelected) {
					const title = 'Iris';
					const body = 'The iris is the coloured part of the eye that controls the size of the pupil, which regulates the amount of light that enters the pupil.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.annotationFButtonHandler = () => {
				highlightsToggle(this.cillary);
				if (this.onObjectSelected) {
					const title = 'Ciliary Muscles';
					const body = 'The shape of the lens can be adjusted by contracting and relaxing of ciliary muscles to enable the eye to focus on objects at different distances.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.annotationGButtonHandler = () => {
				highlightsToggle(this.ligament);
				if (this.onObjectSelected) {
					const title = 'Suspensory Ligaments';
					const body = 'The shape of the lens can be adjusted by the tightening or loosening of suspensory ligaments to enable the eye to focus on objects at different distances.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.annotationHButtonHandler = () => {
				highlightsToggle(this.lens);
				if (this.onObjectSelected) {
					const title = 'Lens';
					const body = 'The lens is a clear disc that further refracts light and adjusts in shape to help focus light onto the retina.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.annotationATriggerBtn = poolButtons.requestEntity();
				this.annotationATriggerBtn?.object3D.position.set(1.66, 2.1, 0.2);
				this.annotationATriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.annotationATriggerBtn?.play();
				this.annotationATriggerBtn?.addEventListener('click', () => {
					this.annotationAButtonHandler();
					if (this.annotationATriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationATriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationATriggerBtn;
					}
				});

				this.annotationBTriggerBtn = poolButtons.requestEntity();
				this.annotationBTriggerBtn?.object3D.position.set(3.6, -0.37, 0.2);
				this.annotationBTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.annotationBTriggerBtn?.play();
				this.annotationBTriggerBtn?.addEventListener('click', () => {
					this.annotationBButtonHandler();
					if (this.annotationBTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationBTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationBTriggerBtn;
					}
				});


				this.annotationCTriggerBtn = poolButtons.requestEntity();
				this.annotationCTriggerBtn?.object3D.position.set(-2.1, 2, 0.2);
				this.annotationCTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.annotationCTriggerBtn?.play();
				this.annotationCTriggerBtn?.addEventListener('click', () => {
					this.annotationCButtonHandler();
					if (this.annotationCTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationCTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationCTriggerBtn;
					}
				});

				this.annotationDTriggerBtn = poolButtons.requestEntity();
				this.annotationDTriggerBtn?.object3D.position.set(-3.45, 0, 0.2);
				this.annotationDTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.annotationDTriggerBtn?.play();
				this.annotationDTriggerBtn?.addEventListener('click', () => {
					this.annotationDButtonHandler();
					if (this.annotationDTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationDTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationDTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationDTriggerBtn;
					}
				});

				this.annotationETriggerBtn = poolButtons.requestEntity();
				this.annotationETriggerBtn?.object3D.position.set(-2.45, 1, 0.2);
				this.annotationETriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.annotationETriggerBtn?.play();
				this.annotationETriggerBtn?.addEventListener('click', () => {
					this.annotationEButtonHandler();
					if (this.annotationETriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationETriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationETriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationETriggerBtn;
					}
				});

				this.annotationFTriggerBtn = poolButtons.requestEntity();
				this.annotationFTriggerBtn?.object3D.position.set(-1.95, -1.55, 0.2);
				this.annotationFTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.annotationFTriggerBtn?.play();
				this.annotationFTriggerBtn?.addEventListener('click', () => {
					this.annotationFButtonHandler();
					if (this.annotationFTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationFTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationFTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationFTriggerBtn;
					}
				});

				this.annotationGTriggerBtn = poolButtons.requestEntity();
				this.annotationGTriggerBtn?.object3D.position.set(-2, 1.27, 0.2);
				this.annotationGTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.annotationGTriggerBtn?.play();
				this.annotationGTriggerBtn?.addEventListener('click', () => {
					this.annotationGButtonHandler();
					if (this.annotationGTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationGTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationGTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationGTriggerBtn;
					}
				});

				this.annotationHTriggerBtn = poolButtons.requestEntity();
				this.annotationHTriggerBtn?.object3D.position.set(-2.2, 0, 0.2);
				this.annotationHTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.annotationHTriggerBtn?.play();
				this.annotationHTriggerBtn?.addEventListener('click', () => {
					this.annotationHButtonHandler();
					if (this.annotationHTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.annotationHTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.annotationHTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.annotationHTriggerBtn;
					}
				});
			}
		},
		// tick(this: IEyeSceneAframe, time: number, deltaTime: number) {

		// },
	},
};
export { EyeScene as EyeSceneComponent }