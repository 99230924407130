export const vertexShader = `
  uniform float pointMultiplier;

  attribute float size;

  void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    gl_PointSize = size * pointMultiplier / gl_Position.w;
  }
`;

export const fragmentShader = `
  uniform sampler2D diffuseTexture;

  void main() {
    vec4 vColour = vec4(1.0, 1.0, 1.0, 1.0);
    vec2 coords = gl_PointCoord;
    
    // Calculate the distance from the center of the particle (0.5, 0.5)
    float dist = distance(coords, vec2(0.5, 0.5));
    
    // Set the clipping radius (e.g. 0.5 for a circular shape)
    float radius = 0.5;
    
    // If the distance is greater than the radius, discard the fragment
    if (dist > radius) {
      discard;
    }
    
    vec4 texel = texture2D(diffuseTexture, coords);
    gl_FragColor = texel * vColour;
  }
`;
