import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface IAcidsAndBasesSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    mixer: THREE.AnimationMixer;
    movingIonsAnimation: THREE.AnimationAction;
    beakerAAnimation: THREE.AnimationAction;
    beakerBAnimation: THREE.AnimationAction;
    beakerCAnimation: THREE.AnimationAction;
    currentAnimation: THREE.AnimationAction;
    beakerAHandler: () => void;
    beakerBHandler: () => void;
    beakerCHandler: () => void;
    blueLitmusPaperHandler: () => void;
    redLitmusPaperHandler: () => void;
    universalIndicatorHandler: () => void;
    onObjectSelected: ((selectedObject: { title: string; body: string; imageExtra?: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
}

const LessonStart = {
    name: 'lesson-scene',
    val: {
        init(this: IAcidsAndBasesSceneAframe) {
            const flashcardIcon = require('../../../assets/img/book_icon.png')

            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                // ony initialise buttons once pool has loaded
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // moved button initialisation to lesson-start event
                // if initialising here, the buttons are not parenting to objects correctly
                // only applies to this lesson
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

            });
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                console.log('lesson started')
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place')
                    this.el.sceneEl?.removeChild(ring)
                    const model = document.getElementById('model') as AFrame.Entity;
                    const beakerA = model.object3D.getObjectByName('Beaker_A') as THREE.Mesh
                    const beakerAMaterial = beakerA.material as THREE.MeshPhysicalMaterial
                    beakerAMaterial.transparent = true;
                    beakerAMaterial.color = new THREE.Color(0x4f4f4f);
                    beakerAMaterial.side = 2;
                    beakerAMaterial.metalness = 0.2;
                    beakerAMaterial.reflectivity = 0.3;
                    beakerAMaterial.thickness = 0;
                    beakerAMaterial.transmission = 0.8;
                    beakerAMaterial.needsUpdate = true;
                }
                initialiseAnimations()
            })

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                console.log('Event recenter received')
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');
                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place', 'id: model; scale: 30 30 30; offset: 0 0 -0.5');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                }
            });
            this.beakerAHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Beaker A';
                    const body = 'This solution contains many hydrogen ions (H<sup>+</sup>). Once you have added the indicator try to determine what type of solution it is. Use the flashcard (<img style="height: 15px; width: 15px; filter: drop-shadow(1px 1px 1px black)" src="' + flashcardIcon + '" alt="icon"> ) to help you, and then answer the quiz questions <b>(Q)</b> to see if you got the correct result!';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.beakerBHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Beaker B';
                    const body = 'This solution contains many hydroxide ions (OH<sup>-</sup>). Once you have added the indicator try to determine what type of solution it is. Use the flashcard (<img style="height: 15px; width: 15px; filter: drop-shadow(1px 1px 1px black)" src="' + flashcardIcon + '" alt="icon"> ) to help you, and then answer the quiz questions <b>(Q)</b> to see if you got the correct result!';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.beakerCHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Beaker C';
                    const body = 'This solution contains an even mix of hydrogen and hydroxide ions. Once you have added the indicator try to determine what type of solution it is. Use the flashcard (<img style="height: 15px; width: 15px; filter: drop-shadow(1px 1px 1px black)" src="' + flashcardIcon + '" alt="icon"> ) to help you, and then answer the quiz questions <b>(Q)</b> to see if you got the correct result!';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.blueLitmusPaperHandler = () => {
                this.beakerAAnimation.stop()
                this.beakerAAnimation.play()
                if (this.onObjectSelected) {
                    const title = 'Blue Litmus Paper';
                    const body = 'Litmus paper is an indicator. An indicator is something that tells you if a solution is acidic or alkaline. Blue litmus paper is used for acidic solutions. If the solution is acidic, the paper will turn red.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.redLitmusPaperHandler = () => {
                this.beakerBAnimation.stop()
                this.beakerBAnimation.play()
                if (this.onObjectSelected) {
                    const title = 'Red Litmus Paper';
                    const body = 'Litmus paper is an indicator. An indicator is something that tells you if a solution is acidic or alkaline. Red litmus paper is used for alkaline solutions. If the solution is alkaline (basic), the paper will turn blue.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.universalIndicatorHandler = () => {
                this.beakerCAnimation.stop()
                this.beakerCAnimation.play()
                if (this.onObjectSelected) {
                    const title = 'Universal Indicator';
                    const body = 'A universal indicator is more specific than litmus paper as it changes colour based on the acidity or alkalinity of a solution. Match the colour change to the pH scale to determine the pH of the solution.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseAnimations = () => {
                const animEl = this.el.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animEl)
                const [MovingIons, BeakerA, BeakerB, BeakerC] = animEl.animations
                this.movingIonsAnimation = this.mixer.clipAction(MovingIons)
                this.beakerAAnimation = this.mixer.clipAction(BeakerA)
                this.beakerBAnimation = this.mixer.clipAction(BeakerB)
                this.beakerCAnimation = this.mixer.clipAction(BeakerC)
                this.beakerAAnimation.setLoop(THREE.LoopOnce, 1)
                this.beakerAAnimation.clampWhenFinished = true
                this.beakerBAnimation.setLoop(THREE.LoopOnce, 1)
                this.beakerBAnimation.clampWhenFinished = true
                this.beakerCAnimation.setLoop(THREE.LoopOnce, 1)
                this.beakerCAnimation.clampWhenFinished = true
                this.movingIonsAnimation.play()

            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const beakerATriggerBtn = poolButtons.requestEntity()
                beakerATriggerBtn?.setAttribute('position', '-0.094 0.075 0.04')
                beakerATriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                beakerATriggerBtn?.play()
                beakerATriggerBtn?.addEventListener('click', () => {
                    this.beakerAHandler()
                    if (beakerATriggerBtn) {
                        this.annotationComponent.setObjectToFollow(beakerATriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (beakerATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = beakerATriggerBtn
                    }
                });
                const beakerBTriggerBtn = poolButtons.requestEntity()
                beakerBTriggerBtn?.setAttribute('position', '0 0.075 0.04')
                beakerBTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                beakerBTriggerBtn?.play()
                beakerBTriggerBtn?.addEventListener('click', () => {
                    this.beakerBHandler()
                    if (beakerBTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(beakerBTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (beakerBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = beakerBTriggerBtn
                    }
                });
                const beakerCTriggerBtn = poolButtons.requestEntity()
                beakerCTriggerBtn?.setAttribute('position', '0.094 0.075 0.04')
                beakerCTriggerBtn?.setAttribute('scale', '0.1 0.1 0.15')
                beakerCTriggerBtn?.play()
                beakerCTriggerBtn?.addEventListener('click', () => {
                    this.beakerCHandler()
                    if (beakerCTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(beakerCTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (beakerCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = beakerCTriggerBtn
                    }
                });
                const blueLitmusPaperTriggerBtn = poolButtons.requestEntity()
                blueLitmusPaperTriggerBtn?.setAttribute('position', '-0.12 0.015 0.065')
                blueLitmusPaperTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                blueLitmusPaperTriggerBtn?.play()
                blueLitmusPaperTriggerBtn?.addEventListener('click', () => {
                    this.blueLitmusPaperHandler()
                    if (blueLitmusPaperTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(blueLitmusPaperTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (blueLitmusPaperTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = blueLitmusPaperTriggerBtn
                    }
                });
                const redLitmusPaperTriggerBtn = poolButtons.requestEntity()
                redLitmusPaperTriggerBtn?.setAttribute('position', '-0.02 0.015 0.065')
                redLitmusPaperTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                redLitmusPaperTriggerBtn?.play()
                redLitmusPaperTriggerBtn?.addEventListener('click', () => {
                    this.redLitmusPaperHandler()
                    if (redLitmusPaperTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(redLitmusPaperTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (redLitmusPaperTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = redLitmusPaperTriggerBtn
                    }
                });
                const universalIndicatorTriggerButton = poolButtons.requestEntity();
                universalIndicatorTriggerButton?.setAttribute('position', '0.08 0.015 0.065');
                universalIndicatorTriggerButton?.setAttribute('scale', '0.1 0.1 0.1');
                universalIndicatorTriggerButton?.play()
                universalIndicatorTriggerButton?.addEventListener('click', () => {
                    this.universalIndicatorHandler()
                    if (universalIndicatorTriggerButton) {
                        this.annotationComponent.setObjectToFollow(universalIndicatorTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (universalIndicatorTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = universalIndicatorTriggerButton

                    }
                });

            };


        },
        tick(this: IAcidsAndBasesSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },

    },
};
export {LessonStart as AcidsAndBasesSceneComponent}
