import React, { useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { TapPlaceComponent } from '../../../lib/aframe/components/tap-place-object';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { HouseControlComponent } from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import { TapPlaceCustom } from 'lib/aframe/components/tap-place-custom';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
} from 'shared/constants/hint-items';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
  'Click on parts of the home to learn about reducing heat loss.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT];

const Physics1Aframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };

  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };

  const onLesstonStarted = () => {
    setLessonStarted(true);
  };

  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };

  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        lessonStarted={lessonStarted}
        onAnnotationStateChanged={onAnnotationStateChanged}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./physics1-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          HouseControlComponent,
          TapPlaceComponent,
          WorldButtonComponent,
          FaceCameraComponent,
          Annotation,
        ]}
        onObjectSelected={onObjectSelected}
        onLessonStart={onLesstonStarted}
        annotationState={annotationState}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { Physics1Aframe };
