import { useCallback } from 'react';
import { useSharedContext } from '../context';
import { openBurgerMenuActionCreator } from '../state';

export function useCloseBurgerMenu() {
  const [, dispatch] = useSharedContext();

  return useCallback(() => {
    dispatch(openBurgerMenuActionCreator(false));
  }, [dispatch]);
}
