import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';
import {IShaderSmokeAframe} from "./shader-smoke";
import {IShaderSmoke2Aframe} from "./shader-smoke2";
import {IShaderSmokeFarmAframe} from "./shader-smoke-farm";

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface IClimateChangeSceneAframe {
    buttonHolder: AFrame.Entity[];
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    solarRadiationButtonHandler: () => void;
    theGreenhouseEffectButtonHandler: () => void;
    industryButtonHandler: () => void;
    agricultureButtonHandler: () => void;
    transportationButtonHandler: () => void;
    deforestationButtonHandler: () => void;
    greenhouseGasesButtonHandler: () => void;
    mixer: THREE.AnimationMixer;
    solarRadiationAnimation: THREE.AnimationAction;
    industryAnimation: THREE.AnimationAction;
    theGreenhouseEffectAnimation: THREE.AnimationAction;
    agricultureAnimation: THREE.AnimationAction;
    transportationAnimation: THREE.AnimationAction;
    focusOnTreesAnimation: THREE.AnimationAction;
    deforestationAnimation: THREE.AnimationAction;
    currentAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; imageExtra?: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    currentAssetId: number;
    smoke: any;
    smoke2: any;
    smokeFarm: any;
    smokeCows: any;
}

const LessonStart = {
    name: 'planet-scene',
    val: {
        init(this: IClimateChangeSceneAframe) {
            const planet = document.getElementById('planetHolder') as AFrame.Entity;
            planet.addEventListener('model-loaded', () => {
                const planet = document.getElementById('planet') as AFrame.Entity;
                planet.setAttribute('lightning', '');
                let animatedEl = planet.object3D.getObjectByName('Scene') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [AtmosArrows, SunArrows, FocusOnFactory, FocusOnFarm, FocusOnTrees, TreesCutDown, Transportation] = animatedEl.animations
                this.solarRadiationAnimation = this.mixer.clipAction(SunArrows)
                this.theGreenhouseEffectAnimation = this.mixer.clipAction(AtmosArrows)
                this.industryAnimation = this.mixer.clipAction(FocusOnFactory)
                this.agricultureAnimation = this.mixer.clipAction(FocusOnFarm)
                this.transportationAnimation = this.mixer.clipAction(Transportation)
                this.focusOnTreesAnimation = this.mixer.clipAction(FocusOnTrees)
                this.deforestationAnimation = this.mixer.clipAction(TreesCutDown)
                this.solarRadiationAnimation.setLoop(THREE.LoopOnce, 1);
                this.solarRadiationAnimation.clampWhenFinished = true
                this.solarRadiationAnimation.play()
                this.theGreenhouseEffectAnimation.setLoop(THREE.LoopOnce, 1);
                this.theGreenhouseEffectAnimation.clampWhenFinished = true
                this.theGreenhouseEffectAnimation.paused = false;
                this.theGreenhouseEffectAnimation.play()
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                if (this.el.hasAttribute('shader-smoke')) {
                    this.smoke = this.el.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                    this.smoke.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke2')) {
                    this.smoke2 = this.el.components['shader-smoke2'] as unknown as IShaderSmoke2Aframe;
                    this.smoke2.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-farm')) {
                    this.smokeFarm = this.el.components['shader-smoke-farm'] as unknown as IShaderSmoke2Aframe;
                    this.smokeFarm.setVisibility(false);
                }
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: planetHolder; scale: 10 10 10; offset: 0 -3 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });
            this.solarRadiationButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.transportationAnimation.stop()
                if (this.el.hasAttribute('shader-smoke')) {
                    this.smoke = this.el.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                    this.smoke.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke2')) {
                    this.smoke2 = this.el.components['shader-smoke2'] as unknown as IShaderSmoke2Aframe;
                    this.smoke2.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-farm')) {
                    this.smokeFarm = this.el.components['shader-smoke-farm'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeFarm.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-cows')) {
                    this.smokeCows = this.el.components['shader-smoke-cows'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeCows.setVisibility(false);
                }
                this.solarRadiationAnimation.stop()
                this.solarRadiationAnimation.play()
                if (this.onObjectSelected) {
                    const title = 'Solar Radiation';
                    const body = 'The sun emits short wavelength radiation which reaches the Earth’s surface, is absorbed, and re-emitted as longwave radiation. Some of this radiation is reflected into space.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.theGreenhouseEffectButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.transportationAnimation.stop()
                if (this.el.hasAttribute('shader-smoke')) {
                    this.smoke = this.el.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                    this.smoke.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke2')) {
                    this.smoke2 = this.el.components['shader-smoke2'] as unknown as IShaderSmoke2Aframe;
                    this.smoke2.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-farm')) {
                    this.smokeFarm = this.el.components['shader-smoke-farm'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeFarm.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-cows')) {
                    this.smokeCows = this.el.components['shader-smoke-cows'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeCows.setVisibility(false);
                }
                this.theGreenhouseEffectAnimation.stop()
                this.theGreenhouseEffectAnimation.play()
                if (this.onObjectSelected) {
                    const title = 'The Greenhouse Effect';
                    const body = 'Some longwave radiation is absorbed by greenhouse gases. Greenhouse gases re-emit the radiation in all different directions. This results in warming of the Earth as longwave radiation is thermal radiation, and is known as the greenhouse effect. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.industryButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.transportationAnimation.stop()
                if (this.el.hasAttribute('shader-smoke-farm')) {
                    this.smokeFarm = this.el.components['shader-smoke-farm'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeFarm.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-cows')) {
                    this.smokeCows = this.el.components['shader-smoke-cows'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeCows.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke')) {
                    this.smoke = this.el.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                    this.smoke.setVisibility(true);
                } else {
                    this.el.setAttribute('shader-smoke', '');
                }
                if (this.el.hasAttribute('shader-smoke2')) {
                    this.smoke2 = this.el.components['shader-smoke2'] as unknown as IShaderSmoke2Aframe;
                    this.smoke2.setVisibility(true);
                } else {
                    this.el.setAttribute('shader-smoke2', '');
                }
                if (this.onObjectSelected) {
                    const title = 'Industry';
                    const body = 'Over the last 150 years, the human population has grown exponentially and become more industrialised. Many industrial practices involve burning lots of fossil fuels, releasing more CO<sub>2</sub> into the atmosphere.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.agricultureButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.transportationAnimation.stop()
                if (this.el.hasAttribute('shader-smoke')) {
                    this.smoke = this.el.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                    this.smoke.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke2')) {
                    this.smoke2 = this.el.components['shader-smoke2'] as unknown as IShaderSmoke2Aframe;
                    this.smoke2.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-farm')) {
                    this.smokeFarm = this.el.components['shader-smoke-farm'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeFarm.setVisibility(true);
                } else {
                    this.el.setAttribute('shader-smoke-farm', '');
                }
                if (this.el.hasAttribute('shader-smoke-cows')) {
                    this.smokeCows = this.el.components['shader-smoke-cows'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeCows.setVisibility(true);
                } else {
                    this.el.setAttribute('shader-smoke-cows', '');
                }
                if (this.onObjectSelected) {
                    const title = 'Agriculture';
                    const body = 'As the human population grows, so does the demand for food. Livestock farming and paddy fields produce a lot of methane each year, increasing the concentration of greenhouse gases in the atmosphere.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.transportationButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                if (this.el.hasAttribute('shader-smoke')) {
                    this.smoke = this.el.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                    this.smoke.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke2')) {
                    this.smoke2 = this.el.components['shader-smoke2'] as unknown as IShaderSmoke2Aframe;
                    this.smoke2.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-farm')) {
                    this.smokeFarm = this.el.components['shader-smoke-farm'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeFarm.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-cows')) {
                    this.smokeCows = this.el.components['shader-smoke-cows'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeCows.setVisibility(false);
                }
                this.transportationAnimation.stop()
                this.transportationAnimation.play()
                this.currentAnimation = this.transportationAnimation
                if (this.onObjectSelected) {
                    const title = 'Transportation';
                    const body = 'Transportation, such as cars, trucks and aeroplanes, burn fossil fuels in the form of petrol or diesel. This releases greenhouse gases into the atmosphere. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.deforestationButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.transportationAnimation.stop()
                if (this.el.hasAttribute('shader-smoke')) {
                    this.smoke = this.el.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                    this.smoke.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke2')) {
                    this.smoke2 = this.el.components['shader-smoke2'] as unknown as IShaderSmoke2Aframe;
                    this.smoke2.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-farm')) {
                    this.smokeFarm = this.el.components['shader-smoke-farm'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeFarm.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-cows')) {
                    this.smokeCows = this.el.components['shader-smoke-cows'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeCows.setVisibility(false);
                }
                this.focusOnTreesAnimation.stop()
                this.focusOnTreesAnimation.setLoop(THREE.LoopOnce, 1);
                this.focusOnTreesAnimation.play()
                this.currentAnimation = this.focusOnTreesAnimation
                setTimeout(() => {
                    this.focusOnTreesAnimation.paused = true
                    this.deforestationAnimation.stop()
                    this.deforestationAnimation.clampWhenFinished = true
                    this.deforestationAnimation.setLoop(THREE.LoopOnce, 1);
                    this.deforestationAnimation.play()
                    setTimeout(() => {
                        this.focusOnTreesAnimation.paused = false
                    }, this.deforestationAnimation.getClip().duration * 1000)
                }, this.focusOnTreesAnimation.getClip().duration * 1000 / 2)

                if (this.onObjectSelected) {
                    const title = 'Deforestation';
                    const body = 'Plants absorb CO<sub>2</sub> for photosynthesis, and trees in particular store carbon for many decades. When trees are cut down or burned, the stored carbon is released back into the atmosphere. Additionally, fewer trees mean less CO<sub>2</sub> is being stored.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.greenhouseGasesButtonHandler = () => {
                if (this.currentAnimation) this.currentAnimation.stop()
                this.transportationAnimation.stop()
                if (this.el.hasAttribute('shader-smoke')) {
                    this.smoke = this.el.components['shader-smoke'] as unknown as IShaderSmokeAframe;
                    this.smoke.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke2')) {
                    this.smoke2 = this.el.components['shader-smoke2'] as unknown as IShaderSmoke2Aframe;
                    this.smoke2.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-farm')) {
                    this.smokeFarm = this.el.components['shader-smoke-farm'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeFarm.setVisibility(false);
                }
                if (this.el.hasAttribute('shader-smoke-cows')) {
                    this.smokeCows = this.el.components['shader-smoke-cows'] as unknown as IShaderSmokeFarmAframe;
                    this.smokeCows.setVisibility(false);
                }
                if (this.onObjectSelected) {
                    const title = 'Greenhouse Gases';
                    const body = 'Greenhouse gases include carbon dioxide, water vapour and methane. They warm Earth through the greenhouse effect, without them it would be extremely cold! However, it’s thought if concentrations become too high Earth may become too hot.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // button holder
                this.buttonHolder = [];
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const solarRadiationTriggerBtn = poolButtons.requestEntity()
                solarRadiationTriggerBtn?.setAttribute('position', '-0.23 0.38 0.03')
                solarRadiationTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3');
                solarRadiationTriggerBtn?.play()
                solarRadiationTriggerBtn?.addEventListener('click', () => {
                    this.solarRadiationButtonHandler()
                    if (solarRadiationTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(solarRadiationTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (solarRadiationTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = solarRadiationTriggerBtn
                    }
                });
                if (solarRadiationTriggerBtn)
                    this.buttonHolder.push(solarRadiationTriggerBtn);

                const theGreenhouseEffectTriggerBtn = poolButtons.requestEntity()
                theGreenhouseEffectTriggerBtn?.setAttribute('position', '-0.02 0.4 0.03')
                theGreenhouseEffectTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                theGreenhouseEffectTriggerBtn?.play()
                theGreenhouseEffectTriggerBtn?.addEventListener('click', () => {
                    this.theGreenhouseEffectButtonHandler()
                    if (theGreenhouseEffectTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(theGreenhouseEffectTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (theGreenhouseEffectTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = theGreenhouseEffectTriggerBtn
                    }
                });
                if (theGreenhouseEffectTriggerBtn)
                    this.buttonHolder.push(theGreenhouseEffectTriggerBtn);

                const industryTriggerBtn = poolButtons.requestEntity()
                industryTriggerBtn?.setAttribute('position', '-0.25 0.27 0')
                industryTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                industryTriggerBtn?.play()
                industryTriggerBtn?.addEventListener('click', () => {
                    this.industryButtonHandler()
                    if (industryTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(industryTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (industryTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = industryTriggerBtn
                    }
                });
                if (industryTriggerBtn)
                    this.buttonHolder.push(industryTriggerBtn);

                const agricultureTriggerBtn = poolButtons.requestEntity()
                agricultureTriggerBtn?.setAttribute('position', '0.27 0.15 0.2')
                agricultureTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                agricultureTriggerBtn?.play()
                agricultureTriggerBtn?.addEventListener('click', () => {
                    this.agricultureButtonHandler()
                    if (agricultureTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(agricultureTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (agricultureTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = agricultureTriggerBtn
                    }
                });
                if (agricultureTriggerBtn)
                    this.buttonHolder.push(agricultureTriggerBtn);

                const transportationTriggerButton = poolButtons.requestEntity();
                transportationTriggerButton?.setAttribute('position', '0.15 0.36 0.13');
                transportationTriggerButton?.setAttribute('scale', '0.3 0.3 0.3');
                transportationTriggerButton?.play()
                transportationTriggerButton?.addEventListener('click', () => {
                    this.transportationButtonHandler()
                    if (transportationTriggerButton) {
                        this.annotationComponent.setObjectToFollow(transportationTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (transportationTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = transportationTriggerButton
                    }
                });
                if (transportationTriggerButton)
                    this.buttonHolder.push(transportationTriggerButton);
                const deforestationTriggerButton = poolButtons.requestEntity();
                deforestationTriggerButton?.setAttribute('position', '-0.02 -0.07 0.325');
                deforestationTriggerButton?.setAttribute('scale', '0.3 0.3 0.3');
                deforestationTriggerButton?.play()
                deforestationTriggerButton?.addEventListener('click', () => {
                    this.deforestationButtonHandler()
                    if (deforestationTriggerButton) {
                        this.annotationComponent.setObjectToFollow(deforestationTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (deforestationTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = deforestationTriggerButton
                    }
                });
                if (deforestationTriggerButton)
                    this.buttonHolder.push(deforestationTriggerButton);
                const greenhouseGasesTriggerButton = poolButtons.requestEntity();
                greenhouseGasesTriggerButton?.setAttribute('position', '-0.29 -0.05 0.19');
                greenhouseGasesTriggerButton?.setAttribute('scale', '0.3 0.3 0.3');
                greenhouseGasesTriggerButton?.play()
                greenhouseGasesTriggerButton?.addEventListener('click', () => {
                    this.greenhouseGasesButtonHandler()
                    if (greenhouseGasesTriggerButton) {
                        this.annotationComponent.setObjectToFollow(greenhouseGasesTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (greenhouseGasesTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = greenhouseGasesTriggerButton
                    }
                });
                if (greenhouseGasesTriggerButton)
                    this.buttonHolder.push(greenhouseGasesTriggerButton);
            };
        },
        tick(this: IClimateChangeSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {LessonStart as GreenhouseSceneComponent}
