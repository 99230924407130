import { Stack, styled } from '@mui/material'

export const StyledText = styled(Stack, { name: 'StyledText' })({
	padding: '5px 20px',
  backgroundColor: '#9945FF',
  borderRadius: '60px',
  fontFamily: 'GilroySemiBold',
  fontSize: '16px',
  color: '#fff',
  display: 'inline'
})