import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IShaderFireAframe } from './shader-fire';
import { IShaderSmokeAframe } from './shader-smoke';
import * as TWEEN from '@tweenjs/tween.js';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IRocketsSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	currentClips: THREE.AnimationAction[];
	mixer: THREE.AnimationMixer;
	el: AFrame.Entity;
	currentAssetId: number;
	poolButtons: PoolComponent;

	groupElement: AFrame.Entity;

	rocket: THREE.Object3D | undefined;
	rocketStageR: THREE.Object3D | undefined;
	rocketStageL: THREE.Object3D | undefined;
	rocketLowSeparated: THREE.Object3D | undefined;
	rocketShell: THREE.Object3D | undefined;
	rocketShell1: THREE.Object3D | undefined;
	rocketShell2: THREE.Object3D | undefined;
	rocketTopPart: THREE.Object3D | undefined;

	cloudPlane: THREE.Object3D | undefined;

	accelerationTriggerBtn: AFrame.Entity | null;
	massTriggerBtn: AFrame.Entity | null;
	lawTriggerBtn: AFrame.Entity | null;
	boostersTriggerBtn: AFrame.Entity | null;

	accelerationButtonHandler: () => void;
	massButtonHandler: () => void;
	lawButtonHandler: () => void;
	boostersButtonHandler: () => void;

	actionRocket: THREE.AnimationAction;
	actionRocketLowSeparated: THREE.AnimationAction;
	actionRocketShell: THREE.AnimationAction;
	actionRocketShell1: THREE.AnimationAction;
	actionRocketShell2: THREE.AnimationAction;
	actionRocketrocketStageL: THREE.AnimationAction;
	actionRocketrocketStageR: THREE.AnimationAction;
	actionRocketTopPart: THREE.AnimationAction;

	backgroudLine_1: THREE.Mesh;
	backgroudLine_2: THREE.Mesh;
	backgroudLine_3: THREE.Mesh;
	backgroudLine_4: THREE.Mesh;

	fireMain: IShaderFireAframe;
	fireR: IShaderFireAframe;
	fireL: IShaderFireAframe;
	fireTopPart: IShaderFireAframe;
	smoke: IShaderSmokeAframe;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const RocketsScene = {
	name: 'experiment',
	val: {
		init(this: IRocketsSceneAframe) {
			var isInitialised = false;
			this.currentClips = [];
			this.el.addEventListener('model-loaded', () => {
				if (!isInitialised) {
					this.groupElement = document.getElementById('model') as AFrame.Entity;
					this.rocket = this.groupElement.object3D.getObjectByName('Rocket');
					this.rocketStageR = this.groupElement.object3D.getObjectByName('StageR');
					this.rocketStageL = this.groupElement.object3D.getObjectByName('StageL');
					this.cloudPlane = this.groupElement.object3D.getObjectByName('CloudPlane');
					this.rocketLowSeparated = this.groupElement.object3D.getObjectByName('Rocket_low_separated');
					this.rocketShell = this.groupElement.object3D.getObjectByName('RocketShell');
					this.rocketShell1 = this.groupElement.object3D.getObjectByName('Rocketshell1');
					this.rocketShell2 = this.groupElement.object3D.getObjectByName('Rocketshell2');
					this.rocketTopPart = this.groupElement.object3D.getObjectByName('TopPart');

					this.backgroudLine_1 = this.el.object3D.getObjectByName('Line001') as THREE.Mesh;
					this.backgroudLine_2 = this.el.object3D.getObjectByName('Line002') as THREE.Mesh;
					this.backgroudLine_3 = this.el.object3D.getObjectByName('Line003') as THREE.Mesh;
					this.backgroudLine_4 = this.el.object3D.getObjectByName('Line004') as THREE.Mesh;

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {

						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;
				}
				else return
			});

			const firstScene = () => {
				this.currentClips[0] = this.actionRocket;
				this.actionRocket.reset();
				this.actionRocket.play();

				if (this.groupElement.hasAttribute('shader-fire')) {
					this.fireMain = this.groupElement.components['shader-fire'] as unknown as IShaderFireAframe;
					this.fireMain.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-fire_stage_l')) {
					this.fireL = this.groupElement.components['shader-fire_stage_l'] as unknown as IShaderFireAframe;
					this.fireL.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-fire_stage_r')) {
					this.fireR = this.groupElement.components['shader-fire_stage_r'] as unknown as IShaderFireAframe;
					this.fireR.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-smoke')) {
					this.smoke = this.groupElement.components['shader-smoke'] as unknown as IShaderSmokeAframe;
					this.smoke.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-top-part-fire')) {
					this.fireTopPart = this.groupElement.components['shader-top-part-fire'] as unknown as IShaderFireAframe;
					this.fireTopPart.setVisibility(false);
				}
				this.fireMain.setVisibility(true);
				this.fireR.setVisibility(true);
				this.fireL.setVisibility(true);
				this.smoke.setVisibility(true);
				if (this.backgroudLine_1) {
					this.backgroudLine_1.visible = true;
				}
				if (this.backgroudLine_2) {
					this.backgroudLine_2.visible = true;
				}
				if (this.backgroudLine_3) {
					this.backgroudLine_3.visible = true;
				}
				if (this.backgroudLine_4) {
					this.backgroudLine_4.visible = true;
				}
				if (this.accelerationTriggerBtn) {
					this.accelerationTriggerBtn.object3D.visible = true;
				}
				if (this.boostersTriggerBtn) {
					this.boostersTriggerBtn.object3D.visible = true;
				}
				if (this.lawTriggerBtn) {
					this.lawTriggerBtn.object3D.visible = true;
				}
				if (this.massTriggerBtn) {
					this.massTriggerBtn.object3D.visible = true;
				}
				if (this.backgroudLine_1) {
					this.backgroudLine_1.visible = false;
				}
				if (this.backgroudLine_2) {
					this.backgroudLine_2.visible = false;
				}
				if (this.backgroudLine_3) {
					this.backgroudLine_3.visible = false;
				}
				if (this.backgroudLine_4) {
					this.backgroudLine_4.visible = false;
				}
			}

			const objectsDisapearingByTimer = (object: THREE.Object3D | undefined, timeout: number) => {
				setTimeout(() => {
					if (object) {
						object.visible = false;
					}
				}, timeout);
			}

			this.el.sceneEl?.addEventListener('return-to-start', () => {
				if (this.currentClips) {
					this.currentClips.forEach(currentClip => {
						currentClip.stop();
					});
				}
				new TWEEN.Tween(this.groupElement.object3D.scale)
					.to({ x: 25, y: 25, z: 25 }, 1400)
					.start();

				new TWEEN.Tween(this.groupElement.object3D.position)
					.to({ y: 0 }, 1400)
					.start();
				
				this.lawTriggerBtn?.object3D.position.set(0, 7, 0.1);
				if (this.rocketTopPart) {
					this.rocketTopPart.visible = true;
				}
				if (this.rocketLowSeparated) {
					this.rocketLowSeparated.visible = true;
				}
				if (this.rocketShell) {
					this.rocketShell.visible = true;
				}
				if (this.rocketShell1) {
					this.rocketShell1.visible = true;
				}
				if (this.rocketShell2) {
					this.rocketShell2.visible = true;
				}
				if (this.rocketStageL) {
					this.rocketStageL.visible = true;
				}
				if (this.rocketStageR) {
					this.rocketStageR.visible = true;
				}
				firstScene();
			})

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
				firstScene();
			})

			// when model is loaded adding recenter functionality
			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				this.el.sceneEl?.emit('recenter');
				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place-air', 'id: sceneHolder; offset: 0 -5 0');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
					this.fireMain.setVisibility(false);
					this.fireL.setVisibility(false);
					this.fireR.setVisibility(false);
					this.smoke.setVisibility(false);
				}
			});

			const animateGroup1 = () => {
				this.currentClips[1] = this.actionRocketrocketStageL;
				this.actionRocketrocketStageL.reset();
				this.actionRocketrocketStageL.repetitions = 1;
				this.actionRocketrocketStageL.clampWhenFinished = true;
				this.actionRocketrocketStageL.play();
				this.currentClips[2] = this.actionRocketrocketStageR;
				this.actionRocketrocketStageR.reset();
				this.actionRocketrocketStageR.repetitions = 1;
				this.actionRocketrocketStageR.clampWhenFinished = true;
				this.actionRocketrocketStageR.play();

				if (this.backgroudLine_1) {
					this.backgroudLine_1.visible = false;
				}
				if (this.backgroudLine_2) {
					this.backgroudLine_2.visible = false;
				}
				if (this.backgroudLine_3) {
					this.backgroudLine_3.visible = false;
				}
				if (this.backgroudLine_4) {
					this.backgroudLine_4.visible = false;
				}

				this.fireL.setVisibility(false);
				this.fireR.setVisibility(false);

				objectsDisapearingByTimer(this.rocketStageR, 5400);
				objectsDisapearingByTimer(this.rocketStageL, 5400);
				setTimeout(() => {
					if (this.boostersTriggerBtn) {
						this.boostersTriggerBtn.object3D.visible = false;
					}
				}, 2000);
			}

			const animateGroup2 = () => {
				this.currentClips[3] = this.actionRocketLowSeparated;
				this.actionRocketLowSeparated.reset();
				this.actionRocketLowSeparated.repetitions = 1;
				this.actionRocketLowSeparated.clampWhenFinished = true;
				this.actionRocketLowSeparated.play();

				if (this.accelerationTriggerBtn) {
					this.accelerationTriggerBtn.object3D.visible = false;
				}
				this.fireMain.setVisibility(false);
				this.smoke.setVisibility(false);
				objectsDisapearingByTimer(this.rocketLowSeparated, 7000);
				setTimeout(() => {
					if (this.massTriggerBtn) {
						this.massTriggerBtn.object3D.visible = false;
					}
					if (this.accelerationTriggerBtn) {
						this.accelerationTriggerBtn.object3D.visible = false;
					}
				}, 2000);
			}

			const animateGroup3 = () => {
				this.currentClips[4] = this.actionRocketShell;
				this.actionRocketShell.reset();
				this.actionRocketShell.repetitions = 1;
				this.actionRocketShell.clampWhenFinished = true;
				this.actionRocketShell.play();

				this.currentClips[5] = this.actionRocketShell1;
				this.actionRocketShell1.reset();
				this.actionRocketShell1.repetitions = 1;
				this.actionRocketShell1.clampWhenFinished = true;
				this.actionRocketShell1.play();

				this.currentClips[6] = this.actionRocketShell2;
				this.actionRocketShell2.reset();
				this.actionRocketShell2.repetitions = 1;
				this.actionRocketShell2.clampWhenFinished = true;
				this.actionRocketShell2.play();

				this.currentClips[7] = this.actionRocketTopPart;
				this.actionRocketTopPart.reset();
				this.actionRocketTopPart.repetitions = 1;
				this.actionRocketTopPart.clampWhenFinished = true;
				this.actionRocketTopPart.play();

				const backgroud = this.el.object3D.getObjectByName('CloudPlane') as THREE.Mesh;

				new TWEEN.Tween(this.groupElement.object3D.scale)
					.to({ x: 150, y: 150, z: 150 }, 1400)
					.start();

				new TWEEN.Tween(this.groupElement.object3D.position)
					.to({ y: -35 }, 1400)
					.start();

				if (this.groupElement.hasAttribute('shader-top-part-fire')) {
					this.fireTopPart.setVisibility(true);
				}
				if (backgroud) {
					backgroud.visible = false;
				}
				if (this.massTriggerBtn) {
					this.massTriggerBtn.object3D.visible = false;
				}
				this.lawTriggerBtn?.object3D.position.set(0, 4, 1);
				objectsDisapearingByTimer(this.rocketShell, 5000);
				objectsDisapearingByTimer(this.rocketShell1, 5000);
				objectsDisapearingByTimer(this.rocketShell2, 5000);
				objectsDisapearingByTimer(this.rocketTopPart, 5000);
			}

			const AnimationOrder = () => {
				animateGroup1();
				let animationCount = 0;
				this.mixer.addEventListener('finished', (e) => {
					animationCount++;
					if (animationCount === 2) {
						animateGroup2();
					}
					if (animationCount === 3) {
						animateGroup3();
					}
				})
			}

			const initialiseAnimations = () => {
				const animatedEl = this.groupElement.object3D.getObjectByName('Scene') as any;

				this.mixer = new THREE.AnimationMixer(animatedEl);

				const showRocketAction = animatedEl.animations[0];
				const showRocketLowSeparatedAction = animatedEl.animations[1];
				const showRocketShellAction = animatedEl.animations[2];
				const showRocketShell1Action = animatedEl.animations[3];
				const showRocketShell2Action = animatedEl.animations[4];
				const showRocketrocketStageLAction = animatedEl.animations[5];
				const showRocketrocketStageRAction = animatedEl.animations[6];
				const showRocketTopPartAction = animatedEl.animations[7];

				this.actionRocket = this.mixer.clipAction(showRocketAction);
				this.actionRocketLowSeparated = this.mixer.clipAction(showRocketLowSeparatedAction);
				this.actionRocketShell = this.mixer.clipAction(showRocketShellAction);
				this.actionRocketShell1 = this.mixer.clipAction(showRocketShell1Action);
				this.actionRocketShell2 = this.mixer.clipAction(showRocketShell2Action);
				this.actionRocketrocketStageL = this.mixer.clipAction(showRocketrocketStageLAction);
				this.actionRocketrocketStageR = this.mixer.clipAction(showRocketrocketStageRAction);
				this.actionRocketTopPart = this.mixer.clipAction(showRocketTopPartAction);
			};

			this.accelerationButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Acceleration';
					const body = 'Acceleration is the rate that an object’s speed changes. If a rocket has an upward acceleration then its upward velocity will increase, starting from rest and increasing to a high enough velocity to escape Earth’s gravitational pull.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.massButtonHandler = () => {
				if (this.lawTriggerBtn) {
					this.lawTriggerBtn.object3D.visible = true;
				}

				if (this.onObjectSelected) {
					const title = 'Inertial Mass';
					const body = 'Mass acts as a resistance to changes in velocity (acceleration). Heavy objects require more force to get them moving and are much harder to stop once they are! This is true even without considering gravity.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.lawButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Newton’s Second Law';
					const body = 'Newton’s second law is an equation that relates the acceleration an object feels (a) with the net force acting on it (F) and it’s mass (m): <b>F = ma.</b>'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.boostersButtonHandler = () => {
				const scene = document.querySelector('a-scene');
				AnimationOrder();
				if (this.massTriggerBtn) {
					this.massTriggerBtn.object3D.visible = true;
				}
				setTimeout(() => {
					scene.emit('return-to-start');
				}, 25000);
				setTimeout(() => {
					scene.emit('annotation-close');
				}, 8000);
				if (this.onObjectSelected) {
					const title = 'Staged Rockets';
					const body = 'When a rocket Stage runs out of fuel, it no longer contributes a force but still has a large mass which reduces the rocket’s acceleration. Rockets detach these empty fuel Stages to reduce their mass and keep a high acceleration upwards.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.accelerationTriggerBtn = poolButtons.requestEntity();
				this.accelerationTriggerBtn?.object3D.position.set(0, 0.2, 0.38);
				this.accelerationTriggerBtn?.object3D.scale.set(2, 2, 2);
				this.accelerationTriggerBtn?.play();
				this.accelerationTriggerBtn?.addEventListener('click', () => {
					this.accelerationButtonHandler();
					if (this.accelerationTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.accelerationTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.accelerationTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.accelerationTriggerBtn;
					}
				});

				this.massTriggerBtn = poolButtons.requestEntity();
				this.massTriggerBtn?.object3D.position.set(0, 4, 0.38);
				this.massTriggerBtn?.object3D.scale.set(2, 2, 2);
				this.massTriggerBtn?.play();
				this.massTriggerBtn?.addEventListener('click', () => {
					this.massButtonHandler();
					if (this.massTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.massTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.massTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.massTriggerBtn;
					}
				});

				this.lawTriggerBtn = poolButtons.requestEntity();
				this.lawTriggerBtn?.object3D.position.set(0, 7, 0.1);
				this.lawTriggerBtn?.object3D.scale.set(2, 2, 2);
				this.lawTriggerBtn?.play();
				this.lawTriggerBtn?.addEventListener('click', () => {
					this.lawButtonHandler();
					if (this.lawTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.lawTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.lawTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.lawTriggerBtn;
					}
				});

				this.boostersTriggerBtn = poolButtons.requestEntity();
				this.boostersTriggerBtn?.object3D.position.set(0.55, 3, 0.3);
				this.boostersTriggerBtn?.object3D.scale.set(2, 2, 2);
				this.boostersTriggerBtn?.play();
				this.boostersTriggerBtn?.addEventListener('click', () => {
					this.boostersButtonHandler();
					if (this.boostersTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.boostersTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.boostersTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.boostersTriggerBtn;
					}
				});
			}
		},
		tick(this: IRocketsSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
			TWEEN.update();
		},
	},
};
export { RocketsScene as RocketsSceneComponent }