import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IconImage } from '../../../shared/components/IconImage';
import { DisplayEnum } from '../../../shared/enums';
import { useTranslation } from 'react-i18next';

import USA from '../../../assets/united-states.svg';
import UK from '../../../assets/united-kingdom.svg';
import KOREA from '../../../assets/south-korea.svg';

export function ContactUsHome() {
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const { t, i18n } = useTranslation();
  const i18nPath = 'LANDING_PAGE.CONTACT_US_COMPONENT.';

  return (
    <Stack
      width={xlDisplay ? 1080 : '100%'}
      mx='auto'
      mt={xlDisplay ? 9 : 3}
      mb={xlDisplay ? 6 : 3}
      boxSizing='border-box'
      px={xlDisplay ? 0 : mobile ? 2 : 3}
      id='contacts'
    >
      <Typography
        fontSize={26}
        fontFamily='GilroyBold'
        color='#000'
        textAlign='center'
        mb={3}
      >
        {t(i18nPath + 'TITLE')}
      </Typography>
      <Stack
        direction='row'
        justifyContent={xlDisplay ? 'space-between' : 'center'}
        flexWrap={xlDisplay ? 'nowrap' : 'wrap'}
      >
        <Stack mr={xlDisplay || mobile ? 0 : 'auto'}>
          <Box
            width={335}
            p={3}
            boxSizing='border-box'
            borderRadius='30px'
            border='1px solid #DCDCDC'
          >
            <Stack direction='row' alignItems='center' mb={2}>
              <IconImage src={UK} width={'30px'} height={'30px'} />
              <Typography
                fontFamily='GilroySemiBold'
                fontSize={20}
                color={theme.palette.text.primary}
                lineHeight={1}
                ml={1.6}
              >
                {t(i18nPath + 'UK.TITLE')}
              </Typography>
            </Stack>
            <Box textAlign='left'>
              <Typography
                fontFamily='GilroySemiBold'
                fontSize={16}
                mb={2}
                color={theme.palette.text.secondary}
              >
                {t(i18nPath + 'UK.PHONE')}
              </Typography>
              <Typography
                fontFamily='GilroySemiBold'
                fontSize={16}
                color={theme.palette.text.secondary}
              >
                {t(i18nPath + 'UK.ADDRESS')}
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Stack mt={mobile ? 3 : 0}>
          <Box
            width={335}
            p={3}
            boxSizing='border-box'
            borderRadius='30px'
            border='1px solid #DCDCDC'
          >
            <Stack direction='row' alignItems='center' mb={2}>
              <IconImage src={USA} width={'30px'} height={'30px'} />
              <Typography
                fontFamily='GilroySemiBold'
                fontSize={20}
                color={theme.palette.text.primary}
                lineHeight={1}
                ml={1.6}
              >
                {t(i18nPath + 'USA.TITLE')}
              </Typography>
            </Stack>
            <Box textAlign='left'>
              <Typography
                fontFamily='GilroySemiBold'
                fontSize={16}
                mb={2}
                color={theme.palette.text.secondary}
              >
                {t(i18nPath + 'USA.PHONE')}
              </Typography>
              <Typography
                fontFamily='GilroySemiBold'
                fontSize={16}
                color={theme.palette.text.secondary}
              >
                {t(i18nPath + 'USA.ADDRESS')}
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Stack mt={xlDisplay ? 0 : 3}>
          <Box
            width={335}
            p={3}
            boxSizing='border-box'
            borderRadius='30px'
            border='1px solid #DCDCDC'
          >
            <Stack direction='row' alignItems='center' mb={2}>
              <IconImage src={KOREA} width={'30px'} height={'30px'} />
              <Typography
                fontFamily='GilroySemiBold'
                fontSize={20}
                color={theme.palette.text.primary}
                lineHeight={1}
                ml={1.6}
              >
                {t(i18nPath + 'KOREA.TITLE')}
              </Typography>
            </Stack>
            <Box textAlign='left'>
              <Typography
                fontFamily='GilroySemiBold'
                fontSize={16}
                mb={2}
                color={theme.palette.text.secondary}
              >
                {t(i18nPath + 'KOREA.PHONE')}
              </Typography>
              <Typography
                fontFamily='GilroySemiBold'
                fontSize={16}
                color={theme.palette.text.secondary}
              >
                {t(i18nPath + 'KOREA.ADDRESS')}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
