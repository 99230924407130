import * as THREE from 'three';
import * as AFrame from 'aframe';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { Object3D } from 'three';
import { fragmentShader, vertexShader } from './shaders/bulbglass-shaders';

interface ICircuitControl {
  ammText: AFrame.Entity;
  voltText: AFrame.Entity;
  currentDeactivatedButton: AFrame.Entity;
  poolEntity: AFrame.Entity;
  annotationComponent: IAnnotationAframe;
  onObjectSelected:
    | ((selectedObject: { title: string; body: string }) => void)
    | null;
  el: AFrame.Entity;
  sliderValue: string;
  currentAssetId: number;
  distanceEvent: CustomEvent;

  lampShaderMaterial: THREE.ShaderMaterial;
  pointLight: AFrame.Entity;
  fillMaterial: THREE.MeshStandardMaterial;
  filamentBulb: Object3D | undefined;
  resistor: Object3D | undefined;
  diod: Object3D | undefined;
  resistorSlider: Object3D | undefined;
  baseNoteBook: Object3D | undefined;
  noteBookResistor: Object3D | undefined;
  noteBookFilamentBulb: Object3D | undefined;
  noteBookDiod: Object3D | undefined;
  noteBookResistorCircles: Object3D[] | undefined;
  noteBookFilamentBulbCircles: Object3D[] | undefined;
  noteBookDiodCircles: Object3D[] | undefined;
}

interface PoolComponent extends AFrame.Component {
  requestEntity(): AFrame.Entity | null;

  returnEntity(entity: AFrame.Entity): void;
}

const CircuitControlComponent = {
  name: 'circuit-control',
  val: {
    init(this: ICircuitControl) {
      this.currentAssetId = 0;
      this.sliderValue = '1';
      const circuit = document.getElementById('circuit') as AFrame.Entity;
      circuit.addEventListener('model-loaded', () => {
        const scene = this.el.sceneEl as AFrame.Scene & {
          systems: { 'annotation-system': IAnnotationSystemAframe };
        };
        const annotationSystem = scene.systems['annotation-system'];
        this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
        this.el.setAttribute('annotation', '');
        this.annotationComponent = this.el.components
          .annotation as IAnnotationAframe;
        this.el.sceneEl?.addEventListener('lesson-start', () => {
          console.log('lesson started');
          const ring = document.getElementById('ring');
          if (ring) {
            ring.removeAttribute('tap-place');
            this.el.sceneEl?.removeChild(ring);
            this.baseNoteBook = circuit.object3D.getObjectByName('Base_1');
            this.baseNoteBook!.visible = false;

            this.filamentBulb =
              circuit.object3D.getObjectByName('FilamentBulb');
            this.resistor = circuit.object3D.getObjectByName('Resistor2');
            this.diod = circuit.object3D.getObjectByName('Diode');
            this.resistorSlider = circuit.object3D.getObjectByName('Slider');
            this.noteBookResistor = circuit.object3D.getObjectByName('Page1');
            this.noteBookDiod = circuit.object3D.getObjectByName('Page2');
            this.noteBookFilamentBulb =
              circuit.object3D.getObjectByName('Page3');

            this.noteBookResistorCircles = [];
            this.noteBookFilamentBulbCircles = [];
            this.noteBookDiodCircles = [];

            this.noteBookResistor!.traverse((child) =>
              this.noteBookResistorCircles?.push(child)
            );
            this.noteBookFilamentBulb!.traverse((child) =>
              this.noteBookFilamentBulbCircles?.push(child)
            );
            this.noteBookDiod!.traverse((child) =>
              this.noteBookDiodCircles?.push(child)
            );

            setModel();
            setActualCircleOnBook();
            setActualResistorSliderPosition();
            updateCircuitDynamicValues();
            createShaderMaterials();

            if (this.currentAssetId === 1) {
              setLightBulbIntensity(this.sliderValue);
            }
          }
        });

        this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
        if (this.poolEntity.hasLoaded) {
          initialiseButtons();
        } else {
          this.poolEntity.addEventListener('loaded', () => {
            initialiseButtons();
          });
        }
        setVoltmeterDigits();
        setAmmeterDigits();
      });

      this.el.sceneEl?.addEventListener('asset-change', (event) => {
        const customEvent = event as CustomEvent;
        this.currentAssetId = customEvent.detail.assetId;
        this.sliderValue = '1';
        setModel();
        setActualCircleOnBook();
        setActualResistorSliderPosition();
        updateCircuitDynamicValues();
        initialiseButtons();
      });

      this.el.sceneEl?.addEventListener('lesson-recenter', () => {
        console.log('Event recenter received');
        const ring = document.getElementById('ring');
        if (ring) {
          return;
        } else {
          const ring = document.createElement('a-ring');
          ring.setAttribute('id', 'ring');
          ring.setAttribute(
            'tap-place',
            'id: circuit; scale: 15 15 15; offset: 0 0 -4'
          );
          ring.setAttribute('material', 'shader: flat; color: #ffffff');
          ring.setAttribute('rotation', '-90 0 0');
          ring.setAttribute('radius-inner', '0.5');
          ring.setAttribute('radius-outer', '0.8');
          this.el.sceneEl?.appendChild(ring);
          if (this.currentDeactivatedButton) {
            (
              this.currentDeactivatedButton.components[
                'world-button'
              ] as unknown as WorldButtonAframeInstance
            ).activate();
            this.annotationComponent.deactivate();
          }
        }
      });

      this.el.sceneEl?.addEventListener('slider-move', (e) => {
        const ce = e as CustomEvent;
        this.sliderValue = ce.detail.value;
        setActualResistorSliderPosition();
        setActualCircleOnBook();
        updateCircuitDynamicValues();
        if (this.currentAssetId === 1) {
          setLightBulbIntensity(this.sliderValue);
        }
      });

      const updateCircuitDynamicValues = () => {
        let V: number = 0;
        let A: number = 0;
        switch (this.sliderValue) {
          case `1`:
            if (this.currentAssetId === 0) {
              V = 1.0;
              A = 0.1;
            }

            if (this.currentAssetId === 1) {
              V = 0.5;
              A = 0.1;
            }

            if (this.currentAssetId === 2) {
              V = 0.0;
              A = 0.1;
            }

            break;
          case `2`:
            if (this.currentAssetId === 0) {
              V = 2.1;
              A = 0.2;
            }

            if (this.currentAssetId === 1) {
              V = 1.0;
              A = 0.2;
            }

            if (this.currentAssetId === 2) {
              V = 0.1;
              A = 0.2;
            }

            break;
          case `3`:
            if (this.currentAssetId === 0) {
              V = 3.0;
              A = 0.3;
            }

            if (this.currentAssetId === 1) {
              V = 1.1;
              A = 0.3;
            }

            if (this.currentAssetId === 2) {
              V = 0.1;
              A = 0.3;
            }

            break;
          case `4`:
            if (this.currentAssetId === 0) {
              V = 3.9;
              A = 0.4;
            }

            if (this.currentAssetId === 1) {
              V = 2.0;
              A = 0.4;
            }

            if (this.currentAssetId === 2) {
              V = 2.0;
              A = 0.4;
            }

            break;
          case `5`:
            if (this.currentAssetId === 0) {
              V = 5.0;
              A = 0.5;
            }

            if (this.currentAssetId === 1) {
              V = 3.5;
              A = 0.5;
            }

            if (this.currentAssetId === 2) {
              V = 5.0;
              A = 0.5;
            }

            break;
        }

        this.voltText.setAttribute(
          'text',
          `value: ${V}; color: #8ad1a9; align: left; width: 10; wrap-count: 50;`
        );
        this.voltText.setAttribute('position', '0.25 0.02 0.2655');
        this.ammText.setAttribute(
          'text',
          `value: ${A}; color: #8ad1a9; align: left; width: 10; wrap-count: 50;`
        );
        this.ammText.setAttribute('position', '0.71 0.02 0.2655');
      };
      const initialiseButtons = () => {
        const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

        const systematicErrorTriggerBtn = poolButtons.requestEntity();
        if (systematicErrorTriggerBtn) {
          systematicErrorTriggerBtn.setAttribute('position', '0.32 0.03 0.18');
          systematicErrorTriggerBtn.play();
          systematicErrorTriggerBtn.addEventListener('click', () => {
            // activate annotation
            if (this.onObjectSelected) {
              let title = '';
              let body = '';
              switch (this.currentAssetId) {
                case 0:
                  title = 'Ohmic Resistor';
                  body =
                    'The I–V characteristic of a resistor is a straight line (linear) because it has a constant resistance. We describe a component with a linear I–V characteristic as an ohmic conductor. This name comes from Ohm’s law V = I×R which is a linear relationship.';
                  break;

                case 1:
                  title = 'Filament Bulb';
                  body =
                    'The filament inside a bulb heats up at high currents which causes the resistance in the filament to increase. This leads to a non-linear graph where the gradient of the I–V characteristic decreases. A filament bulb is therefore a non-ohmic conductor.';
                  break;

                case 2:
                  title = 'Diode';
                  body =
                    'A diode is a component that only allows the flow of current in one direction; this is shown by its I–V characteristic. For negative voltages, nearly 0 current passes through while for positive voltages the diode allows a high current through.A diode is a non-Ohmic conductor.';
                  break;
              }
              this.onObjectSelected({ title, body });
              this.annotationComponent.setObjectToFollow(
                systematicErrorTriggerBtn
              );
            } else {
              console.log('No object selected method');
            }
            // deactivate previous button
            if (this.currentDeactivatedButton) {
              (
                this.currentDeactivatedButton.components[
                  'world-button'
                ] as unknown as WorldButtonAframeInstance
              ).activate();
            }
            (
              systematicErrorTriggerBtn.components[
                'world-button'
              ] as unknown as WorldButtonAframeInstance
            ).deactivate();
            this.currentDeactivatedButton = systematicErrorTriggerBtn;
          });
        } else {
          console.log('No trigger btn');
        }
      };

      const setModel = () => {
        if (this.currentAssetId === 0) {
          this.resistor!.visible = true;
          this.filamentBulb!.visible = false;
          this.diod!.visible = false;
          this.noteBookResistor!.visible = true;
          this.noteBookFilamentBulb!.visible = false;
          this.noteBookDiod!.visible = false;
        }

        if (this.currentAssetId === 1) {
          this.resistor!.visible = false;
          this.filamentBulb!.visible = true;
          this.diod!.visible = false;
          this.noteBookResistor!.visible = false;
          this.noteBookFilamentBulb!.visible = true;
          this.noteBookDiod!.visible = false;
        }

        if (this.currentAssetId === 2) {
          this.resistor!.visible = false;
          this.filamentBulb!.visible = false;
          this.diod!.visible = true;
          this.noteBookResistor!.visible = false;
          this.noteBookFilamentBulb!.visible = true;
          this.noteBookDiod!.visible = true;
        }
      };

      const setVoltmeterDigits = () => {
        if (!this.voltText) {
          const voltText = document.createElement('a-entity');
          voltText.setAttribute(
            'text',
            'value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;'
          );
          voltText.setAttribute('position', '0.25 0.02 0.2655');
          voltText.setAttribute('scale', '0.1 0.1 0.1');
          voltText.setAttribute('rotation', '-90 0 0');
          voltText.setAttribute('visible', true);
          this.el.appendChild(voltText);
          this.voltText = voltText;
        }
      };

      const setAmmeterDigits = () => {
        if (!this.ammText) {
          const ammText = document.createElement('a-entity');
          ammText.setAttribute(
            'text',
            'value: ; color: #8ad1a9; align: left; width: 10; wrap-count: 50;'
          );
          ammText.setAttribute('position', '0.71 0.02 0.2655');
          ammText.setAttribute('scale', '0.1 0.1 0.1');
          ammText.setAttribute('rotation', '-90 0 0');
          ammText.setAttribute('visible', true);
          this.el.appendChild(ammText);
          this.ammText = ammText;
        }
      };

      const setActualCircleOnBook = () => {
        const func = (arr: Object3D[]) => {
          arr.forEach((c, index) => {
            if (
              index <=
              this.noteBookResistorCircles!.length - 1 - (5 - +this.sliderValue)
            ) {
              c!.visible = true;
              return;
            }
            c!.visible = false;
          });
        };

        switch (this.currentAssetId) {
          case 0:
            func(this.noteBookResistorCircles!);
            break;
          case 1:
            func(this.noteBookFilamentBulbCircles!);
            break;
          case 2:
            func(this.noteBookDiodCircles!);
            break;
        }
      };

      const setActualResistorSliderPosition = () => {
        switch (this.sliderValue) {
          case `1`:
            this.resistorSlider!.position.x = -0.0685;
            break;
          case `2`:
            this.resistorSlider!.position.x = -0.040125;
            break;
          case `3`:
            this.resistorSlider!.position.x = -0.01275;
            break;
          case `4`:
            this.resistorSlider!.position.x = 0.014625;
            break;
          case `5`:
            this.resistorSlider!.position.x = 0.045;
            break;
        }
      };

      const createShaderMaterials = () => {
        this.lampShaderMaterial = new THREE.ShaderMaterial({
          uniforms: {
            color: { value: new THREE.Color(0xffffff) },
            fixedColor: { value: new THREE.Vector3(1.0, 0.6, 0.0) },
            baseColor: { value: new THREE.Color(0xff0000) },
            fresnelBias: { value: 0.1 },
            fresnelScale: { value: 2.0 },
            fresnelPower: { value: 3.0 },
            transparencyFactor: { value: 1 },
          },
          vertexShader: vertexShader, // Replace with the vertex shader code above
          fragmentShader: fragmentShader, // Replace with the fragment shader code above
          transparent: true,
        });

        const glassBulb = this.filamentBulb!.getObjectByName(
          'GlassBulb'
        ) as THREE.Mesh;
        glassBulb.material = this.lampShaderMaterial;
        glassBulb.material.needsUpdate = true;

        const fill = this.filamentBulb!.getObjectByName(
          'Base_Filament'
        ) as THREE.Mesh;
        this.fillMaterial = fill.material as THREE.MeshStandardMaterial;
        this.fillMaterial.emissiveIntensity = 0;

        const light = document.createElement('a-light');
        light.setAttribute('type', 'point');
        light.setAttribute('color', '#ffffff');
        light.setAttribute('intensity', '1');
        light.setAttribute('distance', '100');
        light.setAttribute('decay', '2');
        light.setAttribute('position', '-0.0795 0.115 0.15');
        this.pointLight = light;
        this.el.appendChild(this.pointLight);
      };

      const whiteColor = new THREE.Color(0xffffff);
      const dimBaseColor = new THREE.Color(0xf7f49c);
      const dimFixedColor = new THREE.Vector3(1.0, 0.6, 0.0);
      const brightBaseColor = new THREE.Color(0xf5ed00);
      const brightFixedColor = new THREE.Vector3(1.0, 0.8, 0.0);
      const veryBrightBaseColor = whiteColor;
      const veryBrightFixedColor = new THREE.Vector3(1.0, 1.0, 0.0);
      const setLightBulbIntensity = (intensity: string) => {
        switch (intensity) {
          case '1':
          case '2':
            if (
              this.lampShaderMaterial.uniforms.hasOwnProperty(
                'transparencyFactor'
              )
            ) {
              this.lampShaderMaterial.uniforms['transparencyFactor'].value = 1;
            }
            if (this.lampShaderMaterial.uniforms.hasOwnProperty('fixedColor')) {
              this.lampShaderMaterial.uniforms['fixedColor'].value =
                dimFixedColor;
            }

            if (this.lampShaderMaterial.uniforms.hasOwnProperty('baseColor')) {
              this.lampShaderMaterial.uniforms['baseColor'].value =
                dimBaseColor;
            }
            this.lampShaderMaterial.needsUpdate = true;

            if (this.fillMaterial) {
              this.fillMaterial.emissiveIntensity = 0.25;
              this.fillMaterial.needsUpdate = true;
            }

            this.pointLight.setAttribute('intensity', '0.25');
            break;
          case '3':
          case '4':
            // updating lamp material
            if (
              this.lampShaderMaterial.uniforms.hasOwnProperty(
                'transparencyFactor'
              )
            ) {
              this.lampShaderMaterial.uniforms['transparencyFactor'].value = 1;
            }

            if (this.lampShaderMaterial.uniforms.hasOwnProperty('fixedColor')) {
              this.lampShaderMaterial.uniforms['fixedColor'].value =
                brightFixedColor;
            }

            if (this.lampShaderMaterial.uniforms.hasOwnProperty('baseColor')) {
              this.lampShaderMaterial.uniforms['baseColor'].value =
                brightBaseColor;
            }
            this.lampShaderMaterial.needsUpdate = true;

            if (this.fillMaterial) {
              this.fillMaterial.emissiveIntensity = 0.5;
              this.fillMaterial.needsUpdate = true;
            }

            //setting lightss to 0.75
            this.pointLight.setAttribute('intensity', '0.75');
            break;
          case '5':
            // updating material 1
            if (
              this.lampShaderMaterial.uniforms.hasOwnProperty(
                'transparencyFactor'
              )
            ) {
              this.lampShaderMaterial.uniforms['transparencyFactor'].value = 1;
            }

            if (this.lampShaderMaterial.uniforms.hasOwnProperty('fixedColor')) {
              this.lampShaderMaterial.uniforms['fixedColor'].value =
                veryBrightFixedColor;
            }

            if (this.lampShaderMaterial.uniforms.hasOwnProperty('baseColor')) {
              this.lampShaderMaterial.uniforms['baseColor'].value =
                veryBrightBaseColor;
            }

            this.lampShaderMaterial.needsUpdate = true;

            if (this.fillMaterial) {
              this.fillMaterial.color = whiteColor;
              this.fillMaterial.emissiveIntensity = 1;
              this.fillMaterial.needsUpdate = true;
            }

            this.pointLight.setAttribute('intensity', '1');
            break;
          default:
            break;
        }
      };
    },
    tick(this: ICircuitControl) {
      const ring = document.getElementById('ring');
      if (!ring) {
        const circuit = document.getElementById('circuit') as AFrame.Entity;
        const cameraEl = this.el.sceneEl?.querySelector('a-camera');
        if (cameraEl && circuit) {
          const camPos = cameraEl.getAttribute(
            'position'
          ) as unknown as THREE.Vector3;
          let cameraPosition = camPos.clone();
          let spherePos = circuit.object3D.position.clone();
          let distance = cameraPosition.distanceTo(spherePos);
          if (!this.distanceEvent) {
            this.distanceEvent = new CustomEvent('distance-change', {
              detail: { value: false },
            });
          }
          if (
            (distance < 9 && cameraPosition.x > 4)
          ) {
            const scene = this.el.sceneEl as AFrame.Scene;
            this.distanceEvent.detail.value = true;
            scene.emit('distance-change', this.distanceEvent.detail.value);
            circuit.object3D.visible = false;
          } else {
            const scene = this.el.sceneEl as AFrame.Scene;
            this.distanceEvent.detail.value = false;
            scene.emit('distance-change', this.distanceEvent.detail.value);
            if (!circuit.object3D.visible) {
              circuit.object3D.visible = true;
            }
          }
        }
      }
    },
  },
};
export { CircuitControlComponent };
