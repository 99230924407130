import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface ISystemSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    buttonsInitialised: boolean;
    mainAnimation: THREE.AnimationAction;
    fatAnimation: THREE.AnimationAction;
    pancreasAnimation: THREE.AnimationAction;
    proteinAnimation: THREE.AnimationAction;
    starchAnimation: THREE.AnimationAction;
    villiAnimation: THREE.AnimationAction;
    currentClip: THREE.AnimationAction;
    mixer: THREE.AnimationMixer;
    fatMixer: THREE.AnimationMixer;
    pancreasMixer: THREE.AnimationMixer;
    proteinMixer: THREE.AnimationMixer;
    starchMixer: THREE.AnimationMixer;
    villiMixer: THREE.AnimationMixer;
    el: AFrame.Entity;
    prevEl: AFrame.Entity;
    fat: AFrame.Entity;
    pancreas: AFrame.Entity;
    protein: AFrame.Entity;
    starch: AFrame.Entity;
    villi: AFrame.Entity;
    oesophagusButtonHandler: () => void;
    mouthButtonHandler: () => void;
    stomachButtonHandler: () => void;
    largeIntestineButtonHandler: () => void;
    ileumButtonHandler: () => void;
    deodenumButtonHandler: () => void;
    rectumButtonHandler: () => void;
    salivaryGlandButtonHandler: () => void;
    liverButtonHandler: () => void;
    gallbladderButtonHandler: () => void;
    pancreasButtonHandler: () => void;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
}

const LessonStart = {
    name: 'digestive-system',
    val: {
        init(this: ISystemSceneAframe) {
            const mainModel = document.getElementById('digestiveSystem') as AFrame.Entity;
            let isInitialised = false;
            mainModel.addEventListener('model-loaded', (e) => {
                if (isInitialised) { return; }

                isInitialised = true;


                const scene = mainModel.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                mainModel.setAttribute('annotation', '');
                this.annotationComponent = mainModel.components.annotation as IAnnotationAframe;
                mainModel.sceneEl?.addEventListener('lesson-start', () => {
                    const mainModel = document.getElementById('digestiveSystem') as AFrame.Entity;
                    this.fat = document.getElementById('fat') as AFrame.Entity;
                    this.pancreas = document.getElementById('pancreas') as AFrame.Entity;
                    this.protein = document.getElementById('protein') as AFrame.Entity;
                    this.starch = document.getElementById('starch') as AFrame.Entity;
                    this.villi = document.getElementById('villi') as AFrame.Entity;
                    mainModel.object3D.visible = true
                
                    const animatedMainEl = mainModel.object3D.getObjectByName('Scene') as any;
                    const animatedFatEl = this.fat.object3D.getObjectByName('FatEmulsification') as any;
                    const animatedPancreasEl = this.pancreas.object3D.getObjectByName('Pancreas') as any;
                    const animatedProteinEl = this.protein.object3D.getObjectByName('ProteinToPeptides') as any;
                    const animatedStarchEl = this.starch.object3D.getObjectByName('StarchToMaltose') as any;
                    const animatedVilliEl = this.villi.object3D.getObjectByName('Villi') as any;

                    this.mixer = new THREE.AnimationMixer(animatedMainEl)
                    this.fatMixer = new THREE.AnimationMixer(animatedFatEl)
                    this.pancreasMixer = new THREE.AnimationMixer(animatedPancreasEl)
                    this.proteinMixer = new THREE.AnimationMixer(animatedProteinEl)
                    this.starchMixer = new THREE.AnimationMixer(animatedStarchEl)
                    this.villiMixer = new THREE.AnimationMixer(animatedVilliEl)

                    const [Bolus] = animatedMainEl.animations
                    const [FatEmulsification] = animatedFatEl.animations
                    const [Pancreas] = animatedPancreasEl.animations
                    const [Protein] = animatedProteinEl.animations
                    const [Starch] = animatedStarchEl.animations
                    const [Villi] = animatedVilliEl.animations

                    this.mainAnimation = this.mixer.clipAction(Bolus)
                    this.fatAnimation = this.fatMixer.clipAction(FatEmulsification)
                    this.pancreasAnimation = this.pancreasMixer.clipAction(Pancreas)
                    this.proteinAnimation = this.proteinMixer.clipAction(Protein)
                    this.starchAnimation = this.starchMixer.clipAction(Starch)
                    this.villiAnimation = this.villiMixer.clipAction(Villi)
                })
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }
            })

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: sceneHolder; scale: 1 1 1; offset: 0 -7.5 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });


            this.mouthButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                if (this.onObjectSelected) {
                    const title = 'Mouth';
                    const body = 'Teeth break food down into smaller pieces (mechanical digestion) increasing the surface area of the food for digestive enzymes to break down the food further. The tongue mixes food with saliva and creates a rounded ball of food (a bolus).'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.oesophagusButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                this.currentClip = this.mainAnimation
                this.mainAnimation.play()
                if (this.onObjectSelected) {
                    const title = 'Oesophagus';
                    const body = 'The oesophagus is a tube that connects the mouth to the stomach. After swallowing, the muscles in the oesophagus contract in a coordinated fashion to move the bolus of food down towards the stomach. This movement is aided by gravity.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.stomachButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                this.currentClip = this.proteinAnimation;
                this.proteinAnimation.play()
                this.prevEl = this.protein
                this.protein.object3D.visible = true
                if (this.onObjectSelected) {
                    const title = 'Stomach';
                    const body = 'The stomach mechanically churns food with the gastric juices forming a liquid called chyme. Protein digestion begins here. The stomach secretes HCl to create an acidic environment that activates pepsin. Pepsin breaks protein down into peptides.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.largeIntestineButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                if (this.onObjectSelected) {
                    const title = 'Large Intestine';
                    const body = 'The large intestine, also known as the colon, absorbs water and electrolytes from undigested food, forming faeces.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.ileumButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                this.currentClip = this.villiAnimation;
                this.villiAnimation.play()
                this.prevEl = this.villi
                this.villi.object3D.visible = true
                if (this.onObjectSelected) {
                    const title = 'Ileum';
                    const body = 'The small intestine’s second section is the ileum. The ileum’s intestinal wall has a large surface area improving absorption due to the presence of small finger-like projections: villi and microvilli. Nutrients are absorbed by the villi into the bloodstream.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.deodenumButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                if (this.onObjectSelected) {
                    const title = 'Duodenum';
                    const body = 'The small intestine’s first section is the duodenum. The duodenum receives food from the stomach. This is where enzymes and chemicals from the liver and pancreas neutralise the acidity of the chyme and further digest food.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.rectumButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                if (this.onObjectSelected) {
                    const title = 'Rectum';
                    const body = 'Faeces are stored in the rectum and released through the anus. ';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.salivaryGlandButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                this.currentClip = this.starchAnimation;
                this.starchAnimation.play()
                console.log(this.starchAnimation)
                this.prevEl = this.starch
                this.starch.object3D.visible = true
                if (this.onObjectSelected) {
                    const title = 'Salivary Glands';
                    const body = 'Saliva, produced by the salivary glands, contains the enzyme amylase which begins the digestion of carbohydrates, specifically starches. Amylase breaks down starch into simpler sugars, such as maltose, making it easier for the body to absorb them.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.liverButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                this.currentClip = this.fatAnimation;
                this.fatAnimation.play()
                this.prevEl = this.fat
                this.fat.object3D.visible = true
                if (this.onObjectSelected) {
                    const title = 'Liver';
                    const body = 'The liver produces bile, a chemical which helps break down fats into smaller droplets, allowing them to be more easily digested by enzymes called lipases. In the small intestine, bile neutralises the acid released from the stomach.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.gallbladderButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                if (this.onObjectSelected) {
                    const title = 'Gallbladder';
                    const body = 'The gallbladder stores bile and releases it to the duodenum when needed.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.pancreasButtonHandler = () => {
                if (this.currentClip) {
                    this.currentClip.stop();
                }
                if (this.prevEl) {
                    this.prevEl.object3D.visible = false
                }
                this.currentClip = this.pancreasAnimation;
                this.pancreasAnimation.play()
                this.prevEl = this.pancreas
                this.pancreas.object3D.visible = true
                if (this.onObjectSelected) {
                    const title = 'Pancreas';
                    const body = 'The pancreas produces enzymes and an alkaline liquid to create an optimal pH for enzymes. Maltase breaks down maltose into glucose, trypsin breaks down proteins into amino acids, and lipase starts breaking down lipids into fatty acids and glycerol.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

                const mouthTriggerBtn = poolButtons.requestEntity()
                mouthTriggerBtn?.setAttribute('position', '0 0.17 0.05')
                mouthTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                mouthTriggerBtn?.play()
                mouthTriggerBtn?.addEventListener('click', () => {
                    this.mouthButtonHandler()
                    if (mouthTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(mouthTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (mouthTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = mouthTriggerBtn
                    }
                });

                const oesophagusTriggerBtn = poolButtons.requestEntity()
                oesophagusTriggerBtn?.setAttribute('position', '0.001 0.1 -0.006')
                oesophagusTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                oesophagusTriggerBtn?.play()
                oesophagusTriggerBtn?.addEventListener('click', () => {
                    this.oesophagusButtonHandler()
                    if (oesophagusTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(oesophagusTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (oesophagusTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = oesophagusTriggerBtn
                    }
                });

                const stomachTriggerBtn = poolButtons.requestEntity()
                stomachTriggerBtn?.setAttribute('position', '0.015 0.04 0.015')
                stomachTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                stomachTriggerBtn?.play()
                stomachTriggerBtn?.addEventListener('click', () => {
                    this.stomachButtonHandler()
                    if (stomachTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(stomachTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (stomachTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = stomachTriggerBtn
                    }
                });

                const largeIntestineTriggerBtn = poolButtons.requestEntity()
                largeIntestineTriggerBtn?.setAttribute('position', '-0.005 0.025 0.025')
                largeIntestineTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                largeIntestineTriggerBtn?.play()
                largeIntestineTriggerBtn?.addEventListener('click', () => {
                    this.largeIntestineButtonHandler()
                    if (largeIntestineTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(largeIntestineTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (largeIntestineTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = largeIntestineTriggerBtn
                    }
                });

                const ileumTriggerBtn = poolButtons.requestEntity()
                ileumTriggerBtn?.setAttribute('position', '-0.01 0 0.01')
                ileumTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                ileumTriggerBtn?.play()
                ileumTriggerBtn?.addEventListener('click', () => {
                    this.ileumButtonHandler()
                    if (ileumTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(ileumTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (ileumTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = ileumTriggerBtn
                    }
                    console.log(this.currentDeactivatedButton)
                });

                const duodenumTriggerBtn = poolButtons.requestEntity()
                duodenumTriggerBtn?.setAttribute('position', '-0.019 0.02 -0.01')
                duodenumTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                duodenumTriggerBtn?.play()
                duodenumTriggerBtn?.addEventListener('click', () => {
                    this.deodenumButtonHandler()
                    if (duodenumTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(duodenumTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (duodenumTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = duodenumTriggerBtn
                    }
                    console.log(this.currentDeactivatedButton)
                });

                const rectumTriggerBtn = poolButtons.requestEntity()
                rectumTriggerBtn?.setAttribute('position', '0 -0.065 -0.025')
                rectumTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                rectumTriggerBtn?.play()
                rectumTriggerBtn?.addEventListener('click', () => {
                    this.rectumButtonHandler()
                    if (rectumTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(rectumTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (rectumTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = rectumTriggerBtn
                    }
                });

                const salivaryGlandTriggerBtn = poolButtons.requestEntity()
                salivaryGlandTriggerBtn?.setAttribute('position', '0.028 0.185 0.006')
                salivaryGlandTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                salivaryGlandTriggerBtn?.play()
                salivaryGlandTriggerBtn?.addEventListener('click', () => {
                    this.salivaryGlandButtonHandler()
                    if (salivaryGlandTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(salivaryGlandTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (salivaryGlandTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = salivaryGlandTriggerBtn
                    }
                });

                const liverTriggerBtn = poolButtons.requestEntity()
                liverTriggerBtn?.setAttribute('position', '-0.003 0.061 0.025')
                liverTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                liverTriggerBtn?.play()
                liverTriggerBtn?.addEventListener('click', () => {
                    this.liverButtonHandler()
                    if (liverTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(liverTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (liverTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = liverTriggerBtn
                    }
                });

                const gallbladderTriggerBtn = poolButtons.requestEntity()
                gallbladderTriggerBtn?.setAttribute('position', '-0.013 0.045 -0.008')
                gallbladderTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                gallbladderTriggerBtn?.play()
                gallbladderTriggerBtn?.addEventListener('click', () => {
                    this.gallbladderButtonHandler()
                    if (gallbladderTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(gallbladderTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (gallbladderTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = gallbladderTriggerBtn
                    }
                });

                const pancreasTriggerBtn = poolButtons.requestEntity()
                pancreasTriggerBtn?.setAttribute('position', '0 0.03 -0.0135')
                pancreasTriggerBtn?.setAttribute('scale', '0.05 0.05 0.05')
                pancreasTriggerBtn?.play()
                pancreasTriggerBtn?.addEventListener('click', () => {
                    this.pancreasButtonHandler()
                    if (pancreasTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(pancreasTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (pancreasTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = pancreasTriggerBtn
                    }
                });
            };
        },
        tick(this: ISystemSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
            if (this.fatMixer) {
                this.fatMixer.update(deltaTime * 0.001);
            }
            if (this.pancreasMixer) {
                this.pancreasMixer.update(deltaTime * 0.001);
            }
            if (this.proteinMixer) {
                this.proteinMixer.update(deltaTime * 0.001);
            }
            if (this.starchMixer) {
                this.starchMixer.update(deltaTime * 0.001);
            }
            if (this.villiMixer) {
                this.villiMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {LessonStart as LessonSceneComponent}
