import React, { useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { SupercooledCombustionSceneComponent } from './supercooled-combustion-scene';

import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  MODEL3D_HINT,
} from 'shared/constants/hint-items';
import { ShaderFireComponent } from './shader-fire';
import { ShaderFireStageRComponent } from './shader-fire_stageR';
import { ShaderFireStageLComponent } from './shader-fire_stageL';
import { ShaderSmokeComponent } from './shader-smoke';
import { LoadersPermissonsEnum } from 'shared/enums/loadersPermissons.enum';

import { TapPlaceComponent } from 'lib/aframe/components/tap-place-object';
import { TapPlaceCustom } from 'lib/aframe/components/tap-place-custom';
import { ShaderFireStartComponent } from './shader-fire-start';
import { ShaderFireStageRStartComponent } from './shader-fire_stageR-start';
import { ShaderFireStageLStartComponent } from './shader-fire_stageL-start';
import { ShaderSmokeStartComponent } from './shader-smoke-start';
import { ShaderRainComponent } from './shader-rain';


const introText = 'Explore the annotations to learn more about how rockets are launched into space. Press the LAUNCH button to see it in action!';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const PhysicsSupercooledCombustionAframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
    image?: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };

  const onLesstonStarted = () => {
    setLessonStarted(true);
  };

  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };


  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        onAnnotationStateChanged={onAnnotationStateChanged}
        lessonStarted={lessonStarted}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./physics-supercooled-combustion-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          TapPlaceComponent, 
          TapPlaceCustom,
          WorldButtonComponent,
          FaceCameraComponent,
          SupercooledCombustionSceneComponent,
          Annotation,
          ShaderRainComponent,
          ShaderFireComponent,
          ShaderFireStageRComponent,
          ShaderFireStageLComponent,
          ShaderSmokeComponent,
          ShaderFireStartComponent,
          ShaderFireStageRStartComponent,
          ShaderFireStageLStartComponent,
          ShaderSmokeStartComponent,
        ]}
        onObjectSelected={onObjectSelected}
        onLessonStart={onLesstonStarted}
        annotationState={annotationState}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { PhysicsSupercooledCombustionAframe };
