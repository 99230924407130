import React, { useState, useRef } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import { TapPlaceComponent } from '../../../lib/aframe/components/tap-place-object';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { LessonStartComponent } from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import { BottleControlComponent } from './bottle-control';
import { ShaderFireComponent } from './shader-fire';
import { ShaderSpray } from './shader-spray';

import LiIcon from '../../../assets/img/Li_temp.png';
import CaIcon from '../../../assets/img/Ca_temp.png';
import NaIcon from '../../../assets/img/Na_temp.png';
import KIcon from '../../../assets/img/K_temp.png';
import CuIcon from '../../../assets/img/Cu_temp.png';
import ProximityAlert from './proximityalert';
import { ProximityDetector } from './proximity';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  MODEL3D_HINT,
} from 'shared/constants/hint-items';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
  'Identify the coloured flames produced by different metal ions. Use the dropdown menu to change the metal ion solution in the spray bottle, then tap the screen to spray it into the flame.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

// Reference your modules using the following variables
// const reflections = window.Modules8?.getModule({moduleId: 'b6dd7aae-c75b-41f2-b3fa-2dd7204a3cbe'})

const Chemistry1Aframe = () => {
  const [lessonStarted, setLessonStarted] = useState(false);
  const [assetId, setAssetId] = useState(0);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  // console.log('reflections: ', reflections)

  const assetIcons = [LiIcon, CuIcon, CaIcon, NaIcon, KIcon];

  const changeAsset = (assetId: number) => {
    // only change asset if lesson has started
    if (lessonStarted) {
      setAssetId(assetId);
    }
  };

  const onLessonStart = () => {
    setLessonStarted(true);
  };

  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };

  /**
   * Asset changing functionality
   * Lesson ui would update the asset id state
   * Then dispatch an event to the aframe scene
   * And listen to it in any of the aframe scene components
   */
  const assetChangeEventRef = useRef(
    new CustomEvent('asset-change', { detail: { assetId: 0 } })
  );
  React.useEffect(() => {
    // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      console.log('Scene loaded');
      // Update the assetChangeEvent detail before dispatching
      assetChangeEventRef.current.detail.assetId = assetId;
      scene.dispatchEvent(assetChangeEventRef.current);
    }
  }, [assetId]);

  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        onAssetButtonClick={changeAsset}
        assetIcons={assetIcons}
        lessonStarted={lessonStarted}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./chem1-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          TapPlaceComponent,
          LessonStartComponent,
          BottleControlComponent,
          ShaderFireComponent,
          ShaderSpray,
          ProximityDetector,
        ]}
        onLessonStart={onLessonStart}
        permissionsСonfirmation={permissionsСonfirmation}
      />
      <ProximityAlert />
    </React.Fragment>
  );
};
export { Chemistry1Aframe };
