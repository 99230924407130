import { TrainingsListResponse } from '../../../typings';
import type { State } from '../../typings';

export const fetchTrainingsProducer = (
  state: State,
  payload: TrainingsListResponse
): State => ({
  ...state,
  trainingsInfo: payload,
});
