import React, { Fragment, useEffect, useRef, useState } from 'react';
import '../styles/sliderAnnotation.css';
import '../styles/transitions.css';

interface Props {
    title: string;
    range: number[];
    visibility: boolean;
    step: number;
    firstValue: number;
    stringVariant?: boolean;
    blankSlider?: boolean;
    shortVariant?:boolean;
    segments?: number[][];
    content?: string[];
}

const SliderAnnotationContainer: React.FC<Props> = ({ title, range, visibility, step, firstValue, stringVariant, blankSlider, shortVariant, segments, content }) => {
    const [min, max] = range
    const [sliderValue, setSliderValue] = useState(0)
    const [currentContent, setCurrentContent] = useState('')
    const visibilityClass = visibility ? 'sliderAnnotationContainer' : 'shrink-complete';
    const heightClass = shortVariant ? 'sliderAnnotationShort' : 'sliderAnnotationDefault';
    const [currentAsset, setCurrentAsset] = useState()
    useEffect(() => {
        const wrap = document.querySelector(".range-wrap");
        const range = wrap.querySelector(".range");
        const bubble = wrap.querySelector(".bubble");
        if (range) {
            range.addEventListener("input", () => {
                setBubble(range, bubble);
            });
        }
        setBubble(range, bubble);
    }, [visibility, currentAsset])
    useEffect(() => {
        if (segments && content) {
            for (let i = 0; i < content.length; i++){
                if (!sliderValue) setSliderValue(firstValue)
                if (sliderValue >= segments[i][0] && sliderValue <= segments[i][1]){
                    setCurrentContent(content[i])
                }
            }
        }

    }, [sliderValue])
    function getBubbleValue(value: number, range: number[]): string {
        const [min, max] = range;

        if (value <= min) {
            return 'Low';
        } else if (value === max) {
            return 'High';
        } else {
            return 'Mid';
        }
    }

    function setBubble(range: any, bubble: any) {
        const val = range.value;
        setSliderValue(val)
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 100;
        const newVal = Number(((val - min) * 100) / (max - min));
        if (!blankSlider) {
            bubble.innerHTML = stringVariant? getBubbleValue(val, [min, max]) : val;
            const newPosition = 10 - (newVal * 0.2);
            bubble.style.left = `calc(${newVal}% + (${newPosition * 2}px))`;
        }
    }
    const sliderMoveEventRef = useRef(new CustomEvent('slider-move', { detail: { value: 0 } }));
    React.useEffect(() => {
        // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            // Update the assetChangeEvent detail before dispatching
            sliderMoveEventRef.current.detail.value = sliderValue;
            scene.dispatchEvent(sliderMoveEventRef.current);
            scene.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                const currentAssetid = ce.detail.assetId;
                setCurrentAsset(currentAssetid)
            })
        }
    }, [sliderValue, visibility]);
    React.useEffect(() => {
        // @ts-ignore
        document.getElementById('rangeForm').reset()
        const wrap = document.querySelector(".range-wrap");
        const range = wrap.querySelector(".range");
        const bubble = wrap.querySelector(".bubble");
        if (range) {
            range.addEventListener("input", () => {
                setBubble(range, bubble);
            });
        }
        setBubble(range, bubble);
        if (!blankSlider) {
            document.querySelector('.bubble').innerHTML = stringVariant? `${getBubbleValue(firstValue, [min, max])}`:`${firstValue}`
        }
    }, [currentAsset])
    return (
        <Fragment>
            <div id="sliderAnnotationContainer" className={`${visibilityClass} ${heightClass} ${currentContent? 'height' : ''}`}>
                <div className={'rangeContainer'}>
                    <div className="sliderAnnotationTitle">
                        <p dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                    <div className={'range-wrap'}>
                        <form id={'rangeForm'}>
                            <input id={'range'} type="range" className={'range'} defaultValue={firstValue} min={min} max={max}
                                step={step} />
                            <output className="bubble"></output>
                        </form>
                    </div>
                    <div className="sliderAnnotationContent">
                        <p dangerouslySetInnerHTML={{ __html: currentContent }}/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SliderAnnotationContainer;
