// src/components/LessonUI.tsx
import React, { useState } from 'react';
import SideMenu from './side-menu';
import Flashcard from './flashcard';
import QuizComponent from './quiz-component';
import Introduction from './introduction';
import AnnotationContainer from './annotation';
import { AnnotationState } from '../aframe/components/annotation';
import TapPlaceScreenComponent from './tap-place-screen';
import ImageAnnotationContainer from './image-annotation';
import SliderAnnotationContainer from './slider-annotation';
import HintCarousel, { CarouselItem } from './hint-carousel';
import DistancePanel from './distance-panel';
import TrackingStatusPanel from './tracking-status-panel';
import SliderArrowsAnnotationContainer from './slider-arrows-annotation';
import LessonLoader from './loader/lesson-loader';
import { useMediaQuery, useTheme } from '@mui/material';
import { DisplayEnum, LoadersPermissonsEnum } from 'shared/enums';
import ReturnToStartButton from './return-to-start-button';

interface LessonUIProps {
  flashcardData: {
    title: string;
    content: string;
    imgSrc?: string;
    contentExtra?: string;
    imgExtraSrc?: string;
    contentExtraSecond?: string;
  };
  quizData: any[];
  introductionText: string;
  hintItems: CarouselItem[];
  onAssetButtonClick?: (assetId: number) => void;
  onAssetToggleClick?: (assetId: number) => void;
  assetIcons?: string[];
  modelDescription?: string;
  selectedObject?: {
    title: string;
    body: string;
    image?: string;
    imageExtra?: string;
  } | null;
  sliderLoad?: {
    title: string;
    range: number[];
    step: number;
    firstValue: number;
    stringVariant?: boolean;
    blankSlider?: boolean;
    segments?: number[][];
    content?: string[];
  } | null;
  sliderArrowsLoad?: {
    titles: string[];
    content?: string[];
  } | null;
  returnToStartButtonLoad?: boolean;
  onAnnotationStateChanged?: (newState: AnnotationState) => void;
  onToggleAnimation?: (toggle: boolean) => void;
  toggleAnimationStatus?: boolean;
  onTapScreen?: () => void;
  lessonStarted?: boolean;
  showInstructionDefault?: boolean;
  isTrackingLimited?: boolean;
  permissions?: LoadersPermissonsEnum | undefined;
  notShowSliderOnStart?: boolean;
}

const LessonUI: React.FC<LessonUIProps> = ({
  flashcardData,
  quizData,
  introductionText,
  hintItems,
  onToggleAnimation,
  onAssetButtonClick,
  onAssetToggleClick,
  assetIcons,
  toggleAnimationStatus,
  onTapScreen,
  lessonStarted,
  selectedObject,
  sliderLoad,
  sliderArrowsLoad,
  returnToStartButtonLoad,
  onAnnotationStateChanged,
  isTrackingLimited,
  permissions,
  notShowSliderOnStart = false
}) => {
  const theme = useTheme();
  const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet));
  const [flashcardActive, setFlashcardActive] = useState(false);
  const [showIntroduction, setShowIntroduction] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [annotationVisible, setAnnotationVisible] = useState(false);
  const [distancePanelVisible, setDistancePanelVisible] = useState(false);
  const [quizVisibilityBeforeFlashcard, setQuizVisibilityBeforeFlashcard] =
    useState(false);
  const [currentAnnotationState, setCurrentAnnotationState] =
    useState<AnnotationState>(AnnotationState.None);
  const [assetSelectionActive, setAssetSelectionActive] = useState(false);
  const [showTapPlaceScreen, setShowTapPlaceScreen] = useState(true);
  const [sliderVisibility, setSliderVisibility] = useState(true);
  const [sliderArrowsVisibility, setSliderArrowsVisibility] = useState(true);
  // Hide annotation and quiz when flashcard is active
  const annotationVisibility = annotationVisible && !flashcardActive;
  const quizVisibility = showQuiz && !flashcardActive;
  const [showHint, setShowHint] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [orderedHintItems, setOrderedHintItems] = useState(hintItems);
  const [showItemIndex, setShowItemIndex] = useState(0);
  const [isTrackingVisible, setTrackingVisible] = useState(false);
  

  const handleFlascardButtonClick = () => {
    const scene = document.querySelector('a-scene');
    if (flashcardActive) {
      setShowQuiz(quizVisibilityBeforeFlashcard);
      scene?.emit('flashcard-closed');
    } else {
      setQuizVisibilityBeforeFlashcard(showQuiz);
      setShowQuiz(false);
      scene?.emit('flashcard-opened');
    }
    setFlashcardActive(!flashcardActive);
    setShowIntroduction(false);
  };

  const handleQuizIconClick = () => {
    const scene = document.querySelector('a-scene');
    if (showQuiz) {
      setShowQuiz(false);
      scene?.emit('quiz-closed');
    } else {
      setShowQuiz(true);
      setShowIntroduction(false);
      setAnnotationVisible(false); // Close annotation when quiz is activated
      setAssetSelectionActive(false);
      scene?.emit('quiz-opened');
    }
  };

  const closeQuizButton = () => {
    setShowQuiz(false);
    const scene = document.querySelector('a-scene');
    if (scene) {
      scene.emit('quiz-closed');
      setSliderVisibility(true);
      setSliderArrowsVisibility(true);
    }
  };

  // emit recenter event on to the a-scene element if present
  const handleRecenterButtonClick = () => {
    const scene = document.querySelector('a-scene');
    if (scene) {
      scene.emit('lesson-recenter');
      // close quiz
      setAnnotationVisible(false);
      setTrackingVisible(false);
      setShowQuiz(false);
      setSliderVisibility(false);
    }
  };

  const handleAssetSelectionToggle = () => {
    const scene = document.querySelector('a-scene');
    setAssetSelectionActive(!assetSelectionActive);
    if (!assetSelectionActive) {
      setShowQuiz(false);
      scene.emit('quiz-closed');
      if (annotationVisible) {
        closeAnnotation();
      }
    }
  };

  const closeAnnotation = () => {
    scene.emit('annotation-close');
    if (sliderLoad) {
      setSliderVisibility(true);
    }
    if (sliderArrowsLoad) {
      setSliderArrowsVisibility(true);
    }
    setAnnotationVisible(false);
    selectedObject = null;
  };

  const scene = document.querySelector('a-scene');
  scene?.addEventListener('annotation-close', () => {
    setAnnotationVisible(false);
  });

  scene?.addEventListener('distance-change', (e) => {
    const ce = e as CustomEvent;
    const distancePanelState = ce.detail;
    if (distancePanelState) {
      setDistancePanelVisible(true);
    } else {
      setDistancePanelVisible(false);
    }
  });
  
  scene?.addEventListener('slider-visible', () => {
    setSliderVisibility(true);
  });

  scene?.addEventListener('slider-invisible', () => {
    setSliderVisibility(false);
  });

  const onShowHint = () => {
    setShowHint(true);
  };

  const getLocalStorageShowedHintItems = (): CarouselItem[] =>
    JSON.parse(localStorage.getItem('showedHintItems') ?? '[]');

  const getDiff = (): CarouselItem[] => {
    const showedHintItems = getLocalStorageShowedHintItems();
    const diff = hintItems.filter(
      (item) =>
        !showedHintItems.some((elem: CarouselItem) => elem.alt === item.alt)
    );
    return diff;
  };

  const closeHint = () => {
    const showedHintItems = getLocalStorageShowedHintItems();
    const diff = getDiff();
    localStorage.setItem(
      'showedHintItems',
      JSON.stringify(showedHintItems.concat(diff))
    );
    setShowHint(false);
    setShowItemIndex(0);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };

  React.useEffect(() => {
    if (selectedObject) {
      if (selectedObject !== null) {
        setAnnotationVisible(true);
        setShowQuiz(false); // Close quiz when annotation becomes visible and quiz is active
        setShowIntroduction(false); // Hide introduction when showing annotation, in case intro is active
        setAssetSelectionActive(false);
        setSliderVisibility(false);
        setSliderArrowsVisibility(false);
      } else {
        setAnnotationVisible(false);
      }
    }
  }, [selectedObject]);

  React.useEffect(() => {
    if (isTrackingLimited) {
      setAnnotationVisible(false);
      setShowQuiz(false);
      setShowIntroduction(false);
      setFlashcardActive(false);
      // tracking panel on
      setTrackingVisible(true);
    }
  }, [isTrackingLimited]);

  React.useEffect(() => {
    if (selectedObject || showQuiz || !lessonStarted || notShowSliderOnStart) {
      setSliderVisibility(false);
      setSliderArrowsVisibility(false);
    } else {
      setSliderVisibility(true);
      setSliderArrowsVisibility(true);
    }
  }, [selectedObject, showQuiz, lessonStarted, notShowSliderOnStart]);

  React.useEffect(() => {
    if (onAnnotationStateChanged) {
      if (annotationVisibility) {
        setCurrentAnnotationState(AnnotationState.Full);
      } else {
        setCurrentAnnotationState(AnnotationState.None);
      }
      onAnnotationStateChanged(currentAnnotationState);
    }
  }, [annotationVisibility, onAnnotationStateChanged, currentAnnotationState]);

  React.useEffect(() => {
    const diff = getDiff();
    if (diff.length) {
      const newOrderHintItems = orderedHintItems
        .filter((item) => !diff.some((elem) => elem.alt === item.alt))
        .concat(diff);
      const showItemIndex = newOrderHintItems.indexOf(diff[0]);
      setShowItemIndex(showItemIndex === -1 ? 0 : showItemIndex);
      setOrderedHintItems(newOrderHintItems);
      setShowHint(true);
    } else {
      setShowHint(false);
    }
  }, [hintItems]);

  return (
    <div>
      <Introduction
        text={introductionText}
        visible={showIntroduction && !lessonStarted}
      />
      {onTapScreen && !lessonStarted && (
        <TapPlaceScreenComponent
          visible={showTapPlaceScreen}
          onTap={() => {
            setShowTapPlaceScreen(false);
            onTapScreen();
          }}
        />
      )}
      <SideMenu
        onMenuButtonClick={handleFlascardButtonClick}
        onQuizButtonClick={handleQuizIconClick}
        onRecenterButtonClick={handleRecenterButtonClick}
        onAssetButtonClick={onAssetButtonClick}
        onAssetToggleClick={onAssetToggleClick}
        assetSelectionActive={assetSelectionActive}
        onAssetSelectionToggle={handleAssetSelectionToggle}
        onToggleAnimation={onToggleAnimation}
        topPosition={
          quizVisibility || (flashcardActive && quizVisibilityBeforeFlashcard)
        }
        toggleAnimationStatus={toggleAnimationStatus}
        assetIcons={assetIcons}
        lessonStarted={lessonStarted}
        onHintButtonClick={onShowHint}
      />
      {!distancePanelVisible && (
        <Flashcard
          title={flashcardData.title}
          content={flashcardData.content}
          active={flashcardActive}
          imgSrc={flashcardData.imgSrc}
          imgExtraSrc={flashcardData.imgExtraSrc}
          contentExtra={flashcardData.contentExtra}
          contentExtraSecond={flashcardData.contentExtraSecond}
          onBackClick={handleFlascardButtonClick}
        />
      )}
      {!distancePanelVisible && (
        <QuizComponent
          data={quizData}
          visible={quizVisibility}
          closeQuizButton={closeQuizButton}
        />
      )}
      {/*@ts-ignore*/}
      {selectedObject &&
        !distancePanelVisible &&
        (selectedObject?.title !== '' || selectedObject?.body !== '' ? (
          <AnnotationContainer
            // @ts-ignore
            title={selectedObject?.title}
            // @ts-ignore
            body={selectedObject?.body}
            // @ts-ignore
            image={selectedObject?.image}
            imageExtra={selectedObject?.imageExtra}
            onCloseClick={closeAnnotation}
            visible={annotationVisibility}
          />
        ) : (
          <ImageAnnotationContainer
            image={selectedObject?.image}
            onCloseClick={closeAnnotation}
            visible={annotationVisibility}
          />
        ))}
      {sliderLoad && !distancePanelVisible && (
        <SliderAnnotationContainer
          step={sliderLoad.step}
          range={sliderLoad.range}
          title={sliderLoad.title}
          firstValue={sliderLoad.firstValue}
          stringVariant={sliderLoad.stringVariant}
          blankSlider={sliderLoad.blankSlider}
          segments={sliderLoad.segments}
          content={sliderLoad.content}
          visibility={sliderVisibility}
        />
      )}
      {returnToStartButtonLoad && (
        <ReturnToStartButton />
      )}
      {sliderArrowsLoad && !distancePanelVisible && (
        <SliderArrowsAnnotationContainer
          titles={sliderArrowsLoad.titles}
          content={sliderArrowsLoad.content}
          visibility={sliderArrowsVisibility}
        />
      )}
      {distancePanelVisible && <DistancePanel />}

      <TrackingStatusPanel
        visible={isTrackingVisible}
        onRecenterClick={handleRecenterButtonClick}
      />

      {showHint && (
        <HintCarousel
          items={orderedHintItems}
          showItemIndex={showItemIndex}
          closeHintEvent={closeHint}
        />
      )}

      {showLoader && !xlDisplay && (
        <LessonLoader
          items={orderedHintItems}
          showItemIndex={showItemIndex}
          permissions={permissions}
          showHint={showHint}
          closeLoaderEvent={closeLoader}
          closeHintEvent={closeHint}
        />
      )}

    </div>
  );
};

export default LessonUI;
