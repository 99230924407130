import React, {useRef, useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import {TapPlaceComponent} from '../../../lib/aframe/components/tap-place-object';
import {WorldButtonComponent} from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import {CircuitControlComponent} from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
    Annotation,
    AnnotationState,
} from '../../../lib/aframe/components/annotation';
import micrometer from '../../../assets/img/UI_Micrometer.png';
import circuit from '../../../assets/img/UI_Circuit.png';
import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
} from 'shared/constants/hint-items';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
    'Explore the set up to find how to measure the cross sectional area of a wire. Enter the drop down menu to switch to the experiment.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT];

const PhysicsResistivityOfAWireAframe = () => {
    const [assetId, setAssetId] = useState(0);
    const [sliderLoaded, setSliderLoaded] = useState<{
        title: string;
        range: number[];
        step: number;
        firstValue: number;
    } | null>(null);
    const [selectedObject, setSelectedObject] = useState<{
        title: string;
        body: string;
    } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(
        AnnotationState.None
    );
    const [lessonStarted, setLessonStarted] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
    const assetIcons = [micrometer, circuit];
    const changeAsset = (assetId: number) => {
        setSliderLoaded(null);
        if (assetId === 0) {
            setSliderLoaded(null);
        } else {
            setSliderLoaded({
                title: 'Length of Wire/ m',
                range: [0.1, 1],
                step: 0.1,
                firstValue: 0.1,
            });
        }
        setAssetId(assetId);
    };


    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };

    const onLessonStarted = () => {
        setLessonStarted(true);
    };

    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };
    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };
    const assetChangeEventRef = useRef(
        new CustomEvent('asset-change', { detail: { assetId: 0 } })
    );

    React.useEffect(() => {
        // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            // Update the assetChangeEvent detail before dispatching
            assetChangeEventRef.current.detail.assetId = assetId;
            scene.dispatchEvent(assetChangeEventRef.current);
        }
    }, [assetId]);
    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                sliderLoad={sliderLoaded}
                lessonStarted={lessonStarted}
                onAnnotationStateChanged={onAnnotationStateChanged}
                onAssetButtonClick={changeAsset}
                assetIcons={assetIcons}
                hintItems={hintItems}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./physics-resistivity-of-a-wire-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    CircuitControlComponent,
                    TapPlaceComponent,
                    WorldButtonComponent,
                    FaceCameraComponent,
                    Annotation,
                ]}
                onObjectSelected={onObjectSelected}
                onLessonStart={onLessonStarted}
                annotationState={annotationState}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>
    );
};
export {PhysicsResistivityOfAWireAframe};
