import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import './styles/textStyle.css';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IPhotosynthesisSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;
	actionCarbon: THREE.AnimationAction;
	actionOxygen: THREE.AnimationAction;
	actionLeaf: THREE.AnimationAction;
	actionStomata: THREE.AnimationAction;
	actionRoots: THREE.AnimationAction;
	actionLamp: THREE.AnimationAction;
	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;
	groupElement: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;

	el: AFrame.Entity;
	CO2: THREE.Object3D<THREE.Object3DEventMap> | undefined;
	O2: THREE.Object3D<THREE.Object3DEventMap> | undefined;

	carbonTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	oxygenTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	leafTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	stomataTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	rootsTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;
	lampTriggerBtn: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>> | null;

	carbonButtonHandler: () => void;
	oxygenButtonHandler: () => void;
	leafButtonHandler: () => void;
	stomataButtonHandler: () => void;
	rootsButtonHandler: () => void;
	lampButtonHandler: () => void;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const PhotosynthesisScene = {
	name: 'plants-scene',
	val: {
		init(this: IPhotosynthesisSceneAframe) {
			var isInitialised = false
			this.el.addEventListener('model-loaded', () => {
				this.groupElement = document.getElementById('photosynthesisPlants') as AFrame.Entity;
				if (!isInitialised) {
					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {

						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {

							// initialiseButtons();
						});
					}
					initialiseAnimations();
					
					this.CO2 = this.groupElement.object3D.getObjectByName('CO2');

					if (this.CO2) { this.CO2.visible = false }
					if (this.O2) { this.O2.visible = false }

					isInitialised = true
				}
				else return

			});

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
			})



			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				// console.log('Event recenter received')

				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: plantsHolder; scale: 3 3 3;');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}

			});

			this.el.sceneEl?.addEventListener('annotation-close', () => {
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					// remove the line
					this.annotationComponent.deactivate();
				}
			})

			const initialiseAnimations = () => {
				this.groupElement = document.getElementById('photosynthesisPlants') as AFrame.Entity;
				// this.groupElement.object3D.traverse((child) => {
				// 	child.animations.length > 0 && console.log(child);
				// });
				if (this.groupElement) {
					this.groupElement.addEventListener('model-loaded', () => {
						const animatedPlants = this.groupElement?.object3D.getObjectByName('Biology7_PhotosynthesisTheory_AR') as any;

						this.mixer = new THREE.AnimationMixer(animatedPlants);

						const carbonAnimation = animatedPlants.animations[0];
						const oxygenAnimation = animatedPlants.animations[1];
						const leafAnimation = animatedPlants.animations[2];
						const stomataAnimation = animatedPlants.animations[3];
						const rootsAnimation = animatedPlants.animations[4];

						this.actionCarbon = this.mixer.clipAction(carbonAnimation);
						this.actionOxygen = this.mixer.clipAction(oxygenAnimation);
						this.actionLeaf = this.mixer.clipAction(leafAnimation);
						this.actionStomata = this.mixer.clipAction(stomataAnimation);
						this.actionRoots = this.mixer.clipAction(rootsAnimation);
					})

				}

			};

			this.carbonButtonHandler = () => {

				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.CO2) { this.CO2.visible = true }
				this.currentClip = this.actionCarbon;
				this.actionCarbon.reset()
				this.actionCarbon.repetitions = 1
				this.actionCarbon.clampWhenFinished = false
				this.actionCarbon.play()

				if (this.onObjectSelected) {
					const title = 'Carbon Dioxide';
					const body = '<p>CO<span class="superscript">2</span> is a gas molecule made of two oxygen and one carbon atom. It is a reactant of photosynthesis. It must enter the leaves through the stomata for photosynthesis to occur.</p><p><b>6CO<span class="subscript">2</span></b> + 6H<span class="subscript">2</span>O → 6O<span class="subscript">2</span> + C<span class="subscript">6</span>H<span class="subscript">12</span>O<span class="subscript">6</span></p>';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.oxygenButtonHandler = () => {

				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.O2) { this.O2.visible = true }
				this.currentClip = this.actionOxygen;
				this.actionOxygen.reset()
				this.actionOxygen.repetitions = 1
				this.actionOxygen.clampWhenFinished = false
				this.actionOxygen.play()
				if (this.onObjectSelected) {
					const title = 'Oxygen';
					const body = '<p>O<span class="subscript">2</span> is a gas molecule made of two oxygen atoms. Oxygen is a product of photosynthesis that is essential for life on earth.</p><p>6CO<span class="subscript">2</span> + 6H<span class="subscript">2</span>O → <b>6O<span class="subscript">2</span></b>  + C<span class="subscript">6</span>H<span class="subscript">12</span>O<span class="subscript">6</span></p>';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.leafButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionLeaf;
				this.actionLeaf.reset()
				this.actionLeaf.repetitions = 1
				this.actionLeaf.clampWhenFinished = false
				this.actionLeaf.play()
				
				if (this.onObjectSelected) {
					const title = 'Chloroplasts';
					const body = '<p>Leaves contain chloroplasts. These are the organelles responsible for photosynthesis. They contain the green pigment called chlorophyll which absorbs sunlight.</p><p>6CO<span class="subscript">2</span> + 6H<span class="subscript">2</span>O <img style="height: 26px; width: 50px;"  src="https://bridgear.blob.core.windows.net/public/Biology/Photosynthesis/chem_part.png"></img> 6O<span class="subscript">2</span> + C<span class="subscript">6</span>H<span class="subscript">12</span>O<span class="subscript">6</span></p>';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.stomataButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionStomata;
				this.actionStomata.reset()
				this.actionStomata.repetitions = 1
				this.actionStomata.clampWhenFinished = false
				this.actionStomata.play()
				
				if (this.onObjectSelected) {
					const title = 'Stomata';
					const body = '<p>Stomata are mostly found on the bottom of leaves. They are responsible for gas exchange, which is controlled by the opening and closing of guard cells that surround each stoma.</p><p><b>6CO<span class="subscript">2</span></b> + 6H<span class="subscript">2</span>O → <b>6O<span class="subscript">2</span></b> + C<span class="subscript">6</span>H<span class="subscript">12</span>O<span class="subscript">6</span></p>';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.lampButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}

				if (this.onObjectSelected) {
					const title = 'Light source';
					const body = "<p>Light is essential for photosynthesis, its energy is captured by chlorophyll and utilized to convert CO<span class='subscript'>2</span> and water to oxygen and glucose.</p><p>6CO<span class='subscript'>2</span> + 6H<span class='subscript'>2</span>O <img height='26px' src='https://bridgear.blob.core.windows.net/public/Biology/Photosynthesis/chem_part.png'></img> 6O<span class='subscript'>2</span> + C<span class='subscript'>6</span>H<span class='subscript'>12</span>O<span class='subscript'>6</span></p>";
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.rootsButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionRoots;
				this.actionRoots.reset()
				this.actionRoots.repetitions = 1
				this.actionRoots.clampWhenFinished = false
				this.actionRoots.play()

				if (this.onObjectSelected) {
					const title = 'Roots';
					const body = '<p>Roots absorb the water from the soil. Water is a reactant in photosynthesis and is vital for the process to occur.Water is needed for photosynthesis to occur, it is a reactant in the process.</p><p>6CO<span class="subscript">2</span> + <b>6H<span class="subscript">2</span>O</b> → 6O<span class="subscript">2</span> + C<span class="subscript">6</span>H<span class="subscript">12</span>O<span class="subscript">6</span></p>';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.carbonTriggerBtn = poolButtons.requestEntity()
				this.carbonTriggerBtn?.object3D.position.set(-1.32, 2.6, 0.2)
				this.carbonTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.carbonTriggerBtn?.play()
				this.carbonTriggerBtn?.addEventListener('click', () => {
					this.carbonButtonHandler();
					if (this.carbonTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.carbonTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.carbonTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.carbonTriggerBtn
					}
				});

				this.oxygenTriggerBtn = poolButtons.requestEntity()
				this.oxygenTriggerBtn?.object3D.position.set(-0.4, 3, 0.15)
				this.oxygenTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.oxygenTriggerBtn?.play()
				this.oxygenTriggerBtn?.addEventListener('click', () => {
					this.oxygenButtonHandler();
					if (this.oxygenTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.oxygenTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.oxygenTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.oxygenTriggerBtn
					}
				});


				this.leafTriggerBtn = poolButtons.requestEntity()
				this.leafTriggerBtn?.object3D.position.set(0, 1.9, 0)
				this.leafTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.leafTriggerBtn?.play()
				this.leafTriggerBtn?.addEventListener('click', () => {
					this.leafButtonHandler();
					if (this.leafTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.leafTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.leafTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.leafTriggerBtn
					}
				});

				this.stomataTriggerBtn = poolButtons.requestEntity()
				this.stomataTriggerBtn?.object3D.position.set(0.53, 1.57, 0.3)
				this.stomataTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.stomataTriggerBtn?.play()
				this.stomataTriggerBtn?.addEventListener('click', () => {
					this.stomataButtonHandler();
					if (this.stomataTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.stomataTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.stomataTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.stomataTriggerBtn
					}
				});

				this.rootsTriggerBtn = poolButtons.requestEntity()
				this.rootsTriggerBtn?.object3D.position.set(0, 0.45, 0.3)
				this.rootsTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.rootsTriggerBtn?.play()
				this.rootsTriggerBtn?.addEventListener('click', () => {
					this.rootsButtonHandler();
					if (this.rootsTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.rootsTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.rootsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.rootsTriggerBtn
					}
				});

				this.lampTriggerBtn = poolButtons.requestEntity()
				this.lampTriggerBtn?.object3D.position.set(-1.35, 1.8, 0.3)
				this.lampTriggerBtn?.object3D.scale.set(0.5, 0.5, 0.5)
				this.lampTriggerBtn?.play()
				this.lampTriggerBtn?.addEventListener('click', () => {
					this.lampButtonHandler();
					if (this.lampTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.lampTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(this.lampTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = this.lampTriggerBtn
					}
				});
			}
		},
		tick(this: IPhotosynthesisSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}

		},
	},
};
export { PhotosynthesisScene as PhotosynthesisSceneComponent }