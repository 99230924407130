import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Acknowledgment } from './part/Acknowledgment';
import { Changes } from './part/Changes';
import { ContactUs } from './part/ContactUs';
import { Disclaimer } from './part/Disclaimer';
import { DisputesResolution } from './part/DisputesResolution';
import { EuropeanUsers } from './part/EuropeanUsers';
import { GoverningLaw } from './part/GoverningLaw';
import { InterpretationAndDefinitions } from './part/InterpretationAndDefinitions';
import { LimitationOfLiability } from './part/LimitationOfLiability';
import { LinksToOtherWebsites } from './part/LinksToOtherWebsites';
import { SeverabilityAndWaiver } from './part/SeverabilityAndWaiver';
import { Termination } from './part/Termination';
import { TranslationInterpretation } from './part/TranslationInterpretation';
import { UnitedStatesLegalCompliance } from './part/UnitedStatesLegalCompliance';
import { Footer } from '../../../shared/components/Footer';
import { useTranslation } from 'react-i18next';
import Header from '../../../shared/components/Header';

export function TermsAndConditionsPage() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const i18nPath = 'TERMS_AND_CONDITIONS_PAGE.';

  return (
    <Box bgcolor={theme.palette.background.default}>
      <Header />
      <Box maxWidth={1020} marginX='auto'>
        <Stack padding={4.2} spacing={2} justifyContent='center'>
          <Typography
            fontFamily='GilroyBold'
            variant='h3'
            color={theme.palette.text.primary}
          >
            {t(i18nPath + 'TITLE')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_1')}
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t(i18nPath + 'TEXT_2')}
          </Typography>
          <InterpretationAndDefinitions />
          <Acknowledgment />
          <LinksToOtherWebsites />
          <Termination />
          <LimitationOfLiability />
          <Disclaimer />
          <GoverningLaw />
          <DisputesResolution />
          <EuropeanUsers />
          <UnitedStatesLegalCompliance />
          <SeverabilityAndWaiver />
          <TranslationInterpretation />
          <Changes />
          <ContactUs />
        </Stack>
      </Box>
      <Footer onMainPage={false} />
    </Box>
  );
}
