
import { AFrame } from 'aframe';
import * as THREE from 'three';

interface ITapPlaceAframeElement {
    el: AFrame['AEntity'];
    threeCamera: THREE.Camera;
    onTapHandler: () => void;
  }

const TapPlaceScreen = {
    name: 'tap-place-screen',
    val: {
        init(this: ITapPlaceAframeElement) {
            const lessonStartEvent = new Event('lesson-start')
            const camera = document.getElementById('camera') as AFrame['AEntity'];
            this.threeCamera = camera.getObject3D('camera') as THREE.Camera;

            // this component works with the object it's attached to
            const objectToPlace = this.el;
            const relativePosition = objectToPlace.getAttribute('position');
            const relativeRotation = objectToPlace.getAttribute('rotation');
            const scale = objectToPlace.getAttribute('scale');
            const relativeScale = new THREE.Vector3(1, 1, 1);
            relativeScale.copy(scale);
            

            //minimise object when starting tap-place session
            if (objectToPlace) {
              objectToPlace.setAttribute('scale', '0.001 0.001 0.001');
            }

            const generateAnimationString = (fromScale: string, toScale: string, duration: number) => {
              return `property: scale; from: ${fromScale}; to: ${toScale}; dur: ${duration}; easing: linear`;
            }

            this.onTapHandler = () => {
                const objectToPlace = this.el;
                const cameraEl = this.el.sceneEl?.querySelector('a-camera');
                if (cameraEl) {
                    // Get the camera's Y rotation.
    			    const cameraRotation = cameraEl.getAttribute('rotation') as unknown as THREE.Euler;
				    const cameraRotationY = cameraRotation ? cameraRotation.y : 0
    			    // Get the initial rotation of the element.
    			    const elementRotation = relativeRotation;
    			    // Set the element's Y rotation to match the camera's Y rotation.
    			    this.el.setAttribute('rotation', {
    			        x: elementRotation.x,
    			        y: cameraRotationY,
    			        z: elementRotation.z
    			    })

				    const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
				    // Create an offset vector.
    			    const offset = new THREE.Vector3();
                    offset.subVectors(relativePosition, camPos);
				    offset.applyQuaternion(this.el.object3D.quaternion)
    			    // Add the offset to the camera's position.
    			    const newPosition = camPos.clone().add(offset)
    			    // Set the position of the element with the offset.
    			    this.el.setAttribute('position', newPosition);
                }
                
                //animating scale
                const fromScale = '0.001 0.001 0.001';
                const toScale = `${relativeScale.x} ${relativeScale.y} ${relativeScale.z}`;
                const duration = 350; // animation duration in milliseconds
                const animationString = generateAnimationString(fromScale, toScale, duration);
                objectToPlace.setAttribute('animation', animationString);
                objectToPlace.setAttribute('visible', 'true');
                objectToPlace.addEventListener('animationcomplete', () => {
                  objectToPlace.removeAttribute('animation');
                });
            
                this.el.sceneEl?.dispatchEvent(lessonStartEvent); // start lesson experience

            };
            
            this.el.sceneEl?.addEventListener('tap-screen-button', this.onTapHandler);
        },
    },
};
export { TapPlaceScreen}