import type { Actions, State } from '../state';
import type { Dispatch } from 'react';
import { createContext } from 'react';

export type TTrainingsContext = [
  state: State,
  dispatch: Dispatch<Actions | Promise<Actions>>
];

export const TrainingsContext = createContext<TTrainingsContext | undefined>(
  undefined
);
