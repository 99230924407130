import React, { useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { LessonSceneComponent } from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  MODEL3D_HINT,
} from 'shared/constants/hint-items';
import { TapPlaceAir } from 'lib/aframe/components/tap-place-air';
import { MaskedAnimation } from './masked-animation';
import { DynamicLine } from './dynamic-line';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
  'Explore the annotations to learn about the digestive system organs.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const BiologyDigestiveSystemAframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
    image?: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState(false);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };
  
  const onLessonStarted = () => {
    setLessonStarted(true);
  };

  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  }
  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        onAnnotationStateChanged={onAnnotationStateChanged}
        lessonStarted={lessonStarted}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./bio-digestive-system-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          TapPlaceAir,
          WorldButtonComponent,
          FaceCameraComponent,
          LessonSceneComponent,
          MaskedAnimation,
          Annotation,
          DynamicLine
        ]}
        onObjectSelected={onObjectSelected}
        annotationState={annotationState}
        onLessonStart={onLessonStarted}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { BiologyDigestiveSystemAframe };
