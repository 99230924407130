import './App.scss';
import {ThemeProvider} from '@mui/material';
import {Fragment} from 'react';
import {
    Routes,
    Route,
    BrowserRouter,
} from 'react-router-dom';
import { theme } from './theme';
import { CoursesRoutes } from './features/Сourses/CoursesRoutes';
import { SharedContextProvider } from './shared/context';
import { TrainingsContextProvider } from './features/Сourses/context';
import { CommitmentRoutes } from './features/CommitmentPage';
import { BiologySimulationsRoutes } from 'views/biology/BiologySimulationsRoutes';
import { ChemistrySimulationsRoutes } from 'views/chemistry/ChemistrySimulationsRoutes';
import { PhysicsSimulationsRoutes } from 'views/physics/PhysicsSimulationsRoutes';
import HomePageComponent from './features/HomePage/HomePage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SharedContextProvider>
        <TrainingsContextProvider>
          <BrowserRouter>
            <Fragment>
              <CoursesRoutes />
              <CommitmentRoutes />
              <BiologySimulationsRoutes />
              <ChemistrySimulationsRoutes />
              <PhysicsSimulationsRoutes />
              <Routes>
                <Route path='/' element={<HomePageComponent />} />         
              </Routes>
            </Fragment>
          </BrowserRouter>
        </TrainingsContextProvider>
      </SharedContextProvider>
    </ThemeProvider>
  );
}

export default App;
