import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function InterpretationAndDefinitions() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const i18nPath = 'TERMS_AND_CONDITIONS_PAGE.INTERPRETATION_AND_DEFINITIONS.';

  return (
    <>
      <Typography
        fontFamily='GilroyBold'
        variant='h4'
        color={theme.palette.text.primary}
      >
        {t(i18nPath + 'TITLE')}
      </Typography>
      <Typography variant='h5' color={theme.palette.text.primary}>
        {t(i18nPath + 'TEXT_1')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        {t(i18nPath + 'TEXT_2')}
      </Typography>
      <Typography variant='h5' color={theme.palette.text.primary}>
        {t(i18nPath + 'TEXT_3')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        {t(i18nPath + 'TEXT_4')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        <Typography component='span' color={theme.palette.text.primary}>
          <strong>{t(i18nPath + 'TEXT_5')}</strong>
        </Typography>{' '}
        {t(i18nPath + 'TEXT_6')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        <Typography component='span' color={theme.palette.text.primary}>
          <strong>{t(i18nPath + 'TEXT_7')}</strong>
        </Typography>{' '}
        {t(i18nPath + 'TEXT_8')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        <Typography component='span' color={theme.palette.text.primary}>
          <strong>{t(i18nPath + 'TEXT_9')}</strong>
        </Typography>{' '}
        {t(i18nPath + 'TEXT_10')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        <Typography component='span' color={theme.palette.text.primary}>
          <strong>{t(i18nPath + 'TEXT_11')}</strong>
        </Typography>{' '}
        {t(i18nPath + 'TEXT_12')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        <Typography component='span' color={theme.palette.text.primary}>
          <strong>{t(i18nPath + 'TEXT_13')}</strong>
        </Typography>{' '}
        {t(i18nPath + 'TEXT_14')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        <Typography component='span' color={theme.palette.text.primary}>
          <strong>{t(i18nPath + 'TEXT_15')}</strong>
        </Typography>{' '}
        {t(i18nPath + 'TEXT_16')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        <Typography component='span' color={theme.palette.text.primary}>
          <strong>{t(i18nPath + 'TEXT_17')}</strong>
        </Typography>{' '}
        {t(i18nPath + 'TEXT_18')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        <Typography component='span' color={theme.palette.text.primary}>
          <strong>{t(i18nPath + 'TEXT_19')}</strong>
        </Typography>{' '}
        {t(i18nPath + 'TEXT_20')}
      </Typography>
      <Typography variant='body1' color={theme.palette.text.secondary}>
        <Typography component='span' color={theme.palette.text.primary}>
          <strong>{t(i18nPath + 'TEXT_21')}</strong>
        </Typography>{' '}
        {t(i18nPath + 'TEXT_22')}
      </Typography>
    </>
  );
}
