import React, { useRef, useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { TapPlaceComponent } from '../../../lib/aframe/components/tap-place-object';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { CircuitControlComponent } from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  MODEL3D_HINT,
} from 'shared/constants/hint-items';
import Diod from '../../../assets/img/Diode.png';
import Resistor from '../../../assets/img/Resistor.png';
import Bulb from '../../../assets/img/Bulb.png';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
  'In the drop down menu, select a component to investigate. Use the slider to vary the current and potential difference and see how these are plotted in the graph next to the circuit.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const assetIcons = [Resistor, Bulb, Diod];

const PhysicsIVCharacteristicsAframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState<boolean>(false);
  const [sliderLoaded] = useState<{
    title: string;
    range: number[];
    step: number;
    firstValue: number;
  } | null>({
    title: 'Variable Resistor',
    range: [1, 5],
    step: 1,
    firstValue: 1,
  });

  const [assetId, setAssetId] = useState(0);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
  const changeAsset = (assetId: number) => {
    setAssetId(assetId);
  };

  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };

  const onLessonStarted = () => {
    setLessonStarted(true);
  };

  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };

  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };

  const assetChangeEventRef = useRef(
    new CustomEvent('asset-change', { detail: { assetId: 0 } })
  );
  React.useEffect(() => {
    // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      console.log('Scene loaded');
      // Update the assetChangeEvent detail before dispatching
      assetChangeEventRef.current.detail.assetId = assetId;
      scene.dispatchEvent(assetChangeEventRef.current);
    }
  }, [assetId]);

  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        sliderLoad={sliderLoaded}
        lessonStarted={lessonStarted}
        onAnnotationStateChanged={onAnnotationStateChanged}
        hintItems={hintItems}
        assetIcons={assetIcons}
        onAssetButtonClick={changeAsset}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./physics-i-v-characteristics-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          CircuitControlComponent,
          TapPlaceComponent,
          WorldButtonComponent,
          FaceCameraComponent,
          Annotation,
        ]}
        onObjectSelected={onObjectSelected}
        onLessonStart={onLessonStarted}
        annotationState={annotationState}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { PhysicsIVCharacteristicsAframe };
