// ImageAnnotationContainer.tsx
import React, {Fragment} from 'react';
import closeIcon from '../../assets/img/Close.png';
import '../styles/imageAnnotation.css';
import '../styles/transitions.css';

interface Props {
    image: string | undefined;
    onCloseClick: () => void;
    visible: boolean;
}

const ImageAnnotationContainer: React.FC<Props> = ({image, onCloseClick, visible}) => {
    const visibilityClass = visible ? '' : 'shrink-complete';

    return (
        <Fragment>
            <div id="imageAnnotationContainer" className={`${visibilityClass}`}>
                <img className="closeImage" src={closeIcon} alt="Quiz" onClick={onCloseClick}/>
                <img className={'annotationImage'} src={image} alt="annotation"/>
            </div>
        </Fragment>
    );
};

export default ImageAnnotationContainer;
