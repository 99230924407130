import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';
import {IParticleImpulseAframe} from "./particle-impulse";
import {ISecondParticleImpulseAframe} from "./second-particle-impulse";
import {IThirdParticleImpulseAframe} from "./third-particle-impulse";
import {IFourthParticleImpulseAframe} from "./fourth-particle-impulse";
import {IFifthParticleImpulseAframe} from "./fifth-particle-impulse";

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface ICellSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System>>>;
    annotationComponent: IAnnotationAframe;
    interval: any;
    nerveCellButtonHandler: () => void;
    myelinSheathButtonHandler: () => void;
    synapseButtonHandler: () => void;
    dendritesButtonHandler: () => void;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    buttonsInitialised: boolean;
    axonButtonHandler: () => void;
    nerveHighlight: THREE.AnimationAction;
    secondNerveHighlight: THREE.AnimationAction;
    humanNerveHighlight: THREE.AnimationAction;
    whaleNerveHighlight: THREE.AnimationAction;
    particleAction: THREE.AnimationAction;
    mixer: THREE.AnimationMixer;
    secondNerveMixer: THREE.AnimationMixer;
    humanCellMixer: THREE.AnimationMixer;
    whaleCellmixer: THREE.AnimationMixer;
    el: AFrame.Entity;
}

const CellScene = {
    name: 'cell-scene',
    val: {
        init(this: ICellSceneAframe) {
            const cell = document.getElementById('nerveCell') as AFrame.Entity;
            cell.object3D.position.set(0, 4, 0)
            cell.addEventListener('model-loaded', () => {
                const nerveCell = document.getElementById('nerveCell') as AFrame.Entity;
                const cellHolder = document.getElementById('cellHolder') as AFrame.Entity;
                cellHolder.setAttribute('particle-impulse', '')
                cellHolder.setAttribute('second-particle-impulse', '')
                cellHolder.setAttribute('third-particle-impulse', '')
                cellHolder.setAttribute('fourth-particle-impulse', '')
                cellHolder.setAttribute('fifth-particle-impulse', '')

                const animatedEl = nerveCell.object3D.getObjectByName('NerveCell_AR') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl)
                const [NerveHighlight] = animatedEl.animations
                this.nerveHighlight = this.mixer.clipAction(NerveHighlight)
                this.nerveHighlight.setDuration(3)
                this.nerveHighlight.play()
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

                // when model is loaded adding recenter functionality
                this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                    // position this el in fron of camera with the following offset "3 4.5 -5"
                    // Get the camera and the desired offset.
                    // Get the A-Frame camera component.
                    const cameraEl = this.el.sceneEl?.querySelector('a-camera');

                    // Set the position of the element to the camera's position.

                    if (cameraEl) {
                        // console.log('Camera found: ', cameraEl.getAttribute('rotation'));
                        // console.log('Rotation before: ', this.el.getAttribute('rotation'));
                        // Get the camera's Y rotation.
                        const cameraRotation = cameraEl.getAttribute('rotation') as unknown as THREE.Euler;
                        const cameraRotationY = cameraRotation ? cameraRotation.y : 0;

                        // Get the current rotation of the element.
                        const elementRotation = this.el.getAttribute('rotation');

                        // Set the element's Y rotation to match the camera's Y rotation.
                        this.el.setAttribute('rotation', {
                            x: elementRotation.x,
                            y: cameraRotationY,
                            z: elementRotation.z
                        });
                        // console.log('Rotation after: ', this.el.getAttribute('rotation'))
                        const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                        // Create an offset vector.
                        const offset = new THREE.Vector3(0, -8, -8);
                        offset.applyQuaternion(this.el.object3D.quaternion);

                        // Add the offset to the camera's position.
                        const newPosition = camPos.clone().add(offset);
                        const secondCell = document.getElementById('secondNerveCell') as AFrame.Entity;
                        const humanNerveCell = document.getElementById('humanCell') as AFrame.Entity;
                        const whaleNerveCell = document.getElementById('blueWhaleCell') as AFrame.Entity;
                        humanNerveCell.object3D.visible = false
                        whaleNerveCell.object3D.visible = false
                        secondCell.object3D.visible = false
                        clearInterval(this.interval)
                        const cellHolder = document.getElementById('cellHolder') as AFrame.Entity;
                        cellHolder.object3D.scale.set(2, 2, 2)
                        // Set the position of the element with the offset.
                        this.el.setAttribute('position', newPosition);
                    }

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }

                });

            });
            const defaultCellPos = () => {
                const cellHolder = document.getElementById('cellHolder') as AFrame.Entity;
                const secondCell = document.getElementById('secondNerveCell') as AFrame.Entity;
                secondCell.object3D.visible = false
                cellHolder.object3D.position.set(0, 0, 0)
                cellHolder.object3D.scale.set(2, 2, 2)
            }
            const hideSideModels = () => {
                const humanNerveCell = document.getElementById('humanCell') as AFrame.Entity;
                const whaleNerveCell = document.getElementById('blueWhaleCell') as AFrame.Entity;
                humanNerveCell.object3D.visible = false
                whaleNerveCell.object3D.visible = false
            }
            const humanCell = document.getElementById('humanCell') as AFrame.Entity;
            humanCell.addEventListener('model-loaded', () => {
                const humanCell = document.getElementById('humanCell') as AFrame.Entity;
                const animatedEl = humanCell.object3D.getObjectByName('HumanNerve_AR') as any;
                this.humanCellMixer = new THREE.AnimationMixer(animatedEl)
                const [NerveHighlight] = animatedEl.animations
                this.humanNerveHighlight = this.humanCellMixer.clipAction(NerveHighlight)
                this.humanNerveHighlight.play()

            });
            const whaleCell = document.getElementById('blueWhaleCell') as AFrame.Entity;
            whaleCell.addEventListener('model-loaded', () => {
                const whaleCell = document.getElementById('blueWhaleCell') as AFrame.Entity;
                const animatedEl = whaleCell.object3D.getObjectByName('BlueWhale') as any;
                this.whaleCellmixer = new THREE.AnimationMixer(animatedEl)
                const [Whale_NerveHighlight] = animatedEl.animations
                this.whaleNerveHighlight = this.whaleCellmixer.clipAction(Whale_NerveHighlight)
                this.whaleNerveHighlight.play()
            });
            const secondCell = document.getElementById('secondNerveCell') as AFrame.Entity;
            secondCell.addEventListener('model-loaded', () => {
                const secondCell = document.getElementById('secondNerveCell') as AFrame.Entity;
                const animatedEl = secondCell.object3D.getObjectByName('NerveCell_AR') as any;
                this.secondNerveMixer = new THREE.AnimationMixer(animatedEl)
                const [NerveHighlight] = animatedEl.animations
                this.secondNerveHighlight = this.secondNerveMixer.clipAction(NerveHighlight)
                this.secondNerveHighlight.setDuration(3)
            });

            this.axonButtonHandler = () => {
                this.nerveHighlight.play()
                defaultCellPos()
                clearInterval(this.interval)
                const humanNerveCell = document.getElementById('humanCell') as AFrame.Entity;
                const whaleNerveCell = document.getElementById('blueWhaleCell') as AFrame.Entity;
                humanNerveCell.object3D.visible = true
                whaleNerveCell.object3D.visible = true
                if (this.onObjectSelected) {
                    const title = 'Axon';
                    const body = `The axon is long, it is an adaptation that helps nerve cells carry electrical impulses across the organism.<br/>The longest axon in a human body can reach 1 metre long! An axon in a whale can reach 30 metres long!`
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.dendritesButtonHandler = () => {
                hideSideModels()
                defaultCellPos()
                clearInterval(this.interval)
                if (this.onObjectSelected) {
                    const title = 'Dendrites';
                    const body = 'Dendrites receive impulses and then send them down the axon. They are numerous and branch out to make connections with other nerve cells. Dendrites link one nerve cell to the next.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.synapseButtonHandler = () => {
                hideSideModels()
                const cellHolder = document.getElementById('cellHolder') as AFrame.Entity;
                const secondCell = document.getElementById('secondNerveCell') as AFrame.Entity;
                secondCell.object3D.visible = true
                cellHolder.object3D.position.set(-1.8, 4.4, 0)
                cellHolder.object3D.scale.set(0.8, 0.8, 1)
                const animationsDelay = () => {
                    this.nerveHighlight.stop()
                    this.secondNerveHighlight.stop()
                    this.nerveHighlight.play()
                    setTimeout(() => {
                        this.nerveHighlight.stop()
                        // <----------------------------->
                        //place for particle sparks
                        const particleComponent = this.el.components['particle-impulse'] as unknown as IParticleImpulseAframe;
                        const secondParticleComponent = this.el.components['second-particle-impulse'] as unknown as ISecondParticleImpulseAframe;
                        const thirdParticleComponent = this.el.components['third-particle-impulse'] as unknown as IThirdParticleImpulseAframe;
                        const fourthParticleComponent = this.el.components['fourth-particle-impulse'] as unknown as IFourthParticleImpulseAframe;
                        const fifthParticleComponent = this.el.components['fifth-particle-impulse'] as unknown as IFifthParticleImpulseAframe;
                        if (particleComponent.canPlay) {
                            // play trigger animation
                            particleComponent.start()
                            secondParticleComponent.start()
                            thirdParticleComponent.start()
                            fourthParticleComponent.start()
                            fifthParticleComponent.start()
                        }
                        // <----------------------------->
                        setTimeout(() => {
                            this.secondNerveHighlight.play()
                        }, 2000)
                        setTimeout(() => {
                            this.secondNerveHighlight.stop()
                        }, 5000)
                    }, 4000)
                }
                animationsDelay()
                this.interval = setInterval(() => animationsDelay(), 10000)
                this.nerveHighlight.play();
                if (this.onObjectSelected) {
                    const title = 'Synapse';
                    const body = 'A synapse is the junction or gap between which impulses are transferred. This gap can be between two neurons, a neuron and a muscle, or a neuron and a gland. At a synapse, chemical neurotransmitters are released to the next neuron, gland or muscle.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.myelinSheathButtonHandler = () => {
                defaultCellPos()
                clearInterval(this.interval)
                hideSideModels()
                if (this.onObjectSelected) {
                    const title = 'Myelin sheath';
                    const body = 'The myelin sheath is a fatty substance that surrounds and insulates nerve fibres, which helps to increase the speed of the electrical impulses down the length of the nerve.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.nerveCellButtonHandler = () => {
                defaultCellPos()
                clearInterval(this.interval)
                hideSideModels()
                if (this.onObjectSelected) {
                    const title = 'Nerve cell';
                    const body = 'The function of the nerve cell is to carry electrical impulses around the body.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const dendritesriggerBtn = poolButtons.requestEntity()
                dendritesriggerBtn?.setAttribute('position', '0.2 4.9 0')
                dendritesriggerBtn?.setAttribute('scale', '2 2 2')
                dendritesriggerBtn?.play()
                dendritesriggerBtn?.addEventListener('click', () => {
                    this.dendritesButtonHandler()
                    if (dendritesriggerBtn) {
                        this.annotationComponent.setObjectToFollow(dendritesriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (dendritesriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = dendritesriggerBtn
                    }
                });
                const axonTriggerBtn = poolButtons.requestEntity()
                axonTriggerBtn?.setAttribute('position', '0 2.2 0')
                axonTriggerBtn?.setAttribute('scale', '2 2 2')
                axonTriggerBtn?.play()
                axonTriggerBtn?.addEventListener('click', () => {
                    const humanCell = document.getElementById('humanHolder') as AFrame.Entity;
                    const blueWhaleCell = document.getElementById('blueWhaleHolder') as AFrame.Entity;
                    humanCell.object3D.visible = true
                    blueWhaleCell.object3D.visible = true
                    this.axonButtonHandler()
                    if (axonTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(axonTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (axonTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = axonTriggerBtn
                    }
                });
                const myelinSheathTriggerBtn = poolButtons.requestEntity()
                myelinSheathTriggerBtn?.setAttribute('position', '0.8 1.8 0')
                myelinSheathTriggerBtn?.setAttribute('scale', '2 2 2')
                myelinSheathTriggerBtn?.play()
                myelinSheathTriggerBtn?.addEventListener('click', () => {
                    this.myelinSheathButtonHandler()
                    if (myelinSheathTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(myelinSheathTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (myelinSheathTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = myelinSheathTriggerBtn
                    }
                });
                const synapseTriggerButton = poolButtons.requestEntity();
                synapseTriggerButton?.setAttribute('position', '2 1.4 0');
                synapseTriggerButton?.setAttribute('scale', '2 2 2');
                synapseTriggerButton?.play()
                synapseTriggerButton?.addEventListener('click', () => {
                    this.synapseButtonHandler()
                    if (synapseTriggerButton) {
                        this.annotationComponent.setObjectToFollow(synapseTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (synapseTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = synapseTriggerButton

                    }
                });
                const nerveCellTriggerBtn = poolButtons.requestEntity()
                nerveCellTriggerBtn?.setAttribute('position', '1 3 0')
                nerveCellTriggerBtn?.setAttribute('scale', '2 2 2')
                nerveCellTriggerBtn?.play()
                nerveCellTriggerBtn?.addEventListener('click', () => {
                    this.nerveCellButtonHandler()
                    if (nerveCellTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(nerveCellTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (nerveCellTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = nerveCellTriggerBtn
                    }
                });

            };

        },
        tick(this: ICellSceneAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.0008);
            }
            if (this.humanCellMixer) {
                this.humanCellMixer.update(deltaTime * 0.001);
            }
            if (this.whaleCellmixer) {
                this.whaleCellmixer.update(deltaTime * 0.001);
            }
            if (this.secondNerveMixer) {
                this.secondNerveMixer.update(deltaTime * 0.0008);
            }
        },
    },
};
export {CellScene as CellSceneComponent}
