import React, { useRef, useState } from 'react';
import { AFrameScene, DISABLE_IMAGE_TARGETS } from '../../../lib/aframe-component';
import { Annotation, AnnotationState } from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';

import { TapPlaceCustom } from 'lib/aframe/components/tap-place-custom';
import { TapPlaceComponent } from '../../../lib/aframe/components/tap-place-object'

import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';


import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
    MODEL3D_HINT,
} from 'shared/constants/hint-items';

import { GoldFoilExperimentComponent } from './gold-foil-experiment-scene';
import { LoadersPermissonsEnum } from 'shared/enums';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const introText = 'Tap different parts of the experiment to investigate the findings. You can rotate the experiment by tapping and dragging it left or right.';

const PhysicsGoldFoilExperimentAframe = (() => {
    const [selectedObject, setSelectedObject] = useState<{ title: string; body: string; image?: string } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(AnnotationState.None);
    const [lessonStarted, setLessonStarted] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
    
    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };
    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };

    const onLesstonStarted = () => {
        setLessonStarted(true);
    };

    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };

    return (
        <React.Fragment>
            <LessonUI flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                onAnnotationStateChanged={onAnnotationStateChanged}
                lessonStarted={lessonStarted}
                hintItems={hintItems}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./physics-gold-foil-experiment-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[WorldButtonComponent, FaceCameraComponent, TapPlaceComponent, TapPlaceCustom, GoldFoilExperimentComponent, Annotation]}
                onObjectSelected={onObjectSelected}
                onLessonStart={onLesstonStarted}
                annotationState={annotationState}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>)
})
export { PhysicsGoldFoilExperimentAframe }