import { RequestService } from '../services';
import type { ContactUs } from '../typings/ContactUs';
import { URLS } from '../utils';

export async function contactusEffect(
  body: ContactUs
): Promise<{ status: 'success' | 'error' }> {
  try {
    const response = await RequestService.post<ContactUs, undefined>(
      URLS.contactUs,
      body
    );

    if (response.status === 200) {
      return { status: 'success' };
    }

    return { status: 'error' };
  } catch (err) {
    return { status: 'error' };
  }
}
