import React, {useRef, useState} from 'react';
import {
    AFrameScene,
    DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {FaceCameraComponent} from '../../../lib/aframe/components/face-camera';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import {AtomsSceneComponent} from './atoms-scene';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {TapPlaceAir} from 'lib/aframe/components/tap-place-air';
import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
    MODEL3D_HINT,
    PLAY_HINT,
} from 'shared/constants/hint-items';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
    'Use the arrows to see how the different parts of the atom are labelled in nuclide notation';

const hintItems = [
    ANNOTATION_HINT,
    QUIZ_HINT,
    INFO_HINT,
    PLAY_HINT,
    MODEL3D_HINT,
];

const PhysicsNuclideNotationALevelAframe = () => {
    const [toggle, setToggle] = useState(false);
    const [lessonStarted, setLessonStarted] = useState(false);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
    const [sliderArrowsLoaded,] = useState(
        {
            titles: ['Nuclide Notation', 'Element Symbol', 'Nucleon Number (A)', 'Proton Number (Z)', 'Number of Electrons', 'Number of Neutrons'],
            content: [
                'Instead of drawing hundreds of little circles to show atoms, scientists developed <b>nuclide notation</b> to clearly give all the information about an atom.',
                '<span style="font-size: 14px">The letter(s) are the element symbols which tell you what element the atom is. For example, ‘Li’ is the symbol for lithium. You’ll never be assessed on remembering which element has what symbol; some are not very obvious like tungsten which has an element symbol of ‘W’.</span>',
                'The top number is known as the <b>nucleon number (A)</b>, which is the number of protons plus the number of neutrons in the atom. The word nucleon describes particles in the nucleus, i.e. protons and neutrons.',
                'The bottom number is the proton number, the number of protons that are in the nucleus. Sometimes the atomic number is not shown as the number of protons is implied by the element symbol.',
                'Since atoms have no electric charge, for every positive proton there is also a negatively charged electron. This means the proton number is also how many electrons there are around the atom.',
                'The number of neutrons can be calculated by subtracting the proton number from the nucleon number, <b>(A - Z)</b> '
            ]
        }
    );
    const onLessonStarted = () => {
        setLessonStarted(true);
    };

    const onToggleAnimation = () => {
        setToggle(!toggle);
    };

    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    };

    const animToggleEventRef = useRef(
        new CustomEvent('anim-toggle', {detail: {toggle: false}})
    );
    React.useEffect(() => {
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            animToggleEventRef.current.detail.toggle = toggle;
            scene.dispatchEvent(animToggleEventRef.current);
        }
    }, [toggle]);
    return (
        <React.Fragment>
            <LessonUI
                flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                lessonStarted={lessonStarted}
                onToggleAnimation={onToggleAnimation}
                toggleAnimationStatus={toggle}
                hintItems={hintItems}
                sliderArrowsLoad={sliderArrowsLoaded}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./physics-nuclide-notation-a-level-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[
                    TapPlaceAir,
                    FaceCameraComponent,
                    AtomsSceneComponent,
                ]}
                onLessonStart={onLessonStarted}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>
    );
};
export {PhysicsNuclideNotationALevelAframe};
