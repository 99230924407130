import { Stack, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { IconImage } from '../../../shared/components/IconImage';
import { useMemo, useState } from 'react';
import { TabEnum } from '../enums';
import { DisplayEnum } from '../../../shared/enums';

import amazon from '../../../assets/amazon.svg';
import { useTranslation } from 'react-i18next';

export function CustomTab() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { t, i18n } = useTranslation();
  const i18nPath = 'LANDING_PAGE.QR_CODE_COMPONENT.CUSTOM_TAB_COMPONENT.';
  const [activeCard, setActiveCard] = useState(TabEnum.Posters);
  const cardText = useMemo(() => {
    switch (activeCard) {
      case TabEnum.Posters:
        return i18nPath + 'POSTERS.DESCRIPTION';
      case TabEnum.Stickers:
        return i18nPath + 'STICKERS.DESCRIPTION';
      case TabEnum.Cards:
        return i18nPath + 'FLASH_CARDS.DESCRIPTION';
    }
  }, [activeCard]);

  return (
    <Stack borderRadius='30px' bgcolor='rgba(30, 99, 190, .1)' p={3}>
      <Stack direction='row' justifyContent='space-between' mb={3}>
        <Box
          fontFamily='GilroyBold'
          fontSize={18}
          color='#262626'
          width={420}
          display='flex'
          alignItems={mobile ? 'start' : 'center'}
          justifyContent='space-between'
          flexDirection={mobile ? 'column' : 'row'}
        >
          <Box
            mb={mobile ? 1.5 : 0}
            padding={activeCard === TabEnum.Posters ? '8px 20px' : 0}
            color={activeCard === TabEnum.Posters ? '#fff' : '#262626'}
            borderRadius={activeCard === TabEnum.Posters ? '60px' : '0px'}
            bgcolor={activeCard === TabEnum.Posters ? '#9945FF' : 'transparent'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setActiveCard(TabEnum.Posters)}
          >
            {t(i18nPath + 'POSTERS.TITLE')}
          </Box>
          <Box
            mb={mobile ? 1.5 : 0}
            padding={activeCard === TabEnum.Stickers ? '8px 20px' : 0}
            color={activeCard === TabEnum.Stickers ? '#fff' : '#262626'}
            borderRadius={activeCard === TabEnum.Stickers ? '60px' : '0px'}
            bgcolor={
              activeCard === TabEnum.Stickers ? '#9945FF' : 'transparent'
            }
            sx={{ cursor: 'pointer' }}
            onClick={() => setActiveCard(TabEnum.Stickers)}
          >
            {t(i18nPath + 'STICKERS.TITLE')}
          </Box>
          <Box
            mb={mobile ? 1.5 : 0}
            padding={activeCard === TabEnum.Cards ? '8px 20px' : 0}
            color={activeCard === TabEnum.Cards ? '#fff' : '#262626'}
            borderRadius={activeCard === TabEnum.Cards ? '60px' : '0px'}
            bgcolor={activeCard === TabEnum.Cards ? '#9945FF' : 'transparent'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setActiveCard(TabEnum.Cards)}
          >
            {t(i18nPath + 'FLASH_CARDS.TITLE')}
          </Box>
        </Box>
        <Box>
          <IconImage src={amazon} alt='amazon' width='100px' />
        </Box>
      </Stack>
      <Stack>
        <Typography
          fontSize={16}
          fontFamily='GilroyMedium'
          color={theme.palette.text.secondary}
        >
          {t(cardText)}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default CustomTab;
