import { Route, Routes } from 'react-router-dom';
import { CourseContentPage } from './CourseContentPage/CourseContentPage';
import { CourseListPage } from './CoursesListPage/CourseListPage';

export function CoursesRoutes() {
  return (
    <Routes>
      <Route path='/simulations' element={<CourseListPage />} />
      <Route path='/simulations/:id' element={<CourseContentPage />} />
    </Routes>
  );
}
