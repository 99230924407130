import * as AFrame from "aframe";
import * as THREE from "three";

import { IMethodSystemAframe } from "lib/aframe/systems/method-system";
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {WorldButtonAframeInstance} from "../../../lib/aframe/components/world-button";

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface ISpaceScene {
    onStartDeploy: (...args: any[]) => void;
    onFinishDeploy: ((...args: any[]) => void) | undefined;
    telescopeBuildAnimation: any;
    lightRaysAnimation: THREE.AnimationAction;
    currentAnimation: THREE.AnimationAction;
    mixer: any;
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    onObjectSelected: ((...args: any[]) => void) | undefined;
    onLessonStart: (...args: any[]) => void;
    reflectingHandler: () => void;
    hexagonalHandler: () => void;
    sunshieldHandler: () => void;
    whiteDwarfHandler: () => void;
    nebulaHandler: () => void;
    diffractionHandler: () => void;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    el: AFrame.Entity;
};

const SpaceTelescopeAframe = {
    name: "space-scene",
    val: {
        init(this: ISpaceScene) {
            let numberOfModelsLoaded = 0;
            let isTelescopeDeployed = false; // used to control timing of initial deployment on lesson start
            let isDeployAnimationFinished = false; // used to control pause/play behaviour
            const modelsToLoad = 2;


            this.el.sceneEl?.addEventListener('model-loaded', () => {
                numberOfModelsLoaded++;
                if(numberOfModelsLoaded < modelsToLoad) {
                    return;
                };

                // setup callbacks
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "method-system": IMethodSystemAframe };
                };
                const methodSystem = scene.systems["method-system"];
                const callback = methodSystem.getCallback('onLessonStart');
                if (callback) {
                    this.onLessonStart = callback;
                }

                const callback2 = methodSystem.getCallback('onObjectSelected');
                if (callback2) {
                    this.onObjectSelected = callback2;
                }

                const callback3 = methodSystem.getCallback('onFinishDeploy');
                if (callback3) {
                    this.onFinishDeploy = callback3;
                }

                const callback4 = methodSystem.getCallback('onStartDeploy');
                if (callback4) {
                    this.onStartDeploy = callback4;
                }

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                // initialise animations
                initialiseAnimations();

                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                    initialiseTelescopeButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                        initialiseTelescopeButtons();
                    });
                };

                //change materials that require modifications
                updateMaterials();

                // update raycast settings
                updateRaycastSettings();

                // start deploying telescope
                this.el.sceneEl?.addEventListener('lesson-start', () => {
                    if (!isTelescopeDeployed) {
                        onDeployTelescopeStart();
                    }
                });
                
            });

            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.onLessonStart();
                // if (!this.isAnimationInitialised) {
                //     initialiseAnimation();    
                // }
                // deployTelescope();
            });

            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.onFinishDeploy!(); // triggering pause behaviur on deployment animation
                // reset and pause light ray animation if it's active
                if (this.currentAnimation) {
                    this.currentAnimation.enabled = false;
                }

                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if(ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: holder; scale: 2 2 2; offset: 0 -9 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if(this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
            });

            this.el.sceneEl?.addEventListener('anim-toggle', (e) => {
                const event = e as CustomEvent<{ toggle: boolean }>;
                if (event.detail.toggle) {
                    this.el.sceneEl?.emit('annotation-close');
                    // disable light ray animation
                    this.lightRaysAnimation.enabled = false;
                    // playing animation
                    if (isDeployAnimationFinished) {
                        this.telescopeBuildAnimation.stop();
                    }

                    if (this.telescopeBuildAnimation.paused) {
                        this.telescopeBuildAnimation.paused = false;
                    }
                    this.telescopeBuildAnimation.play();
                    isDeployAnimationFinished = false;
                } else {
                    // pausing animation
                    this.telescopeBuildAnimation.paused = true;
                    
                }
            });

            const updateMaterials = () => {
                // updating universe material
                const universe = this.el.object3D.getObjectByName('EclipseForUniverse') as THREE.Mesh;
                const universeMat = universe.material as THREE.MeshPhongMaterial;
                const newMat = new THREE.MeshBasicMaterial({
                    map: universeMat.map,
                    transparent: true,
                });
                universe.material = newMat;
                universe.material.needsUpdate = true;

            };

            const updateRaycastSettings = () => {
                const telescope = document.getElementById('telescope') as AFrame.Entity;
                telescope.classList.remove('cantap'); // remove cantap class to avoid blocking buttons by animation meshes
            };

            const initialiseAnimations = () => {
                const telescope = document.getElementById('telescope') as AFrame.Entity;
                const animatedEl = telescope.object3D.getObjectByName('JWST') as any;
                this.mixer = new THREE.AnimationMixer(animatedEl);
                const [JWSTDeployRaw, LightReflection] = animatedEl.animations;
                const JWSTDeploy = THREE.AnimationUtils.subclip(JWSTDeployRaw, 'deploy', 0, 360);
                this.telescopeBuildAnimation = this.mixer.clipAction(JWSTDeploy);
                this.lightRaysAnimation = this.mixer.clipAction(LightReflection);

            };

            // actions on starting deploying telescope
            const onDeployTelescopeStart = () => {
                this.telescopeBuildAnimation.reset();
                this.telescopeBuildAnimation.setLoop(THREE.LoopOnce);
                this.telescopeBuildAnimation.clampWhenFinished = true;
                this.onStartDeploy();
                
                
                // add event listener for animation ending
                this.mixer.addEventListener("finished", (e: Event) => {
                    const custom = e as CustomEvent<{ action: THREE.AnimationAction }>;
                    if ('action' in custom) {
                        const finishedAction = custom.action as THREE.AnimationAction;
                        const clip = finishedAction.getClip();
                        if (clip.name === 'deploy') {
                            // callback to React to change animation button state 
                            this.onFinishDeploy!();
                            isDeployAnimationFinished = true;
                            if (!isTelescopeDeployed) {
                                isTelescopeDeployed = true;
                            }
                        }
                    }
                });
            };

            this.reflectingHandler = () => {
                if (this.currentAnimation) {
                    this.currentAnimation.stop()
                }
                this.currentAnimation = this.lightRaysAnimation
                this.telescopeBuildAnimation.enabled = false;
                this.lightRaysAnimation.paused = false
                this.lightRaysAnimation.enabled = true;
                this.lightRaysAnimation.setLoop(THREE.LoopOnce, 1);
                this.lightRaysAnimation.play()
                // pause animation after 1.6 seconds to keep light ray in view
                // clampWhenFinished doesn't work on this clip
                setTimeout(() => {
                    this.lightRaysAnimation.paused = true
                }, 1600)
                if (this.onObjectSelected) {
                    const title = 'Reflecting Telescopes';
                    const body = 'The large primary mirror is a concave parabolic shape, 6.5 m in diameter. It focuses waves from distant stars onto a small secondary mirror on the struts which reflects all the waves back into the detector. This is known as a cassegrain arrangement.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.hexagonalHandler = () => {
                if (this.currentAnimation) {
                    this.currentAnimation.reset()
                    this.currentAnimation.paused = true
                }
                if (this.onObjectSelected) {
                    const title = 'Hexagonal Mirrors';
                    const body = 'The primary mirror is made from 18 hexagonal mirrors that fit together; a genius way to build a huge mirror in space! Their gold coating is perfect for reflecting the infrared light that the telescope observes.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.sunshieldHandler = () => {
                if (this.currentAnimation) {
                    this.currentAnimation.reset()
                    this.currentAnimation.paused = true
                }
                if (this.onObjectSelected) {
                    const title = 'Sunshield';
                    const body = 'The large base of the telescope is a sunshield, blocking and reflecting the light and heat from the nearby Earth and Sun. It even shields the telescope from the heat of its own equipment which the instruments are sensitive enough to detect.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.whiteDwarfHandler = () => {
                if (this.currentAnimation) {
                    this.currentAnimation.reset()
                    this.currentAnimation.paused = true
                }
                if (this.onObjectSelected) {
                    const title = 'White Dwarf';
                    const body = 'This is one of the first images received back from the James Webb Space Telescope. The central star is a white dwarf, the extremely hot and dense core of a star that exploded in a powerful supernova.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }

            this.nebulaHandler = () => {
                if (this.currentAnimation) {
                    this.currentAnimation.reset()
                    this.currentAnimation.paused = true
                }
                if (this.onObjectSelected) {
                    const title = 'Planetary Nebula';
                    const body = 'In a supernova, the hot gas that once formed a star is expelled out like a large shockwave. The gas contains all the heavier elements formed in the star (like carbon and oxygen) that are essential for life as we know it. This is known as a planetary nebula.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.diffractionHandler = () => {
                if (this.currentAnimation) {
                    this.currentAnimation.reset()
                    this.currentAnimation.paused = true
                }
                if (this.onObjectSelected) {
                    const title = 'Diffraction Spikes';
                    const body = 'Even a 10 billion dollar telescope has some flaws. The 8 sharp points (diffraction spikes) we see around bright stars are a consequence of the telescope’s signature hexagonal mirrors and its three struts that hold the secondary mirror.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                
                const whiteDwarfTriggerButton = poolButtons.requestEntity();
                whiteDwarfTriggerButton?.setAttribute('position', '1.2 5.1 -7.5');
                whiteDwarfTriggerButton?.setAttribute('scale', '2.25 2.25 2.25')
                
                whiteDwarfTriggerButton?.play()
                whiteDwarfTriggerButton?.addEventListener('click', () => {
                    this.whiteDwarfHandler()
                    if (whiteDwarfTriggerButton) {
                        this.annotationComponent.setObjectToFollow(whiteDwarfTriggerButton);
                        // this.annotationComponent.setLineVisible(false);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (whiteDwarfTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = whiteDwarfTriggerButton
                    }
                });
                const nebulaTriggerButton = poolButtons.requestEntity();
                nebulaTriggerButton?.setAttribute('position', '1.6 7.2 -8');
                nebulaTriggerButton?.setAttribute('scale', '2.25 2.25 2.25')
               
                nebulaTriggerButton?.play()
                nebulaTriggerButton?.addEventListener('click', () => {
                    this.nebulaHandler()
                    if (nebulaTriggerButton) {
                        this.annotationComponent.setObjectToFollow(nebulaTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (nebulaTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = nebulaTriggerButton
                    }
                });
                const diffractionTriggerButton = poolButtons.requestEntity();
                diffractionTriggerButton?.setAttribute('position', '-1.5 5 -8');
                diffractionTriggerButton?.setAttribute('scale', '2.25 2.25 2.25')
            
                diffractionTriggerButton?.play()
                diffractionTriggerButton?.addEventListener('click', () => {
                    this.diffractionHandler()
                    if (diffractionTriggerButton) {
                        this.annotationComponent.setObjectToFollow(diffractionTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (diffractionTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = diffractionTriggerButton
                    }
                });

            };

            const initialiseTelescopeButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool__telescope'] as PoolComponent;

                const reflectingTriggerBtn = poolButtons.requestEntity()
                reflectingTriggerBtn?.setAttribute('position', '0 0.6 1.15')
                reflectingTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const animToggle = customEvent.detail.toggle;
                    if (animToggle) {
                        reflectingTriggerBtn?.setAttribute('scale', '0 0 0')
                    } else {
                        if (isDeployAnimationFinished && isTelescopeDeployed) {
                            //only showing button if telescope is deployed
                            reflectingTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5')
                        }
                    }
                })
                reflectingTriggerBtn?.play()
                reflectingTriggerBtn?.addEventListener('click', () => {
                    this.reflectingHandler()
                    if (reflectingTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(reflectingTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (reflectingTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = reflectingTriggerBtn
                    }
                });
                const hexagonalTriggerBtn = poolButtons.requestEntity()
                hexagonalTriggerBtn?.setAttribute('position', '0 0.85 0.35')
                hexagonalTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const animToggle = customEvent.detail.toggle;
                    if (animToggle) {
                        hexagonalTriggerBtn?.setAttribute('scale', '0 0 0')
                    } else {
                        if (isDeployAnimationFinished && isTelescopeDeployed) {
                            //only showing button is telescope is deployed
                            hexagonalTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5')
                        }
                        
                    }
                })
                hexagonalTriggerBtn?.play()
                hexagonalTriggerBtn?.addEventListener('click', () => {
                    this.hexagonalHandler()
                    if (hexagonalTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(hexagonalTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (hexagonalTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = hexagonalTriggerBtn
                    }
                });
                const sunshieldTriggerBtn = poolButtons.requestEntity()
                sunshieldTriggerBtn?.setAttribute('position', '-0.425 0.2 0.75')
                sunshieldTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    const animToggle = customEvent.detail.toggle;
                    if (animToggle) {
                        sunshieldTriggerBtn?.setAttribute('scale', '0 0 0')
                    } else {
                        if (isDeployAnimationFinished && isTelescopeDeployed) {
                            //only showing button is telescope is deployed
                            sunshieldTriggerBtn?.setAttribute('scale', '0.5 0.5 0.5')
                        }
                    }
                })
                sunshieldTriggerBtn?.play()
                sunshieldTriggerBtn?.addEventListener('click', () => {
                    this.sunshieldHandler()
                    if (sunshieldTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(sunshieldTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (sunshieldTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = sunshieldTriggerBtn
                    }
                });
            };
    
        },
        tick(this: ISpaceScene, time: number, timeDelta: number) {
            if (this.mixer) {
                this.mixer.update(timeDelta / 1000);
            }
        },
    },
};
export { SpaceTelescopeAframe as SpaceScene};