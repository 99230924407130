import React, { useRef, useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { AtomsSceneComponent } from './atoms-scene';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';

import { TapPlaceScreen } from '../../../lib/aframe/components/tap-place-screen';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
  MODEL3D_HINT,
  PLAY_HINT,
} from 'shared/constants/hint-items';
import { LoadersPermissonsEnum } from 'shared/enums';

const introText =
  'Look around to discover the different ideas of atoms throughout history.';

const hintItems = [
  ANNOTATION_HINT,
  QUIZ_HINT,
  INFO_HINT,
  PLAY_HINT,
  MODEL3D_HINT,
];

const PhysicsHistoryOfTheAtomAframe = () => {
  const [toggle, setToggle] = useState(false);
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
  } | null>(null);
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState(false);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
  const onTapScreen = () => {
    const scene = document.querySelector('a-scene');
    if (scene) {
      scene.emit('tap-screen-button');
    }
  };

  const onLessonStarted = () => {
    setLessonStarted(true);
  };
  const onObjectSelected = (data: { title: string; body: string }) => {
    setSelectedObject(data);
  };

  const onToggleAnimation = () => {
    setToggle(!toggle);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };
  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };
  const animToggleEventRef = useRef(
    new CustomEvent('anim-toggle', { detail: { toggle: false } })
  );
  React.useEffect(() => {
    const scene = document.querySelector('a-scene');
    if (scene && scene.hasLoaded) {
      animToggleEventRef.current.detail.toggle = toggle;
      scene.dispatchEvent(animToggleEventRef.current);
    }
  }, [toggle]);
  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        selectedObject={selectedObject}
        lessonStarted={lessonStarted}
        onToggleAnimation={onToggleAnimation}
        toggleAnimationStatus={toggle}
        onTapScreen={onTapScreen}
        onAnnotationStateChanged={onAnnotationStateChanged}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./physics-history-of-the-atom-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          TapPlaceScreen,
          WorldButtonComponent,
          FaceCameraComponent,
          AtomsSceneComponent,
          Annotation,
        ]}
        onObjectSelected={onObjectSelected}
        annotationState={annotationState}
        onLessonStart={onLessonStarted}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { PhysicsHistoryOfTheAtomAframe };
