import { InputBase, styled } from '@mui/material';
import { DisplayEnum } from '../enums';

export const StyledLanguageInput = styled(InputBase)(({ theme }) => ({
  minWidth: '105px',
  height: '48px',
  backgroundColor: '#E8EFF9',
  borderRadius: '6px',
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: 18,
    padding: '10px 26px 10px 12px',
    fontFamily: 'GilroySemiBold',
    minHeight: 'auto !important',
  },
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
    minWidth: 'auto',
  },
}));
