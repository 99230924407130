import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface ICellsSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    tmvMixer: THREE.AnimationMixer;
    measlesMixer: THREE.AnimationMixer;
    hivMixer: THREE.AnimationMixer;
    tmvAnimation: THREE.AnimationAction
    measlesAnimation: THREE.AnimationAction
    hivAnimation: THREE.AnimationAction
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    buttonsInitialised: boolean;
    tmvButtonHandler: () => void;
    measlesButtonHandler: () => void;
    hivButtonHandler: () => void;
    tmvSymptomsButtonHandler: () => void;
    measlesSymptomsButtonHandler: () => void;
    hivTreatmentButtonHandler: () => void;
    tmv: AFrame.Entity;
    measles: AFrame.Entity;
    hiv: AFrame.Entity;
    el: AFrame.Entity;
    currentAssetId: number;
}

const CellScene = {
    name: 'cell-scene',
    val: {
        init(this: ICellsSceneAframe) {
            this.el.addEventListener('model-loaded', () => {
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded

            })
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                    if (!this.currentAssetId) this.currentAssetId = 0

                    if (this.poolEntity.hasLoaded) {
                        initialiseButtons();
                    } else {
                        this.poolEntity.addEventListener('loaded', () => {
                            initialiseButtons();
                        });
                    }
                    initialiseAnimation()
                }
            });
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: holder; scale: 5 5 5; offset: 0 -4 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                }
                this.tmvAnimation.play()
                this.measlesAnimation.play()
                this.hivAnimation.play()
            });
            this.el.sceneEl?.addEventListener('asset-change', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                this.currentAssetId = customEvent.detail.assetId;
                if (this.currentAssetId === 0) {
                    this.measles.object3D.visible = false
                    this.hiv.object3D.visible = false
                    this.tmv.object3D.visible = true
                }
                if (this.currentAssetId === 1) {
                    this.tmv.object3D.visible = false
                    this.measles.object3D.visible = true
                    this.hiv.object3D.visible = false
                }
                if (this.currentAssetId === 2) {
                    this.tmv.object3D.visible = false
                    this.measles.object3D.visible = false
                    this.hiv.object3D.visible = true
                }
            });
            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    this.tmvAnimation.paused = false
                    this.measlesAnimation.paused = false
                    this.hivAnimation.paused = false
                } else {
                    this.tmvAnimation.paused = true
                    this.measlesAnimation.paused = true
                    this.hivAnimation.paused = true
                }
            });
            this.tmvButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = '<span style="font-size: 22px">Tobacco Mosaic Virus (TMV)</span>';
                    const body = 'TMV is a plant virus that infects tobacco and other plants. It spreads through contact with infected plants, or by pests and farmers\' hands.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }
            this.tmvSymptomsButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Symptoms of TMV';
                    const body = 'Symptoms include mosaic-like patterns on the leaves, stunted growth, and yellowing of the leaves; this reduces farmers’ crop yield. There is no cure, but for prevention infected plants should be removed and destroyed to prevent the spread of the virus.'
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }

            this.measlesButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Measles';
                    const body = 'Measles is a highly contagious viral disease that spreads through respiratory droplets when an infected person coughs or sneezes.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }
            this.measlesSymptomsButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Symptoms of Measles';
                    const body = 'Symptoms include fever, cough, and a rash all over the body; it can be treated by antiviral medication. Complications of measles can be fatal. Nowadays for prevention, children are vaccinated against measles, especially in developed countries.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }
            this.hivButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = '<span style="font-size: 16px">Human Immunodeficiency Virus (HIV)</span>';
                    const body = 'HIV is a viral disease that is transmitted by bodily fluids, such as through sexual contact and sharing needles or syringes.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }
            this.hivTreatmentButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Symptoms of HIV';
                    const body = 'Over time, HIV can weaken the immune system and lead to acquired immunodeficiency syndrome (AIDS). Treatment involves antiretroviral therapy, medications that slow the progression of the virus and help people live longer and healthier lives.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')

                }
            }
            const initialiseAnimation = () => {
                this.tmv = document.getElementById('tobaccoMosaicVirus') as AFrame.Entity
                this.measles = document.getElementById('measles') as AFrame.Entity
                this.hiv = document.getElementById('hiv') as AFrame.Entity
                const animatedTMVEl = this.tmv.object3D.getObjectByName('Scene') as any;
                this.tmvMixer = new THREE.AnimationMixer(animatedTMVEl)
                const [TMVAnim] = animatedTMVEl.animations
                this.tmvAnimation = this.tmvMixer.clipAction(TMVAnim)
                this.tmvAnimation.play()
                const animatedMeaslesEl = this.measles.object3D.getObjectByName('Scene') as any;
                this.measlesMixer = new THREE.AnimationMixer(animatedMeaslesEl)
                const [MeaslesAnim] = animatedMeaslesEl.animations
                this.measlesAnimation = this.measlesMixer.clipAction(MeaslesAnim)
                const animatedHIVEl = this.hiv.object3D.getObjectByName('Scene') as any;
                this.hivMixer = new THREE.AnimationMixer(animatedHIVEl)
                const [HIVAnim] = animatedHIVEl.animations
                this.hivAnimation = this.hivMixer.clipAction(HIVAnim)
                this.tmvAnimation.play()
                this.measlesAnimation.play()
                this.hivAnimation.play()
            }

            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const tmvTriggerBtn = poolButtons.requestEntity()
                tmvTriggerBtn?.setAttribute('position', '-0.15 0.2 0.2')
                this.currentAssetId === 0 ? tmvTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3') : tmvTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        tmvTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                    }
                    if (this.currentAssetId === 1) {
                        tmvTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        tmvTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                tmvTriggerBtn?.play()
                tmvTriggerBtn?.addEventListener('click', () => {
                    this.tmvButtonHandler()
                    if (tmvTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(tmvTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (tmvTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = tmvTriggerBtn
                    }
                });
                const tmvSymptomsTriggerBtn = poolButtons.requestEntity()
                tmvSymptomsTriggerBtn?.setAttribute('position', '0.1 0.2 0.2')
                this.currentAssetId === 0 ? tmvSymptomsTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3') : tmvSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        tmvSymptomsTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                    }
                    if (this.currentAssetId === 1) {
                        tmvSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        tmvSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                tmvSymptomsTriggerBtn?.play()
                tmvSymptomsTriggerBtn?.addEventListener('click', () => {
                    this.tmvSymptomsButtonHandler()
                    if (tmvSymptomsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(tmvSymptomsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (tmvSymptomsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = tmvSymptomsTriggerBtn
                    }
                });
                const measlesTriggerBtn = poolButtons.requestEntity()
                measlesTriggerBtn?.setAttribute('position', '-0.1 0.1 0.2')
                measlesTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        measlesTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        measlesTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                    }
                    if (this.currentAssetId === 2) {
                        measlesTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                measlesTriggerBtn?.play()
                measlesTriggerBtn?.addEventListener('click', () => {
                    this.measlesButtonHandler()
                    if (measlesTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(measlesTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (measlesTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = measlesTriggerBtn
                    }
                });
                const measlesSymptomsTriggerBtn = poolButtons.requestEntity()
                measlesSymptomsTriggerBtn?.setAttribute('position', '0.1 0.1 0.2')
                measlesSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        measlesSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        measlesSymptomsTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                    }
                    if (this.currentAssetId === 2) {
                        measlesSymptomsTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                });
                measlesSymptomsTriggerBtn?.play()
                measlesSymptomsTriggerBtn?.addEventListener('click', () => {
                    this.measlesSymptomsButtonHandler()
                    if (measlesSymptomsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(measlesSymptomsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (measlesSymptomsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = measlesSymptomsTriggerBtn
                    }
                });
                const hivTriggerBtn = poolButtons.requestEntity()
                hivTriggerBtn?.setAttribute('position', '-0.1 0.1 0.2')
                hivTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        hivTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        hivTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        hivTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                    }
                });
                hivTriggerBtn?.play()
                hivTriggerBtn?.addEventListener('click', () => {
                    this.hivButtonHandler()
                    if (hivTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(hivTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (hivTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = hivTriggerBtn
                    }
                });
                const hivTreatmentTriggerBtn = poolButtons.requestEntity()
                hivTreatmentTriggerBtn?.setAttribute('position', '0.1 0.1 0.2')
                hivTreatmentTriggerBtn?.setAttribute('scale', '0 0 0')
                this.el.sceneEl?.addEventListener('asset-change', (event) => {
                    const customEvent = event as CustomEvent; // Cast event to CustomEvent
                    this.currentAssetId = customEvent.detail.assetId;
                    if (this.currentAssetId === 0) {
                        hivTreatmentTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 1) {
                        hivTreatmentTriggerBtn?.setAttribute('scale', '0 0 0')
                    }
                    if (this.currentAssetId === 2) {
                        hivTreatmentTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                    }
                });
                hivTreatmentTriggerBtn?.play()
                hivTreatmentTriggerBtn?.addEventListener('click', () => {
                    this.hivTreatmentButtonHandler()
                    if (hivTreatmentTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(hivTreatmentTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (hivTreatmentTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = hivTreatmentTriggerBtn
                    }
                });
            };
        },
        tick(this: ICellsSceneAframe, time: number, deltaTime: number) {
            if (this.tmvMixer) {
                this.tmvMixer.update(deltaTime * 0.001);
            }
            if (this.measlesMixer) {
                this.measlesMixer.update(deltaTime * 0.001);
            }
            if (this.hivMixer) {
                this.hivMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {CellScene as CellSceneComponent}
