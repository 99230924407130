import { TrainingStatusEnum } from './enums';

export const statusColorUtil = (type: string) => {
  switch (type) {
    case TrainingStatusEnum.New:
      return '255, 161, 67';
    case TrainingStatusEnum.Completed:
      return '255, 161, 67';
    case TrainingStatusEnum.InProgress:
      return '255, 161, 67';
  }
};
