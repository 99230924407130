import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import { IShaderFireAframe } from './shader-fire';
import { IShaderSmokeAframe } from './shader-smoke';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IEarthsGravitySceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	currentClips: THREE.AnimationAction[];
	mixer: THREE.AnimationMixer;
	el: AFrame.Entity;
	currentAssetId: number;
	poolButtons: PoolComponent;

	groupElement: AFrame.Entity;

	rocket: THREE.Object3D | undefined;

	cloudPlane: THREE.Object3D | undefined;

	positionATriggerBtn: AFrame.Entity | null;
	positionBTriggerBtn: AFrame.Entity | null;
	positionCTriggerBtn: AFrame.Entity | null;
	positionDTriggerBtn: AFrame.Entity | null;
	positionETriggerBtn: AFrame.Entity | null;
	positionFTriggerBtn: AFrame.Entity | null;

	positonAButtonHandler: () => void;
	positonBButtonHandler: () => void;
	positonCButtonHandler: () => void;
	positonDButtonHandler: () => void;
	positonEButtonHandler: () => void;
	positonFButtonHandler: () => void;


	actionRocket: THREE.AnimationAction;
	actionButton: THREE.AnimationAction;

	fireMain: IShaderFireAframe;
	fireR: IShaderFireAframe;
	fireL: IShaderFireAframe;
	smoke: IShaderSmokeAframe;

	fireMainStart: IShaderFireAframe;
	fireRStart: IShaderFireAframe;
	fireLStart: IShaderFireAframe;
	smokeStart: IShaderSmokeAframe;

	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const EarthsGravityScene = {
	name: 'experiment',
	val: {
		init(this: IEarthsGravitySceneAframe) {
			var isInitialised = false;
			this.currentClips = [];
			this.el.addEventListener('model-loaded', () => {
				if (!isInitialised) {
					this.groupElement = document.getElementById('model') as AFrame.Entity;

					// this.groupElement?.object3D.traverse((child) => {
					// 	console.log(child);
					// });

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {

						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					initialiseAnimations();
					isInitialised = true;
				}
				else return
			});

			const firstScene = () => {
				if (this.groupElement.hasAttribute('shader-fire')) {
					this.fireMain = this.groupElement.components['shader-fire'] as unknown as IShaderFireAframe;
					this.fireMain.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-smoke')) {
					this.smoke = this.groupElement.components['shader-smoke'] as unknown as IShaderSmokeAframe;
					this.smoke.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-fire_stage_l')) {
					this.fireL = this.groupElement.components['shader-fire_stage_l'] as unknown as IShaderFireAframe;
					this.fireL.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-fire_stage_r')) {
					this.fireR = this.groupElement.components['shader-fire_stage_r'] as unknown as IShaderFireAframe;
					this.fireR.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-fire-start')) {
					this.fireMainStart = this.groupElement.components['shader-fire-start'] as unknown as IShaderFireAframe;
					this.fireMainStart.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-smoke-start')) {
					this.smokeStart = this.groupElement.components['shader-smoke-start'] as unknown as IShaderSmokeAframe;
					this.smokeStart.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-fire_stage_l-start')) {
					this.fireLStart = this.groupElement.components['shader-fire_stage_l-start'] as unknown as IShaderFireAframe;
					this.fireLStart.setVisibility(false);
				}
				if (this.groupElement.hasAttribute('shader-fire_stage_r-start')) {
					this.fireRStart = this.groupElement.components['shader-fire_stage_r-start'] as unknown as IShaderFireAframe;
					this.fireRStart.setVisibility(false);
				}
			}

			this.el.sceneEl?.addEventListener('return-to-start', () => {

			})

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place')
					this.el.sceneEl?.removeChild(ring)
				}
				firstScene();
			})

			// when model is loaded adding recenter functionality
			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				this.el.sceneEl?.emit('recenter');
				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place', 'id: sceneHolder; offset: 0 0 0');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);

					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
					this.fireMain.setVisibility(false);
					this.fireL.setVisibility(false);
					this.fireR.setVisibility(false);
					this.smoke.setVisibility(false);

					this.fireMainStart.setVisibility(false);
					this.fireLStart.setVisibility(false);
					this.fireRStart.setVisibility(false);
					this.smokeStart.setVisibility(false);
				}
			});

			const initialiseAnimations = () => {
				const animatedEl = this.groupElement.object3D.getObjectByName('Scene') as any;

				this.mixer = new THREE.AnimationMixer(animatedEl);

				const showRocketAction = animatedEl.animations[0];
				const showButtonAction = animatedEl.animations[1];


				this.actionRocket = this.mixer.clipAction(showRocketAction);
				this.actionButton = this.mixer.clipAction(showButtonAction);

			};

			this.positonAButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Weight';
					const body = 'Weight is the name of the force due to gravity. An object with a large mass will have a larger weight pulling it down. On Earth, the force of gravity is always downwards, towards the centre of Earth.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.positonBButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Force Diagrams';
					const body = 'On a force diagram, forces are shown using arrows. The direction of the arrow shows which way the force is acting while the length of the arrow shows the size of the force, also known as its <b>magnitude</b>.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.positonCButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Newton’s Third Law';
					const body = 'When two objects interact, the forces they exert on each other are equal and opposite. So, when the rocket ejects gas from the exhaust with a huge force, the gas forces the rocket upwards; this force is known as thrust.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			this.positonDButtonHandler = () => {
				if (this.onObjectSelected) {
					const title = 'Newton’s First Law';
					const body = 'A stationary object will stay at rest until a <b>net</b> force acts on it. So, for the rocket to fly upwards, the upward force must be <b>greater</b> than the weight, i.e. there is a <b>net</b> force pointing upward.';
					this.onObjectSelected({ title, body });
				} else {
					console.log('No object selected method');
				}
			}

			var buttonEEnabled = true;
			this.positonEButtonHandler = () => {
				if (buttonEEnabled) {
					buttonEEnabled = false;
					this.currentClips[0] = this.actionButton;
					this.actionButton.reset();
					this.actionButton.repetitions = 1;
					this.actionButton.clampWhenFinished = false;
					this.actionButton.play();

					this.currentClips[1] = this.actionRocket;
					this.actionRocket.reset();
					this.actionRocket.repetitions = 1;
					this.actionRocket.clampWhenFinished = false;
					this.actionRocket.play();

					this.fireMainStart.setVisibility(true);
					this.fireRStart.setVisibility(true);
					this.fireLStart.setVisibility(true);
					this.smokeStart.setVisibility(true);

					setTimeout(() => {
						this.fireMain.setVisibility(true);
						this.fireR.setVisibility(true);
						this.fireL.setVisibility(true);
						this.smoke.setVisibility(true);
					}, 1200);
					
					let animationCount = 0;
					this.mixer.addEventListener('finished', (e) => {
						animationCount++;
						if (animationCount === 1) {
							this.fireMain.setVisibility(false);
							this.fireR.setVisibility(false);
							this.fireL.setVisibility(false);
							this.smoke.setVisibility(false);

							this.fireMainStart.setVisibility(false);
							this.fireRStart.setVisibility(false);
							this.fireLStart.setVisibility(false);
							this.smokeStart.setVisibility(false);
							buttonEEnabled = true;
						}
					})

					const scene = document.querySelector('a-scene');

					setTimeout(() => {
						scene.emit('return-to-start');
					}, 25000);
					setTimeout(() => {
						scene.emit('annotation-close');
					}, 8000);
				}

			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.positionATriggerBtn = poolButtons.requestEntity();
				this.positionATriggerBtn?.object3D.position.set(-2.6, 2, 0.1);
				this.positionATriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionATriggerBtn?.play();
				this.positionATriggerBtn?.addEventListener('click', () => {
					this.positonAButtonHandler();
					if (this.positionATriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionATriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionATriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionATriggerBtn;
					}
				});

				this.positionBTriggerBtn = poolButtons.requestEntity();
				this.positionBTriggerBtn?.object3D.position.set(-2.6, 4.4, 0.1);
				this.positionBTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionBTriggerBtn?.play();
				this.positionBTriggerBtn?.addEventListener('click', () => {
					this.positonBButtonHandler();
					if (this.positionBTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionBTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionBTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionBTriggerBtn;
					}
				});

				this.positionCTriggerBtn = poolButtons.requestEntity();
				this.positionCTriggerBtn?.object3D.position.set(0, 1, 0.38);
				this.positionCTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionCTriggerBtn?.play();
				this.positionCTriggerBtn?.addEventListener('click', () => {
					this.positonCButtonHandler();
					if (this.positionCTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionCTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionCTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionCTriggerBtn;
					}
				});

				this.positionDTriggerBtn = poolButtons.requestEntity();
				this.positionDTriggerBtn?.object3D.position.set(-2.6, 6, 0.1);
				this.positionDTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionDTriggerBtn?.play();
				this.positionDTriggerBtn?.addEventListener('click', () => {
					this.positonDButtonHandler();
					if (this.positionDTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.positionDTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.positionDTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.positionDTriggerBtn;
					}
				});

				this.positionETriggerBtn = poolButtons.requestEntity();
				this.positionETriggerBtn?.object3D.position.set(5, 0, -1);
				this.positionETriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.positionETriggerBtn?.play();
				this.positionETriggerBtn?.addEventListener('click', () => {
					this.positonEButtonHandler();
				});
			}
		},
		tick(this: IEarthsGravitySceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { EarthsGravityScene as EarthsGravitySceneComponent }