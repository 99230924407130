export const parseCurriculumDetails = (details: string) => {
  return details
    .split('\r\n')
    .map((i) => i.replaceAll('<br/>', ''))
    .filter((i) => !!i)
    .map((i) => {
      const splitString = i.split(/\s(.+)/);
      if (splitString.length) {
        return {
          order: splitString[0],
          text: splitString[1],
        };
      }
      return {};
    });
};
