import React, { useRef, useState } from 'react';
import { AFrameScene, DISABLE_IMAGE_TARGETS } from '../../../lib/aframe-component';
import { Annotation, AnnotationState } from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';

import { TapPlaceCustom } from 'lib/aframe/components/tap-place-custom';
import { TapPlaceComponent } from '../../../lib/aframe/components/tap-place-object'

import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';


import {
    ANNOTATION_HINT,
    INFO_HINT,
    QUIZ_HINT,
    MODEL3D_HINT,
} from 'shared/constants/hint-items';
import { MicroscopeComponent } from './microscope-scene';
import SliderArrowsAnnotationContainer from 'lib/lesson-ui/slider-arrows-annotation';
import { LoadersPermissonsEnum } from 'shared/enums';



const sceneA = 'https://bridgear.blob.core.windows.net/public/Biology/Microscope/UI_Microscope.png';
const sceneB = 'https://bridgear.blob.core.windows.net/public/Biology/Microscope/UI_Slide.png';

const assetIcons = [ sceneA, sceneB ];

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT, MODEL3D_HINT];

const introText = 'Explore the annotations to learn about parts of the microscope and click on the drop down menu to observe different specimens under the microscope.'

const BiologyMicroscopeAframe = (() => {
    const [selectedObject, setSelectedObject] = useState<{ title: string; body: string; image?: string } | null>(null);
    const [annotationState, setAnnotationState] = useState<AnnotationState>(AnnotationState.None);
    const [lessonStarted, setLessonStarted] = useState<boolean>(false);
    const [sliderArrowsLoaded, setSliderArrowsLoaded] = useState<{
        titles: string[]
    } | null>(null);
    const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);
    
    const onObjectSelected = (data: { title: string; body: string }) => {
        setSelectedObject(data);
    };
    const onAnnotationStateChanged = (newState: AnnotationState) => {
        setAnnotationState(newState);
    };

    const onLesstonStarted = () => {
        setLessonStarted(true);
    };

    const [assetId, setAssetId] = useState(0);
    const changeAsset = (assetId: number) => {
        setSliderArrowsLoaded(null)
        if (assetId === 0) {
            setSliderArrowsLoaded(null)
        }
        if (assetId === 1) {
            setSliderArrowsLoaded({
                titles: [ 'Muscle Cell', 'Animal Cell', 'Root Hair Cell', 'Nerve Cell', 'Phloem Cell', 'Plant Cell', 'Xylem Cell' ]
            })
        }
        
        setAssetId(assetId);
    }
    /**
     * Asset changing functionality
     * Lesson ui would update the asset id state
     * Then dispatch an event to the aframe scene
     * And listen to it in any of the aframe scene components
     */
    const assetChangeEventRef = useRef(new CustomEvent('asset-change', { detail: { assetId: 0 } }));
    React.useEffect(() => {
        // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            console.log('Scene loaded');
            // Update the assetChangeEvent detail before dispatching
            assetChangeEventRef.current.detail.assetId = assetId;
            scene.dispatchEvent(assetChangeEventRef.current);
        }
    }, [assetId]);

    const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
        setPermissions(status);
    }


    return (
        <React.Fragment>
            <LessonUI flashcardData={flashcardData}
                quizData={quizData}
                introductionText={introText}
                selectedObject={selectedObject}
                onAnnotationStateChanged={onAnnotationStateChanged}
                lessonStarted={lessonStarted}
                assetIcons={assetIcons}
                onAssetButtonClick={changeAsset}
                hintItems={hintItems}
                sliderArrowsLoad={sliderArrowsLoaded}
                permissions={permissions}
            />
            <AFrameScene
                sceneHtml={require('./byology-microscope-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[WorldButtonComponent, FaceCameraComponent, TapPlaceComponent, TapPlaceCustom, MicroscopeComponent, Annotation]}
                onObjectSelected={onObjectSelected}
                onLessonStart={onLesstonStarted}
                annotationState={annotationState}
                permissionsСonfirmation={permissionsСonfirmation}
            />
        </React.Fragment>)
})
export { BiologyMicroscopeAframe }