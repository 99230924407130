import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}
interface IPeriodicTableSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    nuclearSymbolsHandler: () => void;
	alkaliMetalsHandler: () => void;
    transitionMetalsHandler: () => void;
    divideLineHandler: () => void;
    halogensHandler: () => void;
    nobleGasesHandler: () => void;
    onObjectSelected: ((selectedObject: { title: string; body: string; image: string }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    currentAssetId: number;
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: IPeriodicTableSceneAframe) {
            const atoms = document.getElementById('periodicTableHolder') as AFrame.Entity;
            atoms.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                this.el.sceneEl?.addEventListener('lesson-start', () => {
                    const periodicTable = document.getElementById('periodicTable') as AFrame.Entity;
                    periodicTable.object3D.visible = true
                })
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

                // when model is loaded adding recenter functionality
                this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                    // position this el in fron of camera with the following offset "3 4.5 -5"
                    // Get the camera and the desired offset.
                    // Get the A-Frame camera component.
                    const cameraEl = this.el.sceneEl?.querySelector('a-camera');

                    // Set the position of the element to the camera's position.

                    if (cameraEl) {
                        console.log('Camera found: ', cameraEl.getAttribute('rotation'));
                        console.log('Rotation before: ', this.el.getAttribute('rotation'));
                        // Get the camera's Y rotation.
                        const cameraRotation = cameraEl.getAttribute('rotation') as unknown as THREE.Euler;
                        const cameraRotationY = cameraRotation ? cameraRotation.y : 0;

                        // Get the current rotation of the element.
                        const elementRotation = this.el.getAttribute('rotation');

                        // Set the element's Y rotation to match the camera's Y rotation.
                        this.el.setAttribute('rotation', {
                            x: elementRotation.x,
                            y: cameraRotationY,
                            z: elementRotation.z
                        });
                        console.log('Rotation after: ', this.el.getAttribute('rotation'))

                        const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
                        // Create an offset vector.
                        const offset = new THREE.Vector3(0, -8, -8);
                        offset.applyQuaternion(this.el.object3D.quaternion);

                        // Add the offset to the camera's position.
                        const newPosition = camPos.clone().add(offset);

                        // Set the position of the element with the offset.
                        this.el.setAttribute('position', newPosition);

                    }

                    // fix the annotations if there is an active button
                    if (this.currentDeactivatedButton) {
                        (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        // remove the line
                        this.annotationComponent.deactivate();
                    }
                });
            });

            this.nuclearSymbolsHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/PeriodicTable/PeriodicTable/GroupCards/NuclearSymbolsCardHydrogen.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            this.alkaliMetalsHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = ''
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/PeriodicTable/PeriodicTable/GroupCards/AlkaliMetals.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            this.transitionMetalsHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Transition Metals';
                    const body = 'The transition metals are found in the middle of the periodic table. Generally, they are strong, dense metals, such as Fe, Ni, and Cu.';
                    const image = ''
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            this.divideLineHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Divide Line';
                    const body = 'The stepped line separates metals from non-metals. All elements to the left of the line are metals, and all elements on the right are non-metals.';
                    const image = ''
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            this.halogensHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = '';
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/PeriodicTable/PeriodicTable/GroupCards/Halogens.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            this.nobleGasesHandler = () => {
                if (this.onObjectSelected) {
                    const title = '';
                    const body = '';
                    const image = 'https://bridgear.blob.core.windows.net/public/Chemistry/PeriodicTable/PeriodicTable/GroupCards/NobleGases.png'
                    this.onObjectSelected({title, body, image})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const nuclearSymbolsTriggerBtn = poolButtons.requestEntity()
                nuclearSymbolsTriggerBtn?.setAttribute('position', '-0.22 4.63 0.15')
                nuclearSymbolsTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                nuclearSymbolsTriggerBtn?.play()
                nuclearSymbolsTriggerBtn?.addEventListener('click', () => {
                    this.nuclearSymbolsHandler()
                    if (nuclearSymbolsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(nuclearSymbolsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (nuclearSymbolsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = nuclearSymbolsTriggerBtn
                    }
                });
                const alkaliMetalsTriggerBtn = poolButtons.requestEntity()
                alkaliMetalsTriggerBtn?.setAttribute('position', '-1.27 4 0.15')
                alkaliMetalsTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                alkaliMetalsTriggerBtn?.play()
                alkaliMetalsTriggerBtn?.addEventListener('click', () => {

                    this.alkaliMetalsHandler()
                    if (alkaliMetalsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(alkaliMetalsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (alkaliMetalsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = alkaliMetalsTriggerBtn
                    }
                });
                const transitionMetalsTriggerBtn = poolButtons.requestEntity()
                transitionMetalsTriggerBtn?.setAttribute('position', '-0.31 4 0.15')
                transitionMetalsTriggerBtn?.setAttribute('scale', '0.3 0.3 0.35')
                transitionMetalsTriggerBtn?.play()
                transitionMetalsTriggerBtn?.addEventListener('click', () => {

                    this.transitionMetalsHandler()
                    if (transitionMetalsTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(transitionMetalsTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (transitionMetalsTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = transitionMetalsTriggerBtn
                    }
                });
                const divideLiveTriggerBtn = poolButtons.requestEntity()
                divideLiveTriggerBtn?.setAttribute('position', '0.36 4.23 0.15')
                divideLiveTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                divideLiveTriggerBtn?.play()
                divideLiveTriggerBtn?.addEventListener('click', () => {
                    this.divideLineHandler()
                    if (divideLiveTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(divideLiveTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (divideLiveTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = divideLiveTriggerBtn
                    }
                });
                const halogensTriggerBtn = poolButtons.requestEntity()
                halogensTriggerBtn?.setAttribute('position', '0.94 4.32 0.15')
                halogensTriggerBtn?.setAttribute('scale', '0.3 0.3 0.3')
                halogensTriggerBtn?.play()
                halogensTriggerBtn?.addEventListener('click', () => {
                    this.halogensHandler()
                    if (halogensTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(halogensTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (halogensTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = halogensTriggerBtn
                    }
                });
                const nobleGasesTriggerButton = poolButtons.requestEntity();
                nobleGasesTriggerButton?.setAttribute('position', '1.15 4 0.15');
                nobleGasesTriggerButton?.setAttribute('scale', '0.3 0.3 0.3');
                nobleGasesTriggerButton?.play()
                nobleGasesTriggerButton?.addEventListener('click', () => {
                    this.nobleGasesHandler()
                    if (nobleGasesTriggerButton) {
                        this.annotationComponent.setObjectToFollow(nobleGasesTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (nobleGasesTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = nobleGasesTriggerButton

                    }
                });

            };
        },
    },
};
export {AtomsScene as AtomsSceneComponent}
