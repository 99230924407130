import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import { IconImage } from '../../../shared/components/IconImage'

import ToTop from '../../../assets/toTop.svg'

export function ScrollToTop() {
	const trigger = useScrollTrigger({
		target: window,
		disableHysteresis: true,
		threshold: window.innerHeight * 2,
	})

	const handleClick = () => {
		// eslint-disable-next-line no-restricted-globals
		history.replaceState("", document.title, window.location.pathname);
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}

	return (
		<Fade in={trigger}>
			<Box
				onClick={handleClick}
				role='presentation'
				sx={{ position: 'fixed', bottom: 45, right: 45, zIndex: 999,  cursor: 'pointer' }}
				bgcolor='#fff'
				border='4px solid #7A6CF438'
				borderRadius='50%'
				boxShadow='0px 9px 16px #0000001A'
				boxSizing='border-box'
				width={50}
				height={50}
				zIndex={99}
				display='flex'
				alignItems='center'
				justifyContent='center'
			>
				<IconImage src={ToTop} alt='to top' width='18px' />
			</Box>
		</Fade>
	)
}
