import * as THREE from 'three';
import * as AFrame from 'aframe';
import { LinearSpline } from '../../../lib/utils/LinearSpline';
import { vertexShader as _VS, fragmentShader as _FS } from './shaders/particle-shaders';
import { add } from 'date-fns';

interface ParticleData {
  position: THREE.Vector3;
  size: number;
  alpha: number;
  life: number;
  maxLife: number;
  rotation: number;
  velocity: THREE.Vector3;
}

export interface IWaterMachineAframe {
    lastVisibilityState: boolean;
    isInitialized: boolean;
    liftSpawnPoint: THREE.Vector3;
    liftParticleVelocity: THREE.Vector3;
    newSpawnElapsedTime: number;
    velocities: THREE.Vector3[];
    spawnOffsets: THREE.Vector3[];
    alphaSpline: LinearSpline<number>;
    spawningEnabled: boolean;
    el: AFrame.Entity;
    data: {
        lifetimex: number;
        spawnRate: number;
        sizeMax: number;
        spawnRadius: number;
    };
    particleData: ParticleData[];
    geometry: THREE.BufferGeometry;
    startColor: THREE.Color;
    endColor: THREE.Color;
    sizeSpline: LinearSpline<number>;
    points: THREE.Points;
    elapsedTimeSinceEmission: number;
  
    addParticles(deltaTimeS: number): void;
    addLiftParticles(deltaTimeS: number): void;
    updateGeometry(): void;
    updateParticles(deltaTimeS: number): void;
    setVisible(value: boolean): void;
    startSpawning(): void;
    stopSpawning(): void;
  }


const waterMachineComponent = {
  name: 'water-machine',
  val: {
    schema: {
        lifetimex: { type: 'number', default: 1 },
        spawnRate: { type: 'number', default: 4 },
        sizeMax: { type: 'number', default: 5 },
        spawnRadius: { type: 'number', default: 2 },
    },
    init(this: IWaterMachineAframe): void {
        
        const onLoadTexture = (texture: THREE.Texture) => {
          const matShader = new THREE.ShaderMaterial({
            uniforms: {
              diffuseTexture: {value: texture},
              pointMultiplier: {
                  value: window.innerHeight / (2.0 * Math.tan(0.5 * 60.0 * Math.PI / 180.0))
              }
            },
            vertexShader: _VS,
            fragmentShader: _FS,
            blending: THREE.AdditiveBlending,
            depthTest: true,
            depthWrite: false,
            transparent: true,
            // alphaTest: 0.5,
            // vertexColors: true,
          })
      
          // points positions
          this.particleData = []
      
          this.geometry = new THREE.BufferGeometry()
          this.geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
          this.geometry.setAttribute('size', new THREE.Float32BufferAttribute([], 1))
          this.geometry.setAttribute('alpha', new THREE.Float32BufferAttribute([], 1))
          this.geometry.setAttribute('angle', new THREE.Float32BufferAttribute([], 1))
          // this.matPoints = new THREE.PointsMaterial({color: 0xffffff, size: 1})
      
          this.points = new THREE.Points(this.geometry, matShader)
          // this.points.object3D.scale.set('5, 5, 5')
          this.points.position.set(0, 0, 0)
          this.points.scale.set(0.05, 0.05, 0.05)
          this.points.renderOrder = 50;
          this.points.frustumCulled = false;
          this.el.object3D.add(this.points)
      
      
          this.sizeSpline = new LinearSpline((t, a, b) => {
            return a + t * (b - a)
          })
          this.sizeSpline.AddPoint(0.0, this.data.sizeMax)
          this.sizeSpline.AddPoint(1.0, 0.1)
  
          this.alphaSpline = new LinearSpline((t, a, b) => {
            return a + t * (b - a)
          })
          this.alphaSpline.AddPoint(0.0, 0.0)
          this.alphaSpline.AddPoint(0.9, 1)
          this.alphaSpline.AddPoint(1.0, 0)
  
          this.spawnOffsets = [
            new THREE.Vector3(-3, 4, 12),     // left lower wheel water collision
            new THREE.Vector3(2.9, 16.1, 12),     // left lower wheel waterfall start
            new THREE.Vector3(3, 16, -12),  // right upper wheel water collision
            new THREE.Vector3(-2.9, 27.8, -12),  // right upper wheel waterfall start
          ]
  
          this.velocities = [
            new THREE.Vector3(-1.5, 1, 0),
            new THREE.Vector3(-1.5, -1.5, 0),
            new THREE.Vector3(1.5, 1, 0),
            new THREE.Vector3(1.5, -1.5, 0),
          ]
  
          // settings for lift particles
          this.liftSpawnPoint = new THREE.Vector3(-11.5, 32, 1);
          this.liftParticleVelocity = new THREE.Vector3(0, -2, -2.5);
          this.newSpawnElapsedTime = 1;

          this.isInitialized = true;
          if (this.lastVisibilityState) {
            this.setVisible(this.lastVisibilityState);
          }
        };

        const textureElement = document.querySelector('#particleTexture');
        if (textureElement) {
            const texture = new THREE.TextureLoader().load(textureElement.getAttribute('src'), onLoadTexture);
        } else {
            console.log('Particle texture not found');
        }
    
        this.spawningEnabled = false;
    
      },
      tick(this: IWaterMachineAframe, time: number, deltaTime: number): void {
        if (this.isInitialized) {
          const deltaTimeS = deltaTime * 0.001;
          this.addParticles(deltaTimeS);
          this.addLiftParticles(deltaTimeS);
          this.updateParticles(deltaTimeS);
          this.updateGeometry();
        }  
      },
      addLiftParticles(this: IWaterMachineAframe, deltaTimeS: number): void {
        if (!this.spawningEnabled) {
            return;
        }
        this.newSpawnElapsedTime += deltaTimeS;

        if (this.newSpawnElapsedTime >= 1) {
          this.newSpawnElapsedTime -= 1;

          for (let i = 0; i < 8; i++) {
            const lifeRand = (Math.random() * 0.1 + 0.9) * this.data.lifetimex * 2.5;
            const tempVec = new THREE.Vector3(
              (Math.random() * 2 - 1) *  this.data.spawnRadius,
              (Math.random() * 2 - 1) * 0.1,
              (Math.random() * 2 - 1) * 0.2,
            );

            tempVec.add(this.liftSpawnPoint);

            this.particleData.push({
              position: tempVec.clone(),
              size: (Math.random() * 0.5 + 0.5) * 2.0,
              alpha: 1.0,
              life: lifeRand,
              maxLife: lifeRand,
              rotation: Math.random() * 2.0 * Math.PI,
              velocity: this.liftParticleVelocity.clone(),
            });
          }
        }
      },
      addParticles(this: IWaterMachineAframe, deltaTimeS: number): void {
        if (!this.spawningEnabled) {
            return;
        }
        // Your implementation...
        if (!this.elapsedTimeSinceEmission) {
            this.elapsedTimeSinceEmission = 0.0
          }
          const spawnRate = this.data.spawnRate; // Adjust this value to control the spawn rate (lower value means fewer particles)
          this.elapsedTimeSinceEmission += deltaTimeS * spawnRate;
          
          const n = Math.floor(this.elapsedTimeSinceEmission * 125.0)
          this.elapsedTimeSinceEmission -= n / 75.0
          
          const tempVec = new THREE.Vector3(); // Temporary vector to avoid creating new instances
          for (let i = 0; i < n; i++) {
            const spawnIndex = i % this.spawnOffsets.length;
            const lifeRand = (Math.random() * 0.1 + 0.9) * this.data.lifetimex * Math.pow((spawnIndex % 2 + 1), Math.random() * 2 + 0.5);
            tempVec.set(
              (Math.random() * 2 - 1),
              (Math.random() * 2 - 1) * 0.1,
              (Math.random() * 2 - 1) * this.data.spawnRadius
            );

            tempVec.add(this.spawnOffsets[spawnIndex]);
            this.particleData.push({
              position: tempVec.clone(),
              size: (Math.random() * 0.5 + 0.5) * 2.0,
              alpha: 1.0,
              life: lifeRand,
              maxLife: lifeRand,
              rotation: Math.random() * 2.0 * Math.PI,
              velocity: this.velocities[spawnIndex].clone(),
            });
            
           
          }
      },
      updateParticles(this: IWaterMachineAframe, deltaTimeS: number): void {
        const tempVec1 = new THREE.Vector3(); // Temporary vector for vector operations
    
        for (let i = 0; i < this.particleData.length; i++) {
            this.particleData[i].life -= deltaTimeS;
        }
    
        this.particleData = this.particleData.filter((p) => p.life > 0.0);
    
        for (let i = 0; i < this.particleData.length; i++) {
            const p = this.particleData[i];
            // Update rotation
            p.rotation += deltaTimeS * 0.5;
            
            // Movement
            tempVec1.copy(p.velocity).multiplyScalar(deltaTimeS);
            p.position.add(tempVec1);
            tempVec1.copy(p.velocity).multiplyScalar(deltaTimeS * 0.1);
            tempVec1.x = Math.sign(p.velocity.x) * Math.min(Math.abs(tempVec1.x), Math.abs(p.velocity.x));
            tempVec1.y = Math.sign(p.velocity.y) * Math.min(Math.abs(tempVec1.y), Math.abs(p.velocity.y));
            tempVec1.z = Math.sign(p.velocity.z) * Math.min(Math.abs(tempVec1.z), Math.abs(p.velocity.z));
            p.velocity.sub(tempVec1);
            
            const t = p.life / p.maxLife;
            p.alpha = this.alphaSpline.Get(t);
            p.size = this.sizeSpline.Get(t);
        }

    },
    
      updateGeometry(this: IWaterMachineAframe): void {
        const positions = []
        const sizes = []
        const angles = []
        const alpha = []
    
        for (let i = 0; i < this.particleData.length; i++) {
          positions.push(this.particleData[i].position.x, this.particleData[i].position.y, this.particleData[i].position.z)
          sizes.push(this.particleData[i].size)
          angles.push(this.particleData[i].rotation)
          alpha.push(this.particleData[i].alpha)
        }
    
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))
        this.geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1))
        this.geometry.setAttribute('angle', new THREE.Float32BufferAttribute(angles, 1))
        this.geometry.setAttribute('alpha', new THREE.Float32BufferAttribute(alpha, 1))
    
        this.geometry.attributes.position.needsUpdate = true
        this.geometry.attributes.size.needsUpdate = true
        this.geometry.attributes.angle.needsUpdate = true
        this.geometry.attributes.alpha.needsUpdate = true
      },
      setVisible(this: IWaterMachineAframe, value: boolean): void {
        if (this.points) {
          this.points.visible = value;
        }
        this.lastVisibilityState = value;
        
      },
      startSpawning(this: IWaterMachineAframe): void {
        this.spawningEnabled = true;
      },
      stopSpawning(this: IWaterMachineAframe): void {
        this.spawningEnabled = false;
      },
  },
};

export { waterMachineComponent as WaterMachine };

