import { useEffect, useState } from 'react';
import { JoinUsPopup } from '../../../shared/components/JoinUsPopup';
import { Footer } from '../../../shared/components/Footer';
import { DataManager } from '../DataManager';
import { CourseWrapperComponent } from './components';
import { StyledStack } from './components/StyledStack';
import HeaderComponent from '../../../shared/components/Header';

export function CourseListPage() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <DataManager setLoading={setLoading}>
      <StyledStack>
        <HeaderComponent />
        <CourseWrapperComponent loading={loading} />
        <Footer showAdditionalInfo={true} onMainPage={false} />
        {/* <JoinUsPopup /> */}
      </StyledStack>
    </DataManager>
  );
}
