import { ContactUsHome } from './components/ContactUsHome';
import { HeaderBG } from './components/HeaderBG';
import { JoinCommunity } from './components/JoinCommunityComponent';
import { AboutTheCompany } from './components/AboutTheCompany';
import { Footer } from '../../shared/components/Footer';
import { RoleMenu } from '../../shared/components/RoleMenu';
import { ScrollToTop } from './components/ScrollToTop';
import { useEffect } from 'react';
import WelcomeComponent from './components/WelcomeComponent';
import HowItWorksComponent from './components/HowItWorks';
import QRcode from './components/QRcode';
import Courses from './components/Courses';

export function HomePageComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HeaderBG>
      <WelcomeComponent />
      <JoinCommunity />
      <Courses />
      <HowItWorksComponent />
      <QRcode />
      <AboutTheCompany />
      <ContactUsHome />
      <Footer showAdditionalInfo={true} onMainPage={true} />
      <ScrollToTop />
      <RoleMenu />
    </HeaderBG>
  );
}

export default HomePageComponent;
