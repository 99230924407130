import React from 'react';
import '../styles/iconPanel.css';

interface AssetIconListComponentProps {
    icons: string[];
    onAssetButtonClick: (assetId: number) => void;
    assetSelectionOn: boolean;
    selectedAsset: number;
  }

  const AssetIconListComponent: React.FC<AssetIconListComponentProps> = ({ icons, onAssetButtonClick, assetSelectionOn, selectedAsset }) => {
    const renderAssetIcon = (iconSrc: string, index: number) => {
      const assetId = index;
      const handleClick = () => onAssetButtonClick(assetId);
      const isSelectedAsset = index === selectedAsset;
  
      return (
        <div
          key={index}
          className={`menuItem ${isSelectedAsset ? 'selected' : ''}`}
        >
          <img
            src={iconSrc}
            alt={`Icon ${index + 1}`}
            onClick={!isSelectedAsset ? handleClick : undefined}
            style={{ opacity: isSelectedAsset ? 0.5 : 1 }}
          />
        </div>
      );
    };
  
    return (
      <div className='assetIconList'>
        {icons.map((iconSrc, index) => {
          if (assetSelectionOn) {
            return renderAssetIcon(iconSrc, index);
          }
          return null;
        })}
      </div>
    );
  };
  
  export default AssetIconListComponent;
  
