import * as AFrame from 'aframe';
import * as THREE from 'three';
import {IAnnotationAframe} from '../../../lib/aframe/components/annotation';
import {IAnnotationSystemAframe} from '../../../lib/aframe/systems/annotation-system';
import {WorldButtonAframeInstance} from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;

    returnEntity(entity: AFrame.Entity): void;
}

interface IElectronStructureSceneAframe {
    currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    annotationComponent: IAnnotationAframe;
    firstElectronShellButtonHandler: () => void;
    secondElectronShellButtonHandler: () => void;
    thirdElectronShellButtonHandler: () => void;
    electronConfigurationButtonHandler: () => void;
    argonMixer: THREE.AnimationMixer;
    argonCoreAnimation: THREE.AnimationAction;
    argonElectronsAnimation: THREE.AnimationAction;
    onObjectSelected: ((selectedObject: { title: string; body: string; }) => void) | null;
    buttonsInitialised: boolean;
    el: AFrame.Entity;
    argon: AFrame.Entity;
    currentAssetId: number;
}

const AtomsScene = {
    name: 'atoms-scene',
    val: {
        init(this: IElectronStructureSceneAframe) {
            const atoms = document.getElementById('atomsHolder') as AFrame.Entity;
            atoms.addEventListener('model-loaded', () => {
                //setup annotation callback
                const scene = this.el.sceneEl as AFrame.Scene & {
                    systems: { "annotation-system": IAnnotationSystemAframe };
                };
                const annotationSystem = scene.systems["annotation-system"];
                this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

                this.el.setAttribute('annotation', '');
                this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

                //get pool entity
                this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
                // ony initialise buttons once pool has loaded
                if (this.poolEntity.hasLoaded) {
                    initialiseButtons();
                } else {
                    this.poolEntity.addEventListener('loaded', () => {
                        initialiseButtons();
                    });
                }

            });
            this.el.sceneEl?.addEventListener('lesson-start', () => {
                // remove tap place
                const ring = document.getElementById('ring')
                if (ring) {
                    ring.removeAttribute('tap-place-air')
                    this.el.sceneEl?.removeChild(ring)
                }
                this.argon = document.getElementById('argonAtom') as AFrame.Entity;
                const animatedEl = this.argon.object3D.getObjectByName('Scene') as any;
                this.argonMixer = new THREE.AnimationMixer(animatedEl)
                const [HeliumElectronsAnim, HeliumCoreAnim] = animatedEl.animations
                this.argonElectronsAnimation = this.argonMixer.clipAction(HeliumElectronsAnim)
                this.argonCoreAnimation = this.argonMixer.clipAction(HeliumCoreAnim)
                this.argonElectronsAnimation.play()
                this.argonCoreAnimation.play()
                const argonCard = document.getElementById('argonCard') as AFrame.Entity
                argonCard.object3D.position.setY(-0.25)
                argonCard.object3D.scale.set(3, 3, 3)
            })
            // when model is loaded adding recenter functionality
            this.el.sceneEl?.addEventListener('lesson-recenter', () => {
                this.el.sceneEl?.emit('recenter');
                // check if the ring exists
                // if it does ignore the event
                const ring = document.getElementById('ring')
                if (ring) {
                    return;
                } else {
                    const ring = document.createElement('a-ring');

                    ring.setAttribute('id', 'ring');
                    ring.setAttribute('tap-place-air', 'id: atomsHolder; scale: 10 10 10; offset: 0 -2 0');
                    ring.setAttribute('material', 'shader: flat; color: #ffffff');
                    ring.setAttribute('rotation', '-90 0 0');
                    ring.setAttribute('radius-inner', '0.5');
                    ring.setAttribute('radius-outer', '0.8');

                    // Attach the created ring element to the scene or another parent entity.
                    this.el.sceneEl?.appendChild(ring);
                }
            });

            this.el.sceneEl?.addEventListener('anim-toggle', (event) => {
                const customEvent = event as CustomEvent; // Cast event to CustomEvent
                const animToggle = customEvent.detail.toggle;
                if (animToggle) {
                    if (this.argonCoreAnimation && this.argonElectronsAnimation) {
                        this.argonCoreAnimation.paused = false;
                        this.argonElectronsAnimation.paused = false;
                    }
                } else {
                    if (this.argonCoreAnimation && this.argonElectronsAnimation) {
                        this.argonCoreAnimation.paused = true;
                        this.argonElectronsAnimation.paused = true;
                    }
                }
            })
            this.firstElectronShellButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'First Electron Shell';
                    const body = 'The first electron shell is the innermost shell, closest to the nucleus. This shell can occupy a maximum of two electrons. When atoms have more than two electrons the electrons start filling the second electron shell.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.secondElectronShellButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Second Electron Shell';
                    const body = 'The second electron shell can occupy up to eight electrons. When atoms have more than ten electrons (filling the first and second shells), electrons begin to occupy the third electron shell.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.thirdElectronShellButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Third Electron Shell';
                    const body = 'For elements with atomic numbers 1 to 20, the third electron shell occupies up to eight electrons.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            this.electronConfigurationButtonHandler = () => {
                if (this.onObjectSelected) {
                    const title = 'Electron Configuration';
                    const body = 'Electron configuration is used to show the electrons of each shell in an atom. The electron configuration for Argon is 2,8,8 as it has 2 electrons in its first shell, 8 in its second, and 8 in its third shell.';
                    this.onObjectSelected({title, body})
                } else {
                    console.log('No object selected method')
                }
            }
            const initialiseButtons = () => {
                // Wait for the pool component to be initialized
                const poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                const firstElectronShellTriggerBtn = poolButtons.requestEntity()
                firstElectronShellTriggerBtn?.setAttribute('position', '0.071 0.071 0.01')
                firstElectronShellTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                firstElectronShellTriggerBtn?.play()
                firstElectronShellTriggerBtn?.addEventListener('click', () => {
                    this.firstElectronShellButtonHandler()
                    if (firstElectronShellTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(firstElectronShellTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (firstElectronShellTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = firstElectronShellTriggerBtn
                    }
                });
                const secondElectronShellTriggerBtn = poolButtons.requestEntity()
                secondElectronShellTriggerBtn?.setAttribute('position', '0 0.13 0.01')
                secondElectronShellTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                secondElectronShellTriggerBtn?.play()
                secondElectronShellTriggerBtn?.addEventListener('click', () => {

                    this.secondElectronShellButtonHandler()
                    if (secondElectronShellTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(secondElectronShellTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (secondElectronShellTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = secondElectronShellTriggerBtn
                    }
                });
                const thirdElectronShellTriggerBtn = poolButtons.requestEntity()
                thirdElectronShellTriggerBtn?.setAttribute('position', '0.08 0.14 0.01')
                thirdElectronShellTriggerBtn?.setAttribute('scale', '0.1 0.1 0.1')
                thirdElectronShellTriggerBtn?.play()
                thirdElectronShellTriggerBtn?.addEventListener('click', () => {
                    this.thirdElectronShellButtonHandler()
                    if (thirdElectronShellTriggerBtn) {
                        this.annotationComponent.setObjectToFollow(thirdElectronShellTriggerBtn);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (thirdElectronShellTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = thirdElectronShellTriggerBtn
                    }
                });
                const electronConfigurationTriggerButton = poolButtons.requestEntity();
                electronConfigurationTriggerButton?.setAttribute('position', '0.1 -0.22 0.01');
                electronConfigurationTriggerButton?.setAttribute('scale', '0.1 0.1 0.1');
                electronConfigurationTriggerButton?.play()
                electronConfigurationTriggerButton?.addEventListener('click', () => {
                    this.electronConfigurationButtonHandler()
                    if (electronConfigurationTriggerButton) {
                        this.annotationComponent.setObjectToFollow(electronConfigurationTriggerButton);
                        if (this.currentDeactivatedButton) {
                            (this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
                        }
                        (electronConfigurationTriggerButton.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
                        this.currentDeactivatedButton = electronConfigurationTriggerButton
                    }
                });

            };
        },
        tick(this: IElectronStructureSceneAframe, time: number, deltaTime: number) {
            if (this.argonMixer) {
                this.argonMixer.update(deltaTime * 0.001);
            }
        },
    },
};
export {AtomsScene as AtomsSceneComponent}
