import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';
import MuscleCardiac from '../../../assets/img/bio2/MuscleCardiac.png';
import MuscleSkeletal from '../../../assets/img/bio2/MuscleSkeletal.png';
import MuscleSmooth from '../../../assets/img/bio2/MuscleSmooth.png';
interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface ICellSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	buttonsInitialised: boolean;
	actionPulse: THREE.AnimationAction;
	currentClip: THREE.AnimationAction;
	mixer: THREE.AnimationMixer;
	el: AFrame.Entity;
	currentAssetId: number;
	nucleusButtonHandler: () => void;
	mitochondriaButtonHandler: () => void;
	proteinFilamentButtonHandler: () => void;
	muscleCellButtonHandler: () => void;
	skeletalMuscleButtonHandler: () => void;
	smoothMuscleButtonHandler: () => void;
	cardiacMuscleButtonHandler: () => void;
	onObjectSelected: ((selectedObject: { title: string; body: string; image?: string }) => void) | null;
}

const CellScene = {
	name: 'cell-scene',
	val: {
		init(this: ICellSceneAframe) {
			const controlElement = document.getElementById('sceneHolder') as AFrame.Entity;
			controlElement.addEventListener('model-loaded', (e) => {
				const customEvent = e as CustomEvent;
				const modelId = (customEvent.target as HTMLElement).id
				console.log('Loaded model', modelId);
				// only proceeding with the initialisation if the model is the one we want and nothing was yet initialised
				if (modelId !== 'muscleCellContracting') {
					return;
				}

				// console.log(
				// 	'Cell scene model loaded (MuscleCellContracting). Initialising behaviours.',
				// )
				//initialiseAnimations();
				const scene = this.el.sceneEl as AFrame.Scene & {
					systems: { "annotation-system": IAnnotationSystemAframe };
				};
				const annotationSystem = scene.systems["annotation-system"];
				this.onObjectSelected = annotationSystem.getObjectSelectedFunction();
				this.el.setAttribute('annotation', '');
				this.annotationComponent = this.el.components.annotation as IAnnotationAframe;
				this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				if (this.poolEntity.hasLoaded) {
					initialiseButtons();
				} else {
					this.poolEntity.addEventListener('loaded', () => {
						initialiseButtons();
					});
				}
				// moved here
				initialiseAnimations();
			})

			const cell = document.getElementById('cellHolder') as AFrame.Entity;

			const humanBody = document.getElementById('humanBody') as AFrame.Entity;
			humanBody.setAttribute('scale', '0 0 0');

			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				const cameraEl = this.el.sceneEl?.querySelector('a-camera');
				const controlElement = document.getElementById('sceneHolder') as AFrame.Entity;

				// Set the position of the element to the camera's position.

				if (cameraEl) {
					// console.log('Camera found: ', cameraEl.getAttribute('rotation'));
					// console.log('Rotation before: ', controlElement.getAttribute('rotation'));

					// Get the camera's Y rotation.
					const cameraRotation = cameraEl.getAttribute('rotation') as unknown as THREE.Euler;
					const cameraRotationY = cameraRotation ? cameraRotation.y : 0;

					// Get the current rotation of the element.
					const elementRotation = controlElement.getAttribute('rotation');

					// Set the element's Y rotation to match the camera's Y rotation.
					controlElement.setAttribute('rotation', {
						x: elementRotation.x,
						y: cameraRotationY,
						z: elementRotation.z
					});

					console.log('Rotation after: ', controlElement.getAttribute('rotation'))

					const camPos = cameraEl.getAttribute('position') as unknown as THREE.Vector3;
					// Create an offset vector.
					const offset = new THREE.Vector3(0, -8, -8);
					offset.applyQuaternion(controlElement.object3D.quaternion);

					// Add the offset to the camera's position.
					const newPosition = camPos.clone().add(offset);

					// Set the position of the element with the offset.
					controlElement.setAttribute('position', newPosition);
				}
				if (this.currentDeactivatedButton) {
					(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
					this.annotationComponent.deactivate();
				}
			});


			this.currentAssetId = 0;
			this.el.sceneEl?.addEventListener('asset-change', (event) => {
				const customEvent = event as CustomEvent; // Cast event to CustomEvent
				const newAssetId = customEvent.detail.assetId;
				if (newAssetId === 0) {
					cell.setAttribute('scale', '2 2 2');
					humanBody.setAttribute('scale', '0 0 0');
				}
				if (newAssetId === 1) {
					cell.setAttribute('scale', '0 0 0');
					humanBody.setAttribute('scale', '5.3 5.3 5.3');
				}

			});

			const initialiseAnimations = () => {
				const muscleCell = document.getElementById('muscleCellContracting') as AFrame.Entity;
				const animatedEl = muscleCell.object3D.getObjectByName('MuscleCellsContracting_v3_Prefab2') as any;
				this.mixer = new THREE.AnimationMixer(animatedEl)
				const clipPulse = animatedEl.animations[0]
				this.actionPulse = this.mixer.clipAction(clipPulse)
			};

			const playSound = () => {
				const sound = document.getElementById('heartBeat') as any;
				if (sound) {
					sound.play()
				}
			}

			this.mitochondriaButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Mitochondria';
					const body = 'Muscle cells contain many mitochondria to provide the energy needed for contracting and relaxing.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.nucleusButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Nucleus';
					const body = 'Some muscle cells have one nucleus, others are multinucleated, which means they have many nuclei.';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.proteinFilamentButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				this.currentClip = this.actionPulse;
				this.actionPulse.play()

				if (this.onObjectSelected) {
					const title = 'Protein filament';
					const body = 'The special proteins slide over each other causing the fibres to contract.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.muscleCellButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Muscle cell';
					const body = 'The function of the muscle cell is to contract and relax.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method')
				}
			}

			this.skeletalMuscleButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Skeletal muscle';
					const body = 'The function of skeletal muscles is to contract and relax to move the bones in the skeleton. They appear striated and merge together becoming multinucleated to help muscle fibres contract at the same time.';
					const image = MuscleSkeletal;
					this.onObjectSelected({ title, body, image })
				} else {
					console.log('No object selected method')
				}
			}

			this.smoothMuscleButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				if (this.onObjectSelected) {
					const title = 'Smooth muscle';
					const body = 'The function of smooth muscles is to contract and relax to move the food down the digestive system. Smooth muscle cells contain one nucleus and are not striated in appearance.';
					const image = MuscleSmooth;
					this.onObjectSelected({ title, body, image })
				} else {
					console.log('No object selected method')
				}
			}

			this.cardiacMuscleButtonHandler = () => {
				if (this.currentClip) {
					this.currentClip.stop();
				}
				playSound()
				if (this.onObjectSelected) {
					const title = 'Cardiac muscle';
					const body = 'The function of cardiac muscles is to contract and relax to pump blood around the body. Cardiac muscle cells contain one nucleus and are striated in appearance.';
					const image = MuscleCardiac;
					this.onObjectSelected({ title, body, image })

				} else {
					console.log('No object selected method')
				}
			}


			const initialiseButtons = () => {
				// Wait for the pool component to be initialized
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				const nucleusTriggerBtn = poolButtons.requestEntity()
				nucleusTriggerBtn?.setAttribute('position', '3.66 5.62 -9')
				nucleusTriggerBtn?.setAttribute('scale', '2 2 2')
				nucleusTriggerBtn?.play()
				nucleusTriggerBtn?.addEventListener('click', () => {
					this.nucleusButtonHandler()
					if (nucleusTriggerBtn) {
						this.annotationComponent.setObjectToFollow(nucleusTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(nucleusTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = nucleusTriggerBtn
					}
				});
				this.el.sceneEl?.addEventListener('asset-change', (event) => {
					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						nucleusTriggerBtn?.setAttribute('scale', '2 2 2')
					}
					if (newAssetId === 1) {
						nucleusTriggerBtn?.setAttribute('scale', '0 0 0')
					}
				});
				const mitochondriaTriggerBtn = poolButtons.requestEntity()
				mitochondriaTriggerBtn?.setAttribute('position', '-0.13 5.6 -9')
				mitochondriaTriggerBtn?.setAttribute('scale', '2 2 2')
				this.el.sceneEl?.addEventListener('asset-change', (event) => {

					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						mitochondriaTriggerBtn?.setAttribute('scale', '2 2 2')
					}
					if (newAssetId === 1) {
						mitochondriaTriggerBtn?.setAttribute('scale', '0 0 0')
					}

				});
				mitochondriaTriggerBtn?.play()
				mitochondriaTriggerBtn?.addEventListener('click', () => {
					this.mitochondriaButtonHandler()
					if (mitochondriaTriggerBtn) {
						this.annotationComponent.setObjectToFollow(mitochondriaTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(mitochondriaTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = mitochondriaTriggerBtn
					}
				});
				const muscleCellTriggerBtn = poolButtons.requestEntity()
				muscleCellTriggerBtn?.setAttribute('position', '0 15 -9')
				muscleCellTriggerBtn?.setAttribute('scale', '2 2 2')
				this.el.sceneEl?.addEventListener('asset-change', (event) => {

					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						muscleCellTriggerBtn?.setAttribute('scale', '2 2 2')
					}
					if (newAssetId === 1) {
						muscleCellTriggerBtn?.setAttribute('scale', '0 0 0')
					}
				});
				muscleCellTriggerBtn?.play()
				muscleCellTriggerBtn?.addEventListener('click', () => {
					this.muscleCellButtonHandler()
					if (muscleCellTriggerBtn) {
						this.annotationComponent.setObjectToFollow(muscleCellTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(muscleCellTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = muscleCellTriggerBtn
					}
				});
				const proteinFilamentTriggerBtn = poolButtons.requestEntity()
				proteinFilamentTriggerBtn?.setAttribute('position', '-3.5 7.63 -9')
				proteinFilamentTriggerBtn?.setAttribute('scale', '2 2 2')
				this.el.sceneEl?.addEventListener('asset-change', (event) => {

					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						proteinFilamentTriggerBtn?.setAttribute('scale', '2 2 2')
					}
					if (newAssetId === 1) {
						proteinFilamentTriggerBtn?.setAttribute('scale', '0 0 0')
					}
				});
				proteinFilamentTriggerBtn?.play()
				proteinFilamentTriggerBtn?.addEventListener('click', () => {
					this.proteinFilamentButtonHandler()
					if (proteinFilamentTriggerBtn) {
						this.annotationComponent.setObjectToFollow(proteinFilamentTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(proteinFilamentTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = proteinFilamentTriggerBtn
					}
				});
				
				const skeletalTriggerBtn = poolButtons.requestEntity()
				skeletalTriggerBtn?.setAttribute('position', '-3.4 9.5 -4')
				skeletalTriggerBtn?.setAttribute('scale', '0 0 0')
				this.el.sceneEl?.addEventListener('asset-change', (event) => {
					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						skeletalTriggerBtn?.setAttribute('scale', '0 0 0')
					}
					if (newAssetId === 1) {
						skeletalTriggerBtn?.setAttribute('scale', '1 1 1')
					}
				});
				skeletalTriggerBtn?.play()
				skeletalTriggerBtn?.addEventListener('click', () => {
					this.skeletalMuscleButtonHandler()
					if (skeletalTriggerBtn) {
						this.annotationComponent.setObjectToFollow(skeletalTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(skeletalTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = skeletalTriggerBtn
					}
					console.log(this.currentDeactivatedButton)
				});

				const smoothTriggerBtn = poolButtons.requestEntity()
				smoothTriggerBtn?.setAttribute('position', '-2.85 11.3 -4')
				smoothTriggerBtn?.setAttribute('scale', '0 0 0')
				this.el.sceneEl?.addEventListener('asset-change', (event) => {
					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						smoothTriggerBtn?.setAttribute('scale', '0 0 0')
					}
					if (newAssetId === 1) {
						smoothTriggerBtn?.setAttribute('scale', '1 1 1')
					}
				});

				smoothTriggerBtn?.play()
				smoothTriggerBtn?.addEventListener('click', () => {
					this.smoothMuscleButtonHandler()
					if (smoothTriggerBtn) {
						this.annotationComponent.setObjectToFollow(smoothTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(smoothTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = smoothTriggerBtn
					}
					console.log(this.currentDeactivatedButton)
				});

				const cardiacTriggerBtn = poolButtons.requestEntity()
				cardiacTriggerBtn?.setAttribute('position', '-2.6 13.1 -4')
				cardiacTriggerBtn?.setAttribute('scale', '0 0 0')
				this.el.sceneEl?.addEventListener('asset-change', (event) => {
					const customEvent = event as CustomEvent; // Cast event to CustomEvent
					const newAssetId = customEvent.detail.assetId;
					if (newAssetId === 0) {
						cardiacTriggerBtn?.setAttribute('scale', '0 0 0')
					}
					if (newAssetId === 1) {
						console.log('button on')
						cardiacTriggerBtn?.setAttribute('scale', '1 1 1')
					}
				});
				cardiacTriggerBtn?.play()
				cardiacTriggerBtn?.addEventListener('click', () => {
					this.cardiacMuscleButtonHandler()
					if (cardiacTriggerBtn) {
						this.annotationComponent.setObjectToFollow(cardiacTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						}
						(cardiacTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate()
						this.currentDeactivatedButton = cardiacTriggerBtn
					}
				});
			};
		},
		tick(this: ICellSceneAframe, time: number, deltaTime: number) {
			if (this.mixer) {
				this.mixer.update(deltaTime * 0.001);
			}
		},
	},
};
export { CellScene as CellSceneComponent }