import * as AFrame from 'aframe';
import * as THREE from 'three';
import { IAnnotationAframe } from '../../../lib/aframe/components/annotation';
import { IAnnotationSystemAframe } from '../../../lib/aframe/systems/annotation-system';
import { WorldButtonAframeInstance } from '../../../lib/aframe/components/world-button';

interface PoolComponent extends AFrame.Component {
	requestEntity(): AFrame.Entity | null;
	returnEntity(entity: AFrame.Entity): void;
}
interface IBrainSceneAframe {
	currentDeactivatedButton: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
	annotationComponent: IAnnotationAframe;
	el: AFrame.Entity;
	poolButtons: PoolComponent;
	model: AFrame.Entity;
	cerebellumTriggerBtn: AFrame.Entity | null;
	medullaTriggerBtn: AFrame.Entity | null;
	brainTriggerBtn: AFrame.Entity | null;
	brain: THREE.Mesh ;
	cerebellum: THREE.Mesh ;
	medulla: THREE.Mesh ;
	cerebellumButtonHandler: () => void;
	medullaButtonHandler: () => void;
	brainButtonHandler: () => void;
	onObjectSelected: ((selectedObject: { title: string; body: string }) => void) | null;
}

const BrainScene = {
	name: 'experiment',
	val: {
		init(this: IBrainSceneAframe) {
			let isInitialised = false;
			let count = 0;
			this.el.addEventListener('model-loaded', () => {
				count++;
				if (count < 2) return
				if (!isInitialised) {
					this.model = document.getElementById('model') as AFrame.Entity;
					this.brain = this.model.object3D.getObjectByName('Brain_Low') as THREE.Mesh;
					this.cerebellum = this.model.object3D.getObjectByName('Cerebellum_Low') as THREE.Mesh;
					this.medulla = this.model.object3D.getObjectByName('BrainStem_Low') as THREE.Mesh;

					const scene = this.el.sceneEl as AFrame.Scene & {
						systems: { "annotation-system": IAnnotationSystemAframe };
					};
					const annotationSystem = scene.systems["annotation-system"];
					this.onObjectSelected = annotationSystem.getObjectSelectedFunction();

					this.el.setAttribute('annotation', '');
					this.annotationComponent = this.el.components.annotation as IAnnotationAframe;

					//get pool entity
					this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
					// ony initialise buttons once pool has loaded
					if (this.poolEntity.hasLoaded) {
						initialiseButtons();
					} else {
						this.poolEntity.addEventListener('loaded', () => {
							initialiseButtons();
						});
					}
					isInitialised = true;
				}
				else return
			});

			const highlightsToggle = (object: THREE.Mesh) => {
				const material = new THREE.MeshStandardMaterial()
				const oldMaterial = object.material
				Object.assign(material, oldMaterial)
				material.color = new THREE.Color(0, 31, 41)
				if (object) {
					object.material = material
				}
				setTimeout(() => {
					if (object) {
						object.material = oldMaterial
					}
				}, 1000);
				setTimeout(() => {
					if (object) {
						object.material = material
					}
				}, 2000);
				setTimeout(() => {
					if (object) {
						object.material = oldMaterial
					}
				}, 3000);
			}

			this.el.sceneEl?.addEventListener('lesson-start', () => {
				console.log('lesson started')
				// remove tap place
				const ring = document.getElementById('ring')
				if (ring) {
					ring.removeAttribute('tap-place-air')
					this.el.sceneEl?.removeChild(ring)
				}
			})

			// when model is loaded adding recenter functionality
			this.el.sceneEl?.addEventListener('lesson-recenter', () => {
				this.el.sceneEl?.emit('recenter');
				// check if the ring exists
				// if it does ignore the event
				const ring = document.getElementById('ring')
				if (ring) {
					return;
				} else {
					const ring = document.createElement('a-ring');

					ring.setAttribute('id', 'ring');
					ring.setAttribute('tap-place-air', 'id: sceneHolder; scale: 1 1 1; offset: 0 -1 -2');
					ring.setAttribute('material', 'shader: flat; color: #ffffff');
					ring.setAttribute('rotation', '-90 0 0');
					ring.setAttribute('radius-inner', '0.5');
					ring.setAttribute('radius-outer', '0.8');

					// Attach the created ring element to the scene or another parent entity.
					this.el.sceneEl?.appendChild(ring);
					// fix the annotations if there is an active button
					if (this.currentDeactivatedButton) {
						(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate()
						// remove the line
						this.annotationComponent.deactivate();
					}
				}
			});

			this.cerebellumButtonHandler = () => {
				highlightsToggle(this.cerebellum);
				if (this.onObjectSelected) {
					const title = 'Cerebellum';
					const body = 'The cerebellum is located below the cerebrum and is responsible for coordinating muscle movement and balance. '
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.medullaButtonHandler = () => {
				highlightsToggle(this.medulla);
				if (this.onObjectSelected) {
					const title = 'Medulla';
					const body = 'The brainstem connects the brain to the spinal cord; the medulla is a part of it that controls automatic and involuntary functions such as breathing, blood pressure, and heart rate';
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			this.brainButtonHandler = () => {
				highlightsToggle(this.brain);
				if (this.onObjectSelected) {
					const title = 'Cerebral Cortex';
					const body = 'The cerebral cortex is the outer layer of the largest part of the brain, the cerebrum. It contains the left and right hemispheres of the brain, and is responsible for consciousness, thinking and intelligence, language processing, and voluntary actions. This part of the brain is responsible for memory storage.'
					this.onObjectSelected({ title, body })
				} else {
					console.log('No object selected method');
				}
			}

			const initialiseButtons = () => {
				const poolButtons = this.poolEntity.components['pool'] as PoolComponent;

				this.cerebellumTriggerBtn = poolButtons.requestEntity();
				this.cerebellumTriggerBtn?.object3D.position.set(1.2, -2.1, -1.7);
				this.cerebellumTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.cerebellumTriggerBtn?.play();
				this.cerebellumTriggerBtn?.addEventListener('click', () => {
					this.cerebellumButtonHandler();
					if (this.cerebellumTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.cerebellumTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.cerebellumTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.cerebellumTriggerBtn;
					}
				});

				this.medullaTriggerBtn = poolButtons.requestEntity();
				this.medullaTriggerBtn?.object3D.position.set(0, -1.7, 0.2);
				this.medullaTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.medullaTriggerBtn?.play();
				this.medullaTriggerBtn?.addEventListener('click', () => {
					this.medullaButtonHandler();
					if (this.medullaTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.medullaTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.medullaTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.medullaTriggerBtn;
					}
				});


				this.brainTriggerBtn = poolButtons.requestEntity();
				this.brainTriggerBtn?.object3D.position.set(0, 2, 3.3);
				this.brainTriggerBtn?.object3D.scale.set(1.5, 1.5, 1.5);
				this.brainTriggerBtn?.play();
				this.brainTriggerBtn?.addEventListener('click', () => {
					this.brainButtonHandler();
					if (this.brainTriggerBtn) {
						this.annotationComponent.setObjectToFollow(this.brainTriggerBtn);
						if (this.currentDeactivatedButton) {
							(this.currentDeactivatedButton.components['world-button'] as unknown as WorldButtonAframeInstance).activate();
						}
						(this.brainTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance).deactivate();
						this.currentDeactivatedButton = this.brainTriggerBtn;
					}
				});
			}
		},
	},
};
export { BrainScene as BrainSceneComponent }
