import React, { Fragment, useState } from 'react';
import '../styles/sliderArrowsAnnotation.css';
import '../styles/transitions.css';

interface Props {
    titles: string[];
    content?: string[];
    visibility: boolean;
}

const ArrowLeft = 'https://bridgear.blob.core.windows.net/public/icons/arrow_back.png';
const ArrowRight = 'https://bridgear.blob.core.windows.net/public/icons/arrow_forward.png';

const SliderArrowsAnnotationContainer: React.FC<Props> = ({ titles, content, visibility }) => {
    const visibilityClass = visibility ? 'sliderArrowsAnnotationContainer' : 'shrink-complete';
    const [currentAsset, setCurrentAsset] = useState()

    React.useEffect(() => {
        // console.log('Use ref asset id changed: ', assetId, ' dispatching event');
        const scene = document.querySelector('a-scene');
        if (scene && scene.hasLoaded) {
            // Update the assetChangeEvent detail before dispatching
            scene.addEventListener('asset-change', (e) => {
                const ce = e as CustomEvent;
                const currentAssetid = ce.detail.assetId;
                setCurrentAsset(currentAssetid)
            })
        }
    }, [visibility]);

    const [currentSlide, setCurrentSlide] = useState(0);
    const scene = document.querySelector('a-scene');
    const goToNextSlide = () => {
        scene?.emit('next-slide',{title: currentSlide});
        setCurrentSlide((prevSlide) => (prevSlide === titles.length - 1 ? 0 : prevSlide + 1));
    };

    const goToPrevSlide = () => {
        scene?.emit('prev-slide',{title: currentSlide});
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? titles.length - 1 : prevSlide - 1));
    };

    return (
        <Fragment>
            <div id="sliderArrowsAnnotationContainer" className={`${visibilityClass}`}>
                <div className='sliderArrowAnnotationHeader'>
                    <button onClick={goToPrevSlide}><img className='arrow' src={ArrowLeft} alt="prev"/></button>
                    <div className="sliderArrowAnnotationTitle" dangerouslySetInnerHTML={{ __html: titles[currentSlide] }}/>
                    <button onClick={goToNextSlide}><img className='arrow' src={ArrowRight} alt="next"/></button>
                </div>
                { content && <div className="sliderArrowAnnotationContent" dangerouslySetInnerHTML={{ __html: content[currentSlide] }}/> }
            </div>


        </Fragment>
    );
};

export default SliderArrowsAnnotationContainer;
