import React, { useState } from 'react';
import {
  AFrameScene,
  DISABLE_IMAGE_TARGETS,
} from '../../../lib/aframe-component';
import {
  Annotation,
  AnnotationState,
} from '../../../lib/aframe/components/annotation';
import { FaceCameraComponent } from '../../../lib/aframe/components/face-camera';
import { WorldButtonComponent } from '../../../lib/aframe/components/world-button';
import LessonUI from '../../../lib/lesson-ui/lesson-ui';
import { TemperatureChangeSceneComponent } from './lesson-start';
import flashcardData from './data/flashcardData.json';
import quizData from './data/quizData.json';
import {
  ANNOTATION_HINT,
  INFO_HINT,
  QUIZ_HINT,
} from 'shared/constants/hint-items';
import {TapPlaceComponent} from "../../../lib/aframe/components/tap-place-object";
import { LoadersPermissonsEnum } from 'shared/enums';

const introText = 'Perform a paper chromatography experiment and watch the mobile phase by dragging the timeline. Analyse the results by answering a series of quiz questions.';

const hintItems = [ANNOTATION_HINT, QUIZ_HINT, INFO_HINT];

const ChemistryChromatographyAframe = () => {
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    body: string;
    imageExtra?: string;
  } | null>(null);
  const [sliderLoaded,  ] = useState<{
    title: string;
    range: number[];
    step: number;
    firstValue: number;
    stringVariant?: boolean,
  } | null>({
    title: 'Minutes',
    range: [0, 20],
    step: 5,
    firstValue: 0,
    stringVariant: false,
  });
  const [annotationState, setAnnotationState] = useState<AnnotationState>(
    AnnotationState.None
  );
  const [lessonStarted, setLessonStarted] = useState(false);
  const [permissions, setPermissions] = useState<LoadersPermissonsEnum | undefined>(undefined);

  const onObjectSelected = (data: { title: string; body: string; imageExtra?: string; }) => {
    setSelectedObject(data);
  };
  const onLessonStarted = () => {
    setLessonStarted(true);
  };
  const onLessonRecenter = () => {
    setLessonStarted(false);
  };
  const onAnnotationStateChanged = (newState: AnnotationState) => {
    setAnnotationState(newState);
  };
  const permissionsСonfirmation = (status: LoadersPermissonsEnum) => {
    setPermissions(status);
  };

  return (
    <React.Fragment>
      <LessonUI
        flashcardData={flashcardData}
        quizData={quizData}
        introductionText={introText}
        lessonStarted={lessonStarted}
        selectedObject={selectedObject}
        sliderLoad={sliderLoaded}
        onAnnotationStateChanged={onAnnotationStateChanged}
        hintItems={hintItems}
        permissions={permissions}
      />
      <AFrameScene
        sceneHtml={require('./chemistry-chromatography-aframe-scene.html')}
        imageTargets={DISABLE_IMAGE_TARGETS}
        components={[
          WorldButtonComponent,
            TapPlaceComponent,
            FaceCameraComponent,
          TemperatureChangeSceneComponent,
          Annotation,
        ]}
        onObjectSelected={onObjectSelected}
        annotationState={annotationState}
        onLessonRecenter={onLessonRecenter}
        onLessonStart={onLessonStarted}
        permissionsСonfirmation={permissionsСonfirmation}
      />
    </React.Fragment>
  );
};
export { ChemistryChromatographyAframe };
