import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import '../styles/hintCarousel.css';

export interface CarouselItem {
  path: string;
  alt: string;
}

interface HintCarouselProps {
  closeHintEvent: () => void;
  items: CarouselItem[];
  showItemIndex: number;
}

const HintCarousel: React.FC<HintCarouselProps> = ({
  closeHintEvent,
  items,
  showItemIndex
}) => {
  const [currentIndex, setCurrentIndex] = useState(showItemIndex);
  const [orderedItems, setOrderedItems] = useState(items);

  const handleNext = (now: number) => {
    setCurrentIndex(now);
  };

  const handlePrevious = (now: number) => {
    setCurrentIndex(now);
  };

  const closeHint = () => {
    closeHintEvent();
  };

  useEffect(() => {
    setCurrentIndex(showItemIndex);
    setOrderedItems(items);
  }, [items, showItemIndex])

  function Item(props: { key: number; item: CarouselItem }) {
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
      setLoaded(true);
    };
    return (
      <img
          className='carousel-item'
          src={props.item.path}
          alt={props.item.alt}
          style={{ display: loaded ? 'block' : 'none' }}
          onLoad={handleImageLoad}
        />
    );
  }

  return (
    <>
      <Carousel
        className='hint-carousel'
        autoPlay={false}
        animation='slide'
        swipe={true}
        navButtonsAlwaysInvisible={true}
        index={currentIndex}
        cycleNavigation={false}
        indicatorContainerProps={{ className: 'indicator-container' }}
        indicatorIconButtonProps={{ className: 'indicator-icon-button' }}
        activeIndicatorIconButtonProps={{
          className: 'active-indicator-icon-button',
        }}
        next={(now: number | undefined) => handleNext(now || 0)}
        prev={(now: number | undefined) => handlePrevious(now || 0)}
      >
        {orderedItems.map((item: CarouselItem, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
      {currentIndex !== orderedItems.length - 1 && (
        <p className='slider-text'>Swipe to continue</p>
      )}
      {currentIndex === orderedItems.length - 1 && (
        <button className='close-hint-button' onClick={closeHint}>
          Continue
        </button>
      )}
    </>
  );
};

export default HintCarousel;
