import { useTrainingsContext } from '../../context';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export function useData() {
  const { id } = useParams<{ id: string }>();
  const [
    {
      trainingsInfo: { trainings },
    },
  ] = useTrainingsContext();

  const training = useMemo(
    () => trainings.find((training) => training.id === id),
    [id, trainings]
  );

  return {
    training,
  };
}
