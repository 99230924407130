import { Stack, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { IconImage } from '../../../shared/components/IconImage';
import { CustomContainer } from '../../../shared/components/CustomContainer';
import { HowItWorksBG } from './HowItWorksBG';
import { StyledText } from '../../../shared/components/StyledText';
import { DisplayEnum } from '../../../shared/enums';
import { useTranslation } from 'react-i18next';
import Description from './Description';

import howItWorks from '../../../assets/howItWorks.png';

export function HowItWorksComponent() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
  const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet));
  const { t, i18n } = useTranslation();
  const i18nPath = 'LANDING_PAGE.HOW_IT_WORKS_COMPONENT.';

  return (
    <Stack
      mt={tablet ? 3 : 6}
      id='howItWorks'
      overflow={mobile ? 'hidden' : 'auto'}
    >
      <CustomContainer>
        <HowItWorksBG>
          <Box mt={tablet ? 3 : 10} textAlign='center'>
            <Typography
              fontSize={tablet ? 28 : 44}
              fontFamily='GilroyBold'
              color='#000'
              lineHeight={1}
              mb={2}
            >
              {t(i18nPath + 'TITLE')}
            </Typography>
            <Typography
              fontSize={16}
              fontFamily='GilroySemiBold'
              color={theme.palette.text.secondary}
              mb={tablet ? 3 : 6}
              maxWidth={685}
              mx='auto'
            >
              {t(i18nPath + 'DESCRIPTION.PART_1')}
              <br />
              {t(i18nPath + 'DESCRIPTION.PART_2')}
              <br />
              {t(i18nPath + 'DESCRIPTION.PART_3')}
              <br />
              {t(i18nPath + 'DESCRIPTION.PART_4')}
            </Typography>
            <Box mt={tablet ? -5 : -10} ml={mobile ? -13.4 : 0}>
              <IconImage
                src={howItWorks}
                width={tablet ? '600px' : '900px'}
                alt='how It Works'
              />
            </Box>
            <Box mt={tablet ? -17 : -26}>
              <StyledText>{t(i18nPath + 'BEST_WAY')}</StyledText>
            </Box>
            <Box mt={3}>
              <Typography fontSize={26} fontFamily='GilroyBold' color='#000'>
                {t(i18nPath + 'CONNECTING_DIGITAL')}
              </Typography>
              <Typography
                width={mobile ? 'calc(100vw - 32px)' : 565}
                mx='auto'
                fontSize={16}
                fontFamily='GilroyMedium'
                color={theme.palette.text.secondary}
              >
                {t(i18nPath + 'IN_ADDITION')}
              </Typography>
            </Box>
          </Box>
        </HowItWorksBG>
        <Description />
      </CustomContainer>
    </Stack>
  );
}

export default HowItWorksComponent;
